import React from 'react';

import { Form, SelectProps } from 'antd';

import { CommonObjectOption } from 'core/lib';
import CommonObjectMultiSelect, { CommonObjectMultiSelectProps } from 'utils/form/commonObjectMultiSelect';

import { ListingEditableCellProps } from './types';

function ListingCommonObjectMultiSelect({
  dataIndex,
  rules,
  isDisabled,
  ...props
}: ListingEditableCellProps & CommonObjectMultiSelectProps & SelectProps<number[]>) {
  const form = Form.useFormInstance();

  const disabled = props.disabled || (isDisabled && isDisabled(form));

  return (
    <Form.Item
      hasFeedback
      rules={rules}
      name={dataIndex}
      className="m-0"
      valuePropName="selectedOption"
      normalize={(options: CommonObjectOption[]) => {
        return options.map((v) => ({ id: v.value, name: v.label }));
      }}
    >
      <CommonObjectMultiSelect {...props} disabled={disabled} />
    </Form.Item>
  );
}

export default ListingCommonObjectMultiSelect;
