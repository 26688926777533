import React from 'react';

import { Form } from 'antd';
import { RcFile, UploadFile } from 'antd/lib/upload/interface';

import { CommonObjectOption } from 'core/lib';
import { normalizeFileUpload } from 'utils';

import { externalFormConfig } from '../externalFormConfig';

const { generalInformation } = externalFormConfig;

type GeneralInformationProps = {
  disabled: boolean;
};

const GeneralInformationSection = ({ disabled }: GeneralInformationProps) => {
  return (
    <div className="flex flex-col max-w-512 m-auto">
      <div className="flex sm:flex-row flex-col gap-x-4">
        <Form.Item
          className="md:w-3/6"
          label={generalInformation.name.label}
          name={generalInformation.name.dataIndex}
          rules={generalInformation.name.rules}
        >
          <generalInformation.name.component disabled={disabled} />
        </Form.Item>
        <Form.Item
          className="md:w-3/6"
          requiredMark={false}
          label={generalInformation.companyLogo.label}
          name={generalInformation.companyLogo.dataIndex}
          rules={generalInformation.companyLogo.rules}
          normalize={({ file }: { file: UploadFile<RcFile & XMLHttpRequest> }) => {
            return normalizeFileUpload(file, generalInformation.companyLogo.dataIndex);
          }}
        >
          <generalInformation.companyLogo.component disabled={disabled} />
        </Form.Item>
      </div>
      <div className="flex sm:flex-row flex-col gap-x-4">
        <Form.Item
          className="md:w-3/6"
          label={generalInformation.industry.label}
          name={generalInformation.industry.dataIndex}
          rules={generalInformation.industry.rules}
          valuePropName="selectedOption"
          normalize={(options: CommonObjectOption[]) => {
            return options.map((opt) => ({ id: opt.id, name: opt.label }));
          }}
        >
          <generalInformation.industry.component disabled={disabled} />
        </Form.Item>
        <Form.Item
          className="md:w-3/6"
          label={generalInformation.technology.label}
          name={generalInformation.technology.dataIndex}
          rules={generalInformation.technology.rules}
          valuePropName="selectedOption"
          normalize={(option: CommonObjectOption) => {
            return option ? { id: option.id, name: option.label } : null;
          }}
        >
          <generalInformation.technology.component disabled={disabled} />
        </Form.Item>
      </div>
      <div className="flex sm:flex-row flex-col gap-x-4">
        <Form.Item
          className="md:w-3/6"
          label={generalInformation.customerFocus.label}
          name={generalInformation.customerFocus.dataIndex}
          rules={generalInformation.customerFocus.rules}
          valuePropName="selectedOption"
          normalize={(options: CommonObjectOption[]) => {
            return options.map((opt) => ({ id: opt.id, name: opt.label }));
          }}
        >
          <generalInformation.customerFocus.component disabled={disabled} />
        </Form.Item>
        <Form.Item
          className="md:w-3/6"
          label={generalInformation.country.label}
          name={generalInformation.country.dataIndex}
          rules={generalInformation.country.rules}
        >
          <generalInformation.country.component disabled={disabled} />
        </Form.Item>
      </div>
      <div className="flex sm:flex-row flex-col gap-x-4">
        <Form.Item
          className="md:w-3/6"
          label={generalInformation.city.label}
          name={generalInformation.city.dataIndex}
          rules={generalInformation.city.rules}
        >
          <generalInformation.city.component disabled={disabled} />
        </Form.Item>
        <Form.Item
          className="md:w-3/6"
          label={generalInformation.website.label}
          name={generalInformation.website.dataIndex}
          rules={generalInformation.website.rules}
        >
          <generalInformation.website.component disabled={disabled} />
        </Form.Item>
      </div>
      <div className="flex sm:flex-row flex-col gap-x-4">
        <Form.Item
          className="md:w-3/6"
          label={generalInformation.foundingYear.label}
          name={generalInformation.foundingYear.dataIndex}
          rules={generalInformation.foundingYear.rules}
        >
          <generalInformation.foundingYear.component disabled={disabled} />
        </Form.Item>
        <Form.Item
          className="md:w-3/6"
          label={generalInformation.heardFrom.label}
          name={generalInformation.heardFrom.dataIndex}
          rules={generalInformation.heardFrom.rules}
        >
          <generalInformation.heardFrom.component disabled={disabled} />
        </Form.Item>
      </div>
    </div>
  );
};

export default GeneralInformationSection;
