import React, { useEffect, useMemo, useState } from 'react';

import { FilterFilled } from '@ant-design/icons';
import { Badge, Button, Divider, Modal } from 'antd';
import { isEqual, mapValues, omit } from 'lodash';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps, useSelector } from 'react-redux';
import { useMedia } from 'react-use';

import { AppState } from 'core/lib';
import { UserFilters } from 'core/lib/modules/users/entities/filters';
import { selectUserListingFilters, setUserFilters, selectUserFiltersCount } from 'core/lib/modules/users/store';

import OrganizationFilter from './organizationFilter';
import RoleFilter from './roleFilter';
import StatusFilter from './statusFilter';
import { ClearFiltersButton } from '../../components';

const mapState = (state: AppState) => ({
  filters: selectUserListingFilters(state),
});

const mapDispatch = {
  applyFilters: setUserFilters,
};

const connector = connect(mapState, mapDispatch);

type StatusFiltersProps = ConnectedProps<typeof connector>;

const ListingFilters = ({ applyFilters, filters }: StatusFiltersProps) => {
  const [draftFilters, setDraftFilters] = useState<Partial<UserFilters>>(filters);
  const [modalOpen, toggleModal] = useState(false);

  const isMobile = useMedia('(max-width: 768px)');
  const { t } = useTranslation();
  const filtersCount = useSelector(selectUserFiltersCount(isMobile));

  useEffect(() => {
    setDraftFilters(filters);
  }, [filters, modalOpen]);

  const showModal = () => {
    toggleModal(true);
  };

  const handleOk = () => {
    applyFilters(draftFilters);
    toggleModal(false);
  };

  const clearFilterKeys = useMemo(() => {
    if (isMobile) {
      return Object.keys(filters);
    }
    return Object.keys(omit(filters, ['status']));
  }, [isMobile, filters]);

  const onClearModalClear = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setDraftFilters(mapValues(filters, (value, key) => (clearFilterKeys.includes(key) ? undefined : (value as any))));
  };

  const onCancel = () => {
    toggleModal(false);
  };

  const onFiltersChange = (partialFilters: Partial<UserFilters>) => {
    setDraftFilters({ ...draftFilters, ...partialFilters });
  };

  const filtersUpdated = useMemo(() => !isEqual(draftFilters, filters), [draftFilters, filters]);

  return (
    <>
      <Badge count={filtersCount}>
        <Button type="default" onClick={showModal} icon={<FilterFilled />}>
          {t('user:filters.filters')}
        </Button>
      </Badge>
      <ClearFiltersButton />
      <Modal
        width="auto"
        style={{ maxWidth: 600 }}
        bodyStyle={{ overflowY: 'scroll', height: '50vh' }}
        title={t('global:filters.moreFilters')}
        open={modalOpen}
        onCancel={onCancel}
        footer={
          <div>
            <Button onClick={onClearModalClear} type="text">
              {t('actions:global.clearAll')}
            </Button>
            <Button disabled={!filtersUpdated} onClick={handleOk} type="primary">
              {t('actions:global.apply')}
            </Button>
          </div>
        }
      >
        <div>
          {isMobile && (
            <>
              <StatusFilter onChange={onFiltersChange} filters={draftFilters.status} />
              <Divider />
              <RoleFilter onChange={onFiltersChange} filters={draftFilters.roles} />
              <Divider />
              <OrganizationFilter onChange={onFiltersChange} filters={draftFilters.organizations} />
            </>
          )}
        </div>
      </Modal>
    </>
  );
};

export default connector(ListingFilters);
