import React, { useState, useEffect } from 'react';

import { Typography, PageHeader } from 'antd';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router';
import { useMedia } from 'react-use';

import { contactBookApi, ContactBookDetails } from 'core/lib';
import ContactBookBuilder from 'core/lib/utils/builders/ContactBookBuilder';
import { Routes } from 'routes/routes';

import { ContactBookForm } from '../form';

const { Title } = Typography;

const ContactBookCreateComponent = () => {
  const { t } = useTranslation();
  const [contactBook] = useState(new ContactBookBuilder().build());
  const isMobile = useMedia('(max-width: 768px)');
  const history = useHistory();
  const [activeTabKey, setActiveTabKey] = useState<string>();

  const [postDealBase, { isLoading: isSaving, isSuccess: saveSuccess, data }] =
    contactBookApi.usePostContactBookMutation();

  const onSave = (contactBook: ContactBookDetails) => {
    return postDealBase(contactBook).unwrap();
  };

  useEffect(() => {
    if (saveSuccess) {
      history.push(generatePath(Routes.CONTACT_BOOK_DETAILS.path, { id: data }));
    }
  }, [saveSuccess, data, history]);

  const onCancel = () => {
    history.push(Routes.CONTACT_BOOK_LISTING.path);
  };

  return (
    contactBook && (
      <>
        <PageHeader
          title={
            <div className="flex sm:flex-row flex-col items-center gap-2">
              <Title
                className="ws-normal md:truncate w-full text-center"
                style={{ margin: 0 }}
                level={isMobile ? 4 : 3}
              >
                {t('contactBook:placeholders.createContactBook')}
              </Title>
            </div>
          }
        />
        <ContactBookForm
          activeTabKey={activeTabKey}
          setActiveTabKey={setActiveTabKey}
          isSaving={isSaving}
          contactBook={contactBook}
          onSave={onSave}
          onCancel={onCancel}
          editMode
          isNew
        />
      </>
    )
  );
};

export default ContactBookCreateComponent;
