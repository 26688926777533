import { DealBase, FinancialYear, OrganizationShort, Scorecard, BaseUser, DealBaseFounder, Attachment } from 'core/lib';
import { DealBaseStatusType } from 'core/lib/constants/statuses';
import { NumberRange } from 'core/lib/modules/app';
import { CommonObject } from 'core/lib/modules/shared';

class DealBaseBuilder {
  private readonly _dealbase: DealBase;

  constructor() {
    this._dealbase = {
      // General Information - Overview
      id: 0,
      name: '',
      companyLogo: null,
      country: '',
      technology: null,
      industries: null,
      customerFocus: null,
      city: '',
      website: '',
      foundingYear: null,
      stage: null,
      preMoneyValuation: null,
      investmentRound: null,
      // General Information - Questionary
      businessIdea: '',
      productStatus: null,
      problemSolve: '',
      marketSize: '',
      yourCustomer: '',
      getYourCustomer: '',
      tractionSoFar: '',
      roundSpecifications: '',
      // General Information - ContactDetails
      organization: null,
      founders: [],
      ceo: '',
      heardFrom: '',
      contactPerson: '',
      contactNumber: '',
      contactEmail: '',
      //General Information - PitchDeck
      pitchDeckDocument: null,

      // Documents
      businessPlanDocument: null,
      budgetDocument: null,
      externalAttachments: [],
      internalAttachments: [],

      //Evaluation - General
      assigned: null,
      involved: [],
      nextSteps: '',
      timeFrame: '',
      followUpDate: '',
      sort: '',
      priority: null,
      tier: null,
      createdBy: null,

      initialScorecards: [],

      //Evaluation - Financials
      ticketSize: '',
      commitedCapital: '',
      neOption: '',

      //Metrics - Currency
      currency: '',

      //Metrics - Financials details
      revenueType: null,
      revenueYears: [],
      revenueProjections: [],
      ebitdaYears: [],
      ebitdaProjections: [],
      //Status
      status: 0,
      lastStatus: undefined,
    };
  }

  build() {
    return JSON.parse(JSON.stringify(this._dealbase)) as DealBase;
  }

  id(id: number) {
    this._dealbase.id = id;
    return this;
  }

  name(name: string) {
    this._dealbase.name = name;
    return this;
  }
  technology(technology: CommonObject | null) {
    this._dealbase.technology = technology;
    return this;
  }
  industries(industries: CommonObject[] | null) {
    this._dealbase.industries = industries;
    return this;
  }
  customerFocus(customerFocus: CommonObject[] | null) {
    this._dealbase.customerFocus = customerFocus;
    return this;
  }
  city(city: string) {
    this._dealbase.city = city;
    return this;
  }
  country(country: string) {
    this._dealbase.country = country;
    return this;
  }
  website(website: string) {
    this._dealbase.website = website;
    return this;
  }
  foundingYear(foundingYear: number | null) {
    this._dealbase.foundingYear = foundingYear;
    return this;
  }
  stage(stage: CommonObject | null) {
    this._dealbase.stage = stage;
    return this;
  }
  preMoneyValuation(preMoneyValuation: NumberRange | null) {
    this._dealbase.preMoneyValuation = preMoneyValuation;
    return this;
  }
  investmentRound(investmentRound: NumberRange | null) {
    this._dealbase.investmentRound = investmentRound;
    return this;
  }
  businessIdea(businessIdea: string) {
    this._dealbase.businessIdea = businessIdea;
    return this;
  }
  productStatus(productStatus: CommonObject | null) {
    this._dealbase.productStatus = productStatus;
    return this;
  }
  problemSolve(problemSolve: string) {
    this._dealbase.problemSolve = problemSolve;
    return this;
  }
  marketSize(marketSize: string) {
    this._dealbase.marketSize = marketSize;
    return this;
  }
  yourCustomer(yourCustomer: string) {
    this._dealbase.yourCustomer = yourCustomer;
    return this;
  }
  getYourCustomer(getYourCustomer: string) {
    this._dealbase.getYourCustomer = getYourCustomer;
    return this;
  }
  tractionSoFar(tractionSoFar: string) {
    this._dealbase.tractionSoFar = tractionSoFar;
    return this;
  }
  roundSpecifications(roundSpecifications: string) {
    this._dealbase.roundSpecifications = roundSpecifications;
    return this;
  }
  organization(organization: OrganizationShort | null) {
    this._dealbase.organization = organization;
    return this;
  }
  founder(founders: DealBaseFounder[]) {
    this._dealbase.founders = founders;
    return this;
  }
  ceo(ceo: string | null) {
    this._dealbase.ceo = ceo;
    return this;
  }
  heardFrom(heardFrom: string) {
    this._dealbase.heardFrom = heardFrom;
    return this;
  }
  contactPerson(contactPerson: string) {
    this._dealbase.contactPerson = contactPerson;
    return this;
  }
  contactNumber(contactNumber: string) {
    this._dealbase.contactNumber = contactNumber;
    return this;
  }
  contactEmail(contactEmail: string) {
    this._dealbase.contactEmail = contactEmail;
    return this;
  }
  pitchDeckDocument(pitchDeckDocument: Attachment) {
    this._dealbase.pitchDeckDocument = pitchDeckDocument;
    return this;
  }
  businessPlanDocument(businessPlanDocument: Attachment) {
    this._dealbase.businessPlanDocument = businessPlanDocument;
    return this;
  }
  budgetDocument(budgetDocument: Attachment) {
    this._dealbase.budgetDocument = budgetDocument;
    return this;
  }
  internalAttachments(internalAttachments: Attachment[]) {
    this._dealbase.internalAttachments = internalAttachments;
    return this;
  }
  externalAttachments(externalAttachments: Attachment[]) {
    this._dealbase.externalAttachments = externalAttachments;
    return this;
  }
  assigned(assigned: BaseUser | null) {
    this._dealbase.assigned = assigned;
    return this;
  }
  involved(involved: BaseUser[]) {
    this._dealbase.involved = involved;
    return this;
  }
  nextSteps(nextSteps: string) {
    this._dealbase.nextSteps = nextSteps;
    return this;
  }
  timeFrame(timeFrame: string) {
    this._dealbase.timeFrame = timeFrame;
    return this;
  }
  followUp(followUpDate?: string) {
    this._dealbase.followUpDate = followUpDate;
    return this;
  }
  sort(sort: string) {
    this._dealbase.sort = sort;
    return this;
  }
  priority(priority: number | null) {
    this._dealbase.priority = priority;
    return this;
  }
  tier(tier: number | null) {
    this._dealbase.tier = tier;
    return this;
  }
  createdBy(createdBy: BaseUser | null) {
    this._dealbase.createdBy = createdBy;
    return this;
  }
  initialScorecards(scorecards: Scorecard[] = []) {
    this._dealbase.initialScorecards = scorecards;
    return this;
  }
  ticketSize(ticketSize: string) {
    this._dealbase.ticketSize = ticketSize;
    return this;
  }
  commitedCapital(commitedCapital: string) {
    this._dealbase.commitedCapital = commitedCapital;
    return this;
  }
  neOption(neOption: string) {
    this._dealbase.neOption = neOption;
    return this;
  }
  currency(currency: string) {
    this._dealbase.currency = currency;
    return this;
  }
  revenueType(revenueType: CommonObject | null) {
    this._dealbase.revenueType = revenueType;
    return this;
  }
  revenueYears(revenueYears: FinancialYear[]) {
    this._dealbase.revenueYears = revenueYears;
    return this;
  }
  revenueProjections(revenueProjections: FinancialYear[]) {
    this._dealbase.revenueProjections = revenueProjections;
    return this;
  }
  ebitdaYears(ebitdaYears: FinancialYear[]) {
    this._dealbase.ebitdaYears = ebitdaYears;
    return this;
  }
  ebitdaProjections(ebitdaProjections: FinancialYear[]) {
    this._dealbase.ebitdaProjections = ebitdaProjections;
    return this;
  }
  status(status: DealBaseStatusType) {
    this._dealbase.status = status;
    return this;
  }
  lastStatus(lastStatus?: DealBaseStatusType) {
    this._dealbase.lastStatus = lastStatus;
    return this;
  }
}

export default DealBaseBuilder;
