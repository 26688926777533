const currencies = {
  USD: {
    symbol: '$',
    name: 'US Dollar',
  },
  CAD: {
    symbol: 'CA$',
    name: 'Canadian Dollar',
  },
  EUR: {
    symbol: '€',
    name: 'Euro',
  },
  GBP: {
    symbol: '£',
    name: 'British Pound Sterling',
  },
  DKK: {
    symbol: 'Dkr',
    name: 'Danish Krone',
  },
  NOK: {
    symbol: 'Nkr',
    name: 'Norwegian Krone',
  },
  SEK: {
    symbol: 'Skr',
    name: 'Swedish Krona',
  },
  AUD: {
    symbol: 'AU$',
    name: 'Australian Dollar',
  },
  CHF: {
    symbol: 'CHF',
    name: 'Swiss Franc',
  },
};

export default currencies;
