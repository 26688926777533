import React from 'react';

import { Form } from 'antd';

import { contactBookFormConfig } from 'modules/contactBook/form/formConfig';

const {
  details: { tags },
} = contactBookFormConfig;

type TagsSectionProps = {
  editMode: boolean;
};

const TagsSection = ({ editMode }: TagsSectionProps) => {
  return (
    <>
      <div className="flex sm:flex-row flex-col gap-x-4">
        <Form.Item className="w-full" label={tags.tags.label} name={tags.tags.dataIndex} rules={tags.tags.rules}>
          <tags.tags.component disabled={!editMode} />
        </Form.Item>
      </div>
    </>
  );
};

export default TagsSection;
