import { ListingConfig, ListingConfigResponse } from '../../entities';

export const deserializeListingConfig = (response: ListingConfigResponse[]): ListingConfig[] => {
  return response.map((lc) => {
    return {
      id: lc.ID,
      dataIndex: lc.Name,
      width: lc.Width,
      pinned: !!lc.Pinned,
      visible: !lc.Hidden,
      index: lc.ColumnIndex,
    };
  });
};
