import React, { useState, useEffect, useMemo } from 'react';

import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, Empty, Form, message, Popover, Space, Typography } from 'antd';
import { RuleObject } from 'antd/lib/form';
import { useForm } from 'antd/lib/form/Form';
import { StoreValue } from 'antd/lib/form/interface';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';

import { SecurityState } from 'assets';
import { ContentContainer, MainContentContainer } from 'common-ui/containers';
import { DetailsSectionWrapper } from 'common-ui/wrappers';
import { userApi, UserPasswordUpdateRequest } from 'core/lib';
import { getRuleStatus } from 'modules/user/utils';
import { rules } from 'utils/form/rules';
import LoaderWrapper from 'utils/loader-wrapper';
import { PASSWORD_REGEX } from 'utils/validators/general';

import { userProfileFormConfig } from '../formConfig';

const { Text } = Typography;

const { password } = userProfileFormConfig;

const PasswordTab = () => {
  const [editMode, setEditMode] = useState(false);
  const [formDirty, setFormDirty] = useState(false);
  const [passwordValidation, setValidation] = useState(false);
  const [currentPassword, setCurrentPassword] = useState();
  const [form] = useForm();
  const { t } = useTranslation();

  const [updatePassword, { isLoading, isSuccess, error }] = userApi.useUpdatePasswordMutation();

  const onEditClick = () => {
    setEditMode(true);
  };

  const resetState = () => {
    form.resetFields();
    setEditMode(false);
  };

  const onCancelClick = () => {
    resetState();
  };

  const onFinish = (values: UserPasswordUpdateRequest) => {
    updatePassword(values);
  };

  useEffect(() => {
    if (isSuccess) {
      resetState();
      message.success(t('user:password.passwordUpdateSuccess'));
    }
  }, [isSuccess, t]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (error) {
      const errorData = error as { data: { ExceptionMessage: string } };
      message.error(errorData.data.ExceptionMessage);
    }
  }, [error]);

  const passwordSameValidator = (rule: RuleObject, value: StoreValue) => {
    if (form.getFieldValue('currentPassword') === value) {
      return Promise.reject();
    }
    return Promise.resolve();
  };

  const setPasswordValidation = () => {
    setValidation(true);
  };

  const passwordHelp = useMemo(
    () => (
      <div>
        {getRuleStatus(
          PASSWORD_REGEX.LENGTH,
          t('user:password.requirements.length'),
          currentPassword,
          passwordValidation
        )}
        {getRuleStatus(
          PASSWORD_REGEX.UPPERCASE,
          t('user:password.requirements.uppercase'),
          currentPassword,
          passwordValidation
        )}
        {getRuleStatus(
          PASSWORD_REGEX.LOWERCASE,
          t('user:password.requirements.lowercase'),
          currentPassword,
          passwordValidation
        )}
        {getRuleStatus(
          PASSWORD_REGEX.NUMBER,
          t('user:password.requirements.number'),
          currentPassword,
          passwordValidation
        )}
        {getRuleStatus(
          PASSWORD_REGEX.SPECIAL,
          t('user:password.requirements.special'),
          currentPassword,
          passwordValidation
        )}
      </div>
    ),
    [t, passwordValidation, currentPassword]
  );

  const handleValuesChange = debounce((_, allValues) => {
    setFormDirty(true);
    setCurrentPassword(allValues[password.newPassword.dataIndex]);
  }, 100);

  return (
    <LoaderWrapper loading={isLoading}>
      <Form
        onValuesChange={handleValuesChange}
        form={form}
        layout="vertical"
        name="general_user_profile"
        onFinish={onFinish}
      >
        <MainContentContainer id="dealbase-general">
          <ContentContainer>
            <DetailsSectionWrapper>
              <div className="flex w-full justify-end">
                {!editMode ? (
                  <Button onClick={onEditClick}>{t('actions:global.edit')}</Button>
                ) : (
                  <div className="flex gap-4">
                    <Button type="primary" htmlType="submit" disabled={!formDirty}>
                      {t('actions:global.save')}
                    </Button>
                    <Button onClick={onCancelClick}>{t('actions:global.cancel')}</Button>
                  </div>
                )}
              </div>
              {editMode ? (
                <div className="flex flex-col items-center">
                  <Form.Item
                    className="w-full max-w-512"
                    label={t(password.currentPassword.label)}
                    name={password.currentPassword.dataIndex}
                    rules={password.currentPassword.rules}
                  >
                    <password.currentPassword.component onBlur={setPasswordValidation} disabled={!editMode} />
                  </Form.Item>
                  <Form.Item
                    className="w-full max-w-512"
                    label={
                      <div className="flex items-center gap-1">
                        {t(password.newPassword.label)}
                        <Popover
                          content={
                            <div className="whitespace-pre">
                              {t('user:userProfileForm.password.helper.passwordCriteria')}
                            </div>
                          }
                        >
                          <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                        </Popover>
                      </div>
                    }
                    name={password.newPassword.dataIndex}
                    rules={[
                      ...password.newPassword.rules,
                      { validator: passwordSameValidator, message: t('global:inputs.newPasswordSame') },
                    ]}
                    extra={passwordHelp}
                  >
                    <password.newPassword.component disabled={!editMode} />
                  </Form.Item>
                  <Form.Item
                    className="w-full max-w-512"
                    label={t(password.confirmPassword.label)}
                    name={password.confirmPassword.dataIndex}
                    rules={[
                      rules.required,
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue(password.newPassword.dataIndex) === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error(t('user:password.passwordsNotMatching')));
                        },
                      }),
                    ]}
                    dependencies={[password.newPassword.dataIndex]}
                  >
                    <password.confirmPassword.component disabled={!editMode} />
                  </Form.Item>
                </div>
              ) : (
                <Empty
                  imageStyle={{ display: 'inline' }}
                  image={
                    <Space className="justify-center py-8" align="center">
                      <img alt="security-state" src={SecurityState} />
                    </Space>
                  }
                  description={
                    <Text className="flex max-w-96 break-normal mx-auto my-0" type="secondary">
                      {t('global:messages.passwordChangeMessage')}
                    </Text>
                  }
                />
              )}
            </DetailsSectionWrapper>
          </ContentContainer>
        </MainContentContainer>
      </Form>
    </LoaderWrapper>
  );
};

export default PasswordTab;
