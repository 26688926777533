import React, { useEffect } from 'react';

import { NumberOutlined } from '@ant-design/icons';
import { Badge, List, Typography } from 'antd';
import { generatePath } from 'react-router-dom';

import { MenuItemWrapper } from 'common-ui/leftMenu';
import { conversationsApi, selectWebSocketStatus, useAppSelector } from 'core/lib';
import { MODULE_NAMES } from 'core/lib/constants';
import colors from 'core/lib/theme/colors';
import { HubConnectionState } from 'core/lib/utils/signalR/utils';
import { Routes } from 'routes/routes';
import { useMatchParamId } from 'utils/hooks';
import { useHub } from 'utils/hooks/hubHooks';

import { CreateModuleConversationModal } from '../components';

const { Text } = Typography;

const ModuleConversationsMenu = () => {
  const { data = [], isFetching } = conversationsApi.useGetMyMenuModuleConversationsQuery();
  const [hub] = useHub();
  const webSocketStatus = useAppSelector(selectWebSocketStatus);

  const routeConvId = useMatchParamId(Routes.CONVERSATIONS_MODULE_DETAILS.path);

  const isActive = (groupConversationId: number) => {
    return routeConvId && parseInt(routeConvId) === groupConversationId;
  };

  useEffect(() => {
    if (webSocketStatus === HubConnectionState.Connected) {
      hub.conversations.joinModuleConversationMenu();
    }
    return () => {
      hub.conversations.leaveModuleConversationMenu();
    };
  }, [hub.conversations, webSocketStatus]);

  return (
    <div className="flex flex-col gap-2">
      <List
        className="empty-list-no-image"
        locale={{ emptyText: <></> }}
        split={false}
        loading={isFetching}
        dataSource={data}
        renderItem={(conversation) => {
          const title = conversation.objectTitle ?? MODULE_NAMES[conversation.objectType];

          return (
            <MenuItemWrapper
              isActive={!!isActive(conversation.id)}
              path={generatePath(Routes.CONVERSATIONS_MODULE_DETAILS.path, { id: conversation.id })}
              linkClassName="py-2"
              menuItemClassName="px-4"
            >
              <Text className="text-inherit gap-2" ellipsis>
                <NumberOutlined className="mr-2" />
                {title}
              </Text>
              <Badge
                count={conversation.undreadMessages}
                className="leading-auto"
                style={{ backgroundColor: colors.Primary.rgba }}
              />
            </MenuItemWrapper>
          );
        }}
      />
      <CreateModuleConversationModal />
    </div>
  );
};

export default ModuleConversationsMenu;
