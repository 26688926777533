import React from 'react';

import { Select, SelectProps, Tag } from 'antd';
import { PresetColorType } from 'antd/lib/_util/colors';

type BaseSelectProps = {
  asTag?: boolean;
  tagColor?: PresetColorType;
};
const BaseSelect = ({ asTag, tagColor, ...props }: SelectProps & BaseSelectProps) => {
  const displayTag = props.disabled && asTag && props.value;

  if (displayTag) {
    return <Tag color={tagColor}>{props.value}</Tag>;
  }

  return <Select {...props} />;
};

export default BaseSelect;
