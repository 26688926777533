import React from 'react';

import { ReloadOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { Switch, Route } from 'react-router-dom';

import { ListingSettings } from 'common-ui/listingConfig';
import { contactBookApi, ContactBookListing } from 'core/lib';
import { OBJECT_TYPES } from 'core/lib/constants';
import { Routes } from 'routes/routes';

import { columns } from '../listing/columns';

const ListingActions = () => {
  const [refetch] = contactBookApi.endpoints.getContactBookListing.useLazyQuerySubscription();

  const onRefreshClick = () => {
    refetch();
  };

  return (
    <div className="flex flex-row justify-between items-center">
      <div className="flex flex-row gap-4">
        <Button type="link" className="text-black" onClick={onRefreshClick} icon={<ReloadOutlined />} />
      </div>
      <Switch>
        <Route
          path={Routes.CONTACT_BOOK_LISTING.path}
          component={() => (
            <ListingSettings<ContactBookListing> rawColumns={columns} objectType={OBJECT_TYPES.CONTACT_BOOK} />
          )}
        />
      </Switch>
    </div>
  );
};

export default ListingActions;
