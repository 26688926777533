export enum SYSTEM_ROLES {
  SUPER_ADMIN = 'Super Admin',
  CLIENT_ADMIN = 'Client Admin',
  MANAGEMENT = 'Management',
  SYSTEM_OWNER = 'Owner',
  PROCESS_OWNER = 'Responsible',
  PROJECT_MANAGER = 'Project Manager',
  QUALITY_MANAGER = 'Reviewer',
  QUALITY_ASSURANCE = 'Approver',
  SME = 'Subject matter expert',
}

export enum SYSTEM_ROLES_ID {
  SUPER_ADMIN = 1,
  CLIENT_ADMIN = 2,
  MANAGEMENT = 4,
  SYSTEM_OWNER = 8,
  PROCESS_OWNER = 16,
  PROJECT_MANAGER = 32,
  QUALITY_MANAGER = 64,
  QUALITY_ASSURANCE = 128,
  SME = 256,
}

export const INDEXED_SYSTEM_ROLES = {
  [SYSTEM_ROLES_ID.SUPER_ADMIN]: SYSTEM_ROLES.SUPER_ADMIN,
  [SYSTEM_ROLES_ID.CLIENT_ADMIN]: SYSTEM_ROLES.CLIENT_ADMIN,
  [SYSTEM_ROLES_ID.MANAGEMENT]: SYSTEM_ROLES.MANAGEMENT,
  [SYSTEM_ROLES_ID.SYSTEM_OWNER]: SYSTEM_ROLES.SYSTEM_OWNER,
  [SYSTEM_ROLES_ID.PROCESS_OWNER]: SYSTEM_ROLES.PROCESS_OWNER,
  [SYSTEM_ROLES_ID.PROJECT_MANAGER]: SYSTEM_ROLES.PROJECT_MANAGER,
  [SYSTEM_ROLES_ID.QUALITY_MANAGER]: SYSTEM_ROLES.QUALITY_MANAGER,
  [SYSTEM_ROLES_ID.QUALITY_ASSURANCE]: SYSTEM_ROLES.QUALITY_ASSURANCE,
  [SYSTEM_ROLES_ID.SME]: SYSTEM_ROLES.SME,
};

export type SystemRole =
  | SYSTEM_ROLES.SUPER_ADMIN
  | SYSTEM_ROLES.CLIENT_ADMIN
  | SYSTEM_ROLES.MANAGEMENT
  | SYSTEM_ROLES.SYSTEM_OWNER
  | SYSTEM_ROLES.PROCESS_OWNER
  | SYSTEM_ROLES.PROJECT_MANAGER
  | SYSTEM_ROLES.QUALITY_MANAGER
  | SYSTEM_ROLES.QUALITY_ASSURANCE
  | SYSTEM_ROLES.SME;

export type UserSystemRoleId =
  | SYSTEM_ROLES_ID.SUPER_ADMIN
  | SYSTEM_ROLES_ID.CLIENT_ADMIN
  | SYSTEM_ROLES_ID.MANAGEMENT
  | SYSTEM_ROLES_ID.SYSTEM_OWNER
  | SYSTEM_ROLES_ID.PROCESS_OWNER
  | SYSTEM_ROLES_ID.PROJECT_MANAGER
  | SYSTEM_ROLES_ID.QUALITY_MANAGER
  | SYSTEM_ROLES_ID.QUALITY_ASSURANCE
  | SYSTEM_ROLES_ID.SME;
