import { arrayMove } from '@dnd-kit/sortable';
import { createApi } from '@reduxjs/toolkit/query/react';

import { dynamicBaseQuery } from 'core/lib/utils/requesting';
import { cacher } from 'core/lib/utils/rtkQueryCacheUtils';

import endpoints, { TAGS } from './endpoints';
import {
  ArchiveCommonObjectRequest,
  BaseCommonObjectRequest,
  CommonObject,
  CommonObjectConnectedObject,
  CommonObjectOption,
  CommonObjectRequest,
  CreateCommonObjectRequest,
  DeleteCommonObjectRequest,
  OrderCommonObjectRequest,
  UpdateCommonObjectRequest,
} from '../..';

const commonObjectsApi = createApi({
  reducerPath: 'commonObjectsApi',
  baseQuery: dynamicBaseQuery,
  tagTypes: [...cacher.defaultTags, ...Object.values(TAGS)],
  endpoints: (builder) => ({
    getCommonObjects: builder.query<CommonObject[], CommonObjectRequest>({
      query: (params) => ({
        url: endpoints.getCommonObjects.url,
        params,
      }),
      providesTags: cacher.cacheByArg<CommonObjectRequest>(endpoints.getCommonObjects.tag, 'type'),
      transformResponse: endpoints.getCommonObjects.deserializer,
    }),
    getCommonObjectOptions: builder.query<CommonObjectOption[], CommonObjectRequest>({
      query: (params) => ({
        url: endpoints.getCommonObjectOptions.url,
        params,
      }),
      providesTags: cacher.cacheByArg<CommonObjectRequest>(endpoints.getCommonObjectOptions.tag, 'type'),
      transformResponse: endpoints.getCommonObjectOptions.deserializer,
    }),
    getCommonObjectConnectedObjects: builder.query<CommonObjectConnectedObject[], number>({
      query: (id) => `${endpoints.getCommonObjectConnectedObjects.url}/${id}`,
      transformResponse: endpoints.getCommonObjectConnectedObjects.deserializer,
    }),
    createCommonObject: builder.mutation<void, CreateCommonObjectRequest>({
      query: (body) => ({ url: endpoints.createCommonObject.url, method: 'POST', body }),
      async onQueryStarted({ type }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            commonObjectsApi.util.invalidateTags([{ type: endpoints.createCommonObject.invalidates, id: type }])
          );
        } catch {}
      },
    }),
    updateCommonObject: builder.mutation<void, UpdateCommonObjectRequest>({
      query: (body) => ({ url: endpoints.updateCommonObject.url, method: 'POST', body }),
      async onQueryStarted({ type }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            commonObjectsApi.util.invalidateTags([{ type: endpoints.updateCommonObject.invalidates, id: type }])
          );
        } catch {}
      },
    }),
    orderCommonObject: builder.mutation<void, OrderCommonObjectRequest>({
      query: (body) => ({ url: endpoints.orderCommonObject.url, method: 'POST', body }),
      async onQueryStarted({ type, objectType, id, toPosition }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          commonObjectsApi.util.updateQueryData(
            'getCommonObjects',
            { objectType, type, withConnected: true },
            (draft) => {
              const current = draft.find((commonObj) => commonObj.id === id);
              const currentPosition = current?.displayOrder ?? draft.findIndex((commonObj) => commonObj.id === id);
              arrayMove(draft, currentPosition, toPosition).forEach((setting, index) => {
                draft[index] = setting;
                draft[index].displayOrder = index;
              });
            }
          )
        );
        try {
          await queryFulfilled;
          dispatch(
            commonObjectsApi.util.invalidateTags([{ type: endpoints.orderCommonObject.invalidates, id: type }])
          );
        } catch {
          patchResult.undo();
        }
      },
    }),
    resetOrderCommonObject: builder.mutation<void, BaseCommonObjectRequest>({
      query: (body) => ({ url: endpoints.resetOrderCommonObject.url, method: 'POST', body }),
      async onQueryStarted({ type }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            commonObjectsApi.util.invalidateTags([{ type: endpoints.resetOrderCommonObject.invalidates, id: type }])
          );
        } catch {}
      },
    }),
    deleteCommonObject: builder.mutation<void, DeleteCommonObjectRequest>({
      query: ({ id }) => ({ url: `${endpoints.deleteCommonObject.url}/${id}`, method: 'POST' }),
      async onQueryStarted({ type }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            commonObjectsApi.util.invalidateTags([{ type: endpoints.deleteCommonObject.invalidates, id: type }])
          );
        } catch {}
      },
    }),
    archiveCommonObject: builder.mutation<void, ArchiveCommonObjectRequest>({
      query: ({ id }) => ({ url: `${endpoints.archiveCommonObject.url}/${id}`, method: 'POST' }),
      async onQueryStarted({ type }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            commonObjectsApi.util.invalidateTags([{ type: endpoints.archiveCommonObject.invalidates, id: type }])
          );
        } catch {}
      },
    }),
  }),
});

export default commonObjectsApi;
