import React from 'react';

import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Divider, Form, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { useLoadedInFrame } from 'utils/hooks';

import { externalFormConfig } from '../externalFormConfig';

const { Title } = Typography;

const { contactDetails } = externalFormConfig;

type ContactInformationProps = {
  disabled: boolean;
};

const ContactInformationSection = ({ disabled }: ContactInformationProps) => {
  const { t } = useTranslation(['dealbase', 'global']);
  const loadedInFrame = useLoadedInFrame();

  return (
    <div className="flex flex-col max-w-512 m-auto">
      {!loadedInFrame && (
        <>
          <Form.Item
            label={contactDetails.ceo.label}
            name={contactDetails.ceo.dataIndex}
            rules={contactDetails.ceo.rules}
          >
            <contactDetails.ceo.component disabled={disabled} />
          </Form.Item>
          <div className="my-4">
            <Title level={5}>{t('dealbase:form.contactDetails.headers.founders')}</Title>
            <Form.List name="founders">
              {(founders, { add, remove }) => (
                <div className="flex flex-col">
                  {founders.map(({ key, name, ...restField }) => (
                    <div key={key} className="flex flex-col">
                      <div className="flex items-center flex-row gap-x-4">
                        <div className="flex flex-auto sm:flex-row flex-col gap-x-4">
                          <Form.Item
                            {...restField}
                            className="md:w-3/6"
                            label={contactDetails.founderName.label}
                            rules={contactDetails.founderName.rules}
                            name={[name, contactDetails.founderName.dataIndex]}
                          >
                            <contactDetails.founderName.component disabled={disabled} />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            className="md:w-3/6"
                            label={contactDetails.founderJobTitle.label}
                            rules={contactDetails.founderJobTitle.rules}
                            name={[name, contactDetails.founderJobTitle.dataIndex]}
                          >
                            <contactDetails.founderJobTitle.component disabled={disabled} />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            className="md:w-3/6"
                            label={contactDetails.founderLinkedIn.label}
                            rules={contactDetails.founderLinkedIn.rules}
                            name={[name, contactDetails.founderLinkedIn.dataIndex]}
                          >
                            <contactDetails.founderLinkedIn.component disabled={disabled} />
                          </Form.Item>
                        </div>
                        {founders.length > 1 && <MinusCircleOutlined onClick={() => remove(name)} />}
                      </div>
                      {founders.length > 1 && <Divider />}
                    </div>
                  ))}
                  <Form.Item>
                    <Button disabled={disabled} type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                      {t('dealbase:actions.addFounder')}
                    </Button>
                  </Form.Item>
                </div>
              )}
            </Form.List>
          </div>
          <Title level={5}>{t('dealbase:form.contactDetails.headers.contactInfo')}</Title>
        </>
      )}
      <div className="flex sm:flex-row flex-col gap-x-4">
        <Form.Item
          className="md:w-3/6"
          label={contactDetails.contactPerson.label}
          name={contactDetails.contactPerson.dataIndex}
          rules={contactDetails.contactPerson.rules}
        >
          <contactDetails.contactPerson.component disabled={disabled} />
        </Form.Item>
        <Form.Item
          className="md:w-3/6"
          label={contactDetails.contactNumber.label}
          name={contactDetails.contactNumber.dataIndex}
          rules={contactDetails.contactNumber.rules}
        >
          <contactDetails.contactNumber.component disabled={disabled} />
        </Form.Item>
      </div>
      <div className="flex sm:flex-row flex-col gap-x-4">
        <Form.Item
          className="lg:w-3/6 w-full"
          label={contactDetails.contactEmail.label}
          name={contactDetails.contactEmail.dataIndex}
          rules={contactDetails.contactEmail.rules}
        >
          <contactDetails.contactEmail.component disabled={disabled} />
        </Form.Item>
      </div>
    </div>
  );
};

export default ContactInformationSection;
