import React from 'react';

import { MailOutlined, PhoneOutlined } from '@ant-design/icons';
import { Typography } from 'antd';

import colors from 'core/lib/theme/colors';

const { Text } = Typography;

type PopoverBaseContactBookInfoComponentProps = {
  phoneNumber?: string;
  email: string;
};

const PopoverBaseContactBookInfoComponent = ({ phoneNumber, email }: PopoverBaseContactBookInfoComponentProps) => {
  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-row items-center gap-2">
        <MailOutlined style={{ color: colors.Primary.rgba }} />
        <Text copyable>{email}</Text>
      </div>
      {phoneNumber && (
        <div className="flex flex-row items-center gap-2">
          <PhoneOutlined style={{ color: colors.Primary.rgba }} />
          <Text copyable>{phoneNumber}</Text>
        </div>
      )}
    </div>
  );
};

export default PopoverBaseContactBookInfoComponent;
