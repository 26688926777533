import React, { useContext } from 'react';

import { Form } from 'antd';
import { RangeValue } from 'rc-picker/lib/interface';

import { BaseSelectOption, UserDetails, UserOption } from 'core/lib';
import { UserDetailsContext } from 'modules/user/context';
import { userFormConfig } from 'modules/user/form/formConfig';

const {
  generalInformation: { outOfOffice },
} = userFormConfig;

type outOfOfficeProps = {
  editMode: boolean;
};

const OutOfOfficeSection = ({ editMode }: outOfOfficeProps) => {
  const user = useContext(UserDetailsContext) as UserDetails;

  const filterUserOptions = (userOptions: BaseSelectOption[]) => {
    return userOptions.filter((option) => option.value !== user?.id);
  };

  return (
    <div className="flex flex-col">
      <div className="flex sm:flex-row flex-col gap-x-4">
        <Form.Item
          className="md:w-3/6 w-full"
          label={outOfOffice.period.label}
          name={outOfOffice.period.dataIndex}
          rules={outOfOffice.period.rules}
          normalize={(dateRange: RangeValue<Date>) => {
            return { from: dateRange?.[0], to: dateRange?.[1] };
          }}
        >
          <outOfOffice.period.component className="flex" disabled={!editMode} />
        </Form.Item>
        <Form.Item
          className="md:w-3/6 w-full"
          label={outOfOffice.delegate.label}
          name={outOfOffice.delegate.dataIndex}
          valuePropName="selectedOption"
          normalize={(option: UserOption) => {
            return option ? { id: option.value, name: option.label } : null;
          }}
          rules={outOfOffice.delegate.rules}
        >
          <outOfOffice.delegate.component asAvatar allowClear filterFn={filterUserOptions} disabled={!editMode} />
        </Form.Item>
      </div>
    </div>
  );
};

export default OutOfOfficeSection;
