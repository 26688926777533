import React from 'react';

import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';

import { OrganizationOption, UserOption } from 'core/lib';
import { dealBaseFormConfig } from 'modules/dealBase/form/formConfig';

const {
  evaluation: { generalEvaluation },
} = dealBaseFormConfig;

type GeneralEvaluationSectionProps = {
  editMode: boolean;
};

const DealBaseGeneralEvaluationSection = ({ editMode }: GeneralEvaluationSectionProps) => {
  const { t } = useTranslation('dealbase');
  return (
    <div className="flex flex-col">
      <div className="flex sm:flex-row lg:flex-col flex-col gap-x-4">
        <Form.Item
          className="md:w-3/6 lg:w-full"
          label={generalEvaluation.assigned.label}
          name={generalEvaluation.assigned.dataIndex}
          valuePropName="selectedOption"
          normalize={(option: UserOption) => {
            return option ? { id: option.value, name: option.label } : null;
          }}
          rules={generalEvaluation.assigned.rules}
        >
          <generalEvaluation.assigned.component asAvatar allowClear disabled={!editMode} />
        </Form.Item>
        <Form.Item
          className="md:w-3/6 lg:w-full"
          label={generalEvaluation.involved.label}
          name={generalEvaluation.involved.dataIndex}
          valuePropName="selectedOption"
          normalize={(options: UserOption[]) => {
            return options.map((v) => ({ id: v.value, name: v.label }));
          }}
          rules={generalEvaluation.involved.rules}
        >
          <generalEvaluation.involved.component
            avatarProps={{ maxCount: 5 }}
            asAvatar
            allowClear
            disabled={!editMode}
          />
        </Form.Item>
      </div>
      <div className="flex sm:flex-row lg:flex-col flex-col gap-x-4">
        <Form.Item
          className="md:w-3/6 lg:w-full"
          label={generalEvaluation.organization.label}
          name={generalEvaluation.organization.dataIndex}
          valuePropName="selectedOption"
          normalize={(option: OrganizationOption) => {
            return option ? { id: option.value, name: option.label } : null;
          }}
          rules={generalEvaluation.organization.rules}
        >
          <generalEvaluation.organization.component asTag allowClear disabled={!editMode} />
        </Form.Item>
        <Form.Item
          className="md:w-3/6 lg:w-full"
          label={generalEvaluation.timeFrame.label}
          name={generalEvaluation.timeFrame.dataIndex}
          rules={generalEvaluation.timeFrame.rules}
        >
          <generalEvaluation.timeFrame.component disabled={!editMode} />
        </Form.Item>
      </div>
      <div className="flex sm:flex-row lg:flex-col flex-col gap-x-4">
        <Form.Item
          className="md:w-3/6 lg:w-full"
          label={generalEvaluation.nextSteps.label}
          name={generalEvaluation.nextSteps.dataIndex}
          rules={generalEvaluation.nextSteps.rules}
        >
          <generalEvaluation.nextSteps.component disabled={!editMode} />
        </Form.Item>
        <Form.Item
          className="md:w-3/6 lg:w-full"
          label={generalEvaluation.followUpDate.label}
          name={generalEvaluation.followUpDate.dataIndex}
          rules={generalEvaluation.followUpDate.rules}
        >
          <generalEvaluation.followUpDate.component className="flex" disabled={!editMode} />
        </Form.Item>
      </div>
      <div className="flex sm:flex-row lg:flex-col flex-col gap-x-4">
        <Form.Item className="md:w-3/6 lg:w-full" label={t('form.generalEvaluation.labels.sort')} name="sort">
          <Input readOnly />
        </Form.Item>
        <Form.Item
          className="md:w-3/6 lg:w-full"
          label={generalEvaluation.priority.label}
          name={generalEvaluation.priority.dataIndex}
          rules={generalEvaluation.priority.rules}
        >
          <generalEvaluation.priority.component disabled={!editMode} />
        </Form.Item>
      </div>
      <div className="flex sm:flex-row lg:flex-col flex-col w-full md:w-3/6 lg:w-full gap-x-4">
        <Form.Item
          className="db flex-auto"
          label={generalEvaluation.tier.label}
          name={generalEvaluation.tier.dataIndex}
          rules={generalEvaluation.tier.rules}
        >
          <generalEvaluation.tier.component disabled={!editMode} />
        </Form.Item>
      </div>
    </div>
  );
};

export default DealBaseGeneralEvaluationSection;
