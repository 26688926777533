import 'antd/lib/date-picker/style';
import generatePicker, { PickerProps, RangePickerProps } from 'antd/lib/date-picker/generatePicker';
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns';

import { DEFAULT_DATE_FORMAT } from 'core/lib/constants/defaults';

const DatePicker = generatePicker<Date>(dateFnsGenerateConfig);
const defaultDatePickerProps = {
  format: DEFAULT_DATE_FORMAT,
};

DatePicker.defaultProps = defaultDatePickerProps;
DatePicker.RangePicker.defaultProps = defaultDatePickerProps;
DatePicker.defaultProps = defaultDatePickerProps;

export default DatePicker;

export type { PickerProps, RangePickerProps };
