import React from 'react';

import { Input, InputProps } from 'antd';
import Form from 'antd/lib/form';

import { ListingEditableCellProps } from './types';

const ListingText = ({ dataIndex, rules, ...props }: ListingEditableCellProps & InputProps) => {
  return (
    <Form.Item name={dataIndex} className="m-0" rules={rules}>
      <Input {...props} />
    </Form.Item>
  );
};

export default ListingText;
