import React from 'react';

import { List } from 'antd';
import { useTranslation } from 'react-i18next';

import { DateSince } from 'common-ui';
import { UserNotification } from 'core/lib';

import { NotificationCardCommentIcon, NotificationCardWrapper, NotificationTitle } from '../components';

type CommentNotificationCardProps = {
  notification: UserNotification;
  onClick: (id: number) => void;
};

const CommentNotificationCard = ({ notification, onClick }: CommentNotificationCardProps) => {
  const { t } = useTranslation();
  return (
    <NotificationCardWrapper key={notification.id} notification={notification} onClick={onClick}>
      <List.Item.Meta
        avatar={<NotificationCardCommentIcon />}
        title={
          <NotificationTitle title={t(`notifications:${notification.type}.new`)} objectName={notification.objectName} />
        }
        description={<DateSince date={notification.dateCreated} />}
      />
    </NotificationCardWrapper>
  );
};

export default CommentNotificationCard;
