import {
  deserializeModuleConversationsListing,
  deserializeModuleConversationsDetails,
  deserializeMenuModuleConversations,
  deserializeGroupConversationsDetails,
  deserializeConversationMessagesList,
  deserializeConversationMessage,
  deserializeGroupConversationsMenu,
  deserializeDirectConversationsDetails,
  deserializeDirectConversationsMenu,
} from './serializers';
import { deserializeBaseModuleObjectList } from '../../app';
import { deserializeUserShortListResponse } from '../../users';

const API_V2 = 'api/v2';

const CONTROLLER = 'conversations';

const API_URL = {
  GET_MODULE_CONVERSATIONS_LISTING: `${API_V2}/${CONTROLLER}/getModuleConversationListing`,
  GET_MENU_MODULE_CONVERSATIONS: `${API_V2}/${CONTROLLER}/getMyMenuModuleConversations`,
  GET_MODULE_CONVERSATIONS_DETAILS: `${API_V2}/${CONTROLLER}/getModuleConversationDetails`,
  GET_MODULE_CONVERSATIONS_USERS: `${API_V2}/${CONTROLLER}/getModuleConversationUsers`,
  GET_MODULE_CONVERSATIONS_OBJECTS: `${API_V2}/${CONTROLLER}/getModuleConversationObjects`,
  GET_CONVERSATIONS_MESSAGES: `${API_V2}/${CONTROLLER}/getModuleConversationMessages`,
  GET_MENU_GROUP_CONVERSATIONS: `${API_V2}/${CONTROLLER}/getMyMenuGroupConversation`,
  GET_GROUP_CONVERSATIONS_DETAILS: `${API_V2}/${CONTROLLER}/getGroupConversationDetails`,
  GET_MENU_DIRECT_CONVERSATIONS: `${API_V2}/${CONTROLLER}/getMyMenuDirectConversation`,
  GET_DIRECT_CONVERSATIONS_DETAILS: `${API_V2}/${CONTROLLER}/getDirectConversationDetails`,
  GET_MODULE_CONVERSATION_PERMISSION: `${API_V2}/${CONTROLLER}/getModuleConversationPermission`,
  GET_GROUP_CONVERSATION_PERMISSION: `${API_V2}/${CONTROLLER}/getGroupConversationPermission`,
  GET_DIRECT_CONVERSATION_PERMISSION: `${API_V2}/${CONTROLLER}/getDirectConversationPermission`,

  GET_MODULE_CONVERSATION_EXITS: `${API_V2}/${CONTROLLER}/getModuleConversationExists`,

  POST_ADD_USER_TO_CONVERSATION: `${API_V2}/${CONTROLLER}/addUserToConversation`,
  POST_REMOVE_USER_FROM_CONVERSATION: `${API_V2}/${CONTROLLER}/removeUserFromConversation`,

  GET_LEAVE_GROUP_CONVERSATION: `${API_V2}/${CONTROLLER}/leaveGroupConversation`,
  GET_GLOBAL_CONVERSATION_UNREAD_MESSAGES: `${API_V2}/${CONTROLLER}/getHasUnreadMessages`,

  GET_LEAVE_MODULE_CONVERSATION: `${API_V2}/${CONTROLLER}/leaveModuleConversation`,
  GET_JOIN_MODULE_CONVERSATION: `${API_V2}/${CONTROLLER}/joinModuleConversation`,

  POST_CREATE_MODULE_CONVERSATION: `${API_V2}/${CONTROLLER}/createModuleConversation`,
  POST_CREATE_GROUP_CONVERSATION: `${API_V2}/${CONTROLLER}/createGroupConversation`,
  POST_CREATE_DIRECT_CONVERSATION: `${API_V2}/${CONTROLLER}/createDirectConversation`,

  POST_GROUP_CONVERSATION_TITLE: `${API_V2}/${CONTROLLER}/postGroupConversationTitle`,

  POST_CONVERSATION_MESSAGE: `${API_V2}/${CONTROLLER}/postConversationMessage`,
  POST_EDIT_CONVERSATION_MESSAGE: `${API_V2}/${CONTROLLER}/editConversationMessage`,

  DELETE_CONVERSATION_MESSAGE: `${API_V2}/${CONTROLLER}/deleteConversationMessage`,
};

export const TAGS = {
  MODULE_CONVERSATIONS_LISTING: 'ModuleConversations',
  MENU_MODULE_CONVERSATIONS: 'MenuModuleConversations',
  MODULE_CONVERSATION_DETAILS: 'ModuleConversationDetails',
  CONVERSATION_MESSAGES: 'ModuleConversationMessages',
  MODULE_CONVERSATIONS_USERS: 'ModuleConversationUsers',
  MODULE_CONVERSATIONS_OBJECTS: 'ModuleConversationObjects',
  GROUP_CONVERSATION_DETAILS: 'GroupConversationDetails',
  MENU_GROUP_CONVERSATIONS: 'MenuGroupConversations',
  DIRECT_CONVERSATION_DETAILS: 'DirectConversationDetails',
  MENU_DIRECT_CONVERSATIONS: 'MenuDirectConversations',
  MODULE_CONVERSATION_PERMISSION: 'ModuleConversationPermission',
  GROUP_CONVERSATION_PERMISSION: 'GroupConversationPermission',
  DIRECT_CONVERSATION_PERMISSION: 'DirectConversationPermission',
  GLOBAL_CONVERSATION_UNREAD_MESSAGES: 'GlobalConversationUnreadMessages',
};

const endpoints = {
  moduleConversationListing: {
    url: API_URL.GET_MODULE_CONVERSATIONS_LISTING,
    tag: TAGS.MODULE_CONVERSATIONS_LISTING,
    deserializer: deserializeModuleConversationsListing,
  },
  menuModuleConversations: {
    url: API_URL.GET_MENU_MODULE_CONVERSATIONS,
    tag: TAGS.MENU_MODULE_CONVERSATIONS,
    deserializer: deserializeMenuModuleConversations,
  },
  moduleConversationDetails: {
    url: API_URL.GET_MODULE_CONVERSATIONS_DETAILS,
    tag: TAGS.MODULE_CONVERSATION_DETAILS,
    deserializer: deserializeModuleConversationsDetails,
  },
  moduleConversationUsers: {
    url: API_URL.GET_MODULE_CONVERSATIONS_USERS,
    tag: TAGS.MODULE_CONVERSATIONS_USERS,
    deserializer: deserializeUserShortListResponse,
  },
  moduleConversationObjects: {
    url: API_URL.GET_MODULE_CONVERSATIONS_OBJECTS,
    tag: TAGS.MODULE_CONVERSATIONS_OBJECTS,
    deserializer: deserializeBaseModuleObjectList,
  },
  conversationMessages: {
    url: API_URL.GET_CONVERSATIONS_MESSAGES,
    tag: TAGS.CONVERSATION_MESSAGES,
    deserializer: deserializeConversationMessagesList,
  },
  menuGroupConversations: {
    url: API_URL.GET_MENU_GROUP_CONVERSATIONS,
    tag: TAGS.MENU_GROUP_CONVERSATIONS,
    deserializer: deserializeGroupConversationsMenu,
  },
  groupConversationDetails: {
    url: API_URL.GET_GROUP_CONVERSATIONS_DETAILS,
    tag: TAGS.GROUP_CONVERSATION_DETAILS,
    deserializer: deserializeGroupConversationsDetails,
  },
  directConversationDetails: {
    url: API_URL.GET_DIRECT_CONVERSATIONS_DETAILS,
    tag: TAGS.DIRECT_CONVERSATION_DETAILS,
    deserializer: deserializeDirectConversationsDetails,
  },
  menuDirectConversations: {
    url: API_URL.GET_MENU_DIRECT_CONVERSATIONS,
    tag: TAGS.MENU_DIRECT_CONVERSATIONS,
    deserializer: deserializeDirectConversationsMenu,
  },
  addUserToModuleConversation: {
    url: API_URL.POST_ADD_USER_TO_CONVERSATION,
    invalidates: [TAGS.MODULE_CONVERSATIONS_LISTING],
  },
  removeUserFromModuleConversation: {
    url: API_URL.POST_REMOVE_USER_FROM_CONVERSATION,
    invalidates: [TAGS.MODULE_CONVERSATIONS_LISTING],
  },
  addUserToGroupConversation: {
    url: API_URL.POST_ADD_USER_TO_CONVERSATION,
  },
  removeUserFromGroupConversation: {
    url: API_URL.POST_REMOVE_USER_FROM_CONVERSATION,
  },
  leaveModuleConversation: {
    url: API_URL.GET_LEAVE_MODULE_CONVERSATION,
    invalidates: [TAGS.MENU_MODULE_CONVERSATIONS, TAGS.MODULE_CONVERSATIONS_LISTING],
  },
  joinModuleConversation: {
    url: API_URL.GET_JOIN_MODULE_CONVERSATION,
    invalidates: [TAGS.MENU_MODULE_CONVERSATIONS, TAGS.MODULE_CONVERSATIONS_LISTING],
  },
  leaveGroupConversation: {
    url: API_URL.GET_LEAVE_GROUP_CONVERSATION,
    invalidates: [TAGS.MENU_GROUP_CONVERSATIONS],
  },
  createModuleConversation: {
    url: API_URL.POST_CREATE_MODULE_CONVERSATION,
    invalidates: [TAGS.MENU_MODULE_CONVERSATIONS, TAGS.MODULE_CONVERSATIONS_LISTING],
  },
  postConversationMessage: {
    url: API_URL.POST_CONVERSATION_MESSAGE,
    deserializer: deserializeConversationMessage,
  },
  editConversationMessage: {
    url: API_URL.POST_EDIT_CONVERSATION_MESSAGE,
    deserializer: deserializeConversationMessage,
  },
  deleteConversationMessage: {
    url: API_URL.DELETE_CONVERSATION_MESSAGE,
    deserializer: deserializeConversationMessage,
  },
  postGroupConversationTitle: {
    url: API_URL.POST_GROUP_CONVERSATION_TITLE,
  },
  createGroupConversation: {
    url: API_URL.POST_CREATE_GROUP_CONVERSATION,
    invalidates: [TAGS.MENU_GROUP_CONVERSATIONS],
  },
  createDirectConversation: {
    url: API_URL.POST_CREATE_DIRECT_CONVERSATION,
    invalidates: [TAGS.MENU_DIRECT_CONVERSATIONS],
  },
  moduleConversationPermission: {
    url: API_URL.GET_MODULE_CONVERSATION_PERMISSION,
    tag: TAGS.MODULE_CONVERSATION_PERMISSION,
  },
  groupConversationPermission: {
    url: API_URL.GET_GROUP_CONVERSATION_PERMISSION,
    tag: TAGS.GROUP_CONVERSATION_PERMISSION,
  },
  directConversationPermission: {
    url: API_URL.GET_DIRECT_CONVERSATION_PERMISSION,
    tag: TAGS.DIRECT_CONVERSATION_PERMISSION,
  },
  globalConversationUnreadMessages: {
    url: API_URL.GET_GLOBAL_CONVERSATION_UNREAD_MESSAGES,
    tag: TAGS.GLOBAL_CONVERSATION_UNREAD_MESSAGES,
  },
  moduleConversationExists: {
    url: API_URL.GET_MODULE_CONVERSATION_EXITS,
  },
};

export default endpoints;
