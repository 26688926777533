import React, { useState, useEffect, useMemo } from 'react';

import { Button, Form, Typography } from 'antd';
import Table, { ColumnType } from 'antd/lib/table';
import { concat, keyBy, partition, sum } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import AutoSizer from 'react-virtualized-auto-sizer';
import { useVT } from 'virtualizedtableforantd4';

import { EditableCell, EmptyWrapper, PopConfirmCancelWrapper } from 'common-ui';
import { mergeColumnSettings } from 'common-ui/listingConfig';
import { AppState, userApi, ListingConfig, listingConfigApi } from 'core/lib';
import { OBJECT_TYPES } from 'core/lib/constants';
import { UserListing, UserListingUpdate } from 'core/lib/modules/users/entities';
import {
  selectUserFilteredListing,
  selectUserFilteredListingByStatusGroup,
} from 'core/lib/modules/users/store/selectors';
import LoaderWrapper from 'utils/loader-wrapper';

import { columns as rawColumns } from './columns';
import ListingHeader from './listingHeader';
import { ListingContainer } from '../containers';
import ListingFilters from '../filters/filters';
import { getSystemRoleIdFromRoles } from '../utils';

const { Link } = Typography;

const UserListingComponent = () => {
  const [formDirty, setFormDirty] = useState(false);
  const { t } = useTranslation('user');
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState<number | null>(null);

  const [updateListingItem, { isLoading: isSaving, isSuccess: isSavingSuccess }] =
    userApi.useUpdateListingItemMutation();

  const [unlockUser, { isLoading: isUnlocking }] = userApi.useLazyUnlockUserQuery();

  const isEditing = (record: UserListing) => record.id === editingKey;

  const handleValuesChanged = () => {
    setFormDirty(true);
  };

  const edit = (record: UserListing) => {
    form.setFieldsValue({ ...record });
    setEditingKey(record.id!);
  };

  const cancel = () => {
    setEditingKey(null);
    setFormDirty(false);
  };

  useEffect(() => {
    if (isSavingSuccess) {
      setEditingKey(null);
    }
  }, [isSavingSuccess]);

  const { data, isFetching } = userApi.useGetUserListingQuery();

  const save = async (key: React.Key) => {
    const row: UserListingUpdate = await form.validateFields();
    row.id = editingKey!;
    const updatedSystemRole = getSystemRoleIdFromRoles(row.systemRoles);
    row.systemRoleId = updatedSystemRole;
    updateListingItem(row);
  };

  const onUserUnlock = (userId: number) => {
    unlockUser(userId);
  };

  const listingData = useSelector((state: AppState) => selectUserFilteredListing(state, data));

  const listingDataByGroup = useSelector((state: AppState) =>
    selectUserFilteredListingByStatusGroup(state, listingData)
  );

  const { data: listingConfig } = listingConfigApi.useGetListingConfigQuery(OBJECT_TYPES.USER);

  const columns = useMemo(() => {
    const orderedColumns = mergeColumnSettings(rawColumns, keyBy(listingConfig, 'dataIndex')).filter(
      (col) => col.visible
    );

    return concat(...partition(orderedColumns, { pinned: true }));
  }, [listingConfig]);

  const mergedColumns = [
    ...columns.map((col) => {
      if (!col.editable || !col.inputType) {
        return col;
      }

      return {
        ...col,
        fixed: col.pinned && 'left',
        onCell: (record: UserListing) => ({
          record,
          inputType: col.inputType,
          inputProps: col.inputProps,
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing(record),
          isSaving: isSaving,
          rules: col.inputRules,
        }),
      };
    }),
    {
      title: t('global:general.actions'),
      dataIndex: 'actions',
      width: 150,
      render: (_: boolean, record: UserListing) => {
        const editable = isEditing(record);
        return editable ? (
          <div className="flex items-center gap-2">
            <Button type="link" disabled={isSaving || !formDirty} loading={isSaving} onClick={() => save(record.id)}>
              {t('actions:global.save')}
            </Button>
            {!isSaving && (
              <PopConfirmCancelWrapper
                title={t('actions:confirmation.confirmCancel')}
                onConfirm={cancel}
                disabled={!formDirty}
              >
                <Link>{t('actions:global.cancel')}</Link>
              </PopConfirmCancelWrapper>
            )}
          </div>
        ) : (
          <>
            <div className="flex items-center gap-2">
              <Link disabled={editingKey !== null} onClick={() => edit(record)}>
                {t('actions:global.edit')}
              </Link>
              <Button
                type="link"
                loading={isUnlocking}
                disabled={!record.locked}
                onClick={() => onUserUnlock(record.id)}
              >
                <Link>{t('user:actions.unlock')}</Link>
              </Button>
            </div>
          </>
        );
      },
    } as ColumnType<UserListing>,
  ] as (ColumnType<UserListing> & ListingConfig)[];

  const columnsWidth = useMemo(() => sum(columns.map(({ width }) => width).concat(150)), [columns]);

  const [vt, set_components] = useVT(() => ({ scroll: { x: columnsWidth, y: 500 } }), []);

  useEffect(() => {
    set_components({ body: { cell: EditableCell } });
  }, [set_components]);

  return (
    <LoaderWrapper loading={isFetching} message={t('loaders:user.loadingListing')}>
      <ListingContainer count={listingData?.length ?? 0}>
        <ListingFilters />
        <EmptyWrapper description={t('global:results.emptySearch')} empty={!listingData?.length}>
          <AutoSizer disableWidth className="h-full">
            {({ height }) => (
              <Form
                form={form}
                layout="vertical"
                name="userListingForm"
                component={false}
                onValuesChange={handleValuesChanged}
              >
                {vt.body?.cell && (
                  <Table<UserListing>
                    title={() => <ListingHeader data={listingData ?? []} />}
                    className="h-full ant-table-title-padding"
                    rowClassName="ant-design-table-row"
                    rowKey="id"
                    columns={mergedColumns}
                    dataSource={listingDataByGroup}
                    pagination={false}
                    size="middle"
                    scroll={{ y: height - 145, scrollToFirstRowOnChange: true }}
                    components={{ ...vt }}
                  />
                )}
              </Form>
            )}
          </AutoSizer>
        </EmptyWrapper>
      </ListingContainer>
    </LoaderWrapper>
  );
};

export default UserListingComponent;
