import React from 'react';

import { Button } from 'antd';
import Title from 'antd/lib/typography/Title';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import AutoSizer from 'react-virtualized-auto-sizer';

import { error404 } from 'assets';
import { Routes } from 'routes/routes';

const PageNotFound = () => {
  return (
    <div className="h-full">
      <div className="flex flex-auto flex-col lg:flex-row h-full lg:justify-center items-center p-8 gap-8 lg:gap-44">
        <div className="flex flex-col max-w-112">
          <div className="flex flex-col md:gap-6 gap-4">
            <Title className="whitespace-pre m-0 md:text-6xl text-4xl">
              <Trans
                i18nKey="account:title.errorPageTitle"
                components={{ normal: <span className="font-normal md:text-4xl text-2xl" /> }}
              />
            </Title>
            <p className="text-slate-400 m-0">
              <Trans i18nKey="account:text.errorPageText" />
            </p>
            <Link to={Routes.DEFAULT.path}>
              <Button type="primary">
                <Trans i18nKey="account:action.goToHomepage" />
              </Button>
            </Link>
          </div>
        </div>
        <div className="w-full h-full max-w-112">
          <AutoSizer>
            {({ height, width }) => (
              <img className="object-scale-down" src={error404} alt="error404Image" height={height} width={width} />
            )}
          </AutoSizer>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
