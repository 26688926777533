import React from 'react';

import { useMedia } from 'react-use';

import { MyBlueLabelIcon, MyBlueLabelLogo } from 'assets';

const MainLogo = () => {
  const isTablet = useMedia('(max-width: 1023px)');
  return isTablet ? <MyBlueLabelIcon height={42} /> : <MyBlueLabelLogo height={42} width={105} />;
};

export default MainLogo;
