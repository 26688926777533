import React from 'react';

import { Link, LinkProps } from 'react-router-dom';

const WrapInLink = ({
  path,
  children,
  className,
  ...linkProps
}: { path?: string | false; children: React.ReactNode } & Omit<LinkProps, 'to'>) => {
  return path ? (
    <Link {...linkProps} className={className} to={path}>
      {children}
    </Link>
  ) : (
    <div className={className}>{children}</div>
  );
};

export default WrapInLink;
