import React from 'react';

import { Select, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { userApi } from 'core/lib';
import { PortfolioFilters } from 'core/lib/modules/portfolio/entities/filters';
import LoaderWrapper from 'utils/loader-wrapper';

const { Title } = Typography;

type TaskOwnerFilterProps = {
  onChange: (filters: Partial<PortfolioFilters>) => void;
  filters: number[] | undefined;
};

const TaskOwnerFilter = ({ onChange, filters }: TaskOwnerFilterProps) => {
  const { t } = useTranslation();

  const { data: userOptions, isFetching: userOptionsLoading } = userApi.useGetUserOptionsQuery();

  return (
    <div>
      <Title level={5}>{t('portfolio:filters.taskOwner', { count: filters?.length })}</Title>
      <LoaderWrapper loading={userOptionsLoading} message={t('portfolio:filters.loaders.loadingPortfolioFilters')}>
        <Select
          mode="multiple"
          className="w-full"
          allowClear
          showSearch={false}
          options={userOptions}
          value={filters}
          placeholder={t('global:placeholders.selectType', { type: t('portfolio:filters.taskOwner') })}
          onChange={(taskOwner) => onChange({ taskOwner })}
        />
      </LoaderWrapper>
    </div>
  );
};

export default TaskOwnerFilter;
