import React from 'react';

import { Select, SelectProps, TagProps } from 'antd';

import { OrganizationTag } from 'common-ui';
import { OrganizationShort, organizationApi } from 'core/lib';

import { mergeSelectedWithOptions } from './formUtils';

export type OrganizationSelectProps = {
  asTag?: boolean;
  tagProps?: TagProps;
  color?: string;
  selectedOption?: OrganizationShort;
};

const OrganizationSelect = ({
  asTag,
  tagProps = {},
  color,
  selectedOption,
  ...props
}: OrganizationSelectProps & SelectProps<number>) => {
  const [getOrganizationOptions, { data, isFetching }] = organizationApi.useLazyGetUserOrganizationsQuery({
    selectFromResult: ({ data, ...rest }) => {
      const res = mergeSelectedWithOptions<OrganizationShort>(data, selectedOption);
      return { data: res, ...rest };
    },
  });

  const onDropdownVisibleChange = (open: boolean) => {
    if (open) {
      getOrganizationOptions();
    }
  };

  const value = selectedOption?.id;

  if (props.disabled && selectedOption && asTag) {
    return (
      <div className="flex flex-wrap gap-1">
        {asTag && (
          <OrganizationTag
            name={selectedOption.name}
            key={selectedOption.id}
            tagProps={{ className: 'm-0', ...tagProps }}
          />
        )}
      </div>
    );
  }

  return (
    <Select
      showSearch
      allowClear
      optionFilterProp="label"
      loading={isFetching}
      value={value}
      options={data}
      onDropdownVisibleChange={onDropdownVisibleChange}
      {...props}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onChange={(_val, option: any) => {
        props.onChange?.(option, option);
      }}
    />
  );
};

export default OrganizationSelect;
