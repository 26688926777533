import React from 'react';

import { DownOutlined } from '@ant-design/icons';
import { Button, Dropdown } from 'antd';
import { BaseButtonProps } from 'antd/lib/button/button';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { useTranslation } from 'react-i18next';

import { DROPDOWN_TRIGGERS } from 'common-ui';
import { dealBaseApi, DealBase } from 'core/lib';
import { DealBaseStatusType, DEALBASE_STATUSLIST, DEALBASE_STATUS_KEYS } from 'core/lib/constants/statuses/dealbase';

type DealBaseStatusDropdownProps = {
  dealbase: DealBase;
};

const DealBaseStatusDropdown = ({ dealbase, ...props }: DealBaseStatusDropdownProps & BaseButtonProps) => {
  const { t } = useTranslation('dealbase');

  const [updateStatus, { isLoading }] = dealBaseApi.useUpdateDealBaseStatusMutation();

  const handleStatusUpdate = (status: DealBaseStatusType) => {
    if (dealbase) {
      if (dealbase.status !== status) {
        updateStatus({ id: dealbase?.id.toString(), status: status });
      }
    }
  };

  const items: ItemType[] = DEALBASE_STATUSLIST.map((item) => {
    return {
      key: item.key,
      onClick: () => handleStatusUpdate(item.status),
      label: t(`dealbase:status.label.${item.key}`),
    };
  });

  return (
    dealbase && (
      <Dropdown
        trigger={DROPDOWN_TRIGGERS}
        key="statusMenu"
        menu={{ items, defaultSelectedKeys: [DEALBASE_STATUS_KEYS[dealbase.status]] }}
      >
        <Button loading={isLoading} {...props} type="primary">
          {t(`dealbase:status.label.${DEALBASE_STATUS_KEYS[dealbase.status]}`)} <DownOutlined />
        </Button>
      </Dropdown>
    )
  );
};

export default DealBaseStatusDropdown;
