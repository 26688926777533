import React, { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { ListObjectFilter } from 'common-ui/lists';
import { DealBaseListing } from 'core/lib';
import { OBJECT_TYPES } from 'core/lib/constants';

type DealsByIndustryProps = {
  data?: DealBaseListing[];
};

const DealsByIndustry = ({ data }: DealsByIndustryProps) => {
  const { t } = useTranslation();

  const dataByIndustry = useMemo(() => {
    const groupedByIndustry: { [key: string]: DealBaseListing[] } = (data ?? []).reduce((agg, listItem) => {
      if (!listItem.industries?.length) {
        agg[t('global:results.notSpecified')] = (agg[t('global:results.notSpecified')] ?? []).concat(listItem);
      } else {
        listItem.industries.forEach((industry) => {
          agg[industry.name] = (agg[industry.name] ?? []).concat(listItem);
        });
      }

      return agg;
    }, {});

    return groupedByIndustry;
  }, [data, t]);

  return (
    <ListObjectFilter
      placeholder={t('dealbase:placeholders.searchIndustry')}
      data={dataByIndustry}
      title={t('dealbase:dashboards.titles.industry')}
      objectType={OBJECT_TYPES.DEAL_BASE}
    />
  );
};

export default DealsByIndustry;
