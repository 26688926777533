import _ from 'lodash';

import { selectCurrentUser } from 'core/lib';
import { PortfolioStatusType, PORTFOLIO_STATUS } from 'core/lib/constants/statuses/portfolio';
import { UserSystemRoleId, SYSTEM_ROLES_ID } from 'core/lib/constants/systemRoles';
import { PortfolioDetails } from 'core/lib/modules/portfolio';
import store from 'core/lib/store';

type StatusRulesType = {
  [key: number]: {
    mandatoryFields?: string[];
    rolePermissions?: UserSystemRoleId[];
    restoreToPrevious: boolean;
    isGlobal: boolean;
    from?: PortfolioStatusType[];
  };
};

const StatusRules: StatusRulesType = {
  [PORTFOLIO_STATUS.NEW]: {
    from: [],
    mandatoryFields: [],
    rolePermissions: [SYSTEM_ROLES_ID.SUPER_ADMIN, SYSTEM_ROLES_ID.CLIENT_ADMIN, SYSTEM_ROLES_ID.SYSTEM_OWNER],
    restoreToPrevious: false,
    isGlobal: false,
  },
  [PORTFOLIO_STATUS.STARTED]: {
    from: [PORTFOLIO_STATUS.NEW],
    mandatoryFields: [],
    rolePermissions: [SYSTEM_ROLES_ID.SUPER_ADMIN, SYSTEM_ROLES_ID.CLIENT_ADMIN, SYSTEM_ROLES_ID.SYSTEM_OWNER],
    restoreToPrevious: true,
    isGlobal: false,
  },
  [PORTFOLIO_STATUS.COMPLETED]: {
    from: [PORTFOLIO_STATUS.STARTED],
    mandatoryFields: [],
    rolePermissions: [SYSTEM_ROLES_ID.SUPER_ADMIN, SYSTEM_ROLES_ID.CLIENT_ADMIN, SYSTEM_ROLES_ID.SYSTEM_OWNER],
    restoreToPrevious: true,
    isGlobal: false,
  },
  [PORTFOLIO_STATUS.OPERATIONS]: {
    from: [PORTFOLIO_STATUS.COMPLETED],
    mandatoryFields: [],
    rolePermissions: [SYSTEM_ROLES_ID.SUPER_ADMIN, SYSTEM_ROLES_ID.CLIENT_ADMIN, SYSTEM_ROLES_ID.SYSTEM_OWNER],
    restoreToPrevious: true,
    isGlobal: false,
  },
};

export const isNextStatus = (portfolioStatus: PortfolioStatusType, status: PortfolioStatusType) => {
  const { from } = StatusRules[status];
  return from?.includes(portfolioStatus);
};

export const isCurrentStatus = (portfolioStatus: PortfolioStatusType, status: PortfolioStatusType) => {
  return portfolioStatus === status;
};

export const isGlobalStatus = (status: PortfolioStatusType) => {
  const { isGlobal } = StatusRules[status];
  return isGlobal;
};

export const canRestore = (status: PortfolioStatusType) => {
  const { restoreToPrevious } = StatusRules[status];
  return restoreToPrevious;
};

export const hasAllMandatoryFields = (portfolio: PortfolioDetails, status: PortfolioStatusType) => {
  const { mandatoryFields } = StatusRules[status];
  return mandatoryFields?.every((mf) => !_.isNil(portfolio[mf]));
};

export const isChecked = (
  portfolioLastStatus: PortfolioStatusType = 1,
  portfolioStatus: PortfolioStatusType,
  status: PortfolioStatusType
) => {
  const isGlobal = isGlobalStatus(portfolioStatus);
  const bracketStatus = isGlobal && status !== portfolioStatus ? portfolioLastStatus : portfolioStatus;
  return status <= bracketStatus;
};

export const userHasStatusPermission = (status: PortfolioStatusType) => {
  const { rolePermissions } = StatusRules[status];
  const state = store.getState();
  const currentUser = selectCurrentUser(state);

  return (
    !rolePermissions?.length ||
    rolePermissions?.some((role) => currentUser?.systemRoleId && !!((role & currentUser.systemRoleId) === role))
  );
};

export const isEnabled = (portfolio: PortfolioDetails, status: PortfolioStatusType) => {
  return (
    userHasStatusPermission(status) &&
    hasAllMandatoryFields(portfolio, status) &&
    (isNextStatus(portfolio.status, status) || (isCurrentStatus(portfolio.status, status) && canRestore(status)))
  );
};
