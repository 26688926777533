import React, { useMemo } from 'react';

import { Select, SelectProps, Tag } from 'antd';

import { CountryOptions, getCountriesOptionsByRegion } from 'core/lib/constants/selects';

const CountrySelect = ({ region, ...props }: SelectProps<string | string[]> & { region?: string }) => {
  const options = useMemo(() => {
    if (region) {
      return getCountriesOptionsByRegion(region);
    }
    return CountryOptions;
  }, [region]);

  const tags = useMemo(
    () =>
      props.mode === 'multiple' ? (
        <div className="flex flex-wrap gap-y-2 gap-1">
          {(props.value as string[])?.map((country) => (
            <Tag key={country} color="purple">
              {country}
            </Tag>
          ))}
        </div>
      ) : (
        <Tag color="purple">{props.value}</Tag>
      ),
    [props.value, props.mode]
  );

  return props.disabled && props.value?.length ? (
    <>{tags}</>
  ) : (
    <Select options={options} dropdownMatchSelectWidth={150} showSearch {...props} />
  );
};

export default CountrySelect;
