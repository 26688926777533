import { deserializeUserShortResponse } from '../../users/services/serializers';
import {
  Attachment,
  AttachmentResponse,
  BaseComment,
  BaseModuleObject,
  BaseModuleObjectResponse,
  CommentResponse,
  Favorite,
  FavoriteResponse,
  NumberRange,
  NumberRangeResponse,
  ObjectHistory,
  ObjectHistoryResponse,
  SearchResult,
  SearchResultResponse,
  UserComment,
  UserCommentResponse,
  UserFavorite,
  UserFavoriteResponse,
} from '../entities';

export const deserializeSearchResult = (response: SearchResultResponse[]): SearchResult[] => {
  return response.map((res) => ({
    id: res.ID,
    name: res.Name,
    objectType: res.ObjectType,
  }));
};

export const deserializeAttachment = (response: AttachmentResponse): Attachment => {
  return {
    uid: response.ID.toString(),
    id: response.ID,
    name: response.Name,
    dataIndex: response.DataIndex,
  };
};

export const deserializeCommentResponse = (response: CommentResponse): BaseComment => {
  return {
    id: response.ID,
    content: response.Content,
    dateCreated: response.DateCreated,
    dateModified: response.DateModified,
    status: response.Status,
  };
};

export const deserializeUserComment = (response: UserCommentResponse): UserComment => {
  return {
    id: response.ID,
    user: response.User && deserializeUserShortResponse(response.User),
    externalUser: response.ExternalUsername,
    comment: deserializeCommentResponse(response.Comment),
    replies: deserializeUserCommentList(response.Replies),
  };
};

export const deserializeUserCommentList = (response: UserCommentResponse[]): UserComment[] => {
  return response.map(deserializeUserComment);
};

export const deserializeObjectHistory = (response: ObjectHistoryResponse[]): ObjectHistory[] => {
  return response.map((log) => {
    return {
      id: log.ID,
      task: log.Task,
      dateCreated: log.DateCreated,
      user: log.User && deserializeUserShortResponse(log.User),
    };
  });
};

export const deserializeUserFavorite = (response: UserFavoriteResponse[]): UserFavorite[] => {
  return response.map((res) => ({
    id: res.ID,
    objectId: res.ObjectID,
    objectType: res.ObjectType,
    objectName: res.ObjectName,
    objectPrefix: res.ObjectPrefix,
  }));
};

export const deserializeFavorite = (response: FavoriteResponse): Favorite => {
  return {
    id: response?.ID,
    isFavorite: !!response?.IsFavorite,
  };
};

export const deserializeBaseModuleObject = (response: BaseModuleObjectResponse): BaseModuleObject => {
  return {
    id: response.ID,
    title: response.Title,
  };
};

export const deserializeBaseModuleObjectList = (response: BaseModuleObjectResponse[]): BaseModuleObject[] => {
  return response.map(deserializeBaseModuleObject);
};

export const deserializeNumberRange = (response: NumberRangeResponse): NumberRange => {
  return {
    id: response.ID,
    from: response.From,
    to: response.To,
  };
};
