import React, { useCallback, useEffect, useMemo } from 'react';

import { MailOutlined, PhoneOutlined } from '@ant-design/icons';
import { Avatar, AvatarProps, Popover, Typography } from 'antd';
import { generatePath, Link } from 'react-router-dom';

import { baseApi, BaseUser, selectIsAuthenticatedUserAdmin, useAppSelector, userApi } from 'core/lib';
import colors from 'core/lib/theme/colors';
import { CreateDirectConversationButton } from 'modules/conversations/components';
import { Routes } from 'routes/routes';
import LoaderWrapper from 'utils/loader-wrapper';

import ViewUserProfileButton from './viewUserProfileButton';
import CountryTag from '../tags/countryTag';

const { Text, Title } = Typography;

type UserAvatarProps = {
  user: BaseUser;
  active?: boolean;
  iconOnly?: boolean;
  plain?: boolean;
  withLink?: boolean;
  avatarContainerProps?: React.HTMLAttributes<HTMLDivElement>;
};

const contentType = 'data:image/*;base64';

const UserAvatar = ({
  user,
  active,
  iconOnly,
  size = 'default',
  plain = false,
  withLink = true,
  avatarContainerProps = {},
  ...avatarProps
}: UserAvatarProps & AvatarProps) => {
  const isUserAdmin = useAppSelector(selectIsAuthenticatedUserAdmin);
  const [getUserAvatar, { data }] = baseApi.useLazyDownloadBase64Query();
  const [getUserPublicProfile, { data: userPublicProfile, isFetching: loadingPublicProfile }] =
    userApi.useLazyGetUserPublicProfileQuery();

  useEffect(() => {
    if (user.avatarId) {
      getUserAvatar(user.avatarId, true);
    }
  }, [user.avatarId, getUserAvatar]);

  const onUserPopupOpen = (open: boolean) => {
    if (open) {
      getUserPublicProfile(user.id, true);
    }
  };

  const avatarIcon = useMemo(() => {
    const isUserActive = user.active ?? active;
    const userBackgroundColor = isUserActive ? `` : 'bg-neutral-100';
    const userTextColor = isUserActive ? '' : 'text-neutral-500';
    const margin = iconOnly ? 'm-0' : '';
    return (
      <Avatar
        style={data ? {} : { backgroundColor: user.color }}
        className={`inline-flex shrink-0 flex items-center justify-center ${userBackgroundColor} ${userTextColor} ${margin}`}
        size={size}
        {...(data ? { src: `${contentType},${data?.replace(/^[^,]+,/, '')}` } : {})}
        {...avatarProps}
      >
        {`${user.firstName?.charAt(0) ?? ''}${user.lastName?.charAt(0) ?? ''}`}
      </Avatar>
    );
  }, [user, active, size, data, avatarProps, iconOnly]);

  const getUserAvatarRender = useCallback(
    (avatarOnly?: boolean) => {
      return avatarOnly ? (
        avatarIcon
      ) : (
        <div className="flex grow-0 items-center gap-2" {...avatarContainerProps}>
          {avatarIcon}
          {!avatarOnly && <Text ellipsis>{user.name}</Text>}
        </div>
      );
    },
    [user, avatarIcon, avatarContainerProps]
  );

  const wrappedUserAvatar = useMemo(() => {
    if (isUserAdmin && withLink) {
      return (
        <Link
          style={{ display: 'inherit' }}
          className={`${avatarContainerProps.className ?? ''} text-black cursor-pointer`}
          to={generatePath(Routes.USER_DETAILS.path, { id: user.id })}
        >
          {getUserAvatarRender(iconOnly)}
        </Link>
      );
    }
    return getUserAvatarRender(iconOnly);
  }, [getUserAvatarRender, isUserAdmin, avatarContainerProps.className, user.id, iconOnly, withLink]);

  if (user) {
    if (plain) {
      return getUserAvatarRender(iconOnly);
    }

    return (
      <Popover
        arrowPointAtCenter
        onOpenChange={onUserPopupOpen}
        content={
          <LoaderWrapper loading={loadingPublicProfile}>
            {userPublicProfile && (
              <div className="flex flex-col px-1 gap-4">
                {userPublicProfile?.jobTitle && (
                  <div>
                    <Title className="m-0" level={5}>
                      {userPublicProfile.jobTitle}
                    </Title>
                  </div>
                )}
                <div>
                  <CountryTag country={userPublicProfile.country} />
                </div>
                <div className="flex flex-col gap-2">
                  <div className="flex flex-row items-center gap-2">
                    <MailOutlined style={{ color: colors.Primary.rgba }} />
                    <Text copyable>{userPublicProfile.email}</Text>
                  </div>
                  {userPublicProfile.phone && (
                    <div className="flex flex-row items-center gap-2">
                      <PhoneOutlined style={{ color: colors.Primary.rgba }} />
                      <Text copyable>{userPublicProfile.phone}</Text>
                    </div>
                  )}
                </div>
                <div className="flex flex-row gap-2">
                  {user.active && <CreateDirectConversationButton size="small" userId={user.id} />}
                  <ViewUserProfileButton userId={user.id} size="small" type="default" />
                </div>
              </div>
            )}
          </LoaderWrapper>
        }
        title={<div className="px-1 py-3">{getUserAvatarRender(false)}</div>}
        trigger="hover"
      >
        {wrappedUserAvatar}
      </Popover>
    );
  }
  return null;
};

export default UserAvatar;
