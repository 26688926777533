import React, { useEffect } from 'react';

import { Route, Switch, Redirect } from 'react-router-dom';

import { setActiveModule, useAppDispatch } from 'core/lib';
import { OBJECT_TYPES } from 'core/lib/constants';
import { Routes } from 'routes/routes';

import {
  DirectConversationPermissionWrapper,
  ModuleConversationPermissionWrapper,
  GroupConversationPermissionWrapper,
} from './components';
import ListingComponent from './listing';

const Conversations = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setActiveModule(OBJECT_TYPES.MY_CONVERSATIONS));
    return () => {
      dispatch(setActiveModule(null));
    };
  }, [dispatch]);

  return (
    <Switch>
      <Route
        path={Routes.CONVERSATIONS.path}
        exact
        render={() => <Redirect to={Routes.CONVERSATIONS_LISTING.path} />}
      />
      <Route path={Routes.CONVERSATIONS_LISTING.path} exact component={ListingComponent} />
      <Route path={Routes.CONVERSATIONS_MODULE_DETAILS.path} component={ModuleConversationPermissionWrapper} />
      <Route path={Routes.CONVERSATIONS_GROUP_DETAILS.path} component={GroupConversationPermissionWrapper} />
      <Route path={Routes.CONVERSATIONS_DM_DETAILS.path} component={DirectConversationPermissionWrapper} />
    </Switch>
  );
};

export default Conversations;
