import React from 'react';

import { Tag } from 'antd';

import { ContactBookType } from 'core/lib/constants/contactBook';
import { ContactBookTypesByValueKey } from 'core/lib/constants/selects';

import PopoverFieldLabel from './fieldLabel';

type PopoverContactBookTypeProps = {
  type: ContactBookType;
};

const PopoverContactBookType = ({ type }: PopoverContactBookTypeProps) => {
  return (
    <div className="flex flex-col gap-2">
      <PopoverFieldLabel i18nKey="contactBook:shortInfo.labels.type" />
      <div>
        <Tag key={type} color="blue">
          {ContactBookTypesByValueKey[type]}
        </Tag>
      </div>
    </div>
  );
};

export default PopoverContactBookType;
