export const portfolioTabs = {
  generalInformation: 'generalInformation',
  productInformation: 'productInformation',
  activity: 'activity',
};

export const portfolioSections = {
  generalInformation: 'generalInformation',
  ownership: 'ownership',
  supplierInformation: 'supplierInformation',
  compliance: 'compliance',
  classification: 'classification',
  intended: 'intended',
};

export type PortfolioSectionKeys = keyof typeof portfolioSections;

export type PortfolioTabKeys = keyof typeof portfolioTabs;

export const tabSections: { [key in PortfolioTabKeys]: { [key in PortfolioSectionKeys]?: string } } = {
  generalInformation: {
    generalInformation: portfolioSections.generalInformation,
    ownership: portfolioSections.ownership,
    supplierInformation: portfolioSections.supplierInformation,
    compliance: portfolioSections.compliance,
  },
  productInformation: {
    intended: portfolioSections.intended,
    classification: portfolioSections.classification,
  },
  activity: {},
};
