import React, { useMemo } from 'react';

import { InputProps, Typography, Input } from 'antd';

import { validateURL } from 'utils/validators/general';

const { Link } = Typography;

const InputLink = (props: InputProps) => {
  const validUrl = useMemo(() => {
    if ((props.value as string)?.startsWith('https://') || (props.value as string)?.startsWith('http://')) {
      return props.value;
    }
    return `https://${props.value}`;
  }, [props.value]);

  return props.disabled && validateURL(props.value) ? (
    <Link ellipsis href={validUrl as string} target="_blank">
      {props.value}
    </Link>
  ) : (
    <Input {...props} />
  );
};

export default InputLink;
