import React from 'react';

import { PortfolioListing } from 'core/lib';

import {
  PortfoliosByComplianceStatus,
  PortfoliosByOrganization,
  PortfoliosByOwner,
  PortfoliosByResponsible,
  PortfoliosByStatus,
  PortfoliosByTaskOwner,
  PortfoliosByType,
} from './components';
import ListingFilters from '../filters/filters';

type PortfolioDashboardProps = {
  listingData?: PortfolioListing[];
};

const PortfolioDashboard = ({ listingData }: PortfolioDashboardProps) => {
  return (
    <>
      <ListingFilters />
      <div className="grid sm:grid-cols-1 lg:grid-cols-2 gap-8">
        <div className="flex-auto">
          <PortfoliosByStatus data={listingData} />
        </div>
        <div className="flex-auto">
          <PortfoliosByType data={listingData} />
        </div>
      </div>
      <div className="grid sm:grid-cols-1 lg:grid-cols-2 gap-8">
        <div className="flex-auto">
          <PortfoliosByComplianceStatus data={listingData} />
        </div>
        <div className="flex-auto">
          <PortfoliosByOrganization data={listingData} />
        </div>
      </div>
      <div className="grid sm:grid-cols-1 lg:grid-cols-2 gap-8">
        <div className="flex-auto">
          <PortfoliosByResponsible data={listingData} />
        </div>
        <div className="flex-auto">
          <PortfoliosByTaskOwner data={listingData} />
        </div>
      </div>
      <div className="grid sm:grid-cols-1 lg:grid-cols-2 gap-8">
        <div className="flex-auto">
          <PortfoliosByOwner data={listingData} />
        </div>
      </div>
    </>
  );
};

export default PortfolioDashboard;
