import { OBJECT_TYPES } from '../constants';
import { ClientConfiguration } from '../modules/auth/entities';

export function strictWordSearch(search: string, text: string) {
  return text.includes(search.replace(/^=+/, ''));
}

export function fuzzysearch(needle: string, haystack: string, caseSensitive = false) {
  const needleLowered = caseSensitive ? needle : needle.toLowerCase();
  const haystackLowered = caseSensitive ? haystack : haystack.toLowerCase();
  const hlen = haystackLowered.length;
  const nlen = needleLowered.length;
  if (nlen > hlen) {
    return false;
  }
  if (nlen === hlen) {
    return needleLowered === haystackLowered;
  }
  /* eslint-disable */
  outer: for (let i = 0, j = 0; i < nlen; i += 1) {
    const nch = needleLowered.charCodeAt(i);
    while (j < hlen) {
      if (haystackLowered.charCodeAt(j++) === nch) {
        continue outer;
      }
    }
    return false;
  }
  /* eslint-enable */
  return true;
}

export function fuzzyWordsSearch(rawNeedle: string, haystack: string, caseSensitive = false) {
  if (rawNeedle?.startsWith('=')) {
    return strictWordSearch(rawNeedle, haystack);
  }
  const haystacks = haystack.split(/\s/);
  const excludeNeedles = rawNeedle
    .split(/\s/)
    .filter((n) => n)
    .filter((n) => n.startsWith('!'))
    .map((n) => n.slice(1));
  const includeNeedles = rawNeedle
    .split(/\s/)
    .filter((n) => n)
    .filter((n) => !n.startsWith('!'));

  // eslint-disable-next-line no-restricted-syntax
  for (const needle of excludeNeedles) {
    if (new RegExp(needle, 'gi').test(haystack)) return false;
  }

  const hlen = haystacks.length;
  const nlen = includeNeedles.length;
  if (nlen > hlen) {
    return false;
  }
  /* eslint-disable */
  outer: for (let i = 0, j = 0; i < nlen; i += 1) {
    const n = includeNeedles[i];
    while (j < hlen) {
      if (fuzzysearch(n, haystacks[j++], caseSensitive)) {
        continue outer;
      }
    }
    return false;
  }
  /* eslint-enable */
  return true;
}

export const parseJSONDate = (date?: string | null | number | Date) => {
  if (typeof date === 'string') {
    // "/Date(123215123+000)/"
    return new Date(parseInt(date.match(/\d+/)?.[0] ?? ''));
  } else {
    return date ? new Date(date) : null;
  }
};

export const getClientDomainName = () => {
  return process.env.REACT_APP_SUBDOMAIN ?? window.location.hostname.split('.')[0];
};

export const userHasModuleAccess = (module: number, clientConfiguration?: Partial<ClientConfiguration> | null) => {
  if (!clientConfiguration) {
    return true;
  }
  switch (module) {
    case OBJECT_TYPES.DEAL_BASE:
      return clientConfiguration.dealBaseEnabled;
    case OBJECT_TYPES.PORTFOLIO:
      return clientConfiguration.portfolioEnabled;
    case OBJECT_TYPES.CONTACT_BOOK:
      return clientConfiguration.contactBookEnabled;
    default:
      return true;
  }
};
