import React, { useMemo } from 'react';

import { groupBy } from 'lodash';
import { useTranslation } from 'react-i18next';

import { ListObjectFilter } from 'common-ui/lists';
import { DealBaseListing } from 'core/lib';
import { OBJECT_TYPES } from 'core/lib/constants';

type DealsByCountryProps = {
  data?: DealBaseListing[];
};

const DealsByCountry = ({ data }: DealsByCountryProps) => {
  const { t } = useTranslation();

  const dataByCountry = useMemo(() => groupBy(data, (d) => d.country ?? t('global:results.notSpecified')), [data, t]);

  return (
    <ListObjectFilter
      placeholder={t('dealbase:placeholders.searchCountry')}
      data={dataByCountry}
      title={t('dealbase:dashboards.titles.country')}
      objectType={OBJECT_TYPES.DEAL_BASE}
    />
  );
};

export default DealsByCountry;
