const countries = [
  { region: 'Africa', country: 'Algeria', code2: 'DZ', code3: 'DZA' },
  { region: 'Africa', country: 'Egypt', code2: 'EG', code3: 'EGY' },
  { region: 'Africa', country: 'Libya', code2: 'LY', code3: 'LBY' },
  { region: 'Africa', country: 'Morocco', code2: 'MA', code3: 'MAR' },
  { region: 'Africa', country: 'Sudan', code2: 'SD', code3: 'SDN' },
  { region: 'Africa', country: 'Tunisia', code2: 'TN', code3: 'TUN' },
  { region: 'Africa', country: 'Western Sahara', code2: 'EH', code3: 'ESH' },
  { region: 'Africa', country: 'British Indian Ocean Territory', code2: 'IO', code3: 'IOT' },
  { region: 'Africa', country: 'Burundi', code2: 'BI', code3: 'BDI' },
  { region: 'Africa', country: 'Comoros', code2: 'KM', code3: 'COM' },
  { region: 'Africa', country: 'Djibouti', code2: 'DJ', code3: 'DJI	' },
  { region: 'Africa', country: 'Eritrea', code2: 'ER', code3: 'ERI	' },
  { region: 'Africa', country: 'Ethiopia', code2: 'ET', code3: 'ETH' },
  { region: 'Africa', country: 'French Southern Territories', code2: 'TF', code3: 'ATF' },
  { region: 'Africa', country: 'Kenya', code2: 'KE', code3: 'KEN' },
  { region: 'Africa', country: 'Madagascar', code2: 'MG', code3: 'MDG' },
  { region: 'Africa', country: 'Malawi', code2: 'MW', code3: 'MWI' },
  { region: 'Africa', country: 'Mauritius', code2: 'MU', code3: 'MUS' },
  { region: 'Africa', country: 'Mayotte', code2: 'YT', code3: 'MYT' },
  { region: 'Africa', country: 'Mozambique', code2: 'MZ', code3: 'MOZ' },
  { region: 'Africa', country: 'Réunion', code2: 'RE', code3: 'REU' },
  { region: 'Africa', country: 'Rwanda', code2: 'RW', code3: 'RWA' },
  { region: 'Africa', country: 'Seychelles', code2: 'SC', code3: 'SYC' },
  { region: 'Africa', country: 'Somalia', code2: 'SO', code3: 'SOM' },
  { region: 'Africa', country: 'South Sudan', code2: 'SS', code3: 'SSD' },
  { region: 'Africa', country: 'Uganda', code2: 'UG', code3: 'UGA' },
  { region: 'Africa', country: 'United Republic of Tanzania', code2: 'TZ', code3: 'TZA' },
  { region: 'Africa', country: 'Zambia', code2: 'ZM', code3: 'ZMB' },
  { region: 'Africa', country: 'Zimbabwe', code2: 'ZW', code3: 'ZWE' },
  { region: 'Africa', country: 'Angola', code2: 'AO', code3: 'AGO' },
  { region: 'Africa', country: 'Cameroon', code2: 'CM', code3: 'CMR' },
  { region: 'Africa', country: 'Central African Republic', code2: 'CF', code3: 'CAF' },
  { region: 'Africa', country: 'Chad', code2: 'TD', code3: 'TCD' },
  { region: 'Africa', country: 'Congo', code2: 'CG', code3: 'COG' },
  { region: 'Africa', country: 'Democratic Republic of the Congo', code2: 'CD', code3: 'COD' },
  { region: 'Africa', country: 'Equatorial Guinea', code2: 'GQ', code3: 'GNQ' },
  { region: 'Africa', country: 'Gabon', code2: 'GA', code3: 'GAB' },
  { region: 'Africa', country: 'Sao Tome and Principe', code2: 'ST', code3: 'STP' },
  { region: 'Africa', country: 'Botswana', code2: 'BW', code3: 'BWA' },
  { region: 'Africa', country: 'Eswatini', code2: 'SZ', code3: 'SWZ' },
  { region: 'Africa', country: 'Lesotho', code2: 'LS', code3: 'LSO' },
  { region: 'Africa', country: 'Namibia', code2: 'NA', code3: 'NAM' },
  { region: 'Africa', country: 'South Africa', code2: 'ZA', code3: 'ZAF' },
  { region: 'Africa', country: 'Benin', code2: 'BJ', code3: 'BEN' },
  { region: 'Africa', country: 'Burkina Faso', code2: 'BF', code3: 'BFA' },
  { region: 'Africa', country: 'Cabo Verde', code2: 'CV', code3: 'CPV' },
  { region: 'Africa', country: 'Côte d’Ivoire', code2: 'CI', code3: 'CIV' },
  { region: 'Africa', country: 'Gambia', code2: 'GM', code3: 'GMB' },
  { region: 'Africa', country: 'Ghana', code2: 'GH', code3: 'GHA' },
  { region: 'Africa', country: 'Guinea', code2: 'GN', code3: 'GIN' },
  { region: 'Africa', country: 'Guinea-Bissau', code2: 'GW', code3: 'GNB' },
  { region: 'Africa', country: 'Liberia', code2: 'LR', code3: 'LBR' },
  { region: 'Africa', country: 'Mali', code2: 'ML', code3: 'MLI' },
  { region: 'Africa', country: 'Mauritania', code2: 'MR', code3: 'MRT' },
  { region: 'Africa', country: 'Niger', code2: 'NE', code3: 'NER' },
  { region: 'Africa', country: 'Nigeria', code2: 'NG', code3: 'NGA' },
  { region: 'Africa', country: 'Saint Helena', code2: 'SH', code3: 'SHN' },
  { region: 'Africa', country: 'Senegal', code2: 'SN', code3: 'SEN' },
  { region: 'Africa', country: 'Sierra Leone', code2: 'SL', code3: 'SLE' },
  { region: 'Africa', country: 'Togo', code2: 'TG', code3: 'TGO' },
  { region: 'South America', country: 'Anguilla', code2: 'AI', code3: 'AIA' },
  { region: 'South America', country: 'Antigua and Barbuda', code2: 'AG', code3: 'ATG' },
  { region: 'South America', country: 'Aruba', code2: 'AW', code3: 'ABW' },
  { region: 'South America', country: 'Bahamas', code2: 'BS', code3: 'BHS' },
  { region: 'South America', country: 'Barbados', code2: 'BB', code3: 'BRB' },
  { region: 'South America', country: 'Bonaire, Sint Eustatius and Saba', code2: 'BQ', code3: 'BES' },
  { region: 'South America', country: 'British Virgin Islands', code2: 'VG', code3: 'VGB' },
  { region: 'South America', country: 'Cayman Islands', code2: 'KY', code3: 'CYM' },
  { region: 'South America', country: 'Cuba', code2: 'CU', code3: 'CUB' },
  { region: 'South America', country: 'Curaçao', code2: 'CW', code3: 'CUW' },
  { region: 'South America', country: 'Dominica', code2: 'DM', code3: 'DMA' },
  { region: 'South America', country: 'Dominican Republic', code2: 'DO', code3: 'DOM' },
  { region: 'South America', country: 'Grenada', code2: 'GD', code3: 'GRD' },
  { region: 'South America', country: 'Guadeloupe', code2: 'GP', code3: 'GLP' },
  { region: 'South America', country: 'Haiti', code2: 'HT', code3: 'HTI' },
  { region: 'South America', country: 'Jamaica', code2: 'JM', code3: 'JAM' },
  { region: 'South America', country: 'Martinique', code2: 'MQ', code3: 'MTQ' },
  { region: 'South America', country: 'Montserrat', code2: 'MS', code3: 'MSR' },
  { region: 'South America', country: 'Puerto Rico', code2: 'PR', code3: 'PRI' },
  { region: 'South America', country: 'Saint Barthélemy', code2: 'BL', code3: 'BLM' },
  { region: 'South America', country: 'Saint Kitts and Nevis', code2: 'KN', code3: 'KNA' },
  { region: 'South America', country: 'Saint Lucia', code2: 'LC', code3: 'LCA' },
  { region: 'South America', country: 'Saint Martin (French Part)', code2: 'MF', code3: 'MAF' },
  { region: 'South America', country: 'Saint Vincent and the Grenadines', code2: 'VC', code3: 'VCT' },
  { region: 'South America', country: 'Sint Maarten (Dutch part)', code2: 'SX', code3: 'SXM' },
  { region: 'South America', country: 'Trinidad and Tobago', code2: 'TT', code3: 'TTO' },
  { region: 'South America', country: 'Turks and Caicos Islands', code2: 'TC', code3: 'TCA' },
  { region: 'South America', country: 'United States Virgin Islands', code2: 'VI', code3: 'VIR' },
  { region: 'South America', country: 'Belize', code2: 'BZ', code3: 'BLZ' },
  { region: 'South America', country: 'Costa Rica', code2: 'CR', code3: 'CRI' },
  { region: 'South America', country: 'El Salvador', code2: 'SV', code3: 'SLV' },
  { region: 'South America', country: 'Guatemala', code2: 'GT', code3: 'GTM' },
  { region: 'South America', country: 'Honduras', code2: 'HN', code3: 'HND' },
  { region: 'South America', country: 'Mexico', code2: 'MX', code3: 'MEX' },
  { region: 'South America', country: 'Nicaragua', code2: 'NI', code3: 'NIC' },
  { region: 'South America', country: 'Panama', code2: 'PA', code3: 'PAN' },
  { region: 'South America', country: 'Argentina', code2: 'AR', code3: 'ARG' },
  { region: 'South America', country: 'Bolivia (Plurinational State of)', code2: 'BO', code3: 'BOL' },
  { region: 'South America', country: 'Bouvet Island', code2: 'BV', code3: 'BVT' },
  { region: 'South America', country: 'Brazil', code2: 'BR', code3: 'BRA' },
  { region: 'South America', country: 'Chile', code2: 'CL', code3: 'CHL' },
  { region: 'South America', country: 'Colombia', code2: 'CO', code3: 'COL' },
  { region: 'South America', country: 'Ecuador', code2: 'EC', code3: 'ECU' },
  { region: 'South America', country: 'Falkland Islands (Malvinas)', code2: 'FK', code3: 'FLK' },
  { region: 'South America', country: 'French Guiana', code2: 'GF', code3: 'GUF' },
  { region: 'South America', country: 'Guyana', code2: 'GY', code3: 'GUY' },
  { region: 'South America', country: 'Paraguay', code2: 'PY', code3: 'PRY' },
  { region: 'South America', country: 'Peru', code2: 'PE', code3: 'PER' },
  { region: 'South America', country: 'South Georgia and the South Sandwich Islands', code2: 'GS', code3: 'SGS' },
  { region: 'South America', country: 'Suriname', code2: 'SR', code3: 'SUR' },
  { region: 'South America', country: 'Uruguay', code2: 'UY', code3: 'URY' },
  { region: 'South America', country: 'Venezuela (Bolivarian Republic of)', code2: 'VE', code3: 'VEN' },
  { region: 'North America', country: 'Bermuda', code2: 'BM', code3: 'BMU' },
  { region: 'North America', country: 'Canada', code2: 'CA', code3: 'CAN' },
  { region: 'North America', country: 'Greenland', code2: 'GL', code3: 'GRL' },
  { region: 'North America', country: 'Saint Pierre and Miquelon', code2: 'PM', code3: 'SPM' },
  { region: 'North America', country: 'United States', code2: 'US', code3: 'USA' },
  { region: 'Asia', country: 'Kazakhstan', code2: 'KZ', code3: 'KAZ' },
  { region: 'Asia', country: 'Kyrgyzstan', code2: 'KG', code3: 'KGZ' },
  { region: 'Asia', country: 'Tajikistan', code2: 'TJ', code3: 'TJK' },
  { region: 'Asia', country: 'Turkmenistan', code2: 'TM', code3: 'TKM' },
  { region: 'Asia', country: 'Uzbekistan', code2: 'UZ', code3: 'UZB' },
  { region: 'Asia', country: 'China', code2: 'CN', code3: 'CHN' },
  { region: 'Asia', country: 'China, Hong Kong Special Administrative Region', code2: 'HK', code3: 'HKG' },
  { region: 'Asia', country: 'China, Macao Special Administrative Region', code2: 'MO', code3: 'MAC' },
  { region: 'Asia', country: `Democratic People's Republic of Korea`, code2: 'KP', code3: 'PRK' },
  { region: 'Asia', country: 'Japan', code2: 'JP', code3: 'JPN' },
  { region: 'Asia', country: 'Mongolia', code2: 'MN', code3: 'MNG' },
  { region: 'Asia', country: 'Republic of Korea', code2: 'KR', code3: 'KOR' },
  { region: 'Asia', country: 'Brunei Darussalam', code2: 'BN', code3: 'BRN' },
  { region: 'Asia', country: 'Cambodia', code2: 'KH', code3: 'KHM' },
  { region: 'Asia', country: 'Indonesia', code2: 'ID', code3: 'IDN' },
  { region: 'Asia', country: `Lao People's Democratic Republic`, code2: 'LA', code3: 'LAO' },
  { region: 'Asia', country: 'Malaysia', code2: 'MY', code3: 'MYS' },
  { region: 'Asia', country: 'Myanmar', code2: 'MM', code3: 'MMR' },
  { region: 'Asia', country: 'Philippines', code2: 'PH', code3: 'PHL' },
  { region: 'Asia', country: 'Singapore', code2: 'SG', code3: 'SGP' },
  { region: 'Asia', country: 'Thailand', code2: 'TH', code3: 'THA' },
  { region: 'Asia', country: 'Timor-Leste', code2: 'TL', code3: 'TLS' },
  { region: 'Asia', country: 'Viet Nam', code2: 'VN', code3: 'VNM' },
  { region: 'Asia', country: 'Afghanistan', code2: 'AF', code3: 'AFG' },
  { region: 'Asia', country: 'Bangladesh', code2: 'BD', code3: 'BGD' },
  { region: 'Asia', country: 'Bhutan', code2: 'BT', code3: 'BTN' },
  { region: 'Asia', country: 'India', code2: 'IN', code3: 'IND' },
  { region: 'Asia', country: 'Iran (Islamic Republic of)', code2: 'IR', code3: 'IRN' },
  { region: 'Asia', country: 'Maldives', code2: 'MV', code3: 'MDV' },
  { region: 'Asia', country: 'Nepal', code2: 'NP', code3: 'NPL' },
  { region: 'Asia', country: 'Pakistan', code2: 'PK', code3: 'PAK' },
  { region: 'Asia', country: 'Sri Lanka', code2: 'LK', code3: 'LKA' },
  { region: 'Asia', country: 'Armenia', code2: 'AM', code3: 'ARM' },
  { region: 'Asia', country: 'Azerbaijan', code2: 'AZ', code3: 'AZE' },
  { region: 'Asia', country: 'Bahrain', code2: 'BH', code3: 'BHR' },
  { region: 'Asia', country: 'Cyprus', code2: 'CY', code3: 'CYP' },
  { region: 'Asia', country: 'Georgia', code2: 'GE', code3: 'GEO' },
  { region: 'Asia', country: 'Iraq', code2: 'IQ', code3: 'IRQ' },
  { region: 'Asia', country: 'Israel', code2: 'IL', code3: 'ISR' },
  { region: 'Asia', country: 'Jordan', code2: 'JO', code3: 'JOR' },
  { region: 'Asia', country: 'Kuwait', code2: 'KW', code3: 'KWT' },
  { region: 'Asia', country: 'Lebanon', code2: 'LB', code3: 'LBN' },
  { region: 'Asia', country: 'Oman', code2: 'OM', code3: 'OMN' },
  { region: 'Asia', country: 'Qatar', code2: 'QA', code3: 'QAT' },
  { region: 'Asia', country: 'Saudi Arabia', code2: 'SA', code3: 'SAU' },
  { region: 'Asia', country: 'State of Palestine', code2: 'PS', code3: 'PSE' },
  { region: 'Asia', country: 'Syrian Arab Republic', code2: 'SY', code3: 'SYR' },
  { region: 'Asia', country: 'Türkiye', code2: 'TR', code3: 'TUR' },
  { region: 'Asia', country: 'United Arab Emirates', code2: 'AE', code3: 'ARE' },
  { region: 'Asia', country: 'Yemen', code2: 'YE', code3: 'YEM' },
  { region: 'Europe', country: 'Belarus', code2: 'BY', code3: 'BLR' },
  { region: 'Europe', country: 'Bulgaria', code2: 'BG', code3: 'BGR' },
  { region: 'Europe', country: 'Czechia', code2: 'CZ', code3: 'CZE' },
  { region: 'Europe', country: 'Hungary', code2: 'HU', code3: 'HUN' },
  { region: 'Europe', country: 'Poland', code2: 'PL', code3: 'POL' },
  { region: 'Europe', country: 'Republic of Moldova', code2: 'MD', code3: 'MDA' },
  { region: 'Europe', country: 'Romania', code2: 'RO', code3: 'ROU' },
  { region: 'Europe', country: 'Russian Federation', code2: 'RU', code3: 'RUS' },
  { region: 'Europe', country: 'Slovakia', code2: 'SK', code3: 'SVK' },
  { region: 'Europe', country: 'Ukraine', code2: 'UA', code3: 'UKR' },
  { region: 'Europe', country: 'Åland Islands', code2: 'AX', code3: 'ALA' },
  { region: 'Europe', country: 'Channel Islands	Guernsey', code2: 'GG', code3: 'GGY' },
  { region: 'Europe', country: 'Channel Islands	Jersey', code2: 'JE', code3: 'JEY' },
  { region: 'Europe', country: 'Channel', code2: 'Sark', code3: '680' },
  { region: 'Europe', country: 'Denmark', code2: 'DK', code3: 'DNK' },
  { region: 'Europe', country: 'Estonia', code2: 'EE', code3: 'EST' },
  { region: 'Europe', country: 'Faroe Islands', code2: 'FO', code3: 'FRO' },
  { region: 'Europe', country: 'Finland', code2: 'FI', code3: 'FIN' },
  { region: 'Europe', country: 'Iceland', code2: 'IS', code3: 'ISL' },
  { region: 'Europe', country: 'Ireland', code2: 'IE', code3: 'IRL' },
  { region: 'Europe', country: 'Isle of Man', code2: 'IM', code3: 'IMN' },
  { region: 'Europe', country: 'Latvia', code2: 'LV', code3: 'LVA' },
  { region: 'Europe', country: 'Lithuania', code2: 'LT', code3: 'LTU' },
  { region: 'Europe', country: 'Norway', code2: 'NO', code3: 'NOR' },
  { region: 'Europe', country: 'Svalbard and Jan Mayen Islands', code2: 'SJ', code3: 'SJM' },
  { region: 'Europe', country: 'Sweden', code2: 'SE', code3: 'SWE' },
  { region: 'Europe', country: 'United Kingdom', code2: 'GB', code3: 'GBR' },
  { region: 'Europe', country: 'Albania', code2: 'AL', code3: 'ALB' },
  { region: 'Europe', country: 'Andorra', code2: 'AD', code3: 'AND' },
  { region: 'Europe', country: 'Bosnia and Herzegovina', code2: 'BA', code3: 'BIH' },
  { region: 'Europe', country: 'Croatia', code2: 'HR', code3: 'HRV' },
  { region: 'Europe', country: 'Gibraltar', code2: 'GI', code3: 'GIB' },
  { region: 'Europe', country: 'Greece', code2: 'GR', code3: 'GRC' },
  { region: 'Europe', country: 'Holy See', code2: 'VA', code3: 'VAT' },
  { region: 'Europe', country: 'Italy', code2: 'IT', code3: 'ITA' },
  { region: 'Europe', country: 'Malta', code2: 'MT', code3: 'MLT' },
  { region: 'Europe', country: 'Montenegro', code2: 'ME', code3: 'MNE' },
  { region: 'Europe', country: 'North Macedonia', code2: 'MK', code3: 'MKD' },
  { region: 'Europe', country: 'Portugal', code2: 'PT', code3: 'PRT' },
  { region: 'Europe', country: 'San Marino', code2: 'SM', code3: 'SMR' },
  { region: 'Europe', country: 'Serbia', code2: 'RS', code3: 'SRB' },
  { region: 'Europe', country: 'Slovenia', code2: 'SI', code3: 'SVN' },
  { region: 'Europe', country: 'Spain', code2: 'ES', code3: 'ESP' },
  { region: 'Europe', country: 'Austria', code2: 'AT', code3: 'AUT' },
  { region: 'Europe', country: 'Belgium', code2: 'BE', code3: 'BEL' },
  { region: 'Europe', country: 'France', code2: 'FR', code3: 'FRA' },
  { region: 'Europe', country: 'Germany', code2: 'DE', code3: 'DEU' },
  { region: 'Europe', country: 'Liechtenstein', code2: 'LI', code3: 'LIE' },
  { region: 'Europe', country: 'Luxembourg', code2: 'LU', code3: 'LUX' },
  { region: 'Europe', country: 'Monaco', code2: 'MC', code3: 'MCO' },
  { region: 'Europe', country: 'Netherlands', code2: 'NL', code3: 'NLD' },
  { region: 'Europe', country: 'Switzerland', code2: 'CH', code3: 'CHE' },
  { region: 'Oceania', country: 'Australia', code2: 'AU', code3: 'AUS' },
  { region: 'Oceania', country: 'Christmas Island', code2: 'CX', code3: 'CXR' },
  { region: 'Oceania', country: 'Cocos (Keeling) Islands', code2: 'CC', code3: 'CCK' },
  { region: 'Oceania', country: 'Heard Island and McDonald Islands', code2: 'HM', code3: 'HMD' },
  { region: 'Oceania', country: 'New Zealand', code2: 'NZ', code3: 'NZL' },
  { region: 'Oceania', country: 'Norfolk Island', code2: 'NF', code3: 'NFK' },
  { region: 'Oceania', country: 'Fiji', code2: 'FJ', code3: 'FJI' },
  { region: 'Oceania', country: 'New Caledonia', code2: 'NC', code3: 'NCL' },
  { region: 'Oceania', country: 'Papua New Guinea', code2: 'PG', code3: 'PNG' },
  { region: 'Oceania', country: 'Solomon Islands', code2: 'SB', code3: 'SLB' },
  { region: 'Oceania', country: 'Vanuatu', code2: 'VU', code3: 'VUT' },
  { region: 'Oceania', country: 'Guam', code2: 'GU', code3: 'GUM' },
  { region: 'Oceania', country: 'Kiribati', code2: 'KI', code3: 'KIR' },
  { region: 'Oceania', country: 'Marshall Islands', code2: 'MH', code3: 'MHL' },
  { region: 'Oceania', country: 'Micronesia (Federated States of', code2: 'FM', code3: 'FSM' },
  { region: 'Oceania', country: 'Nauru', code2: 'NR', code3: 'NRU' },
  { region: 'Oceania', country: 'Northern Mariana Islands', code2: 'MP', code3: 'MNP' },
  { region: 'Oceania', country: 'Palau', code2: 'PW', code3: 'PLW' },
  { region: 'Oceania', country: 'United States Minor Outlying Islands', code2: 'UM', code3: 'UMI' },
  { region: 'Oceania', country: 'American Samoa', code2: 'AS', code3: 'ASM' },
  { region: 'Oceania', country: 'Cook Islands', code2: 'CK', code3: 'COK' },
  { region: 'Oceania', country: 'French Polynesia', code2: 'PF', code3: 'PYF' },
  { region: 'Oceania', country: 'Niue', code2: 'NU', code3: 'NIU' },
  { region: 'Oceania', country: 'Pitcairn', code2: 'PN', code3: 'PCN' },
  { region: 'Oceania', country: 'Samoa', code2: 'WS', code3: 'WSM' },
  { region: 'Oceania', country: 'Tokelau', code2: 'TK', code3: 'TKL' },
  { region: 'Oceania', country: 'Tonga', code2: 'TO', code3: 'TON' },
  { region: 'Oceania', country: 'Tuvalu', code2: 'TV', code3: 'TUV' },
  { region: 'Oceania', country: 'Wallis and Futuna Islands', code2: 'WF', code3: 'WLF' },
];

export default countries;
