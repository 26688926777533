import React from 'react';

import { Form, SelectProps } from 'antd';

import { CommonObjectOption } from 'core/lib';
import CommonObjectSelect, { CommonObjectSelectProps } from 'utils/form/commonObjectSelect';

import { ListingEditableCellProps } from './types';

function ListingCommonObjectMultiSelect({
  dataIndex,
  rules,
  isDisabled,
  ...props
}: ListingEditableCellProps & CommonObjectSelectProps & SelectProps<number>) {
  const form = Form.useFormInstance();

  const disabled = props.disabled || (isDisabled && isDisabled(form));

  return (
    <Form.Item
      hasFeedback
      rules={rules}
      name={dataIndex}
      className="m-0"
      valuePropName="selectedOption"
      normalize={(option?: CommonObjectOption) => {
        return option ? { id: option.value, name: option.label } : null;
      }}
    >
      <CommonObjectSelect {...props} disabled={disabled} />
    </Form.Item>
  );
}

export default ListingCommonObjectMultiSelect;
