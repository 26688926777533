import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { DownOutlined } from '@ant-design/icons';
import { Button, Checkbox, Dropdown } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { intersection } from 'lodash';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';

import { DROPDOWN_TRIGGERS, FilterActions } from 'common-ui';
import { AppState, userApi } from 'core/lib';
import { selectDealBaseAssignedUserFilters, setDealBaseFilters } from 'core/lib/modules/dealBase/store';

const mapState = (state: AppState) => ({
  assignedUsersFilters: selectDealBaseAssignedUserFilters(state),
});

const mapDispatch = {
  applyFilters: (assignedUsersFilter: number[] | undefined) => setDealBaseFilters({ assigned: assignedUsersFilter }),
};

const connector = connect(mapState, mapDispatch);

type AssignedUserFilterProps = ConnectedProps<typeof connector>;

const AssignedUserFilter = ({ applyFilters, assignedUsersFilters }: AssignedUserFilterProps) => {
  const [visible, setVisibility] = useState(false);
  const [draftFilters, setDraftFilters] = useState(assignedUsersFilters);
  const { t } = useTranslation();

  const { data: userOptions } = userApi.useGetUsersShortQuery();

  const userChecked = useCallback((userId: number) => draftFilters?.includes(userId), [draftFilters]);

  useEffect(() => {
    setDraftFilters(assignedUsersFilters);
  }, [assignedUsersFilters]);

  const filtersUpdated = useMemo(() => {
    return (
      assignedUsersFilters?.length !== draftFilters?.length ||
      intersection(draftFilters, assignedUsersFilters).length !== assignedUsersFilters?.length
    );
  }, [draftFilters, assignedUsersFilters]);

  const onFilterUpdate = (userId: number) => {
    if (userChecked(userId)) {
      setDraftFilters(draftFilters?.filter((draftUserId) => draftUserId !== userId));
    } else {
      setDraftFilters((draftFilters ?? []).concat(userId));
    }
  };

  const onApply = () => {
    applyFilters(draftFilters);
  };

  const onClear = () => {
    setDraftFilters([]);
  };

  const items: ItemType[] = useMemo(() => {
    return [
      {
        key: 'user_group',
        type: 'group',
        className: 'max-h-64 overflow-y-auto p-0',
        children: userOptions?.map((item) => ({
          key: item.id,
          label: (
            <Checkbox
              onChange={() => onFilterUpdate(item.id)}
              checked={userChecked(item.id)}
              className="flex flex-auto"
            >
              {item.name}
            </Checkbox>
          ),
        })),
      },
      { type: 'divider' },
      {
        type: 'group',
        key: 'status_actions',
        label: <FilterActions onClear={onClear} onApply={onApply} filtersUpdated={filtersUpdated} />,
      },
    ];
  }, [filtersUpdated, userOptions, userChecked, t]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <Dropdown
        trigger={DROPDOWN_TRIGGERS}
        onOpenChange={(val) => setVisibility(val)}
        mouseLeaveDelay={0.2}
        open={visible}
        menu={{ items }}
      >
        <Button>
          {t('dealbase:filters.assignedCount', { count: assignedUsersFilters?.length })} <DownOutlined />
        </Button>
      </Dropdown>
    </div>
  );
};

export default connector(AssignedUserFilter);
