import { createApi } from '@reduxjs/toolkit/query/react';

import { dynamicBaseQuery } from 'core/lib/utils/requesting';

import endpoints, { TAGS } from './endpoints';
import { cacher } from '../../../utils/rtkQueryCacheUtils';
import { ObjectHistory, SearchResult } from '../../app';
import {
  PortfolioDetails,
  PortfolioListing,
  PortfolioListingUpdate,
  PortfolioMenu,
  UpdatePortfolioStatusRequest,
} from '../entities';

const portfolioApi = createApi({
  reducerPath: 'portfolioApi',
  baseQuery: dynamicBaseQuery,
  tagTypes: [...cacher.defaultTags, ...Object.values(TAGS)],
  endpoints: (build) => ({
    getPortfolioListing: build.query<PortfolioListing[], void>({
      query: () => endpoints.listing.url,
      providesTags: cacher.providesList(endpoints.listing.tag),
      transformResponse: endpoints.listing.deserializer,
    }),
    getPortfolioSearch: build.query<SearchResult[], string>({
      query: (search) => ({ url: endpoints.search.url, params: { search } }),
      transformResponse: endpoints.search.deserializer,
    }),
    getPortfolioMenu: build.query<PortfolioMenu[], void>({
      query: () => endpoints.menu.url,
      providesTags: cacher.providesList(endpoints.menu.tag),
      transformResponse: endpoints.menu.deserializer,
    }),
    getPortfolioDetails: build.query<PortfolioDetails, string>({
      query: (id) => ({ url: endpoints.details.url, params: { id } }),
      providesTags: cacher.cacheByIdArg(endpoints.details.tag),
      transformResponse: endpoints.details.deserializer,
    }),
    getHistory: build.query<ObjectHistory[], number>({
      query: (id) => ({ url: endpoints.getHistory.url, params: { id } }),
      providesTags: cacher.cacheByIdArg(endpoints.getHistory.tag),
      transformResponse: endpoints.getHistory.deserializer,
    }),
    patchPortfolio: build.mutation<number, Partial<PortfolioDetails>>({
      query: (portfolio) => ({ method: 'POST', url: endpoints.updatePortfolio.url, body: portfolio }),
      invalidatesTags: cacher.invalidatesMultipleLists(endpoints.updatePortfolio.invalidates),
      async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(portfolioApi.util.invalidateTags([{ type: TAGS.PORTFOLIO_DETAILS, id }]));
        } catch {}
      },
    }),
    postPortfolio: build.mutation<number, PortfolioDetails>({
      query: (portfolio) => ({ method: 'POST', url: endpoints.createPortfolio.url, body: portfolio }),
      invalidatesTags: cacher.invalidatesMultipleLists(endpoints.createPortfolio.invalidates),
    }),
    updateListingItem: build.mutation<PortfolioListing, PortfolioListingUpdate>({
      query: (portfolio) => ({
        method: 'POST',
        url: endpoints.updateListingItem.url,
        body: portfolio,
      }),
      invalidatesTags: cacher.invalidatesMultipleLists(endpoints.updateListingItem.invalidates),
      transformResponse: endpoints.updateListingItem.deserializer,
      async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            portfolioApi.util.updateQueryData('getPortfolioListing', undefined, (draft) => {
              const index = draft.findIndex((d) => d.id === id);
              draft.splice(index, 1, { ...draft[index], ...data });
            })
          );
          dispatch(portfolioApi.util.invalidateTags([{ type: TAGS.PORTFOLIO_DETAILS, id }]));
        } catch {}
      },
    }),
    updatePortfolioStatus: build.mutation<void, UpdatePortfolioStatusRequest>({
      query: (body) => ({ url: endpoints.updateStatus.url, body, method: 'POST' }),
      invalidatesTags: cacher.invalidatesMultipleLists(endpoints.updateStatus.invalidates),
      async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(portfolioApi.util.invalidateTags([{ type: TAGS.PORTFOLIO_DETAILS, id }]));
        } catch {}
      },
    }),
  }),
});

export default portfolioApi;
