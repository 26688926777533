import React, { useEffect, useMemo, useState } from 'react';

import { Pie, PieConfig, Datum } from '@ant-design/charts';
import { View } from '@antv/g2';
import { Data } from '@antv/g2plot';
import { Typography } from 'antd';
import { countBy, isEqual, keyBy, mapValues, sumBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { AppState } from 'core/lib';
import { PortfolioStatusType, PORTFOLIO_STATUSLIST } from 'core/lib/constants/statuses';
import { selectPortfolioStatusFilters, setPortfolioFilters } from 'core/lib/modules/portfolio';
import { PortfolioListing } from 'core/lib/modules/portfolio/entities';
import { Routes } from 'routes/routes';

const { Title } = Typography;

type PortfolioByStatusProps = {
  data?: PortfolioListing[];
};

type ChartSeries = {
  status: PortfolioStatusType;
  name: string;
  value: number;
}[];

const mapState = (state: AppState, ownProps: PortfolioByStatusProps) => ({
  filters: selectPortfolioStatusFilters(state),
});

const mapDispatch = {
  applyFilters: (statusFilters: PortfolioStatusType[] | undefined) => setPortfolioFilters({ status: statusFilters }),
};

const connector = connect(mapState, mapDispatch, (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
}));

type PortfolioByStatusConnectedProps = ConnectedProps<typeof connector>;

const PortfoliosByStatus = ({ applyFilters, data, filters }: PortfolioByStatusConnectedProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [selectedLegend, setSelectedLegend] = useState<{
    [x: string]: boolean;
  }>(() =>
    mapValues(
      keyBy(PORTFOLIO_STATUSLIST, ({ name }) => name),
      (item: { key: string; status: PortfolioStatusType }) => !!filters?.includes(item.status)
    )
  );

  useEffect(() => {
    const activeFiltersLegend = mapValues(
      keyBy(PORTFOLIO_STATUSLIST, ({ name }) => name),
      (item: { key: string; status: PortfolioStatusType }) => !!filters?.includes(item.status)
    );
    setSelectedLegend((activeLegend) => {
      return isEqual(activeFiltersLegend, activeLegend) ? activeLegend : activeFiltersLegend;
    });
  }, [filters]);

  useEffect(() => {
    const statusGroup = keyBy(PORTFOLIO_STATUSLIST, 'name');
    const selectedFilters: PortfolioStatusType[] = [];

    Object.entries(selectedLegend).forEach(([name, selected]) => {
      if (selected) {
        selectedFilters.push(statusGroup[name].status);
      }
    });
    if (!isEqual(keyBy(selectedFilters), keyBy(filters ?? []))) {
      applyFilters(selectedFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLegend, applyFilters]);

  function onLegendItemClick(item: { name: string; unchecked: boolean }) {
    setSelectedLegend((currentSelected) => ({ ...currentSelected, [item.name]: !item.unchecked }));
  }

  function handleChartStatisticsClick() {
    history.push(Routes.PORTFOLIO_LISTING.path);
  }

  const series: ChartSeries = useMemo(() => {
    const groupedData = countBy(data, 'status');

    return PORTFOLIO_STATUSLIST.map(({ key, status, name }) => ({
      status,
      name,
      value: groupedData[status] ?? 0,
    }));
  }, [data]);

  const config: PieConfig = {
    height: 300,
    onEvent: (chart, event) => {
      switch (event.type) {
        case 'legend-item:click': {
          onLegendItemClick(event.gEvent.target.cfg.delegateObject.item);
        }
      }
    },
    appendPadding: 40,
    data: series,
    angleField: 'value',
    colorField: 'name',
    radius: 1,
    innerRadius: 0.6,
    label: false,
    legend: {
      itemWidth: 100,
      selected: selectedLegend,
      itemValue: {
        formatter: (text, item, index) => {
          return series[index].value;
        },
      },
      offsetX: -20,
      layout: 'vertical',
    },
    statistic: {
      title: {
        style: {
          fontSize: '1em',
        },
      },

      content: {
        style: { fontSize: '2em' },
        customHtml: (container: HTMLElement, view: View, datum?: Datum, data?: Data) => {
          container.addEventListener('click', handleChartStatisticsClick);
          container.style.pointerEvents = 'auto';
          container.style.cursor = 'pointer';
          return sumBy(data as ChartSeries, (statusData) => statusData.value).toString();
        },
      },
    },
  };

  return (
    <div className="flex flex-col w-full gap-2">
      <Title level={5}>{t('portfolio:dashboards.titles.portfoliosByStatus')}</Title>
      <div className="w-full border border-solid b--grey-200">
        <Pie {...config} />
      </div>
    </div>
  );
};

export default connector(PortfoliosByStatus);
