import { createApi } from '@reduxjs/toolkit/query/react';

import { dynamicBaseQuery } from 'core/lib/utils/requesting';
import { cacher } from 'core/lib/utils/rtkQueryCacheUtils';

import endpoints from './endpoints';
import { UserNotificationPreferences, UserPreferences } from '../../entities';

const userPreferencesApi = createApi({
  reducerPath: 'userPreferencesApi',
  baseQuery: dynamicBaseQuery,
  tagTypes: cacher.defaultTags,
  endpoints: (build) => ({
    getUserNotificationPreferences: build.query<UserNotificationPreferences[], void>({
      query: () => endpoints.getUserNotificationPreferences.url,
      transformResponse: endpoints.getUserNotificationPreferences.deserializer,
    }),
    updateUserNotificationPreferences: build.mutation<void, UserNotificationPreferences[]>({
      query: (preferences) => ({
        method: 'POST',
        url: endpoints.updateUserNotificationPreferences.url,
        body: preferences,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(userPreferencesApi.util.prefetch('getUserNotificationPreferences', undefined, { force: true }));
        } catch {}
      },
    }),
    getPreferences: build.query<UserPreferences | null, void>({
      query: () => endpoints.getPreferences.url,
      transformResponse: endpoints.getPreferences.deserializer,
    }),
    updateUserPreferences: build.mutation<void, Partial<UserPreferences>>({
      query: (preferences) => ({
        method: 'POST',
        url: endpoints.updateUserPreferences.url,
        body: preferences,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(userPreferencesApi.util.prefetch('getPreferences', undefined, { force: true }));
        } catch {}
      },
    }),
  }),
});

export default userPreferencesApi;
