import { isRejectedWithValue, isFulfilled } from '@reduxjs/toolkit';
import * as redux from 'redux';

// add to toaster slice
export const rtqQueryToast: redux.Middleware = (api: redux.MiddlewareAPI) => (next) => (action) => {
  // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these use matchers!
  if (isRejectedWithValue(action)) {
    console.log(action);
    // console.warn('We got a rejected action!');
  } else if (isFulfilled(action)) {
  }

  return next(action);
};
