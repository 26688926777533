import React, { useEffect, useMemo, useState } from 'react';

import { DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, Popconfirm, Checkbox, notification } from 'antd';
import { BaseButtonProps } from 'antd/lib/button/button';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { useTranslation } from 'react-i18next';

import { DROPDOWN_TRIGGERS } from 'common-ui';
import { RequestError } from 'core/lib';
import { EXCEPTIONS } from 'core/lib/constants';
import {
  PortfolioStatusType,
  PORTFOLIO_STATUSLIST,
  PORTFOLIO_STATUS_KEYS,
} from 'core/lib/constants/statuses/portfolio';
import { portfolioApi, PortfolioDetails } from 'core/lib/modules/portfolio';
import { isChecked, isCurrentStatus, isEnabled, isGlobalStatus } from 'core/lib/utils/statuses/portfolio';

import { portfolioFields } from '../form/formConfig';

type PortfolioStatusDropdownProps = {
  portfolio: PortfolioDetails;
};

const PortfolioStatusDropdown = ({ portfolio, ...props }: PortfolioStatusDropdownProps & BaseButtonProps) => {
  const { t } = useTranslation();
  const [statusMenuConfirming, setStatusMenuConfirming] = useState<string | null>(null);

  const [updateStatus, { isLoading, error }] = portfolioApi.useUpdatePortfolioStatusMutation();

  const handleStatusUpdate = (status: PortfolioStatusType, revert: boolean) => {
    if (portfolio) {
      if (revert) {
        const isGlobal = isGlobalStatus(status);
        const prevStatus = (isGlobal ? portfolio.lastStatus ?? status - 1 : status - 1) as PortfolioStatusType;
        updateStatus({ id: portfolio?.id.toString(), status: prevStatus });
      } else {
        updateStatus({ id: portfolio?.id.toString(), status: status });
      }
      setStatusMenuConfirming(null);
    }
  };

  useEffect(() => {
    if (error) {
      const { data } = error as RequestError;
      if (data?.ExceptionType === EXCEPTIONS.MissingFields) {
        const missingFileds: string[] = JSON.parse(data.ExceptionMessage);

        notification.open({
          type: 'error',
          message: t('global:status.mandatoryFields'),
          description: (
            <div className="flex flex-col">
              {missingFileds.map((field) => {
                return <div key="field">{portfolioFields[field]}</div>;
              })}
            </div>
          ),
        });
      }
    }
  }, [error, t]);

  const items: ItemType[] = useMemo(() => {
    return PORTFOLIO_STATUSLIST.map((item) => {
      const disabled = !isEnabled(portfolio, item.status);
      const isCurrent = isCurrentStatus(portfolio?.status, item.status);
      const checked = isChecked(portfolio.lastStatus, portfolio.status, item.status);
      const title = isCurrent ? t('global:status.actions.previousStatus') : t('global:status.actions.nextStatus');
      return {
        key: item.key,
        disabled,
        onClick: ({ domEvent }) => {
          setStatusMenuConfirming(item.key);
          domEvent.stopPropagation();
        },
        label: (
          <Popconfirm
            //having popover in dropdown menu fix z-index
            overlayClassName="z-[1050]"
            key={item.status}
            open={item.key === statusMenuConfirming}
            disabled={disabled}
            title={title}
            onConfirm={(e) => {
              e?.stopPropagation();
              handleStatusUpdate(item.status, isCurrent);
            }}
            onCancel={(e) => {
              e?.stopPropagation();
              setStatusMenuConfirming(null);
            }}
            okButtonProps={{ loading: isLoading }}
            okText={t('actions:global.confirm')}
            cancelText={t('actions:global.cancel')}
          >
            <Checkbox
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
              onChange={(e) => {
                e.preventDefault();
              }}
              disabled={disabled}
              checked={checked}
              className="flex flex-auto"
            >
              {t(`portfolio:status.label.${item.key}`)}
            </Checkbox>
          </Popconfirm>
        ),
      };
    });
  }, [portfolio, isLoading, statusMenuConfirming]); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    portfolio && (
      <Dropdown
        trigger={DROPDOWN_TRIGGERS}
        key="statusMenu"
        {...(statusMenuConfirming ? { open: true } : {})}
        menu={{ items }}
      >
        <Button {...props} type="primary" loading={isLoading}>
          {t(`portfolio:status.label.${PORTFOLIO_STATUS_KEYS[portfolio.status]}`)} <DownOutlined />
        </Button>
      </Dropdown>
    )
  );
};

export default PortfolioStatusDropdown;
