import {
  CommonObject,
  CommonObjectConnectedObject,
  CommonObjectConnectedObjectResponse,
  CommonObjectOption,
  CommonObjectResponse,
} from '../../entities';

export const deserializeCommonObject = (response: CommonObjectResponse): CommonObject => {
  return {
    id: response.ID,
    type: response.Type,
    name: response.Name,
    objectType: response.ObjectType,
    archived: response.Archived,
    connected: response.Connected,
    displayOrder: response.DisplayOrder,
  };
};

export const deserializeCommonObjectList = (response: CommonObjectResponse[]): CommonObject[] => {
  return response.map(deserializeCommonObject);
};

export const deserializeCommonObjectToOption = (response: CommonObjectResponse): CommonObjectOption => {
  return {
    id: response.ID,
    value: response.ID,
    label: response.Name,
    disabled: response.Archived,
  };
};

export const deserializeCommonObjectListToOption = (response: CommonObjectResponse[]): CommonObjectOption[] => {
  return response.map(deserializeCommonObjectToOption);
};

export const deserialzieCommonObjectConnectedObjects = (
  response: CommonObjectConnectedObjectResponse[]
): CommonObjectConnectedObject[] => {
  return response.map((co) => ({ id: co.ID, name: co.Name }));
};
