import React from 'react';

import { Rule } from 'antd/lib/form';
import { Trans } from 'react-i18next';

import { MAX_INT_TYPE } from 'core/lib/constants';
import {
  numberRangeValidator,
  passwordValidator,
  requiredNumberRangeValidator,
  uniqueUserEmailValidator,
} from 'utils/validators/general';

import { valueDelimiterFormatter } from '..';

export const rules = {
  required: {
    required: true,
    message: <Trans i18nKey="global:inputs.required" />,
  } as Rule,
  url: {
    type: 'url',
    message: <Trans i18nKey="global:inputs.url" />,
  } as Rule,
  email: {
    type: 'email',
    message: <Trans i18nKey="global:inputs.email" />,
  } as Rule,
  min: (value: number): Rule => ({
    type: 'number',
    min: value,
    message: <Trans i18nKey="global:inputs.minValue" values={{ value: valueDelimiterFormatter(value) }} />,
  }),
  max: (value: number): Rule => ({
    type: 'number',
    max: value,
    message: <Trans i18nKey="global:inputs.maxValue" values={{ value: valueDelimiterFormatter(value) }} />,
  }),
  minLength: (value: number): Rule => ({
    type: 'string',
    min: value,
    message: <Trans i18nKey="global:inputs.minLength" values={{ value }} />,
  }),
  maxLength: (value: number): Rule => ({
    type: 'string',
    max: value,
    message: <Trans i18nKey="global:inputs.maxLength" values={{ value }} />,
  }),
  password: {
    validator: passwordValidator,
    message: <Trans i18nKey="global:inputs.passwordCriteria" />,
  },
  uniqueUserEmail: {
    validator: uniqueUserEmailValidator,
    message: <Trans i18nKey="global:inputs.userEmailDuplicate" />,
  },
  numberRange: (maxLength: MAX_INT_TYPE) => ({
    validator: numberRangeValidator(maxLength),
    message: <Trans i18nKey="global:inputs.numberLength" values={{ value: maxLength.toString().length }} />,
  }),
  requiredNumberRange: {
    validator: requiredNumberRangeValidator,
    message: <Trans i18nKey="global:inputs.required" />,
  },
};
