import React, { useEffect, useMemo, useState } from 'react';

import { FilterFilled } from '@ant-design/icons';
import { Badge, Button, Divider, Modal } from 'antd';
import { isEqual, mapValues, omit } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useMedia } from 'react-use';

import { CommonObjectFilter } from 'common-ui/filters';
import { useAppDispatch, useAppSelector } from 'core/lib';
import { DYNAMIC, OBJECT_TYPES } from 'core/lib/constants';
import { PortfolioFilters } from 'core/lib/modules/portfolio/entities/filters';
import {
  selectPortfolioFilters,
  setPortfolioFilters,
  selectPortfolioFiltersCount,
  clearPortfolioFilters,
} from 'core/lib/modules/portfolio/store';

import CreationDateFilter from './creationDateFilter';
import OrganizationFilter from './organizationFilter';
import OwnerFilter from './ownerFilter';
import ResponsibleFilter from './responsibleFilter';
import StatusFilter from './statusFilter';
import TaskOwnerFilter from './taskOwnerFilter';

const ListingFilters = () => {
  const filters = useAppSelector(selectPortfolioFilters);
  const [draftFilters, setDraftFilters] = useState<Partial<PortfolioFilters>>(filters);
  const [modalOpen, toggleModal] = useState(false);
  const dispatch = useAppDispatch();

  const isMobile = useMedia('(max-width: 768px)');
  const { t } = useTranslation();
  const filtersCount = useAppSelector(selectPortfolioFiltersCount(isMobile));

  useEffect(() => {
    setDraftFilters(filters);
  }, [filters, modalOpen]);

  const showModal = () => {
    toggleModal(true);
  };

  const handleOk = () => {
    dispatch(setPortfolioFilters(draftFilters));
    toggleModal(false);
  };

  const clearFilterKeys = useMemo(() => {
    if (isMobile) {
      return Object.keys(filters);
    }
    return Object.keys(omit(filters, ['status', 'organization']));
  }, [isMobile, filters]);

  const onClearModalClear = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setDraftFilters(mapValues(filters, (value, key) => (clearFilterKeys.includes(key) ? undefined : (value as any))));
  };

  const onClear = () => {
    dispatch(clearPortfolioFilters());
  };

  const onCancel = () => {
    toggleModal(false);
  };

  const onFiltersChange = (partialFilters: Partial<PortfolioFilters>) => {
    setDraftFilters({ ...draftFilters, ...partialFilters });
  };

  const filtersUpdated = useMemo(() => !isEqual(draftFilters, filters), [draftFilters, filters]);

  return (
    <>
      <Badge count={filtersCount}>
        <Button type="default" onClick={showModal} icon={<FilterFilled />}>
          {t('portfolio:filters.filters')}
        </Button>
      </Badge>
      <Button onClick={onClear} type="text">
        {t('actions:global.clearAll')}
      </Button>
      <Modal
        width="auto"
        style={{ maxWidth: 600 }}
        bodyStyle={{ overflowY: 'scroll', height: '50vh' }}
        title={t('global:filters.moreFilters')}
        open={modalOpen}
        onCancel={onCancel}
        footer={
          <div>
            <Button onClick={onClearModalClear} type="text">
              {t('actions:global.clearAll')}
            </Button>
            <Button disabled={!filtersUpdated} onClick={handleOk} type="primary">
              {t('actions:global.apply')}
            </Button>
          </div>
        }
      >
        <div>
          {isMobile && (
            <>
              <StatusFilter onChange={onFiltersChange} filters={draftFilters.status} />
              <Divider />
              <OrganizationFilter onChange={onFiltersChange} filters={draftFilters.organization} />
              <Divider />
            </>
          )}
          <CreationDateFilter onChange={onFiltersChange} filters={draftFilters.creationDate} />
          <Divider />

          <CommonObjectFilter<PortfolioFilters>
            title={t('portfolio:filters.type')}
            filterKey="type"
            type={DYNAMIC.PORTFOLIO.TYPE}
            objectType={OBJECT_TYPES.PORTFOLIO}
            onChange={onFiltersChange}
            filters={draftFilters.type}
          />
          <Divider />
          <CommonObjectFilter<PortfolioFilters>
            title={t('portfolio:filters.complianceStatus')}
            filterKey="complianceStatus"
            type={DYNAMIC.PORTFOLIO.COMPLIANCE_STATUS}
            objectType={OBJECT_TYPES.PORTFOLIO}
            onChange={onFiltersChange}
            filters={draftFilters.complianceStatus}
          />
          <Divider />
          <OwnerFilter onChange={onFiltersChange} filters={draftFilters.owner} />
          <Divider />
          <ResponsibleFilter onChange={onFiltersChange} filters={draftFilters.responsible} />
          <Divider />
          <TaskOwnerFilter onChange={onFiltersChange} filters={draftFilters.taskOwner} />
        </div>
      </Modal>
    </>
  );
};

export default ListingFilters;
