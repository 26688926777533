import React, { useMemo } from 'react';

import { groupBy } from 'lodash';
import { useTranslation } from 'react-i18next';

import ListObjectFilter from 'common-ui/lists/listObjectFilter';
import { PortfolioListing } from 'core/lib';
import { OBJECT_TYPES } from 'core/lib/constants';

type PortfoliosByOwnerProps = {
  data?: PortfolioListing[];
};

const PortfoliosByOwner = ({ data }: PortfoliosByOwnerProps) => {
  const { t } = useTranslation();

  const dataByOwner = useMemo(() => groupBy(data, (d) => d.owner?.name ?? t('global:results.notSpecified')), [data, t]);

  return (
    <ListObjectFilter<PortfolioListing>
      placeholder={t('portfolio:placeholders.searchOwner')}
      data={dataByOwner}
      title={t('portfolio:dashboards.titles.owner')}
      objectType={OBJECT_TYPES.PORTFOLIO}
    />
  );
};

export default PortfoliosByOwner;
