import React from 'react';

import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Form, Typography, Button, Divider, Empty } from 'antd';
import { useTranslation } from 'react-i18next';

import theme from 'core/lib/theme';
import { dealBaseFormConfig } from 'modules/dealBase/form/formConfig';

const {
  generalInformation: { contactDetails },
} = dealBaseFormConfig;

const { founder } = contactDetails;

const { Title } = Typography;

type ContactDetailsSectionProps = {
  editMode: boolean;
};

const DealBaseContactDetailsSection = ({ editMode }: ContactDetailsSectionProps) => {
  const { t } = useTranslation('dealbase');

  return (
    <>
      <div className="flex sm:flex-row flex-col gap-x-4">
        <Form.Item
          className="db flex-auto"
          label={contactDetails.ceo.label}
          name={contactDetails.ceo.dataIndex}
          rules={contactDetails.ceo.rules}
        >
          <contactDetails.ceo.component disabled={!editMode} />
        </Form.Item>
      </div>
      <Title style={{ color: theme.colors.Primary.rgba }} level={5}>
        {t('form.contactDetails.headers.founders')}
      </Title>
      <Form.List name={founder.dataIndex} rules={founder.rules}>
        {(founders, { add, remove }) => {
          return (
            <div className="flex flex-col">
              {founders.map(({ key, name, ...restField }) => (
                <div key={key} className="flex flex-col">
                  <div className="flex items-center flex-row gap-x-4">
                    <div className="flex flex-auto sm:flex-row lg:flex-col flex-col gap-x-4 overflow-hidden">
                      <Form.Item
                        {...restField}
                        className="md:w-3/6 lg:w-full"
                        label={founder.founderName.label}
                        rules={founder.founderName.rules}
                        name={[name, founder.founderName.dataIndex]}
                      >
                        <founder.founderName.component disabled={!editMode} />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        className="md:w-3/6 lg:w-full"
                        label={founder.founderJobTitle.label}
                        rules={founder.founderJobTitle.rules}
                        name={[name, founder.founderJobTitle.dataIndex]}
                      >
                        <founder.founderJobTitle.component disabled={!editMode} />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        className="md:w-3/6 lg:w-full"
                        label={founder.founderLinkedIn.label}
                        rules={founder.founderLinkedIn.rules}
                        name={[name, founder.founderLinkedIn.dataIndex]}
                      >
                        <founder.founderLinkedIn.component disabled={!editMode} />
                      </Form.Item>
                    </div>
                    {editMode && founders.length > 1 && <MinusCircleOutlined onClick={() => remove(name)} />}
                  </div>
                  {founders.length > 1 && <Divider />}
                </div>
              ))}
              {editMode && (
                <Form.Item>
                  <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                    {t('actions.addFounder')}
                  </Button>
                </Form.Item>
              )}
              {!editMode && !founders.length ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /> : null}
            </div>
          );
        }}
      </Form.List>
      <Title style={{ color: theme.colors.Primary.rgba }} level={5}>
        {t('form.contactDetails.headers.contactInfo')}
      </Title>
      <div className="flex sm:flex-row lg:flex-col flex-col gap-x-4">
        <Form.Item
          className="md:w-3/6 lg:w-full"
          label={contactDetails.contactPerson.label}
          name={contactDetails.contactPerson.dataIndex}
          rules={contactDetails.contactPerson.rules}
        >
          <contactDetails.contactPerson.component disabled={!editMode} />
        </Form.Item>
        <Form.Item
          className="md:w-3/6 lg:w-full"
          label={contactDetails.contactNumber.label}
          name={contactDetails.contactNumber.dataIndex}
          rules={contactDetails.contactNumber.rules}
        >
          <contactDetails.contactNumber.component disabled={!editMode} />
        </Form.Item>
      </div>
      <div className="flex sm:flex-row lg:flex-col flex-col w-full md:w-3/6 lg:w-full gap-x-4">
        <Form.Item
          className="db flex-auto"
          label={contactDetails.contactEmail.label}
          name={contactDetails.contactEmail.dataIndex}
          rules={contactDetails.contactEmail.rules}
        >
          <contactDetails.contactEmail.component disabled={!editMode} />
        </Form.Item>
      </div>
    </>
  );
};

export default DealBaseContactDetailsSection;
