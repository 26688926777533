import { createApi } from '@reduxjs/toolkit/query/react';

import { dynamicBaseQuery } from 'core/lib/utils/requesting';

import endpoints, { TAGS } from './endpoints';
import { cacher } from '../../../utils/rtkQueryCacheUtils';
import { NotificationsInfo, UserNotification } from '../entities';

const notificationApi = createApi({
  reducerPath: 'notificationApi',
  baseQuery: dynamicBaseQuery,
  tagTypes: [...cacher.defaultTags, ...Object.values(TAGS)],
  endpoints: (build) => ({
    getNotificationsByPage: build.query<UserNotification[], number>({
      query: (page) => ({ url: endpoints.listing.url, params: { page } }),
      providesTags: cacher.providesList(endpoints.listing.tag),
      transformResponse: endpoints.listing.deserializer,
    }),
    getNotificationsInfo: build.query<NotificationsInfo, void>({
      query: () => endpoints.notificationsInfo.url,
      transformResponse: endpoints.notificationsInfo.deserializer,
    }),
    postNotificationsSeen: build.mutation<void, void>({
      query: () => ({ url: endpoints.notificationsSeen.url, method: 'POST' }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(notificationApi.util.prefetch('getNotificationsInfo', undefined, { force: true }));
        } catch {}
      },
    }),
    postNotificationVisited: build.mutation<void, number>({
      query: (id) => ({ url: `${endpoints.notificationVisited.url}/${id}`, method: 'POST' }),
      invalidatesTags: endpoints.notificationVisited.invalidates,
      extraOptions: {},
    }),
    postAllNotificationsVisited: build.mutation<void, void>({
      query: () => ({ url: endpoints.notificationsAllVisited.url, method: 'POST' }),
      invalidatesTags: endpoints.notificationsAllVisited.invalidates,
    }),
  }),
});

export default notificationApi;
