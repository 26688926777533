import React from 'react';

import Mention from '@tiptap/extension-mention';
import { mergeAttributes, NodeViewProps, NodeViewWrapper, ReactNodeViewRenderer } from '@tiptap/react';
import {generatePath, Link } from 'react-router-dom';

import { Routes } from 'routes/routes';

const MentionView = (props: NodeViewProps) => {
  const { user } = props.node.attrs;
  const { external } = props.extension.options;

  return (
    <NodeViewWrapper className="mention inline">
      {external ? (
        <span className={`gap-1 p-1 bg-sky-100 rounded-md text-black`}>{`@${user.name}`}</span>
      ) : (
        <Link className={`gap-1 p-1 bg-sky-100 rounded-md text-black`} to={generatePath(Routes.USER_DETAILS.path, { id: user.id })}>
          {`@${user.name}`}
        </Link>
      )}
    </NodeViewWrapper>
  );
};

const CustomMention = Mention.extend({
  addNodeView: () => ReactNodeViewRenderer(MentionView),
  addAttributes: () => ({
    user: {
      default: null,
      parseHTML: (element) => element.getAttribute('data-user'),
      renderHTML: (attributes) => {
        if (!attributes.user) {
          return {};
        }

        return {
          'data-user': attributes.user,
        };
      },
    },
    id: {
      default: null,
      parseHTML: (element) => element.getAttribute('data-id'),
      renderHTML: (attributes) => {
        if (!attributes.id) {
          return {};
        }

        return {
          'data-id': attributes.id,
        };
      },
    },
  }),
  addOptions: () => ({ ...Mention.options, external: false }),

  renderHTML({ HTMLAttributes }) {
    return ['mention', mergeAttributes(HTMLAttributes)];
  },
});

export default CustomMention;
