import React from 'react';

import { Comment } from 'antd';

import { UserAvatar } from 'common-ui';
import { UserComment, selectCurrentUser, useAppSelector } from 'core/lib';

import { CommentList } from './components';
import Editor from './editor';

type CommentsSectionProps = {
  type: string;
  comments: UserComment[];
  nameRequired?: boolean;
  disabled?: boolean;
  external?: boolean;

  onDelete?: (commentId: number, callback?: () => void) => void;
  onSubmit: (comment: Partial<UserComment>, callback?: () => void) => void;
  onReplySubmit: (parentId: number, comment: Partial<UserComment>, callback?: () => void) => void;
  isSaving: boolean;
};

const Comments = ({ type, nameRequired, external, disabled, ...rest }: CommentsSectionProps) => {
  const loggedInUser = useAppSelector(selectCurrentUser);

  return (
    <>
      <Comment
        avatar={loggedInUser && <UserAvatar user={loggedInUser} plain iconOnly />}
        content={
          <Editor
            external={external}
            type={type}
            nameRequired={nameRequired}
            onSubmit={rest.onSubmit}
            submitting={rest.isSaving}
            disabled={disabled}
          />
        }
      />
      <CommentList {...rest} type={type} external={external} nameRequired={nameRequired} />
    </>
  );
};

export default Comments;
