import React from 'react';

import { Radio, RadioChangeEvent } from 'antd';
import { useTranslation } from 'react-i18next';

import { FilterOperator } from 'core/lib';

type FilterOperatorComponentProps<T> = {
  onChange: (filters: Partial<T>) => void;
  filterKey: string;
  operator?: FilterOperator;
};

function FilterOperatorComponent<T extends { [x: string]: FilterOperator }>({
  filterKey,
  onChange,
  operator,
}: FilterOperatorComponentProps<T>) {
  const { t } = useTranslation();
  const onOperatorChange = ({ target: { value } }: RadioChangeEvent) => {
    onChange({ [filterKey]: value } as T);
  };
  return (
    <Radio.Group onChange={onOperatorChange} value={operator}>
      <Radio value={'OR'}>{t('global:operators.or')}</Radio>
      <Radio value={'AND'}>{t('global:operators.and')}</Radio>
    </Radio.Group>
  );
}

export default FilterOperatorComponent;
