import React, { useEffect } from 'react';

import { Route, Switch, Redirect, generatePath } from 'react-router-dom';

import { setActiveModule, useAppDispatch } from 'core/lib';
import { OBJECT_TYPES } from 'core/lib/constants';
import { Routes } from 'routes/routes';

import UserListingDetails from './details';
import UserListing from './listing';
import UserNew from './new';

const User = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(setActiveModule(OBJECT_TYPES.USER));
    return () => {
      dispatch(setActiveModule(null));
    };
  }, [dispatch]);

  return (
    <Switch>
      <Route path={Routes.USER.path} exact render={() => <Redirect to={generatePath(Routes.USER_LISTING.path)} />} />
      <Route path={Routes.USER_LISTING.path} exact component={UserListing} />
      <Route path={Routes.USER_DETAILS.path} exact component={UserListingDetails} />
      <Route path={Routes.USER_NEW.path} component={UserNew} />
    </Switch>
  );
};

export default User;
