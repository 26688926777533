import React from 'react';

import { Input, InputProps } from 'antd';
import Form from 'antd/lib/form';

import { rules as customRules } from 'utils/form/rules';

import { ListingEditableCellProps } from './types';

function ListingUniqueEmail<T extends { id?: number }>({
  dataIndex,
  rules,
  record,
  ...props
}: ListingEditableCellProps & InputProps & { record: T }) {
  return (
    <Form.Item
      name={dataIndex}
      className="m-0"
      validateFirst
      rules={[
        ...rules,
        {
          validateTrigger: 'onSubmit',
          validator: async (rule, value) => customRules.uniqueUserEmail.validator(rule, value, record.id),
          message: customRules.uniqueUserEmail.message,
        },
      ]}
    >
      <Input {...props} />
    </Form.Item>
  );
}

export default ListingUniqueEmail;
