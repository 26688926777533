import { deserializeDetailsSectionHeaderItem, deserializeDetailsSectionHeaders } from './serializers';

const API_V2 = 'api';

const CONTROLLER = 'detailsSectionHeader';

export const TAGS = {
  DetailsSectionHeaders: 'DetailsSectionPanel',
};
const endpoints = {
  getDetailsSectionHeaders: {
    url: `${API_V2}/${CONTROLLER}/getDetailsSectionHeaders`,
    tag: TAGS.DetailsSectionHeaders,
    deserializer: deserializeDetailsSectionHeaders,
  },
  postDetailsSectionItem: {
    url: `${API_V2}/${CONTROLLER}/saveDetailsSectionItem`,
    deserializer: deserializeDetailsSectionHeaderItem,
  },
};

export default endpoints;
