import React from 'react';

import CommentsForm from './commentsForm';
import ExternalLogin from '../externalLogin';

const ExternalDealBaseComments = () => {
  return (
    <ExternalLogin>
      <CommentsForm />
    </ExternalLogin>
  );
};

export default ExternalDealBaseComments;
