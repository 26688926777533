import React from 'react';

import { Form, SelectProps } from 'antd';

import { CountrySelect } from 'utils/form';

import { ListingEditableCellProps } from './types';

function ListingCountrySelect({
  dataIndex,
  rules,
  isDisabled,
  ...props
}: ListingEditableCellProps & SelectProps<string | string[]>) {
  const form = Form.useFormInstance();

  const disabled = props.disabled || (isDisabled && isDisabled(form));

  return (
    <Form.Item rules={rules} name={dataIndex} className="m-0">
      <CountrySelect allowClear {...props} disabled={disabled} />
    </Form.Item>
  );
}

export default ListingCountrySelect;
