import React, { useMemo } from 'react';

import { Skeleton, Typography } from 'antd';

import { WysiwygEditor } from 'common-ui/wysiwyg';
import { CommentStatus } from 'core/lib';
import { COMMENT_STATUS } from 'core/lib/constants/comment';

import { getCommentContent } from './utils';

const { Text } = Typography;

type ViewerProps = {
  content: string;
  external?: boolean;
  status?: CommentStatus | null;
  isLoading?: boolean;
};

const CommentsViewer = ({ content, external, status, isLoading }: ViewerProps) => {
  const value = useMemo(() => getCommentContent(content), [content]);

  if (status === COMMENT_STATUS.DELETED) {
    return (
      <Text italic type="secondary">
        {content}
      </Text>
    );
  }

  if (isLoading) {
    return <Skeleton.Input className="flex w-9/12" active={true} size="small" />;
  }
  return <WysiwygEditor readOnly value={value} external={external} />;
};

export default CommentsViewer;
