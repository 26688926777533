import { isRejectedWithValue } from '@reduxjs/toolkit';
import * as redux from 'redux';

import { logout } from '../modules/auth';

export const authMiddleware: redux.Middleware = (api: redux.MiddlewareAPI) => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    if (action.payload.status === 401) {
      next(logout());
    }
  }

  return next(action);
};
