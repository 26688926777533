import { createApi } from '@reduxjs/toolkit/query/react';

import { SearchResult, UserComment, DraftBaseComment } from 'core/lib';
import { dynamicBaseQuery } from 'core/lib/utils/requesting';

import endpoints, { TAGS } from './endpoints';
import { cacher } from '../../../utils/rtkQueryCacheUtils';
import { DeleteCommentRequest, ObjectHistory } from '../../app';
import {
  DealBase,
  DealBaseInfo,
  DealBaseListing,
  DealBaseListingUpdate,
  DealBaseMenu,
  DealBaseOption,
  ExternalDealBase,
  ExternallAccessCredentials,
  FinancialYear,
  Scorecard,
} from '../entities/dealBase';

const dealBaseApi = createApi({
  reducerPath: 'dealBaseApi',
  baseQuery: dynamicBaseQuery,
  tagTypes: [...cacher.defaultTags, ...Object.values(TAGS)],
  endpoints: (build) => ({
    getDealBaseListing: build.query<DealBaseListing[], void>({
      query: () => endpoints.listing.url,
      providesTags: cacher.providesList(endpoints.listing.tag),
      transformResponse: endpoints.listing.deserializer,
    }),
    getDealBaseOptions: build.query<DealBaseOption[], void>({
      query: () => endpoints.options.url,
      transformResponse: endpoints.options.deserializer,
    }),
    getDealBaseSearch: build.query<SearchResult[], string>({
      query: (search) => ({ url: endpoints.search.url, params: { search } }),
      transformResponse: endpoints.search.deserializer,
    }),
    getDealBaseMenu: build.query<DealBaseMenu[], void>({
      query: () => endpoints.menu.url,
      providesTags: cacher.providesList(endpoints.menu.tag),
      transformResponse: endpoints.menu.deserializer,
    }),
    getDealBaseDetails: build.query<DealBase, string>({
      query: (id) => ({ url: endpoints.details.url, params: { id } }),
      providesTags: cacher.cacheByIdArg(endpoints.details.tag),
      transformResponse: endpoints.details.deserializer,
    }),
    getDealBaseInfo: build.query<DealBaseInfo, string>({
      query: (id) => ({ url: endpoints.info.url, params: { id } }),
      providesTags: cacher.cacheByIdArg(endpoints.info.tag),
      transformResponse: endpoints.info.deserializer,
    }),
    getExternalDealBaseDetails: build.query<ExternalDealBase, string>({
      query: (id) => ({ url: endpoints.externalDetails.url, params: { id } }),
      transformResponse: endpoints.externalDetails.deserializer,
    }),
    getHistory: build.query<ObjectHistory[], number>({
      query: (id) => ({ url: endpoints.getHistory.url, params: { id } }),
      providesTags: cacher.cacheByIdArg(endpoints.getHistory.tag),
      transformResponse: endpoints.getHistory.deserializer,
    }),
    getComments: build.query<UserComment[], number>({
      query: (id) => ({ url: endpoints.comments.url, params: { id } }),
      providesTags: cacher.cacheByIdArg(endpoints.comments.tag),
      transformResponse: endpoints.comments.deserializer,
    }),
    getCommentsCount: build.query<number, number>({
      query: (id) => ({ url: endpoints.commentsCount.url, params: { id } }),
      providesTags: cacher.cacheByIdArg(endpoints.commentsCount.tag),
    }),
    getDecisions: build.query<UserComment[], number>({
      query: (id) => ({ url: endpoints.decisions.url, params: { id } }),
      providesTags: cacher.cacheByIdArg(endpoints.decisions.tag),
      transformResponse: endpoints.decisions.deserializer,
    }),
    getDecisionsCount: build.query<number, number>({
      query: (id) => ({ url: endpoints.decisionsCount.url, params: { id } }),
      providesTags: cacher.cacheByIdArg(endpoints.decisionsCount.tag),
    }),
    getDealbasePitchDeck: build.query<string, { id: number; file: string }>({
      query: ({ id, file }) => ({
        method: 'POST',
        isBase: true,
        url: endpoints.pitchDeckDocument.url,
        params: { UserBlobID: id, file, type: 7 },
      }),
    }),
    getDealbaseInitialScorecards: build.query<Scorecard[], number>({
      query: (id) => ({ url: endpoints.initialScorecards.url, params: { id } }),
      transformResponse: endpoints.initialScorecards.deserializer,
    }),
    getDealBaseRevenueYears: build.query<FinancialYear[], number>({
      query: (id) => ({ url: endpoints.revenueYears.url, params: { id } }),
      transformResponse: endpoints.revenueYears.deserializer,
    }),
    getDealBaseEBITDAYears: build.query<FinancialYear[], number>({
      query: (id) => ({ url: endpoints.ebitdaYears.url, params: { id } }),
      transformResponse: endpoints.ebitdaYears.deserializer,
    }),
    getDealBaseExternalAccess: build.query<ExternallAccessCredentials, { id: number; type: number }>({
      query: ({ id, type }) => ({ url: endpoints.externalAccess.url, params: { id, type } }),
      transformResponse: endpoints.externalAccess.deserializer,
    }),
    postDealBase: build.mutation<number, DealBase>({
      query: (dealbase) => ({ method: 'POST', url: endpoints.createDealBase.url, body: dealbase }),
      invalidatesTags: cacher.invalidatesMultipleLists(endpoints.createDealBase.invalidates),
    }),
    patchDealBase: build.mutation<void, Partial<DealBase>>({
      query: (dealbase) => ({ method: 'POST', url: endpoints.updateDealBase.url, body: dealbase }),
      invalidatesTags: cacher.invalidatesMultipleLists(endpoints.updateDealBase.invalidates),
      async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            dealBaseApi.util.invalidateTags([
              { type: TAGS.DEALBASE_DETAILS, id },
              { type: TAGS.DEALBASE_INFO, id },
            ])
          );
        } catch {}
      },
    }),
    postComment: build.mutation<void, Partial<DraftBaseComment>>({
      query: (comment) => ({
        method: 'POST',
        url: endpoints.postComment.url,
        body: comment,
      }),
      async onQueryStarted({ objectId }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            dealBaseApi.util.invalidateTags([
              { type: TAGS.DEALBASE_COMMENTS, id: objectId! },
              { type: TAGS.DEALBASE_COMMENTS_COUNT, id: objectId! },
            ])
          );
        } catch {}
      },
    }),
    deleteComment: build.mutation<void, DeleteCommentRequest>({
      query: (requestParams) => ({
        method: 'DELETE',
        url: endpoints.deleteComment.url,
        body: requestParams,
      }),
      async onQueryStarted({ objectId }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            dealBaseApi.util.invalidateTags([
              { type: TAGS.DEALBASE_COMMENTS, id: objectId! },
              { type: TAGS.DEALBASE_COMMENTS_COUNT, id: objectId! },
            ])
          );
        } catch {}
      },
    }),
    postDecision: build.mutation<void, Partial<DraftBaseComment>>({
      query: (comment) => ({
        method: 'POST',
        url: endpoints.postDecision.url,
        body: comment,
      }),
      async onQueryStarted({ objectId }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            dealBaseApi.util.invalidateTags([
              { type: TAGS.DEALBASE_DECISIONS, id: objectId! },
              { type: TAGS.DEALBASE_DECISIONS_COUNT, id: objectId! },
            ])
          );
        } catch {}
      },
    }),
    deleteDecision: build.mutation<void, DeleteCommentRequest>({
      query: (requestParams) => ({
        method: 'DELETE',
        url: endpoints.deleteDecision.url,
        body: requestParams,
      }),
      async onQueryStarted({ objectId }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            dealBaseApi.util.invalidateTags([
              { type: TAGS.DEALBASE_DECISIONS, id: objectId! },
              { type: TAGS.DEALBASE_DECISIONS_COUNT, id: objectId! },
            ])
          );
        } catch {}
      },
    }),
    postExternalDealBase: build.mutation<void, DealBase>({
      query: (dealbase) => ({ method: 'POST', url: endpoints.createExternalDealBase.url, body: dealbase }),
    }),
    patchExternalDealBase: build.mutation<void, Partial<DealBase>>({
      query: (dealbase) => ({ method: 'POST', url: endpoints.updateExternalDealBase.url, body: dealbase }),
    }),
    updateListingItem: build.mutation<DealBaseListing, DealBaseListingUpdate>({
      query: ({ id, ...patch }) => ({
        method: 'POST',
        url: `${endpoints.updateListingItem.url}/${id}`,
        body: patch,
      }),
      invalidatesTags: cacher.invalidatesMultipleLists(endpoints.updateListingItem.invalidates),
      transformResponse: endpoints.updateListingItem.deserializer,
      async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            dealBaseApi.util.updateQueryData('getDealBaseListing', undefined, (draft) => {
              const index = draft.findIndex((d) => d.id === id);
              draft.splice(index, 1, { ...draft[index], ...data });
            })
          );
          dispatch(
            dealBaseApi.util.invalidateTags([
              { type: TAGS.DEALBASE_DETAILS, id },
              { type: TAGS.DEALBASE_INFO, id },
            ])
          );
        } catch {}
      },
    }),
    updateDealBaseStatus: build.mutation<void, { id: string; status: number }>({
      query: ({ id, status }) => ({ method: 'POST', url: endpoints.updateStatus.url, body: { id, status } }),
      invalidatesTags: cacher.invalidatesMultipleLists(endpoints.updateStatus.invalidates),
      async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            dealBaseApi.util.invalidateTags([
              { type: TAGS.DEALBASE_DETAILS, id },
              { type: TAGS.DEALBASE_INFO, id },
              { type: TAGS.DEALBASE_OBJECT_HISTORY, id },
            ])
          );
        } catch {}
      },
    }),
  }),
});

export default dealBaseApi;
