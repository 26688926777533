import React, { useContext, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useMedia } from 'react-use';

import { ContentContainer, MainContentContainer, SideContentContainer } from 'common-ui/containers';
import { DetailsSectionCollapse, DetailsSectionPanel } from 'common-ui/detailsSectionCollapse';
import { contactBookApi, ContactBookDetails } from 'core/lib';
import { OBJECT_TYPES } from 'core/lib/constants';

import {
  ContactInformationSection,
  ProfessionalInformationSection,
  TagsSection,
  InsightSection,
  CommentsSection,
} from './sections';
import { ContactBookDetailsContext } from '../../context';
import { tabSections } from '../../form/formKeys';
import { ContactBookTabProps } from '../../types';

const defaultSections = Object.values(tabSections.details).map((sectionKey) => ({
  name: sectionKey,
  collapsed: true,
}));

const ContactBookDetailsTab = ({ editMode, isNew, sectionValidation }: ContactBookTabProps) => {
  const { t } = useTranslation();
  const isTablet = useMedia('(max-width: 1023px)');

  const contactBook = useContext(ContactBookDetailsContext) as ContactBookDetails;
  const [getCommentsCount, { data: commentsCount = 0 }] = contactBookApi.useLazyGetCommentsCountQuery();

  useEffect(() => {
    if (contactBook?.id) {
      getCommentsCount(contactBook.id, true);
    }
  }, [getCommentsCount, contactBook?.id]);

  return (
    <MainContentContainer id="contactBook-details">
      <ContentContainer withSide={!isNew}>
        <DetailsSectionCollapse
          defaultSections={defaultSections}
          keepDefault={isNew}
          objectType={OBJECT_TYPES.CONTACT_BOOK}
        >
          <DetailsSectionPanel
            header={t('contactBook:sections.contactInformation')}
            key={tabSections.details.contactInformation!}
            sectionKey={tabSections.details.contactInformation!}
            forceRender={editMode}
            invalidFields={sectionValidation?.contactInformation}
          >
            <ContactInformationSection editMode={editMode} />
          </DetailsSectionPanel>
          <DetailsSectionPanel
            header={t('contactBook:sections.professionalInformation')}
            key={tabSections.details.professionalInformation!}
            sectionKey={tabSections.details.professionalInformation!}
            invalidFields={sectionValidation?.professionalInformation}
            forceRender={editMode}
          >
            <ProfessionalInformationSection editMode={editMode} />
          </DetailsSectionPanel>
          <DetailsSectionPanel
            header={`${t('contactBook:sections.comments')} (${commentsCount})`}
            key={tabSections.details.comments!}
            sectionKey={tabSections.details.comments!}
            invalidFields={sectionValidation?.comments}
          >
            <CommentsSection editMode={!!contactBook?.id} />
          </DetailsSectionPanel>
          {isTablet && (
            <>
              <DetailsSectionPanel
                header={t('contactBook:sections.tags')}
                key={tabSections.details.tags!}
                sectionKey={tabSections.details.tags!}
                invalidFields={sectionValidation?.tags}
                forceRender={editMode}
              >
                <TagsSection editMode={editMode} />
              </DetailsSectionPanel>
              <DetailsSectionPanel
                header={t('contactBook:sections.insight')}
                key={tabSections.details.insight!}
                sectionKey={tabSections.details.insight!}
                invalidFields={sectionValidation?.insight}
                forceRender={editMode}
              >
                <InsightSection editMode={editMode} />
              </DetailsSectionPanel>
            </>
          )}
        </DetailsSectionCollapse>
      </ContentContainer>
      {!isTablet && (
        <SideContentContainer>
          <DetailsSectionCollapse
            defaultSections={defaultSections}
            keepDefault={isNew}
            objectType={OBJECT_TYPES.CONTACT_BOOK}
          >
            <DetailsSectionPanel
              header={t('contactBook:sections.tags')}
              key={tabSections.details.tags!}
              sectionKey={tabSections.details.tags!}
              invalidFields={sectionValidation?.tags}
              forceRender={editMode}
            >
              <TagsSection editMode={editMode} />
            </DetailsSectionPanel>
            <DetailsSectionPanel
              header={t('contactBook:sections.insight')}
              key={tabSections.details.insight!}
              sectionKey={tabSections.details.insight!}
              invalidFields={sectionValidation?.insight}
              forceRender={editMode}
            >
              <InsightSection editMode={editMode} />
            </DetailsSectionPanel>
          </DetailsSectionCollapse>
        </SideContentContainer>
      )}
    </MainContentContainer>
  );
};

export default ContactBookDetailsTab;
