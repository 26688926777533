import { ReactComponent as MyBlueLabelLogo } from './Logo.svg';
import { ReactComponent as NordicEyeLogo } from './Nordic-Eye.svg';

export { ReactComponent as LoginLogo } from './Logo-Login.svg';
export { MyBlueLabelLogo, NordicEyeLogo };
export { ReactComponent as MyBlueLabelIcon } from './mbl-icon.svg';
export { default as SecurityState } from './security-state.png';

export const CLIENT_LOGO = {
  nordic: NordicEyeLogo,
  mbl: MyBlueLabelLogo,
};

export const DEFAULT_LOGO = MyBlueLabelLogo;
export { default as LoginImage } from './login-image.jpg';
export { ReactComponent as ContactBookIcon } from './ContactBook.svg';
export { ReactComponent as DealBaseIcon } from './DealBase.svg';
export { ReactComponent as PortfolioIcon } from './Portfolio.svg';
export { ReactComponent as MyConversationIcon } from './My-Conversation.svg';
export { ReactComponent as DesignAndDevelopmentIcon } from './Design-and-development.svg';
export { default as error404 } from './error-404.png';
