import React from 'react';

import { Select } from 'antd';
import { useTranslation } from 'react-i18next';

import { FilterOperatorComponent, FiltersContainer, FiltersTitle } from 'common-ui/filters';
import { ContactBookFilters, dealBaseApi, FilterOperator } from 'core/lib';
import LoaderWrapper from 'utils/loader-wrapper';

type SharedCasesFilterProps = {
  onChange: (filters: Partial<ContactBookFilters>) => void;
  filters: number[] | undefined;
  operator: FilterOperator;
  operatorKey: string;
};

const SharedCasesFilter = ({ onChange, filters, operator, operatorKey }: SharedCasesFilterProps) => {
  const { t } = useTranslation();
  const { data: DealBaseOptions, isFetching } = dealBaseApi.useGetDealBaseOptionsQuery();

  return (
    <FiltersContainer>
      <FiltersTitle title={t('contactBook:filters.sharedCases')} />
      <LoaderWrapper loading={isFetching} message={t('loaders:filters.loadingDealBaseFilters')}>
        <FilterOperatorComponent onChange={onChange} operator={operator} filterKey={operatorKey} />
        <Select
          mode="multiple"
          className="w-full"
          allowClear
          showSearch={false}
          options={DealBaseOptions}
          value={filters}
          placeholder={t('global:placeholders.selectType', { type: t('contactBook:filters.sharedCases') })}
          onChange={(sharedCases) => onChange({ sharedCases })}
        />
      </LoaderWrapper>
    </FiltersContainer>
  );
};

export default SharedCasesFilter;
