import React, { useEffect, useState } from 'react';

import { PaperClipOutlined } from '@ant-design/icons';
import { List, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useMedia } from 'react-use';

import { Attachment, baseApi } from 'core/lib';
import { downloadBase64File } from 'utils';

const { Link, Text } = Typography;

type AttachmentsListProps = {
  attachments?: (Attachment & { title: string })[];
};

const AttachmentsList = ({ attachments = [] }: AttachmentsListProps) => {
  const { t } = useTranslation('actions');
  const isMobile = useMedia('(max-width: 768px)');
  const [downloadName, setDownloadName] = useState<string | null>(null);

  const [getFile, { data, isSuccess }] = baseApi.useLazyDownloadBase64Query();

  const onDownloadFileClick = (id: number, filename: string) => {
    setDownloadName(filename);
    getFile(id, true);
  };

  useEffect(() => {
    if (isSuccess && data && downloadName) {
      downloadBase64File(data, downloadName);
      setDownloadName(null);
    }
  }, [isSuccess, data, downloadName]);

  return (
    <List
      itemLayout={isMobile ? 'vertical' : 'horizontal'}
      dataSource={attachments}
      renderItem={(item) => {
        const actions = [
          <Text key="dataIndex" ellipsis>
            {item.title}
          </Text>,
          <Link onClick={() => onDownloadFileClick(item.id, item.name)} key="download-attachment">
            {t('global.download')}
          </Link>,
        ];
        return (
          <List.Item actions={actions}>
            <List.Item.Meta avatar={<PaperClipOutlined />} title={<Text ellipsis>{item.name}</Text>} />
          </List.Item>
        );
      }}
    />
  );
};

export default AttachmentsList;
