import React from 'react';

import { Select, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { PortfolioStatusType, PORTFOLIO_STATUSLIST } from 'core/lib/constants/statuses';
import { PortfolioFilters } from 'core/lib/modules/portfolio/entities/filters';

const { Title } = Typography;

type StatusFilterProps = {
  onChange: (filters: Partial<PortfolioFilters>) => void;
  filters: PortfolioStatusType[] | undefined;
};

const StatusFilter = ({ onChange, filters }: StatusFilterProps) => {
  const { t } = useTranslation();

  const statusOptions = PORTFOLIO_STATUSLIST.map((statusItem) => ({
    value: statusItem.status,
    label: t(`portfolio:status.label.${statusItem.key}`),
  }));

  return (
    <div>
      <Title level={5}>{t('portfolio:filters.statusCount', { count: filters?.length })}</Title>
      <Select
        mode="multiple"
        className="w-full"
        allowClear
        showSearch={false}
        options={statusOptions}
        value={filters}
        placeholder={t('global:placeholders.selectType', { type: t('portfolio:filters.status') })}
        onChange={(status) => onChange({ status })}
      />
    </div>
  );
};

export default StatusFilter;
