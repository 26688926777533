import React, { useContext, useEffect, useState } from 'react';

import { Badge, Collapse, CollapsePanelProps } from 'antd';

import { DetailsSectionWrapper } from 'common-ui/wrappers';
import { FormValidationsContext } from 'utils/form/formUtils';

const { Panel } = Collapse;

type DetailsSectionPanelProps = {
  sectionKey: string;
  header: string;
  invalidFields?: boolean;
  forceRender?: boolean;
  extra?: React.ReactNode;
  children: React.ReactNode;
  maxWidthDisabled?: boolean;
};

const useFormValidation = (key: string) => {
  const formValidations = useContext(FormValidationsContext);
  const [sectionValidation, setSectionValidation] = useState(false);

  useEffect(() => {
    setSectionValidation(!!formValidations?.sections[key]);
  }, [formValidations?.sections, key]);

  return sectionValidation;
};

const DetailsSectionPanel = ({
  sectionKey,
  header,
  forceRender = false,
  children,
  maxWidthDisabled = false,
  invalidFields,
  ...props
}: DetailsSectionPanelProps & CollapsePanelProps) => {
  const badgeDot = useFormValidation(sectionKey);

  return (
    <Panel
      forceRender={forceRender}
      header={
        <Badge offset={[5, 0]} className="color-inherit" dot={invalidFields ?? badgeDot}>
          {header}
        </Badge>
      }
      {...props}
    >
      {maxWidthDisabled ? children : <DetailsSectionWrapper>{children}</DetailsSectionWrapper>}
    </Panel>
  );
};

export default DetailsSectionPanel;
