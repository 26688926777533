import React from 'react';

// import { App } from '@capacitor/app';

type CapacitorAppProps = {
  children: React.ReactNode;
};

const CapacitorApp = ({ children }: CapacitorAppProps) => {
  // const handleAppBackButton = () => {
  //   if (window.history.length === 0) {
  //     App.exitApp();
  //   } else {
  //     window.history.back();
  //   }
  // };
  // App.addListener('backButton', handleAppBackButton);

  return <>{children}</>;
};

export default CapacitorApp;
