import React from 'react';

import { Typography } from 'antd';
import { Trans } from 'react-i18next';

const { Text } = Typography;

type PopoverFieldLabelProps = {
  children?: React.ReactNode;
  i18nKey?: string;
};

const PopoverFieldLabel = ({ i18nKey, children }: PopoverFieldLabelProps) => {
  return <Text type="secondary">{children ?? <Trans i18nKey={i18nKey} />}</Text>;
};

export default PopoverFieldLabel;
