import React from 'react';

import { List } from 'antd';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';

import { DateSince } from 'common-ui';
import { UserNotification } from 'core/lib';
import { Routes } from 'routes/routes';

import { NotificationCardConversationIcon, NotificationCardWrapper } from '../components';

type ConversationAddCardProps = {
  notification: UserNotification;
  onClick: (id: number) => void;
};

const ConversationAddCard = ({ notification, onClick }: ConversationAddCardProps) => {
  const { t } = useTranslation();

  const [type] = notification.action.split('_');

  const route = type === 'group' ? Routes.CONVERSATIONS_GROUP_DETAILS.path : Routes.CONVERSATIONS_MODULE_DETAILS.path;
  const routePath = generatePath(route, { id: notification.refId });

  return (
    <NotificationCardWrapper
      routePath={routePath}
      queryStringParams={undefined}
      key={notification.id}
      notification={notification}
      onClick={onClick}
      hasQueryParams={false}
    >
      <List.Item.Meta
        avatar={<NotificationCardConversationIcon />}
        title={
          <div className="gap-2">
            {`${t(`notifications:conversations.addedToConversation`)} `}
            {<span className="text-primary bold">{notification.objectName}</span>}
            {` ${t(`notifications:conversations.${type}`).toLocaleLowerCase()}`}
          </div>
        }
        description={<DateSince date={notification.dateCreated} />}
      />
    </NotificationCardWrapper>
  );
};

export default ConversationAddCard;
