import React, { useContext, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { ContentContainer, MainContentContainer } from 'common-ui/containers';
import { ActivityList } from 'common-ui/lists';
import { dealBaseApi } from 'core/lib';
import { DealBaseDetailsContext } from 'modules/dealBase/context';
import LoaderWrapper from 'utils/loader-wrapper';

const DealBaseActivityTab = () => {
  const dealbase = useContext(DealBaseDetailsContext);
  const { t } = useTranslation();
  const [getHistory, { data, isFetching }] = dealBaseApi.useLazyGetHistoryQuery();

  useEffect(() => {
    if (dealbase?.id) {
      getHistory(dealbase.id);
    }
  }, [dealbase?.id, getHistory]);

  return (
    <MainContentContainer id="dealbase-activity">
      <ContentContainer>
        <LoaderWrapper loading={isFetching} message={t('loaders:dealBase.loadingActivity')}>
          <ActivityList data={data ?? []} />
        </LoaderWrapper>
      </ContentContainer>
    </MainContentContainer>
  );
};

export default DealBaseActivityTab;
