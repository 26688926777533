import React from 'react';

import { Button, ButtonProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { generatePath, Link } from 'react-router-dom';

import { SYSTEM_ROLES } from 'core/lib/constants';
import { Routes } from 'routes/routes';
import PermissionControl, { PermissionRules } from 'utils/permissionControl';

type ViewUserProfileButtonProps = {
  userId: number;
};

const ViewUserProfileButton = ({ userId, ...buttonProps }: ViewUserProfileButtonProps & ButtonProps) => {
  const { t } = useTranslation();

  return (
    <PermissionControl rule={PermissionRules.ANY} roles={[SYSTEM_ROLES.CLIENT_ADMIN, SYSTEM_ROLES.SUPER_ADMIN]}>
      <Link to={generatePath(Routes.USER_DETAILS.path, { id: userId })}>
        <Button className="w-full" type="primary" {...buttonProps}>
          {t('actions:global.viewProfile')}
        </Button>
      </Link>
    </PermissionControl>
  );
};

export default ViewUserProfileButton;
