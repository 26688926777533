import { omit } from 'lodash';

import countries from '../../utils/config/countries';
import currencies from '../../utils/config/currencies';
import i18n from '../../utils/i18n';
import { INDEXED_SYSTEM_ROLES, SYSTEM_ROLES_ID } from '../systemRoles';

export const CountryOptions = countries
  .map((c) => c.country)
  .sort()
  .map((country) => ({ label: country, value: country }));

export const getCountriesOptionsByRegion = (region: string) =>
  countries
    .filter((c) => c.region === region)
    .map((c) => c.country)
    .sort()
    .map((country) => ({ label: country, value: country }));

export const RegionOptions = [...new Set(countries.map((c) => c.region))]
  .sort()
  .map((region) => ({ label: region, value: region }));

export const CurrencyOptions = Object.entries(currencies).map(([code, currency]) => ({
  label: currency.name,
  value: code,
}));

export const RolesOptions = Object.entries(omit(INDEXED_SYSTEM_ROLES, [SYSTEM_ROLES_ID.SUPER_ADMIN])).map(
  ([systemRoleId, roleName]) => ({
    id: parseInt(systemRoleId),
    label: roleName,
    value: parseInt(systemRoleId),
    disabled: false,
  })
);

export const LanguageOptions = [
  {
    label: i18n.t('global:languages.english'),
    value: 'en',
  },
];
