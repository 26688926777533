import React from 'react';

import { Select } from 'antd';
import { useTranslation } from 'react-i18next';

import { FiltersContainer, FiltersTitle } from 'common-ui/filters';
import { contactBookApi, ContactBookFilters } from 'core/lib';

type TagsFilterProps = {
  onChange: (filters: Partial<ContactBookFilters>) => void;
  filters: string[] | undefined;
};

const TagsFilter = ({ onChange, filters }: TagsFilterProps) => {
  const { t } = useTranslation();

  const { data = [] } = contactBookApi.useGetContactBookTagsQuery();

  const tagOptions = data.map((tag) => ({
    value: tag,
    label: tag,
  }));

  return (
    <FiltersContainer>
      <FiltersTitle title={t('contactBook:filters.tags')} />
      <Select
        mode="multiple"
        className="w-full"
        allowClear
        showSearch={false}
        options={tagOptions}
        value={filters}
        placeholder={t('global:placeholders.selectType', { type: t('contactBook:filters.tags') })}
        onChange={(tags) => onChange({ tags })}
      />
    </FiltersContainer>
  );
};

export default TagsFilter;
