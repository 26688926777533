import store from 'core/lib/store';

import { onMessageReceived } from './conversations.slice';
import { conversationsApi } from '../services';
import { deserializeConversationMessage } from '../services/serializers';

export const messageReceived = (messageResponse: string) => {
  store.dispatch(onMessageReceived(deserializeConversationMessage(JSON.parse(messageResponse))));
};

export const moduleMenuUpdate = () => {
  store.dispatch(conversationsApi.endpoints.getMyMenuModuleConversations.initiate(undefined, { forceRefetch: true }));
};

export const groupMenuUpdate = () => {
  store.dispatch(conversationsApi.endpoints.getMyMenuGroupConversations.initiate(undefined, { forceRefetch: true }));
};

export const directMenuUpdate = () => {
  store.dispatch(conversationsApi.endpoints.getMyMenuDirectConversations.initiate(undefined, { forceRefetch: true }));
};

export const moduleConversationsListingUpdate = () => {
  store.dispatch(conversationsApi.endpoints.getModuleConversationsListing.initiate(undefined, { forceRefetch: true }));
};

export const moduleConversationDetailsUpdate = (conversationId: number) => {
  store
    .dispatch(conversationsApi.endpoints.getModuleConversation.initiate(conversationId, { forceRefetch: true }))
    .unwrap()
    .then((conversationDetails) => {
      const fetchModuleConversationPermission = conversationsApi.endpoints.getModuleConversationPermission.initiate(
        conversationDetails.id,
        { forceRefetch: true }
      );
      store.dispatch(fetchModuleConversationPermission);
    });
};

export const groupConversationDetailsUpdate = (conversationId: number) => {
  const fetchGroupConversationDetails = conversationsApi.endpoints.getGroupConversation.initiate(conversationId, {
    forceRefetch: true,
  });

  store
    .dispatch(fetchGroupConversationDetails)
    .unwrap()
    .then((conversationDetails) => {
      const fetchGroupConversationPermission = conversationsApi.endpoints.getGroupConversationPermission.initiate(
        conversationDetails.id,
        { forceRefetch: true }
      );
      store.dispatch(fetchGroupConversationPermission);
    });
};

export const directConversationDetailsUpdate = (conversationId: number) => {
  const fetchDirectConversationDetails = conversationsApi.endpoints.getDirectConversation.initiate(conversationId, {
    forceRefetch: true,
  });

  store
    .dispatch(fetchDirectConversationDetails)
    .unwrap()
    .then((conversationDetails) => {
      const fetchDirectConversationPermission = conversationsApi.endpoints.getDirectConversationPermission.initiate(
        conversationDetails.id,
        { forceRefetch: true }
      );
      store.dispatch(fetchDirectConversationPermission);
    });
};

export const unreadModuleConversationMessage = (conversationId: number) => {
  store.dispatch(
    conversationsApi.util.updateQueryData('getMyMenuModuleConversations', undefined, (draft) => {
      const index = draft.findIndex((d) => d.conversationId === conversationId);

      if (draft[index]) {
        draft[index].undreadMessages = draft[index].undreadMessages + 1;
      }
    })
  );
};

export const unreadGroupConversationMessage = (conversationId: number) => {
  store.dispatch(
    conversationsApi.util.updateQueryData('getMyMenuGroupConversations', undefined, (draft) => {
      const index = draft.findIndex((d) => d.conversationId === conversationId);
      if (draft[index]) {
        draft[index].undreadMessages = draft[index].undreadMessages + 1;
      }
    })
  );
};

export const unreadDirectConversationMessage = (conversationId: number) => {
  store.dispatch(
    conversationsApi.util.updateQueryData('getMyMenuDirectConversations', undefined, (draft) => {
      const index = draft.findIndex((d) => d.conversationId === conversationId);
      if (draft[index]) {
        draft[index].undreadMessages = draft[index].undreadMessages + 1;
      } else {
        const fetchMyMenuDirectConversations = conversationsApi.endpoints.getMyMenuDirectConversations.initiate(
          undefined,
          { forceRefetch: true }
        );

        store.dispatch(fetchMyMenuDirectConversations);
      }
    })
  );
};

export const globalConversationUnreadMessage = () => {
  store.dispatch(
    conversationsApi.util.updateQueryData('getConversationGlobalUnreadMessages', undefined, (draft) => {
      return true;
    })
  );
};
