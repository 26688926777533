import { subYears, format, addYears } from 'date-fns';

const InfoSources = [
  'Facebook',
  'LinkedIn',
  'Press',
  'www.nordiceye.com',
  'Network',
  'Nordic Eye team member',
  'Unsolicited',
  'Other',
];

export const InfoSourceOptions = InfoSources.map((source) => ({ label: source, value: source }));

export const PriorityOptions = Array.from({ length: 5 }, (_, i) => {
  return { label: i + 1, value: i + 1 };
});

export const TierOptions = Array.from({ length: 5 }, (_, i) => {
  return { label: i + 1, value: i + 1 };
});

export const FoundingYearsOptions = Array.from({ length: 50 }, (_, i) => {
  const year = format(subYears(new Date(), i), 'yyyy');
  return { label: year, value: parseInt(year) };
});

export const RevenueYearsOption = Array.from({ length: 5 }, (_, i) => {
  const year = format(subYears(new Date(), i), 'yyyy');
  let label = year;
  if (!i) {
    label = `${label} YTD`;
  }
  return { label, value: parseInt(year) };
});

export const RevenueProjectionYearsOption = [
  ...Array.from({ length: 5 }, (_, i) => {
    const year = format(subYears(new Date(), i), 'yyyy');

    return { label: year, value: parseInt(year) };
  }),
  ...Array.from({ length: 4 }, (_, i) => {
    const year = format(addYears(new Date(), i + 1), 'yyyy');

    return { label: year, value: parseInt(year) };
  }),
].sort((a, b) => b.value - a.value);

export const EbitdaYearsOption = Array.from({ length: 5 }, (_, i) => {
  const year = format(subYears(new Date(), i), 'yyyy');
  let label = year;
  if (!i) {
    label = `${label} YTD`;
  }
  return { label, value: parseInt(year) };
});

export const EbitdaProjectionYearsOption = [
  ...Array.from({ length: 5 }, (_, i) => {
    const year = format(subYears(new Date(), i), 'yyyy');

    return { label: year, value: parseInt(year) };
  }),
  ...Array.from({ length: 4 }, (_, i) => {
    const year = format(addYears(new Date(), i + 1), 'yyyy');

    return { label: year, value: parseInt(year) };
  }),
].sort((a, b) => b.value - a.value);
