import React from 'react';

import { Form, Typography } from 'antd';
import { Trans } from 'react-i18next';

import colors from 'core/lib/theme/colors';
import { contactBookFormConfig } from 'modules/contactBook/form/formConfig';

const { Text } = Typography;

const {
  details: { professionalInformation },
} = contactBookFormConfig;

type ProfessionalInformationDiligencePartnerProps = {
  editMode: boolean;
};

const ProfessionalInformationDiligencePartner = ({ editMode }: ProfessionalInformationDiligencePartnerProps) => {
  return (
    <div className="flex flex-col">
      <Text className="my-4" style={{ color: colors.Primary.rgba }}>
        <Trans i18nKey="contactBook:types.diligencePartner" />
      </Text>
      <div className="flex sm:flex-row flex-col gap-x-4">
        <Form.Item
          className="w-full"
          label={professionalInformation.diligenceType.label}
          name={professionalInformation.diligenceType.dataIndex}
          rules={professionalInformation.diligenceType.rules}
        >
          <professionalInformation.diligenceType.component disabled={!editMode} />
        </Form.Item>
      </div>
    </div>
  );
};

export default ProfessionalInformationDiligencePartner;
