import { deserializeListingConfig } from './serializers';

const API_V2 = 'api/v2';

const CONTROLLER = 'listingConfig';

export const TAGS = {
  ListingConfigTag: 'ListingConfig',
};
const endpoints = {
  getListingConfig: {
    url: `${API_V2}/${CONTROLLER}/getUserListingConfig`,
    tag: TAGS.ListingConfigTag,
    deserializer: deserializeListingConfig,
  },
  updateListingConfig: {
    url: `${API_V2}/${CONTROLLER}/updateUserListingConfig`,
    invalidates: TAGS.ListingConfigTag,
  },
};

export default endpoints;
