import React from 'react';

import { Popover, PopoverProps } from 'antd';

type TagsGroupProps = {
  children: React.ReactElement[];
};

const TagsGroup = ({ children, ...props }: TagsGroupProps & PopoverProps) => {
  return (
    <Popover
      placement="topLeft"
      content={<div className="flex flex-wrap w-auto max-w-96 gap-y-2 gap-1">{children}</div>}
      {...props}
    >
      {children}
    </Popover>
  );
};

export default TagsGroup;
