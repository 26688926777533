import { HTMLContent, JSONContent } from '@tiptap/core';

export const parseStringToContent = (rawContent: string): JSONContent | HTMLContent | string => {
  const result = `<p>${rawContent.replace(/\t/g, '&nbsp').replace(/\n/g, '<br />')}</p>`;
  try {
    const parsedContent = JSON.parse(rawContent);
    if (typeof parsedContent !== 'string') {
      return parsedContent;
    }
  } catch {
    return result;
  }
  return result;
};
