import React from 'react';

import { List, Typography } from 'antd';
import { Trans } from 'react-i18next';
import { generatePath } from 'react-router-dom';

import { DateSince } from 'common-ui';
import { UserNotification } from 'core/lib';
import colors from 'core/lib/theme/colors';
import { Routes } from 'routes/routes';

import { NotificationCardConversationIcon, NotificationCardWrapper } from '../components';

const { Text } = Typography;

type ConversationCreateCardProps = {
  notification: UserNotification;
  onClick: (id: number) => void;
};

const ConversationCreateCard = ({ notification, onClick }: ConversationCreateCardProps) => {
  const route = Routes.CONVERSATIONS_MODULE_DETAILS.path;
  const routePath = generatePath(route, { id: notification.refId });

  return (
    <NotificationCardWrapper
      routePath={routePath}
      queryStringParams={undefined}
      key={notification.id}
      notification={notification}
      onClick={onClick}
      hasQueryParams={false}
    >
      <List.Item.Meta
        avatar={<NotificationCardConversationIcon />}
        title={
          <Trans
            i18nKey="notifications:conversations.conversationCreated"
            values={{ objectName: notification.objectName }}
            shouldUnescape={true}
            components={{ linkText: <Text style={{ color: colors.Primary.rgba }}></Text> }}
          />
        }
        description={<DateSince date={notification.dateCreated} />}
      />
    </NotificationCardWrapper>
  );
};

export default ConversationCreateCard;
