import React from 'react';

import { PageHeader, Typography } from 'antd';

const { Title } = Typography;

type ListingHeaderProps = {
  count: number;
  title: string;
  extra?: React.ReactNode[];
};

const ListingHeader = ({ count, title, extra }: ListingHeaderProps) => {
  return (
    <PageHeader
      className="listing-header"
      title={
        <div className="flex flex-row gap-4 items-baseline">
          <Title style={{ margin: 0 }} level={4}>
            {title}
          </Title>
          <Title style={{ margin: 0 }} type="secondary" level={5}>
            {count}
          </Title>
        </div>
      }
      extra={extra}
    />
  );
};

export default ListingHeader;
