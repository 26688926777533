import React, { useContext } from 'react';

import { Form } from 'antd';

import { OrganizationOption, PortfolioDetails, UserOption } from 'core/lib';
import { SYSTEM_ROLES_ID } from 'core/lib/constants';

import { PortfolioDetailsContext } from '../../../context';
import { portfolioFormConfig } from '../../../form/formConfig';

const {
  generalInformation: { ownership },
} = portfolioFormConfig;

type OwnershipSectionProps = {
  editMode: boolean;
};

const PortfolioOwnershipSection = ({ editMode }: OwnershipSectionProps) => {
  const portfolio = useContext(PortfolioDetailsContext) as Partial<PortfolioDetails>;

  return (
    <div className="flex flex-col">
      <div className="flex sm:flex-row flex-col gap-x-4">
        <Form.Item
          className="md:w-3/6"
          label={ownership.organization.label}
          name={ownership.organization.dataIndex}
          rules={ownership.organization.rules}
          valuePropName="selectedOption"
          normalize={(option: OrganizationOption, _, draftPortfolio) => {
            draftPortfolio.owner = null;
            draftPortfolio.taskOwner = null;
            draftPortfolio.responsible = null;
            return option ? { id: option.id, name: option.label } : null;
          }}
        >
          <ownership.organization.component asTag disabled={!editMode} />
        </Form.Item>
        <Form.Item
          className="md:w-3/6"
          label={ownership.owner.label}
          name={ownership.owner.dataIndex}
          tooltip={editMode && ownership.owner.tooltip}
          rules={ownership.owner.rules}
          valuePropName="selectedOption"
          normalize={(option: UserOption) => {
            return option ? { id: option.id, name: option.label } : null;
          }}
        >
          <ownership.owner.component
            organizationIds={portfolio.organization && [portfolio.organization?.id]}
            roleIds={[SYSTEM_ROLES_ID.SYSTEM_OWNER]}
            asAvatar
            disabled={!editMode || !portfolio.organization}
          />
        </Form.Item>
      </div>
      <div className="flex sm:flex-row flex-col gap-x-4">
        <Form.Item
          className="md:w-3/6"
          label={ownership.responsible.label}
          name={ownership.responsible.dataIndex}
          tooltip={editMode && ownership.responsible.tooltip}
          rules={ownership.responsible.rules}
          valuePropName="selectedOption"
          normalize={(option: UserOption) => {
            return option ? { id: option.id, name: option.label } : null;
          }}
        >
          <ownership.responsible.component
            organizationIds={portfolio.organization && [portfolio.organization?.id]}
            roleIds={[SYSTEM_ROLES_ID.SYSTEM_OWNER]}
            asAvatar
            disabled={!editMode || !portfolio.organization}
          />
        </Form.Item>
        <Form.Item
          className="md:w-3/6"
          label={ownership.taskOwner.label}
          name={ownership.taskOwner.dataIndex}
          tooltip={editMode && ownership.taskOwner.tooltip}
          rules={ownership.taskOwner.rules}
          valuePropName="selectedOption"
          normalize={(option: UserOption) => {
            return option ? { id: option.id, name: option.label } : null;
          }}
        >
          <ownership.taskOwner.component
            organizationIds={portfolio.organization && [portfolio.organization?.id]}
            roleIds={[SYSTEM_ROLES_ID.SYSTEM_OWNER]}
            asAvatar
            disabled={!editMode || !portfolio.organization}
          />
        </Form.Item>
      </div>
    </div>
  );
};

export default PortfolioOwnershipSection;
