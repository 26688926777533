import React, { useEffect } from 'react';

import { Route, Switch, Redirect, generatePath } from 'react-router-dom';

import { selectUserPreferenceMenuDefaultType, setActiveModule, useAppDispatch, useAppSelector } from 'core/lib';
import { OBJECT_TYPES } from 'core/lib/constants';
import { Routes } from 'routes/routes';
import { getModulePath } from 'utils';
import ModuleControl from 'utils/moduleControl';

import { ListingContainer } from './containers';
import DealBaseDetails from './details';
import DealBaseNew from './new';

const DealBase = () => {
  const userPreferenceMenuDefaultType = useAppSelector(selectUserPreferenceMenuDefaultType);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setActiveModule(OBJECT_TYPES.DEAL_BASE));
    return () => {
      dispatch(setActiveModule(null));
    };
  }, [dispatch]);

  const redirectPath =
    getModulePath(OBJECT_TYPES.DEAL_BASE, userPreferenceMenuDefaultType) ?? generatePath(Routes.DEAL_BASE_LISTING.path);

  return (
    <ModuleControl redirect module={OBJECT_TYPES.DEAL_BASE}>
      <Switch>
        <Route path={Routes.DEAL_BASE.path} exact render={() => <Redirect to={redirectPath} />} />
        <Route path={Routes.DEAL_BASE_DETAILS.path} component={DealBaseDetails} />
        <Route path={Routes.DEAL_BASE_NEW.path} component={DealBaseNew} />
        <ListingContainer />
      </Switch>
    </ModuleControl>
  );
};

export default DealBase;
