import { createSlice } from '@reduxjs/toolkit';

import { USER_STATUS } from 'core/lib/constants/statuses/user';

import { UserFilters } from '../entities/filters';

type UserState = {
  detailsActiveTab: string | null;
  editMode: boolean;
  filters: UserFilters;
  listingGrouping: string;
};

const initialState: UserState = {
  detailsActiveTab: null,
  editMode: false,
  filters: {
    status: [],
    roles: [],
    organizations: [],
  },
  listingGrouping: USER_STATUS.ACTIVE.toString(),
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserEditMode: (state, { payload }: { payload: boolean }) => {
      state.editMode = payload;
    },
    setUserFilters: (state, { payload }: { payload: Partial<UserFilters> }) => {
      Object.assign(state.filters, payload);
    },
    clearUserFilters: (state) => {
      Object.assign(state.filters, initialState.filters);
    },
    setUserDetailsActiveTab: (state, { payload }: { payload: string | null }) => {
      state.detailsActiveTab = payload;
    },
    setUserListingGrouping: (state, { payload }: { payload: string }) => {
      state.listingGrouping = payload;
    },
  },
});

export const { setUserEditMode, setUserFilters, setUserDetailsActiveTab, setUserListingGrouping, clearUserFilters } =
  userSlice.actions;

export default userSlice.reducer;
