import React from 'react';

import { Badge, BadgeProps } from 'antd';

import colors from 'core/lib/theme/colors';

const activeStyle = { backgroundColor: colors.Primary.alpha(0.1).rgba, color: colors.Primary.rgba };

const inactiveStyle = { backgroundColor: colors.Grey200.rgba, color: colors.Black.rgba };

type TextBadgeProps = {
  text: string;
  active?: boolean;
  count?: number;
  badgeProps?: BadgeProps;
};

const TextBadge = ({ text, count = 0, active, badgeProps = {} }: TextBadgeProps) => {
  const style = active ? activeStyle : inactiveStyle;
  return (
    <div className="flex flex-row items-center gap-1 text-inherit">
      {text}
      <Badge style={style} showZero overflowCount={999} count={count} {...badgeProps} />
    </div>
  );
};

export default TextBadge;
