import React from 'react';

import { useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router-dom';

import { conversationsApi } from 'core/lib';
import { Routes } from 'routes/routes';

type MenuItemDirectConversationProps = {
  userId: number;
};

const MenuItemDMCreate = ({ userId }: MenuItemDirectConversationProps) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [createConversation] = conversationsApi.useCreateDirectConversationMutation();

  const onDirectMessageClick = () => {
    createConversation({ userId })
      .unwrap()
      .then((directConvId) => {
        history.push(generatePath(Routes.CONVERSATIONS_DM_DETAILS.path, { id: directConvId }));
      });
  };
  return <div onClick={onDirectMessageClick}>{t('actions:global.directMessage')}</div>;
};

export default MenuItemDMCreate;
