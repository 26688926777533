import React from 'react';

import { Tag } from 'antd';

import { MODULE_SHORT_NAMES } from 'core/lib/constants';

type ModuleTagProps = {
  objectType: number;
  color?: string;
};

const ModuleTag = ({ objectType, color = 'blue' }: ModuleTagProps) => {
  return <Tag color={color}>{MODULE_SHORT_NAMES[objectType]}</Tag>;
};

export default ModuleTag;
