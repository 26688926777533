import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Pagination } from 'antd';
import { Document, Page, pdfjs } from 'react-pdf';
import { useMedia } from 'react-use';
import AutoSizer from 'react-virtualized-auto-sizer';

import { EmptyWrapper } from 'common-ui';
import LoaderWrapper from 'utils/loader-wrapper';

import PdfModalViewer from './pdfModalViewer';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const contentType = 'data:application/octet-stream;base64';

type PdfViewerProps = {
  isLoading: boolean;
  data?: string;
};

const PdfViewer = ({ isLoading, data }: PdfViewerProps) => {
  const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [currentHeight, setCurrentHeight] = useState(300);
  const isMobile = useMedia('(max-width: 768px)');
  const containerRef = useRef<HTMLDivElement | null>(null);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  useEffect(() => {
    if ((containerRef.current?.clientHeight ?? 0) > currentHeight) {
      setCurrentHeight(containerRef.current!.clientHeight);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerRef.current?.clientHeight]);

  const fileData = useMemo(() => {
    return `${contentType},${data?.replace(/^[^,]+,/, '')}`;
  }, [data]);

  return (
    <LoaderWrapper loading={isLoading} message="Loading PDF Document">
      <EmptyWrapper empty={!data}>
        <div>
          <AutoSizer style={{ width: 'auto' }} className="flex justify-center" disableHeight>
            {({ width }) => {
              const pdfWidth = width;
              return (
                <div
                  ref={containerRef}
                  className="flex justify-center"
                  style={{ width: pdfWidth - (isMobile ? 0 : 20) }}
                >
                  {data && (
                    <Document file={fileData} onLoadSuccess={onDocumentLoadSuccess}>
                      <Page
                        className="flex items-center"
                        renderMode="canvas"
                        width={pdfWidth - (isMobile ? 0 : 100)}
                        pageNumber={pageNumber}
                      />
                      <div className="flex items-center justify-center page-controls p-4 bottom-0 gap-4 sticky flex-auto">
                        <div className="flex gap-2 items-center rounded-3xl bg-white p-2 shadow">
                          <Pagination
                            simple
                            current={pageNumber}
                            hideOnSinglePage
                            responsive
                            pageSize={1}
                            total={numPages}
                            onChange={setPageNumber}
                          />
                          <PdfModalViewer numPages={numPages} data={fileData} />
                        </div>
                      </div>
                    </Document>
                  )}
                </div>
              );
            }}
          </AutoSizer>
        </div>
      </EmptyWrapper>
    </LoaderWrapper>
  );
};

export default PdfViewer;
