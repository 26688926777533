import React from 'react';

import { Input, InputProps } from 'antd';
import { RuleObject } from 'antd/lib/form';
import { Trans } from 'react-i18next';

import DatePicker, { RangePickerProps } from 'common-ui/datePicker';
import { OutOfOffice } from 'core/lib';
import { parseJSONDate } from 'core/lib/utils';
import { CountrySelect, OrganizationMultiSelect, rolesMultiSelector, UserSelect } from 'utils/form';
import { rules } from 'utils/form/rules';

export const userFormConfig = {
  generalInformation: {
    loginCredentials: {
      email: {
        label: <Trans i18nKey="user:form.loginCredentials.labels.email" />,
        dataIndex: 'email',
        rules: [rules.required, rules.email],
        component: (props: InputProps) => <Input autoComplete="off" type="email" maxLength={100} {...props} />,
      },
      password: {
        label: <Trans i18nKey="user:form.loginCredentials.labels.password" />,
        dataIndex: 'password',
        rules: [rules.password, rules.required],
        component: (props: InputProps) => <Input.Password autoComplete="new-password" maxLength={40} {...props} />,
      },
    },
    userPrivileges: {
      organizations: {
        label: <Trans i18nKey="user:form.userPrivileges.labels.organizations" />,
        dataIndex: 'organizations',
        rules: [rules.required],
        component: OrganizationMultiSelect,
      },
      roles: {
        label: <Trans i18nKey="user:form.userPrivileges.labels.roles" />,
        dataIndex: 'systemRoles',
        rules: [rules.required],
        component: rolesMultiSelector,
      },
      jobTitle: {
        label: <Trans i18nKey="user:form.userPrivileges.labels.jobTitle" />,
        dataIndex: 'jobTitle',
        rules: [],
        component: (props: InputProps) => <Input maxLength={40} {...props} />,
      },
    },
    personalInformation: {
      firstName: {
        label: <Trans i18nKey="user:form.personalInformation.labels.firstName" />,
        dataIndex: 'firstName',
        rules: [rules.required],
        component: (props: InputProps) => <Input maxLength={40} {...props} />,
      },
      lastName: {
        label: <Trans i18nKey="user:form.personalInformation.labels.lastName" />,
        dataIndex: 'lastName',
        rules: [rules.required],
        component: (props: InputProps) => <Input maxLength={40} {...props} />,
      },
      number: {
        label: <Trans i18nKey="user:form.personalInformation.labels.number" />,
        dataIndex: 'phone',
        rules: [],
        component: (props: InputProps) => <Input maxLength={40} {...props} />,
      },
      country: {
        label: <Trans i18nKey="user:form.personalInformation.labels.country" />,
        dataIndex: 'country',
        rules: [rules.required],
        component: CountrySelect,
      },
    },
    outOfOffice: {
      period: {
        label: <Trans i18nKey="user:form.outOfOffice.labels.period" />,
        dataIndex: 'outOfOffice',
        rules: [],
        component: (props: RangePickerProps<Date> & { value?: OutOfOffice }) => {
          return (
            <DatePicker.RangePicker
              {...props}
              value={[parseJSONDate(props.value?.from), parseJSONDate(props.value?.to)]}
            />
          );
        },
      },
      delegate: {
        label: <Trans i18nKey="user:form.outOfOffice.labels.delegate" />,
        dataIndex: 'delegate',
        rules: [],
        component: UserSelect,
      },
    },
  },
};

export const mandatoryTabFields: { [key: string]: { [key: string]: string[] } } = Object.entries(userFormConfig).reduce(
  (agg, [tabKey, tabObject]) => {
    const requiredSections = Object.keys(tabObject).reduce((aggSection, sectionKey) => {
      const sectionObject = tabObject[sectionKey];
      const requiredFields: string[] = [];
      if (sectionObject?.rules?.some((r: RuleObject) => r.required)) {
        requiredFields.push(sectionObject.dataIndex);
      }
      Object.keys(sectionObject).forEach((oKey) => {
        const fieldObject = sectionObject[oKey];
        if (fieldObject?.rules?.some((r: RuleObject) => r.required)) {
          requiredFields.push(fieldObject.dataIndex);
        }
      });
      if (requiredFields.length) {
        aggSection[sectionKey] = requiredFields;
      }
      return aggSection;
    }, {});
    if (Object.keys(requiredSections).length) {
      agg[tabKey] = requiredSections;
    }
    return agg;
  },
  {}
);
