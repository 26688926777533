import React from 'react';

import { Button, Empty } from 'antd';

type EmptyWrapperProps = {
  children?: React.ReactNode;
  empty: boolean;
  onClick?: () => void;
  description?: string;
  buttonText?: string;
};

const EmptyWrapper = ({ children, onClick, empty, description, buttonText }: EmptyWrapperProps) => {
  return (
    <>
      {empty || !children ? (
        <div className="flex items-center justify-center h-full">
          <Empty description={description} image={Empty.PRESENTED_IMAGE_SIMPLE}>
            {onClick && <Button type="primary">{buttonText}</Button>}
          </Empty>
        </div>
      ) : (
        children
      )}
    </>
  );
};

export default EmptyWrapper;
