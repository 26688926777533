import React, { useMemo } from 'react';

import { EllipsisOutlined } from '@ant-design/icons';
import { Button, Dropdown } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { DROPDOWN_TRIGGERS, MenuItemConversationAction } from 'common-ui';
import { OBJECT_TYPES } from 'core/lib/constants';
import { Routes } from 'routes/routes';

const ListingHeaderActions = () => {
  const { t } = useTranslation('actions');

  const items = useMemo(() => {
    return [
      {
        key: 'create_action',
        label: <Link to={Routes.DEAL_BASE_NEW.path}>{t('global.create')}</Link>,
      },
      { key: 'conversation_action', label: <MenuItemConversationAction objectType={OBJECT_TYPES.DEAL_BASE} /> },
    ];
  }, [t]);

  return (
    <Dropdown trigger={DROPDOWN_TRIGGERS} menu={{ items }}>
      <Button type="default">
        <EllipsisOutlined />
      </Button>
    </Dropdown>
  );
};

export default ListingHeaderActions;
