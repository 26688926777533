import React from 'react';

import { List } from 'antd';

import {
  setCustomFieldsMenuId,
  selectCustomFieldsMenuId,
  selectModulesWithCustomFields,
  useAppSelector,
  useAppDispatch,
} from 'core/lib';

const CustomFieldsLeftMenu = () => {
  const customFieldMenuItem = useAppSelector(selectCustomFieldsMenuId);
  const modulesCustomFields = useAppSelector(selectModulesWithCustomFields);
  const dispatch = useAppDispatch();

  const onItemClick = (objectType: number) => {
    dispatch(setCustomFieldsMenuId(objectType));
  };

  return (
    <List
      size="small"
      itemLayout="vertical"
      dataSource={modulesCustomFields}
      renderItem={(item) => (
        <List.Item
          className={`cursor-pointer hover:bg-gray-50 ${
            item.objectType === customFieldMenuItem ? 'text-primary bg-gray-50' : ''
          }`}
          onClick={() => onItemClick(item.objectType)}
        >
          {item.name}
        </List.Item>
      )}
    />
  );
};

export default CustomFieldsLeftMenu;
