import React from 'react';

import { Input, InputNumber, InputNumberProps, InputProps, Select, SelectProps, UploadProps } from 'antd';
import { TextAreaProps } from 'antd/lib/input';
import { Trans } from 'react-i18next';

import DatePicker, { PickerProps } from 'common-ui/datePicker';
import { ExternalInputLink } from 'common-ui/links';
import { AttachmentUpload, ImageUploader } from 'common-ui/upload';
import { Attachment, CommonObject } from 'core/lib';
import { DYNAMIC, MAX_BIGINT, MAX_INT, OBJECT_TYPES } from 'core/lib/constants';
import { EXTERNAL_ATTACHMENT_PREFIX, NORDIC_ATTACHMENT_PREFIX } from 'core/lib/constants/dealbase';
import {
  EbitdaProjectionYearsOption,
  EbitdaYearsOption,
  FoundingYearsOptions,
  InfoSourceOptions,
  PriorityOptions,
  RevenueProjectionYearsOption,
  RevenueYearsOption,
  TierOptions,
} from 'core/lib/constants/selects';
import { CurrencyOptions } from 'core/lib/constants/selects/general';
import { parseJSONDate } from 'core/lib/utils';
import i18n from 'core/lib/utils/i18n';
import { valueDelimiterFormatter } from 'utils';
import { CountrySelect, OrganizationSelect, UserMultiSelect, UserSelect } from 'utils/form';
import CommonObjectMultiSelect from 'utils/form/commonObjectMultiSelect';
import CommonObjectSelect from 'utils/form/commonObjectSelect';
import InputSuggestions from 'utils/form/inputSuggestions';
import { rules } from 'utils/form/rules';
import {
  ebitdaFinancialValidator,
  founderValidator,
  revenueFinancialValidator,
  scorecardValidator,
} from 'utils/validators/dealbase';

const { TextArea } = Input;

export const dealBaseFormConfig = {
  generalInformation: {
    generalInformation: {
      name: {
        label: <Trans i18nKey="dealbase:form.generalInformation.labels.companyName" />,
        dataIndex: 'name',
        rules: [rules.required],
        component: (props: InputProps) => <Input maxLength={40} {...props} />,
      },
      companyLogo: {
        label: <Trans i18nKey="dealbase:form.generalInformation.labels.companyLogo" />,
        dataIndex: 'companyLogo',
        rules: [],
        component: ({ fileList, ...props }: UploadProps & { value?: Attachment }) => (
          <ImageUploader dataIndex="companyLogo" objectType={OBJECT_TYPES.DEAL_BASE} {...props} />
        ),
      },
      industry: {
        label: (
          <Trans
            i18nKey="dealbase:form.generalInformation.labels.industry"
            values={{ range: i18n.t('global:labels.selectRange', { range: '1-3' }) }}
          />
        ),
        dataIndex: 'industries',
        rules: [],
        component: ({ selectedOption, ...props }: SelectProps<number[]> & { selectedOption?: CommonObject[] }) => (
          <CommonObjectMultiSelect
            {...props}
            archived={false}
            max={3}
            asTags
            color="green"
            type={DYNAMIC.DEAL_BASE.INDUSTRY}
            objectType={OBJECT_TYPES.DEAL_BASE}
            selectedOption={selectedOption}
          />
        ),
      },
      technology: {
        label: <Trans i18nKey="dealbase:form.generalInformation.labels.technology" />,
        dataIndex: 'technology',
        rules: [],
        component: ({ selectedOption, ...props }: SelectProps<number> & { selectedOption?: CommonObject }) => (
          <CommonObjectSelect
            {...props}
            archived={false}
            asTag
            color="volcano"
            type={DYNAMIC.DEAL_BASE.TECHNOLOGY}
            objectType={OBJECT_TYPES.DEAL_BASE}
            selectedOption={selectedOption}
          />
        ),
      },
      customerFocus: {
        label: <Trans i18nKey="dealbase:form.generalInformation.labels.customerFocus" />,
        dataIndex: 'customerFocus',
        rules: [],
        component: (props: SelectProps<number[]> & { selectedOption?: CommonObject[] }) => (
          <CommonObjectMultiSelect
            {...props}
            archived={false}
            asTags
            color="cyan"
            type={DYNAMIC.DEAL_BASE.CUSTOMER_FOCUS}
            objectType={OBJECT_TYPES.DEAL_BASE}
          />
        ),
      },
      country: {
        label: <Trans i18nKey="dealbase:form.generalInformation.labels.country" />,
        dataIndex: 'country',
        rules: [rules.required],
        component: CountrySelect,
      },
      city: {
        label: <Trans i18nKey="dealbase:form.generalInformation.labels.city" />,
        dataIndex: 'city',
        rules: [rules.required],
        component: (props: InputProps) => <Input maxLength={50} {...props} />,
      },
      website: {
        label: <Trans i18nKey="dealbase:form.generalInformation.labels.website" />,
        dataIndex: 'website',
        rules: [rules.required, rules.url],
        component: (props: InputProps) => <ExternalInputLink maxLength={50} {...props} />,
      },
      foundingYear: {
        label: <Trans i18nKey="dealbase:form.generalInformation.labels.foundingYear" />,
        dataIndex: 'foundingYear',
        rules: [rules.required],
        component: (props: SelectProps<number>) => <Select options={FoundingYearsOptions} {...props} />,
      },
      heardFrom: {
        label: <Trans i18nKey="dealbase:form.generalInformation.labels.heardFrom" />,
        dataIndex: 'heardFrom',
        rules: [],
        component: (props: SelectProps<string>) => (
          <InputSuggestions options={InfoSourceOptions} allowClear {...props} />
        ),
      },
    },
    overview: {
      businessIdea: {
        label: <Trans i18nKey="dealbase:form.overview.labels.businessIdea" />,
        dataIndex: 'businessIdea',
        rules: [rules.required],
        component: (props: TextAreaProps) => (
          <TextArea autoSize={{ minRows: 3 }} showCount={!props.disabled} maxLength={250} {...props} />
        ),
      },
      productStatus: {
        label: <Trans i18nKey="dealbase:form.overview.labels.productStatus" />,
        dataIndex: 'productStatus',
        rules: [rules.required],
        component: (props: SelectProps<number> & { selectedOption?: CommonObject }) => (
          <CommonObjectSelect
            {...props}
            archived={false}
            type={DYNAMIC.DEAL_BASE.PRODUCT_STATUS}
            objectType={OBJECT_TYPES.DEAL_BASE}
          />
        ),
      },
      problemSolve: {
        label: <Trans i18nKey="dealbase:form.overview.labels.problemSolve" />,
        dataIndex: 'problemSolve',
        rules: [rules.required],
        component: (props: TextAreaProps) => (
          <TextArea autoSize={{ minRows: 3 }} showCount={!props.disabled} maxLength={250} {...props} />
        ),
      },
      marketSize: {
        label: <Trans i18nKey="dealbase:form.overview.labels.marketSize" />,
        dataIndex: 'marketSize',
        rules: [rules.required],
        component: (props: TextAreaProps) => (
          <TextArea autoSize={{ minRows: 3 }} showCount={!props.disabled} maxLength={250} {...props} />
        ),
      },
      yourCustomer: {
        label: <Trans i18nKey="dealbase:form.overview.labels.yourCustomer" />,
        dataIndex: 'yourCustomer',
        rules: [rules.required],
        component: (props: TextAreaProps) => (
          <TextArea autoSize={{ minRows: 3 }} showCount={!props.disabled} maxLength={250} {...props} />
        ),
      },
      getYourCustomer: {
        label: <Trans i18nKey="dealbase:form.overview.labels.getYourCustomer" />,
        dataIndex: 'getYourCustomer',
        rules: [rules.required],
        component: (props: TextAreaProps) => (
          <TextArea autoSize={{ minRows: 3 }} showCount={!props.disabled} maxLength={250} {...props} />
        ),
      },
      tractionSoFar: {
        label: <Trans i18nKey="dealbase:form.overview.labels.tractionSoFar" />,
        dataIndex: 'tractionSoFar',
        rules: [rules.required],
        component: (props: TextAreaProps) => (
          <TextArea autoSize={{ minRows: 3 }} showCount={!props.disabled} maxLength={250} {...props} />
        ),
      },
      roundSpecifications: {
        label: <Trans i18nKey="dealbase:form.overview.labels.roundSpecifications" />,
        dataIndex: 'roundSpecifications',
        rules: [rules.required],
        component: (props: TextAreaProps) => (
          <TextArea autoSize={{ minRows: 3 }} showCount={!props.disabled} maxLength={250} {...props} />
        ),
      },
      preMoneyValuation: {
        label: <Trans i18nKey="dealbase:form.overview.labels.preMoneyValuation" />,
        dataIndex: 'preMoneyValuation',
        rules: [rules.numberRange(MAX_INT)],
      },
      investmentRound: {
        label: <Trans i18nKey="dealbase:form.overview.labels.investmentRound" />,
        dataIndex: 'investmentRound',
        rules: [rules.numberRange(MAX_INT)],
      },
      stage: {
        label: <Trans i18nKey="dealbase:form.overview.labels.stage" />,
        dataIndex: 'stage',
        rules: [],
        component: (props: SelectProps<number> & { selectedOption?: CommonObject }) => (
          <CommonObjectSelect
            {...props}
            archived={false}
            type={DYNAMIC.DEAL_BASE.STAGE}
            objectType={OBJECT_TYPES.DEAL_BASE}
          />
        ),
      },
    },
    contactDetails: {
      ceo: {
        label: <Trans i18nKey="dealbase:form.contactDetails.labels.ceo" />,
        dataIndex: 'ceo',
        rules: [],
        component: (props: InputProps) => <Input maxLength={40} {...props} />,
      },
      founder: {
        dataIndex: 'founders',
        rules: [
          {
            message: 'founders',
            required: true,
            validator: founderValidator,
          },
        ],
        founderName: {
          label: <Trans i18nKey="dealbase:form.contactDetails.labels.founderName" />,
          dataIndex: 'fullname',
          rules: [rules.required],
          component: (props: InputProps) => <Input maxLength={100} {...props} />,
        },
        founderJobTitle: {
          label: <Trans i18nKey="dealbase:form.contactDetails.labels.jobTitle" />,
          dataIndex: 'jobTitle',
          rules: [rules.required],
          component: (props: InputProps) => <Input maxLength={100} {...props} />,
        },
        founderLinkedIn: {
          label: <Trans i18nKey="dealbase:form.contactDetails.labels.linkedIn" />,
          dataIndex: 'linkedin',
          rules: [rules.url],
          component: (props: InputProps) => <ExternalInputLink maxLength={100} {...props} />,
        },
      },
      contactPerson: {
        label: <Trans i18nKey="dealbase:form.contactDetails.labels.contactPerson" />,
        dataIndex: 'contactPerson',
        rules: [],
        component: (props: InputProps) => <Input maxLength={40} {...props} />,
      },
      contactNumber: {
        label: <Trans i18nKey="dealbase:form.contactDetails.labels.contactNumber" />,
        dataIndex: 'contactNumber',
        rules: [],
        component: (props: InputProps) => <Input maxLength={40} {...props} />,
      },
      contactEmail: {
        label: <Trans i18nKey="dealbase:form.contactDetails.labels.contactEmail" />,
        dataIndex: 'contactEmail',
        rules: [rules.email],
        component: (props: InputProps) => <Input type="email" maxLength={100} {...props} />,
      },
    },
    pitchDeck: {
      document: {
        dataIndex: 'pitchDeckDocument',
        rules: [rules.required],
        component: (props: { value?: Attachment | undefined }) => (
          <AttachmentUpload
            {...props}
            maxCount={1}
            accept=".pdf"
            dragger
            objectType={OBJECT_TYPES.DEAL_BASE}
            dataIndex="pitchDeckDocument"
          />
        ),
      },
    },
  },
  documents: {
    internalDocuments: {
      dataIndex: 'internalAttachments',
      rules: [],
      component: (props: { value?: Attachment | undefined }) => (
        <AttachmentUpload
          {...props}
          dragger
          multiple
          objectType={OBJECT_TYPES.DEAL_BASE}
          dataIndex={NORDIC_ATTACHMENT_PREFIX}
          asPrefix
        />
      ),
    },
    externalDocuments: {
      attachments: {
        dataIndex: 'externalAttachments',
        rules: [],
        component: (props: { value?: Attachment | undefined }) => (
          <AttachmentUpload
            {...props}
            dragger
            multiple
            objectType={OBJECT_TYPES.DEAL_BASE}
            dataIndex={EXTERNAL_ATTACHMENT_PREFIX}
            asPrefix
          />
        ),
      },
      businessPlanDocument: {
        dataIndex: 'businessPlanDocument',
        rules: [],
        component: (props: { value?: Attachment | undefined }) => (
          <AttachmentUpload
            {...props}
            maxCount={1}
            objectType={OBJECT_TYPES.DEAL_BASE}
            dataIndex="businessPlanDocument"
          />
        ),
      },
      budgetDocument: {
        dataIndex: 'budgetDocument',
        rules: [],
        component: (props: { value?: Attachment | undefined }) => (
          <AttachmentUpload {...props} maxCount={1} objectType={OBJECT_TYPES.DEAL_BASE} dataIndex="budgetDocument" />
        ),
      },
    },
  },
  evaluation: {
    generalEvaluation: {
      organization: {
        label: <Trans i18nKey="dealbase:form.generalEvaluation.labels.organization" />,
        dataIndex: 'organization',
        rules: [],
        component: OrganizationSelect,
      },
      assigned: {
        label: <Trans i18nKey="dealbase:form.generalEvaluation.labels.assigned" />,
        dataIndex: 'assigned',
        rules: [],
        component: UserSelect,
      },
      involved: {
        label: <Trans i18nKey="dealbase:form.generalEvaluation.labels.involved" />,
        dataIndex: 'involved',
        rules: [],
        component: UserMultiSelect,
      },
      timeFrame: {
        label: <Trans i18nKey="dealbase:form.generalEvaluation.labels.timeFrame" />,
        dataIndex: 'timeFrame',
        rules: [],
        component: (props: InputProps) => <Input maxLength={40} {...props} />,
      },
      nextSteps: {
        label: <Trans i18nKey="dealbase:form.generalEvaluation.labels.nextSteps" />,
        dataIndex: 'nextSteps',
        rules: [],
        component: (props: InputProps) => <Input maxLength={80} {...props} />,
      },
      followUpDate: {
        label: <Trans i18nKey="dealbase:form.generalEvaluation.labels.followUp" />,
        dataIndex: 'followUpDate',
        rules: [],
        component: (props: PickerProps<Date>) => {
          return <DatePicker {...props} value={props.value && parseJSONDate(props.value)} />;
        },
      },
      priority: {
        label: <Trans i18nKey="dealbase:form.generalEvaluation.labels.priority" />,
        dataIndex: 'priority',
        rules: [],
        component: (props: SelectProps<number>) => <Select options={PriorityOptions} allowClear {...props} />,
      },
      tier: {
        label: <Trans i18nKey="dealbase:form.generalEvaluation.labels.tier" />,
        dataIndex: 'tier',
        rules: [],
        component: (props: SelectProps<number>) => <Select options={TierOptions} allowClear {...props} />,
      },
    },
    initialScoring: {
      dataIndex: 'initialScorecards',
      rules: [
        {
          required: true,
          validator: scorecardValidator,
          message: 'initialScoring',
        },
      ],
      user: {
        label: <Trans i18nKey="dealbase:scoreCards.columns.user" />,
        dataIndex: 'user',
        rules: [rules.required],
        component: UserSelect,
      },
      traction: {
        label: <Trans i18nKey="dealbase:scoreCards.columns.traction" />,
        dataIndex: 'traction',
        rules: [rules.required],
        component: (props: InputNumberProps) => (
          <InputNumber inputMode="numeric" max={10} precision={0} min={0} step={1} {...props} />
        ),
      },
      greatness: {
        label: <Trans i18nKey="dealbase:scoreCards.columns.greatness" />,
        dataIndex: 'greatness',
        rules: [rules.required],
        component: (props: InputNumberProps) => (
          <InputNumber inputMode="numeric" max={10} precision={0} min={0} step={1} {...props} />
        ),
      },
      scalability: {
        label: <Trans i18nKey="dealbase:scoreCards.columns.scalability" />,
        dataIndex: 'scalability',
        rules: [rules.required],
        component: (props: InputNumberProps) => (
          <InputNumber inputMode="numeric" max={10} precision={0} min={0} step={1} {...props} />
        ),
      },
      uniqueness: {
        label: <Trans i18nKey="dealbase:scoreCards.columns.uniqueness" />,
        dataIndex: 'uniqueness',
        rules: [rules.required],
        component: (props: InputNumberProps) => (
          <InputNumber inputMode="numeric" max={10} precision={0} min={0} step={1} {...props} />
        ),
      },
      fit: {
        label: <Trans i18nKey="dealbase:scoreCards.columns.fit" />,
        dataIndex: 'fit',
        rules: [rules.required],
        component: (props: InputNumberProps) => (
          <InputNumber inputMode="numeric" max={10} precision={0} min={0} step={1} {...props} />
        ),
      },
    },
  },
  metrics: {
    financialEvaluation: {
      ticketSize: {
        label: <Trans i18nKey="dealbase:form.financialEvaluation.labels.ticketSize" />,
        dataIndex: 'ticketSize',
        rules: [],
        component: (props: InputNumberProps) => (
          <InputNumber
            addonBefore="€"
            addonAfter={<Trans i18nKey="dealbase:form.financialEvaluation.placeholder.millions" />}
            {...props}
          />
        ),
      },
      commitedCapital: {
        label: <Trans i18nKey="dealbase:form.financialEvaluation.labels.commitedCapital" />,
        dataIndex: 'commitedCapital',
        rules: [],
        component: (props: InputNumberProps) => (
          <InputNumber
            addonBefore="€"
            addonAfter={<Trans i18nKey="dealbase:form.financialEvaluation.placeholder.millions" />}
            {...props}
          />
        ),
      },
      neOption: {
        label: <Trans i18nKey="dealbase:form.financialEvaluation.labels.neOption" />,
        dataIndex: 'neOption',
        rules: [],
        component: (props: InputProps) => <Input maxLength={40} {...props} />,
      },
    },
    metricsCurrency: {
      currency: {
        label: <Trans i18nKey="dealbase:form.metrics.labels.currency" />,
        dataIndex: 'currency',
        rules: [rules.required],
        component: (props: SelectProps) => <Select options={CurrencyOptions} allowClear {...props} />,
      },
    },
    revenue: {
      revenueType: {
        label: <Trans i18nKey="dealbase:form.revenue.labels.revenueType" />,
        help: <Trans i18nKey="dealbase:form.revenue.helper.revenueType" />,
        dataIndex: 'revenueType',
        rules: [],
        component: (props: SelectProps<number> & { selectedOption?: CommonObject }) => (
          <CommonObjectSelect
            {...props}
            archived={false}
            type={DYNAMIC.DEAL_BASE.REVENUE_TYPE}
            objectType={OBJECT_TYPES.DEAL_BASE}
          />
        ),
      },
      revenueYear: {
        dataIndex: 'revenueYears',
        rules: [
          {
            required: true,
            validator: revenueFinancialValidator,
            message: 'revenueYears',
          },
        ],
        year: {
          label: <Trans i18nKey="dealbase:labels.year" />,
          dataIndex: 'year',
          rules: [rules.required],
          component: (props: SelectProps) => <Select options={RevenueYearsOption} {...props} />,
        },
        amount: {
          label: <Trans i18nKey="dealbase:labels.amount" />,
          dataIndex: 'amount',
          rules: [rules.required, rules.max(MAX_BIGINT), rules.min(-MAX_BIGINT)],
          component: (props: InputNumberProps<number>) => (
            <InputNumber<number>
              controls={false}
              formatter={(value) => valueDelimiterFormatter(value)}
              className="w-full"
              {...props}
            />
          ),
        },
      },
      revenueProjection: {
        dataIndex: 'revenueProjections',
        rules: [
          {
            required: true,
            validator: revenueFinancialValidator,
            message: 'revenueProjections',
          },
        ],
        year: {
          label: <Trans i18nKey="dealbase:labels.year" />,
          dataIndex: 'year',
          rules: [rules.required],
          component: (props: SelectProps) => <Select options={RevenueProjectionYearsOption} {...props} />,
        },
        amount: {
          label: <Trans i18nKey="dealbase:labels.amount" />,
          dataIndex: 'amount',
          rules: [rules.required, rules.max(MAX_BIGINT), rules.min(-MAX_BIGINT)],
          component: (props: InputNumberProps<number>) => (
            <InputNumber<number>
              controls={false}
              formatter={(value) => valueDelimiterFormatter(value)}
              className="w-full"
              {...props}
            />
          ),
        },
      },
    },
    ebitda: {
      ebitdaYear: {
        dataIndex: 'ebitdaYears',
        rules: [
          {
            required: true,
            validator: ebitdaFinancialValidator,
            message: 'ebitdaYears',
          },
        ],
        year: {
          label: <Trans i18nKey="global:period.year" />,
          dataIndex: 'year',
          rules: [rules.required],
          component: (props: SelectProps) => <Select options={EbitdaYearsOption} {...props} />,
        },
        amount: {
          label: <Trans i18nKey="dealbase:labels.amount" />,
          dataIndex: 'amount',
          rules: [rules.required, rules.max(MAX_BIGINT), rules.min(-MAX_BIGINT)],
          component: (props: InputNumberProps<number>) => (
            <InputNumber<number>
              controls={false}
              formatter={(value) => valueDelimiterFormatter(value)}
              className="w-full"
              {...props}
            />
          ),
        },
      },
      ebitdaProjection: {
        dataIndex: 'ebitdaProjections',
        rules: [
          {
            required: true,
            validator: ebitdaFinancialValidator,
            message: 'ebitdaProjections',
          },
        ],
        year: {
          label: <Trans i18nKey="global:period.year" />,
          dataIndex: 'year',
          rules: [rules.required],
          component: (props: SelectProps) => <Select options={EbitdaProjectionYearsOption} {...props} />,
        },
        amount: {
          label: <Trans i18nKey="dealbase:labels.amount" />,
          dataIndex: 'amount',
          rules: [rules.required, rules.max(MAX_BIGINT), rules.min(-MAX_BIGINT)],
          component: (props: InputNumberProps<number>) => (
            <InputNumber<number>
              controls={false}
              formatter={(value) => valueDelimiterFormatter(value)}
              className="w-full"
              {...props}
            />
          ),
        },
      },
    },
  },
};
