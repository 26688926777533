import React, { useContext, useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { AttachmentList } from 'common-ui';
import { DealBase } from 'core/lib';
import { DealBaseDetailsContext } from 'modules/dealBase/context';

const DealBaseExternalDocumentsSection = () => {
  const dealbase = useContext(DealBaseDetailsContext) as DealBase;
  const { t, i18n } = useTranslation('actions');

  const externalAtts = useMemo(() => {
    const atts = [...dealbase.externalAttachments];
    if (dealbase.budgetDocument) {
      atts.unshift(dealbase.budgetDocument);
    }
    if (dealbase.businessPlanDocument) {
      atts.unshift(dealbase.businessPlanDocument);
    }
    return atts.map((att) => {
      return {
        ...att,
        title: i18n.exists(`dealbase:documents.${att.dataIndex}`)
          ? t(`dealbase:documents.${att.dataIndex}`)
          : t('dealbase:documents.additional'),
      };
    });
  }, [dealbase.externalAttachments, dealbase.businessPlanDocument, dealbase.budgetDocument, i18n, t]);

  return <AttachmentList attachments={externalAtts} />;
};

export default DealBaseExternalDocumentsSection;
