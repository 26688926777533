import { RcFile, UploadFile } from 'antd/lib/upload/interface';
import { UploadProgressEvent, UploadRequestOption } from 'rc-upload/lib/interface';
import { generatePath } from 'react-router';

import { Attachment, RouteTypes } from 'core/lib/modules/app/entities';
import * as currencies from 'core/lib/utils/config/currencies';
import { getFileUploader } from 'core/lib/utils/requesting';
import { Routes } from 'routes/routes';

export const getCurrencySymbolByCode = (cc: string) => {
  return currencies[cc]?.symbol;
};

export const toCurrency = (arg: number | string, currency?: string) => {
  return new Intl.NumberFormat(navigator.languages[0], {
    style: 'currency',
    currency: currency ?? 'EUR',
  }).format(Number(arg));
};

export const toFormattedNumber = (arg: number | string) => {
  return new Intl.NumberFormat(navigator.languages[0]).format(Number(arg));
};

export const toRoundedNumber = (arg: number | string, round = 0) => {
  const rounded = +Number(arg).toFixed(round);
  return rounded;
};

export const getDetailsPath = (objectType: number, objectId: number) => {
  const moduleRoute = Object.values(Routes).find(
    (route) => route.type === RouteTypes.DETAILS && route.objectType === objectType
  );
  if (moduleRoute) {
    return generatePath(moduleRoute?.path, { id: objectId.toString() });
  }
};

export const getModulePath = (objectType: number, routeType?: RouteTypes) => {
  const moduleRoutes = Object.values(Routes).filter((route) => route.objectType === objectType);
  const moduleRoute = moduleRoutes.find((route) => route.type === routeType);
  return moduleRoute?.path ? generatePath(moduleRoute.path) : null;
};

export const valueDelimiterFormatter = (value?: number | string, decimals = 3, delimiter = ',') => {
  const regex = new RegExp(`\\B(?=(\\d{${decimals}})+(?!\\d))`, 'g');
  const toNumberRegex = new RegExp(`\\$s?|(\\${delimiter}*)`, 'g');
  const parseValue = `${value ?? ''}`.replace(toNumberRegex, '');
  return parseValue.replace(regex, delimiter);
};

export const getNumberWithOrdinal = (n: number) => {
  const s = ['th', 'st', 'nd', 'rd'],
    v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
};

export const toCamelCase = (str: string) => {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, '');
};

export const downloadBase64File = (
  data: string,
  filename: string,
  contentType = 'data:application/octet-stream;base64'
) => {
  const linkSource = `data:${contentType};base64,${data?.replace(/^[^,]+,/, '')}`;
  const downloadLink = document.createElement('a');
  downloadLink.href = linkSource;
  downloadLink.download = filename;
  downloadLink.click();
};

export const normalizeFileUpload = (
  file: UploadFile<RcFile & XMLHttpRequest> & Partial<Attachment>,
  responseKey: string
) => {
  switch (file.status) {
    case 'done': {
      return {
        uid: file.uid,
        dataIndex: file.uid,
        name: file.name,
        id: JSON.parse(file.xhr?.response)?.[responseKey],
      };
    }
    default: {
      return null;
    }
  }
};

export const normalizeMultipleFileUpload = (
  file: UploadFile<RcFile & XMLHttpRequest> & Partial<Attachment>,
  fileList: Attachment[],
  responseKey: string
) => {
  switch (file.status) {
    case 'done': {
      return fileList.concat({
        uid: file.uid,
        dataIndex: file.uid,
        name: file.name,
        id: JSON.parse(file.xhr?.response)?.[responseKey],
      });
    }
    case 'removed': {
      return fileList.filter((fileListItem) => fileListItem.dataIndex !== file.dataIndex);
    }
    default: {
      return fileList;
    }
  }
};

export const getCustomUploadRequestMethod =
  (objectType: number, dataIndex: string, asPrefix = false) =>
  ({ onSuccess, onError, file, onProgress }: UploadRequestOption<RcFile>) => {
    const axiosUploader = getFileUploader();
    const data = new FormData();
    const key = asPrefix ? `${dataIndex}-${(file as RcFile).uid}` : dataIndex;

    data.append(key, file);
    data.append('objectType', objectType.toString());

    return axiosUploader
      .post<string>('', data, {
        onUploadProgress: (event) => {
          onProgress!({ percent: (event.loaded / event.total) * 100 } as UploadProgressEvent);
        },
      })
      .then((response) => {
        onSuccess!(file as RcFile, response.request as XMLHttpRequest);
      })
      .catch((err) => {
        console.log(err);
        onError!(err);
      });
  };

export const getDetailsRouteByObjectType = (objectType: number) => {
  return Object.values(Routes).find((route) => route.objectType === objectType && route.type === RouteTypes.DETAILS)
    ?.path;
};

export const getRouteByObjectTypeAndRouteType = (objectType: number, type?: RouteTypes) => {
  return Object.values(Routes).find(
    (route) => route.objectType === objectType && route.type === (type ?? RouteTypes.MODULE)
  )?.path;
};

export const generateRoutePathByObjectTypeAndObjectId = (objectType: number, objectId: number) => {
  const route = getDetailsRouteByObjectType(objectType);
  if (route) {
    return generatePath(route, { id: objectId.toString() });
  }
};

export const getTimezoneOffset = () => {
  return new Date().getTimezoneOffset() / 60;
};
