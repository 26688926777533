import React from 'react';

import { Checkbox, CheckboxProps } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

type CheckboxSelectProps = CheckboxProps & {
  label: string | React.ReactNode;
};

const CheckboxSelect = ({ label, value, ...props }: CheckboxSelectProps) => {
  return (
    <Checkbox className="flex border border-solid b--grey-200 px-4 py-2" checked={value} {...props}>
      {label}
    </Checkbox>
  );
};

export default CheckboxSelect;

export const normalizeCheckboxChange = ({ target }: CheckboxChangeEvent) => {
  return target.checked;
};
