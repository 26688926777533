import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Avatar, Comment, Popconfirm } from 'antd';
import { delay } from 'lodash';
import { useTranslation } from 'react-i18next';

import { DateRecord, DateSince, ExternalUserTag, UserAvatar, UserLink } from 'common-ui';
import { UserComment } from 'core/lib';
import { COMMENT_STATUS } from 'core/lib/constants/comment';
import { DEFAULT_DATETIME_FORMAT } from 'core/lib/constants/defaults';
import colors from 'core/lib/theme/colors';
import { useLoggedInUser, useRefIdParam } from 'utils/hooks';

import CommentList from './commentList';
import Editor from '../editor';
import Viewer from '../viewer';

export type RenderCommentProps = {
  onEdit: (item: UserComment) => void;
  onCancel: () => void;
  onReplyCancel: () => void;
  onReply: (parentId: number) => void;

  onDelete?: (commentId: number, callback?: () => void) => void;
  onSubmit: (comment: Partial<UserComment>, callback?: () => void) => void;
  onReplySubmit: (parentCommentId: number, replyComment: Partial<UserComment>, callback?: () => void) => void;
  userComment: UserComment;
  isSaving: boolean;
  editMode: boolean;
  replyMode: boolean;
  nameRequired?: boolean;
  type: string;
  external?: boolean;
};

const RenderComment = ({
  userComment,
  nameRequired,
  editMode,
  isSaving,
  type,
  replyMode,
  external,
  onEdit,
  onCancel,
  onReplyCancel,
  onDelete,
  onReply,
  onSubmit,
  onReplySubmit,
}: RenderCommentProps) => {
  const { t } = useTranslation();
  const [refId] = useRefIdParam();
  const loggedInUser = useLoggedInUser();
  const [isLoading, setLoading] = useState(false);
  const commentRef = useRef<HTMLDivElement | null>(null);
  const [focusColor, setFocusColor] = useState<string | null>(null);
  const [firstName = '', lastName = ''] = (userComment.user?.name ?? userComment.externalUser)?.split(' ') ?? [];
  const userCommentWrapper = userComment.user?.name ? (
    <UserLink disabled={external} strong id={userComment.user.id} name={userComment.user.name} />
  ) : (
    <div className="flex flex-row gap-4 items-center">
      {userComment.externalUser}
      <ExternalUserTag />
    </div>
  );
  const userAvatar = userComment.user ? (
    <UserAvatar user={userComment.user} iconOnly plain={external} />
  ) : (
    <Avatar className="grow-0">{`${firstName?.charAt(0) ?? ''}${lastName?.charAt(0) ?? ''}`}</Avatar>
  );

  const scrollCommentIntoView = () => {
    commentRef.current?.scrollIntoView();
    commentRef.current?.focus();
    setFocusColor(colors.Primary.alpha(0.05).rgba);
    delay(setFocusColor, 1000);
  };

  useEffect(() => {
    if (refId && refId === userComment.id) {
      setTimeout(() => {
        scrollCommentIntoView();
      }, 200);
    }
  }, [refId, userComment.id]);

  const userCanEdit = useMemo(() => {
    return loggedInUser && loggedInUser?.id === userComment.user?.id;
  }, [userComment.user?.id, loggedInUser]);

  const handleDelete = () => {
    setLoading(true);
    if (onDelete) {
      onDelete(userComment.id, () => setLoading(false));
    }
  };

  const handleEdit = () => {
    onEdit(userComment);
  };

  const handleReply = () => {
    onReply(userComment.id);
  };

  const commentActions = useMemo(() => {
    if (userComment.comment.status === COMMENT_STATUS.DELETED) {
      return [];
    }
    const actions = [
      <span onClick={handleReply} key="comment-list-reply-to-0">
        {t('actions:global.replyTo')}
      </span>,
    ];

    if (userCanEdit) {
      actions.push(
        <span onClick={handleEdit} key="comment-list-edit">
          {t('actions:global.edit')}
        </span>
      );
      if (onDelete) {
        actions.push(
          <span key="comment-list-delete">
            <Popconfirm
              title={t('actions:confirmation.deleteComment')}
              okText={t('actions:global.delete')}
              onConfirm={handleDelete}
              okButtonProps={{ danger: true, loading: isLoading }}
            >
              {t('actions:global.delete')}
            </Popconfirm>
          </span>
        );
      }
    }
    return actions;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCanEdit, userComment, isLoading]);

  const content = useMemo(() => {
    return editMode ? (
      <Editor
        external={external}
        type={type}
        nameRequired={nameRequired}
        onSubmit={onSubmit}
        onCancel={onCancel}
        submitting={isSaving}
        value={userComment.comment}
      />
    ) : (
      <Viewer
        external={external}
        content={userComment.comment.content}
        status={userComment.comment.status}
        isLoading={isLoading}
      />
    );
  }, [editMode, external, type, nameRequired, isSaving, isLoading, userComment.comment, onSubmit, onCancel]);

  return (
    <div ref={commentRef}>
      <Comment
        style={{ backgroundColor: focusColor ?? '' }}
        className={!focusColor ? `transition-colors duration-[4000ms]` : ''}
        key={userComment.id}
        actions={commentActions}
        author={userCommentWrapper}
        avatar={userAvatar}
        content={content}
        datetime={
          <div className="flex flex-row gap-x-1">
            <DateSince date={userComment.comment.dateCreated} />
            {userComment.comment.dateModified && (
              <DateRecord
                date={userComment.comment.dateModified}
                text={`(${t('global:status.edited').toLowerCase()})`}
                dateFormat={DEFAULT_DATETIME_FORMAT}
              />
            )}
          </div>
        }
      >
        {replyMode && (
          <Editor
            external={external}
            type={type}
            nameRequired={nameRequired}
            onSubmit={(comment, callback) => onReplySubmit(userComment.id, comment, callback)}
            onCancel={onReplyCancel}
            submitting={isSaving}
            autoFocus
          />
        )}
        {userComment.replies && (
          <CommentList
            comments={userComment.replies}
            onDelete={onDelete}
            onReplySubmit={onReplySubmit}
            onSubmit={onSubmit}
            nameRequired={nameRequired}
            isSaving={isSaving}
            type={type}
            external={external}
          />
        )}
      </Comment>
    </div>
  );
};

export default RenderComment;
