import React, { useEffect, useState } from 'react';

import { message } from 'antd';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router-dom';

import { conversationsApi, selectWebSocketStatus, useAppSelector } from 'core/lib';
import { HubConnectionState } from 'core/lib/utils/signalR/utils';
import { Routes } from 'routes/routes';
import { useMatchParamId } from 'utils/hooks';
import LoaderWrapper from 'utils/loader-wrapper';

import { DirectConversationDetailsComponent } from '../details';

const DirectConversationPermissionWrapper = () => {
  const history = useHistory();
  const id = useMatchParamId(Routes.CONVERSATIONS_DM_DETAILS.path);
  const { t } = useTranslation();
  const [initialLoading, setLoading] = useState(false);

  const webSocketStatus = useAppSelector(selectWebSocketStatus);

  const [getPermission, { data: conversationId, isSuccess, isLoading, isFetching }] =
    conversationsApi.useLazyGetDirectConversationPermissionQuery({ refetchOnReconnect: true });

  useEffect(() => {
    if (id) {
      setLoading(true);
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      getPermission(parseInt(id), false);
    }
  }, [id, getPermission]);

  useEffect(() => {
    if (!isFetching) {
      setLoading(false);
    }
  }, [isFetching]);

  useEffect(() => {
    if (id && !conversationId && webSocketStatus === HubConnectionState.Connected) {
      getPermission(parseInt(id), false);
    }
  }, [id, conversationId, webSocketStatus, getPermission]);

  useEffect(() => {
    if (isSuccess && !conversationId) {
      message.error(t('conversations:message.modulePermission'));
      history.push(generatePath(Routes.CONVERSATIONS_LISTING.path));
    }
  }, [history, isSuccess, conversationId, t]);

  return (
    <LoaderWrapper loading={initialLoading || isLoading}>
      {conversationId && <DirectConversationDetailsComponent conversationId={conversationId} />}
    </LoaderWrapper>
  );
};

export default DirectConversationPermissionWrapper;
