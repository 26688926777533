import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { baseApi, detailsSectionPreferenceApi, userApi, UserDetails } from 'core/lib';
import { OBJECT_TYPES } from 'core/lib/constants';
import { UserForm } from 'modules/user/form';
import { Routes } from 'routes/routes';
import { useMatchParamId } from 'utils/hooks';
import LoaderWrapper from 'utils/loader-wrapper';

import { DetailsPageHeader } from '../components';
import { getSystemRoleIdFromRoles } from '../utils';

const UserListingDetails = () => {
  const { t } = useTranslation(['loaders', 'actions', 'user', 'global']);
  const id = useMatchParamId(Routes.USER_DETAILS.path)!;
  const history = useHistory();

  const [editMode, setEditMode] = useState(false);
  const [activeTabKey, setActiveTabKey] = useState<string | null>();

  const { data, refetch, isFetching: fetchingUser, originalArgs } = userApi.useGetUserDetailsQuery(id);
  const { isLoading: loadingSectionHeaders } = detailsSectionPreferenceApi.useGetDetailsSectionHeadersQuery(
    OBJECT_TYPES.USER
  );

  const [postUser, { isSuccess: saveSuccess, isLoading: isSaving }] = userApi.usePatchUserMutation();

  const onToggleEdit = () => {
    setEditMode(true);
  };

  const onCancel = () => {
    setEditMode(false);
  };

  useEffect(() => {
    if (saveSuccess) {
      setEditMode(false);
    }
  }, [saveSuccess]);

  useEffect(() => {
    if (originalArgs) {
      setEditMode(false);
      setActiveTabKey(null);
    }
  }, [originalArgs]);

  useEffect(() => {
    baseApi.util.resetApiState();
  }, [data?.id]);

  const onSave = (userDetails: UserDetails) => {
    userDetails.id = parseInt(id);
    userDetails.systemRoleId = getSystemRoleIdFromRoles(userDetails.systemRoles);
    return postUser(userDetails).unwrap();
  };

  const onRefetch = () => {
    refetch();
  };

  const handlePageHeaderBackClick = () => {
    history.goBack();
  };

  return (
    <LoaderWrapper loading={fetchingUser || loadingSectionHeaders} message={t('loaders:user.loadingDetails')}>
      {data && (
        <>
          <DetailsPageHeader onBack={handlePageHeaderBackClick} user={data} />
          <UserForm
            user={data}
            onSave={onSave}
            onCancel={onCancel}
            onToggleEdit={onToggleEdit}
            onRefetch={onRefetch}
            setActiveTabKey={setActiveTabKey}
            activeTabKey={activeTabKey}
            editMode={editMode}
            isSaving={isSaving}
          />
        </>
      )}
    </LoaderWrapper>
  );
};

export default UserListingDetails;
