import React from 'react';

type ContentContainerProps = {
  children: React.ReactNode;
  withSide?: boolean;
};

const ContentContainer = ({ children, withSide = false }: ContentContainerProps) => {
  const widthWithSide = withSide ? 'lg:w-3/4' : '';
  return <div className={`w-full mx-auto ${widthWithSide}`}>{children}</div>;
};

export default ContentContainer;
