import React, { useEffect, useMemo, useState } from 'react';

import { PlusOutlined, SortAscendingOutlined } from '@ant-design/icons';
import { DragEndEvent, DragStartEvent } from '@dnd-kit/core';
import { Button, Divider, Input, List, Switch } from 'antd';
import { RuleObject } from 'antd/lib/form';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList, ListChildComponentProps } from 'react-window';

import { MainContentContainer } from 'common-ui/containers';
import { DnDWrapper } from 'common-ui/sortable';
import { CommonObject, commonObjectsApi, CreateCommonObjectRequest, UpdateCommonObjectRequest } from 'core/lib';
import { fuzzyWordsSearch } from 'core/lib/utils';
import LoaderWrapper from 'utils/loader-wrapper';

import EditListItem from './editListItem';
import ViewListItem from './viewListItem';

type CustomFieldTabContentProps = {
  type: string;
  objectType: number;
};

const CustomFieldTabContent = ({ objectType, type }: CustomFieldTabContentProps) => {
  const { t } = useTranslation();
  const [editId, setEditId] = useState<number | undefined>();
  const [newField, setNewField] = useState<boolean>(false);
  const [search, setSearch] = useState('');
  const [archivedToggled, setArchivedToggle] = useState(false);
  const [draggingItem, setDraggingItem] = useState<CommonObject | null>(null);

  const {
    data,
    isLoading,
    isFetching: fetchingUpdates,
  } = commonObjectsApi.useGetCommonObjectsQuery({
    objectType,
    type,
    withConnected: true,
  });

  const namesInUseWithoutEditing = useMemo(() => {
    return data?.filter((d) => d.id !== editId).map((d) => d.name.toLowerCase()) ?? [];
  }, [data, editId]);

  const [createCommonObject, { isLoading: isCreating }] = commonObjectsApi.useCreateCommonObjectMutation();
  const [updateCommonObject, { isLoading: isUpdating, originalArgs: updateArgs, reset: resetUpdateApi }] =
    commonObjectsApi.useUpdateCommonObjectMutation();

  const [orderCommonObject, { isLoading: isOrderLoading, originalArgs: orderingArgs }] =
    commonObjectsApi.useOrderCommonObjectMutation();

  const [resetOrderCommonObject, { isLoading: isResetOrderLoading }] =
    commonObjectsApi.useResetOrderCommonObjectMutation();

  const onEditClick = (commonObjectId: number) => {
    setEditId(commonObjectId);
  };

  useEffect(() => {
    if (!fetchingUpdates) {
      resetUpdateApi();
    }
  }, [fetchingUpdates]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSaveClick = (commonObject: UpdateCommonObjectRequest) => {
    updateCommonObject(commonObject)
      .unwrap()
      .then(() => {
        setEditId(undefined);
      });
  };

  const onSaveNewClick = (commonObject: CreateCommonObjectRequest) => {
    createCommonObject(commonObject)
      .unwrap()
      .then(() => {
        setNewField(false);
      });
  };

  const onCancelClick = () => {
    setEditId(undefined);
  };

  const onCancelNewClick = () => {
    setNewField(false);
  };

  const onAddNewClick = () => {
    setNewField(true);
  };

  const isOrdering = (id: number) => {
    return isOrderLoading && id === orderingArgs?.id;
  };

  const nameValidator = (rule: RuleObject, value: string) => {
    if (!namesInUseWithoutEditing.includes(value.toLowerCase())) {
      return Promise.resolve();
    }
    return Promise.reject(rule.message as string);
  };

  const filteredData = useMemo(() => {
    return data?.filter(
      (item) => fuzzyWordsSearch(search, item.name) && (!archivedToggled || (archivedToggled && !item.archived))
    );
  }, [data, search, archivedToggled]);

  const draggableItemsIds = useMemo(() => {
    return data?.map((item) => item.id) ?? [];
  }, [data]);

  const handleDragEnd = (event: DragEndEvent) => {
    const { over } = event;
    const overItem = over?.data.current as CommonObject;

    if (over && draggingItem && draggingItem.id !== over.id) {
      orderCommonObject({
        id: draggingItem.id,
        objectType,
        type,
        toPosition: overItem?.displayOrder ?? over.data.current?.sortable.index,
      });
    }
    setDraggingItem(null);
  };

  const handleResetOrderClick = () => {
    resetOrderCommonObject({ type, objectType });
  };

  const onToggleArchivedClick = () => {
    setArchivedToggle(!archivedToggled);
  };

  const itemHasUpdateLoader = (id: number) => {
    return isUpdating && id === updateArgs?.id;
  };

  const fetchingItemUpdates = (id: number) => {
    return (fetchingUpdates || isUpdating) && id === updateArgs?.id;
  };

  const commonObjectListItemRenderer = ({ index, data, style }: ListChildComponentProps<CommonObject[]>) => {
    const item = data[index];
    return item.id === editId ? (
      <EditListItem
        loading={itemHasUpdateLoader(item.id)}
        nameValidator={nameValidator}
        type={type}
        objectType={objectType}
        item={item}
        onSave={onSaveClick}
        onCancel={onCancelClick}
        style={style}
      />
    ) : (
      <ViewListItem
        loading={fetchingItemUpdates(item.id)}
        handleLoader={isOrdering(item.id)}
        handle={!isMobile}
        item={item}
        objectType={objectType}
        onEdit={onEditClick}
        style={style}
      />
    );
  };

  const handleDragStart = ({ active }: DragStartEvent) => {
    setDraggingItem(active.data.current as CommonObject);
  };

  const handleDragCancel = () => {
    setDraggingItem(null);
  };

  const orderingDisabled = !!editId || fetchingUpdates || isOrderLoading || isMobile;

  return (
    <MainContentContainer id="custom-fields">
      <LoaderWrapper loading={isLoading}>
        <div className="flex flex-col w-full h-full">
          <div className="flex flex-row justify-between gap-4 py-4">
            <Button disabled={newField} type="primary" onClick={onAddNewClick} icon={<PlusOutlined />}>
              {t('actions:global.addType', { type })}
            </Button>
            <Input
              className="w-full max-w-128"
              allowClear
              placeholder={t('global:placeholders.searchByName')}
              onChange={(e) => setSearch(e.target.value)}
              value={search}
            />
          </div>
          <div className="flex flex-row justify-between gap-4 py-4">
            <Button
              type="text"
              loading={isResetOrderLoading}
              className="w-fit"
              onClick={handleResetOrderClick}
              icon={<SortAscendingOutlined />}
            >
              {t('actions:global.alphabetize', { type })}
            </Button>
            <div className="flex flex-row gap-2">
              {t('global:filters.hideArchived')}
              <Switch
                onClick={onToggleArchivedClick}
                title={t('global:filters.hideArchived')}
                checked={archivedToggled}
              />
            </div>
          </div>
          {newField && (
            <>
              <EditListItem
                loading={isCreating}
                nameValidator={nameValidator}
                type={type}
                objectType={objectType}
                onSave={onSaveNewClick}
                onCancel={onCancelNewClick}
              />
              <Divider />
            </>
          )}
          <div className="flex h-full">
            <DnDWrapper
              disabled={orderingDisabled}
              onDragStart={handleDragStart}
              onDragEnd={handleDragEnd}
              onDragCancel={handleDragCancel}
              draggableItemsIds={draggableItemsIds}
              overlay={draggingItem?.name}
            >
              <AutoSizer className="h-full">
                {({ width, height }) => (
                  <List>
                    <FixedSizeList<CommonObject[]>
                      className="box-content"
                      itemData={filteredData}
                      overscanCount={5}
                      itemSize={50}
                      itemCount={filteredData?.length ?? 0}
                      width={width}
                      height={height}
                    >
                      {commonObjectListItemRenderer}
                    </FixedSizeList>
                  </List>
                )}
              </AutoSizer>
            </DnDWrapper>
          </div>
        </div>
      </LoaderWrapper>
    </MainContentContainer>
  );
};

export default CustomFieldTabContent;
