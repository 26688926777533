import React, { useMemo } from 'react';

import { Form, Input, Popover } from 'antd';

import { NumberRangeInput } from 'common-ui/inputs';
import { NumberRangeInputProps } from 'common-ui/inputs/numberRangeInput';
import { NumberRange } from 'core/lib';

import { ListingEditableCellProps } from './types';

function ListingNumberRange({
  dataIndex,
  rules,
  isDisabled,
  ...props
}: ListingEditableCellProps & NumberRangeInputProps) {
  const form = Form.useFormInstance();
  const formValue: NumberRange = Form.useWatch(dataIndex, form);

  const disabled = props.disabled || (isDisabled && isDisabled(form));

  var value = useMemo(() => {
    if (formValue?.from || formValue?.to) {
      var rangeVal = `${formValue.from ?? ''}-${formValue.to ?? ''}`;
      if (props.addonBefore) {
        rangeVal = `${props.addonBefore} ${rangeVal}`;
      }
      if (props.addonAfter) {
        rangeVal = `${rangeVal} ${props.addonAfter} `;
      }
      return rangeVal;
    }
    return;
  }, [formValue, props.addonBefore, props.addonAfter]);

  return (
    <Form.Item
      rules={rules}
      name={dataIndex}
      dependencies={[
        [dataIndex, 'from'],
        [dataIndex, 'to'],
      ]}
      className="m-0"
    >
      <Popover trigger="click" content={<NumberRangeInput {...props} dataIndex={dataIndex} />}>
        <Input disabled={disabled} readOnly value={value} />
      </Popover>
    </Form.Item>
  );
}

export default ListingNumberRange;
