import { keyBy } from 'lodash';

import i18n from 'core/lib/utils/i18n';

export const OBJECT_TYPES = {
  USER: 4,
  ORGANIZATION: 6,
  PORTFOLIO: 12,
  DEAL_BASE: 21,
  MY_CONVERSATIONS: 22,
  CONTACT_BOOK: 32,
};

export const DYNAMIC = {
  CONTACT_BOOK: {
    STAGE: 'Stage',
    INVESTMENT_FOCUS: 'InvestmentFocus',
    INDUSTRY: 'Industry',
  },
  DEAL_BASE: {
    TECHNOLOGY: 'Technology',
    INDUSTRY: 'Industry',
    PRODUCT_STATUS: 'ProductStatus',
    CUSTOMER_FOCUS: 'CustomerFocus',
    REVENUE_TYPE: 'RevenueType',
    STAGE: 'Stage',
  },
  PORTFOLIO: {
    TYPE: 'Type',
    TECHNOLOGY: 'Technology',
    SUPPLIER_TYPE: 'SupplierType',
    SUPPORT_LANGUAGE: 'SupportLanguage',
    COMPLIANCE_STATUS: 'ComplianceStatus',
    NUMBER_OF_USERS: 'NumberOfUsers',
    FREQUENCY_USED: 'FrequencyUsed',
  },
};

export const MODULE_DYNAMIC = {
  [OBJECT_TYPES.DEAL_BASE]: DYNAMIC.DEAL_BASE,
  [OBJECT_TYPES.PORTFOLIO]: DYNAMIC.PORTFOLIO,
  [OBJECT_TYPES.CONTACT_BOOK]: DYNAMIC.CONTACT_BOOK,
};

export const MODULE_NAMES = {
  [OBJECT_TYPES.DEAL_BASE]: i18n.t('global:moduleNames.dealbase'),
  [OBJECT_TYPES.USER]: i18n.t('global:moduleNames.users'),
  [OBJECT_TYPES.ORGANIZATION]: i18n.t('global:moduleNames.organization'),
  [OBJECT_TYPES.PORTFOLIO]: i18n.t('global:moduleNames.portfolio'),
  [OBJECT_TYPES.MY_CONVERSATIONS]: i18n.t('global:moduleNames.myconversations'),
  [OBJECT_TYPES.CONTACT_BOOK]: i18n.t('global:moduleNames.contactbook'),
};

export const MODULE_SHORT_NAMES = {
  [OBJECT_TYPES.DEAL_BASE]: i18n.t('global:moduleNames.short.dealbase'),
  [OBJECT_TYPES.USER]: i18n.t('global:moduleNames.short.user'),
  [OBJECT_TYPES.ORGANIZATION]: i18n.t('global:moduleNames.short.organization'),
  [OBJECT_TYPES.PORTFOLIO]: i18n.t('global:moduleNames.short.portfolio'),
  [OBJECT_TYPES.MY_CONVERSATIONS]: i18n.t('global:moduleNames.short.conversation'),
  [OBJECT_TYPES.CONTACT_BOOK]: i18n.t('global:moduleNames.short.contactbook'),
};

export const MODULES = {
  DEAL_BASE: {
    objectType: OBJECT_TYPES.DEAL_BASE,
    name: MODULE_NAMES[OBJECT_TYPES.DEAL_BASE],
    short: MODULE_SHORT_NAMES[OBJECT_TYPES.DEAL_BASE],
    canSearch: true,
  },
  CONTACT_BOOK: {
    objectType: OBJECT_TYPES.CONTACT_BOOK,
    name: MODULE_NAMES[OBJECT_TYPES.CONTACT_BOOK],
    short: MODULE_SHORT_NAMES[OBJECT_TYPES.CONTACT_BOOK],
    canSearch: true,
  },
  PORTFOLIO: {
    objectType: OBJECT_TYPES.PORTFOLIO,
    name: MODULE_NAMES[OBJECT_TYPES.PORTFOLIO],
    short: MODULE_SHORT_NAMES[OBJECT_TYPES.PORTFOLIO],
    canSearch: true,
  },
  USER: {
    objectType: OBJECT_TYPES.USER,
    name: MODULE_NAMES[OBJECT_TYPES.USER],
    short: MODULE_SHORT_NAMES[OBJECT_TYPES.USER],
    canSearch: false,
  },
  ORGANIZATION: {
    objectType: OBJECT_TYPES.ORGANIZATION,
    name: MODULE_NAMES[OBJECT_TYPES.ORGANIZATION],
    short: MODULE_SHORT_NAMES[OBJECT_TYPES.ORGANIZATION],
    canSearch: false,
  },
  MY_CONVERSATIONS: {
    objectType: OBJECT_TYPES.MY_CONVERSATIONS,
    name: MODULE_NAMES[OBJECT_TYPES.MY_CONVERSATIONS],
    short: MODULE_SHORT_NAMES[OBJECT_TYPES.MY_CONVERSATIONS],
    canSearch: false,
  },
};

export const INDEXED_MODULES = keyBy(Object.values(MODULES), (module) => module.objectType);

export const MODULE_OPTIONS = Object.values(MODULES).map((module) => ({
  value: module.objectType,
  label: module.name,
}));

export const MODULE_SEARCH_OPTIONS = Object.values(MODULES)
  .filter((module) => module.canSearch)
  .map((module) => ({
    value: module.objectType,
    label: module.name,
  }));
