import React from 'react';

import { Form, FormInstance, Tag, Tooltip, Typography } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { Trans } from 'react-i18next';
import { generatePath, Link } from 'react-router-dom';

import {
  ListingText,
  ListingSelect,
  TagsGroup,
  ListingCommonObjectMultiSelect,
  ListingUserMultiSelect,
  UserAvatarGroup,
  ListingContactBookTagSelect,
  ListingCommonObjectSelect,
  ListingCountrySelect,
  ListingNumberRange,
} from 'common-ui';
import { EditableListing } from 'common-ui/cells/types';
import { CommonObject, ContactBookListing, ListingConfig } from 'core/lib';
import { DYNAMIC, OBJECT_TYPES } from 'core/lib/constants';
import { ContactBookType } from 'core/lib/constants/contactBook';
import {
  CountryOptions,
  getCountriesOptionsByRegion,
  RegionOptions,
  TypeOptions,
  ContactBookTypesByValueKey,
  DiligenceTypeOptions,
} from 'core/lib/constants/selects';
import i18n from 'core/lib/utils/i18n';
import { numberSorter, textSorter } from 'core/lib/utils/sorters';
import { Routes } from 'routes/routes';

import ContactBookPopoverWrapper from '../components/popovers/wrapper';
import { contactBookFormConfig } from '../form/formConfig';

const { Text } = Typography;

const {
  details: { contactInformation, insight, professionalInformation, tags },
} = contactBookFormConfig;

export const columns = (
  [
    {
      title: <Trans i18nKey="contactBook:listing.columns.id" />,
      dataIndex: 'id',
      width: 60,
      visible: false,
      sorter: (a, b, order) => numberSorter(a.id, b.id, order),
      ellipsis: {
        showTitle: false,
      },
      render: (id) => (
        <Tooltip placement="topLeft" title={id}>
          {`#${id}`}
        </Tooltip>
      ),
    },
    {
      title: <Trans i18nKey="contactBook:listing.columns.name" />,
      dataIndex: 'name',
      width: 200,
      editable: true,
      visible: true,
      inputType: ListingText,
      inputRules: contactInformation.name.rules,
      sorter: (a, b, order) => textSorter(a.name, b.name, order),
      ellipsis: {
        showTitle: false,
      },
      render: (name, record) => (
        <Tooltip placement="topLeft" title={name} className="flex items-center gap-2">
          <ContactBookPopoverWrapper contactBook={record}>
            <Link className="truncate" to={generatePath(Routes.CONTACT_BOOK_DETAILS.path, { id: record.id })}>
              {name}
            </Link>
          </ContactBookPopoverWrapper>
        </Tooltip>
      ),
    },
    {
      title: <Trans i18nKey="contactBook:listing.columns.email" />,
      dataIndex: 'email',
      width: 200,
      editable: true,
      visible: true,
      inputType: ListingText,
      inputProps: {
        maxLength: 100,
      },
      inputRules: contactInformation.email.rules,
      sorter: (a, b, order) => textSorter(a.email, b.email, order),
      ellipsis: {
        showTitle: false,
      },
      render: (email) => (
        <Tooltip placement="topLeft" title={email} className="flex items-center gap-2">
          <Text
            copyable={{
              text: email,
              tooltips: null,
            }}
          >
            <div className="truncate">{email}</div>
          </Text>
        </Tooltip>
      ),
    },
    {
      title: <Trans i18nKey="contactBook:listing.columns.phoneNumber" />,
      dataIndex: 'phoneNumber',
      width: 200,
      editable: true,
      visible: true,
      inputType: ListingText,
      inputRules: contactInformation.phoneNumber.rules,
      sorter: (a, b, order) => textSorter(a.phoneNumber, b.phoneNumber, order),
      ellipsis: {
        showTitle: false,
      },
      render: (phoneNumber) => (
        <Tooltip placement="topLeft" title={phoneNumber}>
          {phoneNumber}
        </Tooltip>
      ),
    },
    {
      title: <Trans i18nKey="contactBook:listing.columns.type" />,
      dataIndex: 'type',
      width: 200,
      editable: true,
      visible: false,
      inputType: ListingSelect,
      inputProps: {
        onChange: (form: FormInstance) => {
          form.setFieldsValue({
            [contactBookFormConfig.details.professionalInformation.stage.dataIndex]: null,
            [contactBookFormConfig.details.professionalInformation.investmentFocus.dataIndex]: null,
            [contactBookFormConfig.details.professionalInformation.investmentTicket.dataIndex]: null,
            [contactBookFormConfig.details.professionalInformation.fundSize.dataIndex]: null,
            [contactBookFormConfig.details.professionalInformation.investmentGeography.dataIndex]: [],
            [contactBookFormConfig.details.professionalInformation.sharedCases.dataIndex]: [],
            [contactBookFormConfig.details.professionalInformation.industry.dataIndex]: null,
            [contactBookFormConfig.details.professionalInformation.diligenceType.dataIndex]: null,
          });
        },
        options: TypeOptions,
        showSearch: true,
      },
      inputRules: professionalInformation.type.rules,
      sorter: (a, b, order) => textSorter(a.type, b.type, order),
      ellipsis: {
        showTitle: false,
      },
      render: (type) => (
        <Tooltip placement="topLeft" title={ContactBookTypesByValueKey[type]}>
          <Tag key={type} color="blue">
            {ContactBookTypesByValueKey[type]}
          </Tag>
        </Tooltip>
      ),
    },
    {
      title: <Trans i18nKey="contactBook:listing.columns.region" />,
      dataIndex: 'region',
      width: 100,
      editable: true,
      inputType: ListingSelect,
      inputProps: {
        onChange: (form: FormInstance) => {
          form.setFieldValue('country', null);
        },
        showSearch: true,
        options: RegionOptions,
        className: 'w-full',
      },
      inputRules: contactInformation.region.rules,
      visible: true,
      sorter: (a, b, order) => textSorter(a.region, b.region, order),
      ellipsis: {
        showTitle: false,
      },
      render: (region) =>
        region && (
          <Tooltip placement="topLeft" title={region}>
            <Tag key={region} color="pink">
              {region}
            </Tag>
          </Tooltip>
        ),
    },
    {
      title: <Trans i18nKey="contactBook:listing.columns.country" />,
      dataIndex: 'country',
      width: 100,
      editable: true,
      inputType: ({ record, ...props }) => (
        <Form.Item className="m-0" shouldUpdate>
          {({ getFieldValue }) => {
            const region = getFieldValue('region');
            return <ListingSelect {...props} options={region ? getCountriesOptionsByRegion(region) : CountryOptions} />;
          }}
        </Form.Item>
      ),
      inputProps: {
        showSearch: true,
      },
      inputRules: contactInformation.country.rules,
      visible: true,
      sorter: (a, b, order) => textSorter(a.country, b.country, order),
      ellipsis: {
        showTitle: false,
      },
      render: (country) =>
        country && (
          <Tooltip placement="topLeft" title={country}>
            <Tag key={country} color="purple">
              {country}
            </Tag>
          </Tooltip>
        ),
    },
    {
      title: <Trans i18nKey="contactBook:listing.columns.tags" />,
      dataIndex: 'tags',
      width: 200,
      editable: true,
      visible: true,
      inputType: ListingContactBookTagSelect,
      inputProps: {
        allowClear: true,
        maxTagCount: 2,
      },
      inputRules: tags.tags.rules,
      ellipsis: {
        showTitle: false,
      },
      render: (tags?: string[]) =>
        tags && (
          <TagsGroup>
            {tags.map((tag) => (
              <Tag key={tag} color="orange">
                {tag}
              </Tag>
            ))}
          </TagsGroup>
        ),
    },
    {
      title: <Trans i18nKey="contactBook:listing.columns.relations" />,
      dataIndex: 'relations',
      width: 100,
      editable: true,
      visible: true,
      inputType: ListingUserMultiSelect,
      inputProps: {
        maxTagCount: 2,
      },
      inputRules: insight.relations.rules,
      ellipsis: {
        showTitle: false,
      },
      render: (relations) => relations && <UserAvatarGroup users={relations} />,
    },
    {
      title: <Trans i18nKey="contactBook:listing.columns.stage" />,
      dataIndex: 'stage',
      width: 100,
      editable: true,
      visible: false,
      sorter: (a, b, order) => textSorter(a.stage?.name, b.stage?.name, order),
      inputType: ListingCommonObjectSelect,
      inputProps: {
        isDisabled: (form: FormInstance) => {
          return (form.getFieldValue('type') as ContactBookType) !== 'VentureCapital';
        },
        asTag: true,
        archived: false,
        color: 'orange',
        objectType: OBJECT_TYPES.CONTACT_BOOK,
        type: DYNAMIC.CONTACT_BOOK.STAGE,
      },
      inputRules: professionalInformation.stage.rules,
      ellipsis: {
        showTitle: false,
      },
      render: (stage) =>
        stage && (
          <Tooltip placement="topLeft" title={stage.name}>
            <Tag key={stage.id} color="orange">
              {stage.name}
            </Tag>
          </Tooltip>
        ),
    },
    {
      title: <Trans i18nKey="contactBook:listing.columns.investmentFocus" />,
      dataIndex: 'investmentFocus',
      width: 200,
      editable: true,
      visible: false,
      inputType: ListingCommonObjectMultiSelect,
      inputProps: {
        isDisabled: (form: FormInstance) => {
          return (form.getFieldValue('type') as ContactBookType) !== 'VentureCapital';
        },
        asTags: true,
        max: 3,
        archived: false,
        color: 'green',
        objectType: OBJECT_TYPES.CONTACT_BOOK,
        type: DYNAMIC.CONTACT_BOOK.INVESTMENT_FOCUS,
      },
      inputRules: professionalInformation.investmentFocus.rules,
      ellipsis: {
        showTitle: false,
      },
      render: (investmentFocus) =>
        investmentFocus && (
          <Tooltip placement="topLeft" title={investmentFocus.map((focus: CommonObject) => focus.name).join(', ')}>
            {investmentFocus?.map((focus: CommonObject) => (
              <Tag key={focus.id} color="green">
                {focus.name}
              </Tag>
            ))}
          </Tooltip>
        ),
    },
    {
      title: <Trans i18nKey="contactBook:listing.columns.investmentTicket" />,
      dataIndex: 'investmentTicket',
      width: 200,
      editable: true,
      visible: false,
      inputType: ListingNumberRange,
      inputProps: {
        isDisabled: (form: FormInstance) => {
          return (form.getFieldValue('type') as ContactBookType) !== 'VentureCapital';
        },
        addonBefore: '€',
        addonAfter: i18n.t('global:placeholders.millionsAbbr'),
      },
      inputRules: professionalInformation.investmentTicket.rules,
      ellipsis: {
        showTitle: false,
      },
      render: (investmentTicket) =>
        investmentTicket && (
          <div>{`€ ${investmentTicket.from}-${investmentTicket.to} ${i18n.t('global:placeholders.millionsAbbr')}`}</div>
        ),
    },
    {
      title: <Trans i18nKey="contactBook:listing.columns.fundSize" />,
      dataIndex: 'fundSize',
      width: 200,
      editable: true,
      visible: false,
      inputType: ListingNumberRange,
      inputProps: {
        isDisabled: (form: FormInstance) => {
          return (form.getFieldValue('type') as ContactBookType) !== 'VentureCapital';
        },
        addonBefore: '€',
        addonAfter: i18n.t('global:placeholders.millionsAbbr'),
      },
      inputRules: professionalInformation.fundSize.rules,
      ellipsis: {
        showTitle: false,
      },
      render: (fundSize) =>
        fundSize && <div>{`€ ${fundSize.from}-${fundSize.to} ${i18n.t('global:placeholders.millionsAbbr')}`}</div>,
    },
    {
      title: <Trans i18nKey="contactBook:listing.columns.investmentGeography" />,
      dataIndex: 'investmentGeography',
      width: 200,
      editable: true,
      visible: false,
      inputType: ListingCountrySelect,
      inputProps: {
        mode: 'multiple',
        allowClear: true,
        isDisabled: (form: FormInstance) => {
          return (form.getFieldValue('type') as ContactBookType) !== 'VentureCapital';
        },
      },
      inputRules: professionalInformation.investmentGeography.rules,
      ellipsis: {
        showTitle: false,
      },
      render: (investmentGeography) =>
        investmentGeography && (
          <Tooltip placement="topLeft" title={investmentGeography.map((geography: string) => geography).join(', ')}>
            {investmentGeography?.map((geography: string) => (
              <Tag key={geography} color="purple">
                {geography}
              </Tag>
            ))}
          </Tooltip>
        ),
    },
    {
      title: <Trans i18nKey="contactBook:listing.columns.industry" />,
      dataIndex: 'industry',
      width: 200,
      editable: true,
      visible: false,
      inputType: ListingCommonObjectMultiSelect,
      inputProps: {
        isDisabled: (form: FormInstance) => {
          return (form.getFieldValue('type') as ContactBookType) !== 'IndustryExpert';
        },
        asTags: true,
        max: 3,
        archived: false,
        color: 'green',
        objectType: OBJECT_TYPES.CONTACT_BOOK,
        type: DYNAMIC.CONTACT_BOOK.INDUSTRY,
      },
      inputRules: professionalInformation.investmentFocus.rules,
      ellipsis: {
        showTitle: false,
      },
      render: (industry) =>
        industry && (
          <Tooltip placement="topLeft" title={industry.map((ind: CommonObject) => ind.name).join(', ')}>
            {industry?.map((ind: CommonObject) => (
              <Tag key={ind.id} color="green">
                {ind.name}
              </Tag>
            ))}
          </Tooltip>
        ),
    },
    {
      title: <Trans i18nKey="contactBook:listing.columns.diligenceType" />,
      dataIndex: 'diligenceType',
      width: 100,
      editable: true,
      visible: false,
      inputType: ListingSelect,
      inputProps: {
        isDisabled: (form: FormInstance) => {
          return (form.getFieldValue('type') as ContactBookType) !== 'DiligencePartner';
        },
        options: DiligenceTypeOptions,
      },
      inputRules: professionalInformation.diligenceType.rules,
      sorter: (a, b, order) => textSorter(a.diligenceType, b.diligenceType, order),
      ellipsis: {
        showTitle: false,
      },
      render: (diligenceType) =>
        diligenceType && (
          <Tooltip placement="topLeft" title={diligenceType}>
            <Tag key={diligenceType} color="blue">
              {diligenceType}
            </Tag>
          </Tooltip>
        ),
    },
  ] as (ColumnType<ContactBookListing> & Partial<ListingConfig> & EditableListing<ContactBookListing>)[]
).map((col, index) => {
  col.index = index;
  return col;
});
