import React from 'react';

import { Input, InputNumber, InputNumberProps, InputProps, Select, SelectProps, UploadProps } from 'antd';
import { TextAreaProps } from 'antd/lib/input';
import { Trans } from 'react-i18next';

import { AttachmentUpload, ImageUploader } from 'common-ui/upload';
import { Attachment, CommonObject } from 'core/lib';
import { DYNAMIC, MAX_BIGINT, MAX_INT, OBJECT_TYPES } from 'core/lib/constants';
import { EXTERNAL_ATTACHMENT_PREFIX } from 'core/lib/constants/dealbase';
import {
  FoundingYearsOptions,
  InfoSourceOptions,
  RevenueYearsOption,
  EbitdaYearsOption,
  RevenueProjectionYearsOption,
  EbitdaProjectionYearsOption,
} from 'core/lib/constants/selects';
import { CurrencyOptions } from 'core/lib/constants/selects/general';
import i18n from 'core/lib/utils/i18n';
import { valueDelimiterFormatter } from 'utils';
import { CountrySelect } from 'utils/form';
import CommonObjectMultiSelect from 'utils/form/commonObjectMultiSelect';
import CommonObjectSelect from 'utils/form/commonObjectSelect';
import { rules } from 'utils/form/rules';

const { TextArea } = Input;

export const externalFormConfig = {
  generalInformation: {
    name: {
      label: <Trans i18nKey="dealbase:form.generalInformation.labels.companyName" />,
      dataIndex: 'name',
      rules: [rules.required],
      component: (props: InputProps) => <Input maxLength={40} {...props} />,
    },
    companyLogo: {
      label: <Trans i18nKey="dealbase:form.generalInformation.labels.companyLogo" />,
      dataIndex: 'companyLogo',
      rules: [],
      component: ({ fileList, ...props }: UploadProps & { value?: Attachment }) => (
        <ImageUploader objectType={OBJECT_TYPES.DEAL_BASE} dataIndex="companyLogo" {...props} />
      ),
    },
    industry: {
      label: (
        <Trans
          i18nKey="dealbase:form.generalInformation.labels.industry"
          values={{ range: i18n.t('global:labels.selectRange', { range: '1-3' }) }}
        />
      ),
      dataIndex: 'industries',
      rules: [rules.required],
      component: (props: SelectProps<number[]> & { selectedOption?: CommonObject[] }) => (
        <CommonObjectMultiSelect
          {...props}
          max={3}
          asTags
          color="green"
          archived={false}
          type={DYNAMIC.DEAL_BASE.INDUSTRY}
          objectType={OBJECT_TYPES.DEAL_BASE}
        />
      ),
    },
    technology: {
      label: <Trans i18nKey="dealbase:form.generalInformation.labels.technology" />,
      dataIndex: 'technology',
      rules: [rules.required],
      component: (props: SelectProps<number> & { selectedOption?: CommonObject }) => (
        <CommonObjectSelect
          {...props}
          asTag
          color="volcano"
          archived={false}
          type={DYNAMIC.DEAL_BASE.TECHNOLOGY}
          objectType={OBJECT_TYPES.DEAL_BASE}
        />
      ),
    },
    customerFocus: {
      label: <Trans i18nKey="dealbase:form.generalInformation.labels.customerFocus" />,
      dataIndex: 'customerFocus',
      rules: [rules.required],
      component: (props: SelectProps<number[]> & { selectedOption?: CommonObject[] }) => (
        <CommonObjectMultiSelect
          {...props}
          asTags
          color="cyan"
          archived={false}
          type={DYNAMIC.DEAL_BASE.CUSTOMER_FOCUS}
          objectType={OBJECT_TYPES.DEAL_BASE}
        />
      ),
    },
    country: {
      label: <Trans i18nKey="dealbase:form.generalInformation.labels.country" />,
      dataIndex: 'country',
      rules: [rules.required],
      component: CountrySelect,
    },
    city: {
      label: <Trans i18nKey="dealbase:form.generalInformation.labels.city" />,
      dataIndex: 'city',
      rules: [rules.required],
      component: (props: InputProps) => <Input maxLength={50} {...props} />,
    },
    website: {
      label: <Trans i18nKey="dealbase:form.generalInformation.labels.website" />,
      dataIndex: 'website',
      rules: [rules.required, rules.url],
      component: (props: InputProps) => <Input maxLength={50} {...props} />,
    },
    foundingYear: {
      label: <Trans i18nKey="dealbase:form.generalInformation.labels.foundingYear" />,
      dataIndex: 'foundingYear',
      rules: [rules.required],
      component: (props: SelectProps<number>) => <Select options={FoundingYearsOptions} {...props} />,
    },
    heardFrom: {
      label: <Trans i18nKey="dealbase:form.generalInformation.labels.heardFrom" />,
      dataIndex: 'heardFrom',
      rules: [],
      component: (props: SelectProps<undefined>) => <Select options={InfoSourceOptions} allowClear {...props} />,
    },
  },
  contactDetails: {
    ceo: {
      label: <Trans i18nKey="dealbase:form.contactDetails.labels.ceo" />,
      dataIndex: 'ceo',
      rules: [rules.required],
      component: (props: InputProps) => <Input maxLength={40} {...props} />,
    },
    founderName: {
      label: <Trans i18nKey="dealbase:form.contactDetails.labels.founderName" />,
      dataIndex: 'fullname',
      rules: [rules.required],
      component: (props: InputProps) => <Input maxLength={40} {...props} />,
    },
    founderJobTitle: {
      label: <Trans i18nKey="dealbase:form.contactDetails.labels.jobTitle" />,
      dataIndex: 'jobTitle',
      rules: [rules.required],
      component: (props: InputProps) => <Input maxLength={40} {...props} />,
    },
    founderLinkedIn: {
      label: <Trans i18nKey="dealbase:form.contactDetails.labels.linkedIn" />,
      dataIndex: 'linkedin',
      rules: [rules.url],
      component: (props: InputProps) => <Input maxLength={100} {...props} />,
    },
    contactPerson: {
      label: <Trans i18nKey="dealbase:form.contactDetails.labels.contactPerson" />,
      dataIndex: 'contactPerson',
      rules: [rules.required],
      component: (props: InputProps) => <Input maxLength={40} {...props} />,
    },
    contactNumber: {
      label: <Trans i18nKey="dealbase:form.contactDetails.labels.contactNumber" />,
      dataIndex: 'contactNumber',
      rules: [rules.required],
      component: (props: InputProps) => <Input maxLength={40} {...props} />,
    },
    contactEmail: {
      label: <Trans i18nKey="dealbase:form.contactDetails.labels.contactEmail" />,
      dataIndex: 'contactEmail',
      rules: [rules.email, rules.required],
      component: (props: InputProps) => <Input type="email" maxLength={100} {...props} />,
    },
  },
  overview: {
    businessIdea: {
      label: <Trans i18nKey="dealbase:form.overview.labels.businessIdea" />,
      dataIndex: 'businessIdea',
      rules: [rules.required],
      component: (props: TextAreaProps) => (
        <TextArea autoSize={{ minRows: 5 }} showCount={!props.disabled} maxLength={250} {...props} />
      ),
    },
    productStatus: {
      label: <Trans i18nKey="dealbase:form.overview.labels.productStatus" />,
      dataIndex: 'productStatus',
      rules: [rules.required],
      component: (props: SelectProps<number> & { selectedOption?: CommonObject }) => (
        <CommonObjectSelect {...props} type={DYNAMIC.DEAL_BASE.PRODUCT_STATUS} objectType={OBJECT_TYPES.DEAL_BASE} />
      ),
    },
    problemSolve: {
      label: <Trans i18nKey="dealbase:form.overview.labels.problemSolve" />,
      dataIndex: 'problemSolve',
      rules: [rules.required],
      component: (props: TextAreaProps) => (
        <TextArea autoSize={{ minRows: 5 }} showCount={!props.disabled} maxLength={250} {...props} />
      ),
    },
    marketSize: {
      label: <Trans i18nKey="dealbase:form.overview.labels.marketSize" />,
      dataIndex: 'marketSize',
      rules: [rules.required],
      component: (props: TextAreaProps) => (
        <TextArea autoSize={{ minRows: 5 }} showCount={!props.disabled} maxLength={250} {...props} />
      ),
    },
    yourCustomer: {
      label: <Trans i18nKey="dealbase:form.overview.labels.yourCustomer" />,
      dataIndex: 'yourCustomer',
      rules: [rules.required],
      component: (props: TextAreaProps) => (
        <TextArea autoSize={{ minRows: 5 }} showCount={!props.disabled} maxLength={250} {...props} />
      ),
    },
    getYourCustomer: {
      label: <Trans i18nKey="dealbase:form.overview.labels.getYourCustomer" />,
      dataIndex: 'getYourCustomer',
      rules: [rules.required],
      component: (props: TextAreaProps) => (
        <TextArea autoSize={{ minRows: 5 }} showCount={!props.disabled} maxLength={250} {...props} />
      ),
    },
    tractionSoFar: {
      label: <Trans i18nKey="dealbase:form.overview.labels.tractionSoFar" />,
      dataIndex: 'tractionSoFar',
      rules: [rules.required],
      component: (props: TextAreaProps) => (
        <TextArea autoSize={{ minRows: 5 }} showCount={!props.disabled} maxLength={250} {...props} />
      ),
    },
    roundSpecifications: {
      label: <Trans i18nKey="dealbase:form.overview.labels.roundSpecifications" />,
      dataIndex: 'roundSpecifications',
      rules: [rules.required],
      component: (props: TextAreaProps) => (
        <TextArea autoSize={{ minRows: 5 }} showCount={!props.disabled} maxLength={250} {...props} />
      ),
    },
    preMoneyValuation: {
      label: <Trans i18nKey="dealbase:form.overview.labels.preMoneyValuation" />,
      dataIndex: 'preMoneyValuation',
      rules: [rules.required, rules.requiredNumberRange, rules.numberRange(MAX_INT)],
    },
    investmentRound: {
      label: <Trans i18nKey="dealbase:form.overview.labels.investmentRound" />,
      dataIndex: 'investmentRound',
      rules: [rules.required, rules.requiredNumberRange, rules.numberRange(MAX_INT)],
    },
    stage: {
      label: <Trans i18nKey="dealbase:form.overview.labels.stage" />,
      dataIndex: 'stage',
      rules: [rules.required],
      component: (props: SelectProps<number> & { selectedOption?: CommonObject }) => (
        <CommonObjectSelect
          {...props}
          archived={false}
          type={DYNAMIC.DEAL_BASE.STAGE}
          objectType={OBJECT_TYPES.DEAL_BASE}
        />
      ),
    },
  },
  documents: {
    pitchDeck: {
      dataIndex: 'pitchDeckDocument',
      label: <Trans i18nKey="dealbase:documents.pitchDeckDocument" />,
      rules: [rules.required],
      component: (props: { value?: Attachment | undefined; disabled?: boolean }) => (
        <AttachmentUpload
          {...props}
          maxCount={1}
          accept=".pdf"
          objectType={OBJECT_TYPES.DEAL_BASE}
          dataIndex="pitchDeckDocument"
        />
      ),
    },
    budget: {
      dataIndex: 'budgetDocument',
      label: <Trans i18nKey="dealbase:documents.budgetDocument" />,
      rules: [],
      component: (props: { value?: Attachment | undefined; disabled?: boolean }) => (
        <AttachmentUpload {...props} maxCount={1} objectType={OBJECT_TYPES.DEAL_BASE} dataIndex="budgetDocument" />
      ),
    },
    businessPlan: {
      dataIndex: 'businessPlanDocument',
      label: <Trans i18nKey="dealbase:documents.businessPlanDocument" />,
      rules: [],
      component: (props: { value?: Attachment | undefined; disabled?: boolean }) => (
        <AttachmentUpload
          {...props}
          maxCount={1}
          objectType={OBJECT_TYPES.DEAL_BASE}
          dataIndex="businessPlanDocument"
        />
      ),
    },
    externalAttachments: {
      dataIndex: 'externalAttachments',
      rules: [],
      component: (props: { value?: Attachment | undefined; disabled?: boolean }) => (
        <AttachmentUpload
          {...props}
          dragger
          multiple
          objectType={OBJECT_TYPES.DEAL_BASE}
          dataIndex={EXTERNAL_ATTACHMENT_PREFIX}
          asPrefix
        />
      ),
    },
  },

  metrics: {
    currency: {
      label: <Trans i18nKey="dealbase:form.metrics.labels.currency" />,
      dataIndex: 'currency',
      rules: [rules.required],
      component: (props: SelectProps<undefined>) => <Select options={CurrencyOptions} allowClear {...props} />,
    },
    revenueType: {
      label: <Trans i18nKey="dealbase:form.revenue.labels.revenueType" />,
      help: <Trans i18nKey="dealbase:form.revenue.helper.revenueType" />,
      dataIndex: 'revenueType',
      rules: [rules.required],
      component: (props: SelectProps<number> & { selectedOption?: CommonObject }) => (
        <CommonObjectSelect
          {...props}
          archived={false}
          type={DYNAMIC.DEAL_BASE.REVENUE_TYPE}
          objectType={OBJECT_TYPES.DEAL_BASE}
        />
      ),
    },
    revenue: {
      year: {
        label: <Trans i18nKey="dealbase:labels.year" />,
        dataIndex: 'year',
        rules: [rules.required],
        component: (props: SelectProps<undefined>) => <Select options={RevenueYearsOption} {...props} />,
      },
      amount: {
        label: <Trans i18nKey="dealbase:labels.amount" />,
        dataIndex: 'amount',
        rules: [rules.required, rules.max(MAX_BIGINT), rules.min(-MAX_BIGINT)],
        component: (props: InputNumberProps<number>) => (
          <InputNumber<number>
            controls={false}
            formatter={(value) => valueDelimiterFormatter(value)}
            className="w-full"
            {...props}
          />
        ),
      },
    },
    revenueProjection: {
      year: {
        label: <Trans i18nKey="dealbase:labels.year" />,
        dataIndex: 'year',
        rules: [rules.required],
        component: (props: SelectProps<undefined>) => <Select options={RevenueProjectionYearsOption} {...props} />,
      },
      amount: {
        label: <Trans i18nKey="dealbase:labels.amount" />,
        dataIndex: 'amount',
        rules: [rules.max(MAX_BIGINT), rules.min(-MAX_BIGINT)],
        component: (props: InputNumberProps<number>) => (
          <InputNumber<number>
            controls={false}
            formatter={(value) => valueDelimiterFormatter(value)}
            className="w-full"
            {...props}
          />
        ),
      },
    },
    ebitda: {
      year: {
        label: <Trans i18nKey="dealbase:labels.year" />,
        dataIndex: 'year',
        rules: [rules.required],
        component: (props: SelectProps<undefined>) => <Select options={EbitdaYearsOption} {...props} />,
      },
      amount: {
        label: <Trans i18nKey="dealbase:labels.amount" />,
        dataIndex: 'amount',
        rules: [rules.required, rules.max(MAX_BIGINT), rules.min(-MAX_BIGINT)],
        component: (props: InputNumberProps<number>) => (
          <InputNumber<number>
            controls={false}
            formatter={(value) => valueDelimiterFormatter(value)}
            className="w-full"
            {...props}
          />
        ),
      },
    },
    ebitdaProjection: {
      year: {
        label: <Trans i18nKey="dealbase:labels.year" />,
        dataIndex: 'year',
        rules: [rules.required],
        component: (props: SelectProps<undefined>) => <Select options={EbitdaProjectionYearsOption} {...props} />,
      },
      amount: {
        label: <Trans i18nKey="dealbase:labels.amount" />,
        dataIndex: 'amount',
        rules: [rules.max(MAX_BIGINT), rules.min(-MAX_BIGINT)],
        component: (props: InputNumberProps<number>) => (
          <InputNumber<number>
            controls={false}
            formatter={(value) => valueDelimiterFormatter(value)}
            className="w-full"
            {...props}
          />
        ),
      },
    },
  },
};
