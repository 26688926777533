import React from 'react';

import { Badge, Tag, Tooltip } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { format } from 'date-fns';
import { Trans } from 'react-i18next';
import { generatePath, Link } from 'react-router-dom';

import {
  ListingDatePicker,
  ListingText,
  UserAvatar,
  ListingSelect,
  ListingCommonObjectSelect,
  ListingUserSelect,
} from 'common-ui';
import ListingCommonObjectMultiSelect from 'common-ui/cells/listingCommonObjectMultiSelect';
import { EditableListing } from 'common-ui/cells/types';
import { CompanyLogo } from 'common-ui/viewers';
import { CommonObject, DealBaseListing, ListingConfig } from 'core/lib';
import { DYNAMIC, OBJECT_TYPES } from 'core/lib/constants';
import { DEFAULT_DATETIME_FORMAT, DEFAULT_DATE_FORMAT } from 'core/lib/constants/defaults';
import { CountryOptions, PriorityOptions, TierOptions } from 'core/lib/constants/selects';
import { DEALBASE_STATUS_KEYS } from 'core/lib/constants/statuses';
import colors from 'core/lib/theme/colors';
import { parseJSONDate } from 'core/lib/utils';
import i18n from 'core/lib/utils/i18n';
import { dateSorter, numberSorter, textSorter } from 'core/lib/utils/sorters';
import { Routes } from 'routes/routes';

import { dealBaseFormConfig } from '../form/formConfig';

const {
  generalInformation: { generalInformation, overview },
  evaluation: { generalEvaluation },
} = dealBaseFormConfig;

export const columns = (
  [
    {
      title: <Trans i18nKey="dealbase:listing.columns.id" />,
      dataIndex: 'id',
      width: 60,
      visible: false,
      sorter: (a, b, order) => numberSorter(a.id, b.id, order),
      ellipsis: {
        showTitle: false,
      },
      render: (id) => (
        <Tooltip placement="topLeft" title={id}>
          {`#${id}`}
        </Tooltip>
      ),
    },
    {
      title: <Trans i18nKey="dealbase:listing.columns.name" />,
      dataIndex: 'name',
      width: 200,
      editable: true,
      visible: true,
      inputType: ListingText,
      inputProps: {
        maxLength: 40,
      },
      inputRules: generalInformation.name.rules,
      sorter: (a, b, order) => textSorter(a.name, b.name, order),
      ellipsis: {
        showTitle: false,
      },
      render: (name, record) => (
        <Tooltip placement="topLeft" title={name} className="flex items-center gap-2">
          <CompanyLogo id={record.companyLogo} />
          <Link className="truncate" to={generatePath(Routes.DEAL_BASE_DETAILS.path, { id: record.id })}>
            {name}
          </Link>
        </Tooltip>
      ),
    },
    {
      title: <Trans i18nKey="dealbase:listing.columns.assigned" />,
      dataIndex: 'assigned',
      width: 100,
      editable: true,
      visible: true,
      inputType: ListingUserSelect,
      inputRules: generalEvaluation.assigned.rules,
      sorter: (a, b, order) => textSorter(a.assigned?.name, b.assigned?.name, order),
      ellipsis: {
        showTitle: false,
      },
      render: (assigned) => assigned && <UserAvatar user={assigned} />,
    },
    {
      title: <Trans i18nKey="dealbase:listing.columns.industry" />,
      dataIndex: 'industries',
      width: 100,
      visible: true,
      editable: true,
      inputType: ListingCommonObjectMultiSelect,
      inputRules: generalInformation.industry.rules,
      inputProps: {
        max: 3,
        asTags: true,
        archived: false,
        color: 'green',
        objectType: OBJECT_TYPES.DEAL_BASE,
        type: DYNAMIC.DEAL_BASE.INDUSTRY,
      },
      ellipsis: {
        showTitle: false,
      },
      render: (industries) =>
        industries && (
          <Tooltip placement="topLeft" title={industries.map((industry: CommonObject) => industry.name).join(', ')}>
            {industries?.map((industry: CommonObject) => (
              <Tag key={industry.id} color="green">
                {industry.name}
              </Tag>
            ))}
          </Tooltip>
        ),
    },
    {
      title: <Trans i18nKey="dealbase:listing.columns.country" />,
      dataIndex: 'country',
      width: 100,
      editable: true,
      inputType: ListingSelect,
      inputProps: {
        options: CountryOptions,
        showSearch: true,
      },
      inputRules: generalInformation.country.rules,
      visible: true,
      sorter: (a, b, order) => textSorter(a.country, b.country, order),
      ellipsis: {
        showTitle: false,
      },
      render: (country) => (
        <Tooltip placement="topLeft" title={country}>
          <Tag key={country} color="purple">
            {country}
          </Tag>
        </Tooltip>
      ),
    },
    {
      title: <Trans i18nKey="dealbase:listing.columns.technology" />,
      dataIndex: 'technology',
      width: 100,
      visible: true,
      editable: true,
      sorter: (a, b, order) => textSorter(a.technology?.name, b.technology?.name, order),
      inputType: ListingCommonObjectSelect,
      inputRules: generalInformation.technology.rules,
      inputProps: {
        asTag: true,
        archived: false,
        color: 'volcano',
        objectType: OBJECT_TYPES.DEAL_BASE,
        type: DYNAMIC.DEAL_BASE.TECHNOLOGY,
      },
      ellipsis: {
        showTitle: false,
      },
      render: (technology) =>
        technology && (
          <Tooltip placement="topLeft" title={technology.name}>
            <Tag key={technology.id} color="volcano">
              {technology.name}
            </Tag>
          </Tooltip>
        ),
    },
    {
      title: <Trans i18nKey="dealbase:listing.columns.stage" />,
      dataIndex: 'stage',
      width: 100,
      visible: false,
      editable: true,
      inputType: ListingCommonObjectSelect,
      inputRules: overview.stage.rules,
      inputProps: {
        archived: false,
        objectType: OBJECT_TYPES.DEAL_BASE,
        type: DYNAMIC.DEAL_BASE.STAGE,
      },
      sorter: (a, b, order) => textSorter(a.stage?.name, b.stage?.name, order),
      ellipsis: {
        showTitle: false,
      },
      render: (stage: CommonObject | null) => (
        <Tooltip placement="topLeft" title={stage?.name}>
          {stage?.name}
        </Tooltip>
      ),
    },
    {
      title: <Trans i18nKey="dealbase:listing.columns.nextSteps" />,
      dataIndex: 'nextSteps',
      width: 150,
      visible: true,
      sorter: (a, b, order) => textSorter(a.nextSteps, b.nextSteps, order),
      ellipsis: {
        showTitle: false,
      },
      inputType: ListingText,
      inputProps: {
        maxLength: 80,
      },
      inputRules: generalEvaluation.nextSteps.rules,
      editable: true,
      render: (nextSteps) => (
        <Tooltip placement="topLeft" title={nextSteps}>
          {nextSteps}
        </Tooltip>
      ),
    },
    {
      title: <Trans i18nKey="dealbase:listing.columns.sort" />,
      dataIndex: 'sort',
      width: 100,
      visible: false,
      sorter: (a, b, order) => textSorter(a.sort, b.sort, order),
      ellipsis: {
        showTitle: false,
      },
      render: (sort) => (
        <Tooltip placement="topLeft" title={sort}>
          {sort}
        </Tooltip>
      ),
    },
    {
      title: <Trans i18nKey="dealbase:listing.columns.priority" />,
      dataIndex: 'priority',
      width: 100,
      visible: false,
      editable: true,
      inputType: ListingSelect,
      inputProps: {
        options: PriorityOptions,
      },
      inputRules: generalEvaluation.priority.rules,
      sorter: (a, b, order) => numberSorter(a.priority, b.priority, order),
      ellipsis: {
        showTitle: false,
      },
      render: (priority) => (
        <Tooltip placement="topLeft" title={priority}>
          {priority}
        </Tooltip>
      ),
    },
    {
      title: <Trans i18nKey="dealbase:listing.columns.status" />,
      dataIndex: 'status',
      width: 100,
      visible: false,
      sorter: (a, b, order) =>
        textSorter(
          i18n.t(`dealbase:status.label.${DEALBASE_STATUS_KEYS[a.status]}`),
          i18n.t(`dealbase:status.label.${DEALBASE_STATUS_KEYS[b.status]}`),
          order
        ),
      ellipsis: {
        showTitle: false,
      },
      render: (status) => (
        <Tooltip
          placement="topLeft"
          title={<Trans i18nKey={`dealbase:status.label.${DEALBASE_STATUS_KEYS[status]}`} />}
        >
          <Tag color="blue">
            <Trans i18nKey={`dealbase:status.label.${DEALBASE_STATUS_KEYS[status]}`} />
          </Tag>
        </Tooltip>
      ),
    },
    {
      title: <Trans i18nKey="dealbase:listing.columns.resultingScore" />,
      dataIndex: 'resultingScore',
      width: 100,
      visible: true,
      sorter: (a, b, order) => numberSorter(a.resultingScore, b.resultingScore, order),
      ellipsis: {
        showTitle: false,
      },
      render: (resultingScore?: number) => (
        <Badge
          count={resultingScore ? resultingScore.toFixed(1) : null}
          style={{ backgroundColor: colors.Primary.rgba }}
          showZero
        />
      ),
    },
    {
      title: <Trans i18nKey="dealbase:listing.columns.tier" />,
      dataIndex: 'tier',
      width: 100,
      visible: true,
      editable: true,
      inputType: ListingSelect,
      inputProps: {
        options: TierOptions,
      },
      inputRules: generalEvaluation.tier.rules,
      sorter: (a, b, order) => numberSorter(a.tier, b.tier, order),
      ellipsis: {
        showTitle: false,
      },
      render: (tier) => (
        <Tooltip placement="topLeft" title={tier}>
          {tier}
        </Tooltip>
      ),
    },
    {
      title: <Trans i18nKey="dealbase:listing.columns.createdDate" />,
      dataIndex: 'dateCreated',
      width: 100,
      visible: false,
      sorter: (a, b, order) => dateSorter(parseJSONDate(a.dateCreated), parseJSONDate(b.dateCreated), order),
      ellipsis: {
        showTitle: false,
      },
      render: (dateCreated) => {
        const formattedDate = dateCreated ? format(parseJSONDate(dateCreated)!, DEFAULT_DATETIME_FORMAT) : '';

        return (
          <Tooltip placement="topLeft" title={formattedDate}>
            {formattedDate}
          </Tooltip>
        );
      },
    },
    {
      title: <Trans i18nKey="dealbase:listing.columns.followUpDate" />,
      dataIndex: 'followUpDate',
      width: 100,
      editable: true,
      visible: false,
      inputType: ListingDatePicker,
      inputRules: generalEvaluation.followUpDate.rules,
      sorter: (a, b, order) => dateSorter(parseJSONDate(a.followUpDate), parseJSONDate(b.followUpDate), order),
      ellipsis: {
        showTitle: false,
      },
      render: (followUpDate) => {
        const formattedDate = followUpDate ? format(parseJSONDate(followUpDate)!, DEFAULT_DATE_FORMAT) : '';

        return (
          <Tooltip placement="topLeft" title={formattedDate}>
            {formattedDate}
          </Tooltip>
        );
      },
    },
  ] as (ColumnType<DealBaseListing> & Partial<ListingConfig> & EditableListing<DealBaseListing>)[]
).map((col, index) => {
  col.index = index;
  return col;
});
