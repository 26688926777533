import React from 'react';

import { Checkbox, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { DealBaseFilters } from 'core/lib';
import { TierOptions } from 'core/lib/constants/selects';

const { Title } = Typography;

type TierFilterProps = {
  onChange: (filters: Partial<DealBaseFilters>) => void;
  filters: number[] | undefined;
};

const TierFilter = ({ onChange, filters }: TierFilterProps) => {
  const { t } = useTranslation();

  return (
    <div>
      <Title level={5}>{t('dealbase:filters.tier')}</Title>
      <Checkbox.Group value={filters} onChange={(tier) => onChange({ tier: tier as number[] })} options={TierOptions} />
    </div>
  );
};

export default TierFilter;
