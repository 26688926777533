import { OrganizationShort, BaseUser, PortfolioDetails } from 'core/lib';
import { PortfolioReviewStatus } from 'core/lib/constants/portfolio';
import { PortfolioStatusType } from 'core/lib/constants/statuses';
import { CommonObject } from 'core/lib/modules/shared';
import { Classification } from 'core/lib/modules/shared/entities/classification';

class PortfolioBuilder {
  private readonly _portfolio: Partial<PortfolioDetails>;

  constructor() {
    this._portfolio = {};
  }

  build() {
    return JSON.parse(JSON.stringify(this._portfolio)) as PortfolioDetails;
  }

  id(id: number) {
    this._portfolio.id = id;
    return this;
  }

  name(name: string) {
    this._portfolio.name = name;
    return this;
  }
  description(description: string) {
    this._portfolio.description = description;
    return this;
  }

  organization(organization: OrganizationShort) {
    this._portfolio.organization = organization;
    return this;
  }

  type(type: CommonObject) {
    this._portfolio.type = type;
    return this;
  }
  owner(owner: BaseUser) {
    this._portfolio.owner = owner;
    return this;
  }
  responsible(responsible: BaseUser) {
    this._portfolio.responsible = responsible;
    return this;
  }
  taskOwner(taskOwner: BaseUser) {
    this._portfolio.taskOwner = taskOwner;
    return this;
  }
  supplierCompany(supplierCompany: string) {
    this._portfolio.supplierCompany = supplierCompany;
    return this;
  }
  systemName(systemName: string) {
    this._portfolio.systemName = systemName;
    return this;
  }
  systemVersion(systemVersion: string) {
    this._portfolio.systemVersion = systemVersion;
    return this;
  }
  technology(technology: CommonObject | null) {
    this._portfolio.technology = technology;
    return this;
  }
  supplierType(supplierType: CommonObject | null) {
    this._portfolio.supplierType = supplierType;
    return this;
  }
  language(language: CommonObject[]) {
    this._portfolio.language = language;
    return this;
  }
  complianceStatus(complianceStatus: CommonObject | null) {
    this._portfolio.complianceStatus = complianceStatus;
    return this;
  }
  initialValidationDate(initialValidationDate: string) {
    this._portfolio.initialValidationDate = initialValidationDate;
    return this;
  }
  lastPeriodReview(lastPeriodReview: string) {
    this._portfolio.lastPeriodReview = lastPeriodReview;
    return this;
  }
  createdBy(createdBy: BaseUser) {
    this._portfolio.createdBy = createdBy;
    return this;
  }
  frequencyPeriodicReview(frequencyPeriodicReview: number) {
    this._portfolio.frequencyPeriodicReview = frequencyPeriodicReview;
    return this;
  }
  comment(comment: string) {
    this._portfolio.comment = comment;
    return this;
  }
  businessProcesses(businessProcesses: string) {
    this._portfolio.businessProcesses = businessProcesses;
    return this;
  }
  dataType(dataType: string) {
    this._portfolio.dataType = dataType;
    return this;
  }
  businessUnitUsage(businessUnitUsage: string) {
    this._portfolio.businessUnitUsage = businessUnitUsage;
    return this;
  }
  users(users: string) {
    this._portfolio.users = users;
    return this;
  }
  usersGeography(usersGeography: string) {
    this._portfolio.usersGeography = usersGeography;
    return this;
  }
  numberOfUsers(numberOfUsers: CommonObject | null) {
    this._portfolio.numberOfUsers = numberOfUsers;
    return this;
  }
  licensedUsers(licensedUsers: string) {
    this._portfolio.licensedUsers = licensedUsers;
    return this;
  }
  frequencyUsed(frequencyUsed: CommonObject | null) {
    this._portfolio.frequencyUsed = frequencyUsed;
    return this;
  }
  location(location: string) {
    this._portfolio.location = location;
    return this;
  }
  expectedLifeTime(expectedLifeTime: string) {
    this._portfolio.expectedLifeTime = expectedLifeTime;
    return this;
  }
  classification(classification: Classification) {
    this._portfolio.classification = classification;
    return this;
  }
  patientSafety(patientSafety: string) {
    this._portfolio.patientSafety = patientSafety;
    return this;
  }
  productQuality(productQuality: string) {
    this._portfolio.productQuality = productQuality;
    return this;
  }
  dataIntegrity(dataIntegrity: string) {
    this._portfolio.dataIntegrity = dataIntegrity;
    return this;
  }
  complexity(complexity: string) {
    this._portfolio.complexity = complexity;
    return this;
  }
  overallRiskPriority(overallRiskPriority: string) {
    this._portfolio.overallRiskPriority = overallRiskPriority;
    return this;
  }
  gamp5Category(gamp5Category: string) {
    this._portfolio.gamp5Category = gamp5Category;
    return this;
  }
  confidentiality(confidentiality: string) {
    this._portfolio.confidentiality = confidentiality;
    return this;
  }
  integrity(integrity: string) {
    this._portfolio.integrity = integrity;
    return this;
  }
  availability(availability: string) {
    this._portfolio.availability = availability;
    return this;
  }
  reviewStatus(reviewStatus: PortfolioReviewStatus | null) {
    this._portfolio.reviewStatus = reviewStatus;
    return this;
  }
  status(status: PortfolioStatusType) {
    this._portfolio.status = status;
    return this;
  }
  lastStatus(lastStatus?: PortfolioStatusType) {
    this._portfolio.lastStatus = lastStatus;
    return this;
  }
}

export default PortfolioBuilder;
