import React from 'react';

import { useMedia } from 'react-use';

import FiltersModal from './modal';
import UserOrganizationsFilter from './organizationFilter';
import UserRolesFilter from './roleFilter';
import StatusFilters from './statusFilter';
import { ClearFiltersButton, ListingActions } from '../components';

const ListingFilters = () => {
  const isMobile = useMedia('(max-width: 768px)');

  return (
    <div className="flex flex-row justify-between items-center">
      <div className="flex flex-row gap-2">
        {!isMobile && (
          <div className="flex flex-row gap-2">
            <StatusFilters />
            <UserRolesFilter />
            <UserOrganizationsFilter />
            <ClearFiltersButton />
          </div>
        )}
        {isMobile && <FiltersModal />}
      </div>
      <ListingActions />
    </div>
  );
};

export default ListingFilters;
