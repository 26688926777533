import React, { useContext } from 'react';

import { Form, List, Tag, Typography } from 'antd';
import { Trans } from 'react-i18next';
import { generatePath, Link } from 'react-router-dom';

import { UserAvatar } from 'common-ui/links';
import { CommonObjectOption, DealBaseOption } from 'core/lib';
import { DEALBASE_STATUS_KEYS } from 'core/lib/constants/statuses/dealbase';
import colors from 'core/lib/theme/colors';
import { ContactBookDetailsContext } from 'modules/contactBook/context';
import { contactBookFormConfig } from 'modules/contactBook/form/formConfig';
import { Routes } from 'routes/routes';

const { Text } = Typography;

const {
  details: { professionalInformation },
} = contactBookFormConfig;

type ProfessionalInformationVentureCapitalProps = {
  editMode: boolean;
};

const ProfessionalInformationVentureCapital = ({ editMode }: ProfessionalInformationVentureCapitalProps) => {
  const contactBook = useContext(ContactBookDetailsContext);

  return (
    <div className="flex flex-col">
      <Text className="my-4" style={{ color: colors.Primary.rgba }}>
        <Trans i18nKey="contactBook:types.ventureCapital" />
      </Text>
      <div className="flex sm:flex-row flex-col gap-x-4">
        <Form.Item
          className="md:w-3/6"
          label={professionalInformation.stage.label}
          name={professionalInformation.stage.dataIndex}
          rules={professionalInformation.stage.rules}
          valuePropName="selectedOption"
          normalize={(option?: CommonObjectOption) => {
            return option ? { id: option.id, name: option.label } : null;
          }}
        >
          <professionalInformation.stage.component disabled={!editMode} />
        </Form.Item>
        <Form.Item
          className="md:w-3/6"
          label={professionalInformation.investmentFocus.label}
          name={professionalInformation.investmentFocus.dataIndex}
          rules={professionalInformation.investmentFocus.rules}
          valuePropName="selectedOption"
          normalize={(options: CommonObjectOption[]) => {
            return options.map((opt) => ({ id: opt.id, name: opt.label }));
          }}
        >
          <professionalInformation.investmentFocus.component disabled={!editMode} />
        </Form.Item>
      </div>
      <div className="flex sm:flex-row flex-col gap-x-4">
        <Form.Item
          className="md:w-3/6"
          label={professionalInformation.investmentTicket.label}
          name={professionalInformation.investmentTicket.dataIndex}
          rules={professionalInformation.investmentTicket.rules}
        >
          <professionalInformation.investmentTicket.component
            addonBefore="€"
            addonAfter={<Trans i18nKey="global:placeholders.millionsAbbr" />}
            dataIndex={professionalInformation.investmentTicket.dataIndex}
            disabled={!editMode}
          />
        </Form.Item>
        <Form.Item
          className="md:w-3/6"
          label={professionalInformation.fundSize.label}
          name={professionalInformation.fundSize.dataIndex}
          rules={professionalInformation.fundSize.rules}
        >
          <professionalInformation.fundSize.component
            addonBefore="€"
            addonAfter={<Trans i18nKey="global:placeholders.millionsAbbr" />}
            dataIndex={professionalInformation.fundSize.dataIndex}
            disabled={!editMode}
          />
        </Form.Item>
      </div>
      <div className="flex sm:flex-row flex-col gap-x-4">
        <Form.Item
          className="md:w-3/6"
          label={professionalInformation.investmentGeography.label}
          name={professionalInformation.investmentGeography.dataIndex}
          rules={professionalInformation.investmentGeography.rules}
        >
          <professionalInformation.investmentGeography.component disabled={!editMode} />
        </Form.Item>
        {editMode && (
          <Form.Item
            className="md:w-3/6"
            label={professionalInformation.sharedCases.label}
            name={professionalInformation.sharedCases.dataIndex}
            rules={professionalInformation.sharedCases.rules}
            valuePropName="selectedOption"
            normalize={(options: DealBaseOption[]) => {
              return options.map((opt) => ({ id: opt.id, name: opt.label }));
            }}
          >
            <professionalInformation.sharedCases.component disabled={!editMode} />
          </Form.Item>
        )}
      </div>
      {!editMode && (
        <div>
          <List
            size="large"
            header={professionalInformation.sharedCases.label}
            dataSource={contactBook?.sharedCases}
            renderItem={(sharedCase) => (
              <List.Item
                extra={
                  <div className="flex items-center gap-2">
                    <div className="flex">
                      <Tag className="block" color="blue">
                        <Trans i18nKey={`dealbase:status.label.${DEALBASE_STATUS_KEYS[sharedCase.status]}`} />
                      </Tag>
                    </div>
                    <div>{sharedCase.assigned && <UserAvatar iconOnly user={sharedCase.assigned} />}</div>
                  </div>
                }
              >
                <Text className="flex flex-row gap-2">
                  {`#${sharedCase.id}`}
                  <Link to={generatePath(Routes.DEAL_BASE_DETAILS.path, { id: sharedCase.id })}>{sharedCase.name}</Link>
                </Text>
              </List.Item>
            )}
          />
        </div>
      )}
    </div>
  );
};

export default ProfessionalInformationVentureCapital;
