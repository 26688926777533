import React from 'react';

import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

import { clearUserFilters, useAppDispatch } from 'core/lib';

const ClearFiltersButton = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const onClear = () => {
    dispatch(clearUserFilters());
  };

  return (
    <Button onClick={onClear} type="text">
      {t('actions:global.clearAll')}
    </Button>
  );
};

export default ClearFiltersButton;
