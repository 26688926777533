import React from 'react';

import { Skeleton, Tag, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';
import { ListChildComponentProps } from 'react-window';

import { MenuItemWrapper } from 'common-ui/leftMenu';
import { PortfolioMenu } from 'core/lib';
import { PORTFOLIO_REVIEW_STATUS_COLORS } from 'core/lib/constants/portfolio';
import { PORTFOLIO_STATUS_KEYS } from 'core/lib/constants/statuses/portfolio';
import { Routes } from 'routes/routes';

const { Text } = Typography;

type PortfolioMenuItemProps = {
  id?: string;
  isLoading: boolean;
};

type PortfolioMenuItemConnectedProps = ListChildComponentProps<PortfolioMenu[]> & PortfolioMenuItemProps;

const PortfolioMenuItem = ({ index, data, style, id }: PortfolioMenuItemConnectedProps) => {
  const { t } = useTranslation('portfolio');

  const item = data?.[index];
  const isActive = item?.id === parseInt(id ?? '');
  const color = item.reviewStatus ? PORTFOLIO_REVIEW_STATUS_COLORS[item.reviewStatus] : 'blue';
  return (
    <Skeleton active loading={!item}>
      {item && (
        <MenuItemWrapper
          isActive={isActive}
          style={style}
          path={generatePath(Routes.PORTFOLIO_DETAILS.path, { id: item.id })}
        >
          <Text className="text-inherit" ellipsis>
            {item?.name}
          </Text>
          <Tag color={color}>{t(`status.label.${PORTFOLIO_STATUS_KEYS[item.status]}`)}</Tag>
        </MenuItemWrapper>
      )}
    </Skeleton>
  );
};

export default PortfolioMenuItem;
