import React, { useEffect } from 'react';

import { Route, Switch, Redirect, generatePath } from 'react-router-dom';

import { selectUserPreferenceMenuDefaultType, setActiveModule, useAppDispatch, useAppSelector } from 'core/lib';
import { OBJECT_TYPES, SYSTEM_ROLES } from 'core/lib/constants';
import { Routes } from 'routes/routes';
import { getModulePath } from 'utils';
import ModuleControl from 'utils/moduleControl';
import PermissionControl, { PermissionRules } from 'utils/permissionControl';

import { ListingContainer } from './containers';
import PortfolioDetailsComponent from './details';
import PortfolioNew from './new';

const Portfolio = () => {
  const userPreferenceMenuDefaultType = useAppSelector(selectUserPreferenceMenuDefaultType);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setActiveModule(OBJECT_TYPES.PORTFOLIO));
    return () => {
      dispatch(setActiveModule(null));
    };
  }, [dispatch]);

  const redirectPath =
    getModulePath(OBJECT_TYPES.PORTFOLIO, userPreferenceMenuDefaultType) ?? generatePath(Routes.PORTFOLIO_LISTING.path);

  return (
    <ModuleControl redirect module={OBJECT_TYPES.PORTFOLIO}>
      <Switch>
        <Route path={Routes.PORTFOLIO.path} exact render={() => <Redirect to={redirectPath} />} />
        <Route path={Routes.PORTFOLIO_DETAILS.path} component={PortfolioDetailsComponent} />
        <Route
          path={Routes.PORTFOLIO_NEW.path}
          render={() => (
            <PermissionControl
              rule={PermissionRules.ANY}
              roles={[SYSTEM_ROLES.CLIENT_ADMIN, SYSTEM_ROLES.SUPER_ADMIN, SYSTEM_ROLES.SYSTEM_OWNER]}
              render={(restricted) =>
                restricted ? <Redirect to={generatePath(Routes.PORTFOLIO_LISTING.path)} /> : <PortfolioNew />
              }
            />
          )}
        />
        <ListingContainer />
      </Switch>
    </ModuleControl>
  );
};

export default Portfolio;
