import React, { useEffect, useMemo } from 'react';

import { EllipsisOutlined } from '@ant-design/icons';
import { Button, Dropdown, message, Skeleton, Typography } from 'antd';
import { ListItemProps } from 'antd/lib/list';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { pick } from 'lodash';
import { useTranslation } from 'react-i18next';

import { DROPDOWN_TRIGGERS, ModuleObjectsPopover } from 'common-ui';
import { SortableItem } from 'common-ui/sortable';
import { CommonObject, commonObjectsApi } from 'core/lib';
import { OBJECT_TYPES } from 'core/lib/constants';

const { Text } = Typography;

const connectedKey = {
  [OBJECT_TYPES.DEAL_BASE]: 'deal',
  [OBJECT_TYPES.PORTFOLIO]: 'portfolio',
  [OBJECT_TYPES.CONTACT_BOOK]: 'contact',
};

type ViewListItemProps = {
  item: CommonObject;
  objectType: number;
  loading?: boolean;
  handleLoader?: boolean;
  handle?: boolean;
  onEdit: (id: number) => void;
};

const ViewListItem = ({
  item,
  loading,
  objectType,
  handleLoader,
  handle,
  onEdit,
  ...listItemProps
}: ViewListItemProps & ListItemProps) => {
  const { t } = useTranslation();

  const [getConnectedObjects, { data }] = commonObjectsApi.useLazyGetCommonObjectConnectedObjectsQuery();

  const [deleteCommonObject, { isError: isDeleteError, isLoading: isDeleting }] =
    commonObjectsApi.useDeleteCommonObjectMutation();
  const [archiveCommonObject, { isLoading: isArchiving }] = commonObjectsApi.useArchiveCommonObjectMutation();

  const getData = (visible: boolean) => {
    if (visible) {
      getConnectedObjects(item.id, true);
    }
  };

  useEffect(() => {
    if (isDeleteError) {
      message.error(t('actions:message.deleteDeny', { object: item.type }), 3);
    }
  }, [isDeleteError, t, item.type]);

  const onEditClick = () => {
    onEdit(item.id);
  };

  const onDeleteClick = () => {
    deleteCommonObject(pick(item, ['id', 'type', 'objectType']));
  };

  const onArchiveClick = () => {
    archiveCommonObject(pick(item, ['id', 'type', 'objectType']));
  };

  const isLoading = () => {
    return loading || isArchiving || isDeleting;
  };

  const items: ItemType[] = useMemo(() => {
    return [
      {
        key: 'edit-common-object',
        label: t('actions:global.edit'),
        onClick: onEditClick,
      },
      {
        key: 'archive-common-object',
        label: item.archived ? t('actions:global.unarchive') : t('actions:global.archive'),
        onClick: onArchiveClick,
      },
      { type: 'divider' },
      {
        key: 'delete-common-object',
        danger: true,
        className: 'warning',
        label: t('actions:global.delete'),
        onClick: onDeleteClick,
      },
    ];
  }, [item.archived, t]); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <SortableItem
      {...listItemProps}
      id={item.id}
      data={item}
      handle={handle}
      handleLoader={handleLoader}
      className={`flex flex-nowrap items-center ${item.archived ? 'text-gray-400' : ''}`}
      extra={
        <div className="flex flex-auto gap-4 items-center justify-end">
          <div className={`flex shrink-0 ${item.connected ? 'text-primary' : 'text-gray-400'}`}>
            {item.connected ? (
              <ModuleObjectsPopover
                objectType={objectType}
                data={data}
                text={t(`global:results.${connectedKey[objectType]}`, { count: item.connected })}
                trigger="click"
                onOpenChange={getData}
              />
            ) : (
              t(`global:results.${connectedKey[objectType]}`, { count: item.connected })
            )}
          </div>
          <Dropdown trigger={DROPDOWN_TRIGGERS} menu={{ items, className: 'min-w-32' }}>
            <Button size="small" type="text" icon={<EllipsisOutlined />} />
          </Dropdown>
        </div>
      }
    >
      {isLoading() ? (
        <Skeleton.Input className="flex w-9/12" active={true} size="small" />
      ) : (
        <div className="truncate">
          {item.name}
          {item.archived && (
            <Text type="secondary" italic>
              {` | ${t('global:status.archived')}`}
            </Text>
          )}
        </div>
      )}
    </SortableItem>
  );
};

export default ViewListItem;
