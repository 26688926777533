import React from 'react';

import { Link } from 'react-router-dom';
import { useMedia } from 'react-use';

import { Routes } from 'routes/routes';

import { ModuleSearch } from './globalSearch';
import MainLogo from './mainLogo';
import UserTools from './userTools';

const TopBar = () => {
  const isMobile = useMedia('(max-width: 768px)');
  return (
    <div className="flex justify-between items-center px-3 py-1.5 gap-2.5">
      <div className="w-auto md:w-2/6 lg:w-1/4 flex justify-start">
        <Link to={Routes.WELCOME.path}>
          <MainLogo />
        </Link>
      </div>
      {!isMobile && (
        <div className="w-auto md:w-2/6 lg:w-1/4">
          <ModuleSearch />
        </div>
      )}
      <div className="w-auto md:w-2/6 lg:w-1/4">
        <UserTools />
      </div>
    </div>
  );
};

export default TopBar;
