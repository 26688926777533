export const DEFAULT_CURRENCY = 'EUR';

export const DEFAULT_DATE_FORMAT = 'dd-MMM-yyyy';

export const DEFAULT_DATETIME_FORMAT = 'dd-MMM-yyyy HH:mm:ss';

export const DEFAULT_TIME_FORMAT = 'HH:mm:ss';

export const DEFAULT_TIME_HOUR_MINUTES = 'HH:mm';

export const DEFAULT_TIME_FORMAT_DAYPART = 'HH:mm a';

export const MAX_INT = 999999999;

export const MAX_BIGINT = 999999999999999999;

export type MAX_INT_TYPE = typeof MAX_INT | typeof MAX_BIGINT;
