import { UserNotificationsResponse, UserNotification, NotificationsInfo, NotificationsInfoResponse } from '../entities';

export const deserializeNotificationsListing = (response: UserNotificationsResponse[]): UserNotification[] => {
  return response.map((res) => ({
    id: res.ID,
    type: res.Type,
    action: res.Action,
    visited: res.Visited,
    dateCreated: res.DateCreated,
    objectId: res.ObjectID,
    objectName: res.ObjectName ?? res.Metadata.ObjectName,
    objectType: res.ObjectType,
    refId: res.RefID,
    metadata: res.Metadata,
  }));
};

export const deserializeNotificationsInfo = (response: NotificationsInfoResponse): NotificationsInfo => {
  return { total: response.Total, unseen: response.Unseen };
};
