import { RuleObject } from 'antd/lib/form';
import { StoreValue } from 'antd/lib/form/interface';
import { isNil } from 'lodash';

import { FinancialYear, DealBaseFounder, Scorecard } from 'core/lib';
import { MAX_BIGINT } from 'core/lib/constants';

import { validateURL } from './general';

const validateFinancialYear = (value?: FinancialYear[]) => {
  return (
    !value || value.every((v) => v && v.year && !isNil(v.amount) && v.amount >= -MAX_BIGINT && v.amount <= MAX_BIGINT)
  );
};

const validateScorecard = (value?: Scorecard[]) => {
  return (
    !value ||
    value.every(
      (v) =>
        v &&
        v.user &&
        !isNil(v.fit) &&
        !isNil(v.greatness) &&
        !isNil(v.scalability) &&
        !isNil(v.traction) &&
        !isNil(v.uniqueness)
    )
  );
};

export const ebitdaFinancialValidator = (rule: RuleObject, value: StoreValue) => {
  if (validateFinancialYear(value)) {
    return Promise.resolve();
  }
  return Promise.reject(rule.message as string);
};

export const revenueFinancialValidator = (rule: RuleObject, value: StoreValue) => {
  if (validateFinancialYear(value)) {
    return Promise.resolve();
  }
  return Promise.reject(rule.message as string);
};

const validateFounders = (value: Partial<DealBaseFounder>[]) => {
  return value.every((v) => v && v.fullname && v.jobTitle && (!v.linkedin || validateURL(v.linkedin)));
};

export const founderValidator = (rule: RuleObject, value: StoreValue) => {
  if (validateFounders(value)) {
    return Promise.resolve();
  }
  return Promise.reject(rule.message as string);
};

export const scorecardValidator = (rule: RuleObject, value: StoreValue) => {
  if (validateScorecard(value)) {
    return Promise.resolve();
  }
  return Promise.reject(rule.message as string);
};
