import React from 'react';

import { Select, SelectProps, Tag } from 'antd';

import { contactBookApi } from 'core/lib';

const ContactBookTagsSelect = (props: SelectProps<string[]>) => {
  const [getTagOptions, { data, isFetching }] = contactBookApi.useLazyGetContactBookTagsQuery({
    selectFromResult: ({ data, ...rest }) => {
      return {
        data: data?.map((tag) => ({ value: tag, label: tag })),
        ...rest,
      };
    },
  });

  const onDropdownVisibleChange = (open: boolean) => {
    if (open) {
      getTagOptions(undefined, true);
    }
  };

  const displayTags = props.disabled && props.value?.length;
  if (displayTags) {
    return (
      <div className="flex flex-wrap gap-1">
        {props.value?.map((item) => (
          <Tag className="m-0" key={item} color="orange">
            {item}
          </Tag>
        ))}
      </div>
    );
  }

  return (
    <Select
      allowClear
      mode="tags"
      loading={isFetching}
      onDropdownVisibleChange={onDropdownVisibleChange}
      options={data}
      {...props}
    />
  );
};

export default ContactBookTagsSelect;
