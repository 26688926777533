import { ColumnType } from 'antd/lib/table';
import { keyBy, merge, pick, sortBy } from 'lodash';

import { EditableListing } from 'common-ui/cells/types';
import { ListingConfig } from 'core/lib';

export function mergeColumnSettings<T>(
  columns: (ColumnType<T> & EditableListing<T>)[],
  settings:
    | {
        [key: string]: ListingConfig;
      }
    | undefined
) {
  const indexColumns = keyBy(columns, 'dataIndex');
  const indexedListingConfig = pick(settings, Object.keys(indexColumns));
  const listingColumns = Object.values(merge(indexColumns, indexedListingConfig));
  return sortBy(listingColumns, 'index');
}
