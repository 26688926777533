import React, { useEffect, useMemo, useState } from 'react';

import { NumberOutlined } from '@ant-design/icons';
import { Button, Divider, Modal, Popconfirm, Tabs, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { generatePath, Link, useHistory } from 'react-router-dom';

import { UserAvatar, UserAvatarGroup } from 'common-ui';
import { conversationsApi, ModuleConversationDetails } from 'core/lib';
import { MODULE_NAMES } from 'core/lib/constants';
import { Routes } from 'routes/routes';
import { getRouteByObjectTypeAndRouteType, generateRoutePathByObjectTypeAndObjectId } from 'utils';
import { useLoggedInUser } from 'utils/hooks';

import ModalConversationMembers from './modalModuleMembers';

const { Text } = Typography;

const MODAL_TABS = {
  ABOUT: 'about',
  MEMBERS: 'members',
};

type ModuleConversationDetailsHeaderProps = {
  conversationDetails: ModuleConversationDetails;
  trigger?: JSX.Element;
  hideAvatarGroup?: boolean;
  withDivider?: boolean;
};

const ModuleConversationDetailsHeader = ({
  conversationDetails,
  hideAvatarGroup,
  trigger,
  withDivider = false,
}: ModuleConversationDetailsHeaderProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [modalOpen, setModalOpen] = useState(false);
  const [activeTabKey, setActiveTabKey] = useState(MODAL_TABS.ABOUT);

  useEffect(() => {
    setActiveTabKey(MODAL_TABS.ABOUT);
  }, [modalOpen]);

  const currentUser = useLoggedInUser();

  const [leaveConversation, { isLoading: isLeaveConversationLoading }] =
    conversationsApi.useLeaveModuleConversationMutation();

  const [joinConversation, { isLoading: isJoinConversationLoading }] =
    conversationsApi.useJoinModuleConversationMutation();

  const onClick = () => {
    setModalOpen(!modalOpen);
  };

  const onCancel = () => {
    setModalOpen(false);
  };

  const onConfirmLeaveConversation = () => {
    leaveConversation(conversationDetails.id)
      .unwrap()
      .then(() => {
        history.push(generatePath(Routes.CONVERSATIONS_LISTING.path));
      });
  };

  const onJoinConversationClick = () => {
    joinConversation(conversationDetails.id);
    setModalOpen(false);
  };

  const participantsIds = useMemo(
    () => conversationDetails.participants.map((user) => user.id),
    [conversationDetails.participants]
  );

  const isParticipant = useMemo(
    () => currentUser?.id && participantsIds.includes(currentUser.id),
    [participantsIds, currentUser?.id]
  );

  const objectUrl = useMemo(() => {
    if (conversationDetails.objectId) {
      const path = generateRoutePathByObjectTypeAndObjectId(
        conversationDetails.objectType,
        conversationDetails.objectId
      );
      if (path) {
        return path;
      }
    }
    return getRouteByObjectTypeAndRouteType(conversationDetails.objectType);
  }, [conversationDetails.objectType, conversationDetails.objectId]);

  const getUrl = () => {
    return `${window.origin}${objectUrl}`;
  };

  const title = useMemo(
    () => conversationDetails.objectTitle ?? MODULE_NAMES[conversationDetails.objectType],
    [conversationDetails.objectTitle, conversationDetails.objectType]
  );

  const modalTrigger = useMemo(
    () =>
      trigger ? (
        React.cloneElement(trigger, { onClick })
      ) : (
        <Button icon={<NumberOutlined />} type="text" onClick={onClick}>
          {title}
        </Button>
      ),
    [title, trigger] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <>
      <div className="flex flex-col gap-2">
        <div className="flex justify-between">
          {modalTrigger}
          {!hideAvatarGroup && <UserAvatarGroup users={conversationDetails?.participants} />}
        </div>
        {withDivider && <Divider className="m-0" />}
      </div>
      <Modal
        destroyOnClose
        title={title}
        open={modalOpen}
        onCancel={onCancel}
        footer={null}
        bodyStyle={{ paddingTop: 0 }}
        className="hide-modal-title-border"
      >
        <Tabs
          activeKey={activeTabKey}
          onChange={setActiveTabKey}
          size="small"
          type="line"
          items={[
            {
              key: MODAL_TABS.ABOUT,
              label: t('conversations:modal.tabs.about'),
              children: (
                <div className="flex flex-col gap-4">
                  <div className="flex flex-col gap-2">
                    <label>{t('conversations:modal.general.linkedTo')}</label>
                    <div className="flex items-center gap-2">
                      <Text copyable={{ text: getUrl() }}>
                        <Link to={objectUrl!}>{title}</Link>
                      </Text>
                    </div>
                  </div>
                  <div className="flex flex-col gap-2">
                    <label>{t('conversations:modal.general.createdBy')}</label>
                    <div className="flex items-center gap-2">
                      <UserAvatar user={conversationDetails.createdBy} />
                    </div>
                  </div>
                  {isParticipant ? (
                    <Popconfirm
                      overlayClassName="max-w-80"
                      title={t('conversations:modal.info.leaveConversation')}
                      okText={t('conversations:actions.leave')}
                      onConfirm={onConfirmLeaveConversation}
                      okButtonProps={{ danger: true }}
                    >
                      <Button danger type="primary" loading={isLeaveConversationLoading}>
                        {t('conversations:actions.leaveConversation')}
                      </Button>
                    </Popconfirm>
                  ) : (
                    <Button loading={isJoinConversationLoading} onClick={onJoinConversationClick} type="primary">
                      {t('conversations:actions.joinConversation')}
                    </Button>
                  )}
                </div>
              ),
            },
            {
              key: MODAL_TABS.MEMBERS,
              label: t('conversations:modal.tabs.members'),
              children: (
                <ModalConversationMembers
                  createdById={conversationDetails.createdBy.id}
                  participants={conversationDetails.participants}
                  conversationDetails={conversationDetails}
                />
              ),
            },
          ]}
        />
      </Modal>
    </>
  );
};

export default ModuleConversationDetailsHeader;
