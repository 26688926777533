import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Button, Switch, Table, Tooltip, Typography } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';

import { ContentContainer, MainContentContainer } from 'common-ui/containers';
import { DetailsSectionWrapper } from 'common-ui/wrappers';
import { UserNotificationMedium, UserNotificationPreferences, userPreferencesApi } from 'core/lib';
import LoaderWrapper from 'utils/loader-wrapper';

const { Title } = Typography;

// todo extract table and eventType/mediums to individual component to allow scalability
const NotificationsTab = () => {
  const { t } = useTranslation();
  const [formDirty, setFormDirty] = useState(false);
  const [baseColumns] = useState<ColumnType<UserNotificationPreferences>[]>([
    {
      title: t('notifications:eventType'),
      dataIndex: 'eventType',
      key: 'eventType',
      render: (value) => {
        return (
          <Tooltip placement="topLeft" title={t(`notifications:events.${value}`)}>
            {t(`notifications:events.${value}`)}
          </Tooltip>
        );
      },
    },
  ]);
  const [draftPreferences, setDraftPreferences] = useState<UserNotificationPreferences[] | undefined>();
  const [editMode, setEditMode] = useState(false);

  const { data, isLoading: fetchingPreferences } = userPreferencesApi.useGetUserNotificationPreferencesQuery();
  const [updateUserPreferences, { isLoading: isSaving }] =
    userPreferencesApi.useUpdateUserNotificationPreferencesMutation();

  const onEditClick = () => {
    setEditMode(true);
  };

  const onCancelClick = () => {
    setDraftPreferences(cloneDeep(data));
    setEditMode(false);
    setFormDirty(false);
  };

  const onSaveClick = () => {
    if (draftPreferences) {
      updateUserPreferences(draftPreferences);
    }
  };

  useEffect(() => {
    if (!isSaving) {
      setEditMode(false);
    }
  }, [isSaving]);

  useEffect(() => {
    setDraftPreferences(cloneDeep(data));
  }, [data]);

  const mediumTypes = useMemo(() => {
    return draftPreferences?.[0].mediums.map((medium) => medium.type);
  }, [draftPreferences]);

  const onMediumToggle = useCallback(
    (active: boolean, eventType: string, group: string, mediumType: string) => {
      if (draftPreferences) {
        setFormDirty(true);
        const updatedPreferences = draftPreferences.map((pref) => {
          if (pref.eventType === eventType && pref.group === group) {
            pref.mediums = pref.mediums.map((med) => {
              if (med.type === mediumType) {
                med.active = active;
              }
              return med;
            });
          }
          return pref;
        });

        setDraftPreferences(updatedPreferences);
      }
    },
    [draftPreferences]
  );

  const columns = useMemo(() => {
    const mediumColumns =
      mediumTypes?.map((medium, mediumIndex) => ({
        title: t(`notifications:mediums.${medium}`),
        width: 200,
        dataIndex: ['mediums', mediumIndex],
        key: medium,
        render: (medium: UserNotificationMedium, preference: UserNotificationPreferences) => {
          return (
            <Switch
              onChange={(active) => onMediumToggle(active, preference.eventType, preference.group, medium.type)}
              checked={medium.active}
              defaultChecked={medium.active}
              disabled={!editMode}
            />
          );
        },
      })) ?? [];

    return baseColumns.concat(mediumColumns);
  }, [mediumTypes, editMode, baseColumns, t, onMediumToggle]);

  const modulePreference = useMemo(() => draftPreferences?.filter((dp) => dp.group === 'Module'), [draftPreferences]);
  const commentsPreference = useMemo(
    () => draftPreferences?.filter((dp) => dp.group === 'Comment'),
    [draftPreferences]
  );
  const conversationPreferences = useMemo(
    () => draftPreferences?.filter((dp) => dp.group === 'Conversation'),
    [draftPreferences]
  );

  return (
    <LoaderWrapper loading={fetchingPreferences || isSaving}>
      <MainContentContainer id="profile-settings-notifications">
        <ContentContainer>
          <DetailsSectionWrapper>
            <div className="flex flex-col gap-4">
              <div className="flex w-full justify-end">
                {!editMode ? (
                  <Button onClick={onEditClick}>{t('actions:global.edit')}</Button>
                ) : (
                  <div className="flex gap-4">
                    <Button type="primary" loading={isSaving} onClick={onSaveClick} disabled={!formDirty}>
                      {t('actions:global.save')}
                    </Button>
                    <Button loading={isSaving} onClick={onCancelClick}>
                      {t('actions:global.cancel')}
                    </Button>
                  </div>
                )}
              </div>
              <Table
                title={() => <Title level={5}>{t('notifications:groups.module')}</Title>}
                rowKey="eventType"
                pagination={false}
                columns={columns}
                size="small"
                dataSource={modulePreference}
              />
              <Table
                title={() => <Title level={5}>{t('notifications:groups.comments')}</Title>}
                rowKey="eventType"
                pagination={false}
                columns={columns}
                size="small"
                dataSource={commentsPreference}
              />
              <Table
                title={() => <Title level={5}>{t('notifications:groups.myConversations')}</Title>}
                rowKey="eventType"
                pagination={false}
                columns={columns}
                size="small"
                dataSource={conversationPreferences}
              />
            </div>
          </DetailsSectionWrapper>
        </ContentContainer>
      </MainContentContainer>
    </LoaderWrapper>
  );
};

export default NotificationsTab;
