import React from 'react';

import { LockOutlined } from '@ant-design/icons';
import { Form, Input, InputProps, Tag, Tooltip, Typography } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { Trans } from 'react-i18next';
import { generatePath, Link } from 'react-router-dom';

import {
  ListingText,
  ListingSelect,
  ListingObjectMultiSelect,
  TagsGroup,
  SystemRoleTag,
  OrganizationTag,
  StatusTag,
  ListingUniqueUserEmail,
  UserAvatar,
} from 'common-ui';
import ListingOrganizationMultiSelect from 'common-ui/cells/listingOrganizationMultiSelect';
import { ListingEditableCellProps } from 'common-ui/cells/types';
import { ListingConfig, OrganizationShort } from 'core/lib';
import { CountryOptions, RolesOptions } from 'core/lib/constants/selects';
import { UserListing, UserRole } from 'core/lib/modules/users/entities';
import colors from 'core/lib/theme/colors';
import { numberSorter, textSorter } from 'core/lib/utils/sorters';
import { Routes } from 'routes/routes';

import { userFormConfig } from '../form/formConfig';

const { Text } = Typography;

const {
  generalInformation: { personalInformation, loginCredentials, userPrivileges },
} = userFormConfig;

export const columns = (
  [
    {
      title: <Trans i18nKey="user:listing.columns.id" />,
      dataIndex: 'id',
      width: 60,
      visible: false,
      sorter: (a, b, order) => numberSorter(a.id, b.id, order),
      ellipsis: {
        showTitle: false,
      },
      render: (id) => (
        <Tooltip placement="topLeft" title={id}>
          {`#${id}`}
        </Tooltip>
      ),
    },
    {
      title: <Trans i18nKey="user:listing.columns.name" />,
      dataIndex: 'name',
      width: 200,
      editable: true,
      visible: true,
      inputType: ({ dataIndex, rules, ...props }: ListingEditableCellProps & InputProps) => {
        return (
          <div>
            <Form.Item
              label={personalInformation.firstName.label}
              name={personalInformation.firstName.dataIndex}
              labelAlign="left"
              className="flex flex-col m-0"
              rules={personalInformation.firstName.rules}
            >
              <Input {...props} />
            </Form.Item>
            <Form.Item
              label={personalInformation.lastName.label}
              name={personalInformation.lastName.dataIndex}
              labelAlign="left"
              className="flex flex-col m-0"
              rules={personalInformation.lastName.rules}
            >
              <Input {...props} />
            </Form.Item>
          </div>
        );
      },
      inputProps: {
        maxLength: 40,
      },
      sorter: (a, b, order) => textSorter(a.name, b.name, order),
      ellipsis: {
        showTitle: false,
      },
      render: (name, record) => (
        <Tooltip placement="topLeft" title={name} className="flex items-center gap-2">
          <UserAvatar
            avatarContainerProps={{ className: 'inline-block' }}
            plain
            withLink={false}
            iconOnly
            user={record}
            active={record.status === 'active'}
          />
          <Link className="truncate" to={generatePath(Routes.USER_DETAILS.path, { id: record.id })}>
            {name}
          </Link>
          {record.locked && <LockOutlined style={{ color: colors.Primary.rgba }} />}
        </Tooltip>
      ),
    },
    {
      title: <Trans i18nKey="user:listing.columns.email" />,
      dataIndex: 'email',
      width: 200,
      editable: true,
      visible: true,
      inputType: ListingUniqueUserEmail,
      inputProps: {
        maxLength: 100,
      },
      inputRules: loginCredentials.email.rules,
      sorter: (a, b, order) => textSorter(a.email, b.email, order),
      ellipsis: {
        showTitle: false,
      },
      render: (email) => (
        <Tooltip placement="topLeft" title={email} className="flex items-center gap-2">
          <Text
            copyable={{
              text: email,
              tooltips: null,
            }}
          >
            <div className="truncate">{email}</div>
          </Text>
        </Tooltip>
      ),
    },
    {
      title: <Trans i18nKey="user:listing.columns.organizations" />,
      dataIndex: 'organizations',
      width: 100,
      visible: true,
      editable: true,
      inputType: ListingOrganizationMultiSelect,
      inputRules: userPrivileges.organizations.rules,
      inputProps: {
        asTags: true,
        color: 'blue',
        maxTagCount: 2,
      },
      ellipsis: {
        showTitle: false,
      },
      render: (organizations) =>
        organizations && (
          <TagsGroup>
            {organizations?.map((organization: OrganizationShort) => (
              <OrganizationTag key={organization.id} name={organization.name} />
            ))}
          </TagsGroup>
        ),
    },
    {
      title: <Trans i18nKey="user:listing.columns.status" />,
      dataIndex: 'status',
      width: 100,
      visible: false,
      editable: false,
      ellipsis: {
        showTitle: false,
      },
      render: (status) => (
        <Tooltip placement="topLeft" title={<Trans i18nKey={`user:status.label.${status}`} />}>
          <StatusTag status={<Trans i18nKey={`user:status.label.${status}`} />} />
        </Tooltip>
      ),
    },
    {
      title: <Trans i18nKey="user:listing.columns.roles" />,
      dataIndex: 'systemRoles',
      width: 100,
      visible: true,
      editable: true,
      inputType: ListingObjectMultiSelect,
      inputRules: userPrivileges.roles.rules,
      inputProps: {
        options: RolesOptions,
        maxTagCount: 2,
      },
      ellipsis: {
        showTitle: false,
      },
      render: (systemRoles) =>
        systemRoles && (
          <TagsGroup>
            {systemRoles?.map((systemRole: UserRole) => (
              <SystemRoleTag key={systemRole.id} systemRole={systemRole} />
            ))}
          </TagsGroup>
        ),
    },
    {
      title: <Trans i18nKey="user:listing.columns.country" />,
      dataIndex: 'country',
      width: 200,
      editable: true,
      visible: false,
      inputType: ListingSelect,
      inputProps: {
        options: CountryOptions,
        showSearch: true,
      },
      inputRules: personalInformation.country.rules,
      sorter: (a, b, order) => textSorter(a.country, b.country, order),
      ellipsis: {
        showTitle: false,
      },
      render: (country) => (
        <Tooltip placement="topLeft" title={country}>
          <Tag key={country} color="purple">
            {country}
          </Tag>
        </Tooltip>
      ),
    },
    {
      title: <Trans i18nKey="user:listing.columns.jobTitle" />,
      dataIndex: 'jobTitle',
      width: 200,
      editable: true,
      visible: false,
      inputType: ListingText,
      inputProps: {
        maxLength: 40,
      },
      inputRules: userPrivileges.jobTitle.rules,
      sorter: (a, b, order) => textSorter(a.jobTitle, b.jobTitle, order),
      ellipsis: {
        showTitle: false,
      },
      render: (jobTitle) => (
        <Tooltip placement="topLeft" title={jobTitle} className="flex items-center gap-2">
          {jobTitle}
        </Tooltip>
      ),
    },
    {
      title: <Trans i18nKey="user:listing.columns.number" />,
      dataIndex: 'phone',
      width: 200,
      editable: true,
      visible: false,
      inputType: ListingText,
      inputProps: {
        maxLength: 40,
      },
      inputRules: personalInformation.number.rules,
      ellipsis: {
        showTitle: false,
      },
      render: (number) => (
        <Tooltip placement="topLeft" title={number} className="flex items-center gap-2">
          {number}
        </Tooltip>
      ),
    },
  ] as (ColumnType<UserListing> & Partial<ListingConfig>)[]
).map((col, index) => {
  col.index = index;
  return col;
});
