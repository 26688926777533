import React, { useContext } from 'react';

import { Form } from 'antd';

import { CommonObjectOption, PortfolioDetails } from 'core/lib';
import { PORTFOLIO_STATUS } from 'core/lib/constants/statuses';

import { PortfolioDetailsContext } from '../../../context';
import { portfolioFormConfig } from '../../../form/formConfig';

const {
  generalInformation: { compliance },
} = portfolioFormConfig;

type ComplianceSectionProps = {
  editMode: boolean;
};

const PortfolioComplianceSection = ({ editMode }: ComplianceSectionProps) => {
  const portfolio = useContext(PortfolioDetailsContext) as PortfolioDetails;
  return (
    <div className="flex flex-col">
      <div className="flex sm:flex-row flex-col gap-x-4">
        <Form.Item
          className="md:w-3/6"
          label={compliance.complianceStatus.label}
          name={compliance.complianceStatus.dataIndex}
          rules={compliance.complianceStatus.rules}
          valuePropName="selectedOption"
          normalize={(option: CommonObjectOption) => {
            return option ? { id: option.id, name: option.label } : null;
          }}
        >
          <compliance.complianceStatus.component disabled={!editMode} />
        </Form.Item>

        <Form.Item
          className="md:w-3/6"
          label={compliance.initialValidationDate.label}
          name={compliance.initialValidationDate.dataIndex}
          rules={compliance.initialValidationDate.rules}
        >
          <compliance.initialValidationDate.component
            className="flex"
            disabled={!editMode || portfolio.status === PORTFOLIO_STATUS.OPERATIONS}
          />
        </Form.Item>
      </div>

      <div className="flex sm:flex-row flex-col gap-x-4">
        <Form.Item
          className="md:w-3/6"
          label={compliance.lastPeriodReview.label}
          name={compliance.lastPeriodReview.dataIndex}
          rules={compliance.lastPeriodReview.rules}
        >
          <compliance.lastPeriodReview.component className="flex" disabled={!editMode} />
        </Form.Item>
        <Form.Item
          className="md:w-3/6"
          label={compliance.frequencyPeriodicReview.label}
          name={compliance.frequencyPeriodicReview.dataIndex}
          rules={compliance.frequencyPeriodicReview.rules}
        >
          <compliance.frequencyPeriodicReview.component className="w-full" disabled={!editMode} />
        </Form.Item>
      </div>
      <div className="md:w-3/6">
        <Form.Item
          label={compliance.comment.label}
          name={compliance.comment.dataIndex}
          rules={compliance.comment.rules}
        >
          <compliance.comment.component disabled={!editMode} />
        </Form.Item>
      </div>
    </div>
  );
};

export default PortfolioComplianceSection;
