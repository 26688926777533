import React from 'react';

import { Form } from 'antd';

import { CommonObjectOption } from 'core/lib';

import { portfolioFormConfig } from '../../../form/formConfig';

const {
  productInformation: { intended },
} = portfolioFormConfig;

type ProductInformationSectionProps = {
  editMode: boolean;
};

const PortfolioIntendedInformationSection = ({ editMode }: ProductInformationSectionProps) => {
  return (
    <div className="flex flex-col">
      <div className="flex sm:flex-row flex-col gap-x-4">
        <Form.Item
          className="md:w-3/6"
          label={intended.businessProcesses.label}
          name={intended.businessProcesses.dataIndex}
          rules={intended.businessProcesses.rules}
        >
          <intended.businessProcesses.component disabled={!editMode} />
        </Form.Item>
        <Form.Item
          className="md:w-3/6"
          label={intended.dataType.label}
          name={intended.dataType.dataIndex}
          rules={intended.dataType.rules}
        >
          <intended.dataType.component disabled={!editMode} />
        </Form.Item>
      </div>
      <div className="flex sm:flex-row flex-col gap-x-4">
        <Form.Item
          className="md:w-3/6"
          label={intended.businessUnitUsage.label}
          name={intended.businessUnitUsage.dataIndex}
          rules={intended.businessUnitUsage.rules}
        >
          <intended.businessUnitUsage.component disabled={!editMode} />
        </Form.Item>
        <Form.Item
          className="md:w-3/6"
          label={intended.users.label}
          name={intended.users.dataIndex}
          rules={intended.users.rules}
        >
          <intended.users.component disabled={!editMode} />
        </Form.Item>
      </div>
      <div className="flex sm:flex-row flex-col gap-x-4">
        <Form.Item
          className="md:w-3/6"
          label={intended.usersGeography.label}
          name={intended.usersGeography.dataIndex}
          rules={intended.usersGeography.rules}
        >
          <intended.usersGeography.component disabled={!editMode} />
        </Form.Item>
        <Form.Item
          className="md:w-3/6"
          label={intended.licensedUsers.label}
          name={intended.licensedUsers.dataIndex}
          rules={intended.licensedUsers.rules}
        >
          <intended.licensedUsers.component disabled={!editMode} />
        </Form.Item>
      </div>
      <div className="flex sm:flex-row flex-col gap-x-4">
        <Form.Item
          className="md:w-3/6"
          label={intended.numberOfUsers.label}
          name={intended.numberOfUsers.dataIndex}
          rules={intended.numberOfUsers.rules}
          valuePropName="selectedOption"
          normalize={(option: CommonObjectOption) => {
            return option ? { id: option.id, name: option.label } : null;
          }}
        >
          <intended.numberOfUsers.component disabled={!editMode} />
        </Form.Item>
        <Form.Item
          className="md:w-3/6"
          label={intended.frequencyUsed.label}
          name={intended.frequencyUsed.dataIndex}
          rules={intended.frequencyUsed.rules}
          valuePropName="selectedOption"
          normalize={(option: CommonObjectOption) => {
            return option ? { id: option.id, name: option.label } : null;
          }}
        >
          <intended.frequencyUsed.component disabled={!editMode} />
        </Form.Item>
      </div>
      <div className="flex sm:flex-row flex-col gap-x-4">
        <Form.Item
          className="md:w-3/6"
          label={intended.location.label}
          name={intended.location.dataIndex}
          rules={intended.location.rules}
        >
          <intended.location.component disabled={!editMode} />
        </Form.Item>
        <Form.Item
          className="md:w-3/6"
          label={intended.expectedLifeTime.label}
          name={intended.expectedLifeTime.dataIndex}
          rules={intended.expectedLifeTime.rules}
        >
          <intended.expectedLifeTime.component className="" disabled={!editMode} />
        </Form.Item>
      </div>
    </div>
  );
};

export default PortfolioIntendedInformationSection;
