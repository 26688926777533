import React, { useState } from 'react';

import { FullscreenOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import { Document, Page } from 'react-pdf';
import AutoSizer from 'react-virtualized-auto-sizer';

type PdfModalViewerProps = {
  data?: string;
  numPages: number;
};

const PdfModalViewer = ({ data, numPages }: PdfModalViewerProps) => {
  const [modalVisible, setModalVisible] = useState(false);

  const showModal = () => {
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  return (
    <>
      <div>
        <Button type="text" onClick={showModal} icon={<FullscreenOutlined />} />
      </div>
      <Modal
        bodyStyle={{ padding: '3rem', height: '100vh', overflow: 'auto' }}
        className="w-full h-full top-0"
        onCancel={closeModal}
        open={modalVisible}
        footer={null}
        destroyOnClose
      >
        <AutoSizer style={{ width: 'auto' }} className="flex flex-auto" disableHeight>
          {({ width }) => (
            <div>
              <Document className="flex flex-col gap-2" file={data}>
                {Array.from({ length: numPages }).map((x, index) => (
                  <Page
                    key={index + 1}
                    className="flex items-center"
                    renderMode="canvas"
                    width={width}
                    pageNumber={index + 1}
                  />
                ))}
              </Document>
            </div>
          )}
        </AutoSizer>
      </Modal>
    </>
  );
};

export default PdfModalViewer;
