import React, { useMemo, useState } from 'react';

import { Input, List } from 'antd';
import { useTranslation } from 'react-i18next';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList, ListChildComponentProps } from 'react-window';

import { EmptyWrapper, UserAvatar } from 'common-ui';
import { BaseUser } from 'core/lib';
import { fuzzyWordsSearch } from 'core/lib/utils';
import LoaderWrapper from 'utils/loader-wrapper';

type ModalMembersListProps = {
  isLoading: boolean;
  users: BaseUser[];
  getActions: (user: BaseUser) => JSX.Element[];
};

const ModalMembersList = ({ isLoading, users, getActions }: ModalMembersListProps) => {
  const { t } = useTranslation();
  const [search, onSearch] = useState('');

  const filteredUsers = useMemo(() => users.filter((user) => fuzzyWordsSearch(search, user.name)), [users, search]);

  const userListItem = ({ index, data, style }: ListChildComponentProps) => {
    const user = data[index];
    if (user) {
      const actions = getActions(user);

      return (
        <List.Item key={user.id} style={style} actions={actions}>
          <UserAvatar user={user} />
        </List.Item>
      );
    }
    return null;
  };

  return (
    <div className="flex flex-col gap-4">
      <EmptyWrapper empty={!users?.length} description={t('global:placeholders.noMembers')}>
        <Input
          placeholder={t('global:placeholders.searchByName')}
          type="string"
          value={search}
          onChange={({ target }) => onSearch(target.value)}
        />
        <LoaderWrapper loading={isLoading}>
          <div className="min-h-64">
            <AutoSizer>
              {({ height, width }) => (
                <List split={false} bordered={false}>
                  <FixedSizeList
                    itemData={filteredUsers}
                    width={width}
                    height={height}
                    itemCount={filteredUsers.length}
                    itemSize={50}
                  >
                    {userListItem}
                  </FixedSizeList>
                </List>
              )}
            </AutoSizer>
          </div>
        </LoaderWrapper>
      </EmptyWrapper>
    </div>
  );
};

export default ModalMembersList;
