import React from 'react';

import { Select, SelectProps } from 'antd';

import { dealBaseApi, DealBaseShort } from 'core/lib';

import { mergeMultiSelectedWithOptions } from './formUtils';

export type DealBaseMultiSelectProps = {
  selectedOption?: DealBaseShort[];
};

const DealBaseMultiSelect = ({ selectedOption, ...props }: DealBaseMultiSelectProps & SelectProps<number>) => {
  const [getDealBaseOptions, { data, isFetching }] = dealBaseApi.useLazyGetDealBaseOptionsQuery({
    selectFromResult: ({ data, ...rest }) => {
      const res = mergeMultiSelectedWithOptions(data, selectedOption);
      return { data: res, ...rest };
    },
  });

  const onDropdownVisibleChange = (open: boolean) => {
    if (open) {
      getDealBaseOptions();
    }
  };

  const value = selectedOption?.map((so) => so.id) ?? [];

  return (
    <Select
      showSearch
      allowClear
      optionFilterProp="label"
      mode="multiple"
      loading={isFetching}
      value={value}
      options={data}
      onDropdownVisibleChange={onDropdownVisibleChange}
      {...props}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onChange={(_val, option: any) => {
        props.onChange?.(option, option);
      }}
    />
  );
};

export default DealBaseMultiSelect;
