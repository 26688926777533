import React, { useEffect, useState } from 'react';

import { transform } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory } from 'react-router-dom';

import { baseApi, contactBookApi, ContactBookDetails, detailsSectionPreferenceApi } from 'core/lib';
import { OBJECT_TYPES } from 'core/lib/constants';
import { Routes } from 'routes/routes';
import { useMatchParamId } from 'utils/hooks';
import LoaderWrapper from 'utils/loader-wrapper';

import { ContactBookDetailsPageHeader } from '../components';
import { ContactBookForm } from '../form';

const ContactBookDetailsComponent = () => {
  const { t } = useTranslation();
  const id = useMatchParamId(Routes.CONTACT_BOOK_DETAILS.path)!;
  const history = useHistory();

  const [editMode, setEditMode] = useState(false);
  const [activeTabKey, setActiveTabKey] = useState<string | null>();

  const {
    data,
    refetch,
    isFetching: fetchingContactBook,
    originalArgs,
  } = contactBookApi.useGetContactBookDetailsQuery(id);
  const { isLoading: loadingSectionHeaders } = detailsSectionPreferenceApi.useGetDetailsSectionHeadersQuery(
    OBJECT_TYPES.CONTACT_BOOK
  );

  const [postContactBook, { isLoading: isSaving, isSuccess: saveSuccess }] =
    contactBookApi.usePatchContactBookMutation();

  const onToggleEdit = () => {
    setEditMode(true);
  };

  const onCancel = () => {
    setEditMode(false);
  };

  useEffect(() => {
    if (saveSuccess) {
      setEditMode(false);
    }
  }, [saveSuccess]);

  useEffect(() => {
    if (originalArgs) {
      setEditMode(false);
      setActiveTabKey(null);
    }
  }, [originalArgs]);

  useEffect(() => {
    baseApi.util.resetApiState();
  }, [data?.id]);

  const onSave = (contactBook: ContactBookDetails) => {
    contactBook.id = parseInt(id);
    contactBook = transform(contactBook, (result, value, key) => {
      result[key] = value ?? null;
    });
    return postContactBook(contactBook).unwrap();
  };

  const onRefetch = () => {
    refetch();
  };

  const handlePageHeaderBackClick = () => {
    history.goBack();
  };

  return (
    <LoaderWrapper
      loading={fetchingContactBook || loadingSectionHeaders}
      message={t('loaders:contactBook.loadingDetails')}
    >
      {data ? (
        <>
          <ContactBookDetailsPageHeader onBack={handlePageHeaderBackClick} contactBook={data} />
          <ContactBookForm
            contactBook={data}
            onSave={onSave}
            onCancel={onCancel}
            onToggleEdit={onToggleEdit}
            onRefetch={onRefetch}
            setActiveTabKey={setActiveTabKey}
            activeTabKey={activeTabKey}
            editMode={editMode}
            isSaving={isSaving}
          />
        </>
      ) : (
        <Redirect to={Routes.PAGE_404.path} />
      )}
    </LoaderWrapper>
  );
};

export default ContactBookDetailsComponent;
