import React from 'react';

import Title from 'antd/lib/typography/Title';
import { Trans } from 'react-i18next';
import useMedia from 'react-use/lib/useMedia';

import { LoginImage } from 'assets';

interface LoginFormWrapperProps {
  children?: React.ReactNode;
}

const LoginFormWrapper = ({ children }: LoginFormWrapperProps) => {
  const isTablet = useMedia('(max-width: 1023px)');

  const renderTabletView = () => {
    return <div className="flex flex-col p-2 w-full min-w-300">{children}</div>;
  };

  const renderDesktopView = () => {
    return (
      <div className="flex flex-row flex-auto ">
        {children}
        <div className="flex flex-col relative w-1/2 bg-[#003C5B]">
          <img src={LoginImage} alt="LoginImage" className="object-cover h-full w-full opacity-30"></img>
          <Title className="absolute whitespace-pre bottom-8 left-8 m-0 text-white">
            <Trans i18nKey="account:title.loginTitle" components={{ normal: <span className="font-normal" /> }} />
          </Title>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="flex flex-row h-full">{!isTablet ? renderDesktopView() : renderTabletView()}</div>
    </>
  );
};

export default LoginFormWrapper;
