import React, { useMemo, useState } from 'react';

import { PlusOutlined, TeamOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, Select, Typography } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router-dom';

import { conversationsApi, CreateGroupConversationRequest, userApi } from 'core/lib';
import { Routes } from 'routes/routes';
import { rules } from 'utils/form/rules';
import { useLoggedInUser } from 'utils/hooks';

const { Text } = Typography;

const CreateGroupConversationModal = () => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const { t } = useTranslation();
  const [form] = useForm();
  const history = useHistory();

  const [getUsers, { data: userOptions = [], isLoading: loadingUsers }] = userApi.useLazyGetUserOptionsQuery();

  const currentUser = useLoggedInUser();

  const filteredUserOptions = useMemo(
    () => userOptions.filter((option) => option.value !== currentUser?.id),
    [currentUser, userOptions]
  );

  const [createConversation, { isLoading: isSaving }] = conversationsApi.useCreateGroupConversationMutation();

  const toggleCreateConversationModal = () => {
    setModalOpen(!modalOpen);
  };

  const onCancel = () => {
    setModalOpen(false);
    form.resetFields();
  };

  const onCreate = (groupConv: CreateGroupConversationRequest) => {
    createConversation(groupConv)
      .unwrap()
      .then((groupConvId) => {
        onCancel();
        history.push(generatePath(Routes.CONVERSATIONS_GROUP_DETAILS.path, { id: groupConvId }));
      });
  };

  const onUserDropdown = (open: boolean) => {
    if (open) {
      getUsers(undefined, true);
    }
  };

  return (
    <div>
      <Button icon={<PlusOutlined />} type="link" onClick={toggleCreateConversationModal}>
        {t('conversations:actions.createGroup')}
      </Button>
      <Modal
        title={t('conversations:modal.title.createGroup')}
        open={modalOpen}
        onCancel={onCancel}
        footer={
          <div>
            <Button onClick={onCancel} type="text">
              {t('actions:global.cancel')}
            </Button>
            <Button onClick={form.submit} loading={isSaving} type="primary">
              {t('actions:global.create')}
            </Button>
          </div>
        }
      >
        <div>
          <Form
            initialValues={{ title: null, users: [] }}
            form={form}
            layout="vertical"
            name="create_conversation"
            onFinish={onCreate}
          >
            <div>
              <Form.Item label={t('global:labels.title')} name="title" rules={[rules.required]}>
                <Input
                  prefix={<TeamOutlined />}
                  placeholder={t('conversations:modal.placeholders.exampleGroupTitle')}
                  maxLength={250}
                  type="text"
                />
              </Form.Item>
              <Form.Item label={t('global:labels.users')} name="users" rules={[rules.required]}>
                <Select
                  placeholder={t('global:placeholders.select')}
                  mode="multiple"
                  loading={loadingUsers}
                  onDropdownVisibleChange={onUserDropdown}
                  options={filteredUserOptions}
                />
              </Form.Item>
            </div>
          </Form>
          <Text type="secondary">{t('conversations:modal.info.groupUserInvitation')}</Text>
        </div>
      </Modal>
    </div>
  );
};

export default CreateGroupConversationModal;
