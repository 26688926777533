import React from 'react';

import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Divider, Form, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import theme from 'core/lib/theme';

import { externalFormConfig } from '../externalFormConfig';

const { metrics } = externalFormConfig;

const { Title } = Typography;

type FinancialsEbitdaSectionProps = {
  disabled: boolean;
};

const FinancialsEbitdaSection = ({ disabled }: FinancialsEbitdaSectionProps) => {
  const { t } = useTranslation(['dealbase', 'global']);

  return (
    <div>
      <Title className="my-2" level={5}>
        {t('dealbase:form.ebitda.title')}
      </Title>
      <div className="flex lg:flex-row flex-col flex-auto gap-8">
        <div className="flex flex-col w-full lg:w-3/6">
          <Title style={{ color: theme.colors.Primary.rgba }} level={5}>
            {t('dealbase:form.metrics.headers.actuals')}
          </Title>
          <Form.List name="ebitdaYears">
            {(ebitdaYears, { add, remove }) => {
              return (
                <div className="flex flex-col">
                  <Form.Item dependencies={['ebitdaYears.year', 'ebitdaYears.amount']}>
                    {({ getFieldValue }) => {
                      return ebitdaYears.map(({ key, name, ...restField }) => {
                        const disabled = getFieldValue('ebitdaYears')[name]?.lockedYear;
                        return (
                          <div key={key} className="flex flex-col">
                            <div className="flex items-center flex-row gap-x-4">
                              <div className="flex flex-auto sm:flex-row flex-col gap-x-4">
                                <Form.Item
                                  {...restField}
                                  className="md:w-3/6"
                                  label={metrics.ebitda.year.label}
                                  rules={metrics.ebitda.year.rules}
                                  name={[name, metrics.ebitda.year.dataIndex]}
                                >
                                  <metrics.ebitda.year.component disabled={disabled} />
                                </Form.Item>
                                <Form.Item
                                  {...restField}
                                  className="md:w-3/6"
                                  label={metrics.ebitda.amount.label}
                                  rules={metrics.ebitda.amount.rules}
                                  name={[name, metrics.ebitda.amount.dataIndex]}
                                >
                                  <metrics.ebitda.amount.component />
                                </Form.Item>
                              </div>
                              {!disabled && <MinusCircleOutlined onClick={() => remove(name)} />}
                            </div>
                            {ebitdaYears.length > 1 && <Divider />}
                          </div>
                        );
                      });
                    }}
                  </Form.Item>
                  <Form.Item>
                    <Button disabled={disabled} type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                      {t('dealbase:actions.addYear')}
                    </Button>
                  </Form.Item>
                </div>
              );
            }}
          </Form.List>
        </div>
        <div className="flex flex-col w-full lg:w-3/6">
          <Title style={{ color: theme.colors.Primary.rgba }} level={5}>
            {t('dealbase:form.metrics.headers.forecast')}
          </Title>
          <Form.List name="ebitdaProjections">
            {(ebitdaProjections, { add, remove }) => {
              return (
                <div className="flex flex-col">
                  <Form.Item dependencies={['ebitdaProjections.year', 'ebitdaProjections.amount']}>
                    {() =>
                      ebitdaProjections.map(({ key, name, ...restField }) => {
                        return (
                          <div key={key} className="flex flex-col">
                            <div className="flex items-center flex-row gap-x-4">
                              <div className="flex flex-auto sm:flex-row flex-col gap-x-4">
                                <Form.Item
                                  {...restField}
                                  className="md:w-3/6"
                                  label={metrics.ebitdaProjection.year.label}
                                  rules={metrics.ebitdaProjection.year.rules}
                                  name={[name, metrics.ebitdaProjection.year.dataIndex]}
                                >
                                  <metrics.ebitdaProjection.year.component disabled={disabled} />
                                </Form.Item>
                                <Form.Item
                                  {...restField}
                                  className="md:w-3/6"
                                  label={metrics.ebitdaProjection.amount.label}
                                  rules={metrics.ebitdaProjection.amount.rules}
                                  name={[name, metrics.ebitdaProjection.amount.dataIndex]}
                                >
                                  <metrics.ebitdaProjection.amount.component />
                                </Form.Item>
                              </div>
                              {!disabled && <MinusCircleOutlined onClick={() => remove(name)} />}
                            </div>
                            {ebitdaProjections.length > 1 && <Divider />}
                          </div>
                        );
                      })
                    }
                  </Form.Item>
                  <Form.Item>
                    <Button disabled={disabled} type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                      {t('dealbase:actions.addYear')}
                    </Button>
                  </Form.Item>
                </div>
              );
            }}
          </Form.List>
        </div>
      </div>
    </div>
  );
};

export default FinancialsEbitdaSection;
