import React, { useCallback, useEffect, useState } from 'react';

import { EditFilled, ReloadOutlined } from '@ant-design/icons';
import { Badge, Button, Form, Tabs } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Prompt } from 'react-router';

import { PopConfirmCancelWrapper } from 'common-ui/wrappers';
import { UserDetails } from 'core/lib';
import LoaderWrapper from 'utils/loader-wrapper';

import { mandatoryTabFields } from './formConfig';
import { UserSectionKeys, UserTabKeys, userTabs } from './formKeys';
import { UserDetailsContext } from '../context';
import { ActivityTab, UserGeneralTab } from '../tabs';

const DEFAULT_ACTIVE_TAB = 'generalInformation';

type UserFormProps = {
  onSave: (userDetails: UserDetails) => Promise<void | number>;
  onCancel: () => void;
  onToggleEdit?: () => void;
  onRefetch?: () => void;
  setActiveTabKey: (tabKey: string) => void;
  activeTabKey?: string | null;
  editMode: boolean;
  user: Partial<UserDetails>;
  isNew?: boolean;
  isSaving: boolean;
};

const UserForm = ({
  onSave,
  onCancel,
  onToggleEdit,
  onRefetch,
  setActiveTabKey,
  activeTabKey,
  editMode,
  user,
  isNew = false,
  isSaving,
}: UserFormProps) => {
  const [formDirty, setFormDirty] = useState(false);
  const [formObject] = useForm<UserDetails>();
  const [draftUser, setDraftUser] = useState<Partial<UserDetails>>(user);
  const [tabValidations, setTabValidations] = useState<{ [key in UserTabKeys]?: string[] }>({});
  const [sectionValidations, setSectionValidations] = useState<{
    [key in UserTabKeys]?: { [key in UserSectionKeys]?: boolean };
  }>({});

  const { t } = useTranslation(['loaders', 'actions', 'user', 'global']);

  const init = useCallback(() => {
    formObject?.resetFields();
    setFormDirty(false);
  }, [formObject]);

  useEffect(() => {
    setDraftUser(user);
  }, [user]);

  useEffect(() => {
    init();
  }, [editMode, init]);

  useEffect(() => {
    init();
  }, [draftUser.id, init]);

  const validateTabs = () => {
    Object.entries(mandatoryTabFields).forEach(([tabKey, sectionsObject]) => {
      const tabFields = Object.values(sectionsObject).flat();
      formObject?.validateFields(tabFields).then(
        () => {
          setTabValidations((tabValidations) => ({ ...tabValidations, [tabKey]: [] }));
        },
        (err) => {
          setTabValidations((tabValidations) => ({
            ...tabValidations,
            [tabKey]: err.errorFields.map(({ name }: { name: string[] }) => name).flat(),
          }));
        }
      );
    });
  };

  useEffect(() => {
    const sectionValidations = Object.entries(mandatoryTabFields).reduce((agg, [tabKey, sectionObject]) => {
      agg[tabKey] = {};
      Object.entries(sectionObject).forEach(([sectionKey, sectionFields]) => {
        agg[tabKey][sectionKey] = !!tabValidations[tabKey]?.some((field: string) => sectionFields.includes(field));
      });
      return agg;
    }, {});

    setSectionValidations(sectionValidations);
  }, [tabValidations]);

  const setTabValidationStatus = () => {
    validateTabs();
  };

  const resetTabValidationErrors = () => {
    setTabValidations({});
    setSectionValidations({});
  };

  const onFinish = (values: UserDetails) => {
    setFormDirty(false);
    onSave(values)
      .then(() => {
        resetTabValidationErrors();
      })
      .catch(() => {
        setFormDirty(true);
      });
  };

  const handleCancel = () => {
    resetTabValidationErrors();
    setDraftUser(user);
    onCancel();
    setFormDirty(false);
  };

  const handleValuesChange = debounce((_, allValues) => {
    setFormDirty(true);
    setDraftUser({ ...draftUser, ...allValues });
  }, 100);

  return (
    <LoaderWrapper overlay loading={isSaving} message={t('loaders:global.saving')}>
      <UserDetailsContext.Provider value={draftUser}>
        <Prompt when={formDirty} message={t('global:navigation.unsavedChanges')} />
        <Form
          onValuesChange={handleValuesChange}
          onFinishFailed={setTabValidationStatus}
          className="overflow-y-auto h-full"
          form={formObject}
          layout="vertical"
          name="userForm"
          onFinish={onFinish}
          initialValues={draftUser}
        >
          <Tabs
            size="small"
            activeKey={activeTabKey ?? DEFAULT_ACTIVE_TAB}
            onChange={setActiveTabKey}
            type="line"
            className="h-full overflow-y-auto tabs-centered"
            tabBarExtraContent={
              editMode ? (
                <div className="flex flex-row gap-x-3">
                  <Button htmlType="submit" type="primary" disabled={!formDirty}>
                    {t('actions:global.save')}
                  </Button>
                  <PopConfirmCancelWrapper
                    title={t('actions:confirmation.confirmCancel')}
                    disabled={!formDirty}
                    onConfirm={handleCancel}
                  >
                    <Button>{t('actions:global.cancel')}</Button>
                  </PopConfirmCancelWrapper>
                </div>
              ) : (
                <div className="flex flex-row">
                  <Button type="link" onClick={onToggleEdit} icon={<EditFilled />} />
                  <Button type="link" onClick={onRefetch} icon={<ReloadOutlined />} />
                </div>
              )
            }
            items={[
              {
                key: userTabs.generalInformation,
                className: 'h-full',
                label: (
                  <Badge className="text-inherit" dot={!!tabValidations[userTabs.generalInformation]?.length}>
                    {t('user:tabs.generalInformation')}
                  </Badge>
                ),
                children: (
                  <UserGeneralTab
                    isNew={isNew}
                    sectionValidation={sectionValidations[userTabs.generalInformation]}
                    editMode={editMode}
                  />
                ),
              },
              {
                key: userTabs.activity,
                className: 'h-full',
                label: t('user:tabs.activity'),
                disabled: isNew,
                children: <ActivityTab />,
              },
            ]}
          />
        </Form>
      </UserDetailsContext.Provider>
    </LoaderWrapper>
  );
};

export default UserForm;
