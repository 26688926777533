import React from 'react';

import { Select, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { UserStatus, USER_STATUSLIST } from 'core/lib/constants/statuses';
import { UserFilters } from 'core/lib/modules/users/entities/filters';

const { Title } = Typography;

type StatusFilterProps = {
  onChange: (filters: Partial<UserFilters>) => void;
  filters: UserStatus[] | undefined;
};

const StatusFilter = ({ onChange, filters }: StatusFilterProps) => {
  const { t } = useTranslation();

  const statusOptions = USER_STATUSLIST.map((statusItem) => ({
    value: statusItem.status,
    label: t(`user:status.label.${statusItem.key}`),
  }));

  return (
    <div>
      <Title level={5}>{t('user:filters.status', { count: filters?.length })}</Title>
      <Select
        mode="multiple"
        className="w-full"
        allowClear
        showSearch={false}
        options={statusOptions}
        value={filters}
        placeholder={t('global:placeholders.selectType', { type: t('user:filters.status') })}
        onChange={(status) => onChange({ status })}
      />
    </div>
  );
};

export default StatusFilter;
