import React from 'react';

import { Trans } from 'react-i18next';

import { RouteTypes } from 'core/lib';
import { OBJECT_TYPES } from 'core/lib/constants';
import { ClientConfiguration } from 'core/lib/modules/auth/entities';
import i18n from 'core/lib/utils/i18n';

export type ModulePreferenceOptions = {
  label: string;
  value: number | null;
  children?: { label: string; value: RouteTypes }[];
};

export const getRouteOptions = (
  clientConfiguration: Partial<ClientConfiguration> | null
): ModulePreferenceOptions[] => {
  const routeOptions: ModulePreferenceOptions[] = [
    {
      label: i18n.t('global:welcome.title'),
      value: 0,
    },
  ];
  if (clientConfiguration) {
    if (clientConfiguration.dealBaseEnabled) {
      routeOptions.push({
        label: i18n.t('global:moduleNames.dealbase'),
        value: OBJECT_TYPES.DEAL_BASE,
        children: [
          {
            label: i18n.t('global:menu.listing'),
            value: RouteTypes.LISTING,
          },
          {
            label: i18n.t('global:menu.dashboard'),
            value: RouteTypes.DASHBOARD,
          },
        ],
      });
    }
    if (clientConfiguration.portfolioEnabled) {
      routeOptions.push({
        label: i18n.t('global:moduleNames.portfolio'),
        value: OBJECT_TYPES.PORTFOLIO,
        children: [
          {
            label: i18n.t('global:menu.listing'),
            value: RouteTypes.LISTING,
          },
          {
            label: i18n.t('global:menu.dashboard'),
            value: RouteTypes.DASHBOARD,
          },
        ],
      });
    }
    if (clientConfiguration.contactBookEnabled) {
      routeOptions.push({
        label: i18n.t('global:moduleNames.contactbook'),
        value: OBJECT_TYPES.CONTACT_BOOK,
        children: [
          {
            label: i18n.t('global:menu.listing'),
            value: RouteTypes.LISTING,
          },
        ],
      });
    }
  }
  return routeOptions;
};

export const ModulesStatusOptions = [
  { label: <Trans i18nKey="global:menu.listing" />, value: RouteTypes.LISTING },
  { label: <Trans i18nKey="global:menu.dashboard" />, value: RouteTypes.DASHBOARD },
];

export const LeftMenuStatusOptions = [
  { label: <Trans i18nKey="global:menu.status.collapsed" />, value: false },
  { label: <Trans i18nKey="global:menu.status.expanded" />, value: true },
];
