import React, { useEffect, useState } from 'react';

import { transform } from 'lodash';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router-dom';

import { baseApi, detailsSectionPreferenceApi } from 'core/lib';
import { OBJECT_TYPES } from 'core/lib/constants';
import { portfolioApi, PortfolioDetails } from 'core/lib/modules/portfolio';
import PortfolioForm from 'modules/portfolio/form/portfolioForm';
import { Routes } from 'routes/routes';
import { useMatchParamId } from 'utils/hooks';
import LoaderWrapper from 'utils/loader-wrapper';

import { DetailsPageHeader } from '../components';

const PortfolioDetailsComponent = () => {
  const id = useMatchParamId(Routes.PORTFOLIO_DETAILS.path);
  const history = useHistory();
  const { t } = useTranslation(['loaders', 'actions', 'portfolio', 'global']);

  const [editMode, setEditMode] = useState(false);
  const [activeTabKey, setActiveTabKey] = useState<string | null>();

  const {
    data,
    refetch,
    isFetching: fetchingPortfolio,
    isError: loadingPortfolioError,
    originalArgs,
  } = portfolioApi.useGetPortfolioDetailsQuery(id!);
  const { isLoading: loadingSectionHeaders } = detailsSectionPreferenceApi.useGetDetailsSectionHeadersQuery(
    OBJECT_TYPES.PORTFOLIO
  );

  const [postPortfolio, { isLoading: isSaving, isSuccess: saveSuccess }] = portfolioApi.usePatchPortfolioMutation();

  const onToggleEdit = () => {
    setEditMode(true);
  };

  const onCancel = () => {
    setEditMode(false);
  };

  useEffect(() => {
    if (loadingPortfolioError) {
      history.push(generatePath(Routes.PORTFOLIO.path));
    }
  }, [loadingPortfolioError]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (saveSuccess) {
      setEditMode(false);
    }
  }, [saveSuccess]);

  useEffect(() => {
    if (originalArgs) {
      setEditMode(false);
      setActiveTabKey(null);
    }
  }, [originalArgs]);

  useEffect(() => {
    baseApi.util.resetApiState();
  }, [data?.id]);

  const onSave = (portfolio: PortfolioDetails) => {
    portfolio.id = parseInt(id!);
    portfolio = transform(portfolio, (result, value, key) => {
      result[key] = value ?? null;
    });
    return postPortfolio(portfolio).unwrap();
  };

  const onRefetch = () => {
    refetch();
  };

  const handlePageHeaderBackClick = () => {
    history.goBack();
  };

  return (
    <LoaderWrapper loading={fetchingPortfolio || loadingSectionHeaders} message={t('loaders:portfolio.loadingDetails')}>
      {data && (
        <>
          <DetailsPageHeader onBack={handlePageHeaderBackClick} portfolio={data} />
          <PortfolioForm
            portfolio={data}
            onSave={onSave}
            onCancel={onCancel}
            onToggleEdit={onToggleEdit}
            onRefetch={onRefetch}
            setActiveTabKey={setActiveTabKey}
            activeTabKey={activeTabKey}
            editMode={editMode}
            isSaving={isSaving}
          />
        </>
      )}
    </LoaderWrapper>
  );
};

export default PortfolioDetailsComponent;
