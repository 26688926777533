import i18n from 'core/lib/utils/i18n';

export type UserStatus = 'active' | 'inactive';

export const USER_STATUS: { [key: string]: UserStatus } = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
};

export const USER_STATUSLIST = [
  {
    status: USER_STATUS.ACTIVE,
    key: USER_STATUS.ACTIVE,
    name: i18n.t(`user:status.label.${USER_STATUS.ACTIVE}`),
  },
  {
    status: USER_STATUS.INACTIVE,
    key: USER_STATUS.INACTIVE,
    name: i18n.t(`user:status.label.${USER_STATUS.INACTIVE}`),
  },
];
