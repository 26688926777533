import React from 'react';

import { Skeleton } from 'antd';
import { Rule } from 'antd/lib/form';

interface EditableCellProps<T> extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  isSaving: boolean;
  dataIndex: string;
  title: string;
  inputType?: (props: any) => JSX.Element; // eslint-disable-line @typescript-eslint/no-explicit-any
  inputProps?: { [key: string]: string | number };
  index: number;
  children: React.ReactNode;
  rules: Rule[];
  record: T;
}

function EditableCell<T>({
  editing,
  isSaving,
  dataIndex,
  title,
  inputType: FormInput,
  inputProps,
  index,
  children,
  rules,
  record,
  ...restProps
}: EditableCellProps<T>) {
  const showSkeleton = editing && isSaving;

  if (showSkeleton) {
    return (
      <td {...restProps}>
        <Skeleton.Input className="flex flex-auto" active={true} size="small" />
      </td>
    );
  }

  return (
    <td {...restProps}>
      {editing && FormInput ? (
        <FormInput {...inputProps} record={record} dataIndex={dataIndex} rules={rules} />
      ) : (
        children
      )}
    </td>
  );
}

export default EditableCell;
