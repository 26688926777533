import React from 'react';

import { Tag, TagProps } from 'antd';

type OrganizationTagProps = {
  tagProps?: TagProps;
  name?: string;
};

const OrganizationTag = ({ name, tagProps }: OrganizationTagProps) => {
  return (
    <Tag color="blue" {...tagProps}>
      {name}
    </Tag>
  );
};

export default OrganizationTag;
