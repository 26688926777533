import React from 'react';

import { List, Typography } from 'antd';
import { Trans } from 'react-i18next';

import { DateSince } from 'common-ui';
import { UserNotification } from 'core/lib';
import { MODULE_NAMES } from 'core/lib/constants';
import colors from 'core/lib/theme/colors';

import { NotificationCardModuleAssignedIcon, NotificationCardWrapper } from '../components';

const { Text } = Typography;

type ModuleAssignedNotificationCardProps = {
  notification: UserNotification;
  onClick: (id: number) => void;
};

const ModuleAssignedNotificationCard = ({ notification, onClick }: ModuleAssignedNotificationCardProps) => {
  const type = notification.objectType ? MODULE_NAMES[notification.objectType] : notification.type;

  return (
    <NotificationCardWrapper hasQueryParams={false} key={notification.id} notification={notification} onClick={onClick}>
      <List.Item.Meta
        avatar={<NotificationCardModuleAssignedIcon />}
        title={
          <Trans
            i18nKey="notifications:module.assigned"
            shouldUnescape={true}
            values={{ objectName: notification.objectName, type }}
            components={{ linkText: <Text style={{ color: colors.Primary.rgba }}></Text> }}
          />
        }
        description={<DateSince date={notification.dateCreated} />}
      />
    </NotificationCardWrapper>
  );
};

export default ModuleAssignedNotificationCard;
