import React, { useMemo } from 'react';

import { groupBy } from 'lodash';
import { useTranslation } from 'react-i18next';

import { ListObjectFilter } from 'common-ui/lists';
import { DealBaseListing } from 'core/lib';
import { OBJECT_TYPES } from 'core/lib/constants';

type DealsByStageProps = {
  data?: DealBaseListing[];
};

const DealsByStage = ({ data }: DealsByStageProps) => {
  const { t } = useTranslation();

  const dataByStage = useMemo(() => groupBy(data, (d) => d.stage?.name ?? t('global:results.notSpecified')), [data, t]);

  return (
    <ListObjectFilter
      placeholder={t('dealbase:placeholders.searchStage')}
      data={dataByStage}
      title={t('dealbase:dashboards.titles.stage')}
      objectType={OBJECT_TYPES.DEAL_BASE}
    />
  );
};

export default DealsByStage;
