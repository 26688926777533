import { BaseQueryFn, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';
import axios, { AxiosError } from 'axios';
import moment from 'moment';

import { getClientDomainName } from '..';
import AuthenticateSerivce from '../../modules/auth/services/auth.service';

const { REACT_APP_PROTOCOL, REACT_APP_HOSTNAME } = process.env;

export const getClient = () => {
  return AuthenticateSerivce.getCookiesClient() ?? getClientDomainName();
};

const rawBaseQuery = (baseUrl: string) =>
  fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers: Headers) => {
      const token = AuthenticateSerivce.getCookiesAccessToken();
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  });

export const getBaseUrl = () => {
  const client = getClient();
  const baseUrl = `${REACT_APP_PROTOCOL}://${client}.${REACT_APP_HOSTNAME}/`;

  return baseUrl;
};

export const dynamicBaseQuery: BaseQueryFn<
  string | (FetchArgs & { isBase?: boolean }),
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const client = getClient();

  // gracefully handle scenarios where data to generate the URL is missing
  if (!client) {
    return {
      error: {
        status: 400,
        statusText: 'Bad Request',
        data: 'No client received',
      },
    };
  }

  const baseUrl = `${getBaseUrl()}`;

  return rawBaseQuery(baseUrl)(args, api, extraOptions);
};

export const getFileUploader = () => {
  const baseURL = `${getBaseUrl()}api/userblob/upload`;
  const token = AuthenticateSerivce.getCookiesAccessToken();
  const axiosInstance = axios.create({
    baseURL,
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  });

  axiosInstance.interceptors.response.use(
    (response) => response,
    (error: AxiosError) => {
      return Promise.reject(error.response?.data);
    }
  );

  return axiosInstance;
};

export const downloadAuditLog = (moduleName: string, data: string) => {
  const downloadName = `${moment().local().format('DDMMYYYY_HHmmss')}_Audit_Trail_${moduleName}.xls`;

  const hiddenElement = document.createElement('a');

  hiddenElement.href = 'data:application/octet-stream;charset=utf-8;base64,' + data;
  hiddenElement.target = '_blank';
  hiddenElement.download = downloadName;
  hiddenElement.click();
};
