import React, { useEffect, useState } from 'react';

import { Typography, PageHeader, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router';
import { useMedia } from 'react-use';

import { RequestError, userApi, UserDetails } from 'core/lib';
import { EXCEPTIONS } from 'core/lib/constants';
import UserBuilder from 'core/lib/utils/builders/UserBuilder';
import { Routes } from 'routes/routes';

import { UserForm } from '../form';
import { getSystemRoleIdFromRoles } from '../utils';

const { Title } = Typography;

const UserNew = () => {
  const { t } = useTranslation(['loaders', 'actions', 'user', 'global']);
  const [user] = useState(new UserBuilder().build());
  const isMobile = useMedia('(max-width: 768px)');
  const history = useHistory();
  const [activeTabKey, setActiveTabKey] = useState<string>();

  const [postUser, { isLoading: isSaving, isSuccess: saveSuccess, error, data }] = userApi.usePostUserMutation();

  const onSave = (user: UserDetails) => {
    user.systemRoleId = getSystemRoleIdFromRoles(user.systemRoles);

    return postUser(user).unwrap();
  };

  useEffect(() => {
    if (saveSuccess) {
      history.push(generatePath(Routes.USER_DETAILS.path, { id: data! }));
    }
  }, [saveSuccess, data, history]);

  const onCancel = () => {
    history.push(Routes.USER_LISTING.path);
  };

  useEffect(() => {
    if (error) {
      const key = `open${Date.now()}`;

      const errorData = error as RequestError;
      if (errorData.data.ExceptionType === EXCEPTIONS.SubscriptionLimit) {
        notification.open({
          type: 'warning',
          message: t(`user:errors.maximumNumberOfUsers`),
          description: t('user:errors.maximumNumberOfUsersDescription'),
          key,
        });
      }
    }
  }, [error, t]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    user && (
      <>
        <PageHeader
          title={
            <div className="flex sm:flex-row flex-col items-center gap-2">
              <Title
                className="ws-normal md:truncate w-full text-center"
                style={{ margin: 0 }}
                level={isMobile ? 4 : 3}
              >
                {t('user:placeholders.createUser')}
              </Title>
            </div>
          }
        />
        <UserForm
          activeTabKey={activeTabKey}
          setActiveTabKey={setActiveTabKey}
          isSaving={isSaving}
          user={user}
          onSave={onSave}
          onCancel={onCancel}
          editMode
          isNew
        />
      </>
    )
  );
};

export default UserNew;
