export const contactBookTabs = {
  details: 'details',
  activity: 'activity',
};

export const contactBookSections = {
  contactInformation: 'contactInformation',
  professionalInformation: 'professionalInformation',
  comments: 'Comments',
  tags: 'tags',
  insight: 'insight',
};

export type ContactBookSectionKeys = keyof typeof contactBookSections;

export type ContactBookTabKeys = keyof typeof contactBookTabs;

export const tabSections: { [key in ContactBookTabKeys]: { [key in ContactBookSectionKeys]?: string } } = {
  details: {
    contactInformation: 'contactInformation',
    professionalInformation: 'professionalInformation',
    comments: 'Comments',
    tags: 'tags',
    insight: 'insight',
  },
  activity: {},
};
