import React, { useMemo } from 'react';

import { AvatarProps, Select, SelectProps, TagProps } from 'antd';

import { UserAvatarGroup, UserTag } from 'common-ui';
import { BaseUser, userApi } from 'core/lib';

import { mergeMultiSelectedWithOptions } from './formUtils';

export type UserMultiSelectProps = {
  roles?: number[];
  asAvatar?: boolean;
  avatarProps?: AvatarProps & { maxCount?: number };
  asTag?: boolean;
  tagProps?: TagProps;
  color?: string;
  selectedOption?: BaseUser[];
};

const UserMultiSelect = ({
  roles,
  asAvatar,
  avatarProps = {},
  asTag,
  tagProps = {},
  color,
  selectedOption,
  ...props
}: UserMultiSelectProps & SelectProps<number[]>) => {
  const [getUserOptions, { data, isFetching }] = userApi.useLazyGetUserOptionsQuery({
    selectFromResult: ({ data, ...rest }) => {
      return { data: mergeMultiSelectedWithOptions<BaseUser>(data, selectedOption), ...rest };
    },
  });

  const onDropdownVisibleChange = (open: boolean) => {
    if (open) {
      getUserOptions(undefined, true);
    }
  };

  const value = useMemo(() => selectedOption?.map((so) => so.id) ?? [], [selectedOption]);

  if (props.disabled && selectedOption?.length) {
    if (asTag) {
      return (
        <div className="flex flex-wrap gap-1">
          {selectedOption?.map((user: BaseUser) => (
            <UserTag key={user.id} {...user} />
          ))}
        </div>
      );
    } else if (asAvatar) {
      return <UserAvatarGroup size="default" users={selectedOption} {...avatarProps} />;
    }
  }

  return (
    <Select
      showSearch
      allowClear
      optionFilterProp="label"
      loading={isFetching}
      options={data}
      mode="multiple"
      value={value}
      onDropdownVisibleChange={onDropdownVisibleChange}
      {...props}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onChange={(_val, option: any) => {
        props.onChange?.(option, option);
      }}
    />
  );
};

export default UserMultiSelect;
