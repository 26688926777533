import React from 'react';

import { Divider, Tooltip, Typography } from 'antd';

import { DateRecord } from 'common-ui';
import { ConversationMessage } from 'core/lib';
import { DEFAULT_DATETIME_FORMAT, DEFAULT_TIME_FORMAT_DAYPART } from 'core/lib/constants/defaults';

const { Text } = Typography;

const UserMessage = ({ message }: { message: ConversationMessage }) => {
  return (
    <>
      <Divider plain>
        <Tooltip
          title={
            <DateRecord
              date={message.dateCreated}
              dateFormat={DEFAULT_TIME_FORMAT_DAYPART}
              tooltipFormat={DEFAULT_DATETIME_FORMAT}
            />
          }
        >
          <Text type="secondary" italic>
            {message.content}
          </Text>
        </Tooltip>
      </Divider>
    </>
  );
};

export default UserMessage;
