import React, { useEffect, useMemo, useState } from 'react';

import { List, Popover, Typography } from 'antd';
import { isEqual } from 'date-fns';
import { groupBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { generatePath, Link } from 'react-router-dom';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';

import Calendar from 'common-ui/calendar';
import { DealBaseListing } from 'core/lib';
import colors from 'core/lib/theme/colors';
import { parseJSONDate } from 'core/lib/utils';
import { Routes } from 'routes/routes';

const { Title } = Typography;

const BASE_DEAL_BASE_INNER_ROW = 40;
const DEAL_BASE_CONTAINER_HEIGHT = 250;

type DealsByFollowUpDateProps = {
  data?: DealBaseListing[];
};

const DealsByFollowUpDate = ({ data }: DealsByFollowUpDateProps) => {
  const { t } = useTranslation();
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(new Date());
  const [calendarDate, setCalendarDate] = useState<Date>(new Date());

  const followUpData = useMemo(() => {
    return groupBy(data, (d) => d.followUpDate && parseJSONDate(d.followUpDate)?.toDateString());
  }, [data]);

  useEffect(() => {
    setSelectedDate(calendarDate);
  }, [calendarDate]);

  const dateFullCellRender = (value: Date) => {
    const isSelected = !!(selectedDate && isEqual(value, selectedDate));
    const dealsList = followUpData[value.toDateString()];
    const listRawHeight = (dealsList?.length || 0) * BASE_DEAL_BASE_INNER_ROW;
    const listHeight = listRawHeight > DEAL_BASE_CONTAINER_HEIGHT ? DEAL_BASE_CONTAINER_HEIGHT : listRawHeight;
    return (
      dealsList?.length && (
        <Popover
          trigger="click"
          placement="bottom"
          content={
            <div className="min-w-64">
              <AutoSizer disableHeight>
                {({ width }) => (
                  <List>
                    <FixedSizeList
                      itemData={dealsList}
                      width={width}
                      height={listHeight}
                      itemCount={dealsList.length}
                      itemSize={BASE_DEAL_BASE_INNER_ROW}
                    >
                      {({ index, data, style }) => {
                        const item = data[index];
                        return (
                          <List.Item key={item.id} style={style}>
                            <Link to={generatePath(Routes.DEAL_BASE_DETAILS.path, { id: item.id })}>{item.name}</Link>
                          </List.Item>
                        );
                      }}
                    </FixedSizeList>
                  </List>
                )}
              </AutoSizer>
            </div>
          }
          open={isSelected}
          onOpenChange={(visible) => setSelectedDate(visible ? value : undefined)}
        >
          <div style={{ width: '100%', height: '2px', backgroundColor: isSelected ? '' : colors.Primary.rgba }}></div>
        </Popover>
      )
    );
  };
  return (
    <div className="flex flex-col h-full gap-2">
      <Title level={5}>{t('dealbase:dashboards.titles.followUp')}</Title>
      <Calendar
        className="h-full border border-solid b--grey-200"
        mode="month"
        fullscreen={false}
        value={calendarDate}
        onSelect={setCalendarDate}
        dateCellRender={dateFullCellRender}
      />
    </div>
  );
};

export default DealsByFollowUpDate;
