import React, { useContext, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import Comments from 'common-ui/comments';
import { dealBaseApi, DraftBaseComment } from 'core/lib';
import { DealBaseDetailsContext } from 'modules/dealBase/context';
import LoaderWrapper from 'utils/loader-wrapper';

type CommentsSectionProps = {
  editMode: boolean;
};

const DealBaseCommentsSection = ({ editMode }: CommentsSectionProps) => {
  const dealbase = useContext(DealBaseDetailsContext);
  const { t } = useTranslation();
  const [getComments, { data, isLoading }] = dealBaseApi.useLazyGetCommentsQuery();
  const [postComment, { isLoading: isSaving }] = dealBaseApi.usePostCommentMutation();
  const [deleteComment] = dealBaseApi.useDeleteCommentMutation();

  useEffect(() => {
    if (dealbase?.id) {
      getComments(dealbase.id);
    }
  }, [dealbase?.id, getComments]);

  const onSubmit = (comment: Partial<DraftBaseComment>, callback?: () => void) => {
    if (dealbase?.id) {
      comment.objectId = dealbase.id;
      postComment(comment)
        .unwrap()
        .then(() => {
          if (callback) {
            callback();
          }
        });
    }
  };

  const onReplySubmit = (parentId: number, comment: Partial<DraftBaseComment>, callback?: () => void) => {
    if (dealbase?.id) {
      comment.objectId = dealbase.id;
      comment.replyToId = parentId;
      postComment(comment)
        .unwrap()
        .then(() => {
          if (callback) {
            callback();
          }
        });
    }
  };

  const onDelete = (userCommentId: number, callback?: () => void) => {
    if (dealbase?.id) {
      deleteComment({ id: userCommentId, objectId: dealbase.id })
        .unwrap()
        .then(() => {
          if (callback) {
            callback();
          }
        });
    }
  };

  return (
    <LoaderWrapper loading={isLoading} message={t('loaders:dealBase.loadingComments')}>
      <div className="flex flex-col">
        <Comments
          type={t('global:general.comment')}
          isSaving={isSaving}
          onDelete={onDelete}
          onSubmit={onSubmit}
          onReplySubmit={onReplySubmit}
          disabled={!editMode}
          comments={data ?? []}
        />
      </div>
    </LoaderWrapper>
  );
};

export default DealBaseCommentsSection;
