import React, { useCallback, useMemo } from 'react';

import { Badge, Divider, List, Typography } from 'antd';
import { pick, sum } from 'lodash';
import { useTranslation } from 'react-i18next';

import { EmptyWrapper, UserAvatar, UserLink } from 'common-ui';
import { Scorecard } from 'core/lib';
import colors from 'core/lib/theme/colors';
import { toRoundedNumber } from 'utils';

import ScoringItem from './scoringItem';

const { Title } = Typography;

const ScoreFields = ['traction', 'greatness', 'scalability', 'uniqueness', 'fit'] as const;

type ScoringDetailsProps = {
  scorecards: Scorecard[];
};

const ScoringDetails = ({ scorecards }: ScoringDetailsProps) => {
  const { t } = useTranslation();

  const getScoresFromScorecard = (scorecard: Scorecard) => pick(scorecard, ScoreFields);

  const getScorecardBlendedScore = (scorecard: Pick<Scorecard, typeof ScoreFields[number]>) => {
    return sum(Object.values(scorecard)) / ScoreFields.length;
  };

  const getOverallScore = useMemo(() => {
    return (
      sum(scorecards.map((scorecard) => getScorecardBlendedScore(getScoresFromScorecard(scorecard)))) /
      scorecards.length
    );
  }, [scorecards]);

  const getOverallColumnScore = useCallback(
    (column: string) => {
      return sum(scorecards.map((scorecard) => scorecard[column])) / scorecards.length;
    },
    [scorecards]
  );

  return (
    <EmptyWrapper empty={!scorecards.length}>
      <List.Item>
        <List.Item.Meta
          title={
            <div className="flex items-center gap-2">
              <Title style={{ margin: 0 }} level={5}>
                {t('dealbase:scoreCards.columns.overall')}
              </Title>
              <Badge style={{ backgroundColor: colors.Primary.rgba }} count={toRoundedNumber(getOverallScore, 1)} />
            </div>
          }
          description={
            <div className="flex flex-wrap gap-4 gap-y-1">
              <ScoringItem
                label={t('dealbase:scoreCards.columns.traction')}
                value={getOverallColumnScore('traction')}
              />
              <ScoringItem
                label={t('dealbase:scoreCards.columns.greatness')}
                value={getOverallColumnScore('greatness')}
              />
              <ScoringItem
                label={t('dealbase:scoreCards.columns.scalability')}
                value={getOverallColumnScore('scalability')}
              />
              <ScoringItem
                label={t('dealbase:scoreCards.columns.uniqueness')}
                value={getOverallColumnScore('uniqueness')}
              />
              <ScoringItem label={t('dealbase:scoreCards.columns.fit')} value={getOverallColumnScore('fit')} />
            </div>
          }
        />
      </List.Item>
      <Divider className="m-0" />
      <List
        itemLayout="horizontal"
        dataSource={scorecards}
        split
        renderItem={(scorecard) => (
          <List.Item>
            <List.Item.Meta
              avatar={<UserAvatar iconOnly user={scorecard.user} />}
              title={
                <div className="flex items-center gap-2">
                  <UserLink id={scorecard.user.id} name={scorecard.user.name} />
                  <Badge
                    style={{ backgroundColor: colors.Primary.rgba }}
                    count={getScorecardBlendedScore(getScoresFromScorecard(scorecard))}
                  />
                </div>
              }
              description={
                <div className="flex flex-wrap gap-4 gap-y-1 ">
                  <ScoringItem label={t('dealbase:scoreCards.columns.traction')} value={scorecard.traction} />
                  <ScoringItem label={t('dealbase:scoreCards.columns.greatness')} value={scorecard.greatness} />
                  <ScoringItem label={t('dealbase:scoreCards.columns.scalability')} value={scorecard.scalability} />
                  <ScoringItem label={t('dealbase:scoreCards.columns.uniqueness')} value={scorecard.uniqueness} />
                  <ScoringItem label={t('dealbase:scoreCards.columns.fit')} value={scorecard.fit} />
                </div>
              }
            />
          </List.Item>
        )}
      />
    </EmptyWrapper>
  );
};

export default ScoringDetails;
