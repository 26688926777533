import { createApi } from '@reduxjs/toolkit/query/react';

import { dynamicBaseQuery } from 'core/lib/utils/requesting';
import { getTimezoneOffset } from 'utils';

import endpoints, { TAGS } from './endpoints';
import { cacher } from '../../../utils/rtkQueryCacheUtils';
import { ObjectHistory } from '../../app';
import { authApi } from '../../auth';
import {
  BaseUser,
  UserOption,
  UserPasswordUpdateRequest,
  UserProfileDetails,
  UserListing,
  UserMenu,
  UserDetails,
  UserListingUpdate,
  UserShortProfile,
  UniqueEmailValidationRequest,
  UserPublicProfile,
  UserOptionRequest,
} from '../entities';

const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: dynamicBaseQuery,
  tagTypes: [...cacher.defaultTags, ...Object.values(TAGS)],
  endpoints: (build) => ({
    getUserOptions: build.query<UserOption[], UserOptionRequest | void>({
      query: (params) => ({ url: endpoints.options.url, params: params ?? {} }),
      providesTags: cacher.providesList(endpoints.options.tag),
      transformResponse: endpoints.options.deserializer,
    }),
    getUsersShort: build.query<BaseUser[], void>({
      query: () => endpoints.usersShort.url,
      providesTags: cacher.providesList(endpoints.usersShort.tag),
      transformResponse: endpoints.usersShort.deserializer,
    }),
    getUserProfileDetails: build.query<UserProfileDetails, void>({
      query: () => endpoints.userProfileDetails.url,
      transformResponse: endpoints.userProfileDetails.deserializer,
    }),
    getUserShortProfile: build.query<UserShortProfile, number>({
      query: (userId) => `${endpoints.userProfileShort.url}/${userId}`,
      transformResponse: endpoints.userProfileShort.deserializer,
    }),
    getUserPublicProfile: build.query<UserPublicProfile, number>({
      query: (userId) => `${endpoints.userPublicProfile.url}/${userId}`,
      providesTags: cacher.cacheByIdArg(endpoints.userPublicProfile.tag),
      transformResponse: endpoints.userPublicProfile.deserializer,
    }),
    getHistory: build.query<ObjectHistory[], number>({
      query: (id) => ({ url: endpoints.getHistory.url, params: { id } }),
      providesTags: cacher.cacheByIdArg(endpoints.getHistory.tag),
      transformResponse: endpoints.getHistory.deserializer,
    }),
    updateUserProfileDetails: build.mutation<void, UserProfileDetails>({
      query: (userProfileDetails) => ({
        method: 'POST',
        url: `${endpoints.updateProfileInfo.url}`,
        body: userProfileDetails,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(authApi.util.prefetch('getStatus', getTimezoneOffset(), { force: true }));
          dispatch(userApi.util.prefetch('getUserProfileDetails', undefined, { force: true }));
        } catch {}
      },
    }),
    updatePassword: build.mutation<void, UserPasswordUpdateRequest>({
      query: (userPassword) => ({
        method: 'POST',
        url: `${endpoints.updatePassword.url}`,
        body: userPassword,
      }),
    }),
    updateListingItem: build.mutation<UserListing, Partial<UserListingUpdate> & { id: number }>({
      query: ({ id, ...patch }) => ({
        method: 'POST',
        url: `${endpoints.updateListingItem.url}/${id}`,
        body: patch,
      }),
      invalidatesTags: cacher.invalidatesMultipleLists(endpoints.updateListingItem.invalidates),
      transformResponse: endpoints.updateListingItem.deserializer,
      async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            userApi.util.updateQueryData('getUserListing', undefined, (draft) => {
              const index = draft.findIndex((d) => d.id === id);
              draft.splice(index, 1, { ...draft[index], ...data });
            })
          );
          dispatch(userApi.util.invalidateTags([{ type: TAGS.USER_DETAILS, id }]));
        } catch {}
      },
    }),
    update: build.mutation<void, UserPasswordUpdateRequest>({
      query: (userPassword) => ({
        method: 'POST',
        url: `${endpoints.updatePassword.url}`,
        body: userPassword,
      }),
    }),
    getUserMenu: build.query<UserMenu[], void>({
      query: () => endpoints.menu.url,
      providesTags: cacher.providesList(endpoints.menu.tag),
      transformResponse: endpoints.menu.deserializer,
    }),
    getUserListing: build.query<UserListing[], void>({
      query: () => endpoints.listing.url,
      providesTags: cacher.providesList(endpoints.listing.tag),
      transformResponse: endpoints.listing.deserializer,
    }),
    getUserDetails: build.query<UserDetails, string>({
      query: (id) => ({ url: endpoints.details.url, params: { id } }),
      providesTags: cacher.cacheByIdArg(endpoints.details.tag),
      transformResponse: endpoints.details.deserializer,
    }),
    postUser: build.mutation<number, UserDetails>({
      query: (user) => ({ method: 'POST', url: endpoints.createUser.url, body: user }),
      invalidatesTags: cacher.invalidatesMultipleLists(endpoints.createUser.invalidates),
    }),
    patchUser: build.mutation<number, Partial<UserDetails>>({
      query: (user) => ({ method: 'POST', url: endpoints.updateUser.url, body: user }),
      invalidatesTags: cacher.invalidatesMultipleLists(endpoints.updateUser.invalidates),
      async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(userApi.util.invalidateTags([{ type: TAGS.USER_DETAILS, id }]));
        } catch {}
      },
    }),
    resetPassword: build.mutation<string, number>({
      query: (userId) => ({ method: 'POST', url: `${endpoints.resetPassword.url}/${userId}` }),
    }),
    unlockUser: build.query<void, number>({
      query: (userId) => ({
        url: `${endpoints.unlockUser.url}/${userId}`,
      }),
      async onQueryStarted(userId, { dispatch, queryFulfilled }) {
        const patchListingResult = dispatch(
          userApi.util.updateQueryData('getUserListing', undefined, (draft) => {
            const index = draft.findIndex((d) => d.id === userId);
            if (index) {
              draft.splice(index, 1, { ...draft[index], locked: false });
            }
          })
        );
        try {
          await queryFulfilled;
          dispatch(userApi.util.invalidateTags([{ type: TAGS.USER_DETAILS, id: userId }]));
        } catch {
          patchListingResult.undo();
        }
      },
    }),
    activateUser: build.mutation<void, number>({
      query: (userId) => `${endpoints.activateUser.url}/${userId}`,
      invalidatesTags: cacher.invalidatesMultipleLists(endpoints.activateUser.invalidates),
      async onQueryStarted(userId, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(userApi.util.invalidateTags([{ type: TAGS.USER_DETAILS, id: userId }]));
      },
    }),
    deactivateUser: build.mutation<void, number>({
      query: (userId) => `${endpoints.deactivateUser.url}/${userId}`,
      invalidatesTags: cacher.invalidatesMultipleLists(endpoints.deactivateUser.invalidates),
      async onQueryStarted(userId, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(userApi.util.invalidateTags([{ type: TAGS.USER_DETAILS, id: userId }]));
      },
    }),
    checkUniqueEmail: build.query<boolean, UniqueEmailValidationRequest>({
      query: (params) => ({ url: endpoints.getUniqueEmail.url, params }),
    }),
  }),
});

export default userApi;
