import React, { useCallback } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import { Card, List, PageHeader, Tooltip, Typography } from 'antd';
import Meta from 'antd/lib/card/Meta';
import { Trans } from 'react-i18next';

import { WrapInLink } from 'common-ui/links';
import { RouteTypes, selectClientConfiguration, useAppSelector } from 'core/lib';
import { userHasModuleAccess } from 'core/lib/utils';
import { moduleConfigurations } from 'modules/appLayout/defaultModules';
import { useLoggedInUser } from 'utils/hooks';

const { Title, Link: ExternalLink } = Typography;

const Welcome = () => {
  const currentUser = useLoggedInUser();
  const clientConfiguration = useAppSelector(selectClientConfiguration);

  const hasModuleAccess = useCallback(
    (objectType: number) => userHasModuleAccess(objectType, clientConfiguration),
    [clientConfiguration]
  );

  return (
    <div className="overflow-auto">
      <div className="flex flex-col max-w-512 w-full h-full m-auto">
        <PageHeader
          className="self-center flex flex-col p-5"
          title={
            <div>
              <Title data-testid="title" className="justify-center flex flex-row m-0" level={3}>
                <Trans i18nKey="global:welcome.greetingMessage" values={{ user: currentUser?.firstName }} />
              </Title>
              <Title data-testid="secondaryTitle" type="secondary" className="my-2" level={5}>
                <Trans i18nKey="global:welcome.planningMessage" />
              </Title>
            </div>
          }
        />
        <List
          className="px-4"
          grid={{
            gutter: 16,
            lg: 3,
            xl: 3,
            xxl: 3,
            sm: 2,
            xs: 1,
            md: 3,
          }}
          dataSource={Object.values(moduleConfigurations)}
          renderItem={(item) => {
            const moduleAccess = hasModuleAccess(item.objectType);
            const action = moduleAccess ? (
              <Tooltip title={<Trans i18nKey="actions:global.create" />}>
                <WrapInLink path={item[RouteTypes.CREATE]?.route.path ?? item.route.path} key={item.objectType}>
                  <PlusOutlined />
                </WrapInLink>
              </Tooltip>
            ) : (
              <Tooltip title={<Trans i18nKey="global:links.exploreMore" />}>
                <ExternalLink href={item.externalSource}>
                  <Trans i18nKey="global:links.exploreMore" />
                </ExternalLink>
              </Tooltip>
            );
            return (
              <List.Item className="flex h-full w-full">
                <Card
                  data-testid={`cardId-${item.objectType}`}
                  className="flex flex-col w-full h-full"
                  cover={
                    <WrapInLink
                      path={moduleAccess && item.route.path}
                      className="flex justify-center p-8 border border-solid b--grey-200"
                    >
                      {item.icon}
                    </WrapInLink>
                  }
                  actions={[action]}
                >
                  <WrapInLink path={moduleAccess && item.route.path}>
                    <Meta title={item.name} description={item.description} />
                  </WrapInLink>
                </Card>
              </List.Item>
            );
          }}
        />
      </div>
    </div>
  );
};

export default Welcome;
