import { createReducerContext } from 'react-use';

import { ConversationHub } from 'core/lib/utils/signalR';

type ActionType = 'connect' | 'connect-start' | 'disconnect';

type Action = {
  type: ActionType;
  payload: any; //eslint-disable-line  @typescript-eslint/no-explicit-any
};

type HubState = {
  conversations: ConversationHub;
};

const reducer = (state: HubState, action: Action) => {
  switch (action.type) {
  }

  return state;
};

export const [useHub, HubProvider] = createReducerContext(reducer, {
  conversations: new ConversationHub(),
});
