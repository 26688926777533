import React, { useMemo } from 'react';

import { Avatar, Typography } from 'antd';
import { AvatarSize } from 'antd/lib/avatar/SizeContext';
import { useTranslation } from 'react-i18next';

import { BaseUser } from 'core/lib';

import UserAvatar from './userAvatar';

const DEFAULT_MAX = 2;

const { Text } = Typography;

type UserAvatarGroupProps = {
  users?: BaseUser[];
  maxCount?: number;
  size?: AvatarSize;
};

const UserAvatarGroup = ({ maxCount, users, size = 'default' }: UserAvatarGroupProps) => {
  const { t } = useTranslation();

  const userAvatarGroup = useMemo(() => {
    return users?.map((user) => (
      <UserAvatar withLink={false} key={user.id} user={user} size={size} iconOnly className="inline-block" />
    ));
  }, [users, size]);

  return users?.length ? (
    <Avatar.Group className="flex cursor-default" size={size} maxCount={maxCount ?? DEFAULT_MAX}>
      {userAvatarGroup}
    </Avatar.Group>
  ) : (
    <Text type="secondary">{t('global:placeholders.noMembers')}</Text>
  );
};

export default UserAvatarGroup;
