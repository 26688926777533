import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';

import { BaseUser } from 'core/lib';
import AuthenticateSerivce from 'core/lib/modules/auth/services/auth.service';

const { REACT_APP_LOGROCKET, NODE_ENV } = process.env;

if (REACT_APP_LOGROCKET && NODE_ENV !== 'test') {
  LogRocket.init(REACT_APP_LOGROCKET!, {
    network: {
      requestSanitizer: (request) => {
        request.headers['Authorization'] = '';
        return request;
      },
      responseSanitizer: (response) => {
        response.body = undefined;
        return response;
      },
    },
  });
}

export const setLogrocketIdentity = (user: BaseUser) => {
  if (LogRocket.sessionURL) {
    LogRocket.identify(`${AuthenticateSerivce.getCookiesClient()}-${user.id}`, {
      name: user.name,
      email: user.email!,

      client: AuthenticateSerivce.getCookiesClient(),
    });
  }
};

setupLogRocketReact(LogRocket);
