import React from 'react';

import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

import { Listing, ListingHeader } from 'common-ui/containers';

import { CreateModuleConversationModal } from '../components';

type ListingContainerProps = {
  children: React.ReactNode;
  count: number;
};

const ListingContainer = ({ children, count }: ListingContainerProps) => {
  const { t } = useTranslation();
  return (
    <>
      <ListingHeader
        count={count}
        title={t('global:moduleNames.myconversations')}
        extra={[
          <CreateModuleConversationModal
            key="create-conversation"
            trigger={
              <Button key="create-conversation" type="primary">
                {t('conversations:actions.createConversation')}
              </Button>
            }
          />,
        ]}
      />
      <Listing>{children}</Listing>
    </>
  );
};

export default ListingContainer;
