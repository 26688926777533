import React from 'react';

import { Typography } from 'antd';

const { Text } = Typography;

type LeftMenuHeaderProps = {
  title: string;
};

const LeftMenuHeader = ({ title }: LeftMenuHeaderProps) => {
  return <Text strong>{title.toUpperCase()}</Text>;
};

export default LeftMenuHeader;
