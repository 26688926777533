import React, { useEffect, useMemo, useState } from 'react';

import { FilterFilled } from '@ant-design/icons';
import { Badge, Button, Collapse, Divider, Modal, Space, Typography } from 'antd';
import { isEqual, mapValues, omit } from 'lodash';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps, useSelector } from 'react-redux';
import { useMedia } from 'react-use';

import { CommonObjectFilter } from 'common-ui/filters';
import {
  AppState,
  clearContactBookFilters,
  ContactBookFilters,
  selectContactBookFilters,
  setContactBookFilters,
  selectFiltersCount,
} from 'core/lib';
import { DYNAMIC, OBJECT_TYPES } from 'core/lib/constants';

import CountryFilter from './countryFilter';
import DiligenceTypeFilter from './diligenceTypeFilter';
import InvestmentGeographyFilter from './investmentGeographyFilter';
import RegionFilter from './regionFilter';
import SharedCasesFilter from './sharedCasesFilter';
import ContactBookTagsFilter from './tagsFilter';
import ContactBookTypeFilter from './typeFilter';

const { Panel } = Collapse;
const { Title } = Typography;

const mapState = (state: AppState) => ({
  filters: selectContactBookFilters(state),
});

const mapDispatch = {
  applyFilters: setContactBookFilters,
  clearFilters: clearContactBookFilters,
};

const connector = connect(mapState, mapDispatch);

type StatusFiltersProps = ConnectedProps<typeof connector>;

const ListingFilters = ({ applyFilters, clearFilters, filters }: StatusFiltersProps) => {
  const [draftFilters, setDraftFilters] = useState<Partial<ContactBookFilters>>(filters);
  const [modalOpen, toggleModal] = useState(false);

  const isMobile = useMedia('(max-width: 768px)');
  const { t } = useTranslation();
  const filtersCount = useSelector(selectFiltersCount(isMobile));

  useEffect(() => {
    setDraftFilters(filters);
  }, [filters, modalOpen]);

  const showModal = () => {
    toggleModal(true);
  };

  const handleOk = () => {
    applyFilters(draftFilters);
    toggleModal(false);
  };

  const clearFilterKeys = useMemo(() => {
    const filterKeys = omit(filters, [
      'tagsOperator',
      'sharedCasesOperator',
      'investmentGeographyOperator',
      'industryOperator',
    ]);
    if (isMobile) {
      return Object.keys(filterKeys);
    }
    return Object.keys(omit(filterKeys, ['type', 'tags']));
  }, [isMobile, filters]);

  const onClearModalClear = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setDraftFilters(mapValues(filters, (value, key) => (clearFilterKeys.includes(key) ? undefined : (value as any))));
  };

  const onClear = () => {
    clearFilters();
  };

  const onCancel = () => {
    toggleModal(false);
  };

  const onFiltersChange = (partialFilters: Partial<ContactBookFilters>) => {
    setDraftFilters({ ...draftFilters, ...partialFilters });
  };

  const filtersUpdated = useMemo(() => !isEqual(draftFilters, filters), [draftFilters, filters]);

  return (
    <>
      <Badge count={filtersCount}>
        <Button type="default" onClick={showModal} icon={<FilterFilled />}>
          {t('contactBook:filters.filters')}
        </Button>
      </Badge>
      <Button onClick={onClear} type="text">
        {t('actions:global.clearAll')}
      </Button>
      <Modal
        width="auto"
        style={{ maxWidth: 600 }}
        bodyStyle={{ overflowY: 'scroll', height: '50vh' }}
        title={t('global:filters.moreFilters')}
        open={modalOpen}
        onCancel={onCancel}
        footer={
          <div>
            <Button onClick={onClearModalClear} type="text">
              {t('actions:global.clearAll')}
            </Button>
            <Button disabled={!filtersUpdated} onClick={handleOk} type="primary">
              {t('actions:global.apply')}
            </Button>
          </div>
        }
      >
        <div>
          {isMobile && (
            <>
              <ContactBookTypeFilter onChange={onFiltersChange} filters={draftFilters.type} />
              <Divider />
              <ContactBookTagsFilter onChange={onFiltersChange} filters={draftFilters.tags} />
              <Divider />
            </>
          )}
          <RegionFilter onChange={onFiltersChange} filters={draftFilters.region} />
          <Divider />
          <CountryFilter onChange={onFiltersChange} filters={draftFilters.country} />
          <Divider />
          <Space className="w-full" direction="vertical">
            <Collapse className="flex flex-col gap-4" bordered={false}>
              <Panel
                header={<Title level={5}>{t('contactBook:filters.group.byVentureCapital')}</Title>}
                key="ventureCapital"
              >
                <CommonObjectFilter<ContactBookFilters>
                  title={t('contactBook:filters.stage')}
                  filterKey="stage"
                  type={DYNAMIC.CONTACT_BOOK.STAGE}
                  objectType={OBJECT_TYPES.CONTACT_BOOK}
                  onChange={onFiltersChange}
                  filters={draftFilters.stage}
                />
                <Divider />
                <CommonObjectFilter<ContactBookFilters>
                  title={t('contactBook:filters.investmentFocus')}
                  filterKey="investmentFocus"
                  type={DYNAMIC.CONTACT_BOOK.INVESTMENT_FOCUS}
                  objectType={OBJECT_TYPES.CONTACT_BOOK}
                  onChange={onFiltersChange}
                  filters={draftFilters.investmentFocus}
                />
                <Divider />
                <SharedCasesFilter
                  onChange={onFiltersChange}
                  filters={draftFilters.sharedCases}
                  operator={draftFilters.sharedCasesOperator!}
                  operatorKey="sharedCasesOperator"
                />
                <Divider />
                <InvestmentGeographyFilter
                  onChange={onFiltersChange}
                  filters={draftFilters.investmentGeography}
                  operator={draftFilters.investmentGeographyOperator!}
                  operatorKey="investmentGeographyOperator"
                />
              </Panel>
            </Collapse>
            <Collapse className="flex flex-col gap-4" bordered={false}>
              <Panel
                header={<Title level={5}>{t('contactBook:filters.group.byIndustryExpert')}</Title>}
                key="industryExpert"
              >
                <CommonObjectFilter<ContactBookFilters>
                  title={t('contactBook:filters.industry')}
                  filterKey="industry"
                  type={DYNAMIC.CONTACT_BOOK.INDUSTRY}
                  objectType={OBJECT_TYPES.CONTACT_BOOK}
                  onChange={onFiltersChange}
                  filters={draftFilters.industry}
                  withOperator
                  operator={draftFilters.industryOperator}
                  operatorKey="industryOperator"
                />
              </Panel>
            </Collapse>
            <Collapse className="flex flex-col gap-4" bordered={false}>
              <Panel
                header={<Title level={5}>{t('contactBook:filters.group.byDiligencePartner')}</Title>}
                key="diligencePartner"
              >
                <DiligenceTypeFilter onChange={onFiltersChange} filters={draftFilters.diligenceType} />
              </Panel>
            </Collapse>
          </Space>
        </div>
      </Modal>
    </>
  );
};

export default connector(ListingFilters);
