import React from 'react';

import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Divider, Form, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { CommonObjectOption } from 'core/lib';
import theme from 'core/lib/theme';
import { dealBaseFormConfig } from 'modules/dealBase/form/formConfig';

const { Title } = Typography;

type EditRevenueSectionProps = {
  editMode: boolean;
};

const {
  metrics: {
    revenue: { revenueProjection, revenueYear, revenueType },
  },
} = dealBaseFormConfig;

const DealBaseEditRevenueSection = ({ editMode }: EditRevenueSectionProps) => {
  const { t } = useTranslation('dealbase');

  return (
    <div>
      <Form.Item
        extra={revenueType.help}
        label={revenueType.label}
        name={revenueType.dataIndex}
        rules={revenueType.rules}
        valuePropName="selectedOption"
        normalize={(option?: CommonObjectOption) => {
          return option ? { id: option.id, name: option.label } : null;
        }}
      >
        <revenueType.component disabled={!editMode} />
      </Form.Item>
      <div className="flex sm:flex-row flex-col flex-auto gap-8">
        <div className="flex flex-col w-full md:w-3/6">
          <Title style={{ color: theme.colors.Primary.rgba }} level={5}>
            {t('form.metrics.headers.actuals')}
          </Title>
          <Form.List name={revenueYear.dataIndex} rules={revenueYear.rules}>
            {(revenueYears, { add, remove }) => {
              return (
                <div className="flex flex-col">
                  <Form.Item>
                    {revenueYears.map(({ key, name, ...restField }) => {
                      return (
                        <div key={key} className="flex flex-col">
                          <div className="flex items-center flex-row gap-x-4">
                            <div className="flex flex-auto sm:flex-row flex-col gap-x-4">
                              <Form.Item
                                {...restField}
                                className="md:w-3/6"
                                label={revenueYear.year.label}
                                rules={revenueYear.year.rules}
                                name={[name, revenueYear.year.dataIndex]}
                              >
                                <revenueYear.year.component disabled={!editMode} />
                              </Form.Item>
                              <Form.Item
                                {...restField}
                                className="md:w-3/6"
                                label={revenueYear.amount.label}
                                rules={revenueYear.amount.rules}
                                name={[name, revenueYear.amount.dataIndex]}
                              >
                                <revenueYear.amount.component disabled={!editMode} />
                              </Form.Item>
                            </div>
                            <MinusCircleOutlined onClick={() => remove(name)} />
                          </div>
                          {revenueYears.length > 1 && <Divider />}
                        </div>
                      );
                    })}
                  </Form.Item>
                  {editMode && (
                    <Form.Item>
                      <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                        {t('actions.addYear')}
                      </Button>
                    </Form.Item>
                  )}
                </div>
              );
            }}
          </Form.List>
        </div>
        <div className="flex flex-col w-full md:w-3/6">
          <Title style={{ color: theme.colors.Primary.rgba }} level={5}>
            {t('form.metrics.headers.forecast')}
          </Title>
          <Form.List name={revenueProjection.dataIndex} rules={revenueProjection.rules}>
            {(revenueProjections, { add, remove }) => {
              return (
                <div className="flex flex-col">
                  <Form.Item>
                    {revenueProjections.map(({ key, name, ...restField }) => {
                      return (
                        <div key={key} className="flex flex-col">
                          <div className="flex items-center flex-row gap-x-4">
                            <div className="flex flex-auto sm:flex-row flex-col gap-x-4">
                              <Form.Item
                                {...restField}
                                className="md:w-3/6"
                                label={revenueProjection.year.label}
                                rules={revenueProjection.year.rules}
                                name={[name, revenueProjection.year.dataIndex]}
                              >
                                <revenueProjection.year.component disabled={!editMode} />
                              </Form.Item>
                              <Form.Item
                                {...restField}
                                className="md:w-3/6"
                                label={revenueProjection.amount.label}
                                rules={revenueProjection.amount.rules}
                                name={[name, revenueProjection.amount.dataIndex]}
                              >
                                <revenueProjection.amount.component disabled={!editMode} />
                              </Form.Item>
                            </div>
                            <MinusCircleOutlined onClick={() => remove(name)} />
                          </div>
                          {revenueProjections.length > 1 && <Divider />}
                        </div>
                      );
                    })}
                  </Form.Item>
                  {editMode && (
                    <Form.Item>
                      <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                        {t('actions.addYear')}
                      </Button>
                    </Form.Item>
                  )}
                </div>
              );
            }}
          </Form.List>
        </div>
      </div>
    </div>
  );
};

export default DealBaseEditRevenueSection;
