import React from 'react';

import { Select } from 'antd';
import { omit } from 'lodash';
import { useTranslation } from 'react-i18next';

import { commonObjectsApi, FilterOperator } from 'core/lib';
import LoaderWrapper from 'utils/loader-wrapper';

import FilterOperatorComponent from './filterOperator';
import FiltersContainer from './filtersContainer';
import FiltersTitle from './filtersTitle';

type CommonObjectFilterProps<T> = {
  type: string;
  filterKey: string;
  title: string;
  objectType: number;
  onChange: (filters: Partial<T>) => void;
  filters: number[] | undefined;
  withOperator?: boolean;
  operatorKey?: string;
  operator?: FilterOperator;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function CommonObjectFilter<T extends { [x: string]: any }>({
  onChange,
  filters,
  filterKey,
  title,
  type,
  objectType,
  withOperator,
  operatorKey,
  operator,
}: CommonObjectFilterProps<T>) {
  const { t } = useTranslation();

  const { data, isFetching } = commonObjectsApi.useGetCommonObjectOptionsQuery(
    {
      objectType,
      type,
    },
    { selectFromResult: ({ data, ...rest }) => ({ ...rest, data: data?.map((d) => omit(d, 'disabled')) }) }
  );

  return (
    <FiltersContainer>
      <FiltersTitle title={title} />
      <LoaderWrapper loading={isFetching} message={t('loaders:filters.loadingFilters')}>
        {withOperator && <FilterOperatorComponent onChange={onChange} operator={operator} filterKey={operatorKey!} />}
        <Select
          mode="multiple"
          className="w-full"
          allowClear
          showSearch={false}
          options={data}
          placeholder={t('global:placeholders.selectType', { type: title })}
          value={filters}
          onChange={(commonObjectIds) => onChange({ [filterKey]: commonObjectIds } as Partial<T>)}
        />
      </LoaderWrapper>
    </FiltersContainer>
  );
}

export default CommonObjectFilter;
