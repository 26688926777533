import i18n from '../../utils/i18n';

export const OverallRiskPriorityOptions = [
  { label: i18n.t('global:options.yes'), value: 'yes' },
  { label: i18n.t('global:options.no'), value: 'no' },
  { label: i18n.t('global:options.n/a'), value: 'n/a' },
];
export const PatientSafetyOptions = [
  { label: i18n.t('global:options.yes'), value: 'yes' },
  { label: i18n.t('global:options.no'), value: 'no' },
  { label: i18n.t('global:options.n/a'), value: 'n/a' },
];

export const ProductQualityOptions = [
  { label: i18n.t('global:options.yes'), value: 'yes' },
  { label: i18n.t('global:options.no'), value: 'no' },
  { label: i18n.t('global:options.n/a'), value: 'n/a' },
];

export const DataIntegrityOptions = [
  { label: i18n.t('global:options.yes'), value: 'yes' },
  { label: i18n.t('global:options.no'), value: 'no' },
  { label: i18n.t('global:options.n/a'), value: 'n/a' },
];

export const ComplexityOptions = [
  { label: i18n.t('global:options.yes'), value: 'yes' },
  { label: i18n.t('global:options.no'), value: 'no' },
  { label: i18n.t('global:options.n/a'), value: 'n/a' },
];

export const GampCategoryOptions = [
  { label: '1', value: '1' },
  { label: '3', value: '3' },
  { label: '4', value: '4' },
  { label: '5', value: '5' },
];

export const ConfidentialityOptions = [
  { label: i18n.t('global:options.yes'), value: 'yes' },
  { label: i18n.t('global:options.no'), value: 'no' },
  { label: i18n.t('global:options.n/a'), value: 'n/a' },
];

export const IntegrityOptions = [
  { label: i18n.t('global:options.yes'), value: 'yes' },
  { label: i18n.t('global:options.no'), value: 'no' },
  { label: i18n.t('global:options.n/a'), value: 'n/a' },
];

export const AvailabilityOptions = [
  { label: i18n.t('global:options.yes'), value: 'yes' },
  { label: i18n.t('global:options.no'), value: 'no' },
  { label: i18n.t('global:options.n/a'), value: 'n/a' },
];
