import React, { useMemo, useState, useRef } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import { Button, Input, Popover, List } from 'antd';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router-dom';
import { useMedia } from 'react-use';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList, ListChildComponentProps } from 'react-window';

import { UserAvatar } from 'common-ui';
import {
  conversationsApi,
  CreateDirectConversationRequest,
  setLeftMenuToggled,
  useAppDispatch,
  userApi,
} from 'core/lib';
import { fuzzyWordsSearch } from 'core/lib/utils';
import { Routes } from 'routes/routes';
import { useLoggedInUser } from 'utils/hooks';
import LoaderWrapper from 'utils/loader-wrapper';

const CreateDirectConversationModal = () => {
  const [search, setSearch] = useState<string>('');
  const { t } = useTranslation();
  const history = useHistory();
  const isTablet = useMedia('(max-width: 1023px)');
  const dispatch = useAppDispatch();

  const [getUsers, { data = [], isLoading: loadingUsers }] = userApi.useLazyGetUsersShortQuery();

  const currentUser = useLoggedInUser();

  const triggerRef = useRef<HTMLDivElement>(null);

  const filteredUserOptions = useMemo(
    () => data.filter((option) => option.id !== currentUser?.id),
    [currentUser, data]
  );

  const searchResultData = useMemo(() => {
    return filteredUserOptions.filter((user) => fuzzyWordsSearch(search, user.name));
  }, [search, filteredUserOptions]);

  const [createConversation, { isLoading }] = conversationsApi.useCreateDirectConversationMutation();

  const onCreate = (directConv: CreateDirectConversationRequest) => {
    createConversation(directConv)
      .unwrap()
      .then((directConvId) => {
        triggerRef.current?.click();
        if (isTablet) {
          dispatch(setLeftMenuToggled(false));
        }
        history.push(generatePath(Routes.CONVERSATIONS_DM_DETAILS.path, { id: directConvId }));
      });
  };

  const onUserDropdown = (open: boolean) => {
    if (open) {
      getUsers(undefined, true);
    }
  };

  const userListItemRenderer = ({ index, data, style }: ListChildComponentProps) => {
    const user = data[index];
    return (
      <List.Item
        className={`px-2 cursor-pointer hover:bg-gray-50`}
        key={user.id}
        style={style}
        onClick={() => onCreate({ userId: user.id })}
      >
        <UserAvatar size="small" user={user} plain />
      </List.Item>
    );
  };

  return (
    <div>
      <Popover
        arrowPointAtCenter
        onOpenChange={onUserDropdown}
        content={
          <div className="flex flex-overflow-auto w-auto min-h-64 min-w-64 bg-white">
            <LoaderWrapper loading={loadingUsers}>
              <AutoSizer>
                {({ height, width }) => (
                  <List loading={isLoading} itemLayout="vertical">
                    <FixedSizeList
                      itemData={searchResultData}
                      width={width}
                      height={height}
                      itemCount={searchResultData.length}
                      itemSize={40}
                    >
                      {userListItemRenderer}
                    </FixedSizeList>
                  </List>
                )}
              </AutoSizer>
            </LoaderWrapper>
          </div>
        }
        title={
          <Input
            placeholder={t('global:placeholders.search')}
            onChange={({ target }) => setSearch(target.value)}
            value={search}
          />
        }
        trigger="click"
      >
        <Button icon={<PlusOutlined />} type="link" ref={triggerRef}>
          {t('conversations:actions.openDM')}
        </Button>
      </Popover>
    </div>
  );
};

export default CreateDirectConversationModal;
