import React from 'react';

import { List } from 'antd';
import { Link } from 'react-router-dom';
import { useMedia } from 'react-use';

import { setLeftMenuToggled, useAppDispatch } from 'core/lib';

type MenuItemWrapperProps = {
  isActive: boolean;
  style?: React.CSSProperties;
  path: string;
  children: React.ReactNode;
  linkClassName?: string;
  menuItemClassName?: string;
};

const MenuItemWrapper = ({
  isActive,
  style,
  path,
  linkClassName = '',
  menuItemClassName = '',
  children,
}: MenuItemWrapperProps) => {
  const dispatch = useAppDispatch();
  const isTablet = useMedia('(max-width: 1023px)');

  const setToggleLeftMenu = (isToggled: boolean) => dispatch(setLeftMenuToggled(isToggled));

  const toggleMobileLeftMenu = () => {
    if (isTablet) {
      setToggleLeftMenu(false);
    }
  };

  return (
    <List.Item
      className={`flex items-center justify-between gap-4 pl-4 pr-0 py-0 hover:bg-gray-50 ${
        isActive ? 'bg-gray-50' : ''
      } ${menuItemClassName}`}
      style={style}
    >
      <Link
        className={`flex flex-auto justify-between gap-4 h-full items-center overflow-hidden ${
          isActive ? 'text-primary' : 'text-black'
        } ${linkClassName}`}
        to={path}
        onClick={toggleMobileLeftMenu}
      >
        {children}
      </Link>
    </List.Item>
  );
};

export default MenuItemWrapper;
