import React from 'react';

import { useTranslation } from 'react-i18next';

import { CreateModuleConversationModal } from 'modules/conversations/components';

type MenuItemConversationCreateProps = {
  objectType: number;
  objectId?: number;
  objectName?: string;
};

const MenuItemConversationCreate = ({ objectType, objectId, objectName }: MenuItemConversationCreateProps) => {
  const { t } = useTranslation();

  const objectOption = objectId && objectName ? { value: objectId, label: objectName } : undefined;
  return (
    <CreateModuleConversationModal
      trigger={<div>{t('actions:global.createConversation')}</div>}
      objectType={objectType}
      objectOption={objectOption}
    />
  );
};

export default MenuItemConversationCreate;
