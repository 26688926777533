import { createSlice } from '@reduxjs/toolkit';

import { logout } from '../../auth';
import { ContactBookFilters } from '../entities';

type ContactBookState = {
  filters: ContactBookFilters;
};

const initialState: ContactBookState = {
  filters: {
    country: [],
    region: [],
    tagsOperator: 'OR',
    tags: [],
    type: [],
    industryOperator: 'OR',
    industry: [],
    investmentGeographyOperator: 'OR',
    investmentGeography: [],
    diligenceType: [],
    investmentFocus: [],
    sharedCasesOperator: 'OR',
    sharedCases: [],
    stage: [],
  },
};

export const contactBookSlice = createSlice({
  name: 'contactBook',
  initialState,
  reducers: {
    setContactBookFilters: (state, { payload }: { payload: Partial<ContactBookFilters> }) => {
      Object.assign(state.filters, payload);
    },
    clearContactBookFilters: (state) => {
      Object.assign(state.filters, { ...initialState.filters, status: [] });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout, () => {
      return initialState;
    });
  },
});

export const { setContactBookFilters, clearContactBookFilters } = contactBookSlice.actions;

export default contactBookSlice.reducer;
