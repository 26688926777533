import React from 'react';

import { Tag } from 'antd';

import { UserRole } from 'core/lib';
import i18n from 'core/lib/utils/i18n';

export const SystemRoleTag = ({ systemRole }: { systemRole: UserRole }) => <Tag color="green">{systemRole.name}</Tag>;

export const StatusTag = ({ status }: { status: string | React.ReactNode }) => <Tag color="blue">{status}</Tag>;

export const ExternalUserTag = () => <Tag color="blue">{i18n.t('dealbase:labels.external') as string}</Tag>;

export { default as CountryTag } from './countryTag';
export { default as ModuleTag } from './moduleTag';
export { default as TagsGroup } from './tagsGroup';
