import { createApi } from '@reduxjs/toolkit/query/react';

import { endpoints, TAGS } from './endpoints';
import { dynamicBaseQuery } from '../../../utils/requesting';
import { cacher } from '../../../utils/rtkQueryCacheUtils';
import { UserFavorite, Favorite, FavoriteRequest, FavoriteResponse } from '../entities';

const baseApi = createApi({
  reducerPath: 'baseApi',
  baseQuery: dynamicBaseQuery,
  tagTypes: [...cacher.defaultTags, ...Object.values(TAGS)],
  endpoints: (build) => ({
    createAuditLog: build.query<number, { objectId: number; objectType: number }>({
      query: (params) => ({ url: endpoints.createAuditLog.url, params }),
    }),
    getFavorites: build.query<UserFavorite[], void>({
      query: () => endpoints.getFavorites.url,
      providesTags: cacher.providesList(endpoints.getFavorites.tag),
      transformResponse: endpoints.getFavorites.deserializer,
    }),
    getFavoriteById: build.query<Favorite, { id: number; objectType: number }>({
      query: (params) => ({
        url: endpoints.getFavoriteById.url,
        params,
      }),
      providesTags: cacher.cacheByIdArgProperty(endpoints.getFavoriteById.tag),
      transformResponse: endpoints.getFavoriteById.deserializer,
    }),
    setFavorite: build.mutation<FavoriteResponse, FavoriteRequest>({
      query: (fav) => ({
        method: 'POST',
        url: endpoints.setFavorite.url,
        body: fav,
      }),
      invalidatesTags: cacher.invalidatesMultipleLists(endpoints.setFavorite.invalidates),
      async onQueryStarted({ objectId }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(baseApi.util.invalidateTags([{ type: TAGS.FavoriteDetails, id: objectId }]));
        } catch {}
      },
    }),
    downloadBase64: build.query<string, number>({
      query: (id: number) => ({ url: endpoints.downloadBase64.url, params: { id }, responseHandler: 'text' }),
    }),
    download: build.query<string, number>({
      query: (id: number) => ({
        method: 'GET',
        url: endpoints.download.url,
        params: { id },
      }),
    }),
    postUpload: build.mutation<number, FormData>({
      query: (data) => ({
        method: 'POST',
        data,
        url: endpoints.postUpload.url,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }),
    }),
  }),
});

export default baseApi;
