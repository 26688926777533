import { isMobile } from 'react-device-detect';

export * from './textBadges';
export * from './lists';
export * from './wrappers';
export * from './styled';
export * from './links';
export * from './inputs';
export * from './calendar';
export * from './datePicker';
export * from './cells';
export * from './detailsSectionCollapse';
export * from './tags';
export * from './favorite';
export * from './dates';
export * from './conversation';
export * from './filters';
export * from './leftMenu';
export { default as ClientLogo } from './clientLogo';

export const DROPDOWN_TRIGGERS: ('click' | 'hover')[] = isMobile ? ['click'] : ['click'];
