import React from 'react';

import { LockOutlined } from '@ant-design/icons';
import { PageHeader, Typography } from 'antd';
import { useMedia } from 'react-use';

import { UserAvatar } from 'common-ui';
import { UserDetails } from 'core/lib';
import colors from 'core/lib/theme/colors';

import DetailsHeaderActions from './detailsHeaderActions';

const { Title } = Typography;

type UserPageHeaderProps = {
  user: UserDetails;
  onBack: () => void;
};

const DetailsPageHeader = ({ user, onBack }: UserPageHeaderProps) => {
  const isMobile = useMedia('(max-width: 768px)');
  const size = isMobile ? 'small' : 'middle';
  const userAvatarSize = isMobile ? 'small' : 'default';
  const textLevel = isMobile ? 5 : 4;
  return (
    <PageHeader
      className="inherit-wrap"
      onBack={onBack}
      title={
        <div className="flex flex-row items-center gap-2">
          <div className="flex justify-center">
            <UserAvatar active={user.status === 'active'} plain iconOnly user={user} size={userAvatarSize} />
          </div>
          <Title className="ws-normal md:truncate m-0" level={textLevel}>
            {user.name}
          </Title>
          <div className="flex">
            <Title className="m-0" type="secondary" level={5}>{`#${user.id}`}</Title>
          </div>
          {user.locked && <LockOutlined style={{ color: colors.Primary.rgba }} />}
        </div>
      }
      extra={[<DetailsHeaderActions size={size} user={user} key="actionsMenu" />]}
    />
  );
};

export default DetailsPageHeader;
