import { deserializerUserNotificationPreferences, deserializerUserPreferences } from './serializers';

const API_V2 = 'api/v2';

const CONTROLLER = 'userPreferences';

const endpoints = {
  getPreferences: {
    url: `${API_V2}/${CONTROLLER}/getUserPreferences`,
    deserializer: deserializerUserPreferences,
  },
  updateUserPreferences: {
    url: `${API_V2}/${CONTROLLER}/updateUserPreferences`,
  },
  getUserNotificationPreferences: {
    url: `${API_V2}/${CONTROLLER}/getUserNotificationPreferences`,
    deserializer: deserializerUserNotificationPreferences,
  },
  updateUserNotificationPreferences: {
    url: `${API_V2}/${CONTROLLER}/updateUserNotificationPreferences`,
  },
};

export default endpoints;
