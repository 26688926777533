import React from 'react';

import { Form, SelectProps } from 'antd';

import { UserOption } from 'core/lib';
import UserSelect, { UserSelectProps } from 'utils/form/userSelect';

import { ListingEditableCellProps } from './types';

function ListingUserSelect({
  dataIndex,
  rules,
  ...props
}: ListingEditableCellProps & UserSelectProps & SelectProps<number>) {
  return (
    <Form.Item
      hasFeedback
      rules={rules}
      name={dataIndex}
      className="m-0"
      valuePropName="selectedOption"
      normalize={(option?: UserOption) => {
        return option ? { id: option.value, name: option.label } : null;
      }}
    >
      <UserSelect {...props} />
    </Form.Item>
  );
}

export default ListingUserSelect;
