import React, { useContext, useEffect } from 'react';

import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useMedia } from 'react-use';

import { ContentContainer, MainContentContainer, SideContentContainer } from 'common-ui/containers';
import { DetailsSectionCollapse, DetailsSectionPanel } from 'common-ui/detailsSectionCollapse';
import { baseApi, DealBase } from 'core/lib';
import { OBJECT_TYPES } from 'core/lib/constants';
import { DealBaseDetailsContext } from 'modules/dealBase/context';
import { downloadBase64File } from 'utils';

import { GeneralInformationSection, OverviewSection, ContactDetailsSection, PitchDeckSection } from './sections';
import { tabSections } from '../../form/formKeys';
import { DealBaseTabProps } from '../../types';

const defaultSections = Object.values(tabSections.generalInformation).map((section) => ({
  name: section.key,
  collapsed: true,
}));

const DealBaseGeneralTab = ({ editMode, isNew }: DealBaseTabProps) => {
  const { t } = useTranslation('dealbase');
  const isTablet = useMedia('(max-width: 1023px)');

  const dealbase = useContext(DealBaseDetailsContext) as DealBase;

  const [getPitchDeckDocument, { data }] = baseApi.useLazyDownloadBase64Query();

  useEffect(() => {
    if (dealbase.pitchDeckDocument?.id) {
      getPitchDeckDocument(dealbase.pitchDeckDocument?.id);
    }
  }, [dealbase.pitchDeckDocument?.id, getPitchDeckDocument]);

  return (
    <MainContentContainer id="dealbase-general">
      <ContentContainer withSide={!isNew}>
        <DetailsSectionCollapse
          defaultSections={defaultSections}
          keepDefault={isNew}
          objectType={OBJECT_TYPES.DEAL_BASE}
        >
          <DetailsSectionPanel
            header={t('sections.overview')}
            sectionKey={tabSections.generalInformation.overview?.key!}
            key={tabSections.generalInformation.overview?.key!}
            forceRender={editMode}
          >
            <OverviewSection editMode={editMode} />
          </DetailsSectionPanel>
          <DetailsSectionPanel
            header={t('sections.pitchDeck')}
            key={tabSections.generalInformation.pitchDeck?.key!}
            sectionKey={tabSections.generalInformation.pitchDeck?.key!}
            forceRender={editMode}
            maxWidthDisabled
            extra={
              dealbase.pitchDeckDocument && (
                <Button
                  onClick={(event: React.MouseEvent) => {
                    event.stopPropagation();
                    if (data && dealbase.pitchDeckDocument?.name) {
                      downloadBase64File(data, dealbase.pitchDeckDocument?.name);
                    }
                  }}
                  size="small"
                  type="primary"
                >
                  {t('actions:global.download')}
                </Button>
              )
            }
          >
            <PitchDeckSection editMode={editMode} />
          </DetailsSectionPanel>
          {isTablet && (
            <DetailsSectionPanel
              header={t('sections.generalInformation')}
              key={tabSections.generalInformation.generalInformation?.key!}
              sectionKey={tabSections.generalInformation.generalInformation?.key!}
              forceRender={editMode}
            >
              <GeneralInformationSection editMode={editMode} />
            </DetailsSectionPanel>
          )}
          {isTablet && (
            <DetailsSectionPanel
              header={t('sections.contactDetails')}
              key={tabSections.generalInformation.contactDetails?.key!}
              sectionKey={tabSections.generalInformation.contactDetails?.key!}
            >
              <ContactDetailsSection editMode={editMode} />
            </DetailsSectionPanel>
          )}
        </DetailsSectionCollapse>
      </ContentContainer>
      {!isTablet && (
        <SideContentContainer>
          <DetailsSectionCollapse
            defaultSections={defaultSections}
            keepDefault={isNew}
            objectType={OBJECT_TYPES.DEAL_BASE}
          >
            <DetailsSectionPanel
              header={t('sections.generalInformation')}
              key={tabSections.generalInformation.generalInformation?.key!}
              sectionKey={tabSections.generalInformation.generalInformation?.key!}
              forceRender={editMode}
            >
              <GeneralInformationSection editMode={editMode} />
            </DetailsSectionPanel>
            <DetailsSectionPanel
              header={t('sections.contactDetails')}
              key={tabSections.generalInformation.contactDetails?.key!}
              sectionKey={tabSections.generalInformation.contactDetails?.key!}
            >
              <ContactDetailsSection editMode={editMode} />
            </DetailsSectionPanel>
          </DetailsSectionCollapse>
        </SideContentContainer>
      )}
    </MainContentContainer>
  );
};

export default DealBaseGeneralTab;
