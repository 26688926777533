import React, { useEffect, useState } from 'react';

import { PageHeader, Tabs, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { selectCustomFieldsMenuId, useAppSelector } from 'core/lib';
import { MODULE_DYNAMIC, MODULE_NAMES } from 'core/lib/constants';

import CustomFieldTabContent from './tabContent';

const { Title } = Typography;

const CustomFields = () => {
  const { t } = useTranslation();
  const customFieldMenuItem = useAppSelector(selectCustomFieldsMenuId);

  const customTypes = Object.values(MODULE_DYNAMIC[customFieldMenuItem] ?? {});
  const history = useHistory();
  const [tabActiveKey, setActiveKey] = useState(customTypes[0]);

  useEffect(() => {
    setActiveKey(customTypes[0]);
  }, [customFieldMenuItem]); //eslint-disable-line react-hooks/exhaustive-deps

  const onTabClick = (tabKey: string) => {
    setActiveKey(tabKey);
  };

  const onPageHeaderBackClick = () => {
    history.goBack();
  };

  return (
    <>
      <PageHeader
        className="listing-header"
        onBack={onPageHeaderBackClick}
        title={
          <div className="flex flex-row gap-4 items-baseline">
            <Title style={{ margin: 0 }} level={4}>
              {t('global:moduleNames.customFields')}
            </Title>
            <Title style={{ margin: 0 }} type="secondary" level={5}>
              {MODULE_NAMES[customFieldMenuItem]}
            </Title>
          </div>
        }
      />
      <Tabs
        size="small"
        type="line"
        activeKey={tabActiveKey}
        onTabClick={onTabClick}
        className="h-full  overflow-y-auto tabs-centered"
        items={customTypes.map((type) => ({
          key: type,
          label: type,
          destroyInactiveTabPane: true,
          className: 'overflow-auto h-full',
          children: <CustomFieldTabContent type={type} objectType={customFieldMenuItem} />,
        }))}
      />
    </>
  );
};

export default CustomFields;
