import React from 'react';

import { ContactBookListing } from 'core/lib';

import DefaultPopover from './default';
import DiligencePartnerPopover from './diligencePartner';
import IndustryExpertPopover from './industryExpert';
import VentureCapitalPopover from './ventureCapital';

type ContactBookPopoverWrapperProps = {
  contactBook: ContactBookListing;
  children: React.ReactNode;
};

const WrapperByType = {
  VentureCapital: VentureCapitalPopover,
  IndustryExpert: IndustryExpertPopover,
  DiligencePartner: DiligencePartnerPopover,
};

const ContactBookPopoverWrapper = ({ children, contactBook }: ContactBookPopoverWrapperProps) => {
  const PopoverElement = WrapperByType[contactBook.type] ?? DefaultPopover;

  return <PopoverElement contactBook={contactBook}>{children}</PopoverElement>;
};

export default ContactBookPopoverWrapper;
