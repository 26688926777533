import { createApi } from '@reduxjs/toolkit/query/react';

import { dynamicBaseQuery } from 'core/lib/utils/requesting';

import endpoints, { TAGS } from './endpoints';
import { cacher } from '../../../utils/rtkQueryCacheUtils';
import { Organization, OrganizationListing, OrganizationOption } from '../entities';

const organizationApi = createApi({
  reducerPath: 'organizationApi',
  baseQuery: dynamicBaseQuery,
  tagTypes: [...cacher.defaultTags, ...Object.values(TAGS)],
  endpoints: (build) => ({
    getListing: build.query<OrganizationListing[], void>({
      query: () => endpoints.listing.url,
      providesTags: cacher.providesList(endpoints.listing.tag),
      transformResponse: endpoints.listing.deserializer,
    }),
    getDetails: build.query<Organization, string>({
      query: (id) => ({ url: endpoints.details.url, params: { id } }),
      providesTags: cacher.cacheByIdArg(endpoints.details.tag),
      transformResponse: endpoints.details.deserializer,
    }),
    getUserOrganizations: build.query<OrganizationOption[], void>({
      query: () => endpoints.userOrganizations.url,
      providesTags: cacher.providesList(endpoints.userOrganizations.tag),
      transformResponse: endpoints.userOrganizations.deserializer,
    }),
    create: build.mutation<void, Partial<Organization>>({
      query: (organization) => ({ method: 'POST', url: endpoints.create.url, body: { organization } }),
      invalidatesTags: cacher.invalidatesMultipleLists(endpoints.create.invalidates),
    }),
    update: build.mutation<void, Partial<Organization> & Pick<Organization, 'id'>>({
      query: (organization) => ({
        method: 'POST',
        url: endpoints.update.url,
        params: { id: organization.id },
        body: { organization },
      }),
      invalidatesTags: cacher.invalidatesMultipleLists(endpoints.update.invalidates),
    }),
    delete: build.mutation<void, number>({
      query: (id) => ({ method: 'DELETE', url: endpoints.delete.url, params: { id } }),
      invalidatesTags: cacher.invalidatesMultipleLists(endpoints.delete.invalidates),
    }),
  }),
});

export default organizationApi;
