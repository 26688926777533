import React from 'react';

import { Form, SelectProps } from 'antd';

import { UserOption } from 'core/lib';
import UserMultiSelect, { UserMultiSelectProps } from 'utils/form/userMultiSelect';

import { ListingEditableCellProps } from './types';

function ListingUserMultiSelect({
  dataIndex,
  rules,
  ...props
}: ListingEditableCellProps & UserMultiSelectProps & SelectProps<number[]>) {
  return (
    <Form.Item
      hasFeedback
      rules={rules}
      name={dataIndex}
      className="m-0"
      valuePropName="selectedOption"
      normalize={(options: UserOption[]) => {
        return options.map((v) => ({ id: v.value, name: v.label }));
      }}
    >
      <UserMultiSelect {...props} />
    </Form.Item>
  );
}

export default ListingUserMultiSelect;
