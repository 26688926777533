import React, { useMemo } from 'react';

import { groupBy } from 'lodash';
import { useTranslation } from 'react-i18next';

import { ListObjectFilter } from 'common-ui/lists';
import { DealBaseListing } from 'core/lib';
import { OBJECT_TYPES } from 'core/lib/constants';

type DealsByTechnologyProps = {
  data?: DealBaseListing[];
};

const DealsByTechnology = ({ data }: DealsByTechnologyProps) => {
  const { t } = useTranslation();

  const dataByTechnology = useMemo(
    () => groupBy(data, (d) => d.technology?.name ?? t('global:results.notSpecified')),
    [data, t]
  );

  return (
    <ListObjectFilter
      placeholder={t('dealbase:placeholders.searchTechnology')}
      data={dataByTechnology}
      title={t('dealbase:dashboards.titles.technology')}
      objectType={OBJECT_TYPES.DEAL_BASE}
    />
  );
};

export default DealsByTechnology;
