import React from 'react';

import { Tooltip } from 'antd';
import { format } from 'date-fns';

import { DEFAULT_DATETIME_FORMAT } from 'core/lib/constants/defaults';
import { parseJSONDate } from 'core/lib/utils';

type DateRecordProps = {
  date: string | Date;
  text?: string;
  dateFormat?: string;
  tooltipFormat?: string;
  className?: string;
};

const DateRecord = ({
  date,
  text,
  dateFormat = DEFAULT_DATETIME_FORMAT,
  tooltipFormat = DEFAULT_DATETIME_FORMAT,
  className,
}: DateRecordProps) => {
  return (
    <Tooltip title={format(parseJSONDate(date)!, tooltipFormat)}>
      <span className={className}>{text ?? format(parseJSONDate(date)!, dateFormat)}</span>
    </Tooltip>
  );
};

export default DateRecord;
