import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { EditFilled, ReloadOutlined } from '@ant-design/icons';
import { Badge, Button, Form, Tabs } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Prompt } from 'react-router';

import { PopConfirmCancelWrapper } from 'common-ui/wrappers';
import { ContactBookDetails } from 'core/lib';
import { useRefIdParam, useRefTypeParam } from 'utils/hooks';
import LoaderWrapper from 'utils/loader-wrapper';

import { mandatoryTabFields } from './formConfig';
import { ContactBookSectionKeys, ContactBookTabKeys, contactBookTabs } from './formKeys';
import { ContactBookDetailsContext } from '../context';
import { ActivityTab, DetailsTab } from '../tabs';

const DEFAULT_ACTIVE_TAB = 'details';
const QUERY_NAV = {
  comment: contactBookTabs.details,
};

type ContactBookFormProps = {
  onSave: (contactBook: ContactBookDetails) => Promise<void | number>;
  onCancel: () => void;
  onToggleEdit?: () => void;
  onRefetch?: () => void;
  setActiveTabKey: (tabKey: string) => void;
  activeTabKey?: string | null;
  editMode: boolean;
  contactBook: Partial<ContactBookDetails>;
  isNew?: boolean;
  isSaving: boolean;
};

const ContactBookForm = ({
  onSave,
  onCancel,
  onToggleEdit,
  onRefetch,
  setActiveTabKey,
  activeTabKey,
  editMode,
  contactBook,
  isNew = false,
  isSaving,
}: ContactBookFormProps) => {
  const [refType, setRefType] = useRefTypeParam();
  const [refId, setRefId] = useRefIdParam();

  const [formDirty, setFormDirty] = useState(false);
  const [formObject] = useForm<ContactBookDetails>();
  const [draftContactBook, setDraftContactBook] = useState<Partial<ContactBookDetails>>(contactBook);
  const [tabValidations, setTabValidations] = useState<{ [key in ContactBookTabKeys]?: string[] }>({});
  const [sectionValidations, setSectionValidations] = useState<{
    [key in ContactBookTabKeys]?: { [key in ContactBookSectionKeys]?: boolean };
  }>({});

  const { t } = useTranslation();

  const defaultTab = useMemo(() => {
    let tab = DEFAULT_ACTIVE_TAB;
    if (refType) {
      tab = QUERY_NAV[refType];
    }
    return tab ?? DEFAULT_ACTIVE_TAB;
  }, [refType]);

  const handleTabChange = (tabKey: string) => {
    if (refType && QUERY_NAV[refType] !== tabKey) {
      setRefType(undefined);
      setRefId(undefined);
    }
    setActiveTabKey(tabKey);
  };

  useEffect(() => {
    if (refType) {
      setActiveTabKey(QUERY_NAV[refType]);
    }
  }, [refId, refType, setActiveTabKey]);

  const init = useCallback(() => {
    formObject?.resetFields();
    setFormDirty(false);
  }, [formObject]);

  useEffect(() => {
    setDraftContactBook(contactBook);
  }, [contactBook]);

  useEffect(() => {
    init();
  }, [editMode, init]);

  useEffect(() => {
    init();
  }, [draftContactBook.id, init]);

  const validateTabs = () => {
    Object.entries(mandatoryTabFields).forEach(([tabKey, sectionsObject]) => {
      const tabFields = Object.values(sectionsObject).flat();
      formObject?.validateFields(tabFields).then(
        () => {
          setTabValidations((tabValidations) => ({ ...tabValidations, [tabKey]: [] }));
        },
        (err) => {
          setTabValidations((tabValidations) => ({
            ...tabValidations,
            [tabKey]: err.errorFields.map(({ name }: { name: string[] }) => name).flat(),
          }));
        }
      );
    });
  };

  useEffect(() => {
    const sectionValidations = Object.entries(mandatoryTabFields).reduce((agg, [tabKey, sectionObject]) => {
      agg[tabKey] = {};
      Object.entries(sectionObject).forEach(([sectionKey, sectionFields]) => {
        agg[tabKey][sectionKey] = !!tabValidations[tabKey]?.some((field: string) => sectionFields.includes(field));
      });
      return agg;
    }, {});

    setSectionValidations(sectionValidations);
  }, [tabValidations]);

  const setTabValidationStatus = () => {
    validateTabs();
  };

  const resetTabValidationErrors = () => {
    setTabValidations({});
    setSectionValidations({});
  };

  const onFinish = (values: ContactBookDetails) => {
    setFormDirty(false);
    onSave(values)
      .then(() => {
        resetTabValidationErrors();
      })
      .catch(() => {
        setFormDirty(true);
      });
  };

  const handleCancel = () => {
    resetTabValidationErrors();
    setDraftContactBook(contactBook);
    onCancel();
    setFormDirty(false);
  };

  const handleValuesChange = debounce((_, allValues) => {
    setFormDirty(true);
    setDraftContactBook({ ...draftContactBook, ...allValues });
  }, 100);

  return (
    <LoaderWrapper overlay loading={isSaving} message={t('loaders:global.saving')}>
      <ContactBookDetailsContext.Provider value={draftContactBook}>
        <Prompt when={formDirty} message={t('global:navigation.unsavedChanges')} />
        <Form
          onValuesChange={handleValuesChange}
          onFinishFailed={setTabValidationStatus}
          className="overflow-y-auto h-full"
          form={formObject}
          layout="vertical"
          name="contactBookForm"
          onFinish={onFinish}
          initialValues={draftContactBook}
        >
          <Tabs
            size="small"
            activeKey={activeTabKey ?? defaultTab}
            onChange={handleTabChange}
            type="line"
            className="h-full overflow-y-auto tabs-centered"
            tabBarExtraContent={
              editMode ? (
                <div className="flex flex-row gap-x-3">
                  <Button htmlType="submit" type="primary" disabled={!formDirty}>
                    {t('actions:global.save')}
                  </Button>
                  <PopConfirmCancelWrapper
                    title={t('actions:confirmation.confirmCancel')}
                    disabled={!formDirty}
                    onConfirm={handleCancel}
                  >
                    <Button>{t('actions:global.cancel')}</Button>
                  </PopConfirmCancelWrapper>
                </div>
              ) : (
                <div className="flex flex-row">
                  <Button type="link" onClick={onToggleEdit} icon={<EditFilled />} />
                  <Button type="link" onClick={onRefetch} icon={<ReloadOutlined />} />
                </div>
              )
            }
            items={[
              {
                key: contactBookTabs.details,
                className: 'h-full',
                label: (
                  <Badge className="text-inherit" dot={!!tabValidations[contactBookTabs.details]?.length}>
                    {t('contactBook:tabs.details')}
                  </Badge>
                ),
                forceRender: editMode,
                children: (
                  <DetailsTab
                    sectionValidation={sectionValidations[contactBookTabs.details]}
                    isNew={isNew}
                    editMode={editMode}
                  />
                ),
              },
              {
                key: contactBookTabs.activity,
                className: 'h-full',
                label: t('contactBook:tabs.activity'),
                disabled: isNew,
                children: <ActivityTab />,
              },
            ]}
          />
        </Form>
      </ContactBookDetailsContext.Provider>
    </LoaderWrapper>
  );
};

export default ContactBookForm;
