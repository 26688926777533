import React from 'react';

import { Popover, Tag } from 'antd';

import { contactBookApi, ContactBookDetails, ContactBookListing } from 'core/lib';
import LoaderWrapper from 'utils/loader-wrapper';

import {
  PopoverFieldLabel,
  PopoverBaseContactBookInfoComponent,
  PopoverContactButton,
  PopoverContactBookType,
} from './components';
import ContactBookDetailsPageHeader from '../detailsPageHeader';

type DiligencePartnerPopoverProps = {
  contactBook: ContactBookListing;
  children: React.ReactNode;
};

const DiligencePartnerPopover = ({ contactBook, children }: DiligencePartnerPopoverProps) => {
  const [getDiligencePartnerInfo, { data: diligencePartnerInfo, isFetching }] =
    contactBookApi.useLazyGetDiligencePartnerInfoQuery();

  const onContactBaseHover = (open: boolean) => {
    if (open) {
      getDiligencePartnerInfo(contactBook.id.toString(), true);
    }
  };

  return (
    <Popover
      arrowPointAtCenter
      onOpenChange={onContactBaseHover}
      overlayClassName="w-auto max-w-80"
      content={
        <LoaderWrapper loading={isFetching}>
          {diligencePartnerInfo && (
            <div className="flex flex-col px-1 gap-4">
              <PopoverContactBookType type={diligencePartnerInfo.type} />
              {diligencePartnerInfo.diligenceType && (
                <div className="flex flex-col gap-2">
                  <PopoverFieldLabel i18nKey="contactBook:shortInfo.labels.diligenceType" />
                  <div>
                    <Tag key={diligencePartnerInfo.diligenceType} color="purple">
                      {diligencePartnerInfo.diligenceType}
                    </Tag>
                  </div>
                </div>
              )}
              <PopoverBaseContactBookInfoComponent
                phoneNumber={diligencePartnerInfo.phoneNumber}
                email={diligencePartnerInfo.email}
              />
              <PopoverContactButton id={diligencePartnerInfo.id} email={diligencePartnerInfo.email} />
            </div>
          )}
        </LoaderWrapper>
      }
      title={
        <div className="px-1 py-3">
          {diligencePartnerInfo && (
            <ContactBookDetailsPageHeader
              defaultSize="small"
              className="p-0"
              contactBook={diligencePartnerInfo as ContactBookDetails}
            />
          )}
        </div>
      }
      trigger="hover"
    >
      {children}
    </Popover>
  );
};

export default DiligencePartnerPopover;
