import React, { useState } from 'react';

import { Button, Divider, Modal, Tag, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { UserAvatar, ViewUserProfileButton } from 'common-ui';
import { DirectConversationDetails, userApi } from 'core/lib';
import LoaderWrapper from 'utils/loader-wrapper';

const { Title, Text } = Typography;

type DirectConversationDetailsHeaderProps = {
  conversationDetails: DirectConversationDetails;
  withDivider?: boolean;
};

const DirectConversationDetailsHeader = ({
  conversationDetails,
  withDivider = false,
}: DirectConversationDetailsHeaderProps) => {
  const { t } = useTranslation();

  const [modalOpen, setModalOpen] = useState(false);

  const { data: userProfile, isLoading } = userApi.useGetUserShortProfileQuery(conversationDetails.peer.id);

  const onClick = () => {
    setModalOpen(!modalOpen);
  };

  const onCancel = () => {
    setModalOpen(false);
  };

  return (
    <>
      <div className="flex flex-col gap-2">
        <div className="flex justify-between">
          <Button type="text" onClick={onClick}>
            <UserAvatar size="small" plain user={conversationDetails.peer} />
          </Button>
        </div>
        {withDivider && <Divider className="m-0" />}
      </div>
      <Modal
        title={<div>{t('conversations:modal.title.userDetails')}</div>}
        open={modalOpen}
        onCancel={onCancel}
        footer={null}
        bodyStyle={{ paddingTop: 0 }}
        className="hide-modal-title-border"
      >
        <LoaderWrapper loading={isLoading}>
          {userProfile && (
            <div className="flex flex-col gap-8">
              <div className="flex md:flex-row md:items-start flex-col items-center gap-4">
                <div>
                  <UserAvatar user={conversationDetails.peer} iconOnly plain size={80} />
                </div>
                <div className="flex flex-col gap-2">
                  <div>
                    <Title level={3}>{userProfile.name}</Title>
                    <Text>{userProfile?.jobTitle}</Text>
                  </div>
                  <div>
                    <Text copyable>{userProfile?.email}</Text>
                  </div>
                  <div className="flex flex-wrap gap-2">
                    {userProfile.roles.map((role) => (
                      <Tag key={role} color="green">
                        {role}
                      </Tag>
                    ))}
                  </div>
                </div>
              </div>
              <ViewUserProfileButton userId={conversationDetails.peer.id} />
            </div>
          )}
        </LoaderWrapper>
      </Modal>
    </>
  );
};

export default DirectConversationDetailsHeader;
