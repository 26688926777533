import React, { useEffect, useState } from 'react';

import { Anchor, Card, Form, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { ClientLogo } from 'common-ui';
import Comments from 'common-ui/comments';
import PdfViewer from 'common-ui/viewers/pdfViewer';
import { baseApi, dealBaseApi, DraftBaseComment } from 'core/lib';
import LoaderWrapper from 'utils/loader-wrapper';

import { externalFormConfig } from '../externalFormConfig';
import ExternalNordicFooter from '../externalNordicFooter';
import { OverviewSection } from '../sections';

const { Link } = Anchor;

const { generalInformation } = externalFormConfig;

const ExternalDealBaseComments = () => {
  const { t } = useTranslation(['dealbase', 'global']);
  const { dealId } = useParams<{ dealId: string }>();

  const [targetOffset, setTargetOffset] = useState<number | undefined>(undefined);
  useEffect(() => {
    setTargetOffset(window.innerHeight / 2);
  }, []);

  const { data: dealbase, isFetching: fetchingDealBase } = dealBaseApi.useGetDealBaseDetailsQuery(dealId);

  const [getPitchDeckDocument, { data: pitchDeck, isFetching: isPitchDeckFetching }] =
    baseApi.useLazyDownloadBase64Query();
  const [getComments, { data: commentsData }] = dealBaseApi.useLazyGetCommentsQuery();
  const [postComment, { isLoading: isSaving }] = dealBaseApi.usePostCommentMutation();

  useEffect(() => {
    if (dealbase?.pitchDeckDocument?.id) {
      getPitchDeckDocument(dealbase.pitchDeckDocument?.id);
    }
  }, [dealbase?.pitchDeckDocument?.id, getPitchDeckDocument]);

  useEffect(() => {
    if (dealbase?.id) {
      getComments(dealbase.id);
    }
  }, [dealbase?.id, getComments]);

  const onSubmit = (comment: Partial<DraftBaseComment>, callback?: () => void) => {
    if (dealbase?.id) {
      comment.objectId = dealbase.id;
      postComment(comment)
        .unwrap()
        .then(() => {
          if (callback) {
            callback();
          }
        });
    }
  };

  const onReplySubmit = (parentId: number, comment: Partial<DraftBaseComment>, callback?: () => void) => {
    if (dealbase?.id) {
      comment.objectId = dealbase.id;
      comment.replyToId = parentId;
      postComment(comment)
        .unwrap()
        .then(() => {
          if (callback) {
            callback();
          }
        });
    }
  };

  return (
    <div className="flex flex-col lg:px-16 md:px-4 px-2	 h-full">
      <Space className="justify-center bg-white py-8" align="center">
        <ClientLogo height={32} />
      </Space>
      <div className="flex flex-row justify-between">
        <LoaderWrapper loading={fetchingDealBase} message={t('loaders:dealBase.loadingDetails')}>
          <div className="flex flex-col flex-auto overflow-y-scroll gap-4">
            <Form
              initialValues={dealbase}
              requiredMark="optional"
              className="flex flex-col flex-auto overflow-y-scroll gap-4"
              layout="vertical"
              name="dealBaseForm"
            >
              <Card id="general-information" title={t('dealbase:sections.generalInformation')}>
                <div className="max-w-512 m-auto">
                  <Form.Item
                    label={generalInformation.name.label}
                    name={generalInformation.name.dataIndex}
                    rules={generalInformation.name.rules}
                  >
                    <generalInformation.name.component disabled />
                  </Form.Item>
                </div>
              </Card>

              <Card id="overview" title={t('dealbase:sections.overview')}>
                <OverviewSection disabled />
              </Card>
            </Form>
            <Card id="documents" title={t('dealbase:sections.documents')}>
              <div className="max-w-512 m-auto">
                <PdfViewer data={pitchDeck} isLoading={isPitchDeckFetching} />
              </div>
            </Card>
            <Card id="comments" title={t('dealbase:sections.comments')}>
              <div className=" m-auto max-h7 overflow-scroll">
                <Comments
                  external
                  type={t('global:general.comment')}
                  isSaving={isSaving}
                  nameRequired
                  onSubmit={onSubmit}
                  onReplySubmit={onReplySubmit}
                  comments={commentsData ?? []}
                />
              </div>
            </Card>
          </div>
        </LoaderWrapper>
        <div className="p-4 hidden md:block">
          <Anchor targetOffset={targetOffset} offsetTop={16}>
            <Link href="#general-information" title={t('dealbase:sections.generalInformation')} />
            <Link href="#overview" title={t('dealbase:sections.overview')} />
            <Link href="#documents" title={t('dealbase:sections.documents')} />
            <Link href="#comments" title={t('dealbase:sections.comments')} />
          </Anchor>
        </div>
      </div>
      <ExternalNordicFooter />
    </div>
  );
};

export default ExternalDealBaseComments;
