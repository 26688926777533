import {
  CommentNotificationCard,
  CommentMentionNotificationCard,
  CommentReplyNotificationCard,
  ConversationAddCard,
  ConversationRemoveCard,
  ModuleCreateNotificationCard,
  ModuleStatusUpdateNotificationCard,
  ModuleUpdateNotificationCard,
  ModuleAssignedNotificationCard,
  ConversationCreateCard,
} from '.';

const NOTIFICATION_TYPE = {
  COMMENT: 'comment',
  DECISION: 'decision',
  CONVERSATION: 'conversation',
  GROUP: 'group',
  MODULE: 'module',
};

const NOTIFICATION_ACTION = {
  COMMENT: 'Comment',
  REPLY: 'Reply',
  MENTION: 'Mention',

  CONVERSATION_CREATE: 'conversation_create',
  CONVERSATION_ADD: 'conversation_add_user',
  CONVERSATION_REMOVE: 'conversation_remove_user',
  GROUP_ADD: 'group_add_user',
  GROUP_REMOVE: 'group_remove_user',

  MODULE_CREATE: 'create',
  MODULE_UPDATE: 'update',
  MODULE_ASSIGN: 'assign',
  MODULE_STATUS_UPDATE: 'status_update',
};

export const getNotificationComponent = (type: string, action: string) => {
  switch (type) {
    case NOTIFICATION_TYPE.DECISION:
    case NOTIFICATION_TYPE.COMMENT: {
      switch (action) {
        case NOTIFICATION_ACTION.COMMENT: {
          return CommentNotificationCard;
        }
        case NOTIFICATION_ACTION.MENTION: {
          return CommentMentionNotificationCard;
        }
        case NOTIFICATION_ACTION.REPLY: {
          return CommentReplyNotificationCard;
        }
      }
      break;
    }
    case NOTIFICATION_TYPE.CONVERSATION: {
      switch (action) {
        case NOTIFICATION_ACTION.CONVERSATION_CREATE: {
          return ConversationCreateCard;
        }
        case NOTIFICATION_ACTION.CONVERSATION_ADD: {
          return ConversationAddCard;
        }
        case NOTIFICATION_ACTION.CONVERSATION_REMOVE: {
          return ConversationRemoveCard;
        }
      }
      break;
    }
    case NOTIFICATION_TYPE.GROUP: {
      switch (action) {
        case NOTIFICATION_ACTION.GROUP_ADD: {
          return ConversationAddCard;
        }
        case NOTIFICATION_ACTION.GROUP_REMOVE: {
          return ConversationRemoveCard;
        }
      }
      break;
    }
    case NOTIFICATION_TYPE.MODULE: {
      switch (action) {
        case NOTIFICATION_ACTION.MODULE_CREATE: {
          return ModuleCreateNotificationCard;
        }
        case NOTIFICATION_ACTION.MODULE_UPDATE: {
          return ModuleUpdateNotificationCard;
        }
        case NOTIFICATION_ACTION.MODULE_STATUS_UPDATE: {
          return ModuleStatusUpdateNotificationCard;
        }
        case NOTIFICATION_ACTION.MODULE_ASSIGN: {
          return ModuleAssignedNotificationCard;
        }
      }
      break;
    }
  }
};
