import React, { useState } from 'react';

import { Button, Form, Input, List } from 'antd';
import { RuleObject } from 'antd/lib/form';
import { useForm } from 'antd/lib/form/Form';
import { StoreValue } from 'antd/lib/form/interface';
import { ListItemProps } from 'antd/lib/list';
import { once } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Prompt } from 'react-router-dom';

import { CommonObject } from 'core/lib';
import CommonObjectBuilder from 'core/lib/utils/builders/CommonObjectBuilder';
import { rules } from 'utils/form/rules';

type EditListItemProps = {
  item?: CommonObject;
  type: string;
  objectType: number;
  loading?: boolean;
  nameValidator: (rule: RuleObject, value: StoreValue) => Promise<void>;
  onSave: (id: CommonObject) => void;
  onCancel: () => void;
};

const EditListItem = ({
  item,
  loading,
  type,
  objectType,
  onSave,
  onCancel,
  nameValidator,
  ...listItemProps
}: EditListItemProps & ListItemProps) => {
  const [formDirty, setFormDirty] = useState(false);
  const [draftCommonObject] = useState(item ?? new CommonObjectBuilder().objectType(objectType).type(type).build());

  const [form] = useForm();
  const { t } = useTranslation();

  const onFormChange = once(() => {
    setFormDirty(true);
  });

  const onFinish = (values: CommonObject) => {
    const currentValues = { ...draftCommonObject, ...values };
    onSave(currentValues);
  };

  return (
    <>
      <List.Item
        {...listItemProps}
        extra={
          <div className="flex">
            <Button type="link" loading={loading} onClick={() => form.submit()}>
              {t('actions:global.save')}
            </Button>
            <Button type="link" disabled={loading} onClick={() => onCancel()}>
              {t('actions:global.cancel')}
            </Button>
          </div>
        }
      >
        <Prompt when={formDirty} message={t('global:navigation.unsavedChanges')} />
        <Form
          className="w-full max-w-256"
          form={form}
          layout="vertical"
          name="common_object_edit"
          onChange={onFormChange}
          onFinish={onFinish}
          initialValues={draftCommonObject}
        >
          <Form.Item
            className="m-0"
            name="name"
            rules={[
              rules.required,
              {
                validator: nameValidator,
                message: t('global:inputs.duplicate', { type: t('forms:commonObject.labels.name') }),
              },
            ]}
          >
            <Input autoFocus disabled={loading} maxLength={40} showCount />
          </Form.Item>
        </Form>
      </List.Item>
    </>
  );
};

export default EditListItem;
