import { createSelector } from 'reselect';

import { SYSTEM_ROLES } from 'core/lib/constants';

import { AppState } from '../../../store';
import { hasRole } from '../../../utils/userHelper';
import { AuthenticatedUser } from '../entities';

const selectSelf = (state: AppState) => state.auth;

export const selectCurrentUser = createSelector(selectSelf, (auth) => auth.user);

export const isAuthenticated = createSelector(selectSelf, (auth) => !!auth.token);

export const selectAccessToken = createSelector(selectSelf, (auth) => auth.token);

export const selectClientOrganization = createSelector(
  selectSelf,
  (auth) => auth.clientConfiguration?.clientOrganization
);

export const selectClientConfiguration = createSelector(selectSelf, (auth) => auth.clientConfiguration);

export const selectPendingAuthentication = createSelector(selectSelf, (auth) => auth.pendingAuthentication);

export const selectAuthenticatedUserRole = createSelector(selectCurrentUser, (user) => user?.systemRoleId);

export const selectIsAuthenticatedUserAdmin = createSelector(
  selectAuthenticatedUserRole,
  (userSystemRole) => userSystemRole && hasRole(userSystemRole, [SYSTEM_ROLES.CLIENT_ADMIN, SYSTEM_ROLES.SUPER_ADMIN])
);

export const selectUserInitials = createSelector(selectCurrentUser, (user: AuthenticatedUser | null) => {
  if (user) {
    return `${(user.firstName ?? '').charAt(0)}${(user.lastName ?? '').charAt(0)}`;
  }
  return '';
});
