import React, { ImgHTMLAttributes } from 'react';

import { CLIENT_LOGO, DEFAULT_LOGO } from 'assets';
import { getClient } from 'core/lib/utils/requesting';

const ClientLogo = (props: ImgHTMLAttributes<HTMLImageElement>) => {
  const client = getClient();

  const Logo = CLIENT_LOGO[client] ?? DEFAULT_LOGO;

  if (typeof Logo === 'string') {
    return <img alt="client-logo" src={Logo} {...props} />;
  }
  return (
    <div>
      <Logo {...props} />
    </div>
  );
};

export default ClientLogo;
