import React from 'react';

import { Listing } from 'common-ui/containers';

import ListingHeader from '../components/listingHeader';

type ListingContainerProps = {
  children: React.ReactNode;
  count: number;
};

const ListingContainer = ({ children, count }: ListingContainerProps) => {
  return (
    <>
      <ListingHeader count={count} />
      <Listing>{children}</Listing>
    </>
  );
};

export default ListingContainer;
