import React, { useMemo } from 'react';

import { groupBy } from 'lodash';
import { useTranslation } from 'react-i18next';

import ListObjectFilter from 'common-ui/lists/listObjectFilter';
import { PortfolioListing } from 'core/lib';
import { OBJECT_TYPES } from 'core/lib/constants';

type PortfolioByOrganizationProps = {
  data?: PortfolioListing[];
};

const PortfoliosByOrganization = ({ data }: PortfolioByOrganizationProps) => {
  const { t } = useTranslation();

  const dataByOrganization = useMemo(
    () => groupBy(data, (d) => d.organization?.name ?? t('global:results.notSpecified')),
    [data, t]
  );

  return (
    <ListObjectFilter<PortfolioListing>
      placeholder={t('portfolio:placeholders.searchOrganization')}
      data={dataByOrganization}
      title={t('portfolio:dashboards.titles.organization')}
      objectType={OBJECT_TYPES.PORTFOLIO}
    />
  );
};

export default PortfoliosByOrganization;
