import React, { useMemo, useRef, useState } from 'react';

import { Input, List, Switch } from 'antd';
import { useTranslation } from 'react-i18next';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';

import {
  AppState,
  ContactBookMenu,
  contactBookApi,
  setLeftMenuFiltersToggled,
  useAppDispatch,
  useAppSelector,
} from 'core/lib';
import { selectLeftMenuFiltersToggled } from 'core/lib/modules/app/store';
import { selectFilteredMenu } from 'core/lib/modules/contactBook/store';
import { fuzzyWordsSearch } from 'core/lib/utils';
import { Routes } from 'routes/routes';
import { useMatchParamId } from 'utils/hooks';
import LoaderWrapper from 'utils/loader-wrapper';

import ContactBookMenuItem from './menuItem';

const ContactBookLeftMenu = () => {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const searchContainerRef = useRef<HTMLDivElement>(null);

  const dispatch = useAppDispatch();
  const filtersToggled = useAppSelector(selectLeftMenuFiltersToggled);

  const id = useMatchParamId(Routes.CONTACT_BOOK_DETAILS.path);

  const { data, isLoading } = contactBookApi.useGetContactBookMenuQuery();

  const filteredMenuData = useAppSelector((state: AppState) => selectFilteredMenu(state, data));

  const searchResultData: ContactBookMenu[] | undefined = useMemo(() => {
    return filteredMenuData
      ?.filter((contactBook) => fuzzyWordsSearch(search, contactBook.name))
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [search, filteredMenuData]);

  const onFiltersToggleClick = () => {
    dispatch(setLeftMenuFiltersToggled(!filtersToggled));
  };

  return (
    <LoaderWrapper loading={!data?.length && isLoading} message={t('loaders:contactBook.loadingMenu')}>
      <div ref={searchContainerRef}>
        <div className="flex flex-col p-4 gap-4">
          <div className="flex items-center justify-between gap-4">
            {t('global:filters.toggleFilters')}
            <Switch
              onClick={onFiltersToggleClick}
              size="small"
              title={t('global:filters.toggleFilters')}
              checked={filtersToggled}
            />
          </div>
          <Input
            allowClear
            placeholder={t('global:placeholders.searchByName')}
            onChange={(e) => setSearch(e.target.value)}
            value={search}
          />
        </div>
      </div>
      <AutoSizer>
        {({ width, height }) => (
          <List>
            <FixedSizeList<ContactBookMenu[]>
              itemData={searchResultData}
              overscanCount={5}
              itemSize={40}
              itemCount={searchResultData?.length ?? 0}
              width={width}
              height={height - (searchContainerRef.current?.clientHeight ?? 0)}
            >
              {(props) => <ContactBookMenuItem {...props} id={id} isLoading={isLoading} />}
            </FixedSizeList>
          </List>
        )}
      </AutoSizer>
    </LoaderWrapper>
  );
};

export default ContactBookLeftMenu;
