import i18n from 'core/lib/utils/i18n';

export type DealBaseStatusType = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;

export const DEALBASE_STATUS = {
  DRAFT: 0 as DealBaseStatusType,
  NEW: 1 as DealBaseStatusType,
  PRE_SCREENING: 2 as DealBaseStatusType,
  SCREENING: 3 as DealBaseStatusType,
  INTERNAL_DD: 4 as DealBaseStatusType,
  EXTERNAL_DD: 5 as DealBaseStatusType,
  INVESTMENT: 6 as DealBaseStatusType,
  WAIT: 7 as DealBaseStatusType,
  CANCELED: 8 as DealBaseStatusType,
  INVESTMENT_ON_HOLD: 9 as DealBaseStatusType,
  WATCHLIST: 10 as DealBaseStatusType,
};

export const DEALBASE_STATUS_KEYS = {
  [DEALBASE_STATUS.DRAFT]: 'draft',
  [DEALBASE_STATUS.NEW]: 'new',
  [DEALBASE_STATUS.PRE_SCREENING]: 'preScreening',
  [DEALBASE_STATUS.SCREENING]: 'screening',
  [DEALBASE_STATUS.INTERNAL_DD]: 'internalDueDiligence',
  [DEALBASE_STATUS.EXTERNAL_DD]: 'externalDueDiligence',
  [DEALBASE_STATUS.INVESTMENT]: 'investment',
  [DEALBASE_STATUS.WAIT]: 'wait',
  [DEALBASE_STATUS.CANCELED]: 'canceled',
  [DEALBASE_STATUS.INVESTMENT_ON_HOLD]: 'investmentOnHold',
  [DEALBASE_STATUS.WATCHLIST]: 'watchlist',
};

export const DEALBASE_STATUSLIST = [
  {
    status: DEALBASE_STATUS.NEW,
    key: DEALBASE_STATUS_KEYS[DEALBASE_STATUS.NEW],
    name: i18n.t(`dealbase:status.label.${DEALBASE_STATUS_KEYS[DEALBASE_STATUS.NEW]}`),
  },
  {
    status: DEALBASE_STATUS.PRE_SCREENING,
    key: DEALBASE_STATUS_KEYS[DEALBASE_STATUS.PRE_SCREENING],
    name: i18n.t(`dealbase:status.label.${DEALBASE_STATUS_KEYS[DEALBASE_STATUS.PRE_SCREENING]}`),
  },
  {
    status: DEALBASE_STATUS.SCREENING,
    key: DEALBASE_STATUS_KEYS[DEALBASE_STATUS.SCREENING],
    name: i18n.t(`dealbase:status.label.${DEALBASE_STATUS_KEYS[DEALBASE_STATUS.SCREENING]}`),
  },
  {
    status: DEALBASE_STATUS.INTERNAL_DD,
    key: DEALBASE_STATUS_KEYS[DEALBASE_STATUS.INTERNAL_DD],
    name: i18n.t(`dealbase:status.label.${DEALBASE_STATUS_KEYS[DEALBASE_STATUS.INTERNAL_DD]}`),
  },
  {
    status: DEALBASE_STATUS.INVESTMENT_ON_HOLD,
    key: DEALBASE_STATUS_KEYS[DEALBASE_STATUS.INVESTMENT_ON_HOLD],
    name: i18n.t(`dealbase:status.label.${DEALBASE_STATUS_KEYS[DEALBASE_STATUS.INVESTMENT_ON_HOLD]}`),
  },
  {
    status: DEALBASE_STATUS.EXTERNAL_DD,
    key: DEALBASE_STATUS_KEYS[DEALBASE_STATUS.EXTERNAL_DD],
    name: i18n.t(`dealbase:status.label.${DEALBASE_STATUS_KEYS[DEALBASE_STATUS.EXTERNAL_DD]}`),
  },
  {
    status: DEALBASE_STATUS.INVESTMENT,
    key: DEALBASE_STATUS_KEYS[DEALBASE_STATUS.INVESTMENT],
    name: i18n.t(`dealbase:status.label.${DEALBASE_STATUS_KEYS[DEALBASE_STATUS.INVESTMENT]}`),
  },
  {
    status: DEALBASE_STATUS.WATCHLIST,
    key: DEALBASE_STATUS_KEYS[DEALBASE_STATUS.WATCHLIST],
    name: i18n.t(`dealbase:status.label.${DEALBASE_STATUS_KEYS[DEALBASE_STATUS.WATCHLIST]}`),
  },
  {
    status: DEALBASE_STATUS.WAIT,
    key: DEALBASE_STATUS_KEYS[DEALBASE_STATUS.WAIT],
    name: i18n.t(`dealbase:status.label.${DEALBASE_STATUS_KEYS[DEALBASE_STATUS.WAIT]}`),
  },
  {
    status: DEALBASE_STATUS.CANCELED,
    key: DEALBASE_STATUS_KEYS[DEALBASE_STATUS.CANCELED],
    name: i18n.t(`dealbase:status.label.${DEALBASE_STATUS_KEYS[DEALBASE_STATUS.CANCELED]}`),
  },
];
