import React from 'react';

import { InboxOutlined, UploadOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import Upload, { DraggerProps, UploadProps } from 'antd/lib/upload';
import Dragger from 'antd/lib/upload/Dragger';
import { useTranslation } from 'react-i18next';

import { Attachment } from 'core/lib';
import { getCustomUploadRequestMethod } from 'utils';

type AttachmentUploadProps = {
  asPrefix?: boolean;
  objectType: number;
  dataIndex: string;
  dragger?: boolean;
  value?: Attachment | Attachment[];
};

const AttachmentUpload = ({
  asPrefix,
  objectType,
  dataIndex,
  dragger,
  value,
  ...props
}: AttachmentUploadProps & DraggerProps & UploadProps) => {
  const { t } = useTranslation();
  let defaultFileList: Attachment[] = [];
  if (value && Array.isArray(value)) {
    defaultFileList = defaultFileList.concat(...value);
  } else if (value) {
    defaultFileList.push(value);
  }
  return dragger ? (
    <Dragger
      {...props}
      customRequest={getCustomUploadRequestMethod(objectType, dataIndex, asPrefix)}
      defaultFileList={defaultFileList}
    >
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">{t('actions:upload.dragToUpload')}</p>
      {props.accept && (
        <p className="ant-upload-hint">{t('actions:upload.singleUploadTypeHint', { type: props.accept })}</p>
      )}
    </Dragger>
  ) : (
    <Upload
      {...props}
      customRequest={getCustomUploadRequestMethod(objectType, dataIndex, asPrefix)}
      className="flex flex-col"
      defaultFileList={defaultFileList}
    >
      <Button className="w-full" icon={<UploadOutlined />}>
        {t('actions:upload.clickToUploadMax', { count: props.maxCount })}
      </Button>
    </Upload>
  );
};

export default AttachmentUpload;
