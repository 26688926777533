import _ from 'lodash';
import { createSelector } from 'reselect';

import { FILTER_OPERATOR } from 'core/lib/constants';
import { selectLeftMenuFiltersToggled } from 'core/lib/modules/app';
import { ContactBookListing, ContactBookMenu } from 'core/lib/modules/contactBook/entities';

import { AppState } from '../../../store';

const selectSelf = (state: AppState) => state.contactBook;

export const selectContactBookFilters = createSelector(selectSelf, (contactBook) => contactBook.filters);

export const selectContactBookTypeFilters = createSelector(selectContactBookFilters, (filters) => filters.type);

export const selectContactBookTagsFilters = createSelector(selectContactBookFilters, (filters) => filters.tags);

export const selectContactBookTagsOperator = createSelector(
  selectContactBookFilters,
  (filters) => filters.tagsOperator
);

export const selectFilteredMenu = createSelector(
  selectContactBookFilters,
  selectLeftMenuFiltersToggled,
  (_: AppState, listingData?: ContactBookMenu[]) => listingData,
  (filters, leftMenuFiltersToggled, listingData) => {
    if (!listingData || !leftMenuFiltersToggled) {
      return listingData;
    }
    const filterByType = (data: ContactBookMenu) => !filters.type?.length || !!filters.type.includes(data.type);
    const filterByTags = (data: ContactBookMenu) => {
      if (!filters.tags?.length) {
        return true;
      } else if (filters.tagsOperator === FILTER_OPERATOR.AND) {
        return !!filters.tags?.every((tag) => data.tags?.includes(tag));
      } else {
        return !!data.tags?.some((tag) => filters.tags?.includes(tag));
      }
    };
    const filterByCountry = (data: ContactBookMenu) => {
      return !filters.country?.length || !!(data.country && !!filters.country.includes(data.country));
    };
    const filterByRegion = (data: ContactBookMenu) => {
      return !filters.region?.length || !!(data.region && !!filters.region.includes(data.region));
    };
    const filterByStage = (data: ContactBookMenu) => {
      return !filters.stage?.length || !!(data.stageId && !!filters.stage.includes(data.stageId));
    };
    const filterByInvestmentFocus = (data: ContactBookMenu) => {
      return (
        !filters.investmentFocus?.length ||
        !!data.investmentFocusIds?.some((investmentFocusId) => !!filters.investmentFocus?.includes(investmentFocusId))
      );
    };
    const filterByIndustry = (data: ContactBookMenu) => {
      if (!filters.industry?.length) {
        return true;
      } else if (filters.industryOperator === FILTER_OPERATOR.AND) {
        return !!filters.industry?.every((industryId) => data.industryIds?.includes(industryId));
      } else {
        return !!data.industryIds?.some((industryId) => filters.industry?.includes(industryId));
      }
    };
    const filterByInvestmentGeography = (data: ContactBookMenu) => {
      if (!filters.investmentGeography?.length) {
        return true;
      } else if (filters.investmentGeographyOperator === FILTER_OPERATOR.AND) {
        return !!filters.investmentGeography?.every((investmentGeography) =>
          data.investmentGeography?.includes(investmentGeography)
        );
      } else {
        return !!data.investmentGeography?.some((investmentGeography) =>
          filters.investmentGeography?.includes(investmentGeography)
        );
      }
    };
    const filterBySharedCases = (data: ContactBookMenu) => {
      if (!filters.sharedCases?.length) {
        return true;
      } else if (filters.sharedCasesOperator === FILTER_OPERATOR.AND) {
        return !!filters.sharedCases?.every((sharedCaseId) => data.sharedCasesIds?.includes(sharedCaseId));
      } else {
        return !!data.sharedCasesIds?.some((sharedCaseId) => filters.sharedCases?.includes(sharedCaseId));
      }
    };
    const filterByDiligenceType = (data: ContactBookMenu) => {
      return (
        !filters.diligenceType?.length || !!(data.diligenceType && !!filters.diligenceType.includes(data.diligenceType))
      );
    };

    return _(listingData)
      .filter(filterByType)
      .filter(filterByTags)
      .filter(filterByRegion)
      .filter(filterByCountry)
      .filter(filterByStage)
      .filter(filterByInvestmentFocus)
      .filter(filterByInvestmentGeography)
      .filter(filterByIndustry)
      .filter(filterBySharedCases)
      .filter(filterByDiligenceType)
      .value() as ContactBookMenu[];
  }
);

export const selectFilteredListing = createSelector(
  selectContactBookFilters,
  (_: AppState, listingData?: ContactBookListing[]) => listingData,
  (filters, listingData) => {
    if (!listingData) {
      return listingData;
    }
    const filterByType = (data: ContactBookListing) => {
      return !filters.type?.length || !!filters.type.includes(data.type);
    };
    const filterByTags = (data: ContactBookListing) => {
      if (!filters.tags?.length) {
        return true;
      } else if (filters.tagsOperator === FILTER_OPERATOR.AND) {
        return !!filters.tags?.every((tag) => data.tags?.includes(tag));
      } else {
        return !!data.tags?.some((tag) => filters.tags?.includes(tag));
      }
    };
    const filterByCountry = (data: ContactBookListing) => {
      return !filters.country?.length || !!(data.country && !!filters.country.includes(data.country));
    };
    const filterByRegion = (data: ContactBookListing) => {
      return !filters.region?.length || !!(data.region && !!filters.region.includes(data.region));
    };
    const filterByStage = (data: ContactBookListing) => {
      return !filters.stage?.length || !!(data.stage && !!filters.stage.includes(data.stage.id));
    };
    const filterByInvestmentFocus = (data: ContactBookListing) => {
      return (
        !filters.investmentFocus?.length ||
        !!(
          data.investmentFocus &&
          data.investmentFocus?.some((investmentFocus) => !!filters.investmentFocus?.includes(investmentFocus.id))
        )
      );
    };
    const filterByIndustry = (data: ContactBookListing) => {
      if (!filters.industry?.length) {
        return true;
      } else if (filters.industryOperator === FILTER_OPERATOR.AND) {
        return !!filters.industry?.every(
          (industryId) => !!data.industry?.find((industry) => industry.id === industryId)
        );
      } else {
        return !!data.industry?.some((industry) => filters.industry?.includes(industry.id));
      }
    };
    const filterByInvestmentGeography = (data: ContactBookListing) => {
      if (!filters.investmentGeography?.length) {
        return true;
      } else if (filters.investmentGeographyOperator === FILTER_OPERATOR.AND) {
        return !!filters.investmentGeography?.every((investmentGeography) =>
          data.investmentGeography?.includes(investmentGeography)
        );
      } else {
        return !!data.investmentGeography?.some((investmentGeography) =>
          filters.investmentGeography?.includes(investmentGeography)
        );
      }
    };
    const filterBySharedCases = (data: ContactBookListing) => {
      if (!filters.sharedCases?.length) {
        return true;
      } else if (filters.sharedCasesOperator === FILTER_OPERATOR.AND) {
        return !!filters.sharedCases?.every(
          (sharedCaseId) => !!data.sharedCases?.find((sharedCase) => sharedCase.id === sharedCaseId)
        );
      } else {
        return !!data.sharedCases?.some((sharedCase) => filters.sharedCases?.includes(sharedCase.id));
      }
    };
    const filterByDiligenceType = (data: ContactBookListing) => {
      return (
        !filters.diligenceType?.length || !!(data.diligenceType && filters.diligenceType.includes(data.diligenceType))
      );
    };

    return _(listingData)
      .filter(filterByType)
      .filter(filterByTags)
      .filter(filterByRegion)
      .filter(filterByCountry)
      .filter(filterByStage)
      .filter(filterByInvestmentFocus)
      .filter(filterByInvestmentGeography)
      .filter(filterByIndustry)
      .filter(filterBySharedCases)
      .filter(filterByDiligenceType)
      .value() as ContactBookListing[];
  }
);

export const selectFiltersCount = (isMobile: boolean) =>
  createSelector(selectContactBookFilters, (filters) => {
    return [
      ...(isMobile ? [!!filters.type?.length, !!filters.tags?.length] : []),
      !!filters.country?.length,
      !!filters.region?.length,
      !!filters.stage?.length,
      !!filters.diligenceType?.length,
      !!filters.investmentFocus?.length,
      !!filters.industry?.length,
      !!filters.sharedCases?.length,
      !!filters.investmentGeography?.length,
    ].filter((filter) => filter).length;
  });
