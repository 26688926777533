import React from 'react';

import { Select, SelectProps, Tag } from 'antd';

import { CommonObject, commonObjectsApi } from 'core/lib';

export type CommonObjectSelectProps = {
  type: string;
  objectType: number;
  archived?: boolean;
  asTag?: boolean;
  color?: string;
  selectedOption?: CommonObject;
};

const CommonObjectSelect = ({
  type,
  objectType,
  archived,
  asTag,
  color,
  selectedOption,
  ...props
}: CommonObjectSelectProps & SelectProps<number>) => {
  const [getCommonObjectOptions, { data, isFetching }] = commonObjectsApi.useLazyGetCommonObjectOptionsQuery();

  const onDropdownVisibleChange = (open: boolean) => {
    if (open) {
      getCommonObjectOptions({ objectType, type, archived }, true);
    }
  };

  if (props.disabled && asTag && selectedOption) {
    return (
      <div className="flex flex-wrap gap-1">
        <Tag className="m-0" key={selectedOption.id} color={color}>
          {selectedOption.name}
        </Tag>
      </div>
    );
  }

  const value = selectedOption?.id;
  const selectOptions = data ?? (selectedOption ? [{ value: selectedOption?.id, label: selectedOption?.name }] : []);

  return (
    <Select
      showSearch
      allowClear
      optionFilterProp="label"
      loading={isFetching}
      value={value}
      options={selectOptions}
      onDropdownVisibleChange={onDropdownVisibleChange}
      dropdownMatchSelectWidth={150}
      {...props}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onChange={(_val, option: any) => {
        props.onChange?.(option, option);
      }}
    />
  );
};

export default CommonObjectSelect;
