import React, { CSSProperties, useMemo } from 'react';

import { MenuOutlined } from '@ant-design/icons';
import { useSortable } from '@dnd-kit/sortable';
import { CSS as dndCSS } from '@dnd-kit/utilities';
import { ListItemProps } from 'antd/lib/list';

import { SimpleLoader } from 'common-ui/loaders';

import Item from './item';

type SortableItemProps = {
  id: string | number;
  handle?: boolean;
  handleLoader?: boolean;
  data?: any;
  bordered?: boolean;
  disabled?: boolean;
};

const borderedClassName = 'border border-solid b--grey-200 ';
const baseClassName = 'flex gap-4 items-center justify-start ';
const disabledClassName = 'bg-disabled ';

function SortableItem({
  id,
  handle,
  handleLoader,
  children,
  data,
  bordered,
  disabled,
  className = '',
  style = {},
  ...rest
}: SortableItemProps & Omit<ListItemProps, 'id'>) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id,
    data,
  });
  const dragItemStyle: CSSProperties = {
    ...style,
    transform: dndCSS.Transform.toString(transform),
    transition,
  };

  const handleRender = useMemo(() => {
    if (handle) {
      if (handleLoader) {
        return <SimpleLoader />;
      }
      return <MenuOutlined style={{ cursor: 'grab', color: '#999' }} {...listeners} />;
    }
    return null;
  }, [handle, handleLoader, listeners]);

  const sortableClassName = baseClassName
    .concat(bordered ? borderedClassName : '')
    .concat(disabled ? disabledClassName : '')
    .concat(className);

  return (
    <Item
      {...rest}
      className={sortableClassName}
      ref={setNodeRef}
      style={dragItemStyle}
      {...(handle ? {} : attributes)}
      {...(handle ? {} : listeners)}
    >
      {handleRender}
      {children}
    </Item>
  );
}

export default SortableItem;
