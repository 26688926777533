import React from 'react';

type ListingContainerProps = {
  children: React.ReactNode;
};

const ListingContainer = ({ children }: ListingContainerProps) => {
  return <div className="p-4 flex gap-4 flex-col flex-auto h-full overflow-y-auto">{children}</div>;
};

export default ListingContainer;
