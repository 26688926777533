import React from 'react';

import { Form } from 'antd';

import { CommonObjectOption } from 'core/lib';
import { useLoadedInFrame } from 'utils/hooks';

import { FinancialsEbitdaSection, FinancialsRevenueSection } from '.';
import { externalFormConfig } from '../externalFormConfig';

const { metrics } = externalFormConfig;

type FinancialsSectionProps = {
  disabled: boolean;
};

const FinancialsSection = ({ disabled }: FinancialsSectionProps) => {
  const loadedInFrame = useLoadedInFrame();

  return (
    <div className="flex flex-col max-w-512 m-auto">
      <div className="flex sm:flex-row flex-col gap-x-4">
        {!loadedInFrame && (
          <Form.Item
            className="md:w-3/6"
            label={metrics.currency.label}
            name={metrics.currency.dataIndex}
            rules={metrics.currency.rules}
          >
            <metrics.currency.component disabled={disabled} />
          </Form.Item>
        )}
        <Form.Item
          className="md:w-3/6"
          extra={metrics.revenueType.help}
          label={metrics.revenueType.label}
          name={metrics.revenueType.dataIndex}
          rules={metrics.revenueType.rules}
          valuePropName="selectedOption"
          normalize={(option?: CommonObjectOption) => {
            return option ? { id: option.id, name: option.label } : null;
          }}
        >
          <metrics.revenueType.component disabled={disabled} />
        </Form.Item>
      </div>
      {!loadedInFrame && (
        <div className="flex flex-col gap-2">
          <FinancialsRevenueSection disabled={disabled} />
          <FinancialsEbitdaSection disabled={disabled} />
        </div>
      )}
    </div>
  );
};

export default FinancialsSection;
