import React, { useMemo } from 'react';

import { groupBy } from 'lodash';
import { useTranslation } from 'react-i18next';

import { ListObjectFilter } from 'common-ui/lists';
import { DealBaseListing } from 'core/lib';
import { OBJECT_TYPES } from 'core/lib/constants';

type DealsByAssignedProps = {
  data?: DealBaseListing[];
};

const DealsByAssigned = ({ data }: DealsByAssignedProps) => {
  const { t } = useTranslation();

  const dataByAssigned = useMemo(
    () => groupBy(data, (d) => d.assigned?.name ?? t('global:results.notSpecified')),
    [data, t]
  );

  return (
    <ListObjectFilter<DealBaseListing>
      placeholder={t('dealbase:placeholders.searchAssigned')}
      data={dataByAssigned}
      title={t('dealbase:dashboards.titles.assigned')}
      objectType={OBJECT_TYPES.DEAL_BASE}
    />
  );
};

export default DealsByAssigned;
