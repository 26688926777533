import React from 'react';

import { Select, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { DealBaseFilters } from 'core/lib';
import { DealBaseStatusType, DEALBASE_STATUSLIST } from 'core/lib/constants/statuses';

const { Title } = Typography;

type StatusFilterProps = {
  onChange: (filters: Partial<DealBaseFilters>) => void;
  filters: DealBaseStatusType[] | undefined;
};

const StatusFilter = ({ onChange, filters }: StatusFilterProps) => {
  const { t } = useTranslation();

  const statusOptions = DEALBASE_STATUSLIST.map((statusItem) => ({
    value: statusItem.status,
    label: t(`dealbase:status.label.${statusItem.key}`),
  }));

  return (
    <div>
      <Title level={5}>{t('dealbase:filters.statusCount', { count: filters?.length })}</Title>
      <Select
        mode="multiple"
        className="w-full"
        allowClear
        showSearch={false}
        options={statusOptions}
        value={filters}
        placeholder={t('global:placeholders.selectType', { type: t('dealbase:filters.status') })}
        onChange={(status) => onChange({ status })}
      />
    </div>
  );
};

export default StatusFilter;
