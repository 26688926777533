import React from 'react';

import { Form, SelectProps } from 'antd';

import { BaseSelectOption } from 'core/lib';
import { objectMultiSelector as ObjectMultiSelector } from 'utils/form/selects';

import { ListingEditableCellProps } from './types';

function ListingObjectMultiSelect({ dataIndex, rules, ...props }: ListingEditableCellProps & SelectProps<number[]>) {
  return (
    <Form.Item
      hasFeedback
      rules={rules}
      name={dataIndex}
      className="m-0"
      valuePropName="selectedOption"
      normalize={(options: BaseSelectOption[]) => {
        return options.map((v) => ({ id: v.value, name: v.label }));
      }}
    >
      <ObjectMultiSelector<BaseSelectOption> {...props } mode="multiple" allowClear />
    </Form.Item>
  );
}

export default ListingObjectMultiSelect;
