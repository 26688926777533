import React, { useEffect, useMemo } from 'react';

import { BellOutlined, SettingOutlined } from '@ant-design/icons';
import { Badge, Button, List, Popover, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link } from 'react-router-dom';

import { DROPDOWN_TRIGGERS } from 'common-ui';
import { useAppSelector, notificationApi, selectAllNotifications, selectNotificationsPage } from 'core/lib';
import { userProfileTabKeys } from 'modules/userProfile/tabs';
import { Routes } from 'routes/routes';

import { getNotificationComponent } from './utils';

const UserNotifications = () => {
  const { t } = useTranslation();
  const [getNotifications, { isFetching }] = notificationApi.useLazyGetNotificationsByPageQuery();
  const { data: notificationsInfo } = notificationApi.useGetNotificationsInfoQuery(undefined, {
    pollingInterval: 36000,
  });
  const [setNotificationsSeen] = notificationApi.usePostNotificationsSeenMutation();
  const [setNotificationsVisited] = notificationApi.usePostAllNotificationsVisitedMutation();
  const [setNotificationVisited] = notificationApi.usePostNotificationVisitedMutation();
  const page = useAppSelector(selectNotificationsPage);
  const data = useAppSelector(selectAllNotifications);
  const onNotificaitonClick = (notificationId: number) => {
    setNotificationVisited(notificationId);
  };

  const notifications = useMemo(() => {
    return (data ?? []).map((notification) => {
      const component = getNotificationComponent(notification.type, notification.action);
      return {
        ...notification,
        component:
          component &&
          React.createElement(component, {
            notification,
            onClick: onNotificaitonClick,
          }),
      };
    });
  }, [data]); // eslint-disable-line  react-hooks/exhaustive-deps

  useEffect(() => {
    getNotifications(0, false);
  }, [notificationsInfo, getNotifications]);

  const refreshData = () => {
    getNotifications(0, false);
  };

  const hasMoreNotifications = () => {
    return notifications.length < (notificationsInfo?.total ?? 0);
  };

  const loadMoreData = () => {
    getNotifications(page + 1, false);
  };

  const onOpenChange = (open: boolean) => {
    if (open && notificationsInfo?.unseen) {
      setNotificationsSeen();
    }
  };

  const menu = () => (
    <div className="flex flex-col max-h-128 w-80">
      <div className="flex justify-between p-2">
        {t('notifications:general.notifications')}
        <Link
          className="text-black"
          to={{ pathname: Routes.USER_PROFILE.path, state: { activeTabKey: userProfileTabKeys.notifications } }}
        >
          <SettingOutlined />
        </Link>
      </div>
      <div id="scrollableDiv" className="overflow-auto">
        <InfiniteScroll
          refreshFunction={refreshData}
          className="flex flex-col"
          dataLength={notifications.length}
          next={loadMoreData}
          hasMore={hasMoreNotifications()}
          loader={isFetching && <Skeleton avatar paragraph={{ rows: 1 }} active />}
          scrollableTarget="scrollableDiv"
        >
          <List dataSource={notifications} renderItem={(item) => item.component} />
        </InfiniteScroll>
      </div>
      <div>
        <Button type="text" onClick={() => setNotificationsVisited()}>
          {t('notifications:general.markAllAsRead')}
        </Button>
      </div>
    </div>
  );

  return (
    <Popover
      destroyTooltipOnHide
      onOpenChange={onOpenChange}
      trigger={DROPDOWN_TRIGGERS}
      content={menu}
      className="cursor-pointer"
      placement="bottomRight"
    >
      <Badge count={notificationsInfo?.unseen}>
        <BellOutlined />
      </Badge>
    </Popover>
  );
};

export default UserNotifications;
