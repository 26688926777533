import React from 'react';

import { Form } from 'antd';
import { RcFile, UploadFile } from 'antd/lib/upload/interface';
import { useTranslation } from 'react-i18next';

import { EXTERNAL_ATTACHMENT_PREFIX } from 'core/lib/constants/dealbase';
import { dealBaseFormConfig } from 'modules/dealBase/form/formConfig';
import { normalizeFileUpload, normalizeMultipleFileUpload } from 'utils';

const {
  documents: { externalDocuments },
} = dealBaseFormConfig;

const DealBaseExternalDocumentsSection = () => {
  const { t } = useTranslation(['actions', 'dealbase']);

  return (
    <div className="flex flex-col gap-8">
      <div className="flex sm:flex-row flex-col md:gap-16 gap-8">
        <div className="flex flex-col flex-auto">
          <Form.Item
            label={t('dealbase:documents.budgetDocument')}
            rules={externalDocuments.budgetDocument.rules}
            name={externalDocuments.budgetDocument.dataIndex}
            normalize={({ file }: { file: UploadFile<RcFile & XMLHttpRequest> }) => {
              return normalizeFileUpload(file, externalDocuments.budgetDocument.dataIndex);
            }}
          >
            <externalDocuments.budgetDocument.component />
          </Form.Item>
        </div>
        <div className="flex flex-col flex-auto">
          <Form.Item
            label={t('dealbase:documents.businessPlanDocument')}
            rules={externalDocuments.businessPlanDocument.rules}
            name={externalDocuments.businessPlanDocument.dataIndex}
            normalize={({ file }: { file: UploadFile<RcFile & XMLHttpRequest> }) => {
              return normalizeFileUpload(file, externalDocuments.businessPlanDocument.dataIndex);
            }}
          >
            <externalDocuments.businessPlanDocument.component />
          </Form.Item>
        </div>
      </div>
      <div className="flex flex-col flex-auto">
        <Form.Item
          label={t('dealbase:documents.additional')}
          rules={externalDocuments.attachments.rules}
          name={externalDocuments.attachments.dataIndex}
          normalize={({ file }: { file: UploadFile<RcFile & XMLHttpRequest> }, _, allValues) => {
            return normalizeMultipleFileUpload(
              file,
              allValues[externalDocuments.attachments.dataIndex],
              `${EXTERNAL_ATTACHMENT_PREFIX}-${file.uid}`
            );
          }}
        >
          <externalDocuments.attachments.component />
        </Form.Item>
      </div>
    </div>
  );
};

export default DealBaseExternalDocumentsSection;
