import React from 'react';

import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

import { UserRole } from 'core/lib';
import colors from 'core/lib/theme/colors';

export const getSystemRoleIdFromRoles = (systemRoles: UserRole[]) => {
  return systemRoles.reduce((roleId, role) => (roleId += role.id), 0);
};

const SuccessRuleIcon = <CheckCircleOutlined style={{ color: colors.Green.rgba }} />;
const PendingRuleIcon = <CheckCircleOutlined style={{ color: colors.Grey500.rgba }} />;
const FailedRuleIcon = <CloseCircleOutlined style={{ color: colors.Red.rgba }} />;

export const getRuleStatus = (
  regex: RegExp,
  ruleText: string,
  textInput: string | undefined,
  passwordValidation: boolean
) => {
  let icon = passwordValidation ? FailedRuleIcon : PendingRuleIcon;
  if (textInput) {
    if (regex.test(textInput)) {
      icon = SuccessRuleIcon;
    } else {
      icon = passwordValidation ? FailedRuleIcon : PendingRuleIcon;
    }
  }

  return (
    <div className="flex items-center gap-2">
      {icon}
      <div style={{ color: colors.Black.rgba }}>{ruleText}</div>
    </div>
  );
};
