import React, { useMemo } from 'react';

import { Tabs } from 'antd';
import { groupBy } from 'lodash';

import { TextBadge } from 'common-ui';
import { PortfolioListing, useAppDispatch, useAppSelector } from 'core/lib';
import { PORTFOLIO_STATUSLIST } from 'core/lib/constants/statuses/portfolio';
import { selectPortfolioListingGrouping, setPortfolioListingGrouping } from 'core/lib/modules/portfolio/store';

type ListingHeaderProps = {
  data: PortfolioListing[];
  tableRef:
    | React.MutableRefObject<{
        scrollTo: (y: number) => void;
        scrollToIndex: (idx: number) => void;
      }>
    | undefined;
};

const ListingHeader = ({ data, tableRef }: ListingHeaderProps) => {
  const listingGrouping = useAppSelector(selectPortfolioListingGrouping);
  const dispatch = useAppDispatch();
  const setGrouping = (grouping: string) => dispatch(setPortfolioListingGrouping(grouping));
  const groupings = useMemo(() => {
    const groupedData = groupBy(data, 'status');
    return Object.entries(groupedData).reduce((agg, [status, list]) => {
      agg[status] = list.length;
      return agg;
    }, {});
  }, [data]);

  const onGroupingClick = (status: string) => {
    tableRef?.current.scrollToIndex(0);
    setGrouping(status);
  };

  return (
    <div>
      <Tabs
        className="tabs-no-margin"
        onChange={onGroupingClick}
        activeKey={listingGrouping}
        items={PORTFOLIO_STATUSLIST.map(({ status, name }) => ({
          key: status.toString(),
          label: <TextBadge text={name} count={groupings[status]} active={listingGrouping === status.toString()} />,
        }))}
      />
    </div>
  );
};

export default ListingHeader;
