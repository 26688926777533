import React from 'react';

import { Form, Select, SelectProps } from 'antd';
import { useUpdateEffect } from 'react-use';

import { ListingEditableCellProps } from './types';

function ListingSelect({
  dataIndex,
  rules,
  isDisabled,
  onChange,
  ...props
}: ListingEditableCellProps & SelectProps<number | string>) {
  const form = Form.useFormInstance();
  const formValue: string | number = form.getFieldValue(dataIndex);

  useUpdateEffect(() => {
    if (onChange) {
      onChange(form);
    }
  }, [formValue]);

  const disabled = props.disabled || isDisabled?.(form);

  return (
    <Form.Item rules={rules} name={dataIndex} className="m-0">
      <Select allowClear {...props} disabled={disabled} />
    </Form.Item>
  );
}

export default ListingSelect;
