import React, { useContext, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import Comments from 'common-ui/comments';
import { contactBookApi, DraftBaseComment } from 'core/lib';
import { ContactBookDetailsContext } from 'modules/contactBook/context';
import LoaderWrapper from 'utils/loader-wrapper';

type CommentsSectionProps = {
  editMode: boolean;
};

const ContactBookCommentsSection = ({ editMode }: CommentsSectionProps) => {
  const contactBook = useContext(ContactBookDetailsContext);
  const { t } = useTranslation();
  const [getComments, { data, isLoading }] = contactBookApi.useLazyGetCommentsQuery();
  const [postComment, { isLoading: isSaving }] = contactBookApi.usePostCommentMutation();
  const [deleteComment, { isLoading: isDeleting }] = contactBookApi.useDeleteCommentMutation();

  useEffect(() => {
    if (contactBook?.id) {
      getComments(contactBook.id);
    }
  }, [contactBook?.id, getComments]);

  const onSubmit = (comment: Partial<DraftBaseComment>, callback?: () => void) => {
    if (contactBook?.id) {
      comment.objectId = contactBook.id;
      postComment(comment)
        .unwrap()
        .then(() => {
          if (callback) {
            callback();
          }
        });
    }
  };

  const onReplySubmit = (parentId: number, comment: Partial<DraftBaseComment>, callback?: () => void) => {
    if (contactBook?.id) {
      comment.objectId = contactBook.id;
      comment.replyToId = parentId;
      postComment(comment)
        .unwrap()
        .then(() => {
          if (callback) {
            callback();
          }
        });
    }
  };

  const onDelete = (userCommentId: number, callback?: () => void) => {
    if (contactBook?.id) {
      deleteComment({ id: userCommentId, objectId: contactBook.id })
        .unwrap()
        .then(() => {
          if (callback) {
            callback();
          }
        });
    }
  };
  return (
    <LoaderWrapper loading={isLoading} message={t('loaders:contactBook.loadingComments')}>
      <div className="flex flex-col">
        <Comments
          type={t('global:general.comment')}
          isSaving={isSaving || isDeleting}
          onDelete={onDelete}
          onSubmit={onSubmit}
          onReplySubmit={onReplySubmit}
          disabled={!editMode}
          comments={data ?? []}
        />
      </div>
    </LoaderWrapper>
  );
};

export default ContactBookCommentsSection;
