import React from 'react';

import { Spin } from 'antd';

interface LoaderWrapperProps {
  children?: React.ReactNode;
  loading: boolean | string | string[];
  message?: string;
  render?: (isLoading: boolean) => React.ReactNode;
  overlay?: boolean;
}

const DEFAULT_LOADING_MESSAGE = 'Loading ...';

const LoaderWrapper = ({
  children,
  loading,
  overlay,
  message = DEFAULT_LOADING_MESSAGE,
  render,
}: LoaderWrapperProps) => {
  // const isLoading = () => {
  //   if (typeof loading === 'string' || Array.isArray(loading)) return uiStore.isActionActive(loading);
  //   else if (typeof loading === 'boolean') return loading;
  //   return false;
  // };

  const isLoading = () => {
    return !!loading;
  };

  if (render) {
    return <>{render(isLoading())}</>;
  }

  const loader = () =>
    overlay ? (
      <div className="relative h-full">
        <Spin tip={message} className="absolute top-1/2	left-1/2 z-5" />
        <div className="absolute opacity-60 w-full h-full bg-white z-1"></div>
        <div className="blur-xs z-auto">{children}</div>
      </div>
    ) : (
      <div className="flex flex-auto h-full items-center justify-center">{<Spin tip={message} />}</div>
    );

  return <>{isLoading() ? loader() : children}</>;
};

export default LoaderWrapper;
