import React from 'react';

import { GlobalOutlined, LockOutlined, MailOutlined } from '@ant-design/icons';
import { Alert, Button, Checkbox, Form, Input, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';

import { LoginLogo } from 'assets';
import { ErrorData } from 'common-ui/types';
import { AppState } from 'core/lib';
import { LoginCredentials } from 'core/lib/modules/auth/entities/authentication';
import auth from 'core/lib/modules/auth/services/auth.api';
import AuthenticateSerivce from 'core/lib/modules/auth/services/auth.service';
import { selectCurrentUser } from 'core/lib/modules/auth/store/selectors';
import colors from 'core/lib/theme/colors';
import { Routes } from 'routes/routes';

import LoginFormWrapper from './loginFormWrapper';

const { Text } = Typography;

const isMobileApplication = process.env.REACT_APP_IS_MOBILE_APPLICATION;
interface LocationState {
  from?: string;
}

const mapState = (state: AppState) => ({
  isAuthenticated: !!selectCurrentUser(state),
});

const connector = connect(mapState);
type LoginProps = ConnectedProps<typeof connector>;

const LoginForm = ({ isAuthenticated }: LoginProps) => {
  const { state } = useLocation<LocationState>();
  const { t } = useTranslation('account');

  const [login, { isLoading, error }] = auth.useLoginMutation();

  const { data }: ErrorData = (error ?? {}) as ErrorData;

  const onFinish = ({ client, ...loginInfo }: LoginCredentials & { client?: string }) => {
    if (client) {
      AuthenticateSerivce.setCookiesClient(client);
    }
    login(loginInfo);
  };

  return !isAuthenticated ? (
    <LoginFormWrapper>
      <div className="sm:w-4/5 md:w-2/5 lg:w-1/5 min-w-80 h-full flex m-auto flex-col items-center justify-center self-center p-4">
        <div className="flex flex-col self-center justify-center grow gap-8">
          <LoginLogo width="100%" />
          {error && <Alert closable message={data.error_description} type="error" showIcon />}
          <Form<LoginCredentials>
            name="basic"
            layout="vertical"
            labelCol={{ span: 8 }}
            initialValues={{ remember: false, client: AuthenticateSerivce.getCookiesClient() }}
            onFinish={onFinish}
            autoComplete="off"
            requiredMark={false}
          >
            {isMobileApplication && (
              <Form.Item name="client" rules={[{ required: true, message: t('error.missingClient') }]}>
                <Input
                  className="w-full"
                  placeholder={t('inputs.client')}
                  prefix={<GlobalOutlined style={{ color: colors.Primary.rgba }} />}
                />
              </Form.Item>
            )}
            <Form.Item name="username" rules={[{ required: true, message: t('error.missingEmail') }]}>
              <Input
                className="w-full"
                placeholder={t('inputs.email')}
                prefix={<MailOutlined style={{ color: colors.Primary.rgba }} />}
              />
            </Form.Item>
            <Form.Item name="password" rules={[{ required: true, message: t('error.missingPassword') }]}>
              <Input.Password
                className="w-full"
                placeholder={t('inputs.password')}
                prefix={<LockOutlined style={{ color: colors.Primary.rgba }} />}
              />
            </Form.Item>
            <Form.Item name="remember" valuePropName="checked" wrapperCol={{ span: 16 }}>
              <Checkbox defaultChecked={false}>{t('inputs.rememberMe')}</Checkbox>
            </Form.Item>
            <Button className="w-full" type="primary" htmlType="submit" loading={isLoading}>
              {t('action.login')}
            </Button>
          </Form>
        </div>
        <Text type="secondary" style={{ fontSize: 12 }}>
          Copyright ©2021 Produced by MyBlueLabel
        </Text>
      </div>
    </LoginFormWrapper>
  ) : (
    <Redirect to={state?.from ?? Routes.DEFAULT.path} />
  );
};

export default connector(LoginForm);
