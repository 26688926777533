import React, { useEffect, useRef, useState } from 'react';

import { Editor, JSONContent } from '@tiptap/react';
import { Form } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';

import { MainContentContainer, ContentContainer } from 'common-ui/containers';
import { conversationsApi, useAppSelector, selectWebSocketStatus, useAppDispatch, resetMessagesState } from 'core/lib';
import { HubConnectionState } from 'core/lib/utils/signalR/utils';
import { useHub } from 'utils/hooks/hubHooks';
import LoaderWrapper from 'utils/loader-wrapper';

import { ConversationsWysiwygEditor, GroupConversationDetailsHeader, MessagesListing } from '../components';

type GroupConversationDetailsProps = {
  conversationId: number;
};

const GroupConversationDetails = ({ conversationId }: GroupConversationDetailsProps) => {
  const { t } = useTranslation();
  const [editor, setEditor] = useState<Editor | null>(null);
  const [form] = useForm();
  const infiniteScrollRef = useRef<HTMLDivElement>(null);
  const [hub] = useHub();

  const webSocketStatus = useAppSelector(selectWebSocketStatus);

  const {
    data: conversationDetails,
    isLoading: loadingConversationDetails,
    isSuccess: conversationLoaded,
  } = conversationsApi.useGetGroupConversationQuery(conversationId, {
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true,
  });

  const dispatch = useAppDispatch();

  const [postMessage] = conversationsApi.usePostConversationMessageMutation();

  useEffect(() => {
    if (conversationLoaded && webSocketStatus === HubConnectionState.Connected) {
      hub.conversations.joinConversationRoom(conversationId);
    }
    return () => {
      hub.conversations.leaveConversationRoom();
    };
  }, [hub.conversations, webSocketStatus, conversationId, conversationLoaded]);

  useEffect(() => {
    if (conversationId) {
      dispatch(resetMessagesState(conversationId));
    }
  }, [conversationId]); //eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = (draftMessage: { content: JSONContent }) => {
    if (conversationDetails && editor!.getText()) {
      postMessage({
        conversationId,
        content: JSON.stringify(draftMessage.content),
        text: editor!.getText(),
      })
        .unwrap()
        .then(() => {
          infiniteScrollRef.current?.scrollIntoView(false);
        });
      editor?.commands.clearContent();
      editor?.commands.clearNodes();
      editor?.commands.focus();
    }
  };

  return (
    <MainContentContainer id="conversation-details">
      <ContentContainer>
        <LoaderWrapper loading={loadingConversationDetails} message={t('loaders:myConversations.loadingGroupDetails')}>
          <div className="flex flex-col gap-2 h-full">
            {conversationDetails && (
              <GroupConversationDetailsHeader withDivider conversationDetails={conversationDetails} />
            )}
            <MessagesListing
              infiniteScrollRef={infiniteScrollRef}
              conversationId={conversationId}
              conversationTitle={conversationDetails?.title}
              conversationParticipants={conversationDetails?.participants ?? []}
              totalMessages={conversationDetails?.totalMessages}
              loaderMessage={t('loaders:myConversations.loadingGroupMessages')}
            />
            {conversationDetails && (
              <Form onFinish={handleSubmit} initialValues={{ content: '' }} form={form} component={false}>
                <Form.Item name="content" className="m-0">
                  <ConversationsWysiwygEditor
                    onSubmit={form.submit}
                    setEditor={setEditor}
                    conversationParticipants={conversationDetails.participants}
                    placeholder={t('conversations:placeholders.messageInConversation', {
                      conversation: conversationDetails?.title,
                    })}
                  />
                </Form.Item>
              </Form>
            )}
          </div>
        </LoaderWrapper>
      </ContentContainer>
    </MainContentContainer>
  );
};

export default GroupConversationDetails;
