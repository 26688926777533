import React, { useEffect, useState } from 'react';

import { transform } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory } from 'react-router-dom';

import { baseApi, dealBaseApi, DealBase, detailsSectionPreferenceApi } from 'core/lib';
import { OBJECT_TYPES } from 'core/lib/constants';
import { Routes } from 'routes/routes';
import { useMatchParamId } from 'utils/hooks';
import LoaderWrapper from 'utils/loader-wrapper';

import { DealBaseDetailsPageHeader } from '../components';
import { DealBaseForm } from '../form';

const DealBaseDetails = () => {
  const { t } = useTranslation(['loaders', 'actions', 'dealbase', 'global']);
  const id = useMatchParamId(Routes.DEAL_BASE_DETAILS.path)!;
  const history = useHistory();

  const [editMode, setEditMode] = useState(false);
  const [activeTabKey, setActiveTabKey] = useState<string | null>();

  const { data, refetch, isFetching: fetchingDealBase, originalArgs } = dealBaseApi.useGetDealBaseDetailsQuery(id);
  const { isLoading: loadingSectionHeaders } = detailsSectionPreferenceApi.useGetDetailsSectionHeadersQuery(
    OBJECT_TYPES.DEAL_BASE
  );

  const [postDealBase, { isLoading: isSaving, isSuccess: saveSuccess }] = dealBaseApi.usePatchDealBaseMutation();

  const onToggleEdit = () => {
    setEditMode(true);
  };

  const onCancel = () => {
    setEditMode(false);
  };

  useEffect(() => {
    if (saveSuccess) {
      setEditMode(false);
    }
  }, [saveSuccess]);

  useEffect(() => {
    if (originalArgs) {
      setEditMode(false);
      setActiveTabKey(null);
    }
  }, [originalArgs]);

  useEffect(() => {
    baseApi.util.resetApiState();
  }, [data?.id]);

  const onSave = (dealbase: DealBase) => {
    dealbase.id = parseInt(id);
    dealbase = transform(dealbase, (result, value, key) => {
      result[key] = value ?? null;
    });
    return postDealBase(dealbase).unwrap();
  };

  const onRefetch = () => {
    refetch();
  };

  const handlePageHeaderBackClick = () => {
    history.goBack();
  };

  return (
    <LoaderWrapper loading={fetchingDealBase || loadingSectionHeaders} message={t('loaders:dealBase.loadingDetails')}>
      {data ? (
        <>
          <DealBaseDetailsPageHeader onBack={handlePageHeaderBackClick} dealbase={data} />
          <DealBaseForm
            dealbase={data}
            onSave={onSave}
            onCancel={onCancel}
            onToggleEdit={onToggleEdit}
            onRefetch={onRefetch}
            setActiveTabKey={setActiveTabKey}
            activeTabKey={activeTabKey}
            editMode={editMode}
            isSaving={isSaving}
          />
        </>
      ) : (
        <Redirect to={Routes.PAGE_404.path} />
      )}
    </LoaderWrapper>
  );
};

export default DealBaseDetails;
