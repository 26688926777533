import React from 'react';

import { useTranslation } from 'react-i18next';
import { useMedia } from 'react-use';

import { ContentContainer, MainContentContainer, SideContentContainer } from 'common-ui/containers';
import { DetailsSectionCollapse, DetailsSectionPanel } from 'common-ui/detailsSectionCollapse';
import { OBJECT_TYPES } from 'core/lib/constants';

import {
  EditEbitdaSection,
  EditRevenueSection,
  FinancialEvaluationSection,
  MetricsCurrencySection,
  RevenueVsEbitdaSection,
} from './sections';
import { tabSections } from '../../form/formKeys';
import { DealBaseTabProps } from '../../types';

const defaultSections = Object.values(tabSections.metrics).map((section) => ({
  name: section.key,
  collapsed: true,
}));

const DealBaseMetricsTab = ({ editMode, isNew }: DealBaseTabProps) => {
  const { t } = useTranslation('dealbase');
  const isTablet = useMedia('(max-width: 1023px)');

  return (
    <MainContentContainer id="dealbase-metrics">
      <ContentContainer withSide>
        <DetailsSectionCollapse
          defaultSections={defaultSections}
          keepDefault={isNew}
          objectType={OBJECT_TYPES.DEAL_BASE}
        >
          {editMode && (
            <DetailsSectionPanel
              key={tabSections.metrics.metricsCurrency?.key!}
              sectionKey={tabSections.metrics.metricsCurrency?.key!}
              forceRender={editMode}
              header={t('sections.metricsCurrency')}
            >
              <MetricsCurrencySection editMode={editMode} />
            </DetailsSectionPanel>
          )}
          {editMode && (
            <DetailsSectionPanel
              key={tabSections.metrics.revenue?.key!}
              sectionKey={tabSections.metrics.revenue?.key!}
              forceRender={editMode}
              header={t('sections.revenue')}
            >
              <EditRevenueSection editMode={editMode} />
            </DetailsSectionPanel>
          )}
          {editMode && (
            <DetailsSectionPanel
              key={tabSections.metrics.ebitda?.key!}
              sectionKey={tabSections.metrics.ebitda?.key!}
              forceRender={editMode}
              header={t('sections.ebitda')}
            >
              <EditEbitdaSection editMode={editMode} />
            </DetailsSectionPanel>
          )}
          {!editMode && (
            <DetailsSectionPanel
              key={tabSections.metrics.revenueVsEbitda?.key!}
              sectionKey={tabSections.metrics.revenueVsEbitda?.key!}
              forceRender={editMode}
              header={t('sections.revenueVsEbitda')}
            >
              <RevenueVsEbitdaSection />
            </DetailsSectionPanel>
          )}
          {isTablet && (
            <DetailsSectionPanel
              header={t('sections.financialEvaluation')}
              key={tabSections.metrics.financialEvaluation?.key!}
              sectionKey={tabSections.metrics.financialEvaluation?.key!}
              forceRender={editMode}
            >
              <FinancialEvaluationSection editMode={editMode} />
            </DetailsSectionPanel>
          )}
        </DetailsSectionCollapse>
      </ContentContainer>
      {!isTablet && (
        <SideContentContainer>
          <DetailsSectionCollapse
            defaultSections={defaultSections}
            keepDefault={isNew}
            objectType={OBJECT_TYPES.DEAL_BASE}
          >
            <DetailsSectionPanel
              header={t('sections.financialEvaluation')}
              key={tabSections.metrics.financialEvaluation?.key!}
              sectionKey={tabSections.metrics.financialEvaluation?.key!}
              forceRender={editMode}
            >
              <FinancialEvaluationSection editMode={editMode} />
            </DetailsSectionPanel>
          </DetailsSectionCollapse>
        </SideContentContainer>
      )}
    </MainContentContainer>
  );
};

export default DealBaseMetricsTab;
