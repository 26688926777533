import React from 'react';

import { Form, Typography } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';
import { useTranslation } from 'react-i18next';

import { EXTERNAL_ATTACHMENT_PREFIX } from 'core/lib/constants/dealbase';
import { normalizeFileUpload, normalizeMultipleFileUpload } from 'utils';

import { externalFormConfig } from '../externalFormConfig';

const { Text } = Typography;

const { documents } = externalFormConfig;

type DocumentsSectionProps = {
  disabled: boolean;
  updateMode?: boolean;
};

const DocumentsSection = ({ disabled, updateMode }: DocumentsSectionProps) => {
  const { t } = useTranslation(['dealbase', 'global']);
  return (
    <div className="flex flex-col max-w-512 m-auto">
      <div className="flex md:flex-row flex-col gap-x-4">
        <Form.Item
          className="md:w-3/6 w-full"
          label={documents.pitchDeck.label}
          rules={documents.pitchDeck.rules}
          name={documents.pitchDeck.dataIndex}
          normalize={({ file }: { file: UploadFile<RcFile & XMLHttpRequest> }) => {
            return normalizeFileUpload(file, documents.pitchDeck.dataIndex);
          }}
        >
          <documents.pitchDeck.component disabled={disabled} />
        </Form.Item>
        <Form.Item
          className="md:w-3/6 w-full"
          label={documents.budget.label}
          rules={documents.budget.rules}
          name={documents.budget.dataIndex}
          normalize={({ file }: { file: UploadFile<RcFile & XMLHttpRequest> }) => {
            return normalizeFileUpload(file, documents.budget.dataIndex);
          }}
        >
          <documents.budget.component disabled={disabled} />
        </Form.Item>
      </div>
      {updateMode && (
        <>
          <Form.Item
            className="md:w-3/6 w-full"
            label={documents.businessPlan.label}
            rules={documents.businessPlan.rules}
            name={documents.businessPlan.dataIndex}
            normalize={({ file }: { file: UploadFile<RcFile & XMLHttpRequest> }) => {
              return normalizeFileUpload(file, documents.businessPlan.dataIndex);
            }}
          >
            <documents.businessPlan.component disabled={disabled} />
          </Form.Item>
          <div className="flex flex-col flex-auto">
            <Text>{`${t('dealbase:documents.additional')}:`}</Text>
            <Form.Item
              rules={documents.externalAttachments.rules}
              name={documents.externalAttachments.dataIndex}
              normalize={({ file }: { file: UploadFile<RcFile & XMLHttpRequest> }, _, allValues) => {
                return normalizeMultipleFileUpload(
                  file,
                  allValues[documents.externalAttachments.dataIndex],
                  `${EXTERNAL_ATTACHMENT_PREFIX}-${file.uid}`
                );
              }}
            >
              <documents.externalAttachments.component disabled={disabled} />
            </Form.Item>
          </div>
        </>
      )}
    </div>
  );
};

export default DocumentsSection;
