import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getClientDomainName } from 'core/lib/utils';
import { setLogrocketIdentity } from 'utils/logging';

import { ClientConfiguration } from '../entities';
import { AuthenticatedUser } from '../entities/authenticatedUser';
import auth from '../services/auth.api';
import AuthenticateSerivce from '../services/auth.service';
import { deserializeClientConfiguration, deserializeGetStatusUser } from '../services/serializers';

type AuthState = {
  clientConfiguration: ClientConfiguration | null;
  user: AuthenticatedUser | null;
  token: string | null;
  pendingAuthentication: boolean;
};

export const authInitialState: AuthState = {
  clientConfiguration: null,
  user: null,
  token: null,
  pendingAuthentication: true,
};

export const slice = createSlice({
  name: 'auth',
  initialState: authInitialState,
  reducers: {
    setPendingAuthentication: (state: AuthState | undefined, { payload }: PayloadAction<boolean>) => {
      if (state) {
        state.pendingAuthentication = payload;
      }
    },
    logout: () => {
      AuthenticateSerivce.removeCookiesAccessToken();
      return { ...authInitialState, pendingAuthentication: false };
    },
    refreshToken: (state: AuthState | undefined) => {
      if (state) {
        try {
          const token = AuthenticateSerivce.getCookiesAccessToken();
          state.token = token;
        } catch (e) {
          AuthenticateSerivce.removeCookiesAccessToken();
          return { ...authInitialState, pendingAuthentication: false };
        }
      }
    },
    authHydrate: (state, { payload }: PayloadAction<Partial<AuthState>>) => {
      return { ...state, ...payload };
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(auth.endpoints.login.matchFulfilled, (state, { payload }) => {
      const envSubdomain: string = getClientDomainName();
      AuthenticateSerivce.setCookiesAccessToken(payload.token);
      AuthenticateSerivce.setCookiesClient(envSubdomain);
      state.token = payload.token;
      state.user = payload.user;
      state.clientConfiguration = payload.clientConfiguration;
      setLogrocketIdentity(state.user);
    });
    builder.addMatcher(auth.endpoints.login.matchRejected, (state) => {
      AuthenticateSerivce.removeCookiesAccessToken();
      AuthenticateSerivce.removeCookiesClient();
      return { ...authInitialState, pendingAuthentication: false };
    });
    builder.addMatcher(auth.endpoints.getStatus.matchFulfilled, (state, { payload }) => {
      if (payload.CurrentUser?.isAuth) {
        state.user = deserializeGetStatusUser(payload.CurrentUser);
        state.clientConfiguration = deserializeClientConfiguration(payload.ClientConfiguration);
        state.pendingAuthentication = false;
        setLogrocketIdentity(state.user);
      } else {
        state.user = null;
        state.pendingAuthentication = false;
        state.clientConfiguration = null;
        state.token = null;
        AuthenticateSerivce.removeCookiesAccessToken();
        AuthenticateSerivce.removeCookiesClient();
      }
    });
    builder.addMatcher(auth.endpoints.externalLogin.matchFulfilled, (state, { payload }) => {
      state.token = payload.token;
      AuthenticateSerivce.setCookiesAccessToken(payload.token);
    });
    builder.addMatcher(auth.endpoints.externalLogin.matchRejected, (state, { payload }) => {
      state.token = null;
      AuthenticateSerivce.removeCookiesAccessToken();
    });
  },
});

export const { setPendingAuthentication, logout, refreshToken, authHydrate } = slice.actions;

export default slice.reducer;
