import { CommonObject } from 'core/lib';

class CommonObjectBuilder {
  private readonly _commonObject: CommonObject;

  constructor() {
    this._commonObject = {
      id: 0,
      name: '',
      type: '',
      objectType: 0,
      variant: undefined,
      archived: false,
      connected: undefined,
    };
  }

  build() {
    return JSON.parse(JSON.stringify(this._commonObject)) as CommonObject;
  }

  id(id: number) {
    this._commonObject.id = id;
    return this;
  }

  type(type: string) {
    this._commonObject.type = type;
    return this;
  }

  name(name: string) {
    this._commonObject.name = name;
    return this;
  }

  objectType(objectType: number) {
    this._commonObject.objectType = objectType;
    return this;
  }

  variant(variant: number) {
    this._commonObject.variant = variant;
    return this;
  }

  archived(archived: boolean) {
    this._commonObject.archived = archived;
    return this;
  }

  connected(connected: number) {
    this._commonObject.connected = connected;
    return this;
  }
}

export default CommonObjectBuilder;
