import React, { useEffect, useMemo, useState } from 'react';

import { Select, SelectProps } from 'antd';

const InputSuggestions = ({ onChange, ...props }: SelectProps<string>) => {
  const [search, setSearch] = useState('');
  const options = useMemo(() => {
    if (search) {
      return (props.options ?? []).concat({ value: search, label: search });
    }
    return props.options;
  }, [props.options, search]);

  useEffect(() => {
    if (onChange && options && search) {
      onChange(search, options.find((opt) => opt.value === search)!);
    }
  }, [onChange, options, search]);

  const onSearch = (value: string) => {
    setSearch(value);
  };

  return (
    <Select
      {...props}
      onChange={onChange}
      autoClearSearchValue={false}
      options={options}
      allowClear
      showSearch
      searchValue={search}
      // placeholder={t('global.search')}
      onSearch={onSearch}
      style={{ width: '100%' }}
    ></Select>
  );
};

export default InputSuggestions;
