import React from 'react';

import { toRoundedNumber } from 'utils';

type ScoringItemProps = {
  label: string;
  value: number;
};

const ScoringItem = ({ label, value }: ScoringItemProps) => {
  return <div>{`${label}: ${toRoundedNumber(value, 1)}`}</div>;
};

export default ScoringItem;
