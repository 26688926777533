import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';

import { SuggestionProps } from '@tiptap/suggestion';
import { List, Popover } from 'antd';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList, ListChildComponentProps } from 'react-window';

import { UserAvatar } from 'common-ui';
import { BaseUser } from 'core/lib';

export type MentionListRef = {
  onKeyDown: (props: { event: KeyboardEvent }) => boolean;
};

type Props = Pick<SuggestionProps, 'items' | 'command'>;

// eslint-disable-next-line react/display-name
export const MentionList = forwardRef<MentionListRef, Props>((props, ref) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const selectItem = (index: number) => {
    const item = props.items[index];

    if (item) {
      props.command({ user: item, id: item.id });
    }
  };

  const upHandler = () => {
    setSelectedIndex((selectedIndex + props.items.length - 1) % props.items.length);
  };

  const downHandler = () => {
    setSelectedIndex((selectedIndex + 1) % props.items.length);
  };

  const enterHandler = () => {
    selectItem(selectedIndex);
  };

  useEffect(() => setSelectedIndex(0), [props.items]);

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }) => {
      if (event.key === 'ArrowUp') {
        upHandler();
        return true;
      }

      if (event.key === 'ArrowDown') {
        downHandler();
        return true;
      }

      if (event.key === 'Enter') {
        enterHandler();
        return true;
      }

      return false;
    },
  }));

  const mentionListItem = ({ index, data, style }: ListChildComponentProps<BaseUser[]>) => {
    const user = data[index];
    return (
      <List.Item
        className={`${selectedIndex === index ? 'bg-gray-50' : ''} px-2 cursor-pointer hover:bg-gray-50`}
        key={user.id}
        style={style}
        onClick={() => selectItem(index)}
      >
        <UserAvatar size="small" user={user} plain />
      </List.Item>
    );
  };

  return (
    <Popover
      placement="bottom"
      visible
      content={
        <div className="flex flex-overflow-auto w-auto min-h-64 min-w-64 bg-white p-1 border border-solid b--grey-200">
          <AutoSizer>
            {({ height, width }) => (
              <List>
                <FixedSizeList<BaseUser[]>
                  itemData={props.items as BaseUser[]}
                  width={width}
                  height={height}
                  itemCount={props.items.length}
                  itemSize={40}
                >
                  {mentionListItem}
                </FixedSizeList>
              </List>
            )}
          </AutoSizer>
        </div>
      }
    ></Popover>
  );
});
