import React, { ReactNode } from 'react';

import { Redirect } from 'react-router-dom';

import { selectClientConfiguration, useAppSelector } from 'core/lib';
import { userHasModuleAccess } from 'core/lib/utils';
import { Routes } from 'routes/routes';

interface ModuleControlProps {
  children?: ReactNode;
  module: number;
  redirect?: boolean;
}

const ModuleControl = ({ children, module, redirect = false }: ModuleControlProps) => {
  const clientConfiguration = useAppSelector(selectClientConfiguration);

  const isEnabled = userHasModuleAccess(module, clientConfiguration);
  const onPrevented = redirect ? <Redirect to={Routes.DEFAULT.path} /> : null;
  return <>{isEnabled ? children : onPrevented}</>;
};

export default ModuleControl;
