import React from 'react';

import { Button, ButtonProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router-dom';

import { conversationsApi, CreateDirectConversationRequest } from 'core/lib';
import { Routes } from 'routes/routes';
import { useLoggedInUser } from 'utils/hooks';

type CreateDirectConversationButtonProps = {
  userId: number;
};

const CreateDirectConversationButton = ({
  userId,
  ...buttonProps
}: CreateDirectConversationButtonProps & ButtonProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const currentUser = useLoggedInUser();
  const [createConversation, { isLoading }] = conversationsApi.useCreateDirectConversationMutation();

  const onCreate = (directConv: CreateDirectConversationRequest) => {
    createConversation(directConv)
      .unwrap()
      .then((directConvId) => {
        history.push(generatePath(Routes.CONVERSATIONS_DM_DETAILS.path, { id: directConvId }));
      });
  };

  const onCreateDirectConversationClick = () => {
    onCreate({ userId });
  };

  if (currentUser?.id === userId) {
    return null;
  }

  return (
    <Button loading={isLoading} type="primary" {...buttonProps} onClick={onCreateDirectConversationClick}>
      {t('conversations:actions.directMessage')}
    </Button>
  );
};

export default CreateDirectConversationButton;
