import { intersection } from 'lodash';

import { INDEXED_SYSTEM_ROLES } from 'core/lib/constants';

export const getRolesByRoleId = (userRoleId: number) => {
  const roles: string[] = [];
  Object.entries(INDEXED_SYSTEM_ROLES).forEach(([systemRoleId, roleName]) => {
    if ((parseInt(systemRoleId) & userRoleId) === parseInt(systemRoleId)) {
      roles.push(roleName);
    }
  });

  return roles;
};

export const hasRole = (userRole: number, roles: string[]) => {
  const userRoles = getRolesByRoleId(userRole);
  return !!intersection(userRoles, roles).length;
};

export const hasAllRoles = (userRole: number, roles: string[]) => {
  const userRoles = getRolesByRoleId(userRole);
  return intersection(userRoles, roles).length === roles.length;
};
