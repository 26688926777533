import React, { useEffect } from 'react';

import { Route, Switch, Redirect, generatePath } from 'react-router-dom';

import { selectUserPreferenceMenuDefaultType, setActiveModule, useAppDispatch, useAppSelector } from 'core/lib';
import { OBJECT_TYPES } from 'core/lib/constants';
import { Routes } from 'routes/routes';
import { getModulePath } from 'utils';
import ModuleControl from 'utils/moduleControl';

import { ListingContainer } from './containers';
import ContactBookDetailsComponent from './details';
import ContactBookCreateComponent from './new';

const ContactBook = () => {
  const userPreferenceMenuDefaultType = useAppSelector(selectUserPreferenceMenuDefaultType);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setActiveModule(OBJECT_TYPES.CONTACT_BOOK));
    return () => {
      dispatch(setActiveModule(null));
    };
  }, [dispatch]);

  const redirectPath =
    getModulePath(OBJECT_TYPES.CONTACT_BOOK, userPreferenceMenuDefaultType) ??
    generatePath(Routes.CONTACT_BOOK_LISTING.path);

  return (
    <ModuleControl redirect module={OBJECT_TYPES.CONTACT_BOOK}>
      <Switch>
        <Route path={Routes.CONTACT_BOOK.path} exact render={() => <Redirect to={redirectPath} />} />
        <Route path={Routes.CONTACT_BOOK_DETAILS.path} component={ContactBookDetailsComponent} />
        <Route path={Routes.CONTACT_BOOK_NEW.path} component={ContactBookCreateComponent} />
        <ListingContainer />
      </Switch>
    </ModuleControl>
  );
};

export default ContactBook;
