import { BaseUser, Organization } from 'core/lib';

class OrganizationBuilder {
  private readonly _organization: Organization;

  constructor() {
    this._organization = {
      id: 0,
      name: '',
      users: [],
    };
  }

  build() {
    return JSON.parse(JSON.stringify(this._organization)) as Organization;
  }

  id(id: number) {
    this._organization.id = id;
    return this;
  }

  name(name: string) {
    this._organization.name = name;
    return this;
  }

  users(users: BaseUser[]) {
    this._organization.users = users;
    return this;
  }
}

export default OrganizationBuilder;
