import React from 'react';

import { Form } from 'antd';

import { normalizeCheckboxChange } from 'common-ui/inputs/checkboxSelect';

import { portfolioFormConfig } from '../../../form/formConfig';

const {
  productInformation: { classification },
} = portfolioFormConfig;

type ProductInformationSectionProps = {
  editMode: boolean;
};

const PortfolioProductInformationSection = ({ editMode }: ProductInformationSectionProps) => {
  return (
    <div className="flex flex-col">
      <div className="flex sm:flex-row flex-col gap-x-4">
        <Form.Item
          className="md:w-3/6"
          name={classification.businessCritical.dataIndex}
          rules={classification.businessCritical.rules}
          normalize={normalizeCheckboxChange}
        >
          <classification.businessCritical.component disabled={!editMode} />
        </Form.Item>
        <Form.Item
          className="md:w-3/6"
          name={classification.gxp.dataIndex}
          rules={classification.gxp.rules}
          normalize={normalizeCheckboxChange}
        >
          <classification.gxp.component disabled={!editMode} />
        </Form.Item>
      </div>
      <div className="flex sm:flex-row flex-col gap-x-4">
        <Form.Item
          className="md:w-3/6"
          name={classification.eres.dataIndex}
          rules={classification.eres.rules}
          normalize={normalizeCheckboxChange}
        >
          <classification.eres.component disabled={!editMode} />
        </Form.Item>
        <Form.Item
          className="md:w-3/6"
          name={classification.sox.dataIndex}
          rules={classification.sox.rules}
          normalize={normalizeCheckboxChange}
        >
          <classification.sox.component disabled={!editMode} />
        </Form.Item>
      </div>
      <div className="flex sm:flex-row flex-col gap-x-4">
        <Form.Item
          className="md:w-3/6"
          name={classification.legal.dataIndex}
          rules={classification.legal.rules}
          normalize={normalizeCheckboxChange}
        >
          <classification.legal.component disabled={!editMode} />
        </Form.Item>
        <Form.Item
          className="md:w-3/6"
          name={classification.dataPrivacy.dataIndex}
          rules={classification.dataPrivacy.rules}
          normalize={normalizeCheckboxChange}
        >
          <classification.dataPrivacy.component disabled={!editMode} />
        </Form.Item>
      </div>
      <div className="flex sm:flex-row flex-col gap-x-4">
        <Form.Item
          className="md:w-3/6"
          name={classification.informationSecurity.dataIndex}
          rules={classification.informationSecurity.rules}
          normalize={normalizeCheckboxChange}
        >
          <classification.informationSecurity.component disabled={!editMode} />
        </Form.Item>
        <Form.Item
          className="md:w-3/6"
          name={classification.sdg.dataIndex}
          rules={classification.sdg.rules}
          normalize={normalizeCheckboxChange}
        >
          <classification.sdg.component disabled={!editMode} />
        </Form.Item>
      </div>
      <div className="flex sm:flex-row flex-col gap-x-4">
        <Form.Item
          className="md:w-3/6"
          name={classification.other.dataIndex}
          rules={classification.other.rules}
          normalize={normalizeCheckboxChange}
        >
          <classification.other.component disabled={!editMode} />
        </Form.Item>
      </div>
      <div className="flex sm:flex-row flex-col gap-x-4">
        <Form.Item
          className="md:w-3/6"
          label={classification.patientSafety.label}
          name={classification.patientSafety.dataIndex}
          rules={classification.patientSafety.rules}
        >
          <classification.patientSafety.component disabled={!editMode} />
        </Form.Item>
        <Form.Item
          className="md:w-3/6"
          label={classification.productQuality.label}
          name={classification.productQuality.dataIndex}
          rules={classification.productQuality.rules}
        >
          <classification.productQuality.component disabled={!editMode} />
        </Form.Item>
      </div>
      <div className="flex sm:flex-row flex-col gap-x-4">
        <Form.Item
          className="md:w-3/6"
          label={classification.dataIntegrity.label}
          name={classification.dataIntegrity.dataIndex}
          rules={classification.dataIntegrity.rules}
        >
          <classification.dataIntegrity.component disabled={!editMode} />
        </Form.Item>
        <Form.Item
          className="md:w-3/6"
          label={classification.complexity.label}
          name={classification.complexity.dataIndex}
          rules={classification.complexity.rules}
        >
          <classification.complexity.component disabled={!editMode} />
        </Form.Item>
      </div>
      <div className="flex sm:flex-row flex-col gap-x-4">
        <Form.Item
          className="md:w-3/6"
          label={classification.overallRiskPriority.label}
          name={classification.overallRiskPriority.dataIndex}
          rules={classification.overallRiskPriority.rules}
        >
          <classification.overallRiskPriority.component disabled={!editMode} />
        </Form.Item>
        <Form.Item
          className="md:w-3/6"
          label={classification.gamp5Category.label}
          name={classification.gamp5Category.dataIndex}
          rules={classification.gamp5Category.rules}
        >
          <classification.gamp5Category.component disabled={!editMode} />
        </Form.Item>
      </div>
      <div className="flex sm:flex-row flex-col gap-x-4">
        <Form.Item
          className="md:w-3/6"
          label={classification.confidentiality.label}
          name={classification.confidentiality.dataIndex}
          rules={classification.confidentiality.rules}
        >
          <classification.confidentiality.component disabled={!editMode} />
        </Form.Item>
        <Form.Item
          className="md:w-3/6"
          label={classification.integrity.label}
          name={classification.integrity.dataIndex}
          rules={classification.integrity.rules}
        >
          <classification.integrity.component disabled={!editMode} />
        </Form.Item>
      </div>
      <div className="flex sm:flex-row flex-col gap-x-4">
        <Form.Item
          className="md:w-3/6"
          label={classification.availability.label}
          name={classification.availability.dataIndex}
          rules={classification.availability.rules}
        >
          <classification.availability.component disabled={!editMode} />
        </Form.Item>
      </div>
    </div>
  );
};

export default PortfolioProductInformationSection;
