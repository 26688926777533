import React from 'react';

import { Typography } from 'antd';

import { ConversationMessage } from 'core/lib';

const { Text } = Typography;

const UserDeletedMessage = ({ message }: { message: ConversationMessage }) => {
  return (
    <Text className="p-1" type="secondary" italic>
      {message.content}
    </Text>
  );
};

export default UserDeletedMessage;
