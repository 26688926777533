import React from 'react';

import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

interface UserMessageProps {
  onCancel: () => void;
  onSave: () => void;
  loading: boolean;
}

const UserMessageEdit = ({ loading, onCancel, onSave }: UserMessageProps) => {
  const { t } = useTranslation();

  return (
    <div className="flex self-end">
      <div className="flex justify-end">
        <Button type="link" size="small" loading={loading} disabled={loading} onClick={onSave}>
          {t('actions:global.save')}
        </Button>
        <Button type="link" size="small" onClick={onCancel}>
          {t('actions:global.cancel')}
        </Button>
      </div>
    </div>
  );
};
export default UserMessageEdit;
