import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { DownOutlined } from '@ant-design/icons';
import { Button, Checkbox, Dropdown } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { intersection } from 'lodash';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';

import { DROPDOWN_TRIGGERS, FilterActions } from 'common-ui';
import { AppState } from 'core/lib';
import { organizationApi } from 'core/lib/modules/organization/services';
import { selectUserOrganizationFilters, setUserFilters } from 'core/lib/modules/users/store';

const mapState = (state: AppState) => ({
  userOrganizationsFilters: selectUserOrganizationFilters(state),
});

const mapDispatch = {
  applyFilters: (userOrganizationsFilters: number[] | undefined) =>
    setUserFilters({ organizations: userOrganizationsFilters }),
};

const connector = connect(mapState, mapDispatch);

type UserOrganizationsFilterProps = ConnectedProps<typeof connector>;

const UserOrganizationsFilter = ({ applyFilters, userOrganizationsFilters }: UserOrganizationsFilterProps) => {
  const [visible, setVisibility] = useState(false);
  const [draftFilters, setDraftFilters] = useState(userOrganizationsFilters);
  const { t } = useTranslation();

  const { data: userOrganizationOptions } = organizationApi.useGetUserOrganizationsQuery();

  const filterChecked = useCallback((organizationId: number) => draftFilters?.includes(organizationId), [draftFilters]);

  useEffect(() => {
    setDraftFilters(userOrganizationsFilters);
  }, [userOrganizationsFilters]);

  const filtersUpdated = useMemo(() => {
    return (
      userOrganizationsFilters?.length !== draftFilters?.length ||
      intersection(draftFilters, userOrganizationsFilters).length !== userOrganizationsFilters?.length
    );
  }, [draftFilters, userOrganizationsFilters]);

  const onFilterUpdate = (organizationId: number) => {
    if (filterChecked(organizationId)) {
      setDraftFilters(draftFilters?.filter((draftOrganizationId) => draftOrganizationId !== organizationId));
    } else {
      setDraftFilters((draftFilters ?? []).concat(organizationId));
    }
  };

  const onApply = () => {
    applyFilters(draftFilters);
  };

  const onClear = () => {
    setDraftFilters([]);
  };

  const items: ItemType[] = useMemo(() => {
    return [
      {
        key: 'filter_group',
        className: 'max-h-64 overflow-y-auto p-0',
        type: 'group',
        children: userOrganizationOptions?.map((item) => ({
          key: item.id,
          label: (
            <Checkbox
              onChange={() => onFilterUpdate(item.id)}
              checked={filterChecked(item.id)}
              className="flex flex-auto"
            >
              {item.label}
            </Checkbox>
          ),
        })),
      },
      { type: 'divider' },
      {
        type: 'group',
        key: 'status_actions',
        label: <FilterActions onClear={onClear} onApply={onApply} filtersUpdated={filtersUpdated} />,
      },
    ];
  }, [userOrganizationOptions, filtersUpdated, filterChecked, t]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <Dropdown
        trigger={DROPDOWN_TRIGGERS}
        onOpenChange={(val) => setVisibility(val)}
        mouseLeaveDelay={0.2}
        open={visible}
        menu={{ items }}
      >
        <Button>
          {t('user:filters.organizationCount', { count: userOrganizationsFilters?.length })} <DownOutlined />
        </Button>
      </Dropdown>
    </div>
  );
};

export default connector(UserOrganizationsFilter);
