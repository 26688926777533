import './index.css';
import 'antd/dist/antd.css';

import * as React from 'react';

import { setupIonicReact } from '@ionic/react';
import { createRoot } from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { Route, Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { QueryParamProvider } from 'use-query-params';

import 'core/lib/theme/colors.css';
import CapacitorApp from 'capacitorApp';
import store, { history } from 'core/lib/store';
import theme from 'core/lib/theme';
import i18n from 'core/lib/utils/i18n';
import 'utils/listeners';
import 'utils/logging';

import reportWebVitals from './reportWebVitals';
import AppRoutes from './routes';

const App = () => {
  return (
    <CapacitorApp>
      <I18nextProvider i18n={i18n}>
        <Provider store={store}>
          <Router history={history}>
            <ThemeProvider theme={theme}>
              <QueryParamProvider ReactRouterRoute={Route}>
                <AppRoutes />
              </QueryParamProvider>
            </ThemeProvider>
          </Router>
        </Provider>
      </I18nextProvider>
    </CapacitorApp>
  );
};

// Override the initial Ionic config for the app
// https://ionicframework.com/docs/react/config
setupIonicReact({
  rippleEffect: false,
});

const root = createRoot(document.getElementById('root')!);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
