import React from 'react';

type SideContainerProps = {
  children: React.ReactNode;
};

const SideContainer = ({ children }: SideContainerProps) => {
  return <div className="hidden lg:block w-1/4 min-w-64 sticky top-0 overflow-auto">{children}</div>;
};

export default SideContainer;
