import React from 'react';

import { Select } from 'antd';
import { useTranslation } from 'react-i18next';

import { FiltersContainer, FiltersTitle } from 'common-ui/filters';
import { ContactBookFilters } from 'core/lib';
import { CountryOptions } from 'core/lib/constants/selects/general';

type CountryFilterProps = {
  onChange: (filters: Partial<ContactBookFilters>) => void;
  filters: string[] | undefined;
};

const CountryFilter = ({ onChange, filters }: CountryFilterProps) => {
  const { t } = useTranslation();

  return (
    <FiltersContainer>
      <FiltersTitle title={t('contactBook:filters.country')} />
      <Select
        className="w-full"
        allowClear
        mode="multiple"
        value={filters}
        showSearch={false}
        placeholder={t('global:placeholders.selectType', { type: t('contactBook:filters.country') })}
        onChange={(country) => onChange({ country })}
        options={CountryOptions}
      />
    </FiltersContainer>
  );
};

export default CountryFilter;
