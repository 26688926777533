import React from 'react';

import { Cascader, CascaderProps, Input, InputProps, RadioGroupProps, Select, SelectProps, UploadProps } from 'antd';
import { isEmpty } from 'lodash';

import { RadioGroupSelect } from 'common-ui/inputs';
import { ImageUploader } from 'common-ui/upload';
import { Attachment } from 'core/lib';
import { OBJECT_TYPES } from 'core/lib/constants';
import { CountryOptions, LanguageOptions } from 'core/lib/constants/selects/general';
import { rules } from 'utils/form/rules';

import OrderSelect from './components/orderSelect';
import { ModulesStatusOptions, LeftMenuStatusOptions, ModulePreferenceOptions } from './utils';

export const userProfileFormConfig = {
  general: {
    avatar: {
      label: 'user:userProfileForm.general.labels.avatar',
      dataIndex: 'avatar',
      rules: [],
      component: ({ fileList, ...props }: UploadProps & { value?: Attachment }) => (
        <ImageUploader dataIndex="avatar" objectType={OBJECT_TYPES.USER} {...props} />
      ),
    },
    firstName: {
      label: 'user:userProfileForm.general.labels.firstName',
      dataIndex: 'firstName',
      rules: [rules.required],
      component: (props: InputProps) => <Input maxLength={40} {...props} />,
    },
    lastName: {
      label: 'user:userProfileForm.general.labels.lastName',
      dataIndex: 'lastName',
      rules: [rules.required],
      component: (props: InputProps) => <Input maxLength={40} {...props} />,
    },
    email: {
      label: 'user:userProfileForm.general.labels.email',
      dataIndex: 'email',
      rules: [rules.email, rules.required],
      component: (props: InputProps) => <Input type="email" maxLength={100} {...props} />,
    },
    number: {
      label: 'user:userProfileForm.general.labels.number',
      dataIndex: 'number',
      rules: [],
      component: (props: InputProps) => <Input maxLength={40} {...props} />,
    },
    country: {
      label: 'user:userProfileForm.general.labels.country',
      dataIndex: 'country',
      rules: [rules.required],
      component: (props: SelectProps<string>) => <Select options={CountryOptions} showSearch {...props} />,
    },
  },
  password: {
    currentPassword: {
      label: 'user:userProfileForm.password.labels.currentPassword',
      dataIndex: 'currentPassword',
      rules: [rules.required],
      component: (props: InputProps) => <Input.Password maxLength={40} {...props} />,
    },
    newPassword: {
      label: 'user:userProfileForm.password.labels.newPassword',
      dataIndex: 'newPassword',
      rules: [rules.required, rules.password],
      component: (props: InputProps) => <Input.Password maxLength={40} {...props} />,
    },
    confirmPassword: {
      label: 'user:userProfileForm.password.labels.confirmPassword',
      dataIndex: 'confirmPassword',
      rules: [rules.required],
      component: (props: InputProps) => <Input.Password maxLength={40} {...props} />,
    },
  },
  preferences: {
    homepage: {
      label: 'user:userProfileForm.preferences.labels.homepage',
      dataIndex: 'homepage',
      rules: [],
      component: (props: CascaderProps<ModulePreferenceOptions>) => (
        <Cascader
          allowClear={false}
          displayRender={(label) => {
            return label.filter((r) => !isEmpty(r)).join(' / ');
          }}
          {...props}
        />
      ),
    },
    leftMenu: {
      label: 'user:userProfileForm.preferences.labels.leftMenu',
      dataIndex: 'leftMenuOpen',
      rules: [],
      component: (props: RadioGroupProps) => (
        <RadioGroupSelect {...props} className="flex flex-auto border-none w-full" options={LeftMenuStatusOptions} />
      ),
    },
    menuDefaultType: {
      label: 'user:userProfileForm.preferences.labels.modulesMenu',
      dataIndex: 'menuDefaultType',
      rules: [],
      component: (props: RadioGroupProps) => (
        <RadioGroupSelect {...props} className="flex flex-auto border-none w-full" options={ModulesStatusOptions} />
      ),
    },
    language: {
      label: 'user:userProfileForm.preferences.labels.language',
      dataIndex: 'language',
      rules: [],
      component: (props: SelectProps<string>) => <Select {...props} options={LanguageOptions} />,
    },
    menuPreferences: {
      label: 'user:userProfileForm.preferences.labels.modulesOrder',
      dataIndex: 'menuPreferences',
      rules: [],
      component: OrderSelect,
    },
  },
  notificaitons: {},
};
