import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { DownOutlined } from '@ant-design/icons';
import { Button, Checkbox, Dropdown } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { intersection } from 'lodash';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';

import { DROPDOWN_TRIGGERS, FilterActions } from 'common-ui';
import { AppState } from 'core/lib';
import { UserSystemRoleId } from 'core/lib/constants';
import { RolesOptions } from 'core/lib/constants/selects';
import { selectUserRoleFilters, setUserFilters } from 'core/lib/modules/users/store';

const mapState = (state: AppState) => ({
  userRolesFilters: selectUserRoleFilters(state),
});

const mapDispatch = {
  applyFilters: (userRolesFilters: UserSystemRoleId[] | undefined) => setUserFilters({ roles: userRolesFilters }),
};

const connector = connect(mapState, mapDispatch);

type UserRolesFilterProps = ConnectedProps<typeof connector>;

const UserRolesFilter = ({ applyFilters, userRolesFilters }: UserRolesFilterProps) => {
  const [visible, setVisibility] = useState(false);
  const [draftFilters, setDraftFilters] = useState(userRolesFilters);
  const { t } = useTranslation();

  const filterChecked = useCallback((roleId: number) => draftFilters?.includes(roleId), [draftFilters]);

  useEffect(() => {
    setDraftFilters(userRolesFilters);
  }, [userRolesFilters]);

  const filtersUpdated = useMemo(() => {
    return (
      userRolesFilters?.length !== draftFilters?.length ||
      intersection(draftFilters, userRolesFilters).length !== userRolesFilters?.length
    );
  }, [draftFilters, userRolesFilters]);

  const onFilterUpdate = (roleId: number) => {
    if (filterChecked(roleId)) {
      setDraftFilters(draftFilters?.filter((draftRoleId) => draftRoleId !== roleId));
    } else {
      setDraftFilters((draftFilters ?? []).concat(roleId));
    }
  };

  const onApply = () => {
    applyFilters(draftFilters);
  };

  const onClear = () => {
    setDraftFilters([]);
  };

  const items: ItemType[] = useMemo(() => {
    return [
      {
        key: 'filter_group',
        className: 'max-h-64 overflow-y-auto p-0',
        type: 'group',
        children: RolesOptions.map((item) => ({
          key: item.id,
          label: (
            <Checkbox
              onChange={() => onFilterUpdate(item.id)}
              checked={filterChecked(item.id)}
              className="flex flex-auto"
            >
              {item.label}
            </Checkbox>
          ),
        })),
      },
      { type: 'divider' },
      {
        type: 'group',
        key: 'status_actions',
        label: <FilterActions onClear={onClear} onApply={onApply} filtersUpdated={filtersUpdated} />,
      },
    ];
  }, [filtersUpdated, filterChecked, t]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <Dropdown
        trigger={DROPDOWN_TRIGGERS}
        onOpenChange={(val) => setVisibility(val)}
        mouseLeaveDelay={0.2}
        open={visible}
        menu={{ items }}
      >
        <Button>
          {t('user:filters.rolesCount', { count: userRolesFilters?.length })} <DownOutlined />
        </Button>
      </Dropdown>
    </div>
  );
};

export default connector(UserRolesFilter);
