import { ReactRenderer } from '@tiptap/react';
import { SuggestionProps, SuggestionKeyDownProps } from '@tiptap/suggestion';
import tippy, { Props, Instance, GetReferenceClientRect } from 'tippy.js';

import { MentionList, MentionListRef } from './mentionList';

export interface MentionableListHandle {
  onKeyDown: ({ event }: { event: KeyboardEvent }) => boolean;
}

const Suggestions = () => {
  const render = () => {
    let component: ReactRenderer<MentionListRef>;
    let popup: Instance<Props>[];
    return {
      onStart: (props: SuggestionProps) => {
        component = new ReactRenderer(MentionList, {
          props,
          editor: props.editor,
        });

        popup = tippy('body', {
          getReferenceClientRect: props.clientRect as GetReferenceClientRect,
          appendTo: () => document.body,
          content: component.element,
          showOnCreate: true,
          interactive: true,
          trigger: 'manual',
          placement: 'bottom-start',
        });
      },

      onUpdate(props: SuggestionProps) {
        component.updateProps(props);

        popup[0].setProps({
          getReferenceClientRect: props.clientRect as GetReferenceClientRect,
        });
      },

      onKeyDown(props: SuggestionKeyDownProps) {
        if (props.event.key === 'Escape') {
          popup[0].hide();

          return true;
        }

        return !!component.ref?.onKeyDown(props);
      },

      onExit() {
        popup[0].destroy();
        component.destroy();
      },
    };
  };

  return { render };
};

export default Suggestions;
