import React from 'react';

import { Drawer } from 'antd';
import { DrawerProps } from 'antd/lib/drawer';
import { Route, Switch, useLocation } from 'react-router-dom';
import { useMedia, useUpdateEffect } from 'react-use';
import AutoSizer from 'react-virtualized-auto-sizer';
import styled from 'styled-components';

import { selectLeftMenuState, setLeftMenuToggled, useAppDispatch, useAppSelector } from 'core/lib';
import { OBJECT_TYPES } from 'core/lib/constants';
import ContactBookLeftMenu from 'modules/contactBook/leftMenu';
import ConversationLeftMenu from 'modules/conversations/leftMenu';
import DealBaseLeftMenu from 'modules/dealBase/leftMenu';
import { CustomFieldsLeftMenu } from 'modules/general';
import PortfolioLeftMenu from 'modules/portfolio/leftMenu';
import UserLeftMenu from 'modules/user/leftMenu';
import { Routes } from 'routes/routes';
import ModuleControl from 'utils/moduleControl';

const StyledDrawer = styled(Drawer)<DrawerProps>`
  &&& {
    position: relative;
  }
`;

const LeftMenu = () => {
  const dispatch = useAppDispatch();
  const leftMenuToggled = useAppSelector(selectLeftMenuState);
  const isTablet = useMedia('(max-width: 1023px)');
  const location = useLocation();

  useUpdateEffect(() => {
    if (isTablet) {
      dispatch(setLeftMenuToggled(false));
    }
  }, [isTablet, location]);

  const toggleLeftMenu = (toggled: boolean) => {
    dispatch(setLeftMenuToggled(toggled));
  };

  return (
    <Switch>
      <Route
        path={[
          Routes.CONTACT_BOOK.path,
          Routes.CONVERSATIONS.path,
          Routes.DEAL_BASE.path,
          Routes.CUSTOM_FIELDS.path,
          Routes.PORTFOLIO.path,
          Routes.USER.path,
        ]}
        render={() => (
          <div
            role="complementary"
            style={{ height: isTablet ? 'calc(100% - 106px)' : '100%' }}
            className={`flex z-10	 lg:relative fixed shadow lg:shadow-none ${
              leftMenuToggled ? 'lg:w-1/5 md:w-2/4 w-full min-w-64 shrink-0' : 'w-0 sm:px-0'
            } `}
          >
            <AutoSizer disableHeight className="flex">
              {({ width }) => (
                <StyledDrawer
                  placement="left"
                  mask={false}
                  maskClosable={true}
                  closable={false}
                  open={leftMenuToggled}
                  key="leftMenuSideBar"
                  forceRender
                  getContainer={false}
                  contentWrapperStyle={{ boxShadow: 'none' }}
                  bodyStyle={{ overflow: 'hidden', padding: 0 }}
                  onClose={() => toggleLeftMenu(false)}
                  width={width}
                >
                  <Switch>
                    <Route path={Routes.CONVERSATIONS.path} component={ConversationLeftMenu} />
                    <Route
                      path={Routes.DEAL_BASE.path}
                      render={() => (
                        <ModuleControl module={OBJECT_TYPES.DEAL_BASE}>
                          <DealBaseLeftMenu />
                        </ModuleControl>
                      )}
                    />
                    <Route path={Routes.CUSTOM_FIELDS.path} component={CustomFieldsLeftMenu} />
                    <Route
                      path={Routes.PORTFOLIO.path}
                      render={() => (
                        <ModuleControl module={OBJECT_TYPES.PORTFOLIO}>
                          <PortfolioLeftMenu />
                        </ModuleControl>
                      )}
                    />
                    <Route path={Routes.USER.path} component={UserLeftMenu} />
                    <Route path={Routes.CONTACT_BOOK.path} component={ContactBookLeftMenu} />
                  </Switch>
                </StyledDrawer>
              )}
            </AutoSizer>
          </div>
        )}
      />
    </Switch>
  );
};

export default LeftMenu;
