import React, { useEffect, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { Route, Switch, Redirect, generatePath } from 'react-router-dom';
import { useMedia } from 'react-use';

import PageNotFound from 'common-ui/wrappers/pageNotFound';
import { AppState, refreshToken, RouteTypes, selectPendingAuthentication, setLeftMenuToggled } from 'core/lib';
import { SYSTEM_ROLES } from 'core/lib/constants';
import { selectUserPreference } from 'core/lib/modules/app/store/selectors';
import authApi from 'core/lib/modules/auth/services/auth.api';
import AppLayout from 'modules/appLayout';
import AppPreferencesWrapper from 'modules/appLayout/preferences/appPreferences';
import LoginForm from 'modules/authentication/login';
import UserPreferencesWrapper from 'modules/authentication/userPreferences';
import ContactBook from 'modules/contactBook';
import Conversations from 'modules/conversations/index';
import DealBase from 'modules/dealBase';
import ExternalDealBase from 'modules/dealBase/external';
import ExternalOldRedirect from 'modules/dealBase/external/externalOldRedirect';
import { CustomFields, Welcome } from 'modules/general';
import Organization from 'modules/organization';
import Portfolio from 'modules/portfolio';
import User from 'modules/user';
import UserProfile from 'modules/userProfile';
import { getTimezoneOffset, getRouteByObjectTypeAndRouteType } from 'utils';
import LoaderWrapper from 'utils/loader-wrapper';
import PermissionControl, { PermissionRules } from 'utils/permissionControl';

import { Routes } from './routes';

const DEFAULT_ROUTE = generatePath(Routes.WELCOME.path);

const mapState = (state: AppState) => ({
  pendingAuthentication: selectPendingAuthentication(state),
  userPreferences: selectUserPreference(state),
});

const mapDispatch = {
  refreshToken,
  setLeftMenuToggled,
};

const connector = connect(mapState, mapDispatch);
type AppRoutesProps = ConnectedProps<typeof connector>;

const AppRoutes = ({ refreshToken, setLeftMenuToggled, pendingAuthentication, userPreferences }: AppRoutesProps) => {
  const { ready } = useTranslation();
  const isTablet = useMedia('(max-width: 1023px)');

  const [getStatus] = authApi.useLazyGetStatusQuery();

  useEffect(() => {
    if (isTablet) {
      setLeftMenuToggled(false);
    }
  });

  useEffect(() => {
    refreshToken();
  }, [refreshToken]);

  useEffect(() => {
    getStatus(getTimezoneOffset());
  }, [getStatus]);

  const homepage = useMemo(() => {
    if (userPreferences && userPreferences.homepageObjectType) {
      return (
        getRouteByObjectTypeAndRouteType(
          userPreferences.homepageObjectType,
          userPreferences?.homepageType as RouteTypes
        ) ?? DEFAULT_ROUTE
      );
    }

    return DEFAULT_ROUTE;
  }, [userPreferences]);

  return (
    <LoaderWrapper loading={!ready || pendingAuthentication}>
      <Switch>
        <Route path={Routes.LOGIN.path} component={LoginForm} />
        <Route path={Routes.EXTERNAL_OLD.path} component={ExternalOldRedirect} />
        <Route path={[Routes.EXTERNAL_FORM.path, Routes.EXTERNAL_FORM_OLD.path]} component={ExternalDealBase} />
        <AppLayout>
          <AppPreferencesWrapper>
            <UserPreferencesWrapper>
              <Switch>
                <Route path={Routes.DEFAULT.path} exact render={() => <Redirect to={homepage} />} />
                <Route path={Routes.PORTFOLIO.path} component={Portfolio} />
                <Route path={Routes.DEAL_BASE.path} component={DealBase} />
                <Route path={Routes.CONTACT_BOOK.path} component={ContactBook} />
                <Route path={Routes.CONVERSATIONS.path} component={Conversations} />
                <Route path={Routes.USER_PROFILE.path} component={UserProfile} />
                <Route path={Routes.WELCOME.path} component={Welcome} />
                <Route
                  path={[Routes.ORGANIZATION.path, Routes.CUSTOM_FIELDS.path, Routes.USER.path]}
                  component={() => (
                    <PermissionControl
                      roles={[SYSTEM_ROLES.CLIENT_ADMIN, SYSTEM_ROLES.SUPER_ADMIN]}
                      rule={PermissionRules.ANY}
                      render={(forbidden) => (
                        <Switch>
                          {forbidden && <Redirect to={homepage} />}
                          <Route path={Routes.ORGANIZATION.path} component={Organization} />
                          <Route path={Routes.CUSTOM_FIELDS.path} component={CustomFields} />
                          <Route path={Routes.USER.path} component={User} />
                        </Switch>
                      )}
                    ></PermissionControl>
                  )}
                />
                <Route path={['/', Routes.PAGE_404.path]} component={PageNotFound} />
                <Redirect to={homepage} />
              </Switch>
            </UserPreferencesWrapper>
          </AppPreferencesWrapper>
        </AppLayout>
      </Switch>
    </LoaderWrapper>
  );
};

export default connector(AppRoutes);
