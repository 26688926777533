import React from 'react';

import { Form } from 'antd';

import DatePicker, { PickerProps } from 'common-ui/datePicker';
import { parseJSONDate } from 'core/lib/utils';

import { ListingEditableCellProps } from './types';

const DatePickerComponent = (props: PickerProps<Date>) => {
  return <DatePicker {...props} value={props.value && parseJSONDate(props.value)} />;
};

const ListingDatePicker = ({ dataIndex, rules, ...props }: ListingEditableCellProps & PickerProps<Date>) => {
  return (
    <Form.Item name={dataIndex} className="m-0" rules={rules}>
      <DatePickerComponent {...props} />
    </Form.Item>
  );
};

export default ListingDatePicker;
