import React, { useEffect } from 'react';

import { useAppSelector, selectWebSocketStatus } from 'core/lib';
import {
  directMenuUpdate,
  globalConversationUnreadMessage,
  groupConversationDetailsUpdate,
  directConversationDetailsUpdate,
  groupMenuUpdate,
  messageReceived,
  moduleConversationDetailsUpdate,
  moduleMenuUpdate,
  unreadGroupConversationMessage,
  unreadModuleConversationMessage,
  unreadDirectConversationMessage,
  moduleConversationsListingUpdate,
} from 'core/lib/modules/conversations/store/events';
import { HubConnectionState } from 'core/lib/utils/signalR/utils';
import { useHub } from 'utils/hooks/hubHooks';

type HubSubscriptionsWrapperProps = {
  children: React.ReactNode;
};

const HubSubscriptions = ({ children }: HubSubscriptionsWrapperProps) => {
  const [hub] = useHub();

  const webSocketStatus = useAppSelector(selectWebSocketStatus);

  useEffect(() => {
    hub.conversations.connectHub(
      {
        messageReceived,
        moduleMenuUpdate,
        groupMenuUpdate,
        directMenuUpdate,
        moduleConversationsListingUpdate,
        moduleConversationDetailsUpdate,
        groupConversationDetailsUpdate,
        directConversationDetailsUpdate,
        unreadGroupConversationMessage,
        unreadModuleConversationMessage,
        unreadDirectConversationMessage,
        globalConversationUnreadMessage,
      },
      {}
    );
    hub.conversations.start();

    return () => hub.conversations.disconnect();
  }, [hub.conversations]);

  useEffect(() => {
    if (webSocketStatus === HubConnectionState.Connected) {
      hub.conversations.joinGlobalConversationsUnreadMessages();
    }
    return () => {
      hub.conversations.leaveGlobalConversationsUnreadMessages();
    };
  }, [hub.conversations, webSocketStatus]);

  return <>{children}</>;
};

export default HubSubscriptions;
