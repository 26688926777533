import React from 'react';

import { Typography } from 'antd';

const { Title } = Typography;

type FiltersTitleProps = {
  title: string;
};

const FiltersTitle = ({ title }: FiltersTitleProps) => {
  return (
    <Title className="m-0" level={5}>
      {title}
    </Title>
  );
};

export default FiltersTitle;
