import React, { useMemo, useState } from 'react';

import { EllipsisOutlined } from '@ant-design/icons';
import { Button, ButtonProps, Dropdown, message, Popconfirm } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router-dom';

import { DROPDOWN_TRIGGERS, MenuItemConversationAction } from 'common-ui';
import { contactBookApi, ContactBookDetails } from 'core/lib';
import { OBJECT_TYPES } from 'core/lib/constants';
import { Routes } from 'routes/routes';

type DetailsHeaderActionsProps = {
  contactBook: ContactBookDetails;
};

const DetailsHeaderActions = ({ contactBook, ...props }: DetailsHeaderActionsProps & ButtonProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [confirmDeleteOpen, toggleConfirmDelete] = useState(false);

  const [onContactBookDelete] = contactBookApi.useDeleteContactBookMutation();

  const onDelete = () => {
    toggleConfirmDelete(false);
    onContactBookDelete(contactBook.id)
      .unwrap()
      .then(() => {
        message.success(
          t('actions:message.successDelete', { type: t('global:moduleNames.short.contactbook').toLocaleLowerCase() })
        );
        history.push(generatePath(Routes.CONTACT_BOOK_LISTING.path));
      })
      .catch(() => {
        message.error(t('actions:message.deleteDeny', { object: contactBook.name }));
      });
  };

  const items: ItemType[] = useMemo(() => {
    return [
      {
        key: 'conversation_action',
        label: (
          <MenuItemConversationAction
            objectType={OBJECT_TYPES.CONTACT_BOOK}
            objectId={contactBook.id}
            objectName={contactBook.name}
          />
        ),
      },
      { type: 'divider' },
      {
        danger: true,
        className: 'warning',
        key: 'delete',
        onClick: () => {
          toggleConfirmDelete(true);
        },
        label: t('actions:global.delete'),
      },
    ];
  }, [contactBook, t]); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Popconfirm
        open={confirmDeleteOpen}
        overlayClassName="max-w-80"
        title={t('actions:confirmation.confirmDelete', { type: t('contactBook:contact').toLocaleLowerCase() })}
        okText={t('actions:global.delete')}
        onCancel={() => toggleConfirmDelete(false)}
        onConfirm={onDelete}
        okButtonProps={{ danger: true }}
      />
      <Dropdown trigger={DROPDOWN_TRIGGERS} menu={{ items }}>
        <Button {...props} type="default">
          <EllipsisOutlined />
        </Button>
      </Dropdown>
    </>
  );
};

export default DetailsHeaderActions;
