import React from 'react';

import { Form, Typography } from 'antd';
import { Trans } from 'react-i18next';

import { CommonObjectOption } from 'core/lib';
import colors from 'core/lib/theme/colors';
import { contactBookFormConfig } from 'modules/contactBook/form/formConfig';

const { Text } = Typography;

const {
  details: { professionalInformation },
} = contactBookFormConfig;

type ProfessionalInformationIndustryExpertProps = {
  editMode: boolean;
};

const ProfessionalInformationIndustryExpert = ({ editMode }: ProfessionalInformationIndustryExpertProps) => {
  return (
    <div className="flex flex-col">
      <Text className="my-4" style={{ color: colors.Primary.rgba }}>
        <Trans i18nKey="contactBook:types.industryExpert" />
      </Text>
      <div className="flex sm:flex-row flex-col gap-x-4">
        <Form.Item
          className="md:w-3/6"
          label={professionalInformation.industry.label}
          name={professionalInformation.industry.dataIndex}
          rules={professionalInformation.industry.rules}
          valuePropName="selectedOption"
          normalize={(options: CommonObjectOption[]) => {
            return options.map((opt) => ({ id: opt.id, name: opt.label }));
          }}
        >
          <professionalInformation.industry.component disabled={!editMode} />
        </Form.Item>
      </div>
    </div>
  );
};

export default ProfessionalInformationIndustryExpert;
