import React from 'react';

import { Button, Popover, Tag } from 'antd';
import { Trans } from 'react-i18next';
import { Link, generatePath } from 'react-router-dom';

import { UserAvatar } from 'common-ui/links';
import { TagsGroup } from 'common-ui/tags';
import { DealBase, dealBaseApi } from 'core/lib';
import { DealBaseDetailsPageHeader } from 'modules/dealBase/components';
import { Routes } from 'routes/routes';
import LoaderWrapper from 'utils/loader-wrapper';

import { PopoverFieldLabel } from './components';

type DealBasePopoverProps = {
  id: number | string;
  name: string;
};

const DealBasePopover = ({ id, name }: DealBasePopoverProps) => {
  const [getDealBaseInfo, { data: dealBaseInfo, isFetching }] = dealBaseApi.useLazyGetDealBaseInfoQuery();

  const onDealBasePopoverOpen = (open: boolean) => {
    if (open) {
      getDealBaseInfo(id.toString(), true);
    }
  };

  return (
    <Popover
      arrowPointAtCenter
      onOpenChange={onDealBasePopoverOpen}
      overlayClassName="w-auto max-w-80"
      content={
        <LoaderWrapper loading={isFetching}>
          {dealBaseInfo && (
            <div className="flex flex-col px-1 gap-4">
              {dealBaseInfo.industries?.length ? (
                <div className="flex flex-col gap-2">
                  <PopoverFieldLabel i18nKey="dealbase:shortInfo.labels.industry" />
                  <TagsGroup className="text-ellipsis overflow-hidden whitespace-nowrap">
                    {dealBaseInfo.industries.map((industry) => (
                      <Tag key={industry.id} color="green">
                        {industry.name}
                      </Tag>
                    ))}
                  </TagsGroup>
                </div>
              ) : null}
              {dealBaseInfo.technology && (
                <div className="flex flex-col gap-2">
                  <PopoverFieldLabel i18nKey="dealbase:shortInfo.labels.technology" />
                  <div>
                    <Tag key={dealBaseInfo.technology.id} color="red">
                      {dealBaseInfo.technology.name}
                    </Tag>
                  </div>
                </div>
              )}
              {dealBaseInfo.customerFocus?.length ? (
                <div className="flex flex-col gap-2">
                  <PopoverFieldLabel i18nKey="dealbase:shortInfo.labels.customerFocus" />
                  <TagsGroup className="text-ellipsis overflow-hidden whitespace-nowrap">
                    {dealBaseInfo.customerFocus.map((focus) => (
                      <Tag key={focus.id} color="cyan">
                        {focus.name}
                      </Tag>
                    ))}
                  </TagsGroup>
                </div>
              ) : null}
              {dealBaseInfo.assigned && (
                <div className="flex flex-col gap-2">
                  <PopoverFieldLabel i18nKey="dealbase:shortInfo.labels.assigned" />
                  <div className="flex">
                    <UserAvatar user={dealBaseInfo.assigned} />
                  </div>
                </div>
              )}
              <Link to={generatePath(Routes.DEAL_BASE_DETAILS.path, { id })}>
                <Button size="small" type="default">
                  <Trans i18nKey="dealbase:actions.seeDetails" />
                </Button>
              </Link>
            </div>
          )}
        </LoaderWrapper>
      }
      title={
        <div className="px-1 py-1 max-w-96">
          {dealBaseInfo && (
            <DealBaseDetailsPageHeader defaultSize="small" className="p-0" dealbase={dealBaseInfo as DealBase} />
          )}
        </div>
      }
      trigger="hover"
    >
      <Link key={id} to={generatePath(Routes.DEAL_BASE_DETAILS.path, { id })}>
        {name}
      </Link>
    </Popover>
  );
};

export default DealBasePopover;
