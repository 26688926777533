export default class Color {
  r: number;
  g: number;
  b: number;
  a: number;
  css: { [key: string]: string };
  constructor(r: number, g: number, b: number, a = 1) {
    this.r = Math.max(0, Math.min(r, 255));
    this.g = Math.max(0, Math.min(g, 255));
    this.b = Math.max(0, Math.min(b, 255));
    this.a = Math.max(0, Math.min(a, 255));
    this.css = {};
  }

  static rgba(r: number, g: number, b: number, a: number) {
    return new Color(r, g, b, a);
  }

  static fromRGBA(rgbaString: string) {
    if (rgbaString) {
      const [r, g, b, a] = rgbaString
        .replace(/^rgba?\(|\s+|\)$/g, '')
        .split(',')
        .map(Number);
      return new Color(r, g, b, a);
    }
  }

  static hex(hex: string, alpha?: number) {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return new Color(r, g, b, alpha);
  }

  get rgba() {
    return `rgba(${this.r}, ${this.g}, ${this.b}, ${this.a})`;
  }

  get hex() {
    const number = (x: number) => {
      const str = x.toString(16).substring(0, 2);
      return str.length < 2 ? `${'0'.repeat(str.length)}${str}` : str;
    };
    return `${number(this.r)}${number(this.g)}${number(this.b)}`;
  }

  static hash(str: string) {
    let hash = 5381,
      i = str.length;

    while (i) {
      hash = (hash * 33) ^ str.charCodeAt(--i);
    }
    return hash >>> 0;
  }

  static fromString = (text: string) => {
    const hashNumber = this.hash(text);
    return `#${(hashNumber & 0x00ffffff).toString(16).padStart(6, '0')}`;
  };

  darken(value: number) {
    const factor = Math.round(255 * value);
    return new Color(this.r - factor, this.g - factor, this.b - factor, this.a);
  }

  lighten(value: number) {
    const factor = Math.round(255 * value);
    return new Color(this.r + factor, this.g + factor, this.b + factor, this.a);
  }

  alpha(value: number | undefined) {
    return new Color(this.r, this.g, this.b, value);
  }

  blend(using: Color, alpha = 1) {
    const r = Math.round((this.r + using.r) / 2);
    const g = Math.round((this.g + using.g) / 2);
    const b = Math.round((this.b + using.b) / 2);
    return new Color(r, g, b, alpha);
  }

  toString() {
    return this.rgba;
  }
}
