import React, { useMemo } from 'react';

import { Pie, PieConfig, Datum } from '@ant-design/charts';
import { View } from '@antv/g2';
import { Data } from '@antv/g2plot';
import { Typography } from 'antd';
import { sumBy, uniqBy } from 'lodash';
import { useTranslation } from 'react-i18next';

import { commonObjectsApi } from 'core/lib';
import { DYNAMIC, OBJECT_TYPES } from 'core/lib/constants';
import { PortfolioListing } from 'core/lib/modules/portfolio/entities';
import LoaderWrapper from 'utils/loader-wrapper';

const { Title } = Typography;

type PortfolioByTypeProps = {
  data?: PortfolioListing[];
};

type ChartSeries = {
  name: string;
  value: number;
}[];

const PortfoliosByType = ({ data }: PortfolioByTypeProps) => {
  const { t } = useTranslation();
  const { data: activeTypes = [], isFetching } = commonObjectsApi.useGetCommonObjectsQuery({
    type: DYNAMIC.PORTFOLIO.TYPE,
    objectType: OBJECT_TYPES.PORTFOLIO,
    archived: false,
  });

  const listTypes = useMemo(() => data?.filter((item) => item.type).map((item) => item.type) ?? [], [data]);

  const types = useMemo(() => uniqBy(listTypes.concat(activeTypes), 'id'), [activeTypes, listTypes]);

  const dataCountByType: { [key: string]: number } = useMemo(() => {
    return types.reduce((dataCount, type) => {
      dataCount[type.name] = data?.filter((item) => item.type?.id === type.id)?.length ?? 0;
      return dataCount;
    }, {});
  }, [data, types]);

  const series: ChartSeries = useMemo(() => {
    return Object.entries(dataCountByType).map(([name, value]) => ({
      name,
      value,
    }));
  }, [dataCountByType]);

  const config: PieConfig = {
    height: 300,
    appendPadding: 40,
    data: series,
    angleField: 'value',
    colorField: 'name',
    radius: 1,
    innerRadius: 0.6,
    label: false,
    legend: {
      itemWidth: 100,
      itemValue: {
        formatter: (text, item, index) => {
          return series[index].value;
        },
      },
      offsetX: -20,
      layout: 'vertical',
    },
    statistic: {
      title: {
        style: {
          fontSize: '1em',
        },
      },

      content: {
        style: { fontSize: '2em' },
        customHtml: (container: HTMLElement, view: View, datum?: Datum, data?: Data) => {
          //container.addEventListener('click', handleChartStatisticsClick);
          container.style.pointerEvents = 'auto';
          container.style.cursor = 'pointer';
          return sumBy(data as ChartSeries, (statusData) => statusData.value).toString();
        },
      },
    },
  };

  return (
    <LoaderWrapper loading={isFetching}>
      <div className="flex flex-col w-full gap-2">
        <Title level={5}>{t('portfolio:dashboards.titles.portfoliosByType')}</Title>
        <div className="w-full border border-solid b--grey-200">
          <Pie {...config} />
        </div>
      </div>
    </LoaderWrapper>
  );
};

export default PortfoliosByType;
