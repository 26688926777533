import React from 'react';

import { List } from 'antd';
import { useTranslation } from 'react-i18next';

import { DateSince } from 'common-ui';
import { UserNotification } from 'core/lib';

import { NotificationCardConversationIcon, NotificationCardWrapper } from '../components';

type ConversationRemoveCardProps = {
  notification: UserNotification;
  onClick: (id: number) => void;
};

const ConversationRemoveCard = ({ notification, onClick }: ConversationRemoveCardProps) => {
  const { t } = useTranslation();

  const [type] = notification.action.split('_');
  return (
    <NotificationCardWrapper isLink={false} key={notification.id} notification={notification} onClick={onClick}>
      <List.Item.Meta
        avatar={<NotificationCardConversationIcon />}
        title={
          <div className="gap-2">
            {`${t(`notifications:conversations.removedFromConversation`)} `}
            {<span className="text-primary bold">{notification.objectName}</span>}
            {` ${t(`notifications:conversations.${type}`).toLocaleLowerCase()}`}
          </div>
        }
        description={<DateSince date={notification.dateCreated} />}
      />
    </NotificationCardWrapper>
  );
};

export default ConversationRemoveCard;
