import { createEntityAdapter, createSlice, EntityState } from '@reduxjs/toolkit';

import { parseJSONDate } from 'core/lib/utils';
import { dateSorter } from 'core/lib/utils/sorters';

import { UserNotification } from '..';
import { logout } from '../../auth';
import notificationApi from '../services/notifications.api';

export const notificationsAdapter = createEntityAdapter<UserNotification>({
  // Keep the "all IDs" array sorted based on date created
  sortComparer: (a, b) => dateSorter(parseJSONDate(b.dateCreated), parseJSONDate(a.dateCreated), 'descend'),
});

type NotificationState = {
  page: number;
  notifications: EntityState<UserNotification>;
};

const initialState: NotificationState = {
  page: 0,
  notifications: notificationsAdapter.getInitialState(),
};

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setPage: (state, { payload }: { payload: number }) => {
      state.page = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout, () => {
      return initialState;
    });
    builder.addMatcher(notificationApi.endpoints.getNotificationsByPage.matchFulfilled, (state, { payload, meta }) => {
      state.page = meta.arg.originalArgs ?? 0;
      notificationsAdapter.upsertMany(state.notifications, payload);
    });
    builder.addMatcher(notificationApi.endpoints.postNotificationVisited.matchFulfilled, (state, { payload, meta }) => {
      const visitedNotification = state.notifications.entities[meta.arg.originalArgs];
      if (visitedNotification) {
        visitedNotification.visited = true;
      }
    });
    builder.addMatcher(
      notificationApi.endpoints.postAllNotificationsVisited.matchFulfilled,
      (state, { payload, meta }) => {
        Object.keys(state.notifications.entities).forEach((key) => {
          state.notifications.entities[key]!.visited = true;
        });
      }
    );
  },
});

export const { setPage } = notificationSlice.actions;

export default notificationSlice.reducer;
