import React, { useMemo } from 'react';

import { Collapse, CollapseProps } from 'antd';
import { debounce, differenceWith, keyBy, merge, pick, transform, values } from 'lodash';

import { DetailsSectionPanel, detailsSectionPreferenceApi } from 'core/lib';

type DetailsSectionCollapseProps = {
  defaultSections: Omit<DetailsSectionPanel, 'id'>[];
  keepDefault?: boolean;
  objectType: number;
  children: React.ReactNode;
};

const DetailsSectionCollapse = ({
  children,
  defaultSections,
  objectType,
  keepDefault = false,
  ...props
}: DetailsSectionCollapseProps & CollapseProps) => {
  const [saveHeader] = detailsSectionPreferenceApi.usePostDetailsSectionItemMutation();

  const defaultSectionKeys = useMemo(() => defaultSections.map(({ name }) => name), [defaultSections]);

  const { generalSectionPanels } = detailsSectionPreferenceApi.useGetDetailsSectionHeadersQuery(objectType, {
    selectFromResult: ({ data, ...rest }) => {
      const defaultSectionItems = keyBy(defaultSections, 'name');
      return {
        ...rest,
        generalSectionPanels: merge(defaultSectionItems, pick(keyBy(data, 'name'), defaultSectionKeys)),
      };
    },
  });

  const onPanelStateChange = debounce((panelKey: string | string[]) => {
    const updatedSections = transform(
      generalSectionPanels,
      (result, value, key) => {
        result[key] = { ...value, collapsed: !panelKey.includes(value.name) };
      },
      {}
    ) as typeof generalSectionPanels;

    const updatedDetailsSectionItems = differenceWith(
      values(updatedSections),
      values(generalSectionPanels),
      (updated, current) => {
        return updated.name === current.name && updated.collapsed === current.collapsed;
      }
    );

    updatedDetailsSectionItems.forEach(onItemUpdate);
  }, 1000);

  const onItemUpdate = (header?: DetailsSectionPanel) => {
    if (header && !keepDefault) {
      saveHeader({ header, objectType });
    }
  };

  const mergedSectionKeys = useMemo(
    () =>
      values(generalSectionPanels)
        ?.filter((section) => !section.collapsed)
        .map((section) => section.name),
    [generalSectionPanels]
  );

  return (
    <Collapse
      onChange={onPanelStateChange}
      defaultActiveKey={keepDefault ? defaultSectionKeys : mergedSectionKeys}
      {...props}
    >
      {children}
    </Collapse>
  );
};

export default DetailsSectionCollapse;
