import React, { useMemo, useState, useEffect } from 'react';

import { Editor, JSONContent } from '@tiptap/react';
import { Form } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';

import { DateRecord } from 'common-ui';
import { parseStringToContent } from 'common-ui/wysiwyg/utils';
import { BaseUser, ConversationMessage, conversationsApi } from 'core/lib';
import { CONVERSATION_MESSAGE_STATUS } from 'core/lib/constants';
import { DEFAULT_DATETIME_FORMAT, DEFAULT_TIME_HOUR_MINUTES } from 'core/lib/constants/defaults';
import { useLoggedInUser } from 'utils/hooks';

import {
  StyledDateContainer,
  StyledOptionsContainer,
  StyledUserSequenceDate,
  StyledUserSequenceMessageWrapper,
} from './styles';
import UserDeletedMessage from './userDeletedMessage';
import UserMessageActions from './userMessageActions';
import UserMessageEdit from './userMessageEdit';
import { ConversationsWysiwygEditor } from '../components';

type UserMessageSequenceProps = {
  message: ConversationMessage;
  conversationParticipants?: BaseUser[];
};

const UserMessageSequence = ({ message, conversationParticipants }: UserMessageSequenceProps) => {
  const [editMode, setEditMode] = useState(false);
  const [form] = useForm();
  const currentUser = useLoggedInUser();

  const { t } = useTranslation();
  const [editor, setEditor] = useState<Editor | null>(null);
  const [updateMessage, { isLoading: updatingMessages }] = conversationsApi.useEditConversationMessageMutation();
  const [deleteMessage] = conversationsApi.useDeleteConversationMessageMutation();

  useEffect(() => {
    form.resetFields();
  }, [message, form]);

  const onEditCancel = () => {
    setEditMode(false);
    form.resetFields();
  };

  const onEditSave = (draftMessage: { content: JSONContent }) => {
    updateMessage({
      id: message.id,
      conversationId: message.conversationId,
      content: JSON.stringify(draftMessage.content),
      text: editor!.getText(),
    })
      .unwrap()
      .then(() => {
        setEditMode(false);
      });
  };

  const onDelete = () => {
    deleteMessage({ id: message.id, conversationId: message.conversationId });
  };

  const isUserMessageOwner = useMemo(() => {
    return message.user.id === currentUser?.id;
  }, [message.user, currentUser]);

  const userMessageActions = useMemo(() => {
    if (editMode || !isUserMessageOwner) {
      return null;
    } else if (message.status === CONVERSATION_MESSAGE_STATUS.DELETED) {
      return null;
    }
    return (
      <StyledOptionsContainer>
        <UserMessageActions onDelete={onDelete} setEditMode={setEditMode} />
      </StyledOptionsContainer>
    );
  }, [editMode, currentUser, message, isUserMessageOwner]); //eslint-disable-line react-hooks/exhaustive-deps

  const messageContent = useMemo(() => {
    if (message.status === CONVERSATION_MESSAGE_STATUS.DELETED) {
      return <UserDeletedMessage message={message} />;
    }
    return (
      <Form
        onFinish={onEditSave}
        initialValues={{ content: parseStringToContent(message.content) }}
        form={form}
        component={false}
      >
        <Form.Item noStyle name="content" className="m-0">
          <ConversationsWysiwygEditor
            conversationParticipants={conversationParticipants}
            onSubmit={form.submit}
            setEditor={setEditor}
            readOnly={!editMode}
            showSubmitButton={false}
          />
        </Form.Item>
      </Form>
    );
  }, [message, editMode, form, conversationParticipants]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div className="px-2 hover:bg-gray-50">
        <StyledUserSequenceMessageWrapper className="flex flex-row items-center pr-2 px-1">
          <StyledUserSequenceDate>
            <StyledDateContainer>
              <DateRecord
                date={message.dateCreated}
                dateFormat={DEFAULT_TIME_HOUR_MINUTES}
                tooltipFormat={DEFAULT_DATETIME_FORMAT}
                className="flex self-start py-1 text-xs text-gray-400"
              />
            </StyledDateContainer>
          </StyledUserSequenceDate>
          <div className="flex flex-col flex-auto">
            {messageContent}
            <div className={`flex justify-${message.dateModified ? 'between' : 'end'} items-center px-1`}>
              {message.dateModified && (
                <DateRecord
                  className="text-xs text-gray-400"
                  date={message.dateModified}
                  dateFormat={DEFAULT_TIME_HOUR_MINUTES}
                  tooltipFormat={DEFAULT_DATETIME_FORMAT}
                  text={`(${t('global:status.edited').toLocaleLowerCase()})`}
                />
              )}
              {editMode && <UserMessageEdit onSave={form.submit} onCancel={onEditCancel} loading={updatingMessages} />}
            </div>
          </div>
          {userMessageActions}
        </StyledUserSequenceMessageWrapper>
      </div>
    </>
  );
};

export default UserMessageSequence;
