import React from 'react';

import { FormInstance } from 'antd';
import { unionBy } from 'lodash';

import { BaseSelectOption } from 'core/lib';

export function mergeSelectedWithOptions<T extends { id: number; name: string }>(
  data: BaseSelectOption[] = [],
  selected?: T | null
) {
  if (selected) {
    const selectedOption = { id: selected.id, value: selected.id, label: selected.name };
    if (data.some((opt) => opt.value === selectedOption.value)) {
      return data;
    }
    return data.concat(selectedOption);
  }
  return data;
}

export function mergeMultiSelectedWithOptions<T extends { id: number; name: string }>(
  data: BaseSelectOption[] = [],
  selected?: T[] | null
) {
  if (selected) {
    const options = selected.map((selectedOpt) => ({
      id: selectedOpt.id,
      value: selectedOpt.id,
      label: selectedOpt.name,
    }));
    return unionBy(data, options, 'id');
  }
  return data;
}

export const formHasInvalidFields = (form: FormInstance, fields?: string[]) => {
  return form.getFieldsError(fields)?.some(({ errors }) => errors.length);
};

export type FormValidations = {
  tabs: { [key: string]: boolean };
  sections: { [key: string]: boolean };
};

export const FormValidationsContext = React.createContext<FormValidations | null>({ sections: {}, tabs: {} });
