import { deserializeNotificationsInfo, deserializeNotificationsListing } from './serializers';

const API_V2 = 'api/v2';

const CONTROLLER = 'notifications';

const API_URL = {
  GET_NOTIFICATIONS_LISTING: `${API_V2}/${CONTROLLER}/getNotifications`,
  GET_NOTIFICATIONS_INFO: `${API_V2}/${CONTROLLER}/getNotificationsInfo`,
  POST_NOTIFICATIONS_SEEN: `${API_V2}/${CONTROLLER}/postNotificationsSeen`,
  POST_NOTIFICATION_VISITED: `${API_V2}/${CONTROLLER}/visitNotification`,
  POST_NOTIFICATION_ALL_VISITED: `${API_V2}/${CONTROLLER}/setAllVisited`,
};

export const TAGS = {
  NOTIFICATION_LISTING: 'Notifications',
  NOTIFICATION_INFO: 'NotificationsInfo',
};

const endpoints = {
  listing: {
    url: API_URL.GET_NOTIFICATIONS_LISTING,
    tag: TAGS.NOTIFICATION_LISTING,
    deserializer: deserializeNotificationsListing,
  },
  notificationsInfo: {
    url: API_URL.GET_NOTIFICATIONS_INFO,
    tag: TAGS.NOTIFICATION_INFO,
    deserializer: deserializeNotificationsInfo,
  },
  notificationsSeen: {
    url: API_URL.POST_NOTIFICATIONS_SEEN,
  },
  notificationVisited: {
    url: API_URL.POST_NOTIFICATION_VISITED,
    invalidates: [TAGS.NOTIFICATION_LISTING],
  },
  notificationsAllVisited: {
    url: API_URL.POST_NOTIFICATION_ALL_VISITED,
    invalidates: [TAGS.NOTIFICATION_LISTING],
  },
};

export default endpoints;
