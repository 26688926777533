import React from 'react';

import { List, Typography, Popover, PopoverProps } from 'antd';
import { Link } from 'react-router-dom';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList, ListChildComponentProps } from 'react-window';

import { getDetailsPath } from 'utils';

const { Link: Linktext } = Typography;

const INNER_ROW = 40;
const CONTAINER_HEIGHT = 250;

type ModuleObjectsPopoverProps<T> = {
  data?: T[];
  objectType: number;
  text: string;
};

function ModuleObjectsPopover<T extends { id: number; name: string }>({
  data = [],
  objectType,
  text,
  ...popoverProps
}: ModuleObjectsPopoverProps<T> & PopoverProps) {
  const listRawHeight = data.length * INNER_ROW;
  const listHeight = listRawHeight > CONTAINER_HEIGHT ? CONTAINER_HEIGHT : listRawHeight;

  const Row = ({ style, data, index }: ListChildComponentProps) => {
    const item = data[index];
    const url = getDetailsPath(objectType, item.id);
    return (
      <List.Item key={item.id} style={style}>
        {url ? <Link to={url}>{item.name}</Link> : item.name}
      </List.Item>
    );
  };

  return (
    <Popover
      content={
        <div className="min-w-64">
          <AutoSizer disableHeight>
            {({ width }) => (
              <List>
                <FixedSizeList
                  itemData={data}
                  width={width}
                  height={listHeight}
                  itemCount={data?.length}
                  itemSize={INNER_ROW}
                >
                  {Row}
                </FixedSizeList>
              </List>
            )}
          </AutoSizer>
        </div>
      }
      {...popoverProps}
    >
      <Linktext>{text} </Linktext>
    </Popover>
  );
}

export default ModuleObjectsPopover;
