import React from 'react';

import { Form } from 'antd';

import { userFormConfig } from 'modules/user/form/formConfig';

const {
  generalInformation: { personalInformation },
} = userFormConfig;

type personalInformationProps = {
  editMode: boolean;
};

const PersonalInformationSection = ({ editMode }: personalInformationProps) => {
  return (
    <div className="flex flex-col">
      <div className="flex sm:flex-row flex-col gap-x-4">
        <Form.Item
          className="md:w-3/6 w-full"
          label={personalInformation.firstName.label}
          name={personalInformation.firstName.dataIndex}
          rules={personalInformation.firstName.rules}
        >
          <personalInformation.firstName.component disabled={!editMode} />
        </Form.Item>
        <Form.Item
          className="md:w-3/6 w-full"
          label={personalInformation.lastName.label}
          name={personalInformation.lastName.dataIndex}
          rules={personalInformation.lastName.rules}
        >
          <personalInformation.lastName.component disabled={!editMode} />
        </Form.Item>
      </div>
      <div className="flex sm:flex-row flex-col gap-x-4">
        <Form.Item
          className="md:w-3/6 w-full"
          label={personalInformation.number.label}
          name={personalInformation.number.dataIndex}
          rules={personalInformation.number.rules}
        >
          <personalInformation.number.component disabled={!editMode} />
        </Form.Item>
        <Form.Item
          className="md:w-3/6 w-full"
          label={personalInformation.country.label}
          name={personalInformation.country.dataIndex}
          rules={personalInformation.country.rules}
        >
          <personalInformation.country.component disabled={!editMode} />
        </Form.Item>
      </div>
    </div>
  );
};

export default PersonalInformationSection;
