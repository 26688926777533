import React, { ReactNode } from 'react';

import { LockOutlined } from '@ant-design/icons';
import { Alert, Button, Form, Input, Space, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { useParams } from 'react-router-dom';

import { ClientLogo } from 'common-ui';
import { ErrorData } from 'common-ui/types';
import { AppState, authApi, isAuthenticated } from 'core/lib';
import colors from 'core/lib/theme/colors';

const { Title, Text } = Typography;

type ExternalLoginProps = {
  children: ReactNode;
};

type LoginFormProps = {
  password: string;
};

const mapState = (state: AppState, ownProps: ExternalLoginProps) => ({
  isAuthenticated: isAuthenticated(state),
  ...ownProps,
});

const connector = connect(mapState);
type ConnectedExternalLoginProps = ConnectedProps<typeof connector>;

const ExternalLogin = ({ children, isAuthenticated }: ConnectedExternalLoginProps) => {
  const { url, dealId, type } = useParams<{ url: string; dealId: string; type: string }>();

  const { t } = useTranslation();

  const [externalLogin, { isLoading, error }] = authApi.useExternalLoginMutation();

  const { data }: ErrorData = (error ?? {}) as ErrorData;

  const onFinish = (values: LoginFormProps) => {
    externalLogin({ ...values, url, objectId: dealId, type });
  };

  return isAuthenticated ? (
    <>{children}</>
  ) : (
    <div className="h-full p-2">
      <div className="sm:w-4/5 md:w-2/5 lg:w-1/5 min-w-64 h-full flex m-auto flex-col items-center justify-center self-center">
        <div className="flex flex-col self-center justify-center flex-auto gap-8">
          <Space className="justify-center py-8" align="center">
            <ClientLogo height={32} />
          </Space>
          <div className="flex flex-col items-center justify-center">
            <Title className="text-center" level={4}>
              {t('account:labels.passwordProtectedContent')}
            </Title>
            <Text type="secondary">{t('account:labels.enterPasswordToContinue')}</Text>
          </div>
          {error && <Alert message={data.error_description} type="error" showIcon />}
          <Form<LoginFormProps>
            name="basic"
            layout="vertical"
            labelCol={{ span: 8 }}
            initialValues={{ remember: false }}
            onFinish={onFinish}
            autoComplete="off"
            requiredMark={false}
          >
            <Form.Item name="password" rules={[{ required: true, message: t('account:error.missingPassword') }]}>
              <Input.Password
                className="w-full"
                placeholder={t('account:inputs.password')}
                prefix={<LockOutlined style={{ color: colors.Primary.rgba }} />}
              />
            </Form.Item>

            <Button className="w-full" type="primary" htmlType="submit" loading={isLoading}>
              {t('account:action.login')}
            </Button>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default connector(ExternalLogin);
