import React, { useContext, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useMedia } from 'react-use';

import { ContentContainer, MainContentContainer, SideContentContainer } from 'common-ui/containers';
import { DetailsSectionCollapse, DetailsSectionPanel } from 'common-ui/detailsSectionCollapse';
import { dealBaseApi } from 'core/lib';
import { OBJECT_TYPES } from 'core/lib/constants';

import { GeneralEvaluationSection, InitialScoringSection, DecisionSection, CommentsSection } from './sections';
import { DealBaseDetailsContext } from '../../context';
import { tabSections } from '../../form/formKeys';
import { DealBaseTabProps } from '../../types';

const defaultSections = Object.values(tabSections.evaluation).map((section) => ({
  name: section.key,
  collapsed: true,
}));

const DealBaseEvaluationTab = ({ editMode, isNew }: DealBaseTabProps) => {
  const { t } = useTranslation('dealbase');
  const isTablet = useMedia('(max-width: 1023px)');

  const dealbase = useContext(DealBaseDetailsContext);
  const [getCommentsCount, { data: commentsCount = 0 }] = dealBaseApi.useLazyGetCommentsCountQuery();
  const [getDecisionsCount, { data: decisionsCount = 0 }] = dealBaseApi.useLazyGetDecisionsCountQuery();

  useEffect(() => {
    if (dealbase?.id) {
      getCommentsCount(dealbase.id, true);
      getDecisionsCount(dealbase.id, true);
    }
  }, [getCommentsCount, getDecisionsCount, dealbase?.id]);

  const evaluationContent = (
    <>
      <DetailsSectionPanel
        header={t('sections.initialScoring')}
        key={tabSections.evaluation.initialScoring?.key!}
        sectionKey={tabSections.evaluation.initialScoring?.key!}
      >
        <InitialScoringSection editMode={editMode} />
      </DetailsSectionPanel>
      <DetailsSectionPanel
        header={t('sections.generalEvaluation')}
        key={tabSections.evaluation.generalEvaluation?.key!}
        sectionKey={tabSections.evaluation.generalEvaluation?.key!}
      >
        <GeneralEvaluationSection editMode={editMode} />
      </DetailsSectionPanel>
    </>
  );

  return (
    <MainContentContainer id="dealbase-evaluation">
      <ContentContainer withSide={!isTablet}>
        <DetailsSectionCollapse
          defaultSections={defaultSections}
          keepDefault={isNew}
          objectType={OBJECT_TYPES.DEAL_BASE}
        >
          <DetailsSectionPanel
            header={`${t('sections.comments')} (${commentsCount})`}
            key={tabSections.evaluation.comments?.key!}
            sectionKey={tabSections.evaluation.comments?.key!}
          >
            <CommentsSection editMode={!!dealbase?.id} />
          </DetailsSectionPanel>
          <DetailsSectionPanel
            header={`${t('sections.decisions')} (${decisionsCount})`}
            key={tabSections.evaluation.decisions?.key!}
            sectionKey={tabSections.evaluation.decisions?.key!}
          >
            <DecisionSection editMode={!!dealbase?.id} />
          </DetailsSectionPanel>
          {isTablet && evaluationContent}
        </DetailsSectionCollapse>
      </ContentContainer>
      {!isTablet && (
        <SideContentContainer>
          <DetailsSectionCollapse
            defaultSections={defaultSections}
            keepDefault={isNew}
            objectType={OBJECT_TYPES.DEAL_BASE}
          >
            {evaluationContent}
          </DetailsSectionCollapse>
        </SideContentContainer>
      )}
    </MainContentContainer>
  );
};

export default DealBaseEvaluationTab;
