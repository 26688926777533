import React from 'react';

import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Divider, Form, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import theme from 'core/lib/theme';
import { dealBaseFormConfig } from 'modules/dealBase/form/formConfig';

const { Title } = Typography;

type EditEbitdaSectionProps = {
  editMode: boolean;
};

const {
  metrics: {
    ebitda: { ebitdaYear, ebitdaProjection },
  },
} = dealBaseFormConfig;

const DealBaseEditEbitdaSection = ({ editMode }: EditEbitdaSectionProps) => {
  const { t } = useTranslation('dealbase');

  return (
    <div className="flex sm:flex-row flex-col flex-auto gap-8">
      <div className="flex flex-col w-full md:w-3/6">
        <Title style={{ color: theme.colors.Primary.rgba }} level={5}>
          {t('form.metrics.headers.actuals')}
        </Title>
        <Form.List name={ebitdaYear.dataIndex} rules={ebitdaYear.rules}>
          {(ebitdaYears, { add, remove }) => {
            return (
              <div className="flex flex-col">
                <Form.Item>
                  {ebitdaYears.map(({ key, name, ...restField }) => {
                    return (
                      <div key={key} className="flex flex-col">
                        <div className="flex items-center flex-row gap-x-4">
                          <div className="flex flex-auto sm:flex-row flex-col gap-x-4">
                            <Form.Item
                              {...restField}
                              className="md:w-3/6"
                              label={ebitdaYear.year.label}
                              rules={ebitdaYear.year.rules}
                              name={[name, ebitdaYear.year.dataIndex]}
                            >
                              <ebitdaYear.year.component disabled={!editMode} />
                            </Form.Item>
                            <Form.Item
                              {...restField}
                              className="md:w-3/6"
                              label={ebitdaYear.amount.label}
                              rules={ebitdaYear.amount.rules}
                              name={[name, ebitdaYear.amount.dataIndex]}
                            >
                              <ebitdaYear.amount.component disabled={!editMode} />
                            </Form.Item>
                          </div>
                          <MinusCircleOutlined onClick={() => remove(name)} />
                        </div>
                        {ebitdaYears.length > 1 && <Divider />}
                      </div>
                    );
                  })}
                </Form.Item>
                {editMode && (
                  <Form.Item>
                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                      {t('actions.addYear')}
                    </Button>
                  </Form.Item>
                )}
              </div>
            );
          }}
        </Form.List>
      </div>
      <div className="flex flex-col w-full md:w-3/6">
        <Title style={{ color: theme.colors.Primary.rgba }} level={5}>
          {t('form.metrics.headers.forecast')}
        </Title>
        <Form.List name={ebitdaProjection.dataIndex} rules={ebitdaProjection.rules}>
          {(ebitdaProjections, { add, remove }) => {
            return (
              <div className="flex flex-col">
                <Form.Item>
                  {ebitdaProjections.map(({ key, name, ...restField }) => {
                    return (
                      <div key={key} className="flex flex-col">
                        <div className="flex items-center flex-row gap-x-4">
                          <div className="flex flex-auto sm:flex-row flex-col gap-x-4">
                            <Form.Item
                              {...restField}
                              className="md:w-3/6"
                              label={ebitdaProjection.year.label}
                              rules={ebitdaProjection.year.rules}
                              name={[name, ebitdaProjection.year.dataIndex]}
                            >
                              <ebitdaProjection.year.component disabled={!editMode} />
                            </Form.Item>
                            <Form.Item
                              {...restField}
                              className="md:w-3/6"
                              label={ebitdaProjection.amount.label}
                              rules={ebitdaProjection.amount.rules}
                              name={[name, ebitdaProjection.amount.dataIndex]}
                            >
                              <ebitdaProjection.amount.component disabled={!editMode} />
                            </Form.Item>
                          </div>
                          <MinusCircleOutlined onClick={() => remove(name)} />
                        </div>
                        {ebitdaProjections.length > 1 && <Divider />}
                      </div>
                    );
                  })}
                </Form.Item>
                {editMode && (
                  <Form.Item>
                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                      {t('actions.addYear')}
                    </Button>
                  </Form.Item>
                )}
              </div>
            );
          }}
        </Form.List>
      </div>
    </div>
  );
};

export default DealBaseEditEbitdaSection;
