import React from 'react';

import { BarChartOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { Segmented } from 'antd';
import { generatePath, Link, useLocation } from 'react-router-dom';

import { Routes } from 'routes/routes';

const ListingHeaderNavigation = () => {
  const location = useLocation();
  return (
    <Segmented
      value={location.pathname}
      block
      className="segmented-item-padding-0"
      options={[
        {
          value: Routes.DEAL_BASE_LISTING.path,
          label: (
            <Link className="px-4 text-inherit" to={generatePath(Routes.DEAL_BASE_LISTING.path)}>
              <UnorderedListOutlined />
            </Link>
          ),
        },
        {
          value: Routes.DEAL_BASE_DASHBOARD.path,
          label: (
            <Link className="px-4 text-inherit" to={generatePath(Routes.DEAL_BASE_DASHBOARD.path)}>
              <BarChartOutlined />
            </Link>
          ),
        },
      ]}
    />
  );
};

export default ListingHeaderNavigation;
