import React, { ReactNode } from 'react';

import { connect, ConnectedProps } from 'react-redux';

import { AppState, selectCurrentUser } from 'core/lib';
import { SystemRole } from 'core/lib/constants/systemRoles';
import { AuthenticatedUser } from 'core/lib/modules/auth/entities';
import { hasRole, hasAllRoles } from 'core/lib/utils/permissionControl/utils';

export enum PermissionRules {
  ANY = 'any',
  ALL = 'all',
  NONE = 'none',
  WITHOUT_ALL = 'withoutAll',
}

interface PermissionControlProps {
  children?: ReactNode;
  roles?: SystemRole[];
  rule?: PermissionRules.ANY | PermissionRules.ALL | PermissionRules.NONE | PermissionRules.WITHOUT_ALL;
  restricted?: boolean;
  disabled?: boolean;
  render?: (isHidden: boolean, isDisabled: boolean) => JSX.Element | null;
  user: AuthenticatedUser | null;
}

const mapState = (state: AppState) => ({
  user: selectCurrentUser(state),
});

const connector = connect(mapState);
type PermissionControlConnectedProps = ConnectedProps<typeof connector> & PermissionControlProps;

const PermissionControl = ({
  children,
  roles,
  rule = PermissionRules.ALL,
  restricted,
  disabled,
  user,
  render,
}: PermissionControlConnectedProps) => {
  const checkRule = () => {
    if (!user || !roles) {
      return false;
    }
    switch (rule) {
      case PermissionRules.ANY:
        return hasRole(user.systemRoleId, roles);
      case PermissionRules.ALL:
        return hasAllRoles(user.systemRoleId, roles);
      case PermissionRules.NONE:
        return !hasRole(user.systemRoleId, roles);
      case PermissionRules.WITHOUT_ALL:
        return !hasAllRoles(user.systemRoleId, roles);
      default:
        return hasAllRoles(user.systemRoleId, roles);
    }
  };

  const isRestricted = () => {
    return !!(user && (restricted || (roles && !checkRule())));
  };

  const isHidden = () => {
    return !!(isRestricted() && !disabled);
  };

  const isDisabled = () => {
    return !!(isRestricted() && disabled);
  };

  if (render) {
    return render(isHidden(), isDisabled());
  }

  return <>{isRestricted() ? null : children}</>;
};

export default connector(PermissionControl);
