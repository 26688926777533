import React from 'react';

import { useMedia } from 'react-use';

import FiltersModal from './modal';
import TagsFilter from './tagsFilter';
import TypeFilter from './typeFilter';
import { ListingActions } from '../components';

const ListingFilters = () => {
  const isMobile = useMedia('(max-width: 768px)');

  return (
    <div className="flex flex-row justify-between items-center">
      <div className="flex flex-row gap-2">
        {!isMobile && (
          <div className="flex flex-row gap-2">
            <TypeFilter />
            <TagsFilter />
          </div>
        )}
        <FiltersModal />
      </div>
      <ListingActions />
    </div>
  );
};

export default ListingFilters;
