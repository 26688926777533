import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

import React, { useContext, useEffect, useMemo } from 'react';

import { Form } from 'antd';
import { RcFile, UploadFile } from 'antd/lib/upload/interface';

import { ErrorBoundary } from 'common-ui';
import PdfViewer from 'common-ui/viewers/pdfViewer';
import { baseApi, DealBase } from 'core/lib';
import { DealBaseDetailsContext } from 'modules/dealBase/context';
import { dealBaseFormConfig } from 'modules/dealBase/form/formConfig';
import { normalizeFileUpload } from 'utils';

const {
  generalInformation: { pitchDeck },
} = dealBaseFormConfig;

type PitchDeckSectionProps = {
  editMode: boolean;
};

const DealBasePitchDeckSection = ({ editMode }: PitchDeckSectionProps) => {
  const dealbase = useContext(DealBaseDetailsContext) as DealBase;

  const [getPitchDeckDocument, { isFetching, data, originalArgs }] = baseApi.useLazyDownloadBase64Query();

  const currentData = useMemo(
    () => (dealbase.pitchDeckDocument?.id === originalArgs ? data : undefined),
    [data, dealbase.pitchDeckDocument?.id, originalArgs]
  );

  useEffect(() => {
    if (dealbase.pitchDeckDocument?.id) {
      getPitchDeckDocument(dealbase.pitchDeckDocument?.id, true);
    }
  }, [dealbase.pitchDeckDocument?.id, getPitchDeckDocument]);

  return editMode ? (
    <Form.Item
      rules={pitchDeck.document.rules}
      name={pitchDeck.document.dataIndex}
      normalize={({ file }: { file: UploadFile<RcFile & XMLHttpRequest> }) => {
        return normalizeFileUpload(file, pitchDeck.document.dataIndex);
      }}
    >
      <pitchDeck.document.component />
    </Form.Item>
  ) : (
    <ErrorBoundary>
      <PdfViewer data={currentData} isLoading={isFetching} />
    </ErrorBoundary>
  );
};

export default DealBasePitchDeckSection;
