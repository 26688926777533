import { compareAsc, compareDesc } from 'date-fns';
import { isNil } from 'lodash';

export type SortOrder = 'descend' | 'ascend' | null;

export const textSorter = (
  textA: string | null | undefined = '',
  textB: string | null | undefined = '',
  order: SortOrder = 'ascend'
) => {
  let orderValue = 0;
  if (!order) {
    return 0;
  }
  orderValue = order === 'ascend' ? 1 : -1;

  if (isNil(textA)) {
    return orderValue;
  } else if (isNil(textB)) {
    return -orderValue;
  } else {
    return textA.localeCompare(textB);
  }
};

export const numberSorter = (numberA?: number | null, numberB?: number | null, order: SortOrder = 'ascend') => {
  let orderValue = 0;
  if (!order) {
    return orderValue;
  }
  orderValue = order === 'ascend' ? 1 : -1;

  if (isNil(numberA)) {
    return orderValue;
  } else if (isNil(numberB)) {
    return -orderValue;
  } else {
    if (order === 'ascend') {
      return numberA > numberB ? orderValue : -orderValue;
    } else {
      return numberA > numberB ? -orderValue : orderValue;
    }
  }
};

export const dateSorter = (dateA?: Date | null, dateB?: Date | null, order: SortOrder = 'ascend') => {
  let orderValue = 0;

  orderValue = order === 'ascend' ? 1 : -1;

  if (isNil(dateA)) {
    return orderValue;
  } else if (isNil(dateB)) {
    return -orderValue;
  } else {
    if (order === 'ascend') {
      return compareAsc(dateA, dateB);
    } else {
      return compareDesc(dateB, dateA);
    }
  }
};
