import React from 'react';

import { useTranslation } from 'react-i18next';

import { ContentContainer, MainContentContainer } from 'common-ui/containers';
import { DetailsSectionCollapse, DetailsSectionPanel } from 'common-ui/detailsSectionCollapse';
import { OBJECT_TYPES } from 'core/lib/constants';

import {
  GeneralInformationSection,
  OwnershipSection,
  PortfolioComplianceSection,
  PortfolioSupplierInformationSection,
} from './sections';
import { tabSections } from '../../form/formKeys';
import { PortfolioTabProps } from '../../types';

const defaultSections = Object.values(tabSections.generalInformation).map((sectionKey) => ({
  name: sectionKey,
  collapsed: true,
}));

const PortfolioGeneralTab = ({ editMode, isNew, sectionValidation }: PortfolioTabProps) => {
  const { t } = useTranslation('portfolio');

  return (
    <MainContentContainer id="portfolio-general">
      <ContentContainer>
        <DetailsSectionCollapse
          defaultSections={defaultSections}
          keepDefault={isNew}
          objectType={OBJECT_TYPES.PORTFOLIO}
        >
          <DetailsSectionPanel
            header={t('sections.generalInformation')}
            key={tabSections.generalInformation.generalInformation!}
            sectionKey={tabSections.generalInformation.generalInformation!}
            invalidFields={sectionValidation?.generalInformation}
            forceRender={editMode}
          >
            <GeneralInformationSection editMode={editMode} />
          </DetailsSectionPanel>
          <DetailsSectionPanel
            header={t('sections.ownership')}
            key={tabSections.generalInformation.ownership!}
            sectionKey={tabSections.generalInformation.ownership!}
            forceRender={editMode}
            invalidFields={sectionValidation?.ownership}
          >
            <OwnershipSection editMode={editMode} />
          </DetailsSectionPanel>
          <DetailsSectionPanel
            header={t('sections.supplierinformation')}
            key={tabSections.generalInformation.supplierInformation!}
            sectionKey={tabSections.generalInformation.supplierInformation!}
            forceRender={editMode}
            invalidFields={sectionValidation?.supplierInformation}
          >
            <PortfolioSupplierInformationSection editMode={editMode} />
          </DetailsSectionPanel>
          <DetailsSectionPanel
            header={t('sections.compliance')}
            key={tabSections.generalInformation.compliance!}
            sectionKey={tabSections.generalInformation.compliance!}
            forceRender={editMode}
            invalidFields={sectionValidation?.compliance}
          >
            <PortfolioComplianceSection editMode={editMode} />
          </DetailsSectionPanel>
        </DetailsSectionCollapse>
      </ContentContainer>
    </MainContentContainer>
  );
};

export default PortfolioGeneralTab;
