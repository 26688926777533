import React, { useMemo } from 'react';

import { Typography } from 'antd';
import { isNil, sum } from 'lodash';
import { useTranslation } from 'react-i18next';

import { DealBaseListing } from 'core/lib';
import { toCurrency } from 'utils';

import CardWithPie from './cardWithPie';

const { Title } = Typography;

const getGroupedListingByIndustries = (listing: DealBaseListing[], defaultGroup: string) => {
  return listing.reduce((agg, listItem) => {
    if (!listItem.industries?.length) {
      agg[defaultGroup] = (agg[defaultGroup] ?? []).concat(listItem);
    } else {
      listItem.industries.forEach((industry) => {
        agg[industry.name] = (agg[industry.name] ?? []).concat(listItem);
      });
    }

    return agg;
  }, {});
};

const getIndustryOpportunitySeries = (dataByIndustry: { [key: string]: DealBaseListing[] }) => {
  return Object.entries(dataByIndustry).map(([industry, industryDataList]) => {
    const industryOpportunity = industryDataList.reduce((agg, item) => {
      agg += parseFloat(item.neTicket?.replace(/\D/g, '') ?? '0');
      return agg;
    }, 0);
    return { label: industry, value: industryOpportunity };
  });
};

type DealsOpportunityProps = {
  data?: DealBaseListing[];
};

const DealsOpportunity = ({ data }: DealsOpportunityProps) => {
  const { t } = useTranslation();

  const tierOpportunitySeriesByIndustry = useMemo(() => {
    const tier1Data = data?.filter((d) => d.tier === 1);
    const tier2Data = data?.filter((d) => d.tier === 2);
    const tierData = data?.filter((d) => d.tier);

    const tier1DataByIndustry = getGroupedListingByIndustries(tier1Data ?? [], t('global:results.notSpecified'));
    const tier2DataByIndustry = getGroupedListingByIndustries(tier2Data ?? [], t('global:results.notSpecified'));
    const dataByIndustry = getGroupedListingByIndustries(tierData ?? [], t('global:results.notSpecified'));

    const tier1 = getIndustryOpportunitySeries(tier1DataByIndustry);
    const tier2 = getIndustryOpportunitySeries(tier2DataByIndustry);
    const total = getIndustryOpportunitySeries(dataByIndustry);

    return { tier1, tier2, total };
  }, [data, t]);

  const tierOpportunity: { [key: string]: number } | undefined = useMemo(() => {
    return data?.reduce((agg, item) => {
      const key = `opportunity${item.tier}`;
      if (!isNil(item.tier)) {
        agg[key] = (agg[key] ?? 0) + parseFloat(item.neTicket?.replace(/\D/g, '') || '0');
      }
      return agg;
    }, {});
  }, [data]);

  const getTotalOpportunity = () => {
    return tierOpportunity ? sum([tierOpportunity.opportunity1 ?? 0, tierOpportunity.opportunity2 ?? 0]) : 0;
  };

  return (
    <div className="flex flex-col gap-2">
      <Title level={5}>{t('dealbase:dashboards.titles.opportunities')}</Title>
      <div className="grid grid-cols-autofitCards gap-8">
        <CardWithPie
          title={toCurrency(tierOpportunity?.opportunity1 ?? 0, 'EUR')}
          description={t('dealbase:dashboards.labels.totalTier', { tier: 1 })}
          series={tierOpportunitySeriesByIndustry.tier1 ?? []}
        />
        <CardWithPie
          title={toCurrency(tierOpportunity?.opportunity2 ?? 0, 'EUR')}
          description={t('dealbase:dashboards.labels.totalTier', { tier: 2 })}
          series={tierOpportunitySeriesByIndustry.tier2 ?? []}
        />
        <CardWithPie
          title={toCurrency(getTotalOpportunity(), 'EUR')}
          description={t('dealbase:dashboards.labels.totalInvestment')}
          series={tierOpportunitySeriesByIndustry.total ?? []}
        />
      </div>
    </div>
  );
};

export default DealsOpportunity;
