import {
  addListener,
  configureStore as cfgStore,
  createListenerMiddleware,
  TypedAddListener,
  TypedStartListening,
} from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import { createBrowserHistory } from 'history';
import LogRocket from 'logrocket';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { combineReducers, Middleware } from 'redux';
import logger from 'redux-logger';

import { authMiddleware, rtqQueryToast } from '../middlewares';
import baseApi from '../modules/app/services/app.api';
import app from '../modules/app/store/app.slice';
import authApi from '../modules/auth/services/auth.api';
import auth from '../modules/auth/store/auth.slice';
import contactBookApi from '../modules/contactBook/services/contactBook.api';
import contactBook from '../modules/contactBook/store/contactBook.slice';
import conversationsApi from '../modules/conversations/services/conversations.api';
import conversations from '../modules/conversations/store/conversations.slice';
import dealBaseApi from '../modules/dealBase/services/dealBase.api';
import dealBase from '../modules/dealBase/store/dealbase.slice';
import notificationApi from '../modules/notifications/services/notifications.api';
import notification from '../modules/notifications/store/notifications.slice';
import organizationApi from '../modules/organization/services/organization.api';
import organization from '../modules/organization/store/organization.slice';
import portfolioApi from '../modules/portfolio/services/portfolio.api';
import portfolio from '../modules/portfolio/store/portfolio.slice';
import {
  commonObjectsApi,
  userPreferencesApi,
  detailsSectionPreferenceApi,
  listingConfigApi,
} from '../modules/shared/services';
import userApi from '../modules/users/services/user.api';
import user from '../modules/users/store/user.slice';

const { IS_PRODUCTION } = process.env;

export const history = createBrowserHistory();

export const listenerMiddleware = createListenerMiddleware();

const rootReducer = combineReducers({
  [authApi.reducerPath]: authApi.reducer,
  [baseApi.reducerPath]: baseApi.reducer,
  [conversationsApi.reducerPath]: conversationsApi.reducer,
  [contactBookApi.reducerPath]: contactBookApi.reducer,
  [notificationApi.reducerPath]: notificationApi.reducer,
  [commonObjectsApi.reducerPath]: commonObjectsApi.reducer,
  [userPreferencesApi.reducerPath]: userPreferencesApi.reducer,
  [detailsSectionPreferenceApi.reducerPath]: detailsSectionPreferenceApi.reducer,
  [listingConfigApi.reducerPath]: listingConfigApi.reducer,
  [dealBaseApi.reducerPath]: dealBaseApi.reducer,
  [organizationApi.reducerPath]: organizationApi.reducer,
  [portfolioApi.reducerPath]: portfolioApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  auth,
  conversations,
  contactBook,
  app,
  dealBase,
  user,
  organization,
  portfolio,
  notification,
});

const store = cfgStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    const baseMiddlewares = [
      authApi.middleware,
      baseApi.middleware,
      conversationsApi.middleware,
      contactBookApi.middleware,
      commonObjectsApi.middleware,
      userPreferencesApi.middleware,
      detailsSectionPreferenceApi.middleware,
      listingConfigApi.middleware,
      dealBaseApi.middleware,
      organizationApi.middleware,
      notificationApi.middleware,
      portfolioApi.middleware,
      userApi.middleware,
      LogRocket.reduxMiddleware(),
      authMiddleware,
      rtqQueryToast,
      listenerMiddleware.middleware,
    ];

    if (!IS_PRODUCTION) {
      baseMiddlewares.push(logger as Middleware);
    }
    return getDefaultMiddleware().concat(baseMiddlewares);
  },
});

export const resetState = () => {
  store.dispatch(authApi.util.resetApiState());
  store.dispatch(baseApi.util.resetApiState());
  store.dispatch(contactBookApi.util.resetApiState());
  store.dispatch(commonObjectsApi.util.resetApiState());
  store.dispatch(userPreferencesApi.util.resetApiState());
  store.dispatch(detailsSectionPreferenceApi.util.resetApiState());
  store.dispatch(listingConfigApi.util.resetApiState());
  store.dispatch(dealBaseApi.util.resetApiState());
  store.dispatch(organizationApi.util.resetApiState());
  store.dispatch(userApi.util.resetApiState());
  store.dispatch(notificationApi.util.resetApiState());
  store.dispatch(conversationsApi.util.resetApiState());
  store.dispatch(portfolioApi.util.resetApiState());
};

export type AppState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();

export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;

export type AppStartListening = TypedStartListening<AppState, AppDispatch>;

export const startAppListening = listenerMiddleware.startListening as AppStartListening;

export const addAppListener = addListener as TypedAddListener<AppState, AppDispatch>;

setupListeners(store.dispatch);

export default store;
