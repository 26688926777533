import {
  deserializeCommonObjectList,
  deserializeCommonObjectListToOption,
  deserialzieCommonObjectConnectedObjects,
} from './serializers';

const API_V2 = 'api';

const CONTROLLER = 'commonObject';

export const TAGS = {
  CommonObjectList: 'CommonObjectList',
};

const endpoints = {
  getCommonObjects: {
    url: `${API_V2}/${CONTROLLER}/getAll`,
    tag: TAGS.CommonObjectList,
    deserializer: deserializeCommonObjectList,
  },
  getCommonObjectOptions: {
    url: `${API_V2}/${CONTROLLER}/getAllOptions`,
    tag: TAGS.CommonObjectList,
    deserializer: deserializeCommonObjectListToOption,
  },
  getCommonObjectConnectedObjects: {
    url: `${API_V2}/${CONTROLLER}/connectedObjects`,
    deserializer: deserialzieCommonObjectConnectedObjects,
  },
  createCommonObject: { url: `${API_V2}/${CONTROLLER}/create`, invalidates: TAGS.CommonObjectList },
  updateCommonObject: { url: `${API_V2}/${CONTROLLER}/update`, invalidates: TAGS.CommonObjectList },
  orderCommonObject: { url: `${API_V2}/${CONTROLLER}/order`, invalidates: TAGS.CommonObjectList },
  resetOrderCommonObject: { url: `${API_V2}/${CONTROLLER}/resetOrder`, invalidates: TAGS.CommonObjectList },
  deleteCommonObject: { url: `${API_V2}/${CONTROLLER}/delete`, invalidates: TAGS.CommonObjectList },
  archiveCommonObject: { url: `${API_V2}/${CONTROLLER}/archive`, invalidates: TAGS.CommonObjectList },
};

export default endpoints;
