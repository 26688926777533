import {
  deserializeUserListing,
  deserializeUserDetailsResponse,
  deserializeUserMenu,
  deserializeUserListingItem,
  deserializeUserShortListResponse,
  deserializeToUserOption,
  deserializeUserProfileDetails,
  deserializeUserShortProfile,
  deserializeUserPublicProfile,
} from './serializers';
import { deserializeObjectHistory } from '../../app';

const API_V2 = 'api/v2';

const CONTROLLER = 'users';

const API_URL = {
  GET_USER_LISTING: `${API_V2}/${CONTROLLER}/getUserListing`,
  GET_USER_DETAILS: `${API_V2}/${CONTROLLER}/getDetails`,
  GET_USER_OPTIONS: `${API_V2}/${CONTROLLER}/getActiveUsersShort`,
  GET_USERS_SHORT: `${API_V2}/${CONTROLLER}/getActiveUsersShort`,
  GET_USER_MENU: `${API_V2}/${CONTROLLER}/getUserMenu`,
  GET_USER_PROFILE_DETAILS: `${API_V2}/${CONTROLLER}/getUserProfileInfo`,
  GET_USER_PROFILE_SHORT: `${API_V2}/${CONTROLLER}/getUserShortProfile`,
  GET_USER_PUBLIC_PROFILE: `${API_V2}/${CONTROLLER}/getUserPublicProfile`,
  GET_USER_NOTIFICATION_PREFERENCES: `${API_V2}/${CONTROLLER}/getUserNotificationPreferences`,
  GET_USER_HISTORY: `${API_V2}/${CONTROLLER}/getHistory`,
  GET_UNIQUE_EMAIL: `${API_V2}/${CONTROLLER}/checkUniqueUserEmail`,

  UNLOCK_USER: `${API_V2}/${CONTROLLER}/unlockUser`,
  ACTIVATE_USER: `${API_V2}/${CONTROLLER}/activateUser`,
  DEACTIVATE_USER: `${API_V2}/${CONTROLLER}/deactivateUser`,
  RESET_PASSWORD: `${API_V2}/${CONTROLLER}/resetUserPassword`,
  POST_NEW_USER: `${API_V2}/${CONTROLLER}/createUser`,
  PATCH_USER: `${API_V2}/${CONTROLLER}/updateUser`,
  DELETE_USER: `${API_V2}/${CONTROLLER}/delete`,

  POST_USER_LISTING_UPDATE: `${API_V2}/${CONTROLLER}/updateUserListingItem`,
  POST_UPDATE_PASSWORD: `${API_V2}/${CONTROLLER}/updatePassword`,
  POST_UPDATE_USER_NOTIFICATION_PREFERENCES: `${API_V2}/${CONTROLLER}/updateUserNotificationPreferences`,
  POST_UPDATE_PROFILE_INFO: `${API_V2}/${CONTROLLER}/updateUserProfileInfo`,
};

export const TAGS = {
  USER_LISTING: 'UserListing',
  USER_DETAILS: 'UserDetails',
  USER_MENU: 'UserMenu',
  USER_ORGANIZATIONS: 'UserOrganizations',
  USERS_SHORT: 'UsersListShort',
  USER_OBJECT_HISTORY: 'UserObjectHistory',
  USER_PUBLIC_PROFILE: 'UserPublicProfile',
};

const endpoints = {
  listing: {
    url: API_URL.GET_USER_LISTING,
    tag: TAGS.USER_LISTING,
    deserializer: deserializeUserListing,
  },
  unlockUser: {
    url: API_URL.UNLOCK_USER,
    tag: TAGS.USER_LISTING,
    deserializer: deserializeUserListing,
  },
  activateUser: {
    url: API_URL.ACTIVATE_USER,
    invalidates: [TAGS.USER_LISTING, TAGS.USER_MENU, TAGS.USERS_SHORT],
  },
  deactivateUser: {
    url: API_URL.DEACTIVATE_USER,
    invalidates: [TAGS.USER_LISTING, TAGS.USER_MENU, TAGS.USERS_SHORT],
  },
  getUniqueEmail: {
    url: API_URL.GET_UNIQUE_EMAIL,
  },
  details: {
    url: API_URL.GET_USER_DETAILS,
    tag: TAGS.USER_DETAILS,
    deserializer: deserializeUserDetailsResponse,
  },
  createUser: {
    url: API_URL.POST_NEW_USER,
    invalidates: [TAGS.USER_LISTING, TAGS.USER_MENU],
  },
  updateUser: {
    url: API_URL.PATCH_USER,
    invalidates: [TAGS.USER_LISTING, TAGS.USER_MENU, TAGS.USER_DETAILS, TAGS.USER_PUBLIC_PROFILE],
  },
  menu: {
    url: API_URL.GET_USER_MENU,
    tag: TAGS.USER_MENU,
    deserializer: deserializeUserMenu,
  },
  getHistory: {
    url: API_URL.GET_USER_HISTORY,
    tag: TAGS.USER_OBJECT_HISTORY,
    deserializer: deserializeObjectHistory,
  },
  updateListingItem: {
    url: API_URL.POST_USER_LISTING_UPDATE,
    deserializer: deserializeUserListingItem,
    invalidates: [TAGS.USER_MENU, TAGS.USER_PUBLIC_PROFILE],
  },
  updatePassword: {
    url: API_URL.POST_UPDATE_PASSWORD,
    invalidates: [TAGS.USER_LISTING, TAGS.USER_MENU, TAGS.USER_DETAILS],
  },
  options: {
    url: API_URL.GET_USER_OPTIONS,
    tag: TAGS.USER_ORGANIZATIONS,
    deserializer: deserializeToUserOption,
  },
  usersShort: {
    url: API_URL.GET_USERS_SHORT,
    tag: TAGS.USERS_SHORT,
    deserializer: deserializeUserShortListResponse,
  },
  userProfileDetails: {
    url: API_URL.GET_USER_PROFILE_DETAILS,
    deserializer: deserializeUserProfileDetails,
  },
  userProfileShort: {
    url: API_URL.GET_USER_PROFILE_SHORT,
    deserializer: deserializeUserShortProfile,
  },
  userPublicProfile: {
    url: API_URL.GET_USER_PUBLIC_PROFILE,
    tag: TAGS.USER_PUBLIC_PROFILE,
    deserializer: deserializeUserPublicProfile,
  },
  updateProfileInfo: {
    url: API_URL.POST_UPDATE_PROFILE_INFO,
  },
  resetPassword: {
    url: API_URL.RESET_PASSWORD,
  },
};

export default endpoints;
