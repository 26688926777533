import React, { useContext } from 'react';

import { Form } from 'antd';

import { ContactBookDetails } from 'core/lib';
import { ContactBookDetailsContext } from 'modules/contactBook/context';
import { contactBookFormConfig } from 'modules/contactBook/form/formConfig';

const {
  details: { contactInformation },
} = contactBookFormConfig;

type ContactInformationSectionProps = {
  editMode: boolean;
};

const ContactBookContactInformationSection = ({ editMode }: ContactInformationSectionProps) => {
  const contactBook = useContext(ContactBookDetailsContext) as ContactBookDetails;

  return (
    <div className="flex flex-col">
      <div className="flex sm:flex-row flex-col gap-x-4">
        <Form.Item
          className="md:w-3/6"
          label={contactInformation.name.label}
          name={contactInformation.name.dataIndex}
          rules={contactInformation.name.rules}
        >
          <contactInformation.name.component disabled={!editMode} />
        </Form.Item>
        <Form.Item
          className="md:w-3/6"
          label={contactInformation.region.label}
          name={contactInformation.region.dataIndex}
          rules={contactInformation.region.rules}
          normalize={(region: string, _, draftContactBook) => {
            draftContactBook.country = null;
            return region;
          }}
        >
          <contactInformation.region.component allowClear disabled={!editMode} />
        </Form.Item>
      </div>
      <div className="flex sm:flex-row flex-col gap-x-4">
        <Form.Item
          className="md:w-3/6"
          label={contactInformation.country.label}
          name={contactInformation.country.dataIndex}
          rules={contactInformation.country.rules}
          dependencies={[contactInformation.region.dataIndex]}
        >
          <contactInformation.country.component allowClear region={contactBook.region} disabled={!editMode} />
        </Form.Item>
        <Form.Item
          className="md:w-3/6"
          label={contactInformation.city.label}
          name={contactInformation.city.dataIndex}
          rules={contactInformation.city.rules}
        >
          <contactInformation.city.component disabled={!editMode} />
        </Form.Item>
      </div>
      <div className="flex sm:flex-row flex-col gap-x-4">
        <Form.Item
          className="md:w-3/6"
          label={contactInformation.email.label}
          name={contactInformation.email.dataIndex}
          rules={contactInformation.email.rules}
        >
          <contactInformation.email.component disabled={!editMode} />
        </Form.Item>
        <Form.Item
          className="md:w-3/6"
          label={contactInformation.phoneNumber.label}
          name={contactInformation.phoneNumber.dataIndex}
          rules={contactInformation.phoneNumber.rules}
        >
          <contactInformation.phoneNumber.component disabled={!editMode} />
        </Form.Item>
      </div>
      <div className="flex sm:flex-row flex-col gap-x-4">
        <Form.Item
          className="md:w-3/6"
          label={contactInformation.linkedInUrl.label}
          name={contactInformation.linkedInUrl.dataIndex}
          rules={contactInformation.linkedInUrl.rules}
        >
          <contactInformation.linkedInUrl.component disabled={!editMode} />
        </Form.Item>
      </div>
    </div>
  );
};

export default ContactBookContactInformationSection;
