import { useState, useEffect, DependencyList } from 'react';

import { Content, EditorOptions } from '@tiptap/core';
import { Editor } from '@tiptap/react';
import { omit } from 'lodash';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { NumberParam, StringParam, useQueryParam } from 'use-query-params';

import { MenuPreference, selectCurrentUser, selectUserMenuPreferences, useAppSelector } from 'core/lib';
import { ClientConfiguration } from 'core/lib/modules/auth/entities';
import { getModuleMenuItems, ModuleMenuItem } from 'modules/appLayout/defaultModules';

export function useRefTypeParam() {
  return useQueryParam('refType', StringParam);
}

export function useRefIdParam() {
  return useQueryParam('refId', NumberParam);
}

export function useLoggedInUser() {
  return useSelector(selectCurrentUser);
}

export function useMatchParamId(path: string) {
  const match = useRouteMatch<{ id: string }>(path);
  return match?.params.id;
}

function useForceUpdate() {
  const [, setValue] = useState(0);

  return () => setValue((value) => value + 1);
}

export const useTipTapEditor = (options: Partial<EditorOptions> = {}, deps: DependencyList = []) => {
  const [editor, setEditor] = useState<(Editor & { setContent?: (content: Content | undefined) => void }) | null>(null);
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    const instance = new Editor(options);
    setEditor(instance);

    instance.on('transaction', (event) => {
      if (event.editor.isFocused) {
        requestAnimationFrame(() => {
          requestAnimationFrame(() => {
            forceUpdate();
          });
        });
      }
    });

    return () => {
      instance.destroy();
    };
  }, deps); // eslint-disable-line  react-hooks/exhaustive-deps

  return editor;
};

export const useMenuItems = (clientConfig: ClientConfiguration | null) => {
  const [menuItems, setMenuItems] = useState<(Partial<MenuPreference> & Omit<ModuleMenuItem, 'id'>)[]>([]);
  const menuPreference = useAppSelector(selectUserMenuPreferences);

  useEffect(() => {
    const userMenu = getModuleMenuItems(clientConfig);
    const mappedMenuItems = userMenu
      .map((menuItem, index) => {
        const currentMenu = menuPreference?.find((preferences) => preferences.objectType === menuItem.objectType);
        return {
          ...omit(menuItem, 'id'),
          displayOrder: index,
          ...(currentMenu ?? {}),
        };
      })
      .sort((a, b) => a.displayOrder - b.displayOrder);

    setMenuItems(mappedMenuItems);
  }, [clientConfig, menuPreference]);

  return menuItems;
};

export const useLoadedInFrame = () => {
  const [loadedInFrame, setLoadedInFrame] = useState(false);
  useEffect(() => {
    try {
      setLoadedInFrame(window.self !== window.top);
    } catch (e) {
      return setLoadedInFrame(true);
    }
  }, []);
  return loadedInFrame;
};
