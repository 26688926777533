import React from 'react';

import { Form, SelectProps } from 'antd';

import { OrganizationOption } from 'core/lib/modules/organization/entities';
import OrganizationMultiSelect, { OrganizationMultiSelectProps } from 'utils/form/organizationMultiSelect';

import { ListingEditableCellProps } from './types';

function ListingOrganizationMultiSelect({
  dataIndex,
  rules,
  ...props
}: ListingEditableCellProps & OrganizationMultiSelectProps & SelectProps<number[]>) {
  return (
    <Form.Item
      hasFeedback
      rules={rules}
      name={dataIndex}
      className="m-0"
      valuePropName="selectedOption"
      normalize={(options: OrganizationOption[]) => {
        return options.map((v) => ({ id: v.value, name: v.label }));
      }}
    >
      <OrganizationMultiSelect {...props} />
    </Form.Item>
  );
}

export default ListingOrganizationMultiSelect;
