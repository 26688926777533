import { createSlice } from '@reduxjs/toolkit';

import { PORTFOLIO_STATUS } from 'core/lib/constants/statuses';

import { logout } from '../../auth';
import { PortfolioFilters } from '../entities/filters';

type PortfolioState = {
  detailsActiveTab: string | null;
  editMode: boolean;
  filters: PortfolioFilters;
  listingGrouping: string;
};

const initialState: PortfolioState = {
  detailsActiveTab: null,
  editMode: false,
  filters: {
    status: [PORTFOLIO_STATUS.NEW, PORTFOLIO_STATUS.STARTED, PORTFOLIO_STATUS.COMPLETED, PORTFOLIO_STATUS.OPERATIONS],
    organization: [],
    owner: [],
    responsible: [],
    taskOwner: [],
    type: [],
    complianceStatus: [],
    creationDate: { startDate: null, endDate: null },
  },
  listingGrouping: PORTFOLIO_STATUS.NEW.toString(),
};

export const portfolioSlice = createSlice({
  name: 'portfolio',
  initialState,
  reducers: {
    setPortfolioFilters: (state, { payload }: { payload: Partial<PortfolioFilters> }) => {
      Object.assign(state.filters, payload);
    },
    clearPortfolioFilters: (state) => {
      Object.assign(state.filters, { ...initialState.filters, status: [] });
    },
    setPortfolioListingGrouping: (state, { payload }: { payload: string }) => {
      state.listingGrouping = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout, () => {
      return initialState;
    });
  },
});

export const { setPortfolioFilters, clearPortfolioFilters, setPortfolioListingGrouping } = portfolioSlice.actions;

export default portfolioSlice.reducer;
