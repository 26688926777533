import React from 'react';

import { useTranslation } from 'react-i18next';

import { ContentContainer, MainContentContainer } from 'common-ui/containers';
import { DetailsSectionCollapse, DetailsSectionPanel } from 'common-ui/detailsSectionCollapse';
import { OBJECT_TYPES } from 'core/lib/constants';

import { PortfolioIntendedInformationSection, PortfolioProductInformationSection } from './sections';
import { tabSections } from '../../form/formKeys';
import { PortfolioTabProps } from '../../types';

const defaultSections = Object.values(tabSections.productInformation).map((sectionKey) => ({
  name: sectionKey,
  collapsed: true,
}));

const PortfolioProductTab = ({ editMode, isNew, sectionValidation }: PortfolioTabProps) => {
  const { t } = useTranslation('portfolio');

  return (
    <MainContentContainer id="portfolio-product">
      <ContentContainer>
        <DetailsSectionCollapse
          defaultSections={defaultSections}
          keepDefault={isNew}
          objectType={OBJECT_TYPES.PORTFOLIO}
        >
          <DetailsSectionPanel
            header={t('sections.intendedUse')}
            key={tabSections.productInformation.intended!}
            sectionKey={tabSections.productInformation.intended!}
            invalidFields={sectionValidation?.intended}
            forceRender={editMode}
          >
            <PortfolioIntendedInformationSection editMode={editMode} />
          </DetailsSectionPanel>
          <DetailsSectionPanel
            header={t('sections.classification')}
            key={tabSections.productInformation.classification!}
            sectionKey={tabSections.productInformation.classification!}
            forceRender={editMode}
            invalidFields={sectionValidation?.classification}
          >
            <PortfolioProductInformationSection editMode={editMode} />
          </DetailsSectionPanel>
        </DetailsSectionCollapse>
      </ContentContainer>
    </MainContentContainer>
  );
};

export default PortfolioProductTab;
