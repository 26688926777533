import React, { useContext, useEffect, useMemo, useState } from 'react';

import { Button, Form, notification, Typography } from 'antd';
import { Trans, useTranslation } from 'react-i18next';

import { userApi, UserDetails } from 'core/lib';
import { userFormConfig } from 'modules/user/form/formConfig';
import { rules } from 'utils/form/rules';
import { PASSWORD_REGEX } from 'utils/validators/general';

import { UserDetailsContext } from '../../../context';
import { getRuleStatus } from '../../../utils';

const { Text } = Typography;

const {
  generalInformation: { loginCredentials },
} = userFormConfig;

type LoginCredentialsProps = {
  editMode: boolean;
  isNew?: boolean;
};

const LoginCredentialsSection = ({ editMode, isNew }: LoginCredentialsProps) => {
  const [passwordValidation, setValidation] = useState(false);
  const { t } = useTranslation();
  const user = useContext(UserDetailsContext) as UserDetails & { password?: string };

  const [resetPassword, { data, isLoading: isResetting }] = userApi.useResetPasswordMutation();

  const setPasswordValidation = () => {
    setValidation(true);
  };

  const onResetPasswordClick = () => {
    resetPassword(user.id);
  };

  useEffect(() => {
    if (data) {
      const key = `open${Date.now()}`;
      notification.open({
        duration: 0,
        type: 'info',
        message: t('user:password.passwordResetted'),
        description: (
          <div className="flex flex-col gap-4">
            <div>
              <Trans
                i18nKey="user:password.passwordResettedInfo"
                values={{ email: user.email }}
                components={{ bold: <b /> }}
              />
            </div>
            <div className="flex flex-col">
              <Text copyable strong>
                {data}
              </Text>
              <Text type="secondary">{t('user:password.passwordResettedCopyHelper')}</Text>
            </div>
          </div>
        ),
        key,
      });
    }
  }, [data, t]); // eslint-disable-line react-hooks/exhaustive-deps

  const passwordHelp = useMemo(
    () => (
      <div>
        {getRuleStatus(
          PASSWORD_REGEX.LENGTH,
          t('user:password.requirements.length'),
          user.password,
          passwordValidation
        )}
        {getRuleStatus(
          PASSWORD_REGEX.UPPERCASE,
          t('user:password.requirements.uppercase'),
          user.password,
          passwordValidation
        )}
        {getRuleStatus(
          PASSWORD_REGEX.LOWERCASE,
          t('user:password.requirements.lowercase'),
          user.password,
          passwordValidation
        )}
        {getRuleStatus(
          PASSWORD_REGEX.NUMBER,
          t('user:password.requirements.number'),
          user.password,
          passwordValidation
        )}
        {getRuleStatus(
          PASSWORD_REGEX.SPECIAL,
          t('user:password.requirements.special'),
          user.password,
          passwordValidation
        )}
      </div>
    ),
    [t, passwordValidation, user.password]
  );

  return (
    <div className="flex flex-col">
      <div className="flex sm:flex-row flex-col gap-x-4">
        <Form.Item
          className="w-full"
          label={loginCredentials.email.label}
          name={loginCredentials.email.dataIndex}
          rules={[
            ...loginCredentials.email.rules,
            {
              validateTrigger: 'onSubmit',
              validator: async (rule, value) => rules.uniqueUserEmail.validator(rule, value, user.id),
              message: rules.uniqueUserEmail.message,
            },
          ]}
        >
          <loginCredentials.email.component disabled={!editMode} />
        </Form.Item>
      </div>
      {isNew ? (
        <div className="flex sm:flex-row flex-col gap-x-4">
          <Form.Item
            className="w-full"
            label={loginCredentials.password.label}
            name={loginCredentials.password.dataIndex}
            rules={loginCredentials.password.rules}
            extra={passwordHelp}
          >
            <loginCredentials.password.component onBlur={setPasswordValidation} disabled={!editMode} />
          </Form.Item>
          <Form.Item
            className="w-full"
            label={t('user:password.confirm')}
            name="confirm"
            hasFeedback
            rules={[
              rules.required,
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue(loginCredentials.password.dataIndex) === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(t('user:password.passwordsNotMatching')));
                },
              }),
            ]}
            dependencies={[loginCredentials.password.dataIndex]}
          >
            <loginCredentials.password.component disabled={!editMode} />
          </Form.Item>
        </div>
      ) : (
        <div>
          <Button onClick={onResetPasswordClick} loading={isResetting} className="flex">
            {t('user:password.resetPassword')}
          </Button>
        </div>
      )}
    </div>
  );
};

export default LoginCredentialsSection;
