import { deserializeObjectHistory, deserializeSearchResult, deserializeUserCommentList } from 'core/lib/modules/app';

import {
  deserializeDealBaseInfoResponse,
  deserializeDealBaseListing,
  deserializeDealBaseListingItem,
  deserializeDealBaseListToOptions,
  deserializeDealBaseMenu,
  deserializeDealBaseResponse,
  deserializeExternalAccess,
  deserializeExternalDealBaseDetails,
  deserializeFinancialYear,
  deserializeScorecard,
} from './serializers';
import { deserializeCommonObjectToOption } from '../../shared/services/commonObjects';

const API_V2 = 'api/v2';

const CONTROLLER = 'dealbase';
const EXTERNAL_CONTROLLER = 'dealbaseExternal';

const API_URL = {
  GET_DEALBASE_LISTING: `${API_V2}/${CONTROLLER}/getListing`,
  GET_DEALBASE_OPTIONS: `${API_V2}/${CONTROLLER}/getOptions`,
  GET_DEALBASE_SEARCH: `${API_V2}/${CONTROLLER}/getSearch`,
  GET_DEALBASE_DETAILS: `${API_V2}/${CONTROLLER}/getDetails`,
  GET_DEALBASE_INFO: `${API_V2}/${CONTROLLER}/getInfo`,
  GET_DEALBASE_MENU: `${API_V2}/${CONTROLLER}/getMenu`,
  GET_DEALBASE_PITCHDECK_DOCUMENT: 'Services/DownloadFile.ashx',
  GET_DEALBASE_INITIAL_SCORECARDS: `${API_V2}/${CONTROLLER}/getCustomerManagementInitialScoreCards`,
  GET_DEALBASE_REVENUE_YEARS: `${API_V2}/${CONTROLLER}/getRevenueYears`,
  GET_DEALBASE_EBITDA_YEARS: `${API_V2}/${CONTROLLER}/getEbitdaYears`,
  GET_DEALBASE_EXTERNAL_ATTACHMENTS: `${API_V2}/${CONTROLLER}/getExternalAttachments`,
  GET_DEALBASE_INTERNAL_ATTACHMENTS: `${API_V2}/${CONTROLLER}/getInternalAttachments`,
  GET_DEALBASE_EXTERNAL_ACCESS: `${API_V2}/${CONTROLLER}/getCustomerManagementExternalAccess`,
  GET_DEALBASE_COMMENTS: `${API_V2}/${CONTROLLER}/getComments`,
  GET_DEALBASE_COMMENTS_COUNT: `${API_V2}/${CONTROLLER}/getCommentsCount`,
  GET_DEALBASE_DECISIONS: `${API_V2}/${CONTROLLER}/getDecisions`,
  GET_DEALBASE_DECISIONS_COUNT: `${API_V2}/${CONTROLLER}/getDecisionsCount`,
  GET_DEALBASE_HISTORY: `${API_V2}/${CONTROLLER}/getHistory`,

  POST_NEW_DEALBASE: `${API_V2}/${CONTROLLER}/createDealBase`,
  PATCH_DEALBASE: `${API_V2}/${CONTROLLER}/updateDealBase`,
  POST_DEALBASE_STATUS: `${API_V2}/${CONTROLLER}/updateStatus`,
  POST_DEALBASE_LISTING_UPDATE: `${API_V2}/${CONTROLLER}/updateListingItem`,
  POST_DEALBASE_COMMENT: `${API_V2}/${CONTROLLER}/postComment`,
  POST_DEALBASE_DECISION: `${API_V2}/${CONTROLLER}/postDecision`,
  DELETE_DEALBASE_COMMENT: `${API_V2}/${CONTROLLER}/deleteComment`,
  DELETE_DEALBASE_DECISION: `${API_V2}/${CONTROLLER}/deleteDecision`,

  GET_EXTERNAL_DEALBASE_DETAILS: `${API_V2}/${EXTERNAL_CONTROLLER}/getDetails`,
  POST_EXTERNAL_NEW_DEALBASE: `${API_V2}/${EXTERNAL_CONTROLLER}/secured-createDealBase`,
  PATCH_EXTERNAL_DEALBASE: `${API_V2}/${EXTERNAL_CONTROLLER}/updateDealBase`,
};

export const TAGS = {
  DEALBASE_LISTING: 'DealBaseListing',
  DEALBASE_MENU: 'DealBaseMenu',
  DEALBASE_DETAILS: 'DealBase',
  DEALBASE_INFO: 'DealBaseInfo',
  DEALBASE_COMMENTS: 'DealBaseComments',
  DEALBASE_COMMENTS_COUNT: 'DealBaseCommentsCount',
  DEALBASE_DECISIONS: 'DealBaseDecisions',
  DEALBASE_DECISIONS_COUNT: 'DealBaseDecisionsCount',
  DEALBASE_OBJECT_HISTORY: 'DealBaseObjectHistory',
};

const endpoints = {
  listing: {
    url: API_URL.GET_DEALBASE_LISTING,
    tag: TAGS.DEALBASE_LISTING,
    deserializer: deserializeDealBaseListing,
  },
  options: {
    url: API_URL.GET_DEALBASE_OPTIONS,
    deserializer: deserializeDealBaseListToOptions,
  },
  search: {
    url: API_URL.GET_DEALBASE_SEARCH,
    deserializer: deserializeSearchResult,
  },
  details: {
    url: API_URL.GET_DEALBASE_DETAILS,
    tag: TAGS.DEALBASE_DETAILS,
    deserializer: deserializeDealBaseResponse,
  },
  info: {
    url: API_URL.GET_DEALBASE_INFO,
    tag: TAGS.DEALBASE_INFO,
    deserializer: deserializeDealBaseInfoResponse,
  },
  menu: {
    url: API_URL.GET_DEALBASE_MENU,
    tag: TAGS.DEALBASE_MENU,
    deserializer: deserializeDealBaseMenu,
  },
  pitchDeckDocument: {
    url: API_URL.GET_DEALBASE_PITCHDECK_DOCUMENT,
  },
  initialScorecards: {
    url: API_URL.GET_DEALBASE_INITIAL_SCORECARDS,
    deserializer: deserializeScorecard,
  },
  revenueYears: {
    url: API_URL.GET_DEALBASE_REVENUE_YEARS,
    deserializer: deserializeFinancialYear,
  },
  ebitdaYears: {
    url: API_URL.GET_DEALBASE_EBITDA_YEARS,
    deserializer: deserializeFinancialYear,
  },
  getHistory: {
    url: API_URL.GET_DEALBASE_HISTORY,
    tag: TAGS.DEALBASE_OBJECT_HISTORY,
    deserializer: deserializeObjectHistory,
  },
  comments: {
    url: API_URL.GET_DEALBASE_COMMENTS,
    tag: TAGS.DEALBASE_COMMENTS,
    deserializer: deserializeUserCommentList,
  },
  commentsCount: {
    url: API_URL.GET_DEALBASE_COMMENTS_COUNT,
    tag: TAGS.DEALBASE_COMMENTS_COUNT,
  },
  postComment: {
    url: API_URL.POST_DEALBASE_COMMENT,
    invalidates: [TAGS.DEALBASE_COMMENTS],
  },
  deleteComment: {
    url: API_URL.DELETE_DEALBASE_COMMENT,
    invalidates: [TAGS.DEALBASE_COMMENTS],
  },
  decisions: {
    url: API_URL.GET_DEALBASE_DECISIONS,
    tag: TAGS.DEALBASE_DECISIONS,
    deserializer: deserializeUserCommentList,
  },
  decisionsCount: {
    url: API_URL.GET_DEALBASE_DECISIONS_COUNT,
    tag: TAGS.DEALBASE_DECISIONS_COUNT,
  },
  postDecision: {
    url: API_URL.POST_DEALBASE_DECISION,
    invalidates: [TAGS.DEALBASE_DECISIONS],
  },
  deleteDecision: {
    url: API_URL.DELETE_DEALBASE_DECISION,
    invalidates: [TAGS.DEALBASE_DECISIONS],
  },
  externalAccess: {
    url: API_URL.GET_DEALBASE_EXTERNAL_ACCESS,
    deserializer: deserializeExternalAccess,
  },
  updateStatus: {
    url: API_URL.POST_DEALBASE_STATUS,
    deserializer: deserializeCommonObjectToOption,
    invalidates: [TAGS.DEALBASE_LISTING, TAGS.DEALBASE_MENU],
  },
  updateListingItem: {
    url: API_URL.POST_DEALBASE_LISTING_UPDATE,
    deserializer: deserializeDealBaseListingItem,
    invalidates: [TAGS.DEALBASE_MENU],
  },
  createDealBase: {
    url: API_URL.POST_NEW_DEALBASE,
    invalidates: [TAGS.DEALBASE_LISTING, TAGS.DEALBASE_MENU],
  },
  externalDetails: {
    url: API_URL.GET_EXTERNAL_DEALBASE_DETAILS,
    deserializer: deserializeExternalDealBaseDetails,
  },
  createExternalDealBase: {
    url: API_URL.POST_EXTERNAL_NEW_DEALBASE,
  },
  updateDealBase: {
    url: API_URL.PATCH_DEALBASE,
    invalidates: [TAGS.DEALBASE_LISTING, TAGS.DEALBASE_MENU, TAGS.DEALBASE_DETAILS],
  },
  updateExternalDealBase: {
    url: API_URL.PATCH_EXTERNAL_DEALBASE,
  },
};

export default endpoints;
