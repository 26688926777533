import React from 'react';

import { Form } from 'antd';

import { dealBaseFormConfig } from 'modules/dealBase/form/formConfig';

const {
  metrics: { financialEvaluation },
} = dealBaseFormConfig;

type FinancialEvaluationSectionProps = {
  editMode: boolean;
};

const DealBaseFinancialEvaluationSection = ({ editMode }: FinancialEvaluationSectionProps) => {
  return (
    <div className="flex flex-col">
      <div className="flex sm:flex-row lg:flex-col flex-col gap-x-4">
        <Form.Item
          className="md:w-3/6 lg:w-full"
          label={financialEvaluation.ticketSize.label}
          name={financialEvaluation.ticketSize.dataIndex}
          rules={financialEvaluation.ticketSize.rules}
        >
          <financialEvaluation.ticketSize.component className="w-full" disabled={!editMode} />
        </Form.Item>
        <Form.Item
          className="md:w-3/6 lg:w-full"
          label={financialEvaluation.commitedCapital.label}
          name={financialEvaluation.commitedCapital.dataIndex}
          rules={financialEvaluation.commitedCapital.rules}
        >
          <financialEvaluation.commitedCapital.component className="w-full" disabled={!editMode} />
        </Form.Item>
      </div>
      <div className="flex sm:flex-row flex-col gap-x-4">
        <Form.Item
          className="md:w-3/6 lg:w-full"
          label={financialEvaluation.neOption.label}
          name={financialEvaluation.neOption.dataIndex}
          rules={financialEvaluation.neOption.rules}
        >
          <financialEvaluation.neOption.component disabled={!editMode} />
        </Form.Item>
      </div>
    </div>
  );
};

export default DealBaseFinancialEvaluationSection;
