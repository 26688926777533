import { DealBaseStatusType } from '../../../constants/statuses';
import { deserializeNumberRange } from '../../app';
import { deserializeDealBaseShortList } from '../../dealBase/services/serializers';
import { deserializeCommonObject, deserializeCommonObjectList } from '../../shared/services/commonObjects';
import { deserializeUserShortListResponse, deserializeUserShortResponse } from '../../users';
import {
  ContactBookDetails,
  ContactBookDetailsResponse,
  ContactBookDiligencePartnerInfo,
  ContactBookDiligencePartnerInfoResponse,
  ContactBookIndustryExpertInfo,
  ContactBookIndustryExpertInfoResponse,
  ContactBookListing,
  ContactBookListingResponse,
  ContactBookMenu,
  ContactBookMenuResponse,
  ContactBookVentureCapitalInfo,
  ContactBookVentureCapitalInfoResponse,
  VentureCapitalSharedCase,
  VentureCapitalSharedCaseResponse,
} from '../entities';

export const deserializeContactBookDetails = (response: ContactBookDetailsResponse): ContactBookDetails => {
  return {
    id: response.ID,
    name: response.Name,
    email: response.Email,
    phoneNumber: response.PhoneNumber,
    region: response.Region,
    country: response.Country,
    city: response.City,
    jobTitle: response.JobTitle,
    type: response.Type,
    tags: response.Tags,
    companyName: response.CompanyName,
    linkedInUrl: response.LinkedIn,
    notes: response.Notes,
    connection: response.Connection,
    relations: response.Relations && deserializeUserShortListResponse(response.Relations),
    // Venture Capital
    stage: response.Stage && deserializeCommonObject(response.Stage),
    investmentFocus: response.InvestmentFocus && deserializeCommonObjectList(response.InvestmentFocus),
    investmentTicket: response.InvestmentTicket && deserializeNumberRange(response.InvestmentTicket),
    fundSize: response.FundSize && deserializeNumberRange(response.FundSize),
    investmentGeography: response.InvestmentGeography,
    sharedCases: response.SharedCases && deserializeVentureCapitalSharedCaseList(response.SharedCases),
    //Industry Expert
    industry: response.Industry && deserializeCommonObjectList(response.Industry),
    //Diligence Partner
    diligenceType: response.DiligenceType,
  };
};
export const deserializeContactBookListingItem = (response: ContactBookListingResponse): ContactBookListing => {
  return {
    id: response.ID,
    name: response.Name,
    email: response.Email,
    phoneNumber: response.PhoneNumber,
    region: response.Region,
    country: response.Country,
    connection: response.Connection,
    type: response.Type,
    tags: response.Tags,
    relations: response.Relations && deserializeUserShortListResponse(response.Relations),
    // Venture Capital
    stage: response.Stage && deserializeCommonObject(response.Stage),
    investmentFocus: response.InvestmentFocus && deserializeCommonObjectList(response.InvestmentFocus),
    investmentTicket: response.InvestmentTicket && deserializeNumberRange(response.InvestmentTicket),
    fundSize: response.FundSize && deserializeNumberRange(response.FundSize),
    investmentGeography: response.InvestmentGeography,
    sharedCases: response.SharedCases && deserializeDealBaseShortList(response.SharedCases),
    //Industry Expert
    industry: response.Industry && deserializeCommonObjectList(response.Industry),
    //Diligence Partner
    diligenceType: response.DiligenceType,
  };
};

export const deserializeContactBookListing = (response: ContactBookListingResponse[]): ContactBookListing[] => {
  return response.map(deserializeContactBookListingItem);
};

export const deserializeContactBookMenuItem = (response: ContactBookMenuResponse): ContactBookMenu => {
  return {
    id: response.ID,
    name: response.Name,
    region: response.Region,
    country: response.Country,
    type: response.Type,
    tags: response.Tags,
    // Venture Capital
    stageId: response.StageId,
    investmentFocusIds: response.InvestmentFocusIds,
    investmentGeography: response.InvestmentGeography,
    sharedCasesIds: response.SharedCasesIds,
    //Industry Expert
    industryIds: response.IndustryIds,
    //Diligence Partner
    diligenceType: response.DiligenceType,
  };
};

export const deserializeContactBookMenu = (response: ContactBookMenuResponse[]): ContactBookMenu[] => {
  return response.map(deserializeContactBookMenuItem);
};

export const deserializeContactBookDiligencePartnerInfo = (
  response: ContactBookDiligencePartnerInfoResponse
): ContactBookDiligencePartnerInfo => {
  return {
    id: response.ID,
    name: response.Name,
    email: response.Email,
    phoneNumber: response.PhoneNumber,
    type: response.Type,
    diligenceType: response.DiligenceType,
  };
};

export const deserializeContactBookVentureCapitalInfo = (
  response: ContactBookVentureCapitalInfoResponse
): ContactBookVentureCapitalInfo => {
  return {
    id: response.ID,
    name: response.Name,
    email: response.Email,
    phoneNumber: response.PhoneNumber,
    type: response.Type,
    stage: response.Stage && deserializeCommonObject(response.Stage),
    investmentFocus: response.InvestmentFocus && deserializeCommonObjectList(response.InvestmentFocus),
    investmentTicket: response.InvestmentTicket && deserializeNumberRange(response.InvestmentTicket),
    fundSize: response.FundSize && deserializeNumberRange(response.FundSize),
    investmentGeography: response.InvestmentGeography,
  };
};
export const deserializeContactBookIndustryExpertInfo = (
  response: ContactBookIndustryExpertInfoResponse
): ContactBookIndustryExpertInfo => {
  return {
    id: response.ID,
    name: response.Name,
    email: response.Email,
    phoneNumber: response.PhoneNumber,
    type: response.Type,
    industry: response.Industry && deserializeCommonObjectList(response.Industry),
  };
};

export const deserializeVentureCapitalSharedCase = (
  response: VentureCapitalSharedCaseResponse
): VentureCapitalSharedCase => {
  return {
    id: response.ID,
    name: response.Name,
    status: response.Status as DealBaseStatusType,
    assigned: response.Assigned && deserializeUserShortResponse(response.Assigned),
  };
};

export const deserializeVentureCapitalSharedCaseList = (
  response: VentureCapitalSharedCaseResponse[]
): VentureCapitalSharedCase[] => {
  return response.map(deserializeVentureCapitalSharedCase);
};
