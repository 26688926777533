import React from 'react';

import { Popover } from 'antd';

import { ContactBookDetails, ContactBookListing } from 'core/lib';

import { PopoverBaseContactBookInfoComponent, PopoverContactButton, PopoverContactBookType } from './components';
import ContactBookDetailsPageHeader from '../detailsPageHeader';

type DiligencePartnerPopoverProps = {
  contactBook: ContactBookListing;
  children: React.ReactNode;
};

const DiligencePartnerPopover = ({ contactBook, children }: DiligencePartnerPopoverProps) => {
  return (
    <Popover
      arrowPointAtCenter
      overlayClassName="w-auto max-w-80"
      content={
        <div className="flex flex-col px-1 gap-4">
          <PopoverContactBookType type={contactBook.type} />

          <PopoverBaseContactBookInfoComponent phoneNumber={contactBook.phoneNumber} email={contactBook.email} />
          <PopoverContactButton id={contactBook.id} email={contactBook.email} />
        </div>
      }
      title={
        <div className="px-1 py-3">
          <ContactBookDetailsPageHeader
            defaultSize="small"
            className="p-0"
            contactBook={contactBook as ContactBookDetails}
          />
        </div>
      }
      trigger="hover"
    >
      {children}
    </Popover>
  );
};

export default DiligencePartnerPopover;
