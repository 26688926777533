import { BaseUser, ContactBookDetails, VentureCapitalSharedCase } from 'core/lib';
import { ContactBookConnectionType, ContactBookDiligenceType, ContactBookType } from 'core/lib/constants/contactBook';
import { NumberRange } from 'core/lib/modules/app';
import { CommonObject } from 'core/lib/modules/shared';

class ContactBookBuilder {
  private readonly _contactBook: Partial<ContactBookDetails>;

  constructor() {
    this._contactBook = {};
  }

  build() {
    return JSON.parse(JSON.stringify(this._contactBook)) as ContactBookDetails;
  }

  id(id: number) {
    this._contactBook.id = id;
    return this;
  }
  name(name: string) {
    this._contactBook.name = name;
    return this;
  }
  region(region: string) {
    this._contactBook.region = region;
    return this;
  }
  country(country: string) {
    this._contactBook.country = country;
    return this;
  }
  city(city: string) {
    this._contactBook.city = city;
    return this;
  }
  email(email: string) {
    this._contactBook.email = email;
    return this;
  }
  phoneNumber(phoneNumber: string) {
    this._contactBook.phoneNumber = phoneNumber;
    return this;
  }
  linkedInUrl(linkedInUrl: string) {
    this._contactBook.linkedInUrl = linkedInUrl;
    return this;
  }
  companyName(companyName: string) {
    this._contactBook.companyName = companyName;
    return this;
  }
  jobTitle(jobTitle: string) {
    this._contactBook.jobTitle = jobTitle;
    return this;
  }
  type(type: ContactBookType) {
    this._contactBook.type = type;
    return this;
  }
  tags(tags: string[]) {
    this._contactBook.tags = tags;
    return this;
  }
  relations(relations: BaseUser[]) {
    this._contactBook.relations = relations;
    return this;
  }
  connection(connection: ContactBookConnectionType) {
    this._contactBook.connection = connection;
    return this;
  }
  notes(notes: string) {
    this._contactBook.notes = notes;
    return this;
  }
  //Venture Capital
  stage(stage: CommonObject | null) {
    this._contactBook.stage = stage;
    return this;
  }
  investmentFocus(investmentFocus: CommonObject[] | null) {
    this._contactBook.investmentFocus = investmentFocus;
    return this;
  }
  investmentTicket(investmentTicket: NumberRange | null) {
    this._contactBook.investmentTicket = investmentTicket;
    return this;
  }
  fundSize(fundSize: NumberRange | null) {
    this._contactBook.fundSize = fundSize;
    return this;
  }
  investmentGeography(investmentGeography: string[]) {
    this._contactBook.investmentGeography = investmentGeography;
    return this;
  }
  sharedCases(sharedCases: VentureCapitalSharedCase[]) {
    this._contactBook.sharedCases = sharedCases;
    return this;
  }
  // Industry Expert
  industry(industry: CommonObject[] | null) {
    this._contactBook.industry = industry;
    return this;
  }
  //Diligence Partner
  diligenceType(diligenceType: ContactBookDiligenceType | null) {
    this._contactBook.diligenceType = diligenceType;
    return this;
  }
}

export default ContactBookBuilder;
