import React from 'react';

import { Tag, TagProps } from 'antd';
import { generatePath, Link } from 'react-router-dom';

import { selectIsAuthenticatedUserAdmin, useAppSelector } from 'core/lib';
import { Routes } from 'routes/routes';

type UserTagProps = {
  id: number | string;
  name: string;
  tagProps?: TagProps;
};

const UserTag = ({ id, name, tagProps = {} }: UserTagProps) => {
  const isUserAdmin = useAppSelector(selectIsAuthenticatedUserAdmin);

  const userTag = (
    <Tag {...tagProps} color="blue">
      {name}
    </Tag>
  );
  return id && isUserAdmin ? <Link to={generatePath(Routes.USER_DETAILS.path, { id })}>{userTag}</Link> : userTag;
};

export default UserTag;
