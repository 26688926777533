export const userTabs = {
  generalInformation: 'generalInformation',
  activity: 'activity',
};

export const userSections = {
  loginCredentials: 'loginCredentials',
  userPrivileges: 'userPrivileges',
  personalInformation: 'personalInformation',
  outOfOffice: 'outOfOffice',
};

export type UserSectionKeys = keyof typeof userSections;

export type UserTabKeys = keyof typeof userTabs;

export const tabSections: { [key in UserTabKeys]: { [key in UserSectionKeys]?: string } } = {
  generalInformation: {
    loginCredentials: 'loginCredentials',
    userPrivileges: 'userPrivileges',
    personalInformation: 'personalInformation',
    outOfOffice: 'outOfOffice',
  },
  activity: {},
};
