import React, { useEffect, useState } from 'react';

import { Button, Form } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { RcFile, UploadFile } from 'antd/lib/upload/interface';
import { once } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Prompt } from 'react-router-dom';

import { ContentContainer, MainContentContainer } from 'common-ui/containers';
import { DetailsSectionWrapper } from 'common-ui/wrappers';
import { userApi, UserProfileDetails } from 'core/lib';
import { normalizeFileUpload } from 'utils';
import { rules } from 'utils/form/rules';
import LoaderWrapper from 'utils/loader-wrapper';

import { userProfileFormConfig } from '../formConfig';

const { general } = userProfileFormConfig;

const GeneralTab = () => {
  const [formDirty, setFormDirty] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const { t } = useTranslation();
  const [form] = useForm();

  const { data, isFetching } = userApi.useGetUserProfileDetailsQuery();
  const [saveUserProfileDetails, { isLoading: isSaving, isSuccess }] = userApi.useUpdateUserProfileDetailsMutation();

  const onFormChange = once(() => {
    setFormDirty(true);
  });

  const onFinish = (values: UserProfileDetails) => {
    saveUserProfileDetails(values);
  };

  useEffect(() => {
    if (isSuccess) {
      setFormDirty(false);
      setEditMode(false);
    }
  }, [isSuccess]);

  const onEditClick = () => {
    setEditMode(true);
  };

  const onCancelClick = () => {
    form.resetFields();
    setFormDirty(false);
    setEditMode(false);
  };

  return (
    <LoaderWrapper loading={isFetching || isSaving}>
      <Prompt when={formDirty} message={t('global:navigation.unsavedChanges')} />
      {data && (
        <Form
          form={form}
          layout="vertical"
          name="general_user_profile"
          onValuesChange={onFormChange}
          onFinish={onFinish}
          initialValues={data}
        >
          <MainContentContainer id="profile-settings-general">
            <ContentContainer>
              <DetailsSectionWrapper>
                <div className="flex flex-col gap-4">
                  <div className="flex w-full justify-end">
                    {!editMode ? (
                      <Button onClick={onEditClick}>{t('actions:global.edit')}</Button>
                    ) : (
                      <div className="flex gap-4">
                        <Button type="primary" htmlType="submit" disabled={!formDirty}>
                          {t('actions:global.save')}
                        </Button>
                        <Button onClick={onCancelClick}>{t('actions:global.cancel')}</Button>
                      </div>
                    )}
                  </div>
                  <div className="flex flex-col">
                    <Form.Item
                      label={t(general.avatar.label)}
                      name={general.avatar.dataIndex}
                      rules={general.avatar.rules}
                      normalize={({ file }: { file: UploadFile<RcFile & XMLHttpRequest> }) => {
                        return normalizeFileUpload(file, general.avatar.dataIndex);
                      }}
                    >
                      <general.avatar.component disabled={!editMode} />
                    </Form.Item>
                    <div className="flex sm:flex-row flex-col gap-x-4">
                      <Form.Item
                        className="md:w-3/6"
                        label={t(general.firstName.label)}
                        name={general.firstName.dataIndex}
                        rules={general.firstName.rules}
                      >
                        <general.firstName.component disabled={!editMode} />
                      </Form.Item>
                      <Form.Item
                        className="md:w-3/6"
                        label={t(general.lastName.label)}
                        name={general.lastName.dataIndex}
                        rules={general.lastName.rules}
                      >
                        <general.lastName.component disabled={!editMode} />
                      </Form.Item>
                    </div>
                    <div className="flex sm:flex-row flex-col gap-x-4">
                      <Form.Item
                        className="md:w-3/6"
                        label={t(general.email.label)}
                        name={general.email.dataIndex}
                        rules={[
                          ...general.email.rules,
                          {
                            validateTrigger: 'onSubmit',
                            validator: async (rule, value) => rules.uniqueUserEmail.validator(rule, value, data.id),
                            message: rules.uniqueUserEmail.message,
                          },
                        ]}
                      >
                        <general.email.component disabled={!editMode} />
                      </Form.Item>
                      <Form.Item
                        className="md:w-3/6"
                        label={t(general.number.label)}
                        name={general.number.dataIndex}
                        rules={general.number.rules}
                      >
                        <general.number.component disabled={!editMode} />
                      </Form.Item>
                    </div>
                    <div className="flex sm:flex-row flex-col gap-x-4">
                      <Form.Item
                        className="md:w-3/6"
                        label={t(general.country.label)}
                        name={general.country.dataIndex}
                        rules={general.country.rules}
                      >
                        <general.country.component disabled={!editMode} />
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </DetailsSectionWrapper>
            </ContentContainer>
          </MainContentContainer>
        </Form>
      )}
    </LoaderWrapper>
  );
};

export default GeneralTab;
