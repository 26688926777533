import React, { useContext, useMemo } from 'react';

import { Form } from 'antd';

import { ContactBookDetails } from 'core/lib';
import { ContactBookType } from 'core/lib/constants/contactBook';
import { ContactBookDetailsContext } from 'modules/contactBook/context';
import { contactBookFormConfig } from 'modules/contactBook/form/formConfig';

import ProfessionalInformationDiligencePartner from './professionalInformationDiligencePartner';
import ProfessionalInformationIndustryExpert from './professionalInformationIndustryExpert';
import ProfessionalInformationVentureCapital from './professionalInformationVentureCapital';

const {
  details: { professionalInformation },
} = contactBookFormConfig;

type ProfessionalInformationSectionProps = {
  editMode: boolean;
};

const ContactBookProfessionalInformationSection = ({ editMode }: ProfessionalInformationSectionProps) => {
  const contactBook = useContext(ContactBookDetailsContext) as ContactBookDetails;

  const fieldsByType = useMemo(() => {
    switch (contactBook.type) {
      case 'VentureCapital': {
        return <ProfessionalInformationVentureCapital editMode={editMode} />;
      }
      case 'IndustryExpert': {
        return <ProfessionalInformationIndustryExpert editMode={editMode} />;
      }
      case 'DiligencePartner': {
        return <ProfessionalInformationDiligencePartner editMode={editMode} />;
      }
      default: {
        return null;
      }
    }
  }, [editMode, contactBook.type]);

  return (
    <div className="flex flex-col">
      <div className="flex sm:flex-row flex-col gap-x-4">
        <Form.Item
          className="md:w-3/6"
          label={professionalInformation.companyName.label}
          name={professionalInformation.companyName.dataIndex}
          rules={professionalInformation.companyName.rules}
        >
          <professionalInformation.companyName.component disabled={!editMode} />
        </Form.Item>
        <Form.Item
          className="md:w-3/6"
          label={professionalInformation.jobTitle.label}
          name={professionalInformation.jobTitle.dataIndex}
          rules={professionalInformation.jobTitle.rules}
        >
          <professionalInformation.jobTitle.component disabled={!editMode} />
        </Form.Item>
      </div>
      <div className="flex sm:flex-row flex-col gap-x-4">
        <Form.Item
          className="md:w-3/6"
          label={professionalInformation.type.label}
          name={professionalInformation.type.dataIndex}
          rules={professionalInformation.type.rules}
          normalize={(type: ContactBookType, _, draftContactBook) => {
            // reset Venture capital fields
            draftContactBook.stage = null;
            draftContactBook.investmentFocus = null;
            draftContactBook.investmentTicket = null;
            draftContactBook.fundSize = null;
            draftContactBook.investmentGeography = [];
            draftContactBook.sharedCases = [];
            // reset Industry expert fields
            draftContactBook.industry = null;
            // reset Diligence partner fields
            draftContactBook.diligenceType = null;
            return type;
          }}
        >
          <professionalInformation.type.component disabled={!editMode} />
        </Form.Item>
      </div>
      {fieldsByType}
    </div>
  );
};

export default ContactBookProfessionalInformationSection;
