import React from 'react';

import { Tag, Tooltip } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { Trans } from 'react-i18next';
import { generatePath, Link } from 'react-router-dom';

import { ListingCommonObjectSelect, ListingText, ListingUserSelect, OrganizationTag, UserAvatar } from 'common-ui';
import { EditableListing } from 'common-ui/cells/types';
import { ListingConfig } from 'core/lib';
import { DYNAMIC, OBJECT_TYPES, SYSTEM_ROLES_ID } from 'core/lib/constants';
import { PORTFOLIO_REVIEW_STATUS_COLORS } from 'core/lib/constants/portfolio';
import { PORTFOLIO_STATUS_KEYS } from 'core/lib/constants/statuses';
import { PortfolioListing } from 'core/lib/modules/portfolio';
import i18n from 'core/lib/utils/i18n';
import { numberSorter, textSorter } from 'core/lib/utils/sorters';
import { Routes } from 'routes/routes';

import { portfolioFormConfig } from '../form/formConfig';

const {
  generalInformation: { generalInformation, ownership },
} = portfolioFormConfig;

export const columns = (
  [
    {
      title: <Trans i18nKey="portfolio:listing.columns.id" />,
      dataIndex: 'id',
      width: 60,
      visible: false,
      sorter: (a, b, order) => numberSorter(a.id, b.id, order),
      ellipsis: {
        showTitle: false,
      },
      render: (id) => (
        <Tooltip placement="topLeft" title={id}>
          {`#${id}`}
        </Tooltip>
      ),
    },
    {
      title: <Trans i18nKey="portfolio:listing.columns.name" />,
      dataIndex: 'name',
      width: 200,
      editable: true,
      visible: true,
      inputType: ListingText,
      inputProps: {
        maxLength: 200,
      },
      inputRules: generalInformation.name.rules,
      sorter: (a, b, order) => textSorter(a.name, b.name, order),
      ellipsis: {
        showTitle: false,
      },
      render: (name, record) => (
        <Tooltip placement="topLeft" title={name}>
          <Link className="truncate" to={generatePath(Routes.PORTFOLIO_DETAILS.path, { id: record.id })}>
            {name}
          </Link>
        </Tooltip>
      ),
    },
    {
      title: <Trans i18nKey="portfolio:listing.columns.type" />,
      dataIndex: 'type',
      width: 200,
      visible: true,
      editable: true,
      inputType: ListingCommonObjectSelect,
      inputRules: generalInformation.type.rules,
      sorter: (a, b, order) => textSorter(a.type?.name, b.type?.name, order),
      ellipsis: {
        showTitle: false,
      },
      inputProps: {
        asTag: true,
        archived: false,
        color: 'orange',
        objectType: OBJECT_TYPES.PORTFOLIO,
        type: DYNAMIC.PORTFOLIO.TYPE,
      },
      render: (type) =>
        type && (
          <Tooltip placement="topLeft" title={type.name}>
            <Tag color="orange">{type.name}</Tag>
          </Tooltip>
        ),
    },
    {
      title: <Trans i18nKey="portfolio:listing.columns.organization" />,
      dataIndex: 'organization',
      width: 200,
      visible: true,
      editable: false,
      ellipsis: {
        showTitle: false,
      },
      render: (organization) =>
        organization && (
          <Tooltip placement="topLeft" title={organization.name}>
            <OrganizationTag key={organization.id} name={organization.name} />
          </Tooltip>
        ),
    },
    {
      title: <Trans i18nKey="portfolio:listing.columns.owner" />,
      dataIndex: 'owner',
      width: 100,
      editable: true,
      visible: false,
      inputType: ListingUserSelect,
      inputRules: ownership.owner.rules,
      sorter: (a, b, order) => textSorter(a.owner?.name, b.owner?.name, order),
      ellipsis: {
        showTitle: false,
      },
      render: (owner) => owner && <UserAvatar user={owner} />,
    },
    {
      title: <Trans i18nKey="portfolio:listing.columns.responsible" />,
      dataIndex: 'responsible',
      width: 100,
      editable: true,
      visible: false,
      inputType: ListingUserSelect,
      inputRules: ownership.responsible.rules,
      sorter: (a, b, order) => textSorter(a.responsible?.name, b.responsible?.name, order),
      ellipsis: {
        showTitle: false,
      },
      render: (responsible) => responsible && <UserAvatar user={responsible} />,
    },
    {
      title: <Trans i18nKey="portfolio:listing.columns.taskOwner" />,
      dataIndex: 'taskOwner',
      width: 200,
      editable: true,
      visible: true,
      inputType: ListingUserSelect,
      getInputProps: (record: PortfolioListing) => {
        return {
          roleIds: [SYSTEM_ROLES_ID.SYSTEM_OWNER],
          organizationIds: [record.organization.id],
        };
      },
      inputRules: ownership.taskOwner.rules,
      sorter: (a, b, order) => textSorter(a.taskOwner?.name, b.taskOwner?.name, order),
      ellipsis: {
        showTitle: false,
      },
      render: (taskOwner) => taskOwner && <UserAvatar user={taskOwner} />,
    },
    {
      title: <Trans i18nKey="portfolio:listing.columns.status" />,
      dataIndex: 'status',
      width: 100,
      visible: false,
      sorter: (a, b, order) =>
        textSorter(
          i18n.t(`portfolio:status.label.${PORTFOLIO_STATUS_KEYS[a.status]}`),
          i18n.t(`portfolio:status.label.${PORTFOLIO_STATUS_KEYS[b.status]}`),
          order
        ),
      ellipsis: {
        showTitle: false,
      },
      render: (status, item) => {
        const color = item.reviewStatus ? PORTFOLIO_REVIEW_STATUS_COLORS[item.reviewStatus] : 'blue';

        return (
          <Tooltip
            placement="topLeft"
            title={<Trans i18nKey={`portfolio:status.label.${PORTFOLIO_STATUS_KEYS[status]}`} />}
          >
            <Tag color={color}>
              <Trans i18nKey={`portfolio:status.label.${PORTFOLIO_STATUS_KEYS[status]}`} />
            </Tag>
          </Tooltip>
        );
      },
    },
  ] as (ColumnType<PortfolioListing> & Partial<ListingConfig> & EditableListing<PortfolioListing>)[]
).map((col, index) => {
  col.index = index;
  return col;
});
