import { hubConnection } from '@cojam/signalr-no-jquery';
import { debounce } from 'lodash';
import querystring from 'query-string';

import { selectAccessToken } from 'core/lib/modules/auth';
import { conversationsApi, setConversationsWebSocketStatus } from 'core/lib/modules/conversations';
import store from 'core/lib/store';

import { HubConnectionState } from './utils';
import { getBaseUrl } from '../requesting';

export type HubEventListeners = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: (eventPayload: any) => void;
};

export class HubConnectionsManager {
  connection?: SignalR.Hub.Connection & { state?: number; events?: { onStateChanged?: (state: number) => void } };

  private initializeHubConnection(options: SignalR.Hub.Options = {}) {
    const baseUrl = getBaseUrl();
    const access_token = selectAccessToken(store.getState());
    if (access_token) {
      this.connection = hubConnection(baseUrl.substring(0, baseUrl.length - 1), {
        qs: querystring.stringify({ access_token }),
        logging: !process.env.IS_PRODUCTION,
        ...options,
      });
    }
  }

  setOnStateChangeCallback = (callback: (state: SignalR.StateChanged) => void) => {
    if (this.connection) {
      this.connection.stateChanged(callback);
    }
  };

  start(onSuccess: () => void, onFail: () => void) {
    this.connection
      ?.start({
        withCredentials: false,
      })
      .done(onSuccess)
      .fail(onFail);
  }

  restart(onSuccess: () => void, onFail: () => void) {
    this.initializeHubConnection();
    this.connection?.start().done(onSuccess).fail(onFail);
  }

  stop() {
    this.connection?.stop();
  }

  getHubConnection(options: SignalR.Hub.Options) {
    if (this.connection) {
      return this.connection;
    }
    this.initializeHubConnection(options);
    return this.connection!;
  }
}

class BaseHub {
  hubManager = new HubConnectionsManager();
  getConnection(options: SignalR.Hub.Options) {
    return this.hubManager.getHubConnection(options);
  }
}

export class ConversationHub extends BaseHub {
  private static HUB_NAME = 'conversations';
  hubProxy?: SignalR.Hub.Proxy;
  private eventListeners: HubEventListeners = {};
  private hubOptions: SignalR.Hub.Options = {};
  private maxRetries = 5;
  private retries = 0;

  setEventListeners(eventListeners: HubEventListeners) {
    Object.entries(eventListeners).forEach(([event, callback]) => {
      this.hubProxy?.on(event, callback);
    });
  }

  connectHub(eventListeners: HubEventListeners, options: SignalR.Hub.Options) {
    this.eventListeners = eventListeners;
    this.hubOptions = options;
    const connection = this.getConnection(options);
    this.hubProxy = connection.createHubProxy(ConversationHub.HUB_NAME);
    this.hubManager.setOnStateChangeCallback(this.onStateChangeHandler);
    this.setEventListeners(eventListeners);
  }

  joinConversationRoom(conversationId: number) {
    if (this.hubManager?.connection?.state === HubConnectionState.Connected) {
      this.hubProxy?.invoke('JoinConversationRoom', conversationId);
      store.dispatch(conversationsApi.util.prefetch('getConversationGlobalUnreadMessages', undefined, { force: true }));
    }
  }

  leaveConversationRoom() {
    if (this.hubManager?.connection?.state === HubConnectionState.Connected) {
      this.hubProxy?.invoke('LeaveConversationRoom');
    }
  }

  joinModuleConversationMenu() {
    if (this.hubManager?.connection?.state === HubConnectionState.Connected) {
      this.hubProxy?.invoke('JoinModuleConversationsMenu');
    }
  }

  joinModuleConversationListing() {
    if (this.hubManager?.connection?.state === HubConnectionState.Connected) {
      this.hubProxy?.invoke('JoinModuleConversationsListing');
    }
  }

  joinGlobalConversationsUnreadMessages() {
    if (this.hubManager?.connection?.state === HubConnectionState.Connected) {
      this.hubProxy?.invoke('JoinGlobalConversationsUnreadMessages');
    }
  }

  leaveGlobalConversationsUnreadMessages() {
    if (this.hubManager?.connection?.state === HubConnectionState.Connected) {
      this.hubProxy?.invoke('LeaveGlobalConversationsUnreadMessages');
    }
  }

  leaveModuleConversationMenu() {
    if (this.hubManager?.connection?.state === HubConnectionState.Connected) {
      this.hubProxy?.invoke('LeaveModuleConversationsMenu');
    }
  }

  leaveModuleConversationListing() {
    if (this.hubManager?.connection?.state === HubConnectionState.Connected) {
      this.hubProxy?.invoke('LeaveModuleConversationsListing');
    }
  }

  joinGroupConversationMenu() {
    if (this.hubManager?.connection?.state === HubConnectionState.Connected) {
      this.hubProxy?.invoke('JoinGroupConversationsMenu');
    }
  }

  leaveGroupConversationMenu() {
    if (this.hubManager?.connection?.state === HubConnectionState.Connected) {
      this.hubProxy?.invoke('LeaveGroupConversationsMenu');
    }
  }

  joinDirectConversationMenu() {
    if (this.hubManager?.connection?.state === HubConnectionState.Connected) {
      this.hubProxy?.invoke('JoinDirectConversationsMenu');
    }
  }

  leaveDirectConversationMenu() {
    if (this.hubManager?.connection?.state === HubConnectionState.Connected) {
      this.hubProxy?.invoke('LeaveDirectConversationsMenu');
    }
  }

  disconnect() {
    if (this.hubManager?.connection?.state === HubConnectionState.Disconnected) {
      this.hubManager.stop();
    }
  }

  onStateChangeHandler = (state: SignalR.StateChanged) => {
    switch (state.newState) {
      case HubConnectionState.Connected: {
        this.retries = 0;
        store.dispatch(setConversationsWebSocketStatus(HubConnectionState.Connected));
        console.info(`SignalR: ${this.hubProxy?.hubName} has connected (${this.hubProxy?.connection.id})`);
        break;
      }
      case HubConnectionState.Disconnected: {
        if (this.retries < this.maxRetries) {
          this.hubManager.stop();
          store.dispatch(setConversationsWebSocketStatus(HubConnectionState.Disconnected));
          console.info(`SignalR: ${this.hubProxy?.hubName} has disconnected (${this.hubProxy?.connection.id})`);
          this.restart();
          this.retries++;
        }
        break;
      }
      case HubConnectionState.Reconnecting: {
        store.dispatch(setConversationsWebSocketStatus(HubConnectionState.Reconnecting));
        console.info(`SignalR: ${this.hubProxy?.hubName} is reconnecting (${this.hubProxy?.connection.id})`);
        break;
      }
      case HubConnectionState.Connecting: {
        store.dispatch(setConversationsWebSocketStatus(HubConnectionState.Connecting));
        console.info(`SignalR: ${this.hubProxy?.hubName} is connecting (${this.hubProxy?.connection.id})`);
        break;
      }
      default:
        return null;
    }
  };

  onSuccessConnection() {
    store.dispatch(setConversationsWebSocketStatus(HubConnectionState.Connected));
  }

  onRestartSuccessConnection() {
    store.dispatch(setConversationsWebSocketStatus(HubConnectionState.Connected));
    store.dispatch(conversationsApi.util.resetApiState());
  }

  onFailConnection() {
    store.dispatch(setConversationsWebSocketStatus(HubConnectionState.Disconnected));
  }

  disconnectAndRestart = () => {
    this.hubManager.stop();
    this.restart();
  };

  restart = debounce(() => {
    this.hubManager.restart(this.onRestartSuccessConnection, this.onFailConnection);
    this.connectHub(this.eventListeners, this.hubOptions);
  }, 5000);

  start() {
    this.hubManager.start(this.onSuccessConnection, this.onFailConnection);
  }
}
