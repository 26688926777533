import { isRejectedWithValue } from '@reduxjs/toolkit';
import { message } from 'antd';

import { startAppListening } from 'core/lib/store';
import i18n from 'core/lib/utils/i18n';

startAppListening({
  predicate: (action) => {
    if (isRejectedWithValue(action)) {
      return (action.payload as any).status === 403;
    }
    return false;
  },
  effect: (action) => {
    if (action.meta.arg.type === 'mutation') {
      message.error(i18n.t<string>('actions:message.permissionDenied'));
    }
  },
});
