import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';

import { parseJSONDate } from 'core/lib/utils';
import { HubConnectionState } from 'core/lib/utils/signalR/utils';
import { dateSorter } from 'core/lib/utils/sorters';

import { ConversationMessage } from '../entities';
import conversationsApi from '../services/conversations.api';

export const conversationMessagesAdapter = createEntityAdapter<ConversationMessage>({
  sortComparer: (a, b) => dateSorter(parseJSONDate(a.dateCreated), parseJSONDate(b.dateCreated), 'ascend'),
});

type ConversationsState = {
  conversationMessages: {
    [key: number]: EntityState<ConversationMessage>;
  };
  webSocketState: number;
};

export const conversationsInitialState: ConversationsState = {
  conversationMessages: {},
  webSocketState: HubConnectionState.Disconnected,
};

export const conversationsSlice = createSlice({
  name: 'conversations',
  initialState: conversationsInitialState,
  reducers: {
    onMessageReceived: (state, { payload }: PayloadAction<ConversationMessage>) => {
      if (!state.conversationMessages[payload.conversationId]) {
        state.conversationMessages[payload.conversationId] = conversationMessagesAdapter.getInitialState();
      }
      conversationMessagesAdapter.upsertOne(state.conversationMessages[payload.conversationId], payload);
    },
    setConversationsWebSocketStatus: (state, { payload }: PayloadAction<number>) => {
      state.webSocketState = payload;
    },
    resetMessagesState: (state, { payload }: PayloadAction<number>) => {
      if (state.conversationMessages[payload]) {
        state.conversationMessages[payload] = conversationMessagesAdapter.removeAll(
          state.conversationMessages[payload]
        );
      }
    },
    conversationsHydrate: (state, { payload }: PayloadAction<Partial<ConversationsState>>) => {
      return { ...state, ...payload };
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(conversationsApi.endpoints.getConversationMessages.matchPending, (state, { meta }) => {
      // state.page = meta.arg.originalArgs.page ?? 0;
      if (!state.conversationMessages[meta.arg.originalArgs.conversationId]) {
        state.conversationMessages[meta.arg.originalArgs.conversationId] =
          conversationMessagesAdapter.getInitialState();
      }
    });
    builder.addMatcher(
      conversationsApi.endpoints.getConversationMessages.matchFulfilled,
      (state, { payload, meta }) => {
        conversationMessagesAdapter.upsertMany(
          state.conversationMessages[meta.arg.originalArgs.conversationId],
          payload
        );
      }
    );
    builder.addMatcher(
      conversationsApi.endpoints.postConversationMessage.matchFulfilled,
      (state, { payload, meta }) => {
        // state.page = meta.arg.originalArgs.page ?? 0;
        if (!state.conversationMessages[meta.arg.originalArgs.conversationId]) {
          state.conversationMessages[meta.arg.originalArgs.conversationId] =
            conversationMessagesAdapter.getInitialState();
        }
        conversationMessagesAdapter.upsertOne(
          state.conversationMessages[meta.arg.originalArgs.conversationId],
          payload
        );
      }
    );
  },
});

export const { onMessageReceived, setConversationsWebSocketStatus, resetMessagesState, conversationsHydrate } =
  conversationsSlice.actions;

export default conversationsSlice.reducer;
