export const dealbaseTabs = {
  generalInformation: 'generalInformation',
  documents: 'documents',
  evaluation: 'evaluation',
  metrics: 'metrics',
  activity: 'activity',
};

export const dealbaseSections = {
  generalInformation: 'generalInformation',
  overview: 'overview',
  contactDetails: 'contactDetails',
  pitchDeck: 'pitchDeck',
  internalDocuments: 'internalDocuments',
  externalDocuments: 'externalDocuments',
  generalEvaluation: 'generalEvaluation',
  financialEvaluation: 'financialEvaluation',
  initialScoring: 'initialScoring',
  comments: 'Comments',
  decisions: 'Decisions',
  metricsCurrency: 'metricsCurrency',
  revenue: 'revenue',
  ebitda: 'ebitda',
  revenueVsEbitda: 'revenueVsEbitda',
};

export const dealbaseFields = {
  name: 'name',
  companyLogo: 'companyLogo',
  industries: 'industries',
  technology: 'technology',
  customerFocus: 'customerFocus',
  country: 'country',
  city: 'city',
  website: 'website',
  foundingYear: 'foundingYear',
  heardFrom: 'heardFrom',

  businessIdea: 'businessIdea',
  productStatus: 'productStatus',
  problemSolve: 'problemSolve',
  ebitda: 'ebitda',
  marketSize: 'marketSize',
  yourCustomer: 'yourCustomer',
  getYourCustomer: 'getYourCustomer',
  tractionSoFar: 'tractionSoFar',
  roundSpecifications: 'roundSpecifications',
  preMoneyValuation: 'preMoneyValuation',
  investmentRound: 'investmentRound',
  stage: 'stage',

  ceo: 'ceo',
  founders: 'founders',
  contactPerson: 'contactPerson',
  contactNumber: 'contactNumber',
  contactEmail: 'contactEmail',

  pitchDeckDocument: 'pitchDeckDocument',
  internalAttachments: 'internalAttachments',
  externalAttachments: 'externalAttachments',
  businessPlanDocument: 'businessPlanDocument',
  budgetDocument: 'budgetDocument',

  organization: 'organization',
  assigned: 'assigned',
  involved: 'involved',
  timeFrame: 'timeFrame',
  nextSteps: 'nextSteps',
  followUpDate: 'followUpDate',
  priority: 'priority',
  tier: 'tier',

  ticketSize: 'ticketSize',
  commitedCapital: 'commitedCapital',
  neOption: 'neOption',

  initialScorecards: 'initialScorecards',

  currency: 'currency',

  revenueType: 'revenueType',
  revenueYears: 'revenueYears',
  revenueProjections: 'revenueProjections',

  ebitdaYears: 'ebitdaYears',
  ebitdaProjections: 'ebitdaProjections',
};
export type DealBaseFieldKeys = keyof typeof dealbaseFields;

export type DealBaseSectionKeys = keyof typeof dealbaseSections;

export type DealBaseTabKeys = keyof typeof dealbaseTabs;

export const tabSections: {
  [key in DealBaseTabKeys]: {
    [key in DealBaseSectionKeys]?: { key: string; fields: Partial<DealBaseFieldKeys>[] };
  };
} = {
  generalInformation: {
    generalInformation: {
      key: 'generalInformation',
      fields: [
        'name',
        'companyLogo',
        'industries',
        'technology',
        'customerFocus',
        'country',
        'city',
        'website',
        'foundingYear',
        'heardFrom',
      ],
    },
    overview: {
      key: 'overview',
      fields: [
        'businessIdea',
        'productStatus',
        'problemSolve',
        'ebitda',
        'marketSize',
        'yourCustomer',
        'getYourCustomer',
        'tractionSoFar',
        'roundSpecifications',
        'preMoneyValuation',
        'investmentRound',
        'stage',
      ],
    },
    contactDetails: {
      key: 'contactDetails',
      fields: ['ceo', 'founders', 'contactPerson', 'contactNumber', 'contactEmail'],
    },
    pitchDeck: { key: 'pitchDeck', fields: ['pitchDeckDocument'] },
  },
  evaluation: {
    generalEvaluation: {
      key: 'generalEvaluation',
      fields: ['organization', 'assigned', 'involved', 'timeFrame', 'nextSteps', 'followUpDate', 'priority', 'tier'],
    },
    initialScoring: { key: 'initialScoring', fields: ['initialScorecards'] },
    comments: { key: 'comments', fields: [] },
    decisions: { key: 'decisions', fields: [] },
  },
  documents: {
    externalDocuments: {
      key: 'externalDocuments',
      fields: ['externalAttachments', 'businessPlanDocument', 'budgetDocument'],
    },
    internalDocuments: { key: 'internalDocuments', fields: ['internalAttachments'] },
  },
  metrics: {
    financialEvaluation: {
      key: 'financialEvaluation',
      fields: ['ticketSize', 'commitedCapital', 'neOption'],
    },
    metricsCurrency: { key: 'metricsCurrency', fields: ['currency'] },
    revenue: { key: 'revenue', fields: ['revenueType', 'revenueYears', 'revenueProjections'] },
    ebitda: { key: 'ebitda', fields: ['ebitdaYears', 'ebitdaProjections'] },
    revenueVsEbitda: { key: 'revenueVsEbitda', fields: [] },
  },
  activity: {},
};

export const tabFields: { [key: string]: string[] } = Object.entries(tabSections).reduce((agg, [tabKey, section]) => {
  agg[tabKey] = Object.values(section)
    .map((s) => s.fields ?? [])
    .flat();
  return agg;
}, {});

export const sectionFields = Object.values(tabSections).reduce((agg, tab) => {
  Object.values(tab).forEach((s) => {
    agg[s.key] = s.fields;
  });
  return agg;
}, {} as { [key: string]: string[] });
