import React from 'react';

import { Pie, PieConfig } from '@ant-design/charts';
import { Typography } from 'antd';

import { CardNarrowInset } from 'common-ui/styled';

const { Title, Text } = Typography;

type CardWithPieProps = {
  title: string;
  description: string;
  series: { value: string | number; label: string }[];
  pieConfig?: Partial<PieConfig>;
};

const CardWithPie = ({ title, description, series, pieConfig }: CardWithPieProps) => {
  const config: PieConfig = {
    height: 100,
    data: [],
    width: 200,
    angleField: 'value',
    colorField: 'label',
    radius: 1,
    innerRadius: 0.6,
    label: false,
    legend: false,
    statistic: {
      content: false,
      title: false,
    },
  };

  return (
    <CardNarrowInset className="flex flex-auto">
      <div className="flex flex-row justify-between">
        <div className="flex flex-col justify-center">
          <Text type="secondary">{description}</Text>
          <Title style={{ margin: 0 }} level={3}>
            {title}
          </Title>
        </div>
        <Pie {...{ ...config, ...(pieConfig ?? {}) }} data={series} />
      </div>
    </CardNarrowInset>
  );
};

export default CardWithPie;
