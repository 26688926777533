import React from 'react';

import { DoubleLeftOutlined, DoubleRightOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { useMedia } from 'react-use';

import {
  selectClientOrganization,
  selectLeftMenuState,
  setLeftMenuToggled,
  useAppDispatch,
  useAppSelector,
} from 'core/lib';
import { Routes } from 'routes/routes';

const { Title } = Typography;

const LeftMenuNavigation = () => {
  const { t } = useTranslation('global');
  const isTablet = useMedia('(max-width: 1023px)');
  const client = useAppSelector(selectClientOrganization);
  const dispatch = useAppDispatch();
  const leftMenuToggled = useSelector(selectLeftMenuState);

  const onLeftMenuToggle = () => {
    dispatch(setLeftMenuToggled(!leftMenuToggled));
  };

  const NavigationIcon = leftMenuToggled ? DoubleLeftOutlined : DoubleRightOutlined;

  return (
    <>
      {!isTablet && (
        <Title level={5} style={{ margin: 0 }}>
          <Switch>
            <Route path={Routes.CONTACT_BOOK.path} render={() => t('moduleNames.contactbook')} />
            <Route path={Routes.DEAL_BASE.path} render={() => t('moduleNames.dealbase')} />
            <Route path={Routes.ORGANIZATION.path} render={() => t('moduleNames.organization')} />
            <Route path={Routes.CONVERSATIONS.path} render={() => t('moduleNames.myconversations')} />
            <Route path={Routes.PORTFOLIO.path} render={() => t('moduleNames.portfolio')} />
            <Route path={Routes.CUSTOM_FIELDS.path} render={() => t('moduleNames.customFields')} />
            <Route path={Routes.USER.path} render={() => t('moduleNames.users')} />
            <Route path={Routes.USER_PROFILE.path} render={() => t('moduleNames.profileSettings')} />
            <Route path={Routes.WELCOME.path} render={() => client} />
          </Switch>
        </Title>
      )}
      <Switch>
        <Route
          path={[
            Routes.CONTACT_BOOK.path,
            Routes.DEAL_BASE.path,
            Routes.CONVERSATIONS.path,
            Routes.PORTFOLIO.path,
            Routes.CUSTOM_FIELDS.path,
            Routes.USER.path,
          ]}
          render={() => <NavigationIcon className="h-full flex items-center px-3" onClick={onLeftMenuToggle} />}
        />
      </Switch>
    </>
  );
};

export default LeftMenuNavigation;
