import { createSelector } from 'reselect';

import { notificationsAdapter } from './notifications.slice';
import { AppState } from '../../../store';

const { selectAll } = notificationsAdapter.getSelectors();

const selectSelf = (state: AppState) => state.notification;

export const selectNotificationsPage = createSelector(selectSelf, (notificationState) => notificationState.page);

export const selectAllNotifications = createSelector(selectSelf, (notificationState) =>
  selectAll(notificationState.notifications)
);
