import React, { useMemo, useState } from 'react';

import { EllipsisOutlined } from '@ant-design/icons';
import { Button, ButtonProps, Dropdown } from 'antd';
import { useTranslation } from 'react-i18next';

import { DROPDOWN_TRIGGERS, MenuItemConversationAction } from 'common-ui';
import { DealBase, dealBaseApi } from 'core/lib';
import { OBJECT_TYPES } from 'core/lib/constants';
import { EXTERNAL_ACCESS } from 'core/lib/constants/dealbase';
// import appApi from 'core/lib/modules/app/services/app.api';
// import { downloadAuditLog } from 'core/lib/utils/requesting';

import ExternalAccessModal from './externalAccessModal';

type DetailsHeaderActionsProps = {
  dealbase: DealBase;
};

const DetailsHeaderActions = ({ dealbase, ...props }: DetailsHeaderActionsProps & ButtonProps) => {
  const { t } = useTranslation('dealbase');
  const [isExternalAccessModalOpen, setExternallAccessModalOpen] = useState(false);
  const [externalAccessModalTitle, setExternalAccessModalTitle] = useState('');

  const [getExternalAccess, { data, isFetching: isLoading }] = dealBaseApi.useLazyGetDealBaseExternalAccessQuery();
  // const [createAuditLog, { data: blobId, isSuccess: createAuditLogSuccess }] = appApi.useLazyCreateAuditLogQuery();
  // const [downloadfile, { data: downloadData }] = appApi.useLazyDownloadBase64Query();

  const getOwnerExternalAccess = () => {
    getExternalAccess({ id: dealbase.id, type: EXTERNAL_ACCESS.CASE_OWNER });
    setExternalAccessModalTitle(t('actions.shareWithCaseOwner'));
    setExternallAccessModalOpen(true);
  };

  const getThirdPartyExternalAccess = () => {
    getExternalAccess({ id: dealbase.id, type: EXTERNAL_ACCESS.THIRD_PARTY });
    setExternalAccessModalTitle(t('actions.shareWithThirdParty'));
    setExternallAccessModalOpen(true);
  };

  const closeExternalAccessModal = () => {
    setExternallAccessModalOpen(false);
  };

  // const onExportAuditLogClick = () => {
  //   createAuditLog({ objectId: dealbase.id, objectType: OBJECT_TYPES.DEAL_BASE });
  // };

  // useEffect(() => {
  //   if (createAuditLogSuccess && blobId) {
  //     downloadfile(blobId);
  //   }
  // }, [createAuditLogSuccess, blobId, downloadfile]);

  // useEffect(() => {
  //   if (downloadData) {
  //     downloadAuditLog(t('global:moduleNames.dealbase'), downloadData);
  //   }
  // }, [downloadData, t]);

  const items = useMemo(() => {
    return [
      { key: 'case_owner_share', onClick: getOwnerExternalAccess, label: t('actions.shareWithCaseOwner') },
      { key: 'third_party_share', onClick: getThirdPartyExternalAccess, label: t('actions.shareWithThirdParty') },
      {
        key: 'conversation_action',
        label: (
          <MenuItemConversationAction
            objectType={OBJECT_TYPES.DEAL_BASE}
            objectId={dealbase.id}
            objectName={dealbase.name}
          />
        ),
      },
      // { key: 'export_audit_log', label: t('actions:global.exportAuditLog') },
    ];
  }, [dealbase, t]); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Dropdown trigger={DROPDOWN_TRIGGERS} menu={{ items }}>
        <Button {...props} type="default">
          <EllipsisOutlined />
        </Button>
      </Dropdown>
      <ExternalAccessModal
        onClose={closeExternalAccessModal}
        isOpen={isExternalAccessModalOpen}
        externalAccess={data}
        isLoading={isLoading}
        title={externalAccessModalTitle}
      />
    </>
  );
};

export default DetailsHeaderActions;
