import React, { useEffect } from 'react';

import { Route, Switch, Redirect, generatePath } from 'react-router-dom';

import { setActiveModule, useAppDispatch } from 'core/lib';
import { OBJECT_TYPES } from 'core/lib/constants';
import { Routes } from 'routes/routes';

import OrganizationListing from './listing';

const Organization = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(setActiveModule(OBJECT_TYPES.ORGANIZATION));
    return () => {
      dispatch(setActiveModule(null));
    };
  }, [dispatch]);

  return (
    <Switch>
      <Route
        path={Routes.ORGANIZATION.path}
        exact
        render={() => <Redirect to={generatePath(Routes.ORGANIZATION_LISTING.path)} />}
      />
      <Route path={Routes.ORGANIZATION_LISTING.path} exact component={OrganizationListing} />
    </Switch>
  );
};

export default Organization;
