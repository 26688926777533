import React, { useState } from 'react';

import { HTMLContent, JSONContent } from '@tiptap/core';
import { Editor } from '@tiptap/react';
import { Button, Form, Input } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { uniq } from 'lodash';
import { useTranslation } from 'react-i18next';

import { WysiwygEditor } from 'common-ui/wysiwyg';
import { BaseComment, DraftBaseComment, userApi } from 'core/lib';
import { rules } from 'utils/form/rules';

import { getCommentContent } from './utils';

interface DraftComment extends Omit<Partial<DraftBaseComment>, 'content'> {
  content: JSONContent | HTMLContent;
}

type EditorProps = {
  type: string;
  nameRequired?: boolean;
  onSubmit: (comment: Partial<DraftBaseComment>, callback?: () => void) => void;
  onCancel?: () => void;
  submitting: boolean;
  value?: BaseComment;
  disabled?: boolean;
  external?: boolean;
  autoFocus?: boolean;
};

const CommentsEditor = ({
  onSubmit,
  onCancel,
  external,
  nameRequired,
  submitting,
  type,
  value,
  disabled,
  autoFocus,
}: EditorProps) => {
  const [form] = useForm();
  const { t } = useTranslation();
  const [editor, setEditor] = useState<Editor | null>(null);

  const { data } = userApi.useGetUsersShortQuery();

  const [draftComment] = useState<Partial<DraftComment | undefined>>(
    value && {
      ...value,
      content: getCommentContent(value?.content),
    }
  );

  const getMentionIds = (rawContent: JSONContent) => {
    let mentionIds: number[] = [];
    if (rawContent.type === 'mention') {
      if (rawContent.attrs?.id) {
        mentionIds = mentionIds.concat(rawContent.attrs?.id);
      }
    } else if (rawContent.content) {
      rawContent.content.forEach((content) => {
        mentionIds = mentionIds.concat(getMentionIds(content));
      });
    }

    return mentionIds;
  };

  const handleSubmit = ({
    content: rawContent,
    externalUsername,
  }: {
    content: JSONContent;
    externalUsername?: string;
  }) => {
    const content = JSON.stringify(rawContent);
    const mentions = uniq(getMentionIds(rawContent ?? []));

    onSubmit(
      {
        ...draftComment,
        ...value,
        externalUsername,
        content,
        rawText: editor?.getText(),
        html: editor?.getHTML(),
        mentions,
      },
      form.resetFields
    );
  };

  const handleCancel = () => {
    form.resetFields();
    onCancel && onCancel();
  };

  return (
    <Form onFinish={handleSubmit} initialValues={draftComment} form={form} component={false}>
      {nameRequired && (
        <Form.Item rules={[rules.required]} className="w-full" name="externalUsername">
          <Input disabled={submitting} placeholder={t('global:placeholders.enterName')} />
        </Form.Item>
      )}
      <Form.Item rules={[rules.required]} name="content">
        <WysiwygEditor
          disabled={disabled}
          setEditor={setEditor}
          mentionsData={data}
          external={external}
          autoFocus={autoFocus}
        />
      </Form.Item>
      <div className="flex gap-4">
        <Form.Item>
          <Button disabled={disabled} loading={submitting} onClick={form.submit} type="primary">
            {t('actions:global.addType', { type: type.toLocaleLowerCase() })}
          </Button>
        </Form.Item>
        {onCancel && (
          <Form.Item>
            <Button onClick={handleCancel} type="ghost">
              {t('actions:global.cancel')}
            </Button>
          </Form.Item>
        )}
      </div>
    </Form>
  );
};

export default CommentsEditor;
