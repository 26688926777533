import React, { useCallback, useEffect, useState } from 'react';

import { Anchor, Card, Form, Space, Button, message } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { ResultStatusType } from 'antd/lib/result';
import { addYears, format, subYears } from 'date-fns';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';

import { ClientLogo } from 'common-ui';
import ResultWrapper from 'common-ui/wrappers/result';
import { DealBase, dealBaseApi, FinancialYear } from 'core/lib';
import DealBaseBuilder from 'core/lib/utils/builders/DealBaseBuilder';

import ExternalNordicFooter from '../externalNordicFooter';
import {
  ContactInformationSection,
  DocumentsSection,
  FinancialSection,
  GeneralInformationSection,
  OverviewSection,
} from '../sections';

const { Link } = Anchor;

const N_YEARS = 3;

const lastNFinancialYears = Array.from({ length: N_YEARS }, (_, i) => {
  const year = parseInt(format(subYears(new Date(), i), 'yyyy'));
  return { year, lockedYear: true } as FinancialYear & { lockedYear: boolean };
});

const nextNFinancialYears = Array.from({ length: N_YEARS }, (_, i) => {
  const year = parseInt(format(addYears(new Date(), i), 'yyyy'));
  return { year } as FinancialYear;
}).reverse();

const ExternalDealBaseForm = () => {
  const getInitialDealBase = () =>
    new DealBaseBuilder()
      .revenueYears(lastNFinancialYears)
      .ebitdaYears(lastNFinancialYears)
      .revenueProjections(nextNFinancialYears)
      .ebitdaProjections(nextNFinancialYears)
      .founder([{ jobTitle: '', fullname: '' }])
      .build();
  const { t } = useTranslation(['dealbase', 'global']);
  const [dealbase, setInitialDealbase] = useState(getInitialDealBase);
  const [targetOffset, setTargetOffset] = useState<number | undefined>(undefined);
  const [submissionStatus, setSubmissionStatus] = useState<ResultStatusType | undefined>();
  const [captchaToken, setCaptchaToken] = useState<string | null>(null);
  const [formObject] = useForm<DealBase>();

  useEffect(() => {
    setTargetOffset(window.innerHeight / 2);
  }, []);

  const [postDealBase, { isLoading: isSaving, isError, isSuccess }] = dealBaseApi.usePostExternalDealBaseMutation();

  const onFinish = (dealbase: DealBase) => {
    dealbase['g_recaptcha_response'] = captchaToken;
    postDealBase(dealbase);
  };

  const initForm = useCallback(() => {
    setInitialDealbase(getInitialDealBase());
    formObject.resetFields();
  }, [formObject]);

  useEffect(() => {
    if (!submissionStatus) {
      initForm();
    }
  }, [submissionStatus, initForm]);

  const onReturnToForm = () => {
    setSubmissionStatus(undefined);
  };

  useEffect(() => {
    if (isSuccess) {
      setSubmissionStatus('success');
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      message.error(t('actions:message.failedSubmission'));
    }
  }, [isError, t]);

  return (
    <div className="flex flex-col lg:px-16 px-4 px-2 h-full">
      <Space className="justify-center bg-white py-8" align="center">
        <ClientLogo height={32} />
      </Space>
      <ResultWrapper onConfirm={onReturnToForm} status={submissionStatus}>
        <div className="flex flex-auto flex-row justify-between">
          <Form
            requiredMark="optional"
            className="flex flex-col flex-auto gap-4"
            layout="vertical"
            name="dealBaseForm"
            scrollToFirstError
            onFinish={onFinish}
            form={formObject}
            initialValues={dealbase}
          >
            <div className="flex flex-col flex-auto gap-4">
              <Card id="general-information" title={t('dealbase:sections.generalInformation')}>
                <GeneralInformationSection disabled={isSaving} />
              </Card>
              <Card id="contact-information" title={t('dealbase:sections.contactDetails')}>
                <ContactInformationSection disabled={isSaving} />
              </Card>
              <Card id="overview" title={t('dealbase:sections.overview')}>
                <OverviewSection disabled={isSaving} />
              </Card>
              <Card id="financials" title={t('dealbase:sections.financials')}>
                <FinancialSection disabled={isSaving} />
              </Card>
              <Card id="documents" title={t('dealbase:sections.documents')}>
                <DocumentsSection disabled={isSaving} />
              </Card>
            </div>
            <div className="flex flex-col gap-8 flex-auto items-center justify-center py-16">
              <ReCAPTCHA sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA!} onChange={setCaptchaToken} />
              <Button
                disabled={!captchaToken}
                size="large"
                className="min-w-64"
                loading={isSaving}
                htmlType="submit"
                type="primary"
              >
                {t('actions:global.submit')}
              </Button>
            </div>
          </Form>
          <div className="p-4 hidden md:block">
            <Anchor targetOffset={targetOffset} offsetTop={16}>
              <Link href="#general-information" title={t('dealbase:sections.generalInformation')} />
              <Link href="#contact-information" title={t('dealbase:sections.contactDetails')} />
              <Link href="#overview" title={t('dealbase:sections.overview')} />
              <Link href="#financials" title={t('dealbase:sections.financials')} />
              <Link href="#documents" title={t('dealbase:sections.documents')} />
            </Anchor>
          </div>
        </div>
      </ResultWrapper>
      <ExternalNordicFooter />
    </div>
  );
};

export default ExternalDealBaseForm;
