import { DEALBASE_STATUS } from './statuses';

export const EXTERNAL_ACCESS = {
  CASE_OWNER: 1,
  THIRD_PARTY: 2,
};

export const SCORING_STATUS = [
  DEALBASE_STATUS.NEW,
  DEALBASE_STATUS.PRE_SCREENING,
  DEALBASE_STATUS.SCREENING,
  DEALBASE_STATUS.INVESTMENT_ON_HOLD,
  DEALBASE_STATUS.WATCHLIST,
];

export const EXTERNAL_ATTACHMENT_PREFIX = 'external';

export const NORDIC_ATTACHMENT_PREFIX = 'nordicEye';
