import React, { useState } from 'react';

import { EditOutlined } from '@ant-design/icons';
import { Card } from 'antd';

import { UserAvatarGroup } from 'common-ui';
import { Organization } from 'core/lib';

import OrganizationCardActions from './organizationCardActions';
import OrganizationEditCard from './organizationEditCard';

type OrganizationCardProps = {
  organization: Organization;
};

const OrganizationCard = ({ organization }: OrganizationCardProps) => {
  const [editMode, setEditMode] = useState(false);

  const onEditClick = () => {
    setEditMode(true);
  };

  const onCancel = () => {
    setEditMode(false);
  };

  const EditActions = [
    <EditOutlined key="edit" onClick={onEditClick} />,
    <OrganizationCardActions organization={organization} key="actions" />,
  ];

  return editMode ? (
    <OrganizationEditCard organization={organization} onCancel={onCancel} />
  ) : (
    <Card title={organization.name} actions={EditActions}>
      <div className="flex flex-col gap-4">
        <div>#{organization.id}</div>
        <div className="self-end">
          <UserAvatarGroup maxCount={5} users={organization.users} />
        </div>
      </div>
    </Card>
  );
};

export default OrganizationCard;
