import React, { useEffect, useMemo, useState } from 'react';

import { Skeleton } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import { InView } from 'react-intersection-observer';
import { connect, ConnectedProps } from 'react-redux';

import { AppState, BaseUser, conversationsApi, selectConversationMessages } from 'core/lib';
import LoaderWrapper from 'utils/loader-wrapper';

import MessageRenderer from './messageRenderer';

type MessagesListingProps = {
  conversationId: number;
  infiniteScrollRef: React.RefObject<HTMLDivElement>;
  conversationTitle?: string;
  conversationParticipants: BaseUser[];
  totalMessages?: number;
  loaderMessage: string;
};

const mapState = (state: AppState, ownProps: MessagesListingProps) => ({
  conversationMessages: selectConversationMessages(ownProps.conversationId)(state),
});

const connector = connect(mapState);
type MessagesListingPropsConnectedProps = ConnectedProps<typeof connector> & MessagesListingProps;

const MessagesListing = ({
  conversationId,
  conversationTitle,
  conversationParticipants,
  infiniteScrollRef,
  totalMessages = 0,
  conversationMessages,
  loaderMessage,
}: MessagesListingPropsConnectedProps) => {
  const [page, setPage] = useState(0);
  const [isAtBottom, setBottomVisible] = useState<boolean>(true);

  const [getMessages, { isLoading: loadingMessages, isUninitialized }] =
    conversationsApi.useLazyGetConversationMessagesQuery({
      refetchOnReconnect: true,
    });

  const loadMoreData = () => {
    const nextPage = page + 1;
    getMessages({ conversationId, page: nextPage }, false);

    setPage(nextPage);
  };

  const refreshData = () => {
    getMessages({ conversationId, page: 0 }, false);
  };

  useEffect(() => {
    if (isAtBottom) {
      infiniteScrollRef.current?.scrollIntoView(false);
    }
  }, [conversationMessages.length]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setPage(0);
  }, [conversationId, isUninitialized]);

  useEffect(() => {
    if (conversationId) {
      getMessages({ conversationId, page: 0 }, false);
    }
  }, [conversationId, isUninitialized]); //eslint-disable-line react-hooks/exhaustive-deps

  const mappedMessages = useMemo(() => {
    return conversationMessages.map((item, index) => {
      const prevItem = conversationMessages[index - 1];

      return (
        <MessageRenderer
          conversationParticipants={conversationParticipants}
          key={item.id}
          conversationTitle={conversationTitle}
          message={item}
          prevMessage={prevItem}
        />
      );
    });
  }, [conversationMessages, conversationTitle, conversationParticipants]);

  return (
    <LoaderWrapper loading={loadingMessages} message={loaderMessage}>
      <div id="scrollableDiv" className="overflow-auto flex flex-col-reverse h-full">
        <InfiniteScroll
          className="flex flex-col-reverse will-change-auto"
          refreshFunction={refreshData}
          pullDownToRefresh
          dataLength={conversationMessages.length}
          next={loadMoreData}
          hasMore={totalMessages > conversationMessages.length}
          inverse
          loader={<Skeleton avatar paragraph={{ rows: 2 }} active />}
          scrollableTarget="scrollableDiv"
        >
          <div>
            {mappedMessages}
            <InView rootMargin="100px" as="div" onChange={(inView) => setBottomVisible(inView)}>
              <div ref={infiniteScrollRef}></div>
            </InView>
          </div>
        </InfiniteScroll>
      </div>
    </LoaderWrapper>
  );
};

export default connector(MessagesListing);
