import React, { useContext, useMemo } from 'react';

import { InfoCircleOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Divider, Form } from 'antd';
import { uniqBy } from 'lodash';
import { useTranslation } from 'react-i18next';

import { useAppSelector, BaseSelectOption, DealBase, selectCurrentUser, UserOption } from 'core/lib';
import { SCORING_STATUS } from 'core/lib/constants/dealbase';
import { SYSTEM_ROLES } from 'core/lib/constants/systemRoles';
import { DealBaseDetailsContext } from 'modules/dealBase/context';
import { dealBaseFormConfig } from 'modules/dealBase/form/formConfig';
import PermissionControl, { PermissionRules } from 'utils/permissionControl';

import ScoringDetails from '../components/scoringDetails';

const {
  evaluation: { initialScoring },
} = dealBaseFormConfig;

type InitialScoringSectionProps = {
  editMode: boolean;
};

const DealBaseInitialScoringSection = ({ editMode }: InitialScoringSectionProps) => {
  const { t } = useTranslation();
  const dealbase = useContext(DealBaseDetailsContext) as DealBase;
  const currentUser = useAppSelector(selectCurrentUser);
  const dealbaseInitialScorecards = useMemo(
    () => dealbase.initialScorecards.filter((scorecard) => scorecard),
    [dealbase.initialScorecards]
  );

  const scorecardUsers = useMemo(
    () => dealbaseInitialScorecards.filter((scorecard) => scorecard.user).map((scorecard) => scorecard.user),
    [dealbaseInitialScorecards]
  );

  const scorecardUserIds = useMemo(() => scorecardUsers.map((user) => user.id), [scorecardUsers]);

  const userHasScorecard = () => {
    return scorecardUserIds.includes(currentUser!.id);
  };

  const getCurrentUserAsInitialValue = () => {
    return {
      user: currentUser,
    };
  };

  const userIsScorecardOwner = (index: number) => {
    return scorecardUserIds[index] === currentUser!.id;
  };

  const filterSelectedUsers = (userOptions: BaseSelectOption[]) => {
    const scorecardUsersOptions = scorecardUsers.map((user) => ({ id: user.id, value: user.id, label: user.name }));

    return uniqBy((userOptions ?? []).concat(scorecardUsersOptions), 'value')?.map((usr) => ({
      ...usr,
      disabled: scorecardUserIds.includes(usr.id),
    }));
  };

  return editMode ? (
    <div className="flex flex-col">
      <Form.List name={initialScoring.dataIndex} rules={initialScoring.rules}>
        {(initialScorecards, { add, remove }) => {
          return (
            <div className="flex flex-col">
              {initialScorecards.map(({ key, name, ...restField }) => {
                const statusDisabled = !SCORING_STATUS.includes(dealbase.status);
                return (
                  <div key={key} className="flex flex-col">
                    <PermissionControl
                      roles={[SYSTEM_ROLES.CLIENT_ADMIN]}
                      restricted={!editMode || statusDisabled}
                      disabled
                      render={(_, disabled) => {
                        const userCanEdit = !statusDisabled && (userIsScorecardOwner(name) || !disabled);

                        return (
                          <div className="flex items-center flex-row gap-x-4">
                            <div className="flex flex-auto sm:flex-row flex-col gap-x-4 lg:flex-wrap">
                              <Form.Item
                                {...restField}
                                className="w-full"
                                label={initialScoring.user.label}
                                rules={initialScoring.user.rules}
                                name={[name, initialScoring.user.dataIndex]}
                                valuePropName="selectedOption"
                                normalize={(value: UserOption) => {
                                  return { id: value.id, name: value.label };
                                }}
                              >
                                <initialScoring.user.component
                                  className="w-full"
                                  disabled={disabled}
                                  asAvatar
                                  filterFn={filterSelectedUsers}
                                />
                              </Form.Item>
                              <Form.Item
                                {...restField}
                                className="w-full"
                                label={initialScoring.traction.label}
                                rules={initialScoring.traction.rules}
                                name={[name, initialScoring.traction.dataIndex]}
                                tooltip={{
                                  title: t('dealbase:form.financialEvaluation.tooltip.traction'),
                                  icon: <InfoCircleOutlined />,
                                }}
                              >
                                <initialScoring.traction.component className="w-full" disabled={!userCanEdit} />
                              </Form.Item>
                              <Form.Item
                                {...restField}
                                className="w-full"
                                label={initialScoring.greatness.label}
                                rules={initialScoring.greatness.rules}
                                name={[name, initialScoring.greatness.dataIndex]}
                                tooltip={{
                                  title: t('dealbase:form.financialEvaluation.tooltip.greatness'),
                                  icon: <InfoCircleOutlined />,
                                }}
                              >
                                <initialScoring.greatness.component className="w-full" disabled={!userCanEdit} />
                              </Form.Item>
                              <Form.Item
                                {...restField}
                                className="w-full"
                                label={initialScoring.scalability.label}
                                rules={initialScoring.scalability.rules}
                                name={[name, initialScoring.scalability.dataIndex]}
                                tooltip={{
                                  title: t('dealbase:form.financialEvaluation.tooltip.scalability'),
                                  icon: <InfoCircleOutlined />,
                                }}
                              >
                                <initialScoring.scalability.component className="w-full" disabled={!userCanEdit} />
                              </Form.Item>
                              <Form.Item
                                {...restField}
                                className="w-full"
                                label={initialScoring.uniqueness.label}
                                rules={initialScoring.uniqueness.rules}
                                name={[name, initialScoring.uniqueness.dataIndex]}
                                tooltip={{
                                  title: t('dealbase:form.financialEvaluation.tooltip.uniqueness'),
                                  icon: <InfoCircleOutlined />,
                                }}
                              >
                                <initialScoring.uniqueness.component className="w-full" disabled={!userCanEdit} />
                              </Form.Item>
                              <Form.Item
                                {...restField}
                                className="w-full"
                                label={initialScoring.fit.label}
                                rules={initialScoring.fit.rules}
                                name={[name, initialScoring.fit.dataIndex]}
                                tooltip={{
                                  title: t('dealbase:form.financialEvaluation.tooltip.fit'),
                                  icon: <InfoCircleOutlined />,
                                }}
                              >
                                <initialScoring.fit.component className="w-full" disabled={!userCanEdit} />
                              </Form.Item>
                            </div>
                            {userCanEdit && <MinusCircleOutlined onClick={() => remove(name)} />}
                          </div>
                        );
                      }}
                    />
                    {initialScorecards.length > 1 && <Divider />}
                  </div>
                );
              })}
              <PermissionControl
                roles={[SYSTEM_ROLES.CLIENT_ADMIN]}
                rule={PermissionRules.NONE}
                disabled
                render={(_, isAdmin) => {
                  const statusDisabled = !SCORING_STATUS.includes(dealbase.status);
                  const initialValue = isAdmin ? undefined : getCurrentUserAsInitialValue();
                  const enabled = !statusDisabled && (isAdmin || !userHasScorecard());
                  return editMode ? (
                    <Form.Item>
                      <Button
                        type="dashed"
                        disabled={!enabled}
                        onClick={() => {
                          add(initialValue);
                        }}
                        block
                        icon={<PlusOutlined />}
                      >
                        {t('dealbase:actions.addScorecard')}
                      </Button>
                    </Form.Item>
                  ) : null;
                }}
              />
            </div>
          );
        }}
      </Form.List>
    </div>
  ) : (
    <ScoringDetails scorecards={dealbaseInitialScorecards} />
  );
};

export default DealBaseInitialScoringSection;
