import React, { useEffect, useMemo, useState } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import { Button, Form, Modal, notification, Select, Typography } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router-dom';

import {
  conversationsApi,
  CreateModuleConversationRequest,
  GetModuleConversationUsersRequest,
  RequestError,
  useAppSelector,
  selectConversationModuleOptions,
} from 'core/lib';
import { EXCEPTIONS } from 'core/lib/constants';
import { Routes } from 'routes/routes';
import { rules } from 'utils/form/rules';

const { Text } = Typography;

type CreateModuleConversationProps = {
  objectType?: number;
  objectOption?: { value: number; label: string };
  trigger?: JSX.Element;
};

const CreateModuleConversationModal = ({ objectType, objectOption, trigger }: CreateModuleConversationProps) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [selectedObjectType, setCurrentSelectedObjectType] = useState<number | null>(objectType ?? null);
  const [userOptions, setUserOptions] = useState<{ value: number; label: string }[]>([]);
  const [moduleObjectOptions, setModuleObjectOptions] = useState<{ value: number; label: string }[]>(
    objectOption ? [objectOption] : []
  );
  const { t } = useTranslation();
  const [form] = useForm();
  const history = useHistory();
  const moduleOptions = useAppSelector(selectConversationModuleOptions);

  const [getUsers, { data: users, isFetching: loadingUsers }] =
    conversationsApi.useLazyGetModuleConversationUsersQuery();

  const [createConversation, { isLoading: isSaving, error }] = conversationsApi.useCreateModuleConversationMutation();

  const [
    getModuleObjects,
    { currentData: moduleObjects, isFetching: loadingModuleObjects, originalArgs: objectTypeArgument },
  ] = conversationsApi.useLazyGetModuleConversationObjectsQuery();

  useEffect(() => {
    const options = !loadingUsers ? users?.map((object) => ({ value: object.id, label: object.name })) : [];
    setUserOptions(options ?? []);
  }, [users, loadingUsers]);

  useEffect(() => {
    const options =
      !loadingModuleObjects && objectTypeArgument === selectedObjectType
        ? moduleObjects?.map((object) => ({ value: object.id, label: object.title }))
        : [];
    if (objectTypeArgument) {
      setModuleObjectOptions(options ?? []);
    }
  }, [moduleObjects, loadingModuleObjects, selectedObjectType, objectTypeArgument]);

  const toggleCreateConversationModal = () => {
    setModalOpen(!modalOpen);
  };

  const onCancel = () => {
    setModalOpen(false);
    form.resetFields();
    setCurrentSelectedObjectType(null);
    setModuleObjectOptions([]);
    setUserOptions([]);
  };

  const onCreate = (moduleConv: CreateModuleConversationRequest) => {
    moduleConv.users = moduleConv.users ?? [];

    createConversation(moduleConv)
      .unwrap()
      .then((moduleConvId) => {
        onCancel();
        history.push(generatePath(Routes.CONVERSATIONS_MODULE_DETAILS.path, { id: moduleConvId }));
      });
  };

  const onNotificationViewConversation = (moduleConversationId: string) => {
    onCancel();
    history.push(generatePath(Routes.CONVERSATIONS_MODULE_DETAILS.path, { id: moduleConversationId }));
  };

  useEffect(() => {
    if (error) {
      const key = `open${Date.now()}`;

      const errorData = error as RequestError;
      if (errorData.data.ExceptionType === EXCEPTIONS.EntryExists) {
        const { InnerException } = errorData.data;
        notification.open({
          type: 'error',
          message: t(`conversations:modal.info.conversationExists`),
          description: t('conversations:modal.info.conversationExistsDescription'),
          btn:
            InnerException && InnerException.ExceptionType === EXCEPTIONS.IdArgument ? (
              <Button
                type="primary"
                onClick={() => {
                  onNotificationViewConversation(InnerException.ExceptionMessage);
                  notification.close(key);
                }}
              >
                {t('conversations:actions.view')}
              </Button>
            ) : null,
          key,
        });
      }
    }
  }, [error, t]); // eslint-disable-line react-hooks/exhaustive-deps

  const modalTrigger = useMemo(
    () =>
      trigger ? (
        React.cloneElement(trigger, { onClick: toggleCreateConversationModal })
      ) : (
        <Button icon={<PlusOutlined />} type="link" onClick={toggleCreateConversationModal}>
          {t('conversations:actions.createConversation')}
        </Button>
      ),
    [trigger] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const onObjectDropdown = (open: boolean) => {
    if (open) {
      const objectType: number | undefined = form.getFieldValue('objectType');
      if (objectType) {
        getModuleObjects(objectType, true);
      }
    }
  };

  const onUserDropdown = (open: boolean) => {
    if (open) {
      const params = form.getFieldsValue(['objectType', 'objectId']) as Partial<GetModuleConversationUsersRequest>;
      if (params.objectType) {
        getUsers(params as GetModuleConversationUsersRequest);
      }
    }
  };

  const onObjectTypeSelect = (objectType: number) => {
    form.resetFields(['users', 'objectId']);
    setCurrentSelectedObjectType(objectType);
  };

  return (
    <div>
      {modalTrigger}
      <Modal
        title={t('conversations:modal.title.createConversation')}
        open={modalOpen}
        onCancel={onCancel}
        footer={
          <div>
            <Button onClick={onCancel} type="text">
              {t('actions:global.cancel')}
            </Button>
            <Button onClick={form.submit} loading={isSaving} type="primary">
              {t('actions:global.create')}
            </Button>
          </div>
        }
      >
        <div>
          <Text type="secondary">{t('conversations:modal.info.createConversationInfo')}</Text>
          <Form
            initialValues={{ objectId: objectOption?.value, objectType, users: [] }}
            form={form}
            layout="vertical"
            name="create_conversation"
            onFinish={onCreate}
          >
            <div>
              <Form.Item label={t('global:labels.module')} name="objectType" rules={[rules.required]}>
                <Select
                  disabled={!!objectType}
                  placeholder={t('global:placeholders.select')}
                  options={moduleOptions}
                  onChange={onObjectTypeSelect}
                />
              </Form.Item>
              <Form.Item label={t('global:labels.object')} name="objectId">
                <Select
                  disabled={!!objectOption}
                  placeholder={t('global:placeholders.select')}
                  allowClear
                  optionFilterProp="label"
                  showSearch
                  loading={loadingModuleObjects}
                  onDropdownVisibleChange={onObjectDropdown}
                  options={moduleObjectOptions}
                />
              </Form.Item>
              <Form.Item label={t('global:labels.users')} name="users">
                <Select
                  placeholder={t('global:placeholders.select')}
                  mode="multiple"
                  loading={loadingUsers}
                  onDropdownVisibleChange={onUserDropdown}
                  options={userOptions}
                />
              </Form.Item>
            </div>
          </Form>
          <Text type="secondary">{t('conversations:modal.info.userInvitation')}</Text>
        </div>
      </Modal>
    </div>
  );
};

export default CreateModuleConversationModal;
