import styled from 'styled-components';

export const StyledDateContainer = styled.div`
  display: none;
`;

export const StyledUserSequenceDate = styled.div`
  min-width: 32px;
`;

export const StyledOptionsContainer = styled.div`
  display: none;
`;

export const StyledUserSequenceMessageWrapper = styled.div`
  &:hover ${StyledDateContainer} {
    display: block;
  }
  &:hover ${StyledOptionsContainer} {
    display: block;
  }
`;

export const StyledMessageOptionsContainer = styled.div`
  &:hover ${StyledOptionsContainer} {
    display: block;
  }
`;
