import React from 'react';

import { ReloadOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { Switch, Route } from 'react-router-dom';

import { ListingSettings } from 'common-ui/listingConfig';
import { OBJECT_TYPES } from 'core/lib/constants';
import { portfolioApi, PortfolioListing } from 'core/lib/modules/portfolio';
import { Routes } from 'routes/routes';

import { columns } from '../listing/columns';

const ListingActions = () => {
  const [refetch] = portfolioApi.endpoints.getPortfolioListing.useLazyQuerySubscription();

  const onRefreshClick = () => {
    refetch();
  };

  return (
    <div className="flex flex-row justify-between items-center">
      <div className="flex flex-row gap-4">
        <Button type="link" className="text-black" onClick={onRefreshClick} icon={<ReloadOutlined />} />
      </div>
      <Switch>
        <Route
          path={Routes.PORTFOLIO_LISTING.path}
          component={() => (
            <ListingSettings<PortfolioListing> rawColumns={columns} objectType={OBJECT_TYPES.PORTFOLIO} />
          )}
        />
      </Switch>
    </div>
  );
};

export default ListingActions;
