import React from 'react';

type FiltersContainerProps = {
  children: React.ReactNode;
};

const FiltersContainer = ({ children }: FiltersContainerProps) => {
  return <div className="flex flex-col gap-4">{children}</div>;
};

export default FiltersContainer;
