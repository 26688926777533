import React from 'react';

import { Form } from 'antd';

import { UserOption } from 'core/lib';
import { contactBookFormConfig } from 'modules/contactBook/form/formConfig';

const {
  details: { insight },
} = contactBookFormConfig;

type InsightSectionProps = {
  editMode: boolean;
};

const InsightSection = ({ editMode }: InsightSectionProps) => {
  return (
    <>
      <div className="flex flex-col md:flex-row lg:flex-col gap-x-4">
        <Form.Item
          className="w-full lg:w-full md:w-3/6"
          label={insight.relations.label}
          name={insight.relations.dataIndex}
          rules={insight.relations.rules}
          valuePropName="selectedOption"
          normalize={(options: UserOption[]) => {
            return options.map((v) => ({ id: v.value, name: v.label }));
          }}
        >
          <insight.relations.component asAvatar disabled={!editMode} />
        </Form.Item>
        <Form.Item
          className="w-full lg:w-full md:w-3/6"
          label={insight.connection.label}
          name={insight.connection.dataIndex}
          rules={insight.connection.rules}
        >
          <insight.connection.component disabled={!editMode} />
        </Form.Item>
      </div>
      <div className="flex sm:flex-row flex-col gap-x-4">
        <Form.Item
          className="w-full"
          label={insight.notes.label}
          name={insight.notes.dataIndex}
          rules={insight.notes.rules}
        >
          <insight.notes.component disabled={!editMode} />
        </Form.Item>
      </div>
    </>
  );
};

export default InsightSection;
