import React, { useEffect, useState } from 'react';

import { Anchor, Card, Form, Space, Button, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ClientLogo } from 'common-ui';
import { DealBase, dealBaseApi } from 'core/lib';
import LoaderWrapper from 'utils/loader-wrapper';

import ExternalNordicFooter from '../externalNordicFooter';
import {
  ContactInformationSection,
  DocumentsSection,
  FinancialSection,
  GeneralInformationSection,
  OverviewSection,
} from '../sections';

const { Link } = Anchor;

const ExternalDealBaseUpdate = () => {
  const { t } = useTranslation(['dealbase', 'global']);
  const { dealId } = useParams<{ dealId: string }>();

  const {
    data: dealbase,
    isFetching: fetchingDealBase,
    refetch,
  } = dealBaseApi.useGetExternalDealBaseDetailsQuery(dealId);

  const [postDealBase, { isLoading: isSaving, isSuccess, isError }] = dealBaseApi.usePatchExternalDealBaseMutation();

  const [targetOffset, setTargetOffset] = useState<number | undefined>(undefined);

  useEffect(() => {
    setTargetOffset(window.innerHeight / 2);
  }, []);

  useEffect(() => {
    if (isSuccess) {
      refetch();
      message.success(t('actions:message.successSubmission'));
    }
  }, [isSuccess, refetch, t]);

  useEffect(() => {
    if (isError) {
      message.error(t('actions:message.failedSubmission'));
    }
  }, [isError, t]);

  const onFinish = (dealbaseUpdate: Partial<DealBase>) => {
    dealbaseUpdate.id = dealbase?.id;
    postDealBase(dealbaseUpdate);
  };

  return (
    <div className="flex flex-col lg:px-16 px-4 px-2 min-h-full">
      <Space className="justify-center bg-white py-8" align="center">
        <ClientLogo height={32} />
      </Space>
      <LoaderWrapper loading={fetchingDealBase} message={t('loaders:dealBase.loadingDetails')}>
        <div className="flex flex-row justify-between">
          <Form
            requiredMark="optional"
            initialValues={dealbase}
            className="flex flex-col flex-auto gap-4"
            layout="vertical"
            name="dealBaseForm"
            onFinish={onFinish}
          >
            <div className="flex flex-col flex-auto gap-4">
              <Card id="general-information" title={t('dealbase:sections.generalInformation')}>
                <GeneralInformationSection disabled={isSaving} />
              </Card>
              <Card id="contact-information" title={t('dealbase:sections.contactDetails')}>
                <ContactInformationSection disabled={isSaving} />
              </Card>
              <Card id="overview" title={t('dealbase:sections.overview')}>
                <OverviewSection disabled={isSaving} />
              </Card>
              <Card id="financials" title={t('dealbase:sections.financials')}>
                <FinancialSection disabled={isSaving} />
              </Card>
              <Card id="documents" title={t('dealbase:sections.documents')}>
                <DocumentsSection disabled={isSaving} updateMode />
              </Card>
            </div>
            <div className="flex flex-auto items-center justify-center py-16">
              <Button loading={isSaving} size="large" className="min-w-64" htmlType="submit" type="primary">
                {t('actions:global.submit')}
              </Button>
            </div>
          </Form>
          <div className="p-4 hidden md:block">
            <Anchor targetOffset={targetOffset} offsetTop={16}>
              <Link href="#general-information" title={t('dealbase:sections.generalInformation')} />
              <Link href="#contact-information" title={t('dealbase:sections.contactDetails')} />
              <Link href="#overview" title={t('dealbase:sections.overview')} />
              <Link href="#financials" title={t('dealbase:sections.financials')} />
              <Link href="#documents" title={t('dealbase:sections.documents')} />
            </Anchor>
          </div>
        </div>
      </LoaderWrapper>
      <ExternalNordicFooter />
    </div>
  );
};

export default ExternalDealBaseUpdate;
