import React, { useCallback, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';

import { EmptyWrapper } from 'common-ui/wrappers';
import { AppState } from 'core/lib';
import { setCreateNewMode, createNewOrganizationModeSelector } from 'core/lib/modules/organization';
import { organizationApi } from 'core/lib/modules/organization/services';
import LoaderWrapper from 'utils/loader-wrapper';

import { OrganizationCard } from '../components';
import OrganizationEditCard from '../components/organizationEditCard';
import { ListingContainer } from '../containers';

const mapState = (state: AppState) => ({
  createNewMode: createNewOrganizationModeSelector(state),
});

const mapDispatch = {
  setCreateNewMode,
};

const connector = connect(mapState, mapDispatch);
type OrganizationListingProps = ConnectedProps<typeof connector>;

const OrganizationListing = ({ createNewMode, setCreateNewMode }: OrganizationListingProps) => {
  const { t } = useTranslation('organization');
  const { data, isFetching } = organizationApi.useGetListingQuery();

  const onCancel = useCallback(() => {
    setCreateNewMode(false);
  }, [setCreateNewMode]);

  useEffect(() => {
    return onCancel;
  }, [onCancel]);

  return (
    <LoaderWrapper loading={isFetching} message={t('loaders:organization.loadingListing')}>
      <ListingContainer count={data?.length ?? 0}>
        <EmptyWrapper description={t('global:results.emptySearch')} empty={!data?.length && !createNewMode}>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {createNewMode && <OrganizationEditCard onCancel={onCancel} />}
            {data?.map((organization) => (
              <OrganizationCard key={organization.id} organization={organization} />
            ))}
          </div>
        </EmptyWrapper>
      </ListingContainer>
    </LoaderWrapper>
  );
};

export default connector(OrganizationListing);
