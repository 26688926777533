import React from 'react';

import { useTranslation } from 'react-i18next';

import { userPreferencesApi } from 'core/lib';
import LoaderWrapper from 'utils/loader-wrapper';

import HubSubscriptions from './hubSubscribtions';

type UserPreferencesWrapperProps = {
  children: React.ReactNode;
};

const UserPreferencesWrapper = ({ children }: UserPreferencesWrapperProps) => {
  const { t } = useTranslation();
  const { isLoading } = userPreferencesApi.useGetPreferencesQuery();

  return (
    <LoaderWrapper loading={isLoading} message={t('loaders:global.fetchingPreferences')}>
      <HubSubscriptions>{children}</HubSubscriptions>
    </LoaderWrapper>
  );
};

export default UserPreferencesWrapper;
