import { DealBaseStatusType } from '../../../constants/statuses/dealbase';
import { deserializeAttachment, deserializeNumberRange } from '../../app';
import { deserializeOrganizationShortResponse } from '../../organization/services/serializers';
import { deserializeCommonObject, deserializeCommonObjectList } from '../../shared/services/commonObjects';
import { deserializeUserShortListResponse, deserializeUserShortResponse } from '../../users/services';
import {
  DealBase,
  DealBaseDetailsResponse,
  DealBaseFounder,
  DealBaseFounderResponse,
  DealBaseInfo,
  DealBaseInfoResponse,
  DealBaseListing,
  DealBaseListingResponse,
  DealBaseMenu,
  DealBaseMenuResponse,
  DealBaseOption,
  DealBaseShort,
  DealBaseShortResponse,
  ExternalAccessResponse,
  ExternalDealBase,
  ExternalDealBaseDetailsResponse,
  ExternallAccessCredentials,
  FinancialYear,
  FinancialYearResponse,
  Scorecard,
  ScorecardResponse,
} from '../entities/dealBase';

export const deserializeDealBaseResponse = (response: DealBaseDetailsResponse): DealBase => {
  return {
    id: response.ID,
    name: response.Name,
    technology: response.Technology && deserializeCommonObject(response.Technology),
    industries: response.Industries && deserializeCommonObjectList(response.Industries),
    customerFocus: response.CustomerFocus && deserializeCommonObjectList(response.CustomerFocus),
    country: response.Country,
    city: response.City,
    website: response.Website,
    foundingYear: response.FoundingYear,
    founders: deserializeFounderResponse(response.Founders),
    stage: response.Stage && deserializeCommonObject(response.Stage),
    businessIdea: response.BusinessIdea,
    productStatus: response.ProductStatus && deserializeCommonObject(response.ProductStatus),
    problemSolve: response.ProblemSolve,
    marketSize: response.MarketSize,
    yourCustomer: response.YourCustomer,
    getYourCustomer: response.GetYourCustomer,
    tractionSoFar: response.TractionSoFar,
    roundSpecifications: response.RoundSpecifications,
    ceo: response.CEO,
    heardFrom: response.HeardFrom,
    contactPerson: response.ContactPerson,
    contactNumber: response.ContactNumber,
    contactEmail: response.ContactEmail,
    companyLogo: response.CompanyLogo && deserializeAttachment(response.CompanyLogo),
    pitchDeckDocument: response.PitchDeckDocument && deserializeAttachment(response.PitchDeckDocument),
    businessPlanDocument: response.BusinessPlanDocument && deserializeAttachment(response.BusinessPlanDocument),
    budgetDocument: response.BudgetDocument && deserializeAttachment(response.BudgetDocument),
    internalAttachments: response.InternalAttachments.map(deserializeAttachment),
    externalAttachments: response.ExternalAttachments.map(deserializeAttachment),
    organization: response.Organization && deserializeOrganizationShortResponse(response.Organization),
    assigned: response.Assigned && deserializeUserShortResponse(response.Assigned),
    involved: deserializeUserShortListResponse(response.Involved),
    preMoneyValuation: response.PreMoneyValuation && deserializeNumberRange(response.PreMoneyValuation),
    investmentRound: response.InvestmentRound && deserializeNumberRange(response.InvestmentRound),
    nextSteps: response.NextSteps,
    timeFrame: response.TimeFrame,
    followUpDate: response.FollowUpDate,
    sort: response.Sort,
    priority: response.Priority,
    tier: response.Tier,
    createdBy: response.CreatedBy && deserializeUserShortResponse(response.CreatedBy),
    ticketSize: response.TicketSize,
    commitedCapital: response.CommitedCapital,
    neOption: response.NeOption,
    initialScorecards: deserializeScorecard(response.InitialScorecards),
    currency: response.Currency,
    revenueType: response.RevenueType && deserializeCommonObject(response.RevenueType),
    revenueYears: deserializeFinancialYear(response.RevenueYears),
    revenueProjections: deserializeFinancialYear(response.RevenueProjections ?? []),
    ebitdaYears: deserializeFinancialYear(response.EbitdaYears),
    ebitdaProjections: deserializeFinancialYear(response.EbitdaProjections ?? []),
    status: response.Status as DealBaseStatusType,
    lastStatus: response.LastStatus && (response.LastStatus as DealBaseStatusType),
  };
};

export const deserializeExternalDealBaseDetails = (response: ExternalDealBaseDetailsResponse): ExternalDealBase => {
  return {
    id: response.ID,
    name: response.Name,
    country: response.Country,
    industries: response.Industries && deserializeCommonObjectList(response.Industries),
    technology: response.Technology && deserializeCommonObject(response.Technology),
    customerFocus: response.CustomerFocus && deserializeCommonObjectList(response.CustomerFocus),
    city: response.City,
    website: response.Website,
    foundingYear: response.FoundingYear,
    founders: deserializeFounderResponse(response.Founders),
    stage: response.Stage && deserializeCommonObject(response.Stage),
    businessIdea: response.BusinessIdea,
    productStatus: response.ProductStatus && deserializeCommonObject(response.ProductStatus),
    problemSolve: response.ProblemSolve,
    marketSize: response.MarketSize,
    yourCustomer: response.YourCustomer,
    getYourCustomer: response.GetYourCustomer,
    tractionSoFar: response.TractionSoFar,
    roundSpecifications: response.RoundSpecifications,
    preMoneyValuation: response.PreMoneyValuation && deserializeNumberRange(response.PreMoneyValuation),
    investmentRound: response.InvestmentRound && deserializeNumberRange(response.InvestmentRound),
    ceo: response.CEO,
    heardFrom: response.HeardFrom,
    contactPerson: response.ContactPerson,
    contactNumber: response.ContactNumber,
    contactEmail: response.ContactEmail,
    companyLogo: response.CompanyLogo && deserializeAttachment(response.CompanyLogo),
    pitchDeckDocument: response.PitchDeckDocument && deserializeAttachment(response.PitchDeckDocument),
    businessPlanDocument: response.BusinessPlanDocument && deserializeAttachment(response.BusinessPlanDocument),
    budgetDocument: response.BudgetDocument && deserializeAttachment(response.BudgetDocument),
    externalAttachments: response.ExternalAttachments.map(deserializeAttachment),
    currency: response.Currency,
    revenueType: response.RevenueType && deserializeCommonObject(response.RevenueType),
    revenueYears: deserializeFinancialYear(response.RevenueYears),
    revenueProjections: deserializeFinancialYear(response.RevenueProjections ?? []),
    ebitdaYears: deserializeFinancialYear(response.EbitdaYears),
    ebitdaProjections: deserializeFinancialYear(response.EbitdaProjections ?? []),
  };
};

export const deserializeScorecard = (response: ScorecardResponse[]): Scorecard[] => {
  return response.map((res) => ({
    id: res.ID,
    fit: res.Fit,
    greatness: res.Greatness,
    scalability: res.Scalability,
    traction: res.Traction,
    uniqueness: res.Uniqueness,
    userId: res.User.ID,
    user: deserializeUserShortResponse(res.User),
  }));
};

export const deserializeFinancialYear = (response: FinancialYearResponse[]): FinancialYear[] => {
  return response.map((res) => ({
    id: res.ID,
    year: res.Year,
    amount: res.Amount,
  }));
};

export const deserializeDealBaseMenu = (response: DealBaseMenuResponse[]): DealBaseMenu[] => {
  return response.map((deal) => ({
    id: deal.ID,
    name: deal.Name,
    industryIds: deal.IndustryIDs,
    technologyId: deal.TechnologyID,
    assignedId: deal.AssignedID,
    tier: deal.Tier,
    status: deal.Status,
    lastStatus: deal.LastStatus,
    notification: deal.Notification,
  }));
};

export const deserializeDealBaseListingItem = (response: DealBaseListingResponse): DealBaseListing => {
  return {
    id: response.ID,
    name: response.Name,
    companyLogo: response.CompanyLogo,
    country: response.Country,
    industries: response.Industries && deserializeCommonObjectList(response.Industries),
    technology: response.Technology && deserializeCommonObject(response.Technology),
    stage: response.Stage && deserializeCommonObject(response.Stage),
    nextSteps: response.NextSteps,
    sort: response.Sort,
    priority: response.Priority,
    tier: response.Tier,
    neTicket: response.NeTicket,
    assigned: response.Assigned && deserializeUserShortResponse(response.Assigned),
    resultingScore: response.ResultingScore,
    status: response.Status,
    dateCreated: response.DateCreated,
    followUpDate: response.FollowUpDate,
  };
};

export const deserializeDealBaseListing = (response: DealBaseListingResponse[]): DealBaseListing[] => {
  return response.map(deserializeDealBaseListingItem);
};

export const deserializeExternalAccess = (response: ExternalAccessResponse): ExternallAccessCredentials => {
  return {
    dealBaseId: response.CustomerManagementID,
    url: response.URL,
    password: response.Password,
    type: response.Type,
  };
};

export const deserializeFounderResponse = (response: DealBaseFounderResponse[]): DealBaseFounder[] => {
  return response.map((founder) => ({
    id: founder.ID,
    fullname: founder.Name,
    jobTitle: founder.JobTitle,
    linkedin: founder.LinkedInURL,
  }));
};

export const deserializeDealBaseListToOptions = (response: DealBaseShortResponse[]): DealBaseOption[] => {
  return response.map((deal) => ({
    id: deal.ID,
    value: deal.ID,
    label: deal.Name,
  }));
};

export const deserializeDealBaseShort = (response: DealBaseShortResponse): DealBaseShort => {
  return {
    id: response.ID,
    name: response.Name,
  };
};

export const deserializeDealBaseShortList = (response: DealBaseShortResponse[]): DealBaseShort[] => {
  return response.map(deserializeDealBaseShort);
};

export const deserializeDealBaseInfoResponse = (response: DealBaseInfoResponse): DealBaseInfo => {
  return {
    id: response.ID,
    name: response.Name,
    companyLogo: response.CompanyLogo && deserializeAttachment(response.CompanyLogo),
    technology: response.Technology && deserializeCommonObject(response.Technology),
    industries: response.Industries && deserializeCommonObjectList(response.Industries),
    customerFocus: response.CustomerFocus && deserializeCommonObjectList(response.CustomerFocus),
    assigned: response.Assigned && deserializeUserShortResponse(response.Assigned),
    status: response.Status as DealBaseStatusType,
    lastStatus: response.LastStatus && (response.LastStatus as DealBaseStatusType),
  };
};
