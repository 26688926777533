import React from 'react';

import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch } from 'react-router-dom';

import { Listing, ListingHeader } from 'common-ui/containers';
import { portfolioApi, selectFilteredPortfolioListing, useAppSelector } from 'core/lib';
import { Routes } from 'routes/routes';
import LoaderWrapper from 'utils/loader-wrapper';

import { PortfolioListingHeaderActions, PortfolioListingHeaderNavigation } from '../components';
import PortfolioDashboard from '../dashboard';
import PortfolioListing from '../listing';

const PortfolioListingContainer = () => {
  const { t } = useTranslation();

  const { data, isFetching } = portfolioApi.useGetPortfolioListingQuery();

  const listingData = useAppSelector((state) => selectFilteredPortfolioListing(state, data));

  return (
    <Switch>
      <LoaderWrapper loading={isFetching} message={t('loaders:portfolio.loadingListing')}>
        <ListingHeader
          count={listingData?.length ?? 0}
          title={t('global:moduleNames.portfolio')}
          extra={[
            <PortfolioListingHeaderNavigation key="listingNavigation" />,
            <PortfolioListingHeaderActions key="listingActions" />,
          ]}
        />
        <Listing>
          <Switch>
            <Route
              path={Routes.PORTFOLIO_LISTING.path}
              exact
              render={() => <PortfolioListing listingData={listingData} />}
            />
            <Route
              path={Routes.PORTFOLIO_DASHBOARD.path}
              exact
              render={() => <PortfolioDashboard listingData={listingData} />}
            />
            <Redirect to={Routes.DEFAULT.path} />
          </Switch>
        </Listing>
      </LoaderWrapper>
    </Switch>
  );
};

export default PortfolioListingContainer;
