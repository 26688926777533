import React, { useContext } from 'react';

import { Form } from 'antd';
import { useTranslation } from 'react-i18next';

import { UserAvatar } from 'common-ui';
import { CommonObjectOption } from 'core/lib';
import { PortfolioDetails } from 'core/lib/modules/portfolio';

import { PortfolioDetailsContext } from '../../../context';
import { portfolioFormConfig } from '../../../form/formConfig';

const {
  generalInformation: { generalInformation },
} = portfolioFormConfig;

type GeneralInformationSectionProps = {
  editMode: boolean;
};

const PortfolioGeneralInformationSection = ({ editMode }: GeneralInformationSectionProps) => {
  const portfolio = useContext(PortfolioDetailsContext) as PortfolioDetails;
  const { t } = useTranslation('portfolio');

  return (
    <div className="flex flex-col">
      <Form.Item
        label={generalInformation.name.label}
        name={generalInformation.name.dataIndex}
        rules={generalInformation.name.rules}
      >
        <generalInformation.name.component disabled={!editMode} />
      </Form.Item>
      <Form.Item
        label={generalInformation.description.label}
        name={generalInformation.description.dataIndex}
        rules={generalInformation.description.rules}
      >
        <generalInformation.description.component disabled={!editMode} />
      </Form.Item>
      <div className="flex sm:flex-row flex-col gap-x-4">
        <Form.Item
          className="md:w-3/6"
          label={generalInformation.type.label}
          name={generalInformation.type.dataIndex}
          rules={generalInformation.type.rules}
          valuePropName="selectedOption"
          normalize={(option: CommonObjectOption) => {
            return option ? { id: option.id, name: option.label } : null;
          }}
        >
          <generalInformation.type.component disabled={!editMode} />
        </Form.Item>
        <Form.Item className="md:w-3/6" label={t('form.generalInformation.labels.createdBy')} name="createdBy">
          <div className="flex">
            <UserAvatar user={portfolio.createdBy} />
          </div>
        </Form.Item>
      </div>
    </div>
  );
};

export default PortfolioGeneralInformationSection;
