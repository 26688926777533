import { deserializeUserShortListResponse } from '../../users/services/serializers';
import {
  BaseOrganizationResponse,
  Organization,
  OrganizationDetailsResponse,
  OrganizationListing,
  OrganizationListingResponse,
  OrganizationOption,
  OrganizationShort,
  UserOrganizationResponse,
} from '../entities';

export const deserializeOrganizationToOption = (response: UserOrganizationResponse[]): OrganizationOption[] => {
  return response.map((cm) => ({
    id: cm.ID,
    value: cm.ID,
    label: cm.Name,
  }));
};

export const deserializeOrganizationListing = (response: OrganizationListingResponse[]): OrganizationListing[] => {
  return response.map((org) => ({
    id: org.ID,
    name: org.Name,
    users: deserializeUserShortListResponse(org.Users),
  }));
};

export const deserializeOrganizationDetails = (response: OrganizationDetailsResponse): Organization => {
  return {
    id: response.ID,
    name: response.Name,
    users: deserializeUserShortListResponse(response.Users),
  };
};

export const deserializeOrganizationShortResponse = (response: BaseOrganizationResponse): OrganizationShort => {
  return {
    id: response.ID,
    name: response.Name,
  };
};

export const deserializeOrganizationShortListResponse = (response: BaseOrganizationResponse[]): OrganizationShort[] => {
  return response.map(deserializeOrganizationShortResponse);
};