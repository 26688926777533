import React from 'react';

import { Select, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { organizationApi } from 'core/lib/modules/organization/services';
import { PortfolioFilters } from 'core/lib/modules/portfolio/entities/filters';
import LoaderWrapper from 'utils/loader-wrapper';

const { Title } = Typography;

type OrganizationFilterProps = {
  onChange: (filters: Partial<PortfolioFilters>) => void;
  filters: number[] | undefined;
};

const OrganizationFilter = ({ onChange, filters }: OrganizationFilterProps) => {
  const { t } = useTranslation();
  const { data: OrganizationOptions, isFetching: organizationOptionsLoading } =
    organizationApi.useGetUserOrganizationsQuery();

  return (
    <div>
      <Title level={5}>{t('portfolio:filters.organizationCount')}</Title>
      <LoaderWrapper loading={organizationOptionsLoading} message={t('loaders:filters.loadingOrganizationFilters')}>
        <Select
          mode="multiple"
          className="w-full"
          allowClear
          showSearch={false}
          options={OrganizationOptions}
          value={filters}
          placeholder={t('global:placeholders.selectType', { type: t('portfolio:filters.organization') })}
          onChange={(organization) => onChange({ organization })}
        />
      </LoaderWrapper>
    </div>
  );
};

export default OrganizationFilter;
