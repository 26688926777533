import React from 'react';

import { Form } from 'antd';
import { RcFile, UploadFile } from 'antd/lib/upload/interface';
import { useTranslation } from 'react-i18next';

import { NORDIC_ATTACHMENT_PREFIX } from 'core/lib/constants/dealbase';
import { dealBaseFormConfig } from 'modules/dealBase/form/formConfig';
import { normalizeMultipleFileUpload } from 'utils';

const {
  documents: { internalDocuments },
} = dealBaseFormConfig;

const DealBaseInternalDocumentsSection = () => {
  const { t } = useTranslation(['actions', 'dealbase']);

  return (
    <div className="flex flex-col flex-auto">
      <Form.Item
        label={t('dealbase:documents.additional')}
        rules={internalDocuments.rules}
        name={internalDocuments.dataIndex}
        normalize={({ file }: { file: UploadFile<RcFile & XMLHttpRequest> }, _, allValues) => {
          return normalizeMultipleFileUpload(
            file,
            allValues[internalDocuments.dataIndex] ?? [],
            `${NORDIC_ATTACHMENT_PREFIX}-${file.uid}`
          );
        }}
      >
        <internalDocuments.component />
      </Form.Item>
    </div>
  );
};

export default DealBaseInternalDocumentsSection;
