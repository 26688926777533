import { createApi } from '@reduxjs/toolkit/query/react';

import { dynamicBaseQuery } from 'core/lib/utils/requesting';
import { cacher } from 'core/lib/utils/rtkQueryCacheUtils';

import endpoints, { TAGS } from './endpoints';
import { ListingConfig } from '../../entities';

const listingConfigApi = createApi({
  reducerPath: 'listingConfigApi',
  baseQuery: dynamicBaseQuery,
  tagTypes: [...cacher.defaultTags, ...Object.values(TAGS)],
  endpoints: (build) => ({
    getListingConfig: build.query<ListingConfig[], number>({
      query: (objectType: number) => ({
        url: endpoints.getListingConfig.url,
        params: { objectType },
      }),
      providesTags: cacher.providesList(endpoints.getListingConfig.tag),
      transformResponse: endpoints.getListingConfig.deserializer,
    }),
    updateListingConfig: build.mutation<void, { listingConfig: ListingConfig[]; objectType: number }>({
      query: ({ objectType, listingConfig }) => ({
        method: 'POST',
        url: endpoints.updateListingConfig.url,
        body: { objectType, listingConfig },
      }),
      invalidatesTags: cacher.invalidatesList(endpoints.updateListingConfig.invalidates),
    }),
  }),
});

export default listingConfigApi;
