import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Button, Form, Typography } from 'antd';
import Table, { ColumnType } from 'antd/lib/table';
import { concat, keyBy, partition, sum, transform } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import AutoSizer from 'react-virtualized-auto-sizer';
import { useVT } from 'virtualizedtableforantd4';

import { EditableCell } from 'common-ui/cells';
import { EditableListing } from 'common-ui/cells/types';
import { mergeColumnSettings } from 'common-ui/listingConfig';
import { EmptyWrapper, PopConfirmCancelWrapper } from 'common-ui/wrappers';
import {
  AppState,
  dealBaseApi,
  DealBaseListing,
  DealBaseListingUpdate,
  ListingConfig,
  listingConfigApi,
} from 'core/lib';
import { OBJECT_TYPES } from 'core/lib/constants';
import { selectFilteredListingByStatusGroup } from 'core/lib/modules/dealBase/store';

import { columns as rawColumns } from './columns';
import ListingHeader from './listingHeader';
import ListingFilters from '../filters/filters';

const { Link } = Typography;

const ACTIONS_WIDTH = 150;

type DealBaseListingProps = {
  listingData?: DealBaseListing[];
};

const ListComponent = ({ listingData }: DealBaseListingProps) => {
  const { t } = useTranslation('dealbase');
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState<number | null>(null);
  const [formDirty, setFormDirty] = useState(false);

  const [updateListingItem, { isLoading: isSaving, isSuccess: isSavingSuccess }] =
    dealBaseApi.useUpdateListingItemMutation();

  const isEditing = useCallback((record: DealBaseListing) => record.id === editingKey, [editingKey]);

  const handleValuesChanged = () => {
    setFormDirty(true);
  };

  const edit = (record: DealBaseListingUpdate) => {
    form.setFieldsValue({ ...record });
    setEditingKey(record.id!);
  };

  const cancel = () => {
    setEditingKey(null);
    setFormDirty(false);
  };

  useEffect(() => {
    if (isSavingSuccess) {
      setEditingKey(null);
    }
  }, [isSavingSuccess]);

  const { data: listingConfig } = listingConfigApi.useGetListingConfigQuery(OBJECT_TYPES.DEAL_BASE);

  const columns = useMemo(() => {
    const orderedColumns = mergeColumnSettings(rawColumns, keyBy(listingConfig, 'dataIndex')).filter(
      (col) => col.visible
    );

    return concat(...partition(orderedColumns, { pinned: true }));
  }, [listingConfig]);

  const save = async (key: React.Key) => {
    const row: DealBaseListingUpdate = transform(await form.validateFields(), (result, value, key) => {
      result[key] = value ?? null;
    });
    row.id = editingKey!;

    updateListingItem(row);
  };

  const listingDataByGroup = useSelector((state: AppState) => selectFilteredListingByStatusGroup(state, listingData));

  const mergedColumns = useMemo(
    () =>
      [
        ...columns.map((col) => {
          if (!col.editable || !col.inputType) {
            return col;
          }

          return {
            ...col,
            fixed: col.pinned && 'left',
            onCell: (record: DealBaseListing) => ({
              record,
              inputType: col.inputType,
              inputProps: col.inputProps,
              dataIndex: col.dataIndex,
              title: col.title,
              editing: isEditing(record),
              isSaving: isSaving,
              rules: col.inputRules,
            }),
          };
        }),
        {
          title: t('global:general.actions'),
          dataIndex: 'actions',
          width: ACTIONS_WIDTH,
          render: (_: boolean, record: DealBaseListing) => {
            const editable = isEditing(record);
            return editable ? (
              <div className="flex items-center gap-2">
                <Button
                  type="link"
                  disabled={isSaving || !formDirty}
                  loading={isSaving}
                  onClick={() => save(record.id)}
                >
                  {t('actions:global.save')}
                </Button>
                {!isSaving && (
                  <PopConfirmCancelWrapper
                    title={t('actions:confirmation.confirmCancel')}
                    onConfirm={cancel}
                    disabled={!formDirty}
                  >
                    <Link>{t('actions:global.cancel')}</Link>
                  </PopConfirmCancelWrapper>
                )}
              </div>
            ) : (
              <Link disabled={editingKey !== null} onClick={() => edit(record)}>
                {t('actions:global.edit')}
              </Link>
            );
          },
        } as ColumnType<DealBaseListing>,
      ] as (ColumnType<DealBaseListing> & ListingConfig & EditableListing<DealBaseListing>)[],
    [columns, isSaving, isEditing, editingKey, t, formDirty] // eslint-disable-line  react-hooks/exhaustive-deps
  );

  const columnsWidth = useMemo(() => sum(columns.map(({ width }) => width).concat(ACTIONS_WIDTH)), [columns]);

  const [vt, set_components, tableRef] = useVT(() => ({ scroll: { x: columnsWidth, y: 500 } }), []);

  useEffect(() => {
    set_components({ body: { cell: EditableCell } });
  }, [set_components]);

  return (
    <>
      <ListingFilters />
      <EmptyWrapper description={t('global:results.emptySearch')} empty={!listingData?.length}>
        <AutoSizer disableWidth className="h-full">
          {({ height }) => (
            <Form
              onValuesChange={handleValuesChanged}
              form={form}
              component={false}
              layout="vertical"
              name="dealBaseForm"
            >
              {vt.body?.cell && (
                <Table<DealBaseListing>
                  title={() => <ListingHeader tableRef={tableRef} data={listingData ?? []} />}
                  className="h-full ant-table-title-padding"
                  rowClassName="ant-design-table-row"
                  rowKey="id"
                  columns={mergedColumns}
                  dataSource={listingDataByGroup}
                  pagination={false}
                  size="middle"
                  scroll={{ y: height - 145, scrollToFirstRowOnChange: true }}
                  components={{ ...vt }}
                />
              )}
            </Form>
          )}
        </AutoSizer>
      </EmptyWrapper>
    </>
  );
};

export default ListComponent;
