import React, { useMemo, useRef, useState } from 'react';

import { Input, List, Switch } from 'antd';
import { useTranslation } from 'react-i18next';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';

import { AppState, setLeftMenuFiltersToggled, useAppDispatch, useAppSelector } from 'core/lib';
import { selectLeftMenuFiltersToggled } from 'core/lib/modules/app';
import { portfolioApi, PortfolioMenu } from 'core/lib/modules/portfolio';
import { selectPortfolioFilteredMenu } from 'core/lib/modules/portfolio/store';
import { fuzzyWordsSearch } from 'core/lib/utils';
import { Routes } from 'routes/routes';
import { useMatchParamId } from 'utils/hooks';
import LoaderWrapper from 'utils/loader-wrapper';

import PortfolioMenuItem from './menuItem';

const PortfolioLeftMenu = () => {
  const { t } = useTranslation('portfolio');
  const [search, setSearch] = useState('');
  const searchContainerRef = useRef<HTMLDivElement>(null);

  const id = useMatchParamId(Routes.PORTFOLIO_DETAILS.path);

  const { data, isLoading } = portfolioApi.useGetPortfolioMenuQuery();

  const filteredMenuData = useAppSelector((state: AppState) => selectPortfolioFilteredMenu(state, data));
  const selectPortfolioLeftMenu = useAppSelector(selectLeftMenuFiltersToggled);

  const dispatch = useAppDispatch();
  const toggleLeftMenuFilters = (leftMenuToggled: boolean) => dispatch(setLeftMenuFiltersToggled(leftMenuToggled));

  const searchResultData: PortfolioMenu[] | undefined = useMemo(() => {
    return filteredMenuData
      ?.filter((portfolio) => fuzzyWordsSearch(search, portfolio.name))
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [search, filteredMenuData]);

  const onFiltersToggleClick = () => {
    toggleLeftMenuFilters(!selectPortfolioLeftMenu);
  };

  return (
    <LoaderWrapper loading={!data?.length && isLoading} message={t('loaders:portfolio.loadingMenu')}>
      <div ref={searchContainerRef}>
        <div className="flex flex-col p-4 gap-4">
          <div className="flex items-center justify-between gap-4">
            {t('global:filters.toggleFilters')}
            <Switch
              onClick={onFiltersToggleClick}
              size="small"
              title={t('global:filters.toggleFilters')}
              checked={selectPortfolioLeftMenu}
            />
          </div>
          <Input
            allowClear
            placeholder={t('global:placeholders.searchByName')}
            onChange={(e) => setSearch(e.target.value)}
            value={search}
          />
        </div>
      </div>
      <AutoSizer>
        {({ width, height }) => (
          <List>
            <FixedSizeList<PortfolioMenu[]>
              itemData={searchResultData}
              overscanCount={5}
              itemSize={40}
              itemCount={searchResultData?.length ?? 0}
              width={width}
              height={height - (searchContainerRef.current?.clientHeight ?? 0)}
            >
              {(props) => <PortfolioMenuItem {...props} id={id} isLoading={isLoading} />}
            </FixedSizeList>
          </List>
        )}
      </AutoSizer>
    </LoaderWrapper>
  );
};

export default PortfolioLeftMenu;
