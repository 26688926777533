import React, { useEffect, useMemo, useState } from 'react';

import { PushpinFilled, SettingOutlined } from '@ant-design/icons';
import { DragEndEvent } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { Button, List, Modal, Slider, Switch } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { keyBy } from 'lodash';
import { useTranslation } from 'react-i18next';

import { SortableItem, DnDWrapper } from 'common-ui/sortable';
import { ListingConfig, listingConfigApi } from 'core/lib';

import { mergeColumnSettings } from './utils';

type ListingSettingsProps<T> = {
  rawColumns: ColumnType<T>[];
  objectType: number;
};

function ListingSettings<T>({ objectType, rawColumns }: ListingSettingsProps<T>) {
  const [draftSettings, setDraftSettings] = useState<{ [key: string]: ListingConfig } | undefined>();
  const [modalOpen, toggleModal] = useState(false);
  const { t } = useTranslation();

  const { data: listingConfig } = listingConfigApi.endpoints.getListingConfig.useQueryState(objectType);
  const [updateListingConfig, { isLoading: isSaving, isSuccess }] = listingConfigApi.useUpdateListingConfigMutation();

  useEffect(() => {
    const indexedListingConfig = keyBy(listingConfig, 'dataIndex');
    setDraftSettings(indexedListingConfig);
  }, [listingConfig, modalOpen]);

  const columns = useMemo(() => mergeColumnSettings(rawColumns, draftSettings), [draftSettings, rawColumns]);

  const draggableItemsIds = useMemo(() => {
    return columns.map((col) => col.dataIndex);
  }, [columns]);

  const showModal = () => {
    toggleModal(true);
  };

  const onCancel = () => {
    toggleModal(false);
  };

  const onSave = () => {
    updateListingConfig({ objectType, listingConfig: columns });
  };

  useEffect(() => {
    if (isSuccess) {
      toggleModal(false);
    }
  }, [isSuccess]);

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const updatedSettings = keyBy(
        arrayMove(columns, active?.data?.current?.index, over?.data?.current?.index).map((setting, index) => ({
          ...setting,
          index,
        })),
        'dataIndex'
      );

      setDraftSettings(
        Object.entries(updatedSettings ?? {}).reduce((agg, [dataIndex, stng]) => {
          agg[dataIndex] = { ...stng, index: updatedSettings[dataIndex]?.index ?? stng.index };
          return agg;
        }, {})
      );
    }
  };

  const onWidthChange = (dataIndex: string, width: number) => {
    setDraftSettings((settings) => settings && { ...settings, [dataIndex]: { ...settings[dataIndex], width } });
  };

  const onPinnedChange = (dataIndex: string, pinned: boolean) => {
    setDraftSettings((settings) => settings && { ...settings, [dataIndex]: { ...settings[dataIndex], pinned } });
  };

  const onVisibilityChange = (dataIndex: string, visible: boolean) => {
    setDraftSettings((settings) => settings && { ...settings, [dataIndex]: { ...settings[dataIndex], visible } });
  };

  return (
    <>
      <Button type="link" className="text-black" onClick={showModal} icon={<SettingOutlined />} />
      <Modal
        width="auto"
        style={{ maxWidth: 600 }}
        bodyStyle={{ overflowY: 'scroll', height: '50vh' }}
        title={t('global:settings.columnConfiguration')}
        open={modalOpen}
        onCancel={onCancel}
        footer={
          <div>
            <Button onClick={onCancel} type="text">
              {t('actions:global.cancel')}
            </Button>
            <Button type="primary" loading={isSaving} onClick={onSave}>
              {t('actions:global.apply')}
            </Button>
          </div>
        }
      >
        <DnDWrapper onDragEnd={handleDragEnd} draggableItemsIds={draggableItemsIds}>
          <List
            className="mr3"
            itemLayout="vertical"
            dataSource={columns}
            renderItem={(item) => (
              <SortableItem handle key={item.dataIndex} data={item} id={item.dataIndex}>
                <div className="flex sm:flex-row flex-col w-full items-center justify-between">
                  <div className="md:self-center self-start">{item.title as string}</div>
                  <div className="flex gap-4 items-center md:max-w-96 w-full">
                    <div className="flex flex-auto items-center gap-4">
                      <Slider
                        className="flex-auto"
                        step={10}
                        min={50}
                        max={250}
                        onChange={(value) => onWidthChange(item.dataIndex, value)}
                        value={item.width}
                      />
                      {item.width}px
                    </div>
                    <Switch
                      size="small"
                      checked={item.visible}
                      onChange={(visible) => onVisibilityChange(item.dataIndex, visible)}
                    />
                    <PushpinFilled
                      className={`${item.pinned ? 'active-color' : 'innactive'}`}
                      style={{ cursor: 'pointer' }}
                      onClick={() => onPinnedChange(item.dataIndex, !item.pinned)}
                    />
                  </div>
                </div>
              </SortableItem>
            )}
          />
        </DnDWrapper>
      </Modal>
    </>
  );
}

export default ListingSettings;
