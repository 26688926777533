import { createApi } from '@reduxjs/toolkit/query/react';

import { dynamicBaseQuery } from 'core/lib/utils/requesting';

import endpoints, { TAGS } from './endpoints';
import { cacher } from '../../../utils/rtkQueryCacheUtils';
import { BaseModuleObject } from '../../app';
import { BaseUser } from '../../users';
import {
  MenuGroupConversations,
  ModuleConversationListing,
  MenuModuleConversations,
  ModuleConversationDetails,
  ConversationMessage,
  GetModuleConversationUsersRequest,
  CreateModuleConversationRequest,
  CreateGroupConversationRequest,
  GroupConversationDetails,
  UserToModuleConversationRequest,
  UserToGroupConversationRequest,
  ConversationMessageRequest,
  PostConversationMessageRequest,
  PostGroupConversationTitle,
  GetModuleConversationExistsRequest,
  MenuDirectConversations,
  CreateDirectConversationRequest,
  DirectConversationDetails,
  DeleteConversationMessageRequest,
} from '../entities';

const conversationsApi = createApi({
  reducerPath: 'conversationsApi',
  baseQuery: dynamicBaseQuery,
  tagTypes: [...cacher.defaultTags, ...Object.values(TAGS)],
  endpoints: (build) => ({
    getModuleConversationsListing: build.query<ModuleConversationListing[], void>({
      query: () => endpoints.moduleConversationListing.url,
      providesTags: cacher.providesList(endpoints.moduleConversationListing.tag),
      transformResponse: endpoints.moduleConversationListing.deserializer,
    }),
    getMyMenuModuleConversations: build.query<MenuModuleConversations[], void>({
      query: () => endpoints.menuModuleConversations.url,
      providesTags: cacher.providesList(endpoints.menuModuleConversations.tag),
      transformResponse: endpoints.menuModuleConversations.deserializer,
    }),
    getModuleConversation: build.query<ModuleConversationDetails, number>({
      query: (id) => ({ url: endpoints.moduleConversationDetails.url, params: { id } }),
      providesTags: cacher.cacheByIdArg(endpoints.moduleConversationDetails.tag),
      transformResponse: endpoints.moduleConversationDetails.deserializer,
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data: moduleConv } = await queryFulfilled;
          dispatch(
            conversationsApi.util.updateQueryData('getMyMenuModuleConversations', undefined, (draft) => {
              const index = draft.findIndex((d) => d.conversationId === moduleConv.conversationId);
              if (draft[index]) {
                draft[index].undreadMessages = 0;
              }
            })
          );
        } catch {}
      },
    }),
    getConversationMessages: build.query<ConversationMessage[], ConversationMessageRequest>({
      query: ({ conversationId, page }) => ({
        url: `${endpoints.conversationMessages.url}/${conversationId}`,
        params: { page },
      }),
      providesTags: cacher.providesList(endpoints.conversationMessages.tag),
      transformResponse: endpoints.conversationMessages.deserializer,
    }),
    getModuleConversationUsers: build.query<BaseUser[], GetModuleConversationUsersRequest>({
      query: (params) => ({ url: endpoints.moduleConversationUsers.url, params }),
      providesTags: cacher.providesList(endpoints.moduleConversationUsers.tag),
      transformResponse: endpoints.moduleConversationUsers.deserializer,
    }),
    getModuleConversationObjects: build.query<BaseModuleObject[], number>({
      query: (objectType) => ({ url: endpoints.moduleConversationObjects.url, params: { objectType } }),
      // providesTags: cacher.providesList(endpoints.moduleConversationObjects.tag),
      transformResponse: endpoints.moduleConversationObjects.deserializer,
    }),
    getGroupConversation: build.query<GroupConversationDetails, number>({
      query: (id) => ({ url: endpoints.groupConversationDetails.url, params: { id } }),
      providesTags: cacher.cacheByIdArg(endpoints.groupConversationDetails.tag),
      transformResponse: endpoints.groupConversationDetails.deserializer,
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data: groupConv } = await queryFulfilled;
          dispatch(
            conversationsApi.util.updateQueryData('getMyMenuGroupConversations', undefined, (draft) => {
              const index = draft.findIndex((d) => d.conversationId === groupConv.conversationId);
              if (draft[index]) {
                draft[index].undreadMessages = 0;
              }
            })
          );
        } catch {}
      },
    }),
    getDirectConversation: build.query<DirectConversationDetails, number>({
      query: (id) => ({ url: endpoints.directConversationDetails.url, params: { id } }),
      providesTags: cacher.cacheByIdArg(endpoints.directConversationDetails.tag),
      transformResponse: endpoints.directConversationDetails.deserializer,
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data: directConv } = await queryFulfilled;
          dispatch(
            conversationsApi.util.updateQueryData('getMyMenuDirectConversations', undefined, (draft) => {
              const index = draft.findIndex((d) => d.conversationId === directConv.conversationId);
              if (draft[index]) {
                draft[index].undreadMessages = 0;
              }
            })
          );
        } catch {}
      },
    }),
    getMyMenuGroupConversations: build.query<MenuGroupConversations[], void>({
      query: () => endpoints.menuGroupConversations.url,
      providesTags: cacher.providesList(endpoints.menuGroupConversations.tag),
      transformResponse: endpoints.menuGroupConversations.deserializer,
    }),
    getMyMenuDirectConversations: build.query<MenuDirectConversations[], void>({
      query: () => endpoints.menuDirectConversations.url,
      providesTags: cacher.providesList(endpoints.menuDirectConversations.tag),
      transformResponse: endpoints.menuDirectConversations.deserializer,
    }),
    leaveModuleConversation: build.mutation<void, number>({
      query: (moduleConversationId) => ({
        method: 'POST',
        url: endpoints.leaveModuleConversation.url,
        params: { moduleConversationId },
      }),
      invalidatesTags: endpoints.leaveModuleConversation.invalidates,
      async onQueryStarted(moduleConversationId, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            conversationsApi.util.invalidateTags([
              { type: endpoints.moduleConversationDetails.tag, id: moduleConversationId },
            ])
          );
        } catch {}
      },
    }),
    joinModuleConversation: build.mutation<void, number>({
      query: (moduleConversationId) => ({
        method: 'POST',
        url: endpoints.joinModuleConversation.url,
        params: { moduleConversationId },
      }),
      async onQueryStarted(moduleConversationId, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            conversationsApi.util.invalidateTags([
              ...endpoints.joinModuleConversation.invalidates,
              { type: endpoints.moduleConversationDetails.tag, id: moduleConversationId },
            ])
          );
        } catch {}
      },
    }),
    addUserToModuleConversation: build.mutation<void, UserToModuleConversationRequest>({
      query: (params) => ({
        method: 'POST',
        url: endpoints.addUserToModuleConversation.url,
        params,
      }),
      invalidatesTags: endpoints.addUserToModuleConversation.invalidates,
      async onQueryStarted({ moduleConversationId }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            conversationsApi.util.invalidateTags([
              { type: endpoints.moduleConversationDetails.tag, id: moduleConversationId },
            ])
          );
        } catch {}
      },
    }),
    removeUserFromModuleConversation: build.mutation<void, UserToModuleConversationRequest>({
      query: (params) => ({
        method: 'POST',
        url: endpoints.removeUserFromModuleConversation.url,
        params,
      }),
      invalidatesTags: endpoints.removeUserFromModuleConversation.invalidates,
      async onQueryStarted({ moduleConversationId }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            conversationsApi.util.invalidateTags([
              { type: endpoints.moduleConversationDetails.tag, id: moduleConversationId },
            ])
          );
        } catch {}
      },
    }),
    addUserToGroupConversation: build.mutation<void, UserToGroupConversationRequest>({
      query: (params) => ({
        method: 'POST',
        url: endpoints.addUserToGroupConversation.url,
        params,
      }),
      async onQueryStarted({ groupConversationId }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            conversationsApi.util.invalidateTags([
              { type: endpoints.groupConversationDetails.tag, id: groupConversationId },
            ])
          );
        } catch {}
      },
    }),
    removeUserFromGroupConversation: build.mutation<void, UserToGroupConversationRequest>({
      query: (params) => ({
        method: 'POST',
        url: endpoints.removeUserFromGroupConversation.url,
        params,
      }),
      async onQueryStarted({ groupConversationId }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            conversationsApi.util.invalidateTags([
              { type: endpoints.groupConversationDetails.tag, id: groupConversationId },
            ])
          );
        } catch {}
      },
    }),
    leaveGroupConversation: build.mutation<void, number>({
      query: (groupConversationId) => ({
        method: 'POST',
        url: endpoints.leaveGroupConversation.url,
        params: { groupConversationId },
      }),
      invalidatesTags: endpoints.leaveGroupConversation.invalidates,
    }),
    createModuleConversation: build.mutation<number, CreateModuleConversationRequest>({
      query: (moduleConv) => ({
        method: 'POST',
        url: endpoints.createModuleConversation.url,
        body: moduleConv,
      }),
      invalidatesTags: endpoints.createModuleConversation.invalidates,
    }),
    createGroupConversation: build.mutation<number, CreateGroupConversationRequest>({
      query: (groupConv) => ({
        method: 'POST',
        url: endpoints.createGroupConversation.url,
        body: groupConv,
      }),
      invalidatesTags: endpoints.createGroupConversation.invalidates,
    }),
    createDirectConversation: build.mutation<number, CreateDirectConversationRequest>({
      query: (directConv) => ({
        method: 'POST',
        url: endpoints.createDirectConversation.url,
        body: directConv,
      }),
      invalidatesTags: endpoints.createDirectConversation.invalidates,
    }),
    postConversationMessage: build.mutation<ConversationMessage, PostConversationMessageRequest>({
      query: (convMessageRequest) => ({
        method: 'POST',
        url: `${endpoints.postConversationMessage.url}/${convMessageRequest.conversationId}`,
        body: convMessageRequest,
      }),
      transformResponse: endpoints.postConversationMessage.deserializer,
    }),
    editConversationMessage: build.mutation<ConversationMessage, PostConversationMessageRequest>({
      query: (convMessageRequest) => ({
        method: 'POST',
        url: `${endpoints.editConversationMessage.url}/${convMessageRequest.id}`,
        body: convMessageRequest,
      }),
      transformResponse: endpoints.postConversationMessage.deserializer,
    }),
    deleteConversationMessage: build.mutation<ConversationMessage, DeleteConversationMessageRequest>({
      query: (convMessageRequest) => ({
        method: 'DELETE',
        url: `${endpoints.deleteConversationMessage.url}/${convMessageRequest.id}`,
        body: convMessageRequest,
      }),
      transformResponse: endpoints.postConversationMessage.deserializer,
    }),
    postRenameGroup: build.mutation<void, PostGroupConversationTitle>({
      query: (groupConvRequest) => ({
        method: 'POST',
        url: `${endpoints.postGroupConversationTitle.url}/${groupConvRequest.groupConversationId}`,
        params: groupConvRequest,
      }),

      async onQueryStarted({ title, groupConversationId }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          conversationsApi.util.updateQueryData('getGroupConversation', groupConversationId, (draft) => {
            draft.title = title;
          })
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    getModuleConversationPermission: build.query<number, number | string>({
      query: (moduleConversationId) => ({
        url: endpoints.moduleConversationPermission.url,
        params: { moduleConversationId },
      }),
      providesTags: cacher.cacheByIdArg(endpoints.moduleConversationPermission.tag),
    }),
    getGroupConversationPermission: build.query<number, number | string>({
      query: (groupConversationId) => ({
        url: endpoints.groupConversationPermission.url,
        params: { groupConversationId },
      }),
      providesTags: cacher.cacheByIdArg(endpoints.groupConversationPermission.tag),
    }),
    getDirectConversationPermission: build.query<number, number | string>({
      query: (directConversationId) => ({
        url: endpoints.directConversationPermission.url,
        params: { directConversationId },
      }),
      providesTags: cacher.cacheByIdArg(endpoints.directConversationPermission.tag),
    }),
    getConversationGlobalUnreadMessages: build.query<boolean, void>({
      query: () => endpoints.globalConversationUnreadMessages.url,
    }),
    getModuleConversationExists: build.query<boolean, GetModuleConversationExistsRequest>({
      query: (params) => ({
        url: endpoints.moduleConversationExists.url,
        params,
      }),
    }),
  }),
});

export default conversationsApi;
