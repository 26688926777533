import React, { ReactNode } from 'react';

import { List } from 'antd';
import { Location } from 'history';
import querystring from 'query-string';
import { Link } from 'react-router-dom';

import { UserNotification } from 'core/lib';
import { generateRoutePathByObjectTypeAndObjectId } from 'utils';

type NotificationCardWrapperProps = {
  children: ReactNode;
  notification: UserNotification;
  onClick: (id: number) => void;
  routePath?: string;
  queryStringParams?: string;
  hasQueryParams?: boolean;
  isLink?: boolean;
};

const NotificationCardWrapper = ({
  children,
  notification,
  routePath,
  queryStringParams,
  onClick,
  isLink = true,
  hasQueryParams = true,
}: NotificationCardWrapperProps) => {
  /**
   * if we have the objectType and objectId we can try to construct a valid app route
   * if the notification has metadata for being deleted we don't create a link
   */
  if (isLink && notification.objectType && notification.objectId && !notification.metadata.Deleted) {
    const linkProps: Partial<Location<{ id: number }>> = {};
    linkProps.pathname =
      routePath ?? generateRoutePathByObjectTypeAndObjectId(notification.objectType, notification.objectId);

    if (hasQueryParams) {
      linkProps.search =
        queryStringParams ?? querystring.stringify({ refType: notification.type, refId: notification.refId });
    }
    if (linkProps.pathname) {
      return (
        <Link to={linkProps} onClick={() => onClick(notification.id)}>
          <List.Item className={`p-4 cursor-pointer hover:bg-gray-50 ${notification.visited ? '' : 'bg-sky-50'}`}>
            {children}
          </List.Item>
        </Link>
      );
    }
  }
  return (
    <List.Item onClick={() => onClick(notification.id)} className="p-4 cursor-pointer hover:bg-gray-50">
      {children}
    </List.Item>
  );
};

export default NotificationCardWrapper;
