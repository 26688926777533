import React from 'react';

import { Input, RadioGroupProps, Select, SelectProps } from 'antd';
import { RuleObject } from 'antd/lib/form';
import { InputProps, TextAreaProps } from 'antd/lib/input';
import { Trans } from 'react-i18next';

import { NumberRangeInput, RadioGroupSelect } from 'common-ui/inputs';
import { ExternalInputLink } from 'common-ui/links';
import { CommonObject } from 'core/lib';
import { DYNAMIC, MAX_INT, OBJECT_TYPES } from 'core/lib/constants';
import { ConnectionOptions, DiligenceTypeOptions, TypeOptions } from 'core/lib/constants/selects/contactBook';
import i18n from 'core/lib/utils/i18n';
import {
  CommonObjectSelect,
  CommonObjectMultiSelect,
  CountrySelect,
  RegionSelect,
  DealBaseMultiSelect,
  UserMultiSelect,
  BaseSelect,
  ContactBookTagsSelect,
} from 'utils/form';
import { rules } from 'utils/form/rules';

const { TextArea } = Input;

export const contactBookFormConfig = {
  details: {
    contactInformation: {
      name: {
        label: <Trans i18nKey="contactBook:form.contactInformation.labels.name" />,
        dataIndex: 'name',
        rules: [rules.required, rules.maxLength(200)],
        component: Input,
      },
      region: {
        label: <Trans i18nKey="contactBook:form.contactInformation.labels.region" />,
        dataIndex: 'region',
        rules: [],
        component: RegionSelect,
      },
      country: {
        label: <Trans i18nKey="contactBook:form.contactInformation.labels.country" />,
        dataIndex: 'country',
        rules: [],
        component: CountrySelect,
      },
      city: {
        label: <Trans i18nKey="contactBook:form.contactInformation.labels.city" />,
        dataIndex: 'city',
        rules: [rules.maxLength(50)],
        component: Input,
      },
      email: {
        label: <Trans i18nKey="contactBook:form.contactInformation.labels.email" />,
        dataIndex: 'email',
        rules: [rules.required, rules.email, rules.maxLength(100)],
        component: (props: InputProps) => <Input type="email" {...props} />,
      },
      phoneNumber: {
        label: <Trans i18nKey="contactBook:form.contactInformation.labels.phoneNumber" />,
        dataIndex: 'phoneNumber',
        rules: [rules.maxLength(40)],
        component: (props: InputProps) => <Input {...props} />,
      },
      linkedInUrl: {
        label: <Trans i18nKey="contactBook:form.contactInformation.labels.linkedIn" />,
        dataIndex: 'linkedInUrl',
        rules: [rules.url, rules.maxLength(100)],
        component: (props: InputProps) => <ExternalInputLink {...props} />,
      },
    },
    professionalInformation: {
      companyName: {
        label: <Trans i18nKey="contactBook:form.professionalInformation.labels.companyName" />,
        dataIndex: 'companyName',
        rules: [rules.maxLength(200)],
        component: Input,
      },
      jobTitle: {
        label: <Trans i18nKey="contactBook:form.professionalInformation.labels.jobTitle" />,
        dataIndex: 'jobTitle',
        rules: [rules.maxLength(200)],
        component: Input,
      },
      type: {
        label: <Trans i18nKey="contactBook:form.professionalInformation.labels.type" />,
        dataIndex: 'type',
        rules: [rules.required],
        component: (props: SelectProps<string>) => <Select options={TypeOptions} {...props} />,
      },
      stage: {
        label: <Trans i18nKey="contactBook:form.professionalInformation.labels.stage" />,
        dataIndex: 'stage',
        rules: [],
        component: (props: SelectProps<number> & { selectedOption?: CommonObject }) => (
          <CommonObjectSelect
            {...props}
            archived={false}
            asTag
            color="orange"
            type={DYNAMIC.CONTACT_BOOK.STAGE}
            objectType={OBJECT_TYPES.CONTACT_BOOK}
          />
        ),
      },
      investmentFocus: {
        label: (
          <Trans
            i18nKey="contactBook:form.professionalInformation.labels.investmentFocus"
            values={{ range: i18n.t('global:labels.selectRange', { range: '1-3' }) }}
          />
        ),
        dataIndex: 'investmentFocus',
        rules: [],
        component: (props: SelectProps<number[]> & { selectedOption?: CommonObject[] }) => (
          <CommonObjectMultiSelect
            {...props}
            archived={false}
            asTags
            max={3}
            color="green"
            type={DYNAMIC.CONTACT_BOOK.INVESTMENT_FOCUS}
            objectType={OBJECT_TYPES.CONTACT_BOOK}
          />
        ),
      },
      industry: {
        label: <Trans i18nKey="contactBook:form.professionalInformation.labels.industry" />,
        dataIndex: 'industry',
        rules: [],
        component: (props: SelectProps<number[]> & { selectedOption?: CommonObject[] }) => (
          <CommonObjectMultiSelect
            {...props}
            archived={false}
            asTags
            color="green"
            type={DYNAMIC.CONTACT_BOOK.INDUSTRY}
            objectType={OBJECT_TYPES.CONTACT_BOOK}
          />
        ),
      },
      investmentTicket: {
        label: <Trans i18nKey="contactBook:form.professionalInformation.labels.investmentTicket" />,
        dataIndex: 'investmentTicket',
        rules: [rules.numberRange(MAX_INT)],
        component: NumberRangeInput,
      },
      fundSize: {
        label: <Trans i18nKey="contactBook:form.professionalInformation.labels.fundSize" />,
        dataIndex: 'fundSize',
        rules: [rules.numberRange(MAX_INT)],
        component: NumberRangeInput,
      },
      investmentGeography: {
        label: <Trans i18nKey="contactBook:form.professionalInformation.labels.investmentGeography" />,
        dataIndex: 'investmentGeography',
        rules: [],
        component: (props: SelectProps<string | string[]>) => <CountrySelect allowClear mode="multiple" {...props} />,
      },
      sharedCases: {
        label: <Trans i18nKey="contactBook:form.professionalInformation.labels.sharedCases" />,
        dataIndex: 'sharedCases',
        rules: [],
        component: DealBaseMultiSelect,
      },
      diligenceType: {
        label: <Trans i18nKey="contactBook:form.professionalInformation.labels.diligenceType" />,
        dataIndex: 'diligenceType',
        rules: [],
        component: (props: RadioGroupProps) => <RadioGroupSelect {...props} options={DiligenceTypeOptions} />,
      },
    },
    tags: {
      tags: {
        label: <Trans i18nKey="contactBook:form.tags.labels.tags" />,
        dataIndex: 'tags',
        rules: [],
        component: ContactBookTagsSelect,
      },
    },
    insight: {
      relations: {
        label: <Trans i18nKey="contactBook:form.insight.labels.relations" />,
        dataIndex: 'relations',
        rules: [],
        component: UserMultiSelect,
      },
      connection: {
        label: <Trans i18nKey="contactBook:form.insight.labels.connection" />,
        dataIndex: 'connection',
        rules: [],
        component: (props: SelectProps<string>) => (
          <BaseSelect allowClear asTag tagColor="cyan" options={ConnectionOptions} {...props} />
        ),
      },
      notes: {
        label: <Trans i18nKey="contactBook:form.insight.labels.notes" />,
        dataIndex: 'notes',
        rules: [rules.maxLength(4000)],
        component: (props: TextAreaProps) => (
          <TextArea autoSize={{ minRows: 3 }} showCount={!props.disabled} maxLength={4000} {...props} />
        ),
      },
    },
  },
};

export const contactBookFields = Object.values(contactBookFormConfig)
  .flatMap((section): { dataIndex: string; label: string }[] => {
    return Object.values(section).flatMap((sectionFields) => Object.values(sectionFields));
  })
  .reduce((fields, field) => {
    fields[field.dataIndex] = field.label;
    return fields;
  }, {});

export const mandatoryTabFields: { [key: string]: { [key: string]: string[] } } = Object.entries(
  contactBookFormConfig
).reduce((agg, [tabKey, tabObject]) => {
  const requiredSections = Object.keys(tabObject).reduce((aggSection, sectionKey) => {
    const sectionObject = tabObject[sectionKey];
    const requiredFields: string[] = [];

    Object.keys(sectionObject).forEach((oKey) => {
      const fieldObject = sectionObject[oKey];
      if (fieldObject?.rules?.some((r: RuleObject) => r.required)) {
        requiredFields.push(fieldObject.dataIndex);
      }
    });
    if (requiredFields.length) {
      aggSection[sectionKey] = requiredFields;
    }
    return aggSection;
  }, {});
  if (Object.keys(requiredSections).length) {
    agg[tabKey] = requiredSections;
  }
  return agg;
}, {});
