import React, { useContext, useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { AttachmentList } from 'common-ui';
import { DealBaseDetailsContext } from 'modules/dealBase/context';

const DealBaseInternalDocumentsSection = () => {
  const { t, i18n } = useTranslation('actions');

  const dealbase = useContext(DealBaseDetailsContext);

  const attachments = useMemo(() => {
    return dealbase?.internalAttachments?.map((att) => {
      return {
        ...att,
        title: i18n.exists(`dealbase:documents.${att.dataIndex}`)
          ? t(`dealbase:documents.${att.dataIndex}`)
          : t('dealbase:documents.additional'),
      };
    });
  }, [dealbase?.internalAttachments, i18n, t]);

  return <AttachmentList attachments={attachments} />;
};

export default DealBaseInternalDocumentsSection;
