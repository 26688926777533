import React from 'react';

import { Typography } from 'antd';
import { RangeValue } from 'rc-picker/lib/interface';
import { useTranslation } from 'react-i18next';

import DatePicker from 'common-ui/datePicker';
import { PortfolioFilters } from 'core/lib/modules/portfolio/entities/filters';

const { Title } = Typography;

type CreationDateFilterProps = {
  onChange: (filters: Partial<PortfolioFilters>) => void;
  filters?: { startDate?: string | null; endDate?: string | null };
};

const CreationDateFilter = ({ onChange, filters }: CreationDateFilterProps) => {
  const { t } = useTranslation();

  const onDateRangeChange = (_: RangeValue<Date>, [startDate, endDate]: [string, string]) => {
    onChange({ creationDate: { startDate, endDate } });
  };

  return (
    <div>
      <Title level={5}>{t('portfolio:filters.creationDate')}</Title>
      <div className="flex flex-col w-full gap-2">
        <DatePicker.RangePicker
          onChange={onDateRangeChange}
          value={[
            filters?.startDate ? new Date(filters?.startDate) : null,
            filters?.endDate ? new Date(filters?.endDate) : null,
          ]}
        />
      </div>
    </div>
  );
};

export default CreationDateFilter;
