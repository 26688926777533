import { DetailsSectionPanel, DetailsSectionPanelResponse } from '../../entities';

export const deserializeDetailsSectionHeaderItem = (response: DetailsSectionPanelResponse): DetailsSectionPanel => {
  return { id: response.ID, name: response.Name, collapsed: response.Collapsed };
};

export const deserializeDetailsSectionHeaders = (
  sectionPanels?: DetailsSectionPanelResponse[]
): DetailsSectionPanel[] => {
  return sectionPanels?.map(deserializeDetailsSectionHeaderItem) ?? [];
};
