import {
  deserializePortfolioDetails,
  deserializePortfolioListing,
  deserializePortfolioListingItem,
  deserializePortfolioMenu,
} from './serializers';
import { deserializeObjectHistory, deserializeSearchResult } from '../../app';

const API_V2 = 'api/v2';
const CONTROLLER = 'portfolio';

const API_URL = {
  GET_PORTFOLIO_DETAILS: `${API_V2}/${CONTROLLER}/getDetails`,
  GET_PORTFOLIO_SEARCH: `${API_V2}/${CONTROLLER}/getSearch`,
  UPDATE_STATUS: `${API_V2}/${CONTROLLER}/updateStatus`,
  GET_PORTFOLIO_LISTING: `${API_V2}/${CONTROLLER}/getListing`,
  GET_PORTFOLIO_MENU: `${API_V2}/${CONTROLLER}/getMenu`,
  PATCH_PORTFOLIO: `${API_V2}/${CONTROLLER}/updatePortfolio`,
  POST_NEW_PORTFOLIO: `${API_V2}/${CONTROLLER}/createPortfolio`,
  POST_PORTFOLIO_LISTING_UPDATE: `${API_V2}/${CONTROLLER}/updateListingItem`,
  GET_PORTFOLIO_HISTORY: `${API_V2}/${CONTROLLER}/getHistory`,
};

export const TAGS = {
  PORTFOLIO_DETAILS: 'PortfolioDetails',
  PORTFOLIO_LISTING: 'PortfolioListing',
  PORTFOLIO_MENU: 'PortfolioMenu',
  PORTFOLIO_OBJECT_HISTORY: 'PortfolioObjectHistory',
};

const endpoints = {
  details: {
    url: API_URL.GET_PORTFOLIO_DETAILS,
    tag: TAGS.PORTFOLIO_DETAILS,
    deserializer: deserializePortfolioDetails,
  },
  listing: {
    url: API_URL.GET_PORTFOLIO_LISTING,
    tag: TAGS.PORTFOLIO_LISTING,
    deserializer: deserializePortfolioListing,
  },
  search: {
    url: API_URL.GET_PORTFOLIO_SEARCH,
    deserializer: deserializeSearchResult,
  },
  menu: {
    url: API_URL.GET_PORTFOLIO_MENU,
    tag: TAGS.PORTFOLIO_MENU,
    deserializer: deserializePortfolioMenu,
  },
  createPortfolio: {
    url: API_URL.POST_NEW_PORTFOLIO,
    invalidates: [TAGS.PORTFOLIO_LISTING, TAGS.PORTFOLIO_MENU],
  },
  getHistory: {
    url: API_URL.GET_PORTFOLIO_HISTORY,
    tag: TAGS.PORTFOLIO_OBJECT_HISTORY,
    deserializer: deserializeObjectHistory,
  },
  updatePortfolio: {
    url: API_URL.PATCH_PORTFOLIO,
    invalidates: [TAGS.PORTFOLIO_LISTING, TAGS.PORTFOLIO_MENU],
  },
  updateListingItem: {
    url: API_URL.POST_PORTFOLIO_LISTING_UPDATE,
    deserializer: deserializePortfolioListingItem,
    invalidates: [TAGS.PORTFOLIO_MENU],
  },
  updateStatus: {
    url: API_URL.UPDATE_STATUS,
    invalidates: [TAGS.PORTFOLIO_LISTING, TAGS.PORTFOLIO_MENU],
  },
};

export default endpoints;
