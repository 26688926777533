import React from 'react';

import { DealBaseIcon, PortfolioIcon, MyConversationIcon, ContactBookIcon } from 'assets';
import { RouteTypes } from 'core/lib';
import { OBJECT_TYPES } from 'core/lib/constants';
import { ClientConfiguration } from 'core/lib/modules/auth/entities';
import i18n from 'core/lib/utils/i18n';
import { AppRoute, Routes } from 'routes/routes';

export type ModuleMenuItem = {
  [key in RouteTypes.LISTING | RouteTypes.DASHBOARD | RouteTypes.CREATE]?: { route: AppRoute; isMain: boolean };
} & {
  objectType: number;
  name: string;
  menuId: string;
  icon: React.ReactNode;
  route: AppRoute;
  isMain: boolean;
  description: string;
  externalSource?: string;
};

export const moduleConfigurations: { [key: string]: ModuleMenuItem } = {
  dealbase: {
    objectType: OBJECT_TYPES.DEAL_BASE,
    menuId: 'dealBase',
    icon: <DealBaseIcon height={100} />,
    description: i18n.t('global:moduleDescriptions.dealbase'),
    name: i18n.t('global:moduleNames.dealbase'),
    route: Routes.DEAL_BASE,
    isMain: true,
    [RouteTypes.LISTING]: {
      route: Routes.DEAL_BASE_LISTING,
      isMain: false,
    },
    [RouteTypes.DASHBOARD]: {
      route: Routes.DEAL_BASE_DASHBOARD,
      isMain: false,
    },
    [RouteTypes.CREATE]: {
      route: Routes.DEAL_BASE_NEW,
      isMain: false,
    },
  },
  myconversations: {
    objectType: OBJECT_TYPES.MY_CONVERSATIONS,
    menuId: 'myConversations',
    icon: <MyConversationIcon height={100} />,
    description: i18n.t('global:moduleDescriptions.myconversations'),
    name: i18n.t('global:moduleNames.myconversations'),
    route: Routes.CONVERSATIONS_LISTING,
    isMain: false,
  },
  portfolio: {
    objectType: OBJECT_TYPES.PORTFOLIO,
    menuId: 'portfolio',
    icon: <PortfolioIcon height={100} />,
    description: i18n.t('global:moduleDescriptions.portfolio'),
    name: i18n.t('global:moduleNames.portfolio'),
    externalSource: 'https://www.mybluelabel.com/module/inventory/',
    route: Routes.PORTFOLIO,
    isMain: true,
    [RouteTypes.LISTING]: {
      route: Routes.PORTFOLIO_LISTING,
      isMain: false,
    },
    [RouteTypes.DASHBOARD]: {
      route: Routes.PORTFOLIO_DASHBOARD,
      isMain: false,
    },
    [RouteTypes.CREATE]: {
      route: Routes.PORTFOLIO_NEW,
      isMain: false,
    },
  },
  contactBook: {
    objectType: OBJECT_TYPES.CONTACT_BOOK,
    menuId: 'contactBook',
    icon: <ContactBookIcon height={100} />,
    description: i18n.t('global:moduleDescriptions.contactBook'),
    name: i18n.t('global:moduleNames.contactbook'),
    route: Routes.CONTACT_BOOK,
    isMain: true,
    [RouteTypes.LISTING]: {
      route: Routes.CONTACT_BOOK_LISTING,
      isMain: false,
    },
    [RouteTypes.CREATE]: {
      route: Routes.CONTACT_BOOK_NEW,
      isMain: false,
    },
  },
};

export const getModuleMenuItems = (clientConfiguration: Partial<ClientConfiguration> | null) => {
  const moduleItems = [];

  if (clientConfiguration) {
    if (clientConfiguration.dealBaseEnabled) {
      moduleItems.push(moduleConfigurations.dealbase);
    }
    if (clientConfiguration.portfolioEnabled) {
      moduleItems.push(moduleConfigurations.portfolio);
    }
    if (clientConfiguration.contactBookEnabled) {
      moduleItems.push(moduleConfigurations.contactBook);
    }
  }
  moduleItems.push(moduleConfigurations.myconversations);
  return moduleItems as ModuleMenuItem[];
};
