import React from 'react';

import { useMedia } from 'react-use';

import UserFavorites from './favorites';
// import UserHistory from './history';
import { UserNotifications } from './notifications';
import UserProfileSettings from './profileSettings';
import { ModuleSearch } from '../globalSearch';

const UserTools = () => {
  const isMobile = useMedia('(max-width: 768px)');

  return (
    <div className="grid grid-flow-col justify-end items-center gap-4">
      {isMobile && <ModuleSearch />}
      {/* <UserHistory /> */}
      <UserFavorites />
      <UserNotifications />
      <UserProfileSettings />
    </div>
  );
};

export default UserTools;
