import { deserializeOrganizationShortResponse } from '../../organization/services/serializers';
import { deserializeClassification } from '../../shared/serializers';
import { deserializeCommonObject, deserializeCommonObjectList } from '../../shared/services/commonObjects';
import { deserializeUserShortResponse } from '../../users';
import {
  PortfolioDetails,
  PortfolioDetailsResponse,
  PortfolioListing,
  PortfolioListingResponse,
  PortfolioMenu,
  PortfolioMenuResponse,
} from '../entities';

export const deserializePortfolioDetails = (response: PortfolioDetailsResponse): PortfolioDetails => {
  return {
    id: response.ID,
    name: response.Name,
    description: response.Description,
    createdBy: response.CreatedBy && deserializeUserShortResponse(response.CreatedBy),
    type: response.Type && deserializeCommonObject(response.Type),
    organization: response.Organization && deserializeOrganizationShortResponse(response.Organization),
    owner: response.Owner && deserializeUserShortResponse(response.Owner),
    responsible: response.Responsible && deserializeUserShortResponse(response.Responsible),
    taskOwner: response.TaskOwner && deserializeUserShortResponse(response.TaskOwner),
    supplierCompany: response.SupplierCompany,
    systemName: response.SystemName,
    systemVersion: response.SystemVersion,
    technology: response.Technology && deserializeCommonObject(response.Technology),
    supplierType: response.SupplierType && deserializeCommonObject(response.SupplierType),
    language: response.Language && deserializeCommonObjectList(response.Language),
    complianceStatus: response.ComplianceStatus && deserializeCommonObject(response.ComplianceStatus),
    initialValidationDate: response.InitialValidationDate,
    lastPeriodReview: response.LastPeriodReview,
    frequencyPeriodicReview: response.FrequencyPeriodicReview,
    comment: response.Comment,
    businessProcesses: response.BusinessProcesses,
    dataType: response.DataType,
    businessUnitUsage: response.BusinessUnitUsage,
    users: response.Users,
    usersGeography: response.UsersGeography,
    numberOfUsers: response.NumberOfUsers && deserializeCommonObject(response.NumberOfUsers),
    licensedUsers: response.LicensedUsers,
    frequencyUsed: response.FrequencyUsed && deserializeCommonObject(response.FrequencyUsed),
    location: response.Location,
    classification: deserializeClassification(response.Classification),
    expectedLifeTime: response.ExpectedLifeTime,
    patientSafety: response.PatientSafety,
    productQuality: response.ProductQuality,
    dataIntegrity: response.DataIntegrity,
    complexity: response.Complexity,
    overallRiskPriority: response.OverallRiskPriority,
    gamp5Category: response.Gamp5Category,
    confidentiality: response.Confidentiality,
    integrity: response.Integrity,
    availability: response.Availability,
    reviewStatus: response.ReviewStatus,
    status: response.Status,
    lastStatus: response.LastStatus,
  };
};

export const deserializePortfolioListing = (response: PortfolioListingResponse[]): PortfolioListing[] => {
  return response.map(deserializePortfolioListingItem);
};

export const deserializePortfolioListingItem = (response: PortfolioListingResponse): PortfolioListing => {
  return {
    id: response.ID,
    name: response.Name,
    type: response.Type && deserializeCommonObject(response.Type),
    organization: response.Organization && deserializeOrganizationShortResponse(response.Organization),
    owner: response.Owner && deserializeUserShortResponse(response.Owner),
    responsible: response.Responsible && deserializeUserShortResponse(response.Responsible),
    taskOwner: response.TaskOwner && deserializeUserShortResponse(response.TaskOwner),
    reviewStatus: response.ReviewStatus,
    status: response.Status,
    complianceStatus: response.ComplianceStatus && deserializeCommonObject(response.ComplianceStatus),
    dateCreated: response.DateCreated,
  };
};

export const deserializePortfolioMenu = (response: PortfolioMenuResponse[]): PortfolioMenu[] => {
  return response.map(deserializePortfolioMenuItem);
};

export const deserializePortfolioMenuItem = (response: PortfolioMenuResponse): PortfolioMenu => {
  return {
    id: response.ID,
    name: response.Name,
    typeId: response.TypeID,
    organizationId: response.OrganizationID,
    ownerId: response.OwnerID,
    responsibleId: response.ResponsibleID,
    taskOwnerId: response.TaskOwnerID,
    complianceStatusId: response.ComplianceStatusID,
    reviewStatus: response.ReviewStatus,
    status: response.Status,
    dateCreated: response.DateCreated,
  };
};
