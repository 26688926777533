import React, { useContext } from 'react';

import { useTranslation } from 'react-i18next';

import { ContentContainer, MainContentContainer } from 'common-ui/containers';
import { DetailsSectionCollapse, DetailsSectionPanel } from 'common-ui/detailsSectionCollapse';
import { OBJECT_TYPES } from 'core/lib/constants';
import { DealBaseDetailsContext } from 'modules/dealBase/context';
import { tabSections } from 'modules/dealBase/form/formKeys';

import {
  ExternalDocumentsSection,
  EditExternalDocumentsSection,
  InternalDocumentsSection,
  EditInternalDocumentsSection,
} from './sections';
import { DealBaseTabProps } from '../../types';

const defaultSections = Object.values(tabSections.documents).map((section) => ({
  name: section.key,
  collapsed: true,
}));

const DealBaseDocumentsTab = ({ editMode, isNew }: DealBaseTabProps) => {
  const { t } = useTranslation('dealbase');
  const dealbase = useContext(DealBaseDetailsContext);

  return (
    <MainContentContainer id="dealbase-documents">
      <ContentContainer>
        <DetailsSectionCollapse
          defaultSections={defaultSections}
          keepDefault={isNew}
          objectType={OBJECT_TYPES.DEAL_BASE}
        >
          <DetailsSectionPanel
            header={dealbase?.name ?? t('sections.externalDocuments')}
            key={tabSections.documents.externalDocuments?.key!}
            sectionKey={tabSections.documents.externalDocuments?.key!}
            forceRender={editMode}
          >
            {editMode ? <EditExternalDocumentsSection /> : <ExternalDocumentsSection />}
          </DetailsSectionPanel>
          <DetailsSectionPanel
            header={t('sections.internalDocuments')}
            key={tabSections.documents.internalDocuments?.key!}
            sectionKey={tabSections.documents.internalDocuments?.key!}
            forceRender={editMode}
          >
            {editMode ? <EditInternalDocumentsSection /> : <InternalDocumentsSection />}
          </DetailsSectionPanel>
        </DetailsSectionCollapse>
      </ContentContainer>
    </MainContentContainer>
  );
};

export default DealBaseDocumentsTab;
