import React, { useContext, useMemo } from 'react';

import { Datum } from '@ant-design/charts';
import { Column } from '@ant-design/plots';
import { Table, Tag, Tooltip } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { keyBy, uniq } from 'lodash';
import { AlignType } from 'rc-table/lib/interface';
import { useTranslation } from 'react-i18next';
import AutoSizer from 'react-virtualized-auto-sizer';

import { FinancialYear } from 'core/lib';
import { numberSorter } from 'core/lib/utils/sorters';
import { DealBaseDetailsContext } from 'modules/dealBase/context';
import { toCurrency, toFormattedNumber } from 'utils';

const DealBaseRevenueSection = () => {
  const { t } = useTranslation('dealbase');
  const dealbase = useContext(DealBaseDetailsContext);

  const getYears = (financialYears?: FinancialYear[]) => {
    return financialYears?.filter((financialYear) => financialYear).map(({ year }) => year) ?? [];
  };

  const xAxis = useMemo(() => {
    const mergedYears = uniq(
      getYears(dealbase?.revenueYears)
        .concat(getYears(dealbase?.revenueProjections))
        .concat(getYears(dealbase?.ebitdaYears))
        .concat(getYears(dealbase?.ebitdaProjections))
    );

    return mergedYears.sort((a, b) => a - b);
  }, [dealbase?.ebitdaYears, dealbase?.revenueYears, dealbase?.revenueProjections, dealbase?.ebitdaProjections]);

  const ebitdaTableData = useMemo(() => {
    const ebitdaYears = uniq(getYears(dealbase?.ebitdaYears).concat(getYears(dealbase?.ebitdaProjections))).sort();

    const ebitdaYearsDict = keyBy(dealbase?.ebitdaYears, 'year');
    const ebitdaProjectionsDict = keyBy(dealbase?.ebitdaProjections, 'year');

    return ebitdaYears.map((year) => {
      return {
        key: year,
        year,
        value: ebitdaYearsDict[year]?.amount ?? null,
        projection: ebitdaProjectionsDict[year]?.amount ?? null,
      };
    });
  }, [dealbase?.ebitdaYears, dealbase?.ebitdaProjections]);

  const ebitdaData = useMemo(() => {
    const ebitdaYearsDict = keyBy(dealbase?.ebitdaYears, 'year');

    return xAxis
      .map((year) => {
        const financialYear = ebitdaYearsDict[year] ?? { id: `ebitda_${year}`, year, amount: null };
        return {
          key: financialYear.id,
          category: t('form.ebitda.title'),
          year: financialYear.year,
          value: financialYear.amount,
        };
      })
      .sort((a, b) => a.year - b.year);
  }, [xAxis, dealbase?.ebitdaYears, t]);

  const ebitdaProjectionsData = useMemo(() => {
    const ebitdaProjectionYearsDict = keyBy(dealbase?.ebitdaProjections, 'year');

    return xAxis
      .map((year) => {
        const financialYear = ebitdaProjectionYearsDict[year] ?? { id: `ebitda_${year}`, year, amount: null };
        return {
          key: financialYear.id,
          category: t('form.ebitda.forecast'),
          year: financialYear.year,
          value: financialYear.amount,
        };
      })
      .sort((a, b) => a.year - b.year);
  }, [xAxis, dealbase?.ebitdaProjections, t]);

  const revenueTableData = useMemo(() => {
    const revenueYears = uniq(getYears(dealbase?.revenueYears).concat(getYears(dealbase?.revenueProjections))).sort();

    const revenueYearsDict = keyBy(dealbase?.revenueYears, 'year');
    const revenueProjectionsDict = keyBy(dealbase?.revenueProjections, 'year');

    return revenueYears.map((year) => {
      return {
        key: year,
        year,
        value: revenueYearsDict[year]?.amount ?? null,
        projection: revenueProjectionsDict[year]?.amount ?? null,
      };
    });
  }, [dealbase?.revenueYears, dealbase?.revenueProjections]);

  const revenueData = useMemo(() => {
    const revenueYearsDict = keyBy(dealbase?.revenueYears, 'year');

    return xAxis
      .map((year) => {
        const financialYear = revenueYearsDict[year] ?? { id: `revenue_${year}`, year, amount: null };
        return {
          key: financialYear.id,
          category: t('form.revenue.title'),
          year: financialYear.year,
          value: financialYear.amount,
        };
      })
      .sort((a, b) => a.year - b.year);
  }, [xAxis, dealbase?.revenueYears, t]);

  const revenueProjectionsData = useMemo(() => {
    const ebitdaProjectionYearsDict = keyBy(dealbase?.revenueProjections, 'year');

    return xAxis
      .map((year) => {
        const financialYear = ebitdaProjectionYearsDict[year] ?? { id: `ebitda_${year}`, year, amount: null };
        return {
          key: financialYear.id,
          category: t('form.revenue.forecast'),
          year: financialYear.year,
          value: financialYear.amount,
        };
      })
      .sort((a, b) => a.year - b.year);
  }, [xAxis, dealbase?.revenueProjections, t]);

  const columns: ColumnType<{ year: number; value: number; projection: number }>[] = [
    {
      title: t('global:period.year'),
      dataIndex: 'year',
      sorter: (a, b, order) => numberSorter(a.year, b.year, order),
      width: '20%',
      ellipsis: true,
      render: (year: number) => {
        return (
          year && (
            <Tooltip placement="topLeft" title={year}>
              <span className="truncate">{year}</span>
            </Tooltip>
          )
        );
      },
    },
    {
      title: t('form.metrics.headers.actuals'),
      dataIndex: 'value',
      sorter: (a, b, order) => numberSorter(a.value, b.value, order),
      width: '40%',
      ellipsis: true,
      align: 'right' as AlignType,
      render: (value: number) => {
        return (
          value && (
            <Tooltip placement="topLeft" title={toCurrency(value, dealbase!.currency)}>
              <span className="truncate">{toCurrency(value, dealbase!.currency)}</span>
            </Tooltip>
          )
        );
      },
    },
    {
      title: t('form.metrics.headers.forecast'),
      dataIndex: 'projection',
      sorter: (a, b, order) => numberSorter(a.projection, b.projection, order),
      width: '40%',
      ellipsis: true,
      align: 'right' as AlignType,
      render: (projection: number) => {
        return (
          projection && (
            <Tooltip placement="topLeft" title={toCurrency(projection, dealbase!.currency)}>
              <span className="truncate">{toCurrency(projection, dealbase!.currency)}</span>
            </Tooltip>
          )
        );
      },
    },
  ];

  const config = {
    formatter: (datum: Datum) => {
      return {
        name: datum.category,
        value: toCurrency(datum.value, dealbase!.currency),
      };
    },
  } as any;

  return (
    <div className="flex flex-col w-full gap-8">
      <div className="flex sm:flex-row flex-col flex-auto gap-4">
        <Table
          className="md:w-3/6 w-full"
          pagination={false}
          size="small"
          columns={columns}
          dataSource={revenueTableData}
          title={() => (
            <div className="flex flex-row gap-2">
              {t('form.revenue.title')}
              {dealbase?.revenueType && <Tag color="blue">{dealbase?.revenueType?.name}</Tag>}
            </div>
          )}
        />
        <Table
          className="md:w-3/6 w-full"
          pagination={false}
          size="small"
          columns={columns}
          dataSource={ebitdaTableData}
          title={() => t('form.ebitda.title')}
        />
      </div>
      <div className="flex-1">
        <AutoSizer disableHeight>
          {({ width }) => {
            return (
              <div style={{ width }}>
                <Column
                  isGroup={true}
                  width={width}
                  xField="year"
                  yAxis={{
                    label: {
                      formatter: toFormattedNumber,
                    },
                  }}
                  {...config}
                  yField="value"
                  seriesField="category"
                  data={[...revenueData, ...ebitdaData, ...revenueProjectionsData, ...ebitdaProjectionsData]}
                  legend={{
                    selected: {
                      [t('form.revenue.title')]: true,
                      [t('form.ebitda.title')]: true,
                      [t('form.revenue.forecast')]: false,
                      [t('form.ebitda.forecast')]: false,
                    },
                  }}
                />
              </div>
            );
          }}
        </AutoSizer>
      </div>
    </div>
  );
};

export default DealBaseRevenueSection;
