import React, { useMemo } from 'react';

import { Tabs } from 'antd';
import { groupBy } from 'lodash';
import { connect, ConnectedProps } from 'react-redux';

import { TextBadge } from 'common-ui';
import { AppState } from 'core/lib';
import { USER_STATUSLIST } from 'core/lib/constants/statuses';
import { UserListing } from 'core/lib/modules/users/entities';
import { selectUserListingGrouping, setUserListingGrouping } from 'core/lib/modules/users/store';

type ListingHeaderProps = {
  data: UserListing[];
};

const mapState = (state: AppState, ownProps: ListingHeaderProps) => ({
  listingGrouping: selectUserListingGrouping(state),
});

const mapDispatch = {
  setUserListingGrouping,
};

const connector = connect(mapState, mapDispatch, (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
}));

type ListingHeaderConnectedProps = ConnectedProps<typeof connector>;

const ListingHeader = ({ data, listingGrouping, setUserListingGrouping }: ListingHeaderConnectedProps) => {
  const groupings = useMemo(() => {
    const groupedData = groupBy(data, 'status');
    return Object.entries(groupedData).reduce((agg, [status, list]) => {
      agg[status] = list.length;
      return agg;
    }, {});
  }, [data]);

  const onGroupingClick = (status: string) => {
    setUserListingGrouping(status);
  };

  return (
    <div>
      <Tabs
        className="tabs-no-margin"
        onChange={onGroupingClick}
        activeKey={listingGrouping}
        items={USER_STATUSLIST.map(({ status, name }) => ({
          key: status.toString(),
          label: <TextBadge text={name} count={groupings[status]} active={listingGrouping === status.toString()} />,
        }))}
      />
    </div>
  );
};

export default connector(ListingHeader);
