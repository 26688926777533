import { OBJECT_TYPES } from 'core/lib/constants';
import { RouteTypes } from 'core/lib/modules/app/entities';

export type AppRoute = {
  path: string;
  name: string;
  type: RouteTypes;
  objectType: number | null;
};

export type IndexedRoutes = {
  [key: string]: AppRoute;
};

export const Routes: IndexedRoutes = {
  DEFAULT: { path: '/', name: 'default', type: RouteTypes.APP, objectType: null },
  LOGIN: { path: '/login', name: 'Login', type: RouteTypes.APP, objectType: null },
  PAGE_404: { path: '/not-found', name: 'Page not found', type: RouteTypes.APP, objectType: null },
  CONTACT_BOOK: {
    path: '/contacts',
    name: 'Contact book',
    type: RouteTypes.APP,
    objectType: OBJECT_TYPES.CONTACT_BOOK,
  },
  CONTACT_BOOK_LISTING: {
    path: '/contacts/listing',
    name: 'Contact book listing',
    type: RouteTypes.LISTING,
    objectType: OBJECT_TYPES.CONTACT_BOOK,
  },
  CONTACT_BOOK_DETAILS: {
    path: '/contacts/details/:id?',
    name: 'Contact book details',
    type: RouteTypes.DETAILS,
    objectType: OBJECT_TYPES.CONTACT_BOOK,
  },
  CONTACT_BOOK_NEW: {
    path: '/contacts/new',
    name: 'Contact book new',
    type: RouteTypes.CREATE,
    objectType: OBJECT_TYPES.CONTACT_BOOK,
  },
  CUSTOM_FIELDS: {
    path: '/custom-fields',
    name: 'Custom Fields',
    type: RouteTypes.APP,
    objectType: null,
  },
  DEAL_BASE: { path: '/deals', name: 'Deal Base', type: RouteTypes.MODULE, objectType: OBJECT_TYPES.DEAL_BASE },
  DEAL_BASE_LISTING: {
    path: '/deals/listing',
    name: 'Deal Base Listing',
    type: RouteTypes.LISTING,
    objectType: OBJECT_TYPES.DEAL_BASE,
  },
  DEAL_BASE_DASHBOARD: {
    path: '/deals/dashboard',
    name: 'Deal Base Dashboard',
    type: RouteTypes.DASHBOARD,
    objectType: OBJECT_TYPES.DEAL_BASE,
  },
  DEAL_BASE_DETAILS: {
    path: '/deals/details/:id?',
    name: 'Deal Base Details',
    type: RouteTypes.DETAILS,
    objectType: OBJECT_TYPES.DEAL_BASE,
  },
  DEAL_BASE_NEW: {
    path: '/deals/new',
    name: 'Deal Base New',
    type: RouteTypes.CREATE,
    objectType: OBJECT_TYPES.DEAL_BASE,
  },
  EXTERNAL_OLD: {
    path: '/external-login*',
    name: 'External Old Form',
    type: RouteTypes.MODULE,
    objectType: null,
  },
  EXTERNAL_FORM: {
    path: '/external',
    name: 'External Form',
    type: RouteTypes.MODULE,
    objectType: null,
  },
  EXTERNAL_FORM_OLD: {
    path: '/external-create',
    name: 'External Old Form',
    type: RouteTypes.MODULE,
    objectType: null,
  },
  EXTERNAL_CREATE: {
    path: '/external/create',
    name: 'External Create Form',
    type: RouteTypes.CREATE,
    objectType: null,
  },
  EXTERNAL_LOGIN: {
    path: '/external/*/:url?/:dealId?/:type?',
    name: 'External Login',
    type: RouteTypes.MODULE,
    objectType: null,
  },
  EXTERNAL_CASEOWNER: {
    path: '/external/update/:url?/:dealId?/:type?',
    name: 'External Update Form',
    type: RouteTypes.CREATE,
    objectType: null,
  },
  EXTERNAL_THIRDPARTY: {
    path: '/external/comments/:url?/:dealId?/:type?',
    name: 'External Comments Form',
    type: RouteTypes.DETAILS,
    objectType: null,
  },
  USER: {
    path: '/users',
    name: 'Users',
    type: RouteTypes.MODULE,
    objectType: OBJECT_TYPES.USER,
  },
  USER_PROFILE: {
    path: '/profile',
    name: 'User Profile',
    type: RouteTypes.MODULE,
    objectType: OBJECT_TYPES.USER,
  },
  USER_LISTING: {
    path: '/users/listing',
    name: 'Users Listing',
    type: RouteTypes.LISTING,
    objectType: OBJECT_TYPES.USER,
  },
  USER_DETAILS: {
    path: '/users/details/:id',
    name: 'Users Details',
    type: RouteTypes.DETAILS,
    objectType: OBJECT_TYPES.USER,
  },
  USER_NEW: {
    path: '/users/new',
    name: 'User New',
    type: RouteTypes.CREATE,
    objectType: OBJECT_TYPES.USER,
  },
  ORGANIZATION: {
    path: '/organizations',
    name: 'Organizations',
    type: RouteTypes.MODULE,
    objectType: OBJECT_TYPES.ORGANIZATION,
  },
  ORGANIZATION_LISTING: {
    path: '/organizations/listing',
    name: 'Organizations Listing',
    type: RouteTypes.LISTING,
    objectType: OBJECT_TYPES.ORGANIZATION,
  },
  CONVERSATIONS: {
    path: '/conversations',
    name: 'Conversations',
    type: RouteTypes.MODULE,
    objectType: OBJECT_TYPES.MY_CONVERSATIONS,
  },
  CONVERSATIONS_LISTING: {
    path: '/conversations/browse',
    name: 'Conversations',
    type: RouteTypes.LISTING,
    objectType: OBJECT_TYPES.MY_CONVERSATIONS,
  },
  CONVERSATIONS_MODULE_DETAILS: {
    path: '/conversations/module/:id?',
    name: 'Conversations Details',
    type: RouteTypes.DETAILS,
    objectType: OBJECT_TYPES.MY_CONVERSATIONS,
  },
  CONVERSATIONS_GROUP_DETAILS: {
    path: '/conversations/group/:id?',
    name: 'Conversation Group Details',
    type: RouteTypes.DETAILS,
    objectType: OBJECT_TYPES.MY_CONVERSATIONS,
  },
  CONVERSATIONS_DM_DETAILS: {
    path: '/conversations/dm/:id?',
    name: 'Conversations Details',
    type: RouteTypes.DETAILS,
    objectType: OBJECT_TYPES.MY_CONVERSATIONS,
  },
  PORTFOLIO: {
    path: '/portfolios',
    name: 'Portfolios',
    type: RouteTypes.MODULE,
    objectType: OBJECT_TYPES.PORTFOLIO,
  },
  PORTFOLIO_DETAILS: {
    path: '/portfolios/details/:id?',
    name: 'Portfolio Details',
    type: RouteTypes.DETAILS,
    objectType: OBJECT_TYPES.PORTFOLIO,
  },
  PORTFOLIO_NEW: {
    path: '/portfolios/new',
    name: 'Portfolio Create',
    type: RouteTypes.CREATE,
    objectType: OBJECT_TYPES.PORTFOLIO,
  },
  PORTFOLIO_LISTING: {
    path: '/portfolios/listing',
    name: 'Portfolios Listing',
    type: RouteTypes.LISTING,
    objectType: OBJECT_TYPES.PORTFOLIO,
  },
  PORTFOLIO_DASHBOARD: {
    path: '/portfolios/dashboard',
    name: 'Portfolios Dashboard',
    type: RouteTypes.DASHBOARD,
    objectType: OBJECT_TYPES.PORTFOLIO,
  },
  WELCOME: {
    path: '/welcome',
    name: 'Welcome',
    type: RouteTypes.MODULE,
    objectType: null,
  },
};
