import { isNil } from 'lodash';

import { INDEXED_SYSTEM_ROLES, UserSystemRoleId } from 'core/lib/constants';
import colors from 'core/lib/theme/colors';
import { getRolesByRoleId } from 'core/lib/utils/permissionControl/utils';

import { deserializeAttachment } from '../../app/services/serializers';
import { deserializeOrganizationShortListResponse } from '../../organization/services/serializers';
import {
  BaseUser,
  UserOption,
  UserProfileDetails,
  UserProfileDetailsResponse,
  UserShortProfile,
  UserShortProfileResponse,
  UserShortResponse,
  UserListingResponse,
  UserListing,
  UserMenu,
  UserMenuResponse,
  UserDetailsResponse,
  UserDetails,
  OutOfOfficeResponse,
  OutOfOffice,
  UserRole,
  UserPublicProfileResponse,
  UserPublicProfile,
} from '../entities';

const getRolesObjectByRoleId = (userRoleId: number) => {
  const roles: UserRole[] = [];
  Object.entries(INDEXED_SYSTEM_ROLES).forEach(([systemRoleId, roleName]) => {
    if ((parseInt(systemRoleId) & userRoleId) === parseInt(systemRoleId)) {
      roles.push({ id: parseInt(systemRoleId), name: roleName });
    }
  });
  return roles;
};

const getUserColor = (name: string, active?: boolean) => {
  if (!isNil(active) && !active) {
    return colors.Grey400.rgba;
  }
  return colors.fromString(name);
};

export const deserializeUserListingItem = (response: UserListingResponse): UserListing => {
  const name = `${response.FirstName} ${response.LastName}`;
  return {
    id: response.ID,
    firstName: response.FirstName,
    lastName: response.LastName,
    name,
    color: getUserColor(name, response.Status === 'active'),
    email: response.Email,
    status: response.Status,
    systemRoleId: response.SystemRoleId as UserSystemRoleId,
    systemRoles: getRolesObjectByRoleId(response.SystemRoleId),
    country: response.Country,
    jobTitle: response.JobTitle,
    phone: response.Phone,
    avatarId: response.AvatarID,
    organizations: deserializeOrganizationShortListResponse(response.Organizations),
    locked: response.Locked,
  };
};

export const deserializeUserListing = (response: UserListingResponse[]): UserListing[] => {
  return response.map(deserializeUserListingItem);
};

const deserializeOutOfOfficeResponse = (response: OutOfOfficeResponse): OutOfOffice => {
  return {
    from: response.From,
    to: response.To,
  };
};

export const deserializeUserDetailsResponse = (response: UserDetailsResponse): UserDetails => {
  const name = `${response.FirstName} ${response.LastName}`;

  return {
    id: response.ID,
    email: response.Email,
    avatarId: response.AvatarID,
    color: getUserColor(name, response.Status === 'active'),
    systemRoleId: response.SystemRoleId,
    status: response.Status,
    locked: response.Locked,
    firstName: response.FirstName,
    lastName: response.LastName,
    name,
    phone: response.Phone,
    systemRoles: getRolesObjectByRoleId(response.SystemRoleId),
    organizations: deserializeOrganizationShortListResponse(response.Organizations),
    delegate: response.Delegate && deserializeUserShortResponse(response.Delegate),
    country: response.Country,
    outOfOffice: deserializeOutOfOfficeResponse(response.OutOfOffice),
    jobTitle: response.JobTitle,
    documentTemplateDocument:
      response.DocumentTemplateDocument && deserializeAttachment(response.DocumentTemplateDocument),
    accessFormDocument: response.AccessFormDocument && deserializeAttachment(response.AccessFormDocument),
    delegationFormDocument: response.DelegationFormDocument && deserializeAttachment(response.DelegationFormDocument),
    trainingPlanDocument: response.TrainingPlanDocument && deserializeAttachment(response.TrainingPlanDocument),
  };
};

export const deserializeUserMenu = (response: UserMenuResponse[]): UserMenu[] => {
  return response.map((res) => {
    return {
      id: res.ID,
      firstName: res.FirstName,
      lastName: res.LastName,
      name: `${res.FirstName} ${res.LastName}`,
      status: res.Status,
      organizationIds: res.OrganizationIds,
      systemRoleId: res.SystemRoleId,
      systemRoles: getRolesObjectByRoleId(res.SystemRoleId),
    };
  });
};

export const deserializeToUserOption = (response: UserShortResponse[]): UserOption[] => {
  return response.map((cm) => ({
    id: cm.ID,
    value: cm.ID,
    label: `${cm.FirstName} ${cm.LastName}`,
    disabled: false,
  }));
};

export const deserializeUserShortResponse = (response: UserShortResponse): BaseUser => {
  const name = `${response.FirstName} ${response.LastName}`;

  return {
    id: response.ID,
    firstName: response.FirstName,
    lastName: response.LastName,
    name,
    color: getUserColor(name, response.Active),
    avatarId: response.AvatarID,
    active: response.Active,
  };
};

export const deserializeUserShortListResponse = (response: UserShortResponse[]): BaseUser[] => {
  return response.map(deserializeUserShortResponse);
};

export const deserializeUserProfileDetails = (response: UserProfileDetailsResponse): UserProfileDetails => {
  return {
    id: response.ID,
    firstName: response.FirstName,
    lastName: response.LastName,
    email: response.Email,
    number: response.Number,
    country: response.Country,
    avatar: response.Avatar && deserializeAttachment(response.Avatar),
  };
};

export const deserializeUserShortProfile = (response: UserShortProfileResponse): UserShortProfile => {
  return {
    id: response.ID,
    email: response.Email,
    firstName: response.FirstName,
    lastName: response.LastName,
    name: `${response.FirstName} ${response.LastName}`,
    avatarId: response.AvatarID,
    roles: getRolesByRoleId(response.SystemRoleID),
    jobTitle: response.JobTitle,
  };
};

export const deserializeUserPublicProfile = (response: UserPublicProfileResponse): UserPublicProfile => {
  return {
    id: response.ID,
    email: response.Email,
    firstName: response.FirstName,
    lastName: response.LastName,
    name: `${response.FirstName} ${response.LastName}`,
    avatarId: response.AvatarID,
    jobTitle: response.JobTitle,
    country: response.Country,
    phone: response.Phone,
  };
};
