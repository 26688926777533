import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Editor, JSONContent } from '@tiptap/react';
import { Alert, Button, Form } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';

import { MainContentContainer, ContentContainer } from 'common-ui/containers';
import { conversationsApi, useAppSelector, selectWebSocketStatus, resetMessagesState, useAppDispatch } from 'core/lib';
import { MODULE_NAMES } from 'core/lib/constants';
import { HubConnectionState } from 'core/lib/utils/signalR/utils';
import { useHub } from 'utils/hooks/hubHooks';
import LoaderWrapper from 'utils/loader-wrapper';

import {
  ConversationsWysiwygEditor,
  MessagesListing,
  ModuleCommentViewWrapper,
  ModuleConversationDetailsHeader,
} from '../components';

type ModuleConversationDetailsProps = {
  conversationId: number;
};

const ModuleConversationDetails = ({ conversationId }: ModuleConversationDetailsProps) => {
  const { t } = useTranslation();
  const [editor, setEditor] = useState<Editor | null>(null);
  const [form] = useForm();
  const infiniteScrollRef = useRef<HTMLDivElement>(null);
  const [hub] = useHub();

  const webSocketStatus = useAppSelector(selectWebSocketStatus);

  const {
    data: conversationDetails,
    isLoading: loadingConversationDetails,
    isSuccess: conversationLoaded,
  } = conversationsApi.useGetModuleConversationQuery(conversationId, {
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true,
  });

  const dispatch = useAppDispatch();

  const [postMessage] = conversationsApi.usePostConversationMessageMutation();

  useEffect(() => {
    if (conversationLoaded && webSocketStatus === HubConnectionState.Connected) {
      hub.conversations.joinConversationRoom(conversationId);
    }
    return () => {
      hub.conversations.leaveConversationRoom();
    };
  }, [hub.conversations, webSocketStatus, conversationId, conversationLoaded]);

  useEffect(() => {
    if (conversationId) {
      dispatch(resetMessagesState(conversationId));
    }
  }, [conversationId]); //eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = (draftMessage: { content: JSONContent }) => {
    if (conversationDetails && editor!.getText()) {
      postMessage({
        conversationId,
        content: JSON.stringify(draftMessage.content),
        text: editor!.getText(),
      })
        .unwrap()
        .then(() => {
          infiniteScrollRef.current?.scrollIntoView(false);
        });
      editor?.commands.clearContent();
      editor?.commands.clearNodes();
    }
  };

  const conversationTitle = useMemo(() => {
    if (conversationDetails) {
      return conversationDetails?.objectTitle ?? MODULE_NAMES[conversationDetails.objectType];
    }
  }, [conversationDetails]);

  return (
    <MainContentContainer id="conversation-details">
      <ContentContainer>
        <LoaderWrapper
          loading={loadingConversationDetails}
          message={t('loaders:myConversations.loadingConversationDetails')}
        >
          <div className="flex flex-col gap-2 h-full">
            {conversationDetails && (
              <ModuleConversationDetailsHeader withDivider conversationDetails={conversationDetails} />
            )}
            <MessagesListing
              infiniteScrollRef={infiniteScrollRef}
              conversationId={conversationId}
              conversationTitle={conversationTitle}
              conversationParticipants={conversationDetails?.participants ?? []}
              totalMessages={conversationDetails?.totalMessages}
              loaderMessage={t('loaders:myConversations.loadingConversationMessages')}
            />
            {webSocketStatus === HubConnectionState.Disconnected && (
              <Alert
                message={t('global:placeholders.connectionLost')}
                showIcon
                type="error"
                action={
                  <Button
                    danger
                    type="primary"
                    disabled={
                      webSocketStatus === HubConnectionState.Connecting ||
                      webSocketStatus === HubConnectionState.Reconnecting
                    }
                    loading={
                      webSocketStatus === HubConnectionState.Connecting ||
                      webSocketStatus === HubConnectionState.Reconnecting
                    }
                    onClick={hub.conversations.disconnectAndRestart}
                  >
                    {t('actions:global.reconnect')}
                  </Button>
                }
              />
            )}
            {conversationDetails && (
              <ModuleCommentViewWrapper conversationDetails={conversationDetails}>
                <Form onFinish={handleSubmit} initialValues={{ content: '' }} form={form} component={false}>
                  <Form.Item name="content" className="m-0">
                    <ConversationsWysiwygEditor
                      onSubmit={form.submit}
                      setEditor={setEditor}
                      conversationParticipants={conversationDetails.participants}
                      placeholder={t('conversations:placeholders.messageInConversation', {
                        conversation: conversationDetails.objectTitle,
                      })}
                    />
                  </Form.Item>
                </Form>
              </ModuleCommentViewWrapper>
            )}
          </div>
        </LoaderWrapper>
      </ContentContainer>
    </MainContentContainer>
  );
};

export default ModuleConversationDetails;
