import React from 'react';

import { Popover, Tag } from 'antd';

import { TagsGroup } from 'common-ui/tags';
import { contactBookApi, ContactBookDetails, ContactBookListing } from 'core/lib';
import LoaderWrapper from 'utils/loader-wrapper';

import {
  PopoverFieldLabel,
  PopoverBaseContactBookInfoComponent,
  PopoverContactButton,
  PopoverContactBookType,
} from './components';
import ContactBookDetailsPageHeader from '../detailsPageHeader';

type IndustryExpertPopoverProps = {
  contactBook: ContactBookListing;
  children: React.ReactNode;
};

const IndustryExpertPopover = ({ contactBook, children }: IndustryExpertPopoverProps) => {
  const [getIndustryExpertInfo, { data: industryExpertInfo, isFetching }] =
    contactBookApi.useLazyGetIndustryExpertInfoQuery();

  const onContactBaseHover = (open: boolean) => {
    if (open) {
      getIndustryExpertInfo(contactBook.id.toString(), true);
    }
  };

  return (
    <Popover
      arrowPointAtCenter
      onOpenChange={onContactBaseHover}
      overlayClassName="w-auto max-w-80"
      content={
        <LoaderWrapper loading={isFetching}>
          {industryExpertInfo && (
            <div className="flex flex-col px-1 gap-4">
              <PopoverContactBookType type={industryExpertInfo.type} />
              {industryExpertInfo.industry?.length ? (
                <div className="flex flex-col gap-2">
                  <PopoverFieldLabel i18nKey="contactBook:shortInfo.labels.industry" />
                  <TagsGroup>
                    {industryExpertInfo.industry.map((industry) => (
                      <Tag key={industry.id} color="green">
                        {industry.name}
                      </Tag>
                    ))}
                  </TagsGroup>
                </div>
              ) : null}
              <PopoverBaseContactBookInfoComponent
                phoneNumber={industryExpertInfo.phoneNumber}
                email={industryExpertInfo.email}
              />
              <PopoverContactButton id={industryExpertInfo.id} email={industryExpertInfo.email} />
            </div>
          )}
        </LoaderWrapper>
      }
      title={
        <div className="px-1 py-3">
          {industryExpertInfo && (
            <ContactBookDetailsPageHeader
              defaultSize="small"
              className="p-0"
              contactBook={industryExpertInfo as ContactBookDetails}
            />
          )}
        </div>
      }
      trigger="hover"
    >
      {children}
    </Popover>
  );
};

export default IndustryExpertPopover;
