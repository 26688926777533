import React from 'react';

import { PageHeader, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { OrganizationListingHeaderActions } from '.';

const { Title } = Typography;

type ListingHeaderProps = {
  count: number;
};

const ListingHeader = ({ count }: ListingHeaderProps) => {
  const { t } = useTranslation();

  return (
    <PageHeader
      className="listing-header"
      title={
        <div className="flex flex-row gap-4 items-baseline">
          <Title style={{ margin: 0 }} level={4}>
            {t('global:moduleNames.organization')}
          </Title>
          <Title style={{ margin: 0 }} type="secondary" level={5}>
            {count}
          </Title>
        </div>
      }
      extra={[<OrganizationListingHeaderActions key="listingActions" />]}
    />
  );
};

export default ListingHeader;
