import {
  deserializeContactBookDetails,
  deserializeContactBookDiligencePartnerInfo,
  deserializeContactBookIndustryExpertInfo,
  deserializeContactBookListing,
  deserializeContactBookListingItem,
  deserializeContactBookMenu,
  deserializeContactBookVentureCapitalInfo,
} from './serializers';
import { deserializeObjectHistory, deserializeSearchResult, deserializeUserCommentList } from '../../app';

const API_V2 = 'api/v2';

const CONTROLLER = 'contactBook';

const API_URL = {
  GET_CONTACT_BOOK_LISTING: `${API_V2}/${CONTROLLER}/getListing`,
  GET_CONTACT_BOOK_DETAILS: `${API_V2}/${CONTROLLER}/getDetails`,
  GET_CONTACT_BOOK_DILIGENCE_PARTNER_INFO: `${API_V2}/${CONTROLLER}/getDiligencePartnerInfo`,
  GET_CONTACT_BOOK_VENTURE_CAPITAL_INFO: `${API_V2}/${CONTROLLER}/getVentureCapitalInfo`,
  GET_CONTACT_BOOK_INDUSTRY_EXPERT_INFO: `${API_V2}/${CONTROLLER}/getIndustryExpertInfo`,
  GET_CONTACT_BOOK_SEARCH: `${API_V2}/${CONTROLLER}/getSearch`,
  GET_CONTACT_BOOK_MENU: `${API_V2}/${CONTROLLER}/getMenu`,
  GET_CONTACT_BOOK_COMMENTS: `${API_V2}/${CONTROLLER}/getComments`,
  GET_CONTACT_BOOK_COMMENTS_COUNT: `${API_V2}/${CONTROLLER}/getCommentsCount`,
  GET_CONTACT_BOOK_HISTORY: `${API_V2}/${CONTROLLER}/getHistory`,
  GET_CONTACT_BOOK_TAGS: `${API_V2}/${CONTROLLER}/getTags`,

  POST_NEW_CONTACT_BOOK: `${API_V2}/${CONTROLLER}/create`,
  PATCH_CONTACT_BOOK: `${API_V2}/${CONTROLLER}/update`,
  POST_CONTACT_BOOK_LISTING_UPDATE: `${API_V2}/${CONTROLLER}/updateListingItem`,
  POST_CONTACT_BOOK_COMMENT: `${API_V2}/${CONTROLLER}/postComment`,
  DELETE_CONTACT_BOOK_COMMENT: `${API_V2}/${CONTROLLER}/deleteComment`,

  DELETE_CONTACT_BOOK: `${API_V2}/${CONTROLLER}/delete`,
};

export const TAGS = {
  CONTACT_BOOK_LISTING: 'ContactBookListing',
  CONTACT_BOOK_MENU: 'ContactBookMenu',
  CONTACT_BOOK_DETAILS: 'ContactBook',
  CONTACT_BOOK_DILIGENCE_PARTNER_INFO: 'ContactBookDiligencePartnerInfo',
  CONTACT_BOOK_VENTURE_CAPITAL_INFO: 'ContactBookVentureCapitalInfo',
  CONTACT_BOOK_INDUSTRY_EXPERT_INFO: 'ContactBookIndustryExpertInfo',
  CONTACT_BOOK_COMMENTS: 'ContactBookComments',
  CONTACT_BOOK_COMMENTS_COUNT: 'ContactBookCommentsCount',
  CONTACT_BOOK_OBJECT_HISTORY: 'ContactBookObjectHistory',
};

const endpoints = {
  listing: {
    url: API_URL.GET_CONTACT_BOOK_LISTING,
    tag: TAGS.CONTACT_BOOK_LISTING,
    deserializer: deserializeContactBookListing,
  },
  search: {
    url: API_URL.GET_CONTACT_BOOK_SEARCH,
    deserializer: deserializeSearchResult,
  },
  details: {
    url: API_URL.GET_CONTACT_BOOK_DETAILS,
    tag: TAGS.CONTACT_BOOK_DETAILS,
    deserializer: deserializeContactBookDetails,
  },
  diligencePartnerInfo: {
    url: API_URL.GET_CONTACT_BOOK_DILIGENCE_PARTNER_INFO,
    tag: TAGS.CONTACT_BOOK_DILIGENCE_PARTNER_INFO,
    deserializer: deserializeContactBookDiligencePartnerInfo,
  },
  ventureCapitalInfo: {
    url: API_URL.GET_CONTACT_BOOK_VENTURE_CAPITAL_INFO,
    tag: TAGS.CONTACT_BOOK_VENTURE_CAPITAL_INFO,
    deserializer: deserializeContactBookVentureCapitalInfo,
  },
  industryExpertInfo: {
    url: API_URL.GET_CONTACT_BOOK_INDUSTRY_EXPERT_INFO,
    tag: TAGS.CONTACT_BOOK_INDUSTRY_EXPERT_INFO,
    deserializer: deserializeContactBookIndustryExpertInfo,
  },
  menu: {
    url: API_URL.GET_CONTACT_BOOK_MENU,
    tag: TAGS.CONTACT_BOOK_MENU,
    deserializer: deserializeContactBookMenu,
  },
  getHistory: {
    url: API_URL.GET_CONTACT_BOOK_HISTORY,
    tag: TAGS.CONTACT_BOOK_OBJECT_HISTORY,
    deserializer: deserializeObjectHistory,
  },
  getTags: {
    url: API_URL.GET_CONTACT_BOOK_TAGS,
  },
  comments: {
    url: API_URL.GET_CONTACT_BOOK_COMMENTS,
    tag: TAGS.CONTACT_BOOK_COMMENTS,
    deserializer: deserializeUserCommentList,
  },
  commentsCount: {
    url: API_URL.GET_CONTACT_BOOK_COMMENTS_COUNT,
    tag: TAGS.CONTACT_BOOK_COMMENTS_COUNT,
  },
  postComment: {
    url: API_URL.POST_CONTACT_BOOK_COMMENT,
    invalidates: [TAGS.CONTACT_BOOK_COMMENTS],
  },
  deleteComment: {
    url: API_URL.DELETE_CONTACT_BOOK_COMMENT,
    invalidates: [TAGS.CONTACT_BOOK_COMMENTS],
  },
  updateListingItem: {
    url: API_URL.POST_CONTACT_BOOK_LISTING_UPDATE,
    deserializer: deserializeContactBookListingItem,
    invalidates: [TAGS.CONTACT_BOOK_MENU],
  },
  createContactBook: {
    url: API_URL.POST_NEW_CONTACT_BOOK,
    invalidates: [TAGS.CONTACT_BOOK_LISTING, TAGS.CONTACT_BOOK_MENU],
  },
  deleteContactBook: {
    url: API_URL.DELETE_CONTACT_BOOK,
    invalidates: [TAGS.CONTACT_BOOK_LISTING, TAGS.CONTACT_BOOK_MENU],
  },
  updateContactBook: {
    url: API_URL.PATCH_CONTACT_BOOK,
    invalidates: [TAGS.CONTACT_BOOK_LISTING, TAGS.CONTACT_BOOK_MENU, TAGS.CONTACT_BOOK_DETAILS],
  },
};

export default endpoints;
