import i18n from 'core/lib/utils/i18n';

export type PortfolioStatusType = 1 | 2 | 3 | 4;

export const PORTFOLIO_STATUS = {
  NEW: 1 as PortfolioStatusType,
  STARTED: 2 as PortfolioStatusType,
  COMPLETED: 3 as PortfolioStatusType,
  OPERATIONS: 4 as PortfolioStatusType,
};

export const PORTFOLIO_STATUS_KEYS = {
  [PORTFOLIO_STATUS.NEW]: 'new',
  [PORTFOLIO_STATUS.STARTED]: 'started',
  [PORTFOLIO_STATUS.COMPLETED]: 'completed',
  [PORTFOLIO_STATUS.OPERATIONS]: 'operations',
};

export const PORTFOLIO_STATUSLIST = [
  {
    status: PORTFOLIO_STATUS.NEW,
    key: PORTFOLIO_STATUS_KEYS[PORTFOLIO_STATUS.NEW],
    name: i18n.t(`portfolio:status.label.${PORTFOLIO_STATUS_KEYS[PORTFOLIO_STATUS.NEW]}`),
  },
  {
    status: PORTFOLIO_STATUS.STARTED,
    key: PORTFOLIO_STATUS_KEYS[PORTFOLIO_STATUS.STARTED],
    name: i18n.t(`portfolio:status.label.${PORTFOLIO_STATUS_KEYS[PORTFOLIO_STATUS.STARTED]}`),
  },
  {
    status: PORTFOLIO_STATUS.COMPLETED,
    key: PORTFOLIO_STATUS_KEYS[PORTFOLIO_STATUS.COMPLETED],
    name: i18n.t(`portfolio:status.label.${PORTFOLIO_STATUS_KEYS[PORTFOLIO_STATUS.COMPLETED]}`),
  },
  {
    status: PORTFOLIO_STATUS.OPERATIONS,
    key: PORTFOLIO_STATUS_KEYS[PORTFOLIO_STATUS.OPERATIONS],
    name: i18n.t(`portfolio:status.label.${PORTFOLIO_STATUS_KEYS[PORTFOLIO_STATUS.OPERATIONS]}`),
  },
];
