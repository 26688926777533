import React from 'react';

import { Form } from 'antd';

import { BaseSelectOption } from 'core/lib';
import { RolesOptions } from 'core/lib/constants/selects';
import { userFormConfig } from 'modules/user/form/formConfig';

const {
  generalInformation: { userPrivileges },
} = userFormConfig;

type userPrivilegesProps = {
  editMode: boolean;
};

const UserPrivilegesSection = ({ editMode }: userPrivilegesProps) => {
  return (
    <div className="flex flex-col">
      <div className="flex sm:flex-row flex-col gap-x-4">
        <Form.Item
          className="md:w-3/6 w-full"
          label={userPrivileges.organizations.label}
          name={userPrivileges.organizations.dataIndex}
          rules={userPrivileges.organizations.rules}
          valuePropName="selectedOption"
          normalize={(options: BaseSelectOption[]) => {
            return options.map((v) => ({ id: v.value, name: v.label }));
          }}
        >
          <userPrivileges.organizations.component disabled={!editMode} asTags allowClear />
        </Form.Item>
        <Form.Item
          className="md:w-3/6 w-full"
          label={userPrivileges.roles.label}
          name={userPrivileges.roles.dataIndex}
          rules={userPrivileges.roles.rules}
          valuePropName="selectedOption"
          normalize={(options: BaseSelectOption[]) => {
            return options.map((v) => ({ id: v.id, name: v.label }));
          }}
        >
          <userPrivileges.roles.component options={RolesOptions} disabled={!editMode} mode="multiple" allowClear />
        </Form.Item>
      </div>
      <div className="flex sm:flex-row flex-col gap-x-4">
        <Form.Item
          className="md:w-3/6 w-full"
          label={userPrivileges.jobTitle.label}
          name={userPrivileges.jobTitle.dataIndex}
          rules={userPrivileges.jobTitle.rules}
        >
          <userPrivileges.jobTitle.component disabled={!editMode} />
        </Form.Item>
      </div>
    </div>
  );
};

export default UserPrivilegesSection;
