import React, { useMemo } from 'react';

import { Button, Popconfirm } from 'antd';
import { sortBy } from 'lodash';
import { useTranslation } from 'react-i18next';

import {
  BaseUser,
  conversationsApi,
  ModuleConversationDetails,
  selectCurrentUser,
  selectIsAuthenticatedUserAdmin,
  useAppSelector,
} from 'core/lib';
import { SYSTEM_ROLES } from 'core/lib/constants';
import PermissionControl from 'utils/permissionControl';

import ModalMembersList from './modalMembersList';

type ModalMembersProps = {
  createdById: number;
  participants: BaseUser[];
  conversationDetails: ModuleConversationDetails;
};

const ModalModuleMembers = ({ conversationDetails, participants }: ModalMembersProps) => {
  const { t } = useTranslation();

  const { data: users = [], isFetching: loadingUsers } = conversationsApi.useGetModuleConversationUsersQuery({
    objectType: conversationDetails.objectType,
    objectId: conversationDetails.objectId,
  });

  const currentUser = useAppSelector(selectCurrentUser);
  const isCurrentUserAdmin = useAppSelector(selectIsAuthenticatedUserAdmin);

  const [removeUserFromConversation, { isLoading: isRemovingUserLoading, originalArgs: originalRemoveUserArgs }] =
    conversationsApi.useRemoveUserFromModuleConversationMutation();

  const [addUserToConversation, { isLoading: isAddUserLoading, originalArgs: originalAddUserArgs }] =
    conversationsApi.useAddUserToModuleConversationMutation();

  const participantsIds = useMemo(() => participants.map((user) => user.id), [participants]);

  const isPartOfConversation = useMemo(
    () => currentUser?.id && participantsIds.includes(currentUser.id),
    [currentUser, participantsIds]
  );

  const onRemoveUser = (userId: number) => {
    removeUserFromConversation({
      userId,
      conversationId: conversationDetails.conversationId,
      moduleConversationId: conversationDetails.id,
    });
  };

  const onAddUser = (userId: number) => {
    addUserToConversation({
      userId,
      conversationId: conversationDetails.conversationId,
      moduleConversationId: conversationDetails.id,
    });
  };

  const onConfirmRemove = (userId: number) => {
    onRemoveUser(userId);
  };

  const orderedUsersByParticipants = useMemo(() => {
    if (isPartOfConversation) {
      const excludeCurrentUserData = users.filter((user) => user.id !== currentUser?.id);

      return sortBy(excludeCurrentUserData, (user) => !participantsIds.includes(user.id));
    }
    return users.filter((user) => participantsIds.includes(user.id));
  }, [users, participantsIds, currentUser?.id, isPartOfConversation]);

  const getActions = (user: BaseUser) => {
    if (!isPartOfConversation && !isCurrentUserAdmin) {
      return [];
    }
    const actions = [];
    if (participantsIds.includes(user.id)) {
      actions.push(
        <PermissionControl roles={[SYSTEM_ROLES.CLIENT_ADMIN]}>
          <Popconfirm
            overlayClassName="max-w-80"
            title={t('conversations:modal.info.removeFromConversation', { user: user.name })}
            okText={t('actions:global.remove')}
            onConfirm={() => onConfirmRemove(user.id)}
            okButtonProps={{ danger: true }}
          >
            <Button type="link" loading={isRemovingUserLoading && originalRemoveUserArgs?.userId === user.id}>
              {t('actions:global.remove')}
            </Button>
          </Popconfirm>
        </PermissionControl>
      );
    } else if (!participantsIds.includes(user.id)) {
      actions.push(
        <Button
          type="link"
          loading={isAddUserLoading && originalAddUserArgs?.userId === user.id}
          onClick={() => onAddUser(user.id)}
        >
          {t('actions:global.add')}
        </Button>
      );
    }

    return actions;
  };

  return <ModalMembersList getActions={getActions} users={orderedUsersByParticipants} isLoading={loadingUsers} />;
};

export default ModalModuleMembers;
