import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { DownOutlined } from '@ant-design/icons';
import { Button, Checkbox, Dropdown } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { intersection } from 'lodash';
import { useTranslation } from 'react-i18next';

import { DROPDOWN_TRIGGERS, FilterActions } from 'common-ui';
import { organizationApi, OrganizationOption, useAppDispatch, useAppSelector } from 'core/lib';
import { selectPortfolioOrganizationFilters, setPortfolioFilters } from 'core/lib/modules/portfolio/store';

const PortfolioOrganizationsFilter = () => {
  const [visible, setVisibility] = useState(false);
  const portfolioOrganizationsFilters = useAppSelector(selectPortfolioOrganizationFilters);
  const dispatch = useAppDispatch();

  const [draftFilters, setDraftFilters] = useState(portfolioOrganizationsFilters);
  const { t } = useTranslation();

  const applyFilters = (portfolioOrganizationsFilters: number[] | undefined) =>
    dispatch(setPortfolioFilters({ organization: portfolioOrganizationsFilters }));

  const { data: OrganizationOptions } = organizationApi.useGetUserOrganizationsQuery();
  const filterChecked = useCallback((organizationId: number) => draftFilters?.includes(organizationId), [draftFilters]);

  useEffect(() => {
    setDraftFilters(portfolioOrganizationsFilters);
  }, [portfolioOrganizationsFilters]);

  const filtersUpdated = useMemo(() => {
    return (
      portfolioOrganizationsFilters?.length !== draftFilters?.length ||
      intersection(draftFilters, portfolioOrganizationsFilters).length !== portfolioOrganizationsFilters?.length
    );
  }, [draftFilters, portfolioOrganizationsFilters]);

  const onFilterUpdate = (organizationId: number) => {
    if (filterChecked(organizationId)) {
      setDraftFilters(draftFilters?.filter((draftOrganizationId) => draftOrganizationId !== organizationId));
    } else {
      setDraftFilters((draftFilters ?? []).concat(organizationId));
    }
  };

  const onApply = () => {
    applyFilters(draftFilters);
  };

  const onClear = () => {
    setDraftFilters([]);
  };

  const items: ItemType[] = useMemo(() => {
    return [
      {
        key: 'filter_group',
        className: 'max-h-64 overflow-y-auto p-0',
        type: 'group',
        children: OrganizationOptions?.map((item: OrganizationOption) => ({
          key: item.id,
          label: (
            <Checkbox
              onChange={() => onFilterUpdate(item.id)}
              checked={filterChecked(item.id)}
              className="flex flex-auto"
            >
              {item.label}
            </Checkbox>
          ),
        })),
      },
      { type: 'divider' },
      {
        type: 'group',
        key: 'status_actions',
        label: <FilterActions onClear={onClear} onApply={onApply} filtersUpdated={filtersUpdated} />,
      },
    ];
  }, [OrganizationOptions, filtersUpdated, filterChecked, t]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <Dropdown
        trigger={DROPDOWN_TRIGGERS}
        onOpenChange={(val) => setVisibility(val)}
        mouseLeaveDelay={0.2}
        open={visible}
        menu={{ items }}
      >
        <Button>
          {t('portfolio:filters.organizationCount', { count: portfolioOrganizationsFilters?.length })} <DownOutlined />
        </Button>
      </Dropdown>
    </div>
  );
};

export default PortfolioOrganizationsFilter;
