import { createApi } from '@reduxjs/toolkit/query/react';

import { dynamicBaseQuery } from 'core/lib/utils/requesting';
import { cacher } from 'core/lib/utils/rtkQueryCacheUtils';

import endpoints, { TAGS } from './endpoints';
import { DetailsSectionPanel } from '../../entities';

const detailsSectionPreferenceApi = createApi({
  reducerPath: 'detailsSectionPreferenceApi',
  baseQuery: dynamicBaseQuery,
  tagTypes: [...cacher.defaultTags, ...Object.values(TAGS)],
  endpoints: (build) => ({
    getDetailsSectionHeaders: build.query<DetailsSectionPanel[], number>({
      query: (objectType: number) => ({
        url: endpoints.getDetailsSectionHeaders.url,
        params: { objectType },
      }),
      providesTags: cacher.providesList(endpoints.getDetailsSectionHeaders.tag),
      transformResponse: endpoints.getDetailsSectionHeaders.deserializer,
    }),

    postDetailsSectionItem: build.mutation<
      DetailsSectionPanel,
      { header: Partial<DetailsSectionPanel>; objectType: number }
    >({
      query: ({ objectType, header }) => ({
        method: 'POST',
        url: endpoints.postDetailsSectionItem.url,
        body: { objectType, header },
      }),
      transformResponse: endpoints.postDetailsSectionItem.deserializer,
      async onQueryStarted({ objectType, header }, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            detailsSectionPreferenceApi.util.updateQueryData('getDetailsSectionHeaders', objectType, (draft) => {
              const index = draft.findIndex((d) => d.name === header.name);
              draft.splice(index, 1, { ...draft[index], ...data });
            })
          );
        } catch {}
      },
    }),
  }),
});

export default detailsSectionPreferenceApi;
