import i18n from '../utils/i18n';

export type PortfolioReviewStatus = 'onTrack' | 'dueForReview' | 'overdue';

const REVIEW_STATUS: { [key: string]: PortfolioReviewStatus } = {
  ON_TRACK: 'onTrack',
  DUE_FOR_REVIEW: 'dueForReview',
  OVERDUE: 'overdue',
};

export const PORTFOLIO_REVIEW_STATUS = {
  [REVIEW_STATUS.ON_TRACK]: i18n.t(`portfolio:reviewStatus.${REVIEW_STATUS.ON_TRACK}`),
  [REVIEW_STATUS.DUE_FOR_REVIEW]: i18n.t(`portfolio:reviewStatus.${REVIEW_STATUS.DUE_FOR_REVIEW}`),
  [REVIEW_STATUS.OVERDUE]: i18n.t(`portfolio:reviewStatus.${REVIEW_STATUS.OVERDUE}`),
};

export const PORTFOLIO_REVIEW_STATUS_COLORS = {
  [REVIEW_STATUS.ON_TRACK]: 'blue',
  [REVIEW_STATUS.DUE_FOR_REVIEW]: 'orange',
  [REVIEW_STATUS.OVERDUE]: 'red',
};
