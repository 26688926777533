import React from 'react';

import CheckableTag from 'antd/lib/tag/CheckableTag';
import { useTranslation } from 'react-i18next';

import { FiltersContainer, FiltersTitle } from 'common-ui/filters';
import { ContactBookFilters } from 'core/lib';
import { ContactBookDiligenceType } from 'core/lib/constants/contactBook';
import { DiligenceTypeOptions } from 'core/lib/constants/selects';

type DiligenceTypeFilterProps = {
  onChange: (filters: Partial<ContactBookFilters>) => void;
  filters: ContactBookDiligenceType[] | undefined;
};

const DiligenceTypeFilter = ({ onChange, filters }: DiligenceTypeFilterProps) => {
  const { t } = useTranslation();

  const handleOnTagClick = (type: ContactBookDiligenceType, checked: boolean) => {
    if (checked) {
      onChange({ diligenceType: [...(filters ?? []), type] });
    } else {
      onChange({ diligenceType: filters?.filter((dType) => dType !== type) });
    }
  };

  return (
    <FiltersContainer>
      <FiltersTitle title={t('contactBook:filters.diligenceType')} />
      <div className="flex gap-2">
        {DiligenceTypeOptions.map((diligenceType) => (
          <CheckableTag
            key={diligenceType.value}
            checked={!!filters?.includes(diligenceType.value)}
            onChange={(checked) => handleOnTagClick(diligenceType.value, checked)}
          >
            {diligenceType.label}
          </CheckableTag>
        ))}
      </div>
    </FiltersContainer>
  );
};

export default DiligenceTypeFilter;
