import React from 'react';

import { Popover, Tag } from 'antd';
import { Trans } from 'react-i18next';

import { TagsGroup } from 'common-ui/tags';
import { contactBookApi, ContactBookDetails, ContactBookListing } from 'core/lib';
import currencies from 'core/lib/utils/config/currencies';
import LoaderWrapper from 'utils/loader-wrapper';

import {
  PopoverFieldLabel,
  PopoverBaseContactBookInfoComponent,
  PopoverContactButton,
  PopoverContactBookType,
} from './components';
import ContactBookDetailsPageHeader from '../detailsPageHeader';

type VentureCapitalPopoverProps = {
  contactBook: ContactBookListing;
  children: React.ReactNode;
};

const VentureCapitalPopover = ({ contactBook, children }: VentureCapitalPopoverProps) => {
  const [getVentureCapitalInfo, { data: ventureCapitalInfo, isFetching }] =
    contactBookApi.useLazyGetVentureCapitalInfoQuery();

  const onContactBaseHover = (open: boolean) => {
    if (open) {
      getVentureCapitalInfo(contactBook.id.toString(), true);
    }
  };

  return (
    <Popover
      arrowPointAtCenter
      onOpenChange={onContactBaseHover}
      overlayClassName="w-auto max-w-80"
      content={
        <LoaderWrapper loading={isFetching}>
          {ventureCapitalInfo && (
            <div className="flex flex-col px-1 gap-4">
              <div className="flex flex-row justify-between">
                <PopoverContactBookType type={ventureCapitalInfo.type} />
                {ventureCapitalInfo.stage && (
                  <div className="flex flex-col gap-2">
                    <PopoverFieldLabel i18nKey="contactBook:shortInfo.labels.stage" />
                    <div>
                      <Tag key={ventureCapitalInfo.stage.id} color="orange">
                        {ventureCapitalInfo.stage.name}
                      </Tag>
                    </div>
                  </div>
                )}
              </div>
              {ventureCapitalInfo.investmentFocus?.length ? (
                <div className="flex flex-col gap-2">
                  <PopoverFieldLabel i18nKey="contactBook:shortInfo.labels.investmentFocus" />
                  <TagsGroup className="text-ellipsis overflow-hidden whitespace-nowrap">
                    {ventureCapitalInfo.investmentFocus.map((iFocus) => (
                      <Tag key={iFocus.id} color="green">
                        {iFocus.name}
                      </Tag>
                    ))}
                  </TagsGroup>
                </div>
              ) : null}
              {ventureCapitalInfo.investmentGeography?.length ? (
                <div className="flex flex-col gap-2">
                  <PopoverFieldLabel i18nKey="contactBook:shortInfo.labels.investmentGeography" />
                  <TagsGroup className="text-ellipsis overflow-hidden whitespace-nowrap">
                    {ventureCapitalInfo.investmentGeography.map((iGeography) => (
                      <Tag key={iGeography} color="purple">
                        {iGeography}
                      </Tag>
                    ))}
                  </TagsGroup>
                </div>
              ) : null}
              <div className="flex flex-row justify-between">
                {ventureCapitalInfo.investmentTicket && (
                  <div className="flex flex-col">
                    <PopoverFieldLabel i18nKey="contactBook:shortInfo.labels.investmentTicket" />
                    {ventureCapitalInfo.investmentTicket && (
                      <span>
                        {`${currencies.EUR.symbol}${ventureCapitalInfo.investmentTicket.from}-${ventureCapitalInfo.investmentTicket.to}`}
                        <Trans i18nKey="global:placeholders.millionsAbbr" />
                      </span>
                    )}
                  </div>
                )}
                {ventureCapitalInfo.fundSize && (
                  <div className="flex flex-col">
                    <PopoverFieldLabel i18nKey="contactBook:shortInfo.labels.fundSize" />
                    {ventureCapitalInfo.fundSize && (
                      <span>
                        {`${currencies.EUR.symbol}${ventureCapitalInfo.fundSize.from}-${ventureCapitalInfo.fundSize.to}`}{' '}
                        <Trans i18nKey="global:placeholders.millionsAbbr" />
                      </span>
                    )}
                  </div>
                )}
              </div>
              <PopoverBaseContactBookInfoComponent
                phoneNumber={ventureCapitalInfo.phoneNumber}
                email={ventureCapitalInfo.email}
              />
              <PopoverContactButton id={ventureCapitalInfo.id} email={ventureCapitalInfo.email} />
            </div>
          )}
        </LoaderWrapper>
      }
      title={
        <div className="px-1 py-3">
          {ventureCapitalInfo && (
            <ContactBookDetailsPageHeader
              defaultSize="small"
              className="p-0"
              contactBook={ventureCapitalInfo as ContactBookDetails}
            />
          )}
        </div>
      }
      trigger="hover"
    >
      {children}
    </Popover>
  );
};

export default VentureCapitalPopover;
