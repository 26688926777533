import React, { useMemo, useState } from 'react';

import { Input, List, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList, ListChildComponentProps } from 'react-window';

import { fuzzyWordsSearch } from 'core/lib/utils';
import { getNumberWithOrdinal } from 'utils';

import ModuleObjectsPopover from './moduleObjectsPopover';

const { Title } = Typography;

type ListObjectFilterProps<T> = {
  title: string;
  placeholder: string;
  data: { [key: string]: T[] };
  objectType: number;
};

function ListObjectFilter<T extends { id: number; name: string }>({
  data,
  title,
  placeholder,
  objectType,
}: ListObjectFilterProps<T>) {
  const [search, setSearch] = useState('');
  const [popOverIndex, setPopOverVisible] = useState<number | null>(null);

  const { t } = useTranslation();

  const renderItem = ({ index: itemIndex, data, style }: ListChildComponentProps) => {
    const [objectName, dataList, index] = data[itemIndex];

    return (
      <List.Item key={`${itemIndex}-${objectName}`} style={style} className="px-4">
        <List.Item.Meta
          title={objectName}
          description={
            <ModuleObjectsPopover<T>
              objectType={objectType}
              data={dataList}
              text={t('global:results.submissions', { count: dataList.length })}
              trigger="click"
              open={popOverIndex === index}
              onOpenChange={(visible) => setPopOverVisible(visible ? index : null)}
            />
          }
        />
        <div>{getNumberWithOrdinal(index + 1)}</div>
      </List.Item>
    );
  };

  const filteredData = useMemo(() => {
    return Object.entries(data)
      .sort(([_, listAData], [__, listBData]) => listBData.length - listAData.length)
      .map(([objectName, dataList], index): [string, T[], number] => [objectName, dataList, index])
      .filter(([objectName, _, __]) => fuzzyWordsSearch(search, objectName));
  }, [search, data]);

  return (
    <div className="flex flex-col gap-2">
      <Title level={5}>{title}</Title>
      <div className="flex flex-col gap-4 border border-solid b--grey-200 p-4">
        <Input placeholder={placeholder} className="md:w-3/6 w-full" onChange={(e) => setSearch(e.target.value)} />
        <div className="h-64">
          <AutoSizer>
            {({ height, width }) => (
              <List>
                <FixedSizeList
                  itemData={filteredData}
                  width={width}
                  height={height}
                  itemCount={filteredData.length}
                  itemSize={80}
                >
                  {renderItem}
                </FixedSizeList>
              </List>
            )}
          </AutoSizer>
        </div>
      </div>
    </div>
  );
}

export default ListObjectFilter;
