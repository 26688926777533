import React from 'react';

import { Badge, Skeleton, Tag, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';
import { ListChildComponentProps } from 'react-window';

import { MenuItemWrapper } from 'common-ui/leftMenu';
import { DealBaseMenu } from 'core/lib';
import { DEALBASE_STATUS_KEYS } from 'core/lib/constants/statuses/dealbase';
import { Routes } from 'routes/routes';

const { Text } = Typography;

type DealBaseMenuItemProps = {
  id?: string;
  isLoading: boolean;
};

type DealBaseMenuItemConnectedProps = ListChildComponentProps<DealBaseMenu[]> & DealBaseMenuItemProps;

const DealBaseMenuItem = ({ index, data, style, id }: DealBaseMenuItemConnectedProps) => {
  const { t } = useTranslation('dealbase');

  const item = data?.[index];
  const isActive = item?.id === parseInt(id ?? '');
  return (
    <Skeleton active loading={!item}>
      {item && (
        <MenuItemWrapper
          isActive={isActive}
          style={style}
          path={generatePath(Routes.DEAL_BASE_DETAILS.path, { id: item.id })}
        >
          <Badge
            style={{ lineHeight: 'auto' }}
            dot={item.notification}
            size="small"
            offset={[5, 0]}
            className="text-inherit overflow-hidden"
          >
            <Text className="text-inherit" ellipsis>
              {item?.name}
            </Text>
          </Badge>
          <Tag color="blue">{t(`status.label.${DEALBASE_STATUS_KEYS[item.status]}`)}</Tag>
        </MenuItemWrapper>
      )}
    </Skeleton>
  );
};

export default DealBaseMenuItem;
