import React from 'react';

import { Select, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { DealBaseFilters, userApi } from 'core/lib';
import LoaderWrapper from 'utils/loader-wrapper';

const { Title } = Typography;

type AssignedUsersFilterProps = {
  onChange: (filters: Partial<DealBaseFilters>) => void;
  filters: number[] | undefined;
};

const AssignedUsersFilter = ({ onChange, filters }: AssignedUsersFilterProps) => {
  const { t } = useTranslation();
  const { data: userOptions, isFetching: userOptionsLoading } = userApi.useGetUserOptionsQuery();

  return (
    <div>
      <Title level={5}>{t('dealbase:filters.assignedCount', { count: filters?.length })}</Title>
      <LoaderWrapper loading={userOptionsLoading} message={t('loaders:filters.loadingUserFilters')}>
        <Select
          mode="multiple"
          className="w-full"
          allowClear
          showSearch={false}
          options={userOptions}
          value={filters}
          placeholder={t('global:placeholders.selectType', { type: t('dealbase:filters.assigned') })}
          onChange={(assigned) => onChange({ assigned })}
        />
      </LoaderWrapper>
    </div>
  );
};

export default AssignedUsersFilter;
