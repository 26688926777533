import {
  deserializeOrganizationDetails,
  deserializeOrganizationListing,
  deserializeOrganizationToOption,
} from './serializers';

const API_V2 = 'api/v2';

const CONTROLLER = 'organizations';

const API_URL = {
  GET_ORGANIZATION_LISTING: `${API_V2}/${CONTROLLER}/getListing`,
  GET_ORGANIZATION_DETAILS: `${API_V2}/${CONTROLLER}/getById`,
  GET_USER_ORGANIZATIONS: `${API_V2}/${CONTROLLER}/getAllByUserShort`,
  POST_ORGANIZATION: `${API_V2}/${CONTROLLER}/create`,
  PATCH_ORGANIZATION: `${API_V2}/${CONTROLLER}/update`,
  DELETE_ORGANIZATION: `${API_V2}/${CONTROLLER}/delete`,
};

export const TAGS = {
  ORGANIZATION_LISTING: 'OrganizationListing',
  ORGANIZATION_DETAILS: 'OrganizationDetails',
  USER_ORGANIZATIONS: 'UserOrganizations',
};

const endpoints = {
  listing: {
    url: API_URL.GET_ORGANIZATION_LISTING,
    tag: TAGS.ORGANIZATION_LISTING,
    deserializer: deserializeOrganizationListing,
  },
  details: {
    url: API_URL.GET_ORGANIZATION_DETAILS,
    tag: TAGS.ORGANIZATION_DETAILS,
    deserializer: deserializeOrganizationDetails,
  },
  userOrganizations: {
    url: API_URL.GET_USER_ORGANIZATIONS,
    tag: TAGS.USER_ORGANIZATIONS,
    deserializer: deserializeOrganizationToOption,
  },
  create: {
    url: API_URL.POST_ORGANIZATION,
    invalidates: [TAGS.ORGANIZATION_LISTING],
  },
  update: {
    url: API_URL.PATCH_ORGANIZATION,
    invalidates: [TAGS.ORGANIZATION_LISTING],
  },
  delete: {
    url: API_URL.DELETE_ORGANIZATION,
    invalidates: [TAGS.ORGANIZATION_LISTING],
  },
};

export default endpoints;
