import React from 'react';

import { generatePath, Redirect, useLocation } from 'react-router-dom';

import { Routes } from 'routes/routes';

const ExternalOldRedirect = () => {
  const location = useLocation();
  const [id, dealId, type] = location.pathname.replace('/external-login-', '').split('-');

  if (type === '1') {
    return <Redirect to={generatePath(Routes.EXTERNAL_CASEOWNER.path, { url: id, dealId, type })} />;
  } else if (type === '2') {
    return <Redirect to={generatePath(Routes.EXTERNAL_THIRDPARTY.path, { url: id, dealId, type })} />;
  }

  return null;
};

export default ExternalOldRedirect;
