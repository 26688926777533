import React, { useMemo, useState } from 'react';

import { TeamOutlined } from '@ant-design/icons';
import { Button, Divider, Form, Input, Modal, Popconfirm, Tabs } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router-dom';

import { UserAvatar, UserAvatarGroup } from 'common-ui';
import { conversationsApi, GroupConversationDetails } from 'core/lib';
import { Routes } from 'routes/routes';
import { rules } from 'utils/form/rules';
import { useLoggedInUser } from 'utils/hooks';

import ModalGroupMembers from './modalGroupMembers';

const MODAL_TABS = {
  ABOUT: 'about',
  MEMBERS: 'members',
};

type GroupConversationDetailsHeaderProps = {
  conversationDetails: GroupConversationDetails;
  withDivider?: boolean;
};

const GroupConversationDetailsHeader = ({
  conversationDetails,
  withDivider = false,
}: GroupConversationDetailsHeaderProps) => {
  const { t } = useTranslation();
  const [editMode, setEditMode] = useState(false);
  const history = useHistory();
  const [modalOpen, setModalOpen] = useState(false);
  const [activeTabKey, setActiveTabKey] = useState(MODAL_TABS.ABOUT);
  const [form] = useForm();
  const currentUser = useLoggedInUser();

  const [leaveConversation, { isLoading: isLeaveConversationLoading }] =
    conversationsApi.useLeaveGroupConversationMutation();

  const [updateTitle, { isLoading: updatingTitle }] = conversationsApi.usePostRenameGroupMutation();

  const isLastMember = useMemo(() => conversationDetails.participants.length === 1, [conversationDetails.participants]);

  const isOwner = useMemo(
    () => conversationDetails.createdBy.id === currentUser?.id,
    [conversationDetails.createdBy, currentUser]
  );

  const leaveGroupInfoText = useMemo(() => {
    if (isOwner) {
      return t('conversations:modal.info.creatorLeaveGroup');
    } else if (isLastMember) {
      return t('conversations:modal.info.lastMemberLeaveGroup');
    }
    return t('conversations:modal.info.leaveGroup');
  }, [t, isLastMember, isOwner]);

  const onClick = () => {
    setModalOpen(!modalOpen);
  };

  const onCancel = () => {
    setModalOpen(false);
    handleTitleUpdateCancel();
  };

  const onConfirmLeaveConversation = () => {
    leaveConversation(conversationDetails.id)
      .unwrap()
      .then(() => {
        history.push(generatePath(Routes.CONVERSATIONS_LISTING.path));
      });
  };

  const handleTitleUpdate = (values: { title: string }) => {
    updateTitle({ title: values.title, groupConversationId: conversationDetails.id })
      .unwrap()
      .then(() => {
        setEditMode(false);
      });
  };

  const handleTitleUpdateCancel = () => {
    form.resetFields();
    setEditMode(false);
  };

  return (
    <>
      <div className="flex flex-col gap-2">
        <div className="flex justify-between">
          <Button icon={<TeamOutlined />} type="text" onClick={onClick}>
            {conversationDetails.title}
          </Button>
          <UserAvatarGroup users={conversationDetails?.participants} />
        </div>
        {withDivider && <Divider className="m-0" />}
      </div>
      <Modal
        title={
          <div className="flex items-center gap-2">
            {conversationDetails.title}
            <TeamOutlined />
          </div>
        }
        open={modalOpen}
        onCancel={onCancel}
        footer={null}
        bodyStyle={{ paddingTop: 0 }}
        className="hide-modal-title-border"
      >
        <Tabs
          activeKey={activeTabKey}
          onChange={setActiveTabKey}
          size="small"
          type="line"
          items={[
            {
              key: MODAL_TABS.ABOUT,
              label: t('conversations:modal.tabs.about'),
              children: (
                <div className="flex flex-col gap-4">
                  <div className="flex flex-auto flex-col">
                    <Form
                      layout="vertical"
                      name="group_conversation_title"
                      onFinish={handleTitleUpdate}
                      initialValues={{ title: conversationDetails.title }}
                      form={form}
                    >
                      <div className="flex flex-col">
                        <Form.Item rules={[rules.required]} label={t('conversations:modal.general.name')} name="title">
                          <Input disabled={!editMode} />
                        </Form.Item>
                        {isOwner && (
                          <div className="flex justify-end">
                            {!editMode ? (
                              <Button type="link" onClick={() => setEditMode(true)}>
                                {t('actions:global.edit')}
                              </Button>
                            ) : (
                              <div>
                                <Button
                                  loading={updatingTitle}
                                  disabled={updatingTitle}
                                  type="link"
                                  onClick={form.submit}
                                >
                                  {t('actions:global.save')}
                                </Button>
                                <Button disabled={updatingTitle} type="link" onClick={handleTitleUpdateCancel}>
                                  {t('actions:global.cancel')}
                                </Button>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </Form>
                  </div>
                  <div className="flex flex-col gap-2">
                    <label>{t('conversations:modal.general.createdBy')}</label>
                    <div className="flex items-center gap-2">
                      <UserAvatar user={conversationDetails.createdBy} />
                    </div>
                  </div>
                  <Popconfirm
                    overlayClassName="max-w-80"
                    title={leaveGroupInfoText}
                    okText={t('conversations:actions.leave')}
                    onConfirm={onConfirmLeaveConversation}
                    okButtonProps={{ danger: true }}
                  >
                    <Button danger type="primary" loading={isLeaveConversationLoading}>
                      {t('conversations:actions.leaveGroup')}
                    </Button>
                  </Popconfirm>
                </div>
              ),
            },
            {
              key: MODAL_TABS.MEMBERS,
              label: t('conversations:modal.tabs.members'),
              children: (
                <ModalGroupMembers
                  createdById={conversationDetails.createdBy.id}
                  participants={conversationDetails.participants}
                  conversationDetails={conversationDetails}
                />
              ),
            },
          ]}
        />
      </Modal>
    </>
  );
};

export default GroupConversationDetailsHeader;
