import { deserializeFavorite, deserializeUserFavorite } from './serializers';

export const TAGS = {
  FavoriteList: 'FavoriteList',
  FavoriteDetails: 'FavoriteDetails',
};

export const endpoints = {
  createAuditLog: {
    url: 'api/audit/createAuditLog',
  },
  getFavorites: {
    url: 'api/userPageManagement/getFavorites',
    tag: TAGS.FavoriteList,
    deserializer: deserializeUserFavorite,
  },
  getFavoriteById: {
    url: 'api/userPageManagement/getFavorite',
    tag: TAGS.FavoriteDetails,
    deserializer: deserializeFavorite,
  },
  setFavorite: {
    url: 'api/userPageManagement/setFavorite',
    invalidates: [TAGS.FavoriteList],
  },
  downloadBase64: {
    url: 'api/userblob/downloadbase64',
  },
  download: {
    url: 'api/userblob/download',
  },
  postUpload: {
    url: 'api/userblob/upload',
  },
};
