import { createSlice } from '@reduxjs/toolkit';

import { logout } from '../../auth';
import { organizationApi } from '../services';

type OrganizationState = {
  createNew: boolean;
};

const initialState: OrganizationState = {
  createNew: false,
};

export const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    setCreateNewMode: (state, { payload }: { payload: boolean }) => {
      state.createNew = payload;
   }
  },
  extraReducers: (builder) => {
    builder.addCase(logout, () => {
      return initialState;
    });
    builder.addMatcher(organizationApi.endpoints.create.matchFulfilled, (state) => {
      state.createNew = false;
    });
  }
});

export const { setCreateNewMode } = organizationSlice.actions;

export default organizationSlice.reducer;
