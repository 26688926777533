import React from 'react';

import { BackTop } from 'antd';

type MainContainerProps = {
  children: React.ReactNode;
  id: string;
};

const MainContentContainer = ({ children, id }: MainContainerProps) => {
  return (
    <div id={id} className="p-4 flex flex-row h-full overflow-scroll gap-x-4">
      {children}
      <BackTop style={{ bottom: 30, right: 30 }} target={() => document.getElementById(id)!} />
    </div>
  );
};

export default MainContentContainer;
