import React from 'react';

import { Form } from 'antd';

import { dealBaseFormConfig } from 'modules/dealBase/form/formConfig';

type MetricsCurrencySectionProps = {
  editMode: boolean;
};

const {
  metrics: { metricsCurrency },
} = dealBaseFormConfig;

const DealBaseMetricsCurrencySection = ({ editMode }: MetricsCurrencySectionProps) => {
  return (
    <div className="flex flex-col">
      <div className="flex sm:flex-row flex-col gap-x-4">
        <Form.Item
          className="w-full"
          label={metricsCurrency.currency.label}
          rules={metricsCurrency.currency.rules}
          name={metricsCurrency.currency.dataIndex}
        >
          <metricsCurrency.currency.component disabled={!editMode} />
        </Form.Item>
      </div>
    </div>
  );
};

export default DealBaseMetricsCurrencySection;
