import React from 'react';

import { Form, SelectProps } from 'antd';

import { ContactBookTagsSelect } from 'utils/form';

import { ListingEditableCellProps } from './types';

function ListingContactBookTagSelect({ dataIndex, rules, ...props }: ListingEditableCellProps & SelectProps<string[]>) {
  return (
    <Form.Item rules={rules} name={dataIndex} className="m-0">
      <ContactBookTagsSelect {...props} />
    </Form.Item>
  );
}

export default ListingContactBookTagSelect;
