import React, { useEffect, useMemo } from 'react';

import { EllipsisOutlined } from '@ant-design/icons';
import { Dropdown, message } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { useTranslation } from 'react-i18next';

import { DROPDOWN_TRIGGERS, MenuItemConversationAction } from 'common-ui';
import { Organization } from 'core/lib';
import { OBJECT_TYPES } from 'core/lib/constants';
import appApi from 'core/lib/modules/app/services/app.api';
import { organizationApi } from 'core/lib/modules/organization/services';
import { downloadAuditLog } from 'core/lib/utils/requesting';

type OrganizationCardActionsProps = {
  organization: Organization;
};

const OrganizationCardActions = ({ organization }: OrganizationCardActionsProps) => {
  const { t } = useTranslation();

  const [deleteOrganization, { isError }] = organizationApi.useDeleteMutation();
  const [createAuditLog, { data: blobId, isSuccess: createAuditLogSuccess }] = appApi.useLazyCreateAuditLogQuery();
  const [downloadfile, { data: downloadData }] = appApi.useLazyDownloadBase64Query();

  useEffect(() => {
    if (isError) {
      message.error(t('actions:message.deleteDeny', { object: t('global:moduleNames.organization') }), 3);
    }
  }, [isError, t]);

  const onDeleteOrganizationClick = () => {
    deleteOrganization(organization.id);
  };

  const onExportAuditLogClick = () => {
    createAuditLog({ objectId: organization.id, objectType: OBJECT_TYPES.ORGANIZATION });
  };

  useEffect(() => {
    if (createAuditLogSuccess && blobId) {
      downloadfile(blobId);
    }
  }, [createAuditLogSuccess, blobId, downloadfile]);

  useEffect(() => {
    if (downloadData) {
      downloadAuditLog(t('global:moduleNames.organization'), downloadData);
    }
  }, [downloadData, t]);

  const items: ItemType[] = useMemo(() => {
    return [
      { key: 'export-audit-log', label: t('actions:global.exportAuditLog'), onClick: onExportAuditLogClick },
      {
        key: 'conversation_action',
        label: (
          <MenuItemConversationAction
            objectType={OBJECT_TYPES.ORGANIZATION}
            objectId={organization.id}
            objectName={organization.name}
          />
        ),
      },
      { type: 'divider' },
      {
        key: 'delete_organization',
        danger: true,
        className: 'warning',
        label: t('actions:global.delete'),
        onClick: onDeleteOrganizationClick,
      },
    ];
  }, [organization, t]); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Dropdown trigger={DROPDOWN_TRIGGERS} menu={{ className: 'min-w-32', items }}>
      <EllipsisOutlined />
    </Dropdown>
  );
};

export default OrganizationCardActions;
