import React, { useContext, useEffect, useState } from 'react';

import { Badge } from 'antd';

import { FormValidationsContext } from 'utils/form/formUtils';

type FormTabTitleProps = {
  label: string;
  dot?: boolean;
  tabKey: string;
};

const useFormValidation = (key: string) => {
  const formValidations = useContext(FormValidationsContext);
  const [tabValidation, setTabValidation] = useState(false);

  useEffect(() => {
    setTabValidation(!!formValidations?.tabs[key]);
  }, [formValidations?.tabs, key]);

  return tabValidation;
};

const FormTabTitle = ({ label, dot, tabKey }: FormTabTitleProps) => {
  const badgeDot = useFormValidation(tabKey);
  return (
    <Badge className="text-inherit" dot={dot ?? badgeDot}>
      {label}
    </Badge>
  );
};

export default FormTabTitle;
