import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { EditFilled, ReloadOutlined } from '@ant-design/icons';
import { Button, Form, Tabs } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Prompt } from 'react-router';

import FormTabTitle from 'common-ui/textBadges/formTabTitle';
import { PopConfirmCancelWrapper } from 'common-ui/wrappers';
import { DealBase } from 'core/lib';
import { formHasInvalidFields, FormValidations, FormValidationsContext } from 'utils/form/formUtils';
import { useRefIdParam, useRefTypeParam } from 'utils/hooks';
import LoaderWrapper from 'utils/loader-wrapper';

import { dealbaseTabs, sectionFields, tabFields } from './formKeys';
import { DealBaseDetailsContext } from '../context';
import { ActivityTab, DocumentsTab, EvaluationTab, GeneralTab, MetricsTab } from '../tabs';

const DEFAULT_ACTIVE_TAB = 'generalInformation';
const QUERY_NAV = {
  comment: dealbaseTabs.evaluation,
  decision: dealbaseTabs.evaluation,
};

type DealBaseFormProps = {
  onSave: (dealbase: DealBase) => Promise<void | number>;
  onCancel: () => void;
  onToggleEdit?: () => void;
  onRefetch?: () => void;
  setActiveTabKey: (tabKey: string) => void;
  activeTabKey?: string | null;
  editMode: boolean;
  dealbase: Partial<DealBase>;
  isNew?: boolean;
  isSaving: boolean;
};

const DealBaseForm = ({
  onSave,
  onCancel,
  onToggleEdit,
  onRefetch,
  setActiveTabKey,
  activeTabKey,
  editMode,
  dealbase,
  isNew = false,
  isSaving,
}: DealBaseFormProps) => {
  const [refType, setRefType] = useRefTypeParam();
  const [refId, setRefId] = useRefIdParam();

  const [formValidations, setFormValidations] = useState<FormValidations | null>(null);

  const [formDirty, setFormDirty] = useState(false);
  const [formObject] = useForm<DealBase>();
  const [draftDealBase, setDraftDealBase] = useState<Partial<DealBase>>(dealbase);

  const { t } = useTranslation(['loaders', 'actions', 'dealbase', 'global']);

  const defaultTab = useMemo(() => {
    let tab = DEFAULT_ACTIVE_TAB;
    if (refType) {
      tab = QUERY_NAV[refType];
    }
    return tab ?? DEFAULT_ACTIVE_TAB;
  }, [refType]);

  const handleTabChange = (tabKey: string) => {
    if (refType && QUERY_NAV[refType] !== tabKey) {
      setRefType(undefined);
      setRefId(undefined);
    }
    setActiveTabKey(tabKey);
  };

  useEffect(() => {
    if (refType) {
      setActiveTabKey(QUERY_NAV[refType]);
    }
  }, [refId, refType, setActiveTabKey]);

  const init = useCallback(() => {
    formObject?.resetFields();
    setFormDirty(false);
  }, [formObject]);

  useEffect(() => {
    setDraftDealBase(dealbase);
  }, [dealbase]);

  useEffect(() => {
    init();
  }, [editMode, init]);

  useEffect(() => {
    init();
  }, [draftDealBase.id, init]);

  const onFinish = (values: DealBase) => {
    setFormDirty(false);
    onSave(values).catch(() => {
      setFormDirty(true);
    });
  };

  const handleCancel = () => {
    setDraftDealBase(dealbase);
    onCancel();
    setFormDirty(false);
    setFormValidations(null);
  };

  const handleValuesChange = debounce((_, allValues) => {
    setFormDirty(true);
    setDraftDealBase({ ...draftDealBase, ...allValues });
  }, 100);

  const validateTabs = () => {
    const tabFieldsValidations = Object.entries(tabFields).reduce((agg, [tabKey, fields]) => {
      agg[tabKey] = formHasInvalidFields(formObject, fields);
      return agg;
    }, {});
    const sectionFieldsValidations = Object.entries(sectionFields).reduce((agg, [sectionKey, fields]) => {
      agg[sectionKey] = formHasInvalidFields(formObject, fields);
      return agg;
    }, {});

    setFormValidations({ tabs: tabFieldsValidations, sections: sectionFieldsValidations });
  };

  return (
    <LoaderWrapper overlay loading={isSaving} message={t('loaders:global.saving')}>
      <FormValidationsContext.Provider value={formValidations}>
        <DealBaseDetailsContext.Provider value={draftDealBase}>
          <Prompt when={formDirty} message={t('global:navigation.unsavedChanges')} />
          <Form
            onValuesChange={handleValuesChange}
            onFinishFailed={validateTabs}
            className="overflow-y-auto h-full"
            form={formObject}
            layout="vertical"
            name="dealBaseForm"
            onFinish={onFinish}
            initialValues={draftDealBase}
          >
            <Tabs
              size="small"
              activeKey={activeTabKey ?? defaultTab}
              onChange={handleTabChange}
              type="line"
              className="h-full overflow-y-auto tabs-centered"
              tabBarExtraContent={
                editMode ? (
                  <div className="flex flex-row gap-x-3">
                    <Button htmlType="submit" type="primary" disabled={!formDirty}>
                      {t('actions:global.save')}
                    </Button>
                    <PopConfirmCancelWrapper
                      title={t('actions:confirmation.confirmCancel')}
                      disabled={!formDirty}
                      onConfirm={handleCancel}
                    >
                      <Button>{t('actions:global.cancel')}</Button>
                    </PopConfirmCancelWrapper>
                  </div>
                ) : (
                  <div className="flex flex-row">
                    <Button type="link" onClick={onToggleEdit} icon={<EditFilled />} />
                    <Button type="link" onClick={onRefetch} icon={<ReloadOutlined />} />
                  </div>
                )
              }
              items={[
                {
                  key: dealbaseTabs.generalInformation,
                  className: 'h-full',
                  label: (
                    <FormTabTitle
                      label={t('dealbase:tabs.generalInformation')}
                      tabKey={dealbaseTabs.generalInformation}
                    />
                  ),
                  forceRender: editMode,
                  children: <GeneralTab isNew={isNew} editMode={editMode} />,
                },
                {
                  key: dealbaseTabs.evaluation,
                  className: 'h-full',
                  label: <FormTabTitle label={t('dealbase:tabs.evaluation')} tabKey={dealbaseTabs.evaluation} />,
                  children: <EvaluationTab editMode={editMode} />,
                },
                {
                  key: dealbaseTabs.metrics,
                  className: 'h-full',
                  label: <FormTabTitle label={t('dealbase:tabs.metrics')} tabKey={dealbaseTabs.metrics} />,
                  forceRender: editMode,
                  children: <MetricsTab editMode={editMode} />,
                },
                {
                  key: dealbaseTabs.documents,
                  className: 'h-full',
                  label: t('dealbase:tabs.documents'),
                  forceRender: editMode,
                  disabled: isNew,
                  children: <DocumentsTab editMode={editMode} />,
                },
                {
                  key: dealbaseTabs.activity,
                  className: 'h-full',
                  label: t('dealbase:tabs.activity'),
                  disabled: isNew,
                  children: <ActivityTab />,
                },
              ]}
            />
          </Form>
        </DealBaseDetailsContext.Provider>
      </FormValidationsContext.Provider>
    </LoaderWrapper>
  );
};

export default DealBaseForm;
