import Color from './colorUtil';

const colors = {
  hex: Color.hex,
  rgba: Color.rgba,
  fromRGBA: Color.fromRGBA,
  fromString: Color.fromString,

  Primary10D: Color.hex('#007284'),
  Primary: Color.hex('#1890ff'), // antd primary
  // Primary: Color.hex('#007F93'),
  Primary50L: Color.hex('#80BFC9'),
  Primary10L: Color.hex('#E6F3F5'),
  Primary5L: Color.hex('#F2F8F9'),

  Black: Color.hex('#000000'),
  Grey900: Color.hex('#212529'),
  Grey800: Color.hex('#343A40'),
  Grey700: Color.hex('#495057'),
  Grey600: Color.hex('#6C757D'),
  Grey500: Color.hex('#ADB5BD'),
  Grey400: Color.hex('#CED4DA'),
  Grey300: Color.hex('#DEE2E6'),
  Grey200: Color.hex('#E9ECEF'),
  Grey100: Color.hex('#F8F9FA'),
  White: Color.hex('#FFFFFF'),

  Success: Color.hex('#28A745'),
  Warning: Color.hex('#FFC107'),
  Danger: Color.hex('#DC3545'),
  Info: Color.hex('#007BFF'),

  Blue: Color.hex('#007BFF'),
  Indigo: Color.hex('#6610F2'),
  Purple: Color.hex('#6F42C1'),
  Pink: Color.hex('#E83E8C'),
  Red: Color.hex('#DC3545'),
  Orange: Color.hex('#FD7E14'),
  Yellow: Color.hex('#FADB14'),
  Green: Color.hex('#28A745'),
  Teal: Color.hex('#20C997'),
};

export default colors;

export type Colors = typeof colors;
