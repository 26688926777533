import React from 'react';

import { Select } from 'antd';
import { useTranslation } from 'react-i18next';

import { FilterOperatorComponent, FiltersContainer, FiltersTitle } from 'common-ui/filters';
import { ContactBookFilters, FilterOperator } from 'core/lib';
import { CountryOptions } from 'core/lib/constants/selects/general';

type InvestmentGeographyFilterProps = {
  onChange: (filters: Partial<ContactBookFilters>) => void;
  filters: string[] | undefined;
  operator: FilterOperator;
  operatorKey: string;
};

const InvestmentGeographyFilter = ({ onChange, filters, operator, operatorKey }: InvestmentGeographyFilterProps) => {
  const { t } = useTranslation();

  return (
    <FiltersContainer>
      <FiltersTitle title={t('contactBook:filters.investmentGeography')} />
      <FilterOperatorComponent onChange={onChange} operator={operator} filterKey={operatorKey} />
      <Select
        className="w-full"
        allowClear
        mode="multiple"
        value={filters}
        showSearch={false}
        placeholder={t('global:placeholders.selectType', { type: t('contactBook:filters.investmentGeography') })}
        onChange={(investmentGeography) => onChange({ investmentGeography })}
        options={CountryOptions}
      />
    </FiltersContainer>
  );
};

export default InvestmentGeographyFilter;
