import React from 'react';

import { Alert } from 'antd';
import { useTranslation } from 'react-i18next';

const { ErrorBoundary } = Alert;

type CustomErrorBoundaryProps = {
  children: React.ReactNode;
};

const CustomErrorBoundary = ({ children }: CustomErrorBoundaryProps) => {
  const { t } = useTranslation();
  return <ErrorBoundary message={t('global:error.componentError')}>{children}</ErrorBoundary>;
};

export default CustomErrorBoundary;
