import React from 'react';

import { FacebookFilled, LinkedinFilled } from '@ant-design/icons';
import { Typography } from 'antd';

import { ClientLogo } from 'common-ui';

const { Link } = Typography;

const ExternalNordicFooter = () => {
  return (
    <div className="flex grow-0 lg:flex-row flex-col justify-between items-center py-16 gap-8 md:gap-16">
      <ClientLogo height={45} />
      <div className="flex sm:flex-row flex-col gap-8 font-medium items-center">
        <div>Havnegade 55</div>
        <div>1058 Copenhagen K</div>
        <a href="mailto: info@nordiceye.com">info@nordiceye.com</a>
        <div className="flex gap-4 f4">
          <Link href="https://www.facebook.com/WeSpotWeAddWeExit">
            <FacebookFilled />
          </Link>
          <Link href="https://www.linkedin.com/company/nordic-eye/">
            <LinkedinFilled />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ExternalNordicFooter;
