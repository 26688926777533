import React from 'react';

import { InfoCircleOutlined } from '@ant-design/icons';
import { CheckboxProps, Input, InputNumber, InputNumberProps, RadioGroupProps, SelectProps } from 'antd';
import { RuleObject } from 'antd/lib/form';
import { TextAreaProps } from 'antd/lib/input';
import { Trans } from 'react-i18next';

import DatePicker, { PickerProps } from 'common-ui/datePicker';
import { CheckboxSelect, RadioGroupSelect } from 'common-ui/inputs';
import { CommonObject } from 'core/lib';
import { DYNAMIC, OBJECT_TYPES } from 'core/lib/constants';
import {
  OverallRiskPriorityOptions,
  PatientSafetyOptions,
  ProductQualityOptions,
  DataIntegrityOptions,
  ComplexityOptions,
  GampCategoryOptions,
  ConfidentialityOptions,
  IntegrityOptions,
  AvailabilityOptions,
} from 'core/lib/constants/selects/portfolio';
import { parseJSONDate } from 'core/lib/utils';
import { CommonObjectSelect, CommonObjectMultiSelect, OrganizationSelect, UserSelect } from 'utils/form';
import { rules } from 'utils/form/rules';

const { TextArea } = Input;

export const portfolioFormConfig = {
  generalInformation: {
    generalInformation: {
      name: {
        label: <Trans i18nKey="portfolio:form.generalInformation.labels.name" />,
        dataIndex: 'name',
        rules: [rules.required, rules.maxLength(200)],
        component: Input,
      },
      description: {
        label: <Trans i18nKey="portfolio:form.generalInformation.labels.description" />,
        dataIndex: 'description',
        rules: [rules.required, rules.maxLength(4000)],
        component: (props: TextAreaProps) => (
          <TextArea autoSize={{ minRows: 3, maxRows: 6 }} showCount={!props.disabled} maxLength={4000} {...props} />
        ),
      },
      type: {
        label: <Trans i18nKey="portfolio:form.generalInformation.labels.type" />,
        dataIndex: 'type',
        rules: [],
        component: (props: SelectProps<number> & { selectedOption?: CommonObject }) => (
          <CommonObjectSelect
            {...props}
            archived={false}
            asTag
            color="orange"
            type={DYNAMIC.PORTFOLIO.TYPE}
            objectType={OBJECT_TYPES.PORTFOLIO}
          />
        ),
      },
    },
    compliance: {
      complianceStatus: {
        label: <Trans i18nKey="portfolio:form.compliance.labels.complianceStatus" />,
        dataIndex: 'complianceStatus',
        rules: [],
        component: (props: SelectProps<number> & { selectedOption?: CommonObject }) => (
          <CommonObjectSelect
            {...props}
            archived={false}
            asTag
            color="green"
            type={DYNAMIC.PORTFOLIO.COMPLIANCE_STATUS}
            objectType={OBJECT_TYPES.PORTFOLIO}
          />
        ),
      },
      frequencyPeriodicReview: {
        label: <Trans i18nKey="portfolio:form.compliance.labels.frequencyPeriodicReview" />,
        dataIndex: 'frequencyPeriodicReview',
        rules: [],
        component: (props: InputNumberProps<number>) => (
          <InputNumber
            {...props}
            addonAfter={
              <span style={{ textTransform: 'lowercase' }}>
                <Trans i18nKey="global:period.month" values={{ count: props.value }} />
              </span>
            }
          />
        ),
      },
      initialValidationDate: {
        label: <Trans i18nKey="portfolio:form.compliance.labels.initialValidation" />,
        dataIndex: 'initialValidationDate',
        rules: [],
        component: (props: PickerProps<Date>) => (
          <DatePicker {...props} value={props.value && parseJSONDate(props.value)} />
        ),
      },
      lastPeriodReview: {
        label: <Trans i18nKey="portfolio:form.compliance.labels.lastPeriodicReview" />,
        dataIndex: 'lastPeriodReview',
        rules: [],
        component: (props: PickerProps<Date>) => (
          <DatePicker {...props} value={props.value && parseJSONDate(props.value)} />
        ),
      },
      comment: {
        label: <Trans i18nKey="portfolio:form.compliance.labels.comment" />,
        dataIndex: 'comment',
        rules: [rules.maxLength(4000)],
        component: (props: TextAreaProps) => (
          <TextArea autoSize={{ minRows: 3, maxRows: 6 }} showCount={!props.disabled} maxLength={4000} {...props} />
        ),
      },
    },
    ownership: {
      organization: {
        label: <Trans i18nKey="portfolio:form.ownership.labels.organization" />,
        dataIndex: 'organization',
        rules: [rules.required],
        component: OrganizationSelect,
      },
      owner: {
        tooltip: {
          title: <Trans i18nKey="portfolio:form.ownership.tooltip.organizationRequired" />,
          icon: <InfoCircleOutlined />,
        },
        label: <Trans i18nKey="portfolio:form.ownership.labels.owner" />,
        dataIndex: 'owner',
        rules: [rules.required],
        component: UserSelect,
      },
      responsible: {
        tooltip: {
          title: <Trans i18nKey="portfolio:form.ownership.tooltip.organizationRequired" />,
          icon: <InfoCircleOutlined />,
        },
        label: <Trans i18nKey="portfolio:form.ownership.labels.responsible" />,
        dataIndex: 'responsible',
        rules: [rules.required],
        component: UserSelect,
      },
      taskOwner: {
        tooltip: {
          title: <Trans i18nKey="portfolio:form.ownership.tooltip.organizationRequired" />,
          icon: <InfoCircleOutlined />,
        },
        label: <Trans i18nKey="portfolio:form.ownership.labels.taskOwner" />,
        dataIndex: 'taskOwner',
        rules: [rules.required],
        component: UserSelect,
      },
    },
    supplierInformation: {
      supplierCompany: {
        label: <Trans i18nKey="portfolio:form.supplierInformation.labels.supplierCompanyAndAddress" />,
        dataIndex: 'supplierCompany',
        rules: [rules.maxLength(4000)],
        component: (props: TextAreaProps) => (
          <TextArea autoSize={{ minRows: 3, maxRows: 6 }} showCount={!props.disabled} maxLength={4000} {...props} />
        ),
      },
      systemName: {
        label: <Trans i18nKey="portfolio:form.supplierInformation.labels.systemName" />,
        dataIndex: 'systemName',
        rules: [rules.maxLength(200)],
        component: Input,
      },
      systemVersion: {
        label: <Trans i18nKey="portfolio:form.supplierInformation.labels.systemVersion" />,
        dataIndex: 'systemVersion',
        rules: [rules.maxLength(200)],
        component: Input,
      },
      technology: {
        label: <Trans i18nKey="portfolio:form.supplierInformation.labels.technology" />,
        dataIndex: 'technology',
        rules: [],
        component: (props: SelectProps<number> & { selectedOption?: CommonObject }) => (
          <CommonObjectSelect
            {...props}
            archived={false}
            asTag
            color="geekblue"
            type={DYNAMIC.PORTFOLIO.TECHNOLOGY}
            objectType={OBJECT_TYPES.PORTFOLIO}
          />
        ),
      },
      supplierType: {
        label: <Trans i18nKey="portfolio:form.supplierInformation.labels.supplierType" />,
        dataIndex: 'supplierType',
        rules: [],
        component: (props: SelectProps<number> & { selectedOption?: CommonObject }) => (
          <CommonObjectSelect
            {...props}
            archived={false}
            asTag
            color="pink"
            type={DYNAMIC.PORTFOLIO.SUPPLIER_TYPE}
            objectType={OBJECT_TYPES.PORTFOLIO}
          />
        ),
      },
      language: {
        label: <Trans i18nKey="portfolio:form.supplierInformation.labels.supportLanguage" />,
        dataIndex: 'language',
        rules: [],
        component: (props: SelectProps<number[]> & { selectedOption?: CommonObject[] }) => (
          <CommonObjectMultiSelect
            {...props}
            archived={false}
            asTags
            color="volcano"
            type={DYNAMIC.PORTFOLIO.SUPPORT_LANGUAGE}
            objectType={OBJECT_TYPES.PORTFOLIO}
          />
        ),
      },
    },
  },
  productInformation: {
    intended: {
      usersGeography: {
        label: <Trans i18nKey="portfolio:form.productInformation.labels.usersGeography" />,
        dataIndex: 'usersGeography',
        rules: [rules.maxLength(4000)],
        component: (props: TextAreaProps) => (
          <TextArea autoSize={{ minRows: 3, maxRows: 5 }} showCount={!props.disabled} maxLength={4000} {...props} />
        ),
      },
      numberOfUsers: {
        label: <Trans i18nKey="portfolio:form.productInformation.labels.numberOfUsers" />,
        dataIndex: 'numberOfUsers',
        rules: [],
        component: (props: SelectProps<number> & { selectedOption?: CommonObject }) => (
          <CommonObjectSelect
            {...props}
            archived={false}
            asTag
            color="purple"
            type={DYNAMIC.PORTFOLIO.NUMBER_OF_USERS}
            objectType={OBJECT_TYPES.PORTFOLIO}
          />
        ),
      },
      frequencyUsed: {
        label: <Trans i18nKey="portfolio:form.productInformation.labels.frequencyUsed" />,
        dataIndex: 'frequencyUsed',
        rules: [],
        component: (props: SelectProps<number> & { selectedOption?: CommonObject }) => (
          <CommonObjectSelect
            {...props}
            archived={false}
            asTag
            color="cyan"
            type={DYNAMIC.PORTFOLIO.FREQUENCY_USED}
            objectType={OBJECT_TYPES.PORTFOLIO}
          />
        ),
      },
      location: {
        label: <Trans i18nKey="portfolio:form.productInformation.labels.hostingLocation" />,
        dataIndex: 'location',
        rules: [rules.maxLength(4000)],
        component: (props: TextAreaProps) => (
          <TextArea autoSize={{ minRows: 3, maxRows: 5 }} showCount={!props.disabled} maxLength={4000} {...props} />
        ),
      },
      expectedLifeTime: {
        label: <Trans i18nKey="portfolio:form.productInformation.labels.expectedSystemLifetime" />,
        dataIndex: 'expectedLifeTime',
        rules: [rules.maxLength(4000)],
        component: (props: TextAreaProps) => (
          <TextArea autoSize={{ minRows: 3, maxRows: 5 }} showCount={!props.disabled} maxLength={4000} {...props} />
        ),
      },
      businessProcesses: {
        label: <Trans i18nKey="portfolio:form.productInformation.labels.businessProcesses" />,
        dataIndex: 'businessProcesses',
        rules: [rules.maxLength(4000)],
        component: (props: TextAreaProps) => (
          <TextArea autoSize={{ minRows: 3, maxRows: 5 }} showCount={!props.disabled} maxLength={4000} {...props} />
        ),
      },
      dataType: {
        label: <Trans i18nKey="portfolio:form.productInformation.labels.dataType" />,
        dataIndex: 'dataType',
        rules: [rules.maxLength(4000)],
        component: (props: TextAreaProps) => (
          <TextArea autoSize={{ minRows: 3, maxRows: 5 }} showCount={!props.disabled} maxLength={4000} {...props} />
        ),
      },
      businessUnitUsage: {
        label: <Trans i18nKey="portfolio:form.productInformation.labels.businessUnits" />,
        dataIndex: 'businessUnitUsage',
        rules: [rules.maxLength(4000)],
        component: (props: TextAreaProps) => (
          <TextArea autoSize={{ minRows: 3, maxRows: 5 }} showCount={!props.disabled} maxLength={4000} {...props} />
        ),
      },
      licensedUsers: {
        label: <Trans i18nKey="portfolio:form.productInformation.labels.licensedUsers" />,
        dataIndex: 'licensedUsers',
        rules: [rules.maxLength(4000)],
        component: (props: TextAreaProps) => (
          <TextArea autoSize={{ minRows: 3, maxRows: 5 }} showCount={!props.disabled} maxLength={4000} {...props} />
        ),
      },
      users: {
        label: <Trans i18nKey="portfolio:form.productInformation.labels.users" />,
        dataIndex: 'users',
        rules: [rules.maxLength(4000)],
        component: (props: TextAreaProps) => (
          <TextArea autoSize={{ minRows: 3, maxRows: 5 }} showCount={!props.disabled} maxLength={4000} {...props} />
        ),
      },
    },
    classification: {
      businessCritical: {
        label: <Trans i18nKey="portfolio:form.classification.labels.businessCritical" />,
        dataIndex: ['classification', 'businessCritical'],
        rules: [],
        component: (props: CheckboxProps) => (
          <CheckboxSelect
            {...props}
            label={<Trans i18nKey="portfolio:form.classification.labels.businessCritical" />}
          />
        ),
      },
      eres: {
        label: <Trans i18nKey="portfolio:form.classification.labels.eres" />,
        dataIndex: ['classification', 'eres'],
        rules: [],
        component: (props: CheckboxProps) => (
          <CheckboxSelect {...props} label={<Trans i18nKey="portfolio:form.classification.labels.eres" />} />
        ),
      },
      legal: {
        label: <Trans i18nKey="portfolio:form.classification.labels.legal" />,
        dataIndex: ['classification', 'legal'],
        rules: [],
        component: (props: CheckboxProps) => (
          <CheckboxSelect {...props} label={<Trans i18nKey="portfolio:form.classification.labels.legal" />} />
        ),
      },
      informationSecurity: {
        label: <Trans i18nKey="portfolio:form.classification.labels.informationSecurity" />,
        dataIndex: ['classification', 'informationSecurity'],
        rules: [],
        component: (props: CheckboxProps) => (
          <CheckboxSelect
            {...props}
            label={<Trans i18nKey="portfolio:form.classification.labels.informationSecurity" />}
          />
        ),
      },
      other: {
        label: <Trans i18nKey="portfolio:form.classification.labels.other" />,
        dataIndex: ['classification', 'other'],
        rules: [],
        component: (props: CheckboxProps) => (
          <CheckboxSelect {...props} label={<Trans i18nKey="portfolio:form.classification.labels.other" />} />
        ),
      },
      gxp: {
        label: <Trans i18nKey="portfolio:form.classification.labels.gxp" />,
        dataIndex: ['classification', 'gxp'],
        rules: [],
        component: (props: CheckboxProps) => (
          <CheckboxSelect {...props} label={<Trans i18nKey="portfolio:form.classification.labels.gxp" />} />
        ),
      },
      sox: {
        label: <Trans i18nKey="portfolio:form.classification.labels.sox" />,
        dataIndex: ['classification', 'sox'],
        rules: [],
        component: (props: CheckboxProps) => (
          <CheckboxSelect {...props} label={<Trans i18nKey="portfolio:form.classification.labels.sox" />} />
        ),
      },
      dataPrivacy: {
        label: <Trans i18nKey="portfolio:form.classification.labels.dataPrivacy" />,
        dataIndex: ['classification', 'dataPrivacy'],
        rules: [],
        component: (props: CheckboxProps) => (
          <CheckboxSelect {...props} label={<Trans i18nKey="portfolio:form.classification.labels.dataPrivacy" />} />
        ),
      },
      sdg: {
        label: <Trans i18nKey="portfolio:form.classification.labels.sdg" />,
        dataIndex: ['classification', 'sdg'],
        rules: [],
        component: (props: CheckboxProps) => (
          <CheckboxSelect {...props} label={<Trans i18nKey="portfolio:form.classification.labels.sdg" />} />
        ),
      },
      patientSafety: {
        label: <Trans i18nKey="portfolio:form.classification.labels.patientSafety" />,
        dataIndex: 'patientSafety',
        rules: [],
        component: (props: RadioGroupProps) => <RadioGroupSelect {...props} options={PatientSafetyOptions} />,
      },
      productQuality: {
        label: <Trans i18nKey="portfolio:form.classification.labels.productQuality" />,
        dataIndex: 'productQuality',
        rules: [],
        component: (props: RadioGroupProps) => <RadioGroupSelect {...props} options={ProductQualityOptions} />,
      },
      dataIntegrity: {
        label: <Trans i18nKey="portfolio:form.classification.labels.dataIntegrity" />,
        dataIndex: 'dataIntegrity',
        rules: [],
        component: (props: RadioGroupProps) => <RadioGroupSelect {...props} options={DataIntegrityOptions} />,
      },
      complexity: {
        label: <Trans i18nKey="portfolio:form.classification.labels.complexity" />,
        dataIndex: 'complexity',
        rules: [],
        component: (props: RadioGroupProps) => <RadioGroupSelect {...props} options={ComplexityOptions} />,
      },
      overallRiskPriority: {
        label: <Trans i18nKey="portfolio:form.classification.labels.overallRiskPriority" />,
        dataIndex: 'overallRiskPriority',
        rules: [],
        component: (props: RadioGroupProps) => <RadioGroupSelect {...props} options={OverallRiskPriorityOptions} />,
      },
      gamp5Category: {
        label: <Trans i18nKey="portfolio:form.classification.labels.gamp5Category" />,
        dataIndex: 'gamp5Category',
        rules: [],
        component: (props: RadioGroupProps) => <RadioGroupSelect {...props} options={GampCategoryOptions} />,
      },
      confidentiality: {
        label: <Trans i18nKey="portfolio:form.classification.labels.confidentiality" />,
        dataIndex: 'confidentiality',
        rules: [],
        component: (props: RadioGroupProps) => <RadioGroupSelect {...props} options={ConfidentialityOptions} />,
      },
      integrity: {
        label: <Trans i18nKey="portfolio:form.classification.labels.integrity" />,
        dataIndex: 'integrity',
        rules: [],
        component: (props: RadioGroupProps) => <RadioGroupSelect {...props} options={IntegrityOptions} />,
      },
      availability: {
        label: <Trans i18nKey="portfolio:form.classification.labels.availability" />,
        dataIndex: 'availability',
        rules: [],
        component: (props: RadioGroupProps) => <RadioGroupSelect {...props} options={AvailabilityOptions} />,
      },
    },
  },
};

export const portfolioFields = Object.values(portfolioFormConfig)
  .flatMap((section): { dataIndex: string; label: string }[] => {
    return Object.values(section).flatMap((sectionFields) => Object.values(sectionFields));
  })
  .reduce((fields, field) => {
    fields[field.dataIndex] = field.label;
    return fields;
  }, {});

export const mandatoryTabFields: { [key: string]: { [key: string]: string[] } } = Object.entries(
  portfolioFormConfig
).reduce((agg, [tabKey, tabObject]) => {
  const requiredSections = Object.keys(tabObject).reduce((aggSection, sectionKey) => {
    const sectionObject = tabObject[sectionKey];
    const requiredFields: string[] = [];

    Object.keys(sectionObject).forEach((oKey) => {
      const fieldObject = sectionObject[oKey];
      if (fieldObject?.rules?.some((r: RuleObject) => r.required)) {
        requiredFields.push(fieldObject.dataIndex);
      }
    });
    if (requiredFields.length) {
      aggSection[sectionKey] = requiredFields;
    }
    return aggSection;
  }, {});
  if (Object.keys(requiredSections).length) {
    agg[tabKey] = requiredSections;
  }
  return agg;
}, {});
