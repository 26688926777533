import React from 'react';

import { Select, SelectProps, Tag } from 'antd';
import { PresetColorType } from 'antd/lib/_util/colors';

export function objectSelector<T extends { id: number }>({
  selectedOption,
  ...props
}: SelectProps<number> & { selectedOption?: T }): JSX.Element {
  return (
    <Select
      showSearch
      optionFilterProp="label"
      {...props}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onChange={(_val, option: any) => {
        props.onChange?.(option, option);
      }}
      value={selectedOption?.id}
    />
  );
}

export function objectMultiSelector<T extends { id: number; name?: string }>({
  selectedOption,
  max,
  options,
  asTags,
  color,
  ...props
}: SelectProps<number[]> & {
  max?: number;
  selectedOption?: T[];
  asTags?: boolean;
  color?: PresetColorType;
}) {
  if (props.disabled && asTags && selectedOption?.length) {
    return (
      <div className="flex flex-wrap gap-2">
        {selectedOption?.map((item) => (
          <Tag className="m-0" key={item.id} color={color}>
            {item.name}
          </Tag>
        ))}
      </div>
    );
  }
  const value = selectedOption?.map((so) => so.id) ?? [];
  const selectOptions =
    options?.map((opt) => {
      if (max && (selectedOption?.length ?? 0) >= max) {
        return { ...opt, disabled: !value?.includes(opt.id) };
      }
      return opt;
    }) ??
    selectedOption?.map((opt) => ({
      value: opt.id,
      label: opt.name,
    })) ??
    [];

  return (
    <Select
      showSearch
      allowClear
      optionFilterProp="label"
      mode="multiple"
      value={value}
      options={selectOptions}
      {...props}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onChange={(_val, option: any) => {
        props.onChange?.(option, option);
      }}
    />
  );
}

export function rolesMultiSelector<T extends { id: number; name: string }>(
  props: SelectProps<number[]> & { selectedOption?: T[] }
) {
  return props.disabled && props.selectedOption?.length ? (
    <div className="flex flex-wrap gap-y-2 gap-1">
      {props.selectedOption.map((role) => {
        return (
          <Tag key={role.id} color="green">
            {role.name}
          </Tag>
        );
      })}
    </div>
  ) : (
    objectMultiSelector({ ...props, mode: 'multiple' })
  );
}
