import React, { useEffect, useMemo, useState } from 'react';

import { Button, Form } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { once, pick } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Prompt } from 'react-router-dom';

import { ContentContainer, MainContentContainer } from 'common-ui/containers';
import { DetailsSectionWrapper } from 'common-ui/wrappers';
import { MenuPreference, RouteTypes, selectClientConfiguration, useAppSelector, userPreferencesApi } from 'core/lib';
import { useMenuItems } from 'utils/hooks';
import LoaderWrapper from 'utils/loader-wrapper';

import { userProfileFormConfig } from '../formConfig';
import { getRouteOptions } from '../utils';

const { preferences } = userProfileFormConfig;

const PreferencesTab = () => {
  const [formDirty, setFormDirty] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const { t } = useTranslation();
  const [form] = useForm();
  const clientConfiguration = useAppSelector(selectClientConfiguration);
  const { data, isLoading: fetchingPreferences } = userPreferencesApi.useGetPreferencesQuery();
  const [saveUserPreferences, { isLoading: isSaving, isSuccess }] =
    userPreferencesApi.useUpdateUserPreferencesMutation();
  const menuPreferences = useMenuItems(clientConfiguration);

  const routeOptions = useMemo(() => getRouteOptions(clientConfiguration), [clientConfiguration]);

  const initialFormData = useMemo(() => {
    if (data) {
      const { homepageObjectType, homepageType, ...rest } = data;
      const homepage = [homepageObjectType, homepageType];
      return { ...rest, homepage, menuPreferences };
    }
    return { menuPreferences };
  }, [data, menuPreferences]);

  const onFormChange = once(() => {
    setFormDirty(true);
  });

  const onFinish = (values: {
    homepage?: [number, RouteTypes.DASHBOARD | RouteTypes.LISTING];
    leftMenuOpen: boolean;
  }) => {
    const { homepage, ...rest } = values;
    const [homepageObjectType, homepageType] = values.homepage ?? [data?.homepageObjectType, data?.homepageType];
    saveUserPreferences({ ...data, homepageObjectType, homepageType, ...rest });
  };

  useEffect(() => {
    if (isSuccess) {
      setFormDirty(false);
      setEditMode(false);
    }
  }, [isSuccess]);

  const onEditClick = () => {
    setEditMode(true);
  };

  useEffect(() => {
    form.resetFields();
  }, [menuPreferences]); //eslint-disable-line react-hooks/exhaustive-deps

  const onCancelClick = () => {
    form.resetFields();
    setFormDirty(false);
    setEditMode(false);
  };

  return (
    <LoaderWrapper loading={fetchingPreferences || isSaving}>
      <Prompt when={formDirty} message={t('global:navigation.unsavedChanges')} />
      <Form
        form={form}
        layout="vertical"
        name="preferences_user_profile"
        onValuesChange={onFormChange}
        onFinish={onFinish}
        initialValues={initialFormData}
      >
        <MainContentContainer id="profile-settings-preferences">
          <ContentContainer>
            <DetailsSectionWrapper>
              <div className="flex flex-col gap-4">
                <div className="flex w-full justify-end">
                  {!editMode ? (
                    <Button onClick={onEditClick}>{t('actions:global.edit')}</Button>
                  ) : (
                    <div className="flex gap-4">
                      <Button type="primary" htmlType="submit" disabled={!formDirty}>
                        {t('actions:global.save')}
                      </Button>
                      <Button onClick={onCancelClick}>{t('actions:global.cancel')}</Button>
                    </div>
                  )}
                </div>
                <div className="flex flex-col">
                  <div className="flex sm:flex-row flex-col gap-x-4">
                    <Form.Item
                      className="md:w-3/6"
                      label={t(preferences.homepage.label)}
                      name={preferences.homepage.dataIndex}
                      rules={preferences.homepage.rules}
                    >
                      <preferences.homepage.component options={routeOptions} disabled={!editMode} />
                    </Form.Item>
                    <Form.Item
                      className="md:w-3/6"
                      label={t(preferences.menuDefaultType.label)}
                      name={preferences.menuDefaultType.dataIndex}
                      rules={preferences.menuDefaultType.rules}
                    >
                      <preferences.menuDefaultType.component className="w-full" disabled={!editMode} />
                    </Form.Item>
                  </div>
                  <div className="flex sm:flex-row flex-col gap-x-4">
                    <Form.Item
                      className="md:w-3/6"
                      label={t(preferences.leftMenu.label)}
                      name={preferences.leftMenu.dataIndex}
                      rules={preferences.leftMenu.rules}
                    >
                      <preferences.leftMenu.component className="w-full" disabled={!editMode} />
                    </Form.Item>
                    <Form.Item
                      className="md:w-3/6"
                      label={t(preferences.language.label)}
                      name={preferences.language.dataIndex}
                      rules={preferences.language.rules}
                    >
                      <preferences.language.component className="w-full" disabled={!editMode} />
                    </Form.Item>
                  </div>
                  <div className="flex sm:flex-row flex-col gap-x-4">
                    <Form.Item
                      className="md:w-3/6"
                      valuePropName="menu"
                      label={t(preferences.menuPreferences.label)}
                      name={preferences.menuPreferences.dataIndex}
                      rules={preferences.menuPreferences.rules}
                      normalize={(preferences: MenuPreference[]) => {
                        const keys: Array<keyof (MenuPreference & { name: string })> = [
                          'id',
                          'name',
                          'displayOrder',
                          'menuId',
                          'objectType',
                        ];
                        return preferences.map((pref) => pick(pref, keys));
                      }}
                    >
                      <preferences.menuPreferences.component disabled={!editMode} />
                    </Form.Item>
                  </div>
                </div>
              </div>
            </DetailsSectionWrapper>
          </ContentContainer>
        </MainContentContainer>
      </Form>
    </LoaderWrapper>
  );
};

export default PreferencesTab;
