import React from 'react';

import { Select } from 'antd';
import { useTranslation } from 'react-i18next';

import { FiltersContainer, FiltersTitle } from 'common-ui/filters';
import { ContactBookFilters } from 'core/lib';
import { RegionOptions } from 'core/lib/constants/selects';

type RegionFilterProps = {
  onChange: (filters: Partial<ContactBookFilters>) => void;
  filters: string[] | undefined;
};

const RegionFilter = ({ onChange, filters }: RegionFilterProps) => {
  const { t } = useTranslation();

  return (
    <FiltersContainer>
      <FiltersTitle title={t('contactBook:filters.region')} />
      <Select
        mode="multiple"
        className="w-full"
        allowClear
        showSearch={false}
        options={RegionOptions}
        value={filters}
        placeholder={t('global:placeholders.selectType', { type: t('contactBook:filters.region') })}
        onChange={(region) => onChange({ region })}
      />
    </FiltersContainer>
  );
};

export default RegionFilter;
