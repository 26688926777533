import { UserDetails, OrganizationShort, BaseUser } from 'core/lib';
import { UserStatus } from 'core/lib/constants/statuses';
import { OutOfOffice, UserRole } from 'core/lib/modules/users';

class UserBuilder {
  private readonly _user: UserDetails;

  constructor() {
    this._user = {
      // General Information - Login Credentials
      id: 0,
      email: '',
      name: '',

      // General Information - User privileges
      jobTitle: '',
      organizations: [],
      status: 'active',
      systemRoleId: 0,
      systemRoles: [],
      locked: false,

      // General Information - Personal Information
      firstName: '',
      lastName: '',
      country: '',
      phone: '',

      //General Information - Out of Office
      outOfOffice: {},
      delegate: null,

      //Documents
      documentTemplateDocument: null,
      accessFormDocument: null,
      delegationFormDocument: null,
      trainingPlanDocument: null,
    };
  }

  build() {
    return JSON.parse(JSON.stringify(this._user)) as UserDetails;
  }

  id(id: number) {
    this._user.id = id;
    return this;
  }

  email(email: string) {
    this._user.email = email;
    return this;
  }

  jobTitle(jobTitle: string) {
    this._user.jobTitle = jobTitle;
    return this;
  }
  organizations(organizations: OrganizationShort[]) {
    this._user.organizations = organizations;
    return this;
  }
  systemRoles(systemRoles: UserRole[]) {
    this._user.systemRoles = systemRoles;
    return this;
  }
  status(status: UserStatus) {
    this._user.status = status;
    return this;
  }
  roles(systemRoleId: number) {
    this._user.systemRoleId = systemRoleId;
    return this;
  }
  firstName(firstName: string) {
    this._user.firstName = firstName;
    return this;
  }
  lastName(lastName: string) {
    this._user.lastName = lastName;
    return this;
  }
  country(country: string) {
    this._user.country = country;
    return this;
  }
  phone(phone: string) {
    this._user.phone = phone;
    return this;
  }
  outOfOffice(ooOffice: OutOfOffice) {
    this._user.outOfOffice = ooOffice;
    return this;
  }
  delegate(delegate: BaseUser | null) {
    this._user.delegate = delegate;
    return this;
  }
}

export default UserBuilder;
