import colors from 'core/lib/theme/colors';

import { GetStatusCurrentUserResponse } from '../../users';
import {
  AuthenticatedUser,
  AuthenticatedUserResponse,
  ClientConfiguration,
  ClientConfigurationResponse,
  LoginResponse,
  LoginSuccess,
} from '../entities';

export const deserializeGetStatusUser = (response: GetStatusCurrentUserResponse): AuthenticatedUser => {
  const name = `${response.FirstName} ${response.LastName}`;

  return {
    id: response.ID,
    firstName: response.FirstName,
    lastName: response.LastName,
    active: response.Active,
    name,
    systemRoleId: response.Role,
    avatarId: response.AvatarID,
    color: colors.fromString(name),
    email: response.Email,
  };
};

export const deserializeAuthenticatedUser = (response: AuthenticatedUserResponse): AuthenticatedUser => {
  const name = `${response.FirstName} ${response.LastName}`;
  return {
    id: response.ID,
    firstName: response.FirstName,
    lastName: response.LastName,
    active: response.Active,
    name,
    systemRoleId: response.SystemRole,
    avatarId: response.AvatarID,
    color: colors.fromString(name),
    email: response.Email,
  };
};

export const deserializeClientConfiguration = (response: ClientConfigurationResponse): ClientConfiguration => {
  return {
    currentClientId: response.CurrentClientID,
    clientOrganization: response.ClientOrganization,
    capaEnabled: response.CAPA_Enabled,
    changeEnabled: response.Change_Enabled,
    clientBaseEnabled: response.ClientBase_Enabled,
    complaintEnabled: response.Complaint_Enabled,
    configurationEnabled: response.Configuration_Enabled,
    dealBaseEnabled: response.DealBase_Enabled,
    deviationEnabled: response.Deviation_Enabled,
    dmsEnabled: response.DMS_Enabled,
    incidentEnabled: response.Incident_Enabled,
    portfolioEnabled: response.Inventory_Enabled,
    knowledgeBaseEnabled: response.KB_Enabled,
    projectEnabled: response.Project_Enabled,
    riskEnabled: response.RiskManagement_Enabled,
    specificationEnabled: response.SpecificationManagement_Enabled,
    testEnabled: response.Test_Enabled,
    trainingEnabled: response.Training_Enabled,
    contactBookEnabled: response.ContactBook_Enabled,
  };
};

export const deserializeLoginResponse = (response: LoginResponse): LoginSuccess => {
  return {
    token: response.access_token,
    user: deserializeAuthenticatedUser(JSON.parse(response['as:CurrentUser']) as AuthenticatedUserResponse),
    clientConfiguration: deserializeClientConfiguration(
      JSON.parse(response['as:client_configuration']) as ClientConfigurationResponse
    ),
  };
};
