import React, { useEffect, useState } from 'react';

import { Button, Card, Form, message } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { once } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Prompt } from 'react-router-dom';

import { Organization, UserOption } from 'core/lib';
import { organizationApi } from 'core/lib/modules/organization/services';
import OrganizationBuilder from 'core/lib/utils/builders/OrganizationBuilder';

import { organizationFormConfig } from '../form/formConfig';

type OrganizationCardProps = {
  organization?: Partial<Organization>;
  onCancel: () => void;
};

const OrganizationEditCard = ({ organization, onCancel }: OrganizationCardProps) => {
  const [formDirty, setFormDirty] = useState(false);
  const [draftOrganization] = useState(organization ?? new OrganizationBuilder().build());

  const [form] = useForm();
  const { t } = useTranslation();

  const [createOrganization, { isLoading: createOrganizationLoading, error: createError }] =
    organizationApi.useCreateMutation();
  const [updateOrganization, { isLoading: updateOrganizationLoading, error: updateError }] =
    organizationApi.useUpdateMutation();

  const onSaveClick = () => {
    form.submit();
  };

  const onCancelClick = () => {
    form.resetFields();
    onCancel();
  };

  useEffect(() => {
    if (createError) {
      const errorData = createError as { data: { ExceptionMessage: string } };
      message.error(errorData.data.ExceptionMessage);
    }
  }, [createError]);

  useEffect(() => {
    if (updateError) {
      const errorData = updateError as { data: { ExceptionMessage: string } };
      message.error(errorData.data.ExceptionMessage);
    }
  }, [updateError]);

  const onFinish = (values: Organization) => {
    const currentValues = { ...draftOrganization, ...values };
    if (currentValues?.id) {
      updateOrganization(currentValues);
    } else {
      createOrganization(currentValues);
    }
  };

  const onFormChange = once(() => {
    setFormDirty(true);
  });

  const EditActions = [
    <div key="actions" className="flex justify-end gap-4 px-4">
      <Button
        key="save"
        type="primary"
        loading={createOrganizationLoading || updateOrganizationLoading}
        onClick={onSaveClick}
      >
        {t('actions:global.save')}
      </Button>
      <Button key="cancel" type="default" onClick={onCancelClick}>
        {t('actions:global.cancel')}
      </Button>
    </div>,
  ];

  return (
    <Card actions={EditActions}>
      <Prompt when={formDirty} message={t('global:navigation.unsavedChanges')} />
      <Form
        form={form}
        layout="vertical"
        name="organizationCard"
        onChange={onFormChange}
        onFinish={onFinish}
        initialValues={draftOrganization}
      >
        <Form.Item
          label={organizationFormConfig.name.label}
          name={organizationFormConfig.name.dataIndex}
          rules={organizationFormConfig.name.rules}
        >
          <organizationFormConfig.name.component />
        </Form.Item>
        <Form.Item
          label={organizationFormConfig.users.label}
          name={organizationFormConfig.users.dataIndex}
          valuePropName="selectedOption"
          normalize={(value: UserOption[]) => {
            return value.map((v) => ({ id: v.value, name: v.label }));
          }}
          rules={organizationFormConfig.users.rules}
        >
          <organizationFormConfig.users.component allowClear asAvatar />
        </Form.Item>
      </Form>
    </Card>
  );
};

export default OrganizationEditCard;
