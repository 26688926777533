import React from 'react';

import { BellOutlined, LockOutlined, SlidersOutlined, UserOutlined } from '@ant-design/icons';
import { PageHeader, Tabs, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import { PreferencesTab, GeneralTab, NotificationsTab, PasswordTab, userProfileTabKeys } from './tabs';

const { Title } = Typography;

interface LocationState {
  activeTabKey?: string;
}

const UserProfile = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { state } = useLocation<LocationState>();

  const onPageHeaderBackClick = () => {
    history.goBack();
  };

  return (
    <>
      <PageHeader
        className="listing-header"
        onBack={onPageHeaderBackClick}
        title={
          <Title style={{ margin: 0 }} level={4}>
            {t('global:moduleNames.profileSettings')}
          </Title>
        }
      />
      <Tabs
        defaultActiveKey={state?.activeTabKey}
        size="small"
        type="line"
        className="h-full overflow-y-auto tabs-centered"
        destroyInactiveTabPane
        renderTabBar={(props, DefaultTabsBar) => (
          <div className="px-4">
            <DefaultTabsBar {...props} />
          </div>
        )}
        items={[
          {
            key: userProfileTabKeys.general,
            className: 'h-full',
            label: (
              <div>
                <UserOutlined />
                {t('user:userProfileTabs.general')}
              </div>
            ),
            children: <GeneralTab />,
          },
          {
            key: userProfileTabKeys.password,
            className: 'h-full',
            label: (
              <div>
                <LockOutlined />
                {t('user:userProfileTabs.password')}
              </div>
            ),
            children: <PasswordTab />,
          },
          {
            key: userProfileTabKeys.preferences,
            className: 'h-full',
            label: (
              <div>
                <SlidersOutlined />
                {t('user:userProfileTabs.preferences')}
              </div>
            ),
            children: <PreferencesTab />,
          },
          {
            key: userProfileTabKeys.notifications,
            className: 'h-full',
            label: (
              <div>
                <BellOutlined />
                {t('user:userProfileTabs.notifications')}
              </div>
            ),
            children: <NotificationsTab />,
          },
        ]}
      />
    </>
  );
};

export default UserProfile;
