import React from 'react';

import {
  closestCenter,
  DndContext,
  DndContextProps,
  DragOverlay,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis, restrictToParentElement } from '@dnd-kit/modifiers';
import { SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable';

import ItemOverlay from './sortableItemOverlay';

type DnDWrapperProps = {
  draggableItemsIds: UniqueIdentifier[];
  children: React.ReactNode;
  disabled?: boolean;
  overlay?: React.ReactElement | string | null;
};

const DnDWrapper = ({ draggableItemsIds, disabled, children, overlay, ...rest }: DnDWrapperProps & DndContextProps) => {
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 250,
        tolerance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );
  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      modifiers={[restrictToVerticalAxis, restrictToParentElement]}
      autoScroll={{ layoutShiftCompensation: false }}
      {...rest}
    >
      <SortableContext disabled={disabled} items={draggableItemsIds} strategy={verticalListSortingStrategy}>
        {children}
      </SortableContext>
      <DragOverlay modifiers={[restrictToVerticalAxis, restrictToParentElement]}>
        {overlay ? <ItemOverlay>{overlay}</ItemOverlay> : null}
      </DragOverlay>
    </DndContext>
  );
};

export default DnDWrapper;
