import React from 'react';

import { useMedia } from 'react-use';

import { ListingActions } from 'modules/portfolio/components';

import FiltersModal from './modal';
import OrganizationFilter from './organizationFilter';
import StatusFilters from './statusFilter';

const ListingPortfolioFilters = () => {
  const isMobile = useMedia('(max-width: 768px)');

  return (
    <div className="flex flex-row justify-between items-center">
      <div className="flex flex-row gap-2">
        {!isMobile && (
          <div className="flex flex-row gap-2">
            <StatusFilters />
            <OrganizationFilter />
          </div>
        )}
        <FiltersModal />
      </div>
      <ListingActions />
    </div>
  );
};

export default ListingPortfolioFilters;
