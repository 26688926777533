import { textSorter } from '../../utils/sorters';
import { ContactBookConnectionType, ContactBookDiligenceType, ContactBookType } from '../contactBook';

const _TypeOptions: { value: ContactBookType; label: string }[] = [
  {
    value: 'AssetManager',
    label: 'Asset manager',
  },
  {
    value: 'VentureCapital',
    label: 'Venture capital',
  },
  {
    value: 'FamilyOffice',
    label: 'Family office',
  },
  {
    value: 'PrivateInvestor',
    label: 'Private investor',
  },
  {
    value: 'FundOfFunds',
    label: 'Fund-of-Funds',
  },
  {
    value: 'PensionFund',
    label: 'Pension fund',
  },
  {
    value: 'InvestmentBank',
    label: 'Investment bank',
  },
  {
    value: 'Fundraiser',
    label: 'Fundraiser',
  },
  {
    value: 'IndustryExpert',
    label: 'Industry expert',
  },
  {
    value: 'DiligencePartner',
    label: 'Diligence partner',
  },
];

export const TypeOptions = _TypeOptions.sort((a, b) => textSorter(a.label, b.label));

export const ContactBookTypesByValueKey = TypeOptions.reduce((obj, opt) => {
  obj[opt.value] = opt.label;
  return obj;
}, {});

export const ConnectionOptions: { value: ContactBookConnectionType; label: string }[] = [
  {
    value: 'Weak',
    label: 'Weak',
  },
  {
    value: 'Moderate',
    label: 'Moderate',
  },
  {
    value: 'Strong',
    label: 'Strong',
  },
];

export const DiligenceTypeOptions: { value: ContactBookDiligenceType; label: string }[] = [
  {
    value: 'Financial',
    label: 'Financial',
  },
  {
    value: 'Legal',
    label: 'Legal',
  },
];
