import React from 'react';

import { SearchOutlined } from '@ant-design/icons';
import { Button, Collapse } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link, matchPath, useLocation } from 'react-router-dom';
import { useMedia } from 'react-use';

import { setLeftMenuToggled, useAppDispatch } from 'core/lib';
import { Routes } from 'routes/routes';

import DirectConversationsMenu from './directConversationsMenu';
import GroupConversationsMenu from './groupConversationsMenu';
import ModuleConversationsMenu from './moduleConversationsMenu';
import { LeftMenuHeader } from '../components';

const { Panel } = Collapse;

const MENU_KEYS = {
  moduleConversation: 'moduleConversation',
  groupConversation: 'groupConversation',
  directConversation: 'directConversation',
};

const ConversationLeftMenu = () => {
  const { t } = useTranslation();
  const isTablet = useMedia('(max-width: 1023px)');
  const dispatch = useAppDispatch();

  const location = useLocation();

  const isListing = !!matchPath(location.pathname, { path: Routes.CONVERSATIONS_LISTING.path, exact: true });

  const toggleMobileLeftMenu = () => {
    if (isTablet) {
      dispatch(setLeftMenuToggled(false));
    }
  };

  return (
    <div className="flex h-full flex-col p-4 pr-0 overflow-auto">
      <Link to={Routes.CONVERSATIONS_LISTING.path} onClick={toggleMobileLeftMenu}>
        <div className="pr-4">
          <Button
            icon={<SearchOutlined />}
            className={`h-auto text-left w-full gap-2 py-2 px-4 ${
              isListing ? 'text-primary bg-gray-50' : 'text-black'
            }`}
            type="text"
          >
            {t('conversations:menu.navigation.browseConversations')}
          </Button>
        </div>
      </Link>
      <Collapse defaultActiveKey={Object.values(MENU_KEYS)} ghost className="compact">
        <Panel
          forceRender
          header={<LeftMenuHeader title={t('conversations:menu.headers.conversations')} />}
          key={MENU_KEYS.moduleConversation}
        >
          <ModuleConversationsMenu />
        </Panel>
        <Panel
          forceRender
          header={<LeftMenuHeader title={t('conversations:menu.headers.groups')} />}
          key={MENU_KEYS.groupConversation}
        >
          <GroupConversationsMenu />
        </Panel>
        <Panel
          forceRender
          header={<LeftMenuHeader title={t('conversations:menu.headers.directMessages')} />}
          key={MENU_KEYS.directConversation}
        >
          <DirectConversationsMenu />
        </Panel>
      </Collapse>
    </div>
  );
};

export default ConversationLeftMenu;
