import React, { useEffect, useMemo, useState } from 'react';

import { FilterFilled } from '@ant-design/icons';
import { Badge, Button, Divider, Modal } from 'antd';
import { isEqual, mapValues, omit } from 'lodash';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps, useSelector } from 'react-redux';
import { useMedia } from 'react-use';

import { CommonObjectFilter } from 'common-ui/filters';
import { AppState } from 'core/lib';
import { DYNAMIC, OBJECT_TYPES } from 'core/lib/constants';
import { DealBaseFilters } from 'core/lib/modules/dealBase/entities';
import {
  selectDealBaseFilters,
  setDealBaseFilters,
  selectFiltersCount,
  clearDealBaseFilters,
} from 'core/lib/modules/dealBase/store';

import AssignedUsersFilter from './assignedUsersFilter';
// import CountryFilter from './countryFilter';
// import FollowUpDateFilter from './followUpDateFilter';
// import OrganizationFilter from './organizationFilter';
// import PriorityFilter from './priorityFilter';
// import ResultingScoreFilter from './resultingScoreFilter';
import StatusFilter from './statusFilter';
import TierFilter from './tierFilter';

const mapState = (state: AppState) => ({
  filters: selectDealBaseFilters(state),
});

const mapDispatch = {
  applyFilters: setDealBaseFilters,
  clearFilters: clearDealBaseFilters,
};

const connector = connect(mapState, mapDispatch);

type StatusFiltersProps = ConnectedProps<typeof connector>;

const ListingFilters = ({ applyFilters, clearFilters, filters }: StatusFiltersProps) => {
  const [draftFilters, setDraftFilters] = useState<Partial<DealBaseFilters>>(filters);
  const [modalOpen, toggleModal] = useState(false);

  const isMobile = useMedia('(max-width: 768px)');
  const { t } = useTranslation();
  const filtersCount = useSelector(selectFiltersCount(isMobile));

  useEffect(() => {
    setDraftFilters(filters);
  }, [filters, modalOpen]);

  const showModal = () => {
    toggleModal(true);
  };

  const handleOk = () => {
    applyFilters(draftFilters);
    toggleModal(false);
  };

  const clearFilterKeys = useMemo(() => {
    if (isMobile) {
      return Object.keys(filters);
    }
    return Object.keys(omit(filters, ['status', 'assigned']));
  }, [isMobile, filters]);

  const onClearModalClear = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setDraftFilters(mapValues(filters, (value, key) => (clearFilterKeys.includes(key) ? undefined : (value as any))));
  };

  const onClear = () => {
    clearFilters();
  };

  const onCancel = () => {
    toggleModal(false);
  };

  const onFiltersChange = (partialFilters: Partial<DealBaseFilters>) => {
    setDraftFilters({ ...draftFilters, ...partialFilters });
  };

  const filtersUpdated = useMemo(() => !isEqual(draftFilters, filters), [draftFilters, filters]);

  return (
    <>
      <Badge count={filtersCount}>
        <Button type="default" onClick={showModal} icon={<FilterFilled />}>
          {t('dealbase:filters.filters')}
        </Button>
      </Badge>
      <Button onClick={onClear} type="text">
        {t('actions:global.clearAll')}
      </Button>
      <Modal
        width="auto"
        style={{ maxWidth: 600 }}
        bodyStyle={{ overflowY: 'scroll', height: '50vh' }}
        title={t('global:filters.moreFilters')}
        open={modalOpen}
        onCancel={onCancel}
        footer={
          <div>
            <Button onClick={onClearModalClear} type="text">
              {t('actions:global.clearAll')}
            </Button>
            <Button disabled={!filtersUpdated} onClick={handleOk} type="primary">
              {t('actions:global.apply')}
            </Button>
          </div>
        }
      >
        <div>
          {isMobile && (
            <>
              <StatusFilter onChange={onFiltersChange} filters={draftFilters.status} />
              <Divider />
              <AssignedUsersFilter onChange={onFiltersChange} filters={draftFilters.assigned} />
              <Divider />
            </>
          )}
          <CommonObjectFilter<DealBaseFilters>
            title={t('dealbase:filters.industry')}
            filterKey="industry"
            type={DYNAMIC.DEAL_BASE.INDUSTRY}
            objectType={OBJECT_TYPES.DEAL_BASE}
            onChange={onFiltersChange}
            filters={draftFilters.industry}
          />
          <Divider />
          <CommonObjectFilter<DealBaseFilters>
            title={t('dealbase:filters.technology')}
            filterKey="technology"
            type={DYNAMIC.DEAL_BASE.TECHNOLOGY}
            objectType={OBJECT_TYPES.DEAL_BASE}
            onChange={onFiltersChange}
            filters={draftFilters.technology}
          />
          {/* <Divider />
          <CountryFilter onChange={onFiltersChange} filters={draftFilters.country} />
          <Divider />
          <ProductStatusFilter onChange={onFiltersChange} filters={draftFilters.productStatus} />
          <Divider />
          <OrganizationFilter onChange={onFiltersChange} filters={draftFilters.organization} />
          <Divider />
          <FollowUpDateFilter onChange={onFiltersChange} filters={draftFilters.followUp} />
          <Divider />
          <StageFilter onChange={onFiltersChange} filters={draftFilters.stage} />
          <Divider />
          <PriorityFilter onChange={onFiltersChange} filters={draftFilters.priority} /> */}
          <Divider />
          <TierFilter onChange={onFiltersChange} filters={draftFilters.tier} />
          {/* <Divider /> */}
          {/* <ResultingScoreFilter onChange={onFiltersChange} filters={draftFilters.resultingScore} /> */}
        </div>
      </Modal>
    </>
  );
};

export default connector(ListingFilters);
