import React, { useCallback, useMemo } from 'react';

import { Badge, Menu } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { useTranslation } from 'react-i18next';
import { generatePath, Link } from 'react-router-dom';

import { DROPDOWN_TRIGGERS } from 'common-ui';
import {
  conversationsApi,
  selectActiveModule,
  selectClientConfiguration,
  selectUserPreferenceMenuDefaultType,
  useAppSelector,
} from 'core/lib';
import { OBJECT_TYPES } from 'core/lib/constants';
import colors from 'core/lib/theme/colors';
import { useMenuItems } from 'utils/hooks';

const ModulesBar = () => {
  const { t } = useTranslation('global');
  const activeModule = useAppSelector(selectActiveModule);
  const clientConfiguration = useAppSelector(selectClientConfiguration);
  const userPreferencesMenuDefaultType = useAppSelector(selectUserPreferenceMenuDefaultType);
  const menuPreferences = useMenuItems(clientConfiguration);

  const { data: conversationNotification } = conversationsApi.useGetConversationGlobalUnreadMessagesQuery();

  const hasNotification = useCallback(
    (moduleId: number) => moduleId === OBJECT_TYPES.MY_CONVERSATIONS && conversationNotification,
    [conversationNotification]
  );

  const items: ItemType[] = useMemo(() => {
    return menuPreferences.map((module) => {
      let { path } = module.route;
      if (userPreferencesMenuDefaultType && module[userPreferencesMenuDefaultType]) {
        ({ path } = module[userPreferencesMenuDefaultType]!.route);
      }
      const modulePath = generatePath(path);
      const moduleItem = (
        <Link to={modulePath} className="w-full h-auto text-inherit">
          <Badge
            style={{ backgroundColor: colors.Primary.rgba }}
            className="text-inherit"
            dot={hasNotification(module.objectType)}
          >
            {t(`moduleNames.${module.menuId.toLowerCase()}`)}
          </Badge>
        </Link>
      );

      return {
        key: module.objectType.toString(),
        className: 'block',
        label: moduleItem,
      };
    });
  }, [t, hasNotification, menuPreferences, userPreferencesMenuDefaultType]);

  return (
    <Menu
      items={items}
      triggerSubMenuAction={DROPDOWN_TRIGGERS[0]}
      className="flex flex-auto border-none w-full"
      style={{ minWidth: 0 }}
      mode="horizontal"
      selectedKeys={[activeModule?.toString() ?? '']}
    />
  );
};

export default ModulesBar;
