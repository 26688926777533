import React, { useMemo } from 'react';

import { Editor } from '@tiptap/react';
import { isMobile } from 'react-device-detect';

import { WysiwygEditor } from 'common-ui/wysiwyg';
import { EditorProps } from 'common-ui/wysiwyg/editor';
import { BaseUser, selectCurrentUser, useAppSelector } from 'core/lib';

type ConversationsWysiwygEditorProps = {
  onSubmit: () => void;
  setEditor: (editor: Editor | null) => void;
  conversationParticipants?: BaseUser[];
  placeholder?: string;
};

const ConversationsWysiwygEditor = ({
  onSubmit,
  setEditor,
  conversationParticipants,
  placeholder,
  ...rest
}: ConversationsWysiwygEditorProps & EditorProps) => {
  const currentUser = useAppSelector(selectCurrentUser);

  const mentionsData = useMemo(() => {
    return conversationParticipants?.filter((user) => user.id !== currentUser?.id) ?? [];
  }, [conversationParticipants, currentUser]);

  return (
    <WysiwygEditor
      disabledExtentions={{ orderedList: false, bulletList: false }}
      submitOnEnter
      onSubmit={onSubmit}
      setEditor={setEditor}
      mentionsData={mentionsData}
      toolbarHidden
      autoFocus={!isMobile}
      oneRow
      showSubmitButton
      placeholder={placeholder}
      {...rest}
    />
  );
};

export default ConversationsWysiwygEditor;
