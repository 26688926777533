import { createApi } from '@reduxjs/toolkit/query/react';
import querystring from 'query-string';

import { dynamicBaseQuery } from 'core/lib/utils/requesting';

import { GET_STATUS_ENDPOINT, LOGIN_ENDPOINT } from './endpoints';
import { deserializeLoginResponse } from './serializers';
import {
  ExternalLoginCredentials,
  ExternalLoginSuccess,
  GetStatusResponse,
  LoginCredentials,
  LoginResponse,
  LoginSuccess,
} from '../entities/authentication';

const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: dynamicBaseQuery,
  endpoints: (builder) => ({
    login: builder.mutation<LoginSuccess, LoginCredentials>({
      query: (credentials) => ({
        url: LOGIN_ENDPOINT,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        method: 'POST',
        isBase: true,
        body: querystring.stringify({ ...credentials, grant_type: 'password' }),
      }),
      transformResponse: deserializeLoginResponse,
    }),
    externalLogin: builder.mutation<ExternalLoginSuccess, ExternalLoginCredentials>({
      query: (credentials) => ({
        url: LOGIN_ENDPOINT,
        method: 'POST',
        isBase: true,
        body: querystring.stringify({ ...credentials, grant_type: 'password' }),
      }),
      transformResponse: (response: LoginResponse) => {
        return { token: response.access_token };
      },
    }),
    getStatus: builder.query<GetStatusResponse, number>({
      query: (timezoneOffset) => ({
        url: GET_STATUS_ENDPOINT,
        method: 'GET',
        params: { timezoneOffset },
      }),
    }),
  }),
});

export default authApi;
