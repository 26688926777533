import React from 'react';

import { Select, SelectProps, Tag } from 'antd';

import { RegionOptions } from 'core/lib/constants/selects';

const CountrySelect = (props: SelectProps<string>) => {
  return props.disabled && props.value ? (
    <Tag color="pink">{props.value}</Tag>
  ) : (
    <Select options={RegionOptions} dropdownMatchSelectWidth={150} showSearch {...props} />
  );
};

export default CountrySelect;
