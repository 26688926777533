import React from 'react';

import { Form } from 'antd';
import { Trans } from 'react-i18next';

import { NumberRangeInput } from 'common-ui/inputs';
import { CommonObjectOption } from 'core/lib';
import { dealBaseFormConfig } from 'modules/dealBase/form/formConfig';

const {
  generalInformation: { overview },
} = dealBaseFormConfig;

type OverviewSectionProps = {
  editMode: boolean;
};

const DealBaseOverviewSection = ({ editMode }: OverviewSectionProps) => {
  return (
    <div className="flex flex-col">
      <div className="flex sm:flex-row flex-col gap-x-4">
        <Form.Item
          className="md:w-3/6"
          label={overview.businessIdea.label}
          name={overview.businessIdea.dataIndex}
          rules={overview.businessIdea.rules}
        >
          <overview.businessIdea.component disabled={!editMode} />
        </Form.Item>
        <Form.Item
          className="md:w-3/6"
          label={overview.productStatus.label}
          name={overview.productStatus.dataIndex}
          rules={overview.productStatus.rules}
          valuePropName="selectedOption"
          normalize={(option?: CommonObjectOption) => {
            return option ? { id: option.id, name: option.label } : null;
          }}
        >
          <overview.productStatus.component disabled={!editMode} />
        </Form.Item>
      </div>
      <div className="flex sm:flex-row flex-col gap-x-4">
        <Form.Item
          className="md:w-3/6"
          label={overview.problemSolve.label}
          name={overview.problemSolve.dataIndex}
          rules={overview.problemSolve.rules}
        >
          <overview.problemSolve.component disabled={!editMode} />
        </Form.Item>
        <Form.Item
          className="md:w-3/6"
          label={overview.marketSize.label}
          name={overview.marketSize.dataIndex}
          rules={overview.marketSize.rules}
        >
          <overview.marketSize.component disabled={!editMode} />
        </Form.Item>
      </div>
      <div className="flex sm:flex-row flex-col gap-x-4">
        <Form.Item
          className="md:w-3/6"
          label={overview.yourCustomer.label}
          name={overview.yourCustomer.dataIndex}
          rules={overview.yourCustomer.rules}
        >
          <overview.yourCustomer.component disabled={!editMode} />
        </Form.Item>
        <Form.Item
          className="md:w-3/6"
          label={overview.getYourCustomer.label}
          name={overview.getYourCustomer.dataIndex}
          rules={overview.getYourCustomer.rules}
        >
          <overview.getYourCustomer.component disabled={!editMode} />
        </Form.Item>
      </div>
      <div className="flex sm:flex-row flex-col gap-x-4">
        <Form.Item
          className="md:w-3/6"
          label={overview.tractionSoFar.label}
          name={overview.tractionSoFar.dataIndex}
          rules={overview.tractionSoFar.rules}
        >
          <overview.tractionSoFar.component disabled={!editMode} />
        </Form.Item>
        <Form.Item
          className="md:w-3/6"
          label={overview.roundSpecifications.label}
          name={overview.roundSpecifications.dataIndex}
          rules={overview.roundSpecifications.rules}
        >
          <overview.roundSpecifications.component disabled={!editMode} />
        </Form.Item>
      </div>
      <div className="flex sm:flex-row flex-col gap-x-4">
        <Form.Item
          className="md:w-3/6"
          label={overview.preMoneyValuation.label}
          name={overview.preMoneyValuation.dataIndex}
          rules={overview.preMoneyValuation.rules}
          dependencies={[
            [overview.preMoneyValuation.dataIndex, 'from'],
            [overview.preMoneyValuation.dataIndex, 'to'],
          ]}
        >
          <NumberRangeInput
            dataIndex={overview.preMoneyValuation.dataIndex}
            disabled={!editMode}
            addonBefore="€"
            addonAfter={<Trans i18nKey="global:placeholders.millionsAbbr" />}
          />
        </Form.Item>
        <Form.Item
          className="md:w-3/6"
          label={overview.investmentRound.label}
          name={overview.investmentRound.dataIndex}
          rules={overview.investmentRound.rules}
          dependencies={[
            [overview.investmentRound.dataIndex, 'from'],
            [overview.investmentRound.dataIndex, 'to'],
          ]}
        >
          <NumberRangeInput
            dataIndex={overview.investmentRound.dataIndex}
            disabled={!editMode}
            addonBefore="€"
            addonAfter={<Trans i18nKey="global:placeholders.millionsAbbr" />}
          />
        </Form.Item>
      </div>
      <div className="flex sm:flex-row flex-col gap-x-4">
        <Form.Item
          className="md:w-3/6"
          label={overview.stage.label}
          name={overview.stage.dataIndex}
          rules={overview.stage.rules}
          valuePropName="selectedOption"
          normalize={(option?: CommonObjectOption) => {
            return option ? { id: option.id, name: option.label } : null;
          }}
        >
          <overview.stage.component disabled={!editMode} />
        </Form.Item>
      </div>
    </div>
  );
};

export default DealBaseOverviewSection;
