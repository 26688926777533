import React from 'react';

import { PageHeader, PageHeaderProps, Typography } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { useMedia } from 'react-use';

import FavoriteToggle from 'common-ui/favorite';
import { CompanyLogo } from 'common-ui/viewers';
import { DealBase } from 'core/lib';
import { OBJECT_TYPES } from 'core/lib/constants';

import { DealBaseStatusDropdown, DealBaseDetailsHeaderActions } from '.';

const { Title } = Typography;

type DealBasePageHeaderProps = {
  defaultSize?: SizeType;
  dealbase: DealBase;
  onBack?: () => void;
};

const DealBaseDetailsPageHeader = ({
  dealbase,
  onBack,
  defaultSize,
  ...props
}: DealBasePageHeaderProps & PageHeaderProps) => {
  const isMobile = useMedia('(max-width: 768px)');
  const size = defaultSize ?? isMobile ? 'small' : 'middle';
  const companyLogoSize = defaultSize ?? isMobile ? 'small' : 'default';
  const textLevel = isMobile || defaultSize === 'small' ? 5 : 4;

  return (
    <PageHeader
      {...props}
      onBack={onBack}
      title={
        <div className="flex flex-row items-center gap-2">
          <div className="flex justify-center">
            <CompanyLogo id={dealbase.companyLogo?.id} size={companyLogoSize} />
          </div>
          <Title className="ws-normal md:truncate m-0" level={textLevel}>
            {dealbase.name}
          </Title>
          <div className="flex">
            <Title className="m-0" type="secondary" level={5}>{`#${dealbase.id}`}</Title>
          </div>
          <FavoriteToggle objectType={OBJECT_TYPES.DEAL_BASE} objectId={dealbase.id} />
        </div>
      }
      extra={[
        <DealBaseStatusDropdown size={size} dealbase={dealbase} key="statusMenu" />,
        <DealBaseDetailsHeaderActions size={size} dealbase={dealbase} key="actionsMenu" />,
      ]}
    />
  );
};

export default DealBaseDetailsPageHeader;
