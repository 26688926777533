import React from 'react';

import { Divider, Tooltip } from 'antd';
import { format, formatRelative, getDayOfYear } from 'date-fns';
import enGB from 'date-fns/locale/en-GB';

import { BaseUser, ConversationMessage } from 'core/lib';
import { DEFAULT_DATE_FORMAT } from 'core/lib/constants/defaults';
import { parseJSONDate } from 'core/lib/utils';

import { MessageRenderMapper } from '../messageComponents';

const formatRelativeLocale = {
  yesterday: "'Yesterday'", //eslint-disable-line quotes
  today: "'Today'", //eslint-disable-line quotes
  other: DEFAULT_DATE_FORMAT, // Difference: Add time to the date
};

const locale = {
  ...enGB,
  formatRelative: (token: string) => formatRelativeLocale[token] ?? formatRelativeLocale.other,
};

type MessageRendererProps = {
  message: ConversationMessage;
  prevMessage?: ConversationMessage;
  conversationTitle?: string;
  conversationParticipants: BaseUser[];
};

const MessageRenderer = React.memo(function ConversationRowRenderer({
  message,
  prevMessage,
  conversationTitle,
  conversationParticipants,
}: MessageRendererProps) {
  if (!message) {
    return null;
  }

  const MessageComponent = MessageRenderMapper[message.type];

  const render =
    prevMessage &&
    getDayOfYear(parseJSONDate(message.dateCreated)!) !== getDayOfYear(parseJSONDate(prevMessage.dateCreated)!) ? (
      <>
        <Divider className="text-sm text-gray-400">
          <Tooltip title={format(parseJSONDate(message.dateCreated)!, DEFAULT_DATE_FORMAT)}>
            {formatRelative(parseJSONDate(message.dateCreated)!, new Date(), { locale })}
          </Tooltip>
        </Divider>
        <MessageComponent
          conversationParticipants={conversationParticipants}
          conversationTitle={conversationTitle}
          message={message}
        />
      </>
    ) : (
      <MessageComponent
        conversationParticipants={conversationParticipants}
        conversationTitle={conversationTitle}
        message={message}
      />
    );

  return (
    <>
      {render ?? (
        <>
          <Divider className="text-sm text-gray-400">
            <Tooltip title={format(parseJSONDate(message.dateCreated)!, DEFAULT_DATE_FORMAT)}>
              {formatRelative(parseJSONDate(message.dateCreated)!, new Date(), { locale })}
            </Tooltip>
          </Divider>
          <MessageComponent
            conversationParticipants={conversationParticipants}
            conversationTitle={conversationTitle}
            message={message}
          />
        </>
      )}
    </>
  );
});

export default MessageRenderer;
