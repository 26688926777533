import React, { useMemo } from 'react';

import { StarOutlined } from '@ant-design/icons';
import { Dropdown } from 'antd';

import { DROPDOWN_TRIGGERS, EmptyWrapper } from 'common-ui';
import { baseApi } from 'core/lib';
import LoaderWrapper from 'utils/loader-wrapper';

import FavoritesItem from './favoritesItem';

const UserFavorites = () => {
  const [getFavorites, { data, isLoading }] = baseApi.useLazyGetFavoritesQuery();

  const handleFavoritesListOpen = (open: boolean) => {
    if (open) {
      getFavorites(undefined, true);
    }
  };

  const items = useMemo(() => {
    if (!data || !data.length) {
      return [
        {
          key: 'loader',
          label: (
            <LoaderWrapper loading={isLoading}>
              <EmptyWrapper empty={!data?.length}></EmptyWrapper>
            </LoaderWrapper>
          ),
        },
      ];
    }
    return data.map((item) => ({
      key: item.id,
      className: 'menu-item-overflow-hidden',
      label: <FavoritesItem key={item.objectId} item={item} />,
    }));
  }, [data, isLoading]);

  return (
    <Dropdown
      onOpenChange={handleFavoritesListOpen}
      trigger={DROPDOWN_TRIGGERS}
      menu={{ items, className: 'max-h-64 min-h-32 min-w-32 max-w-64 overflow-y-auto overflow-hidden' }}
      className="cursor-pointer"
    >
      <StarOutlined />
    </Dropdown>
  );
};

export default UserFavorites;
