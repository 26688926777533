import React, { ReactNode } from 'react';

import {
  BoldOutlined,
  ItalicOutlined,
  OrderedListOutlined,
  UnderlineOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';
import { Editor } from '@tiptap/react';
import { Divider } from 'antd';

import { ToolbarButton } from './shared';

export const Menu = ({ children }: { children: ReactNode }) => (
  <div className="border border-solid b--grey-200 border-b-0">{children}</div>
);

export const Toolbar = ({
  editor,
  enabledExtentions,
}: {
  editor: Editor;
  enabledExtentions: { [key: string]: boolean };
}) => (
  <Menu>
    <ToolbarButton
      icon={<BoldOutlined />}
      active={editor.isActive('bold')}
      onClick={() => {
        editor.chain().focus().toggleBold().run();
        editor.view.focus();
      }}
    />
    <ToolbarButton
      icon={<ItalicOutlined />}
      active={editor.isActive('italic')}
      onClick={() => {
        editor.chain().focus().toggleItalic().run();
        editor.view.focus();
      }}
    />
    <ToolbarButton
      icon={<UnderlineOutlined />}
      active={editor.isActive('underline')}
      onClick={() => {
        editor.chain().focus().toggleUnderline().run();
        editor.view.focus();
      }}
    />
    <Divider type="vertical" />
    {enabledExtentions.orderedList && (
      <ToolbarButton
        icon={<OrderedListOutlined />}
        active={editor.isActive('orderedList')}
        onClick={() => {
          editor.chain().focus().toggleOrderedList().run();
          editor.view.focus();
        }}
      />
    )}
    {enabledExtentions.bulletList && (
      <ToolbarButton
        icon={<UnorderedListOutlined />}
        active={editor.isActive('bulletList')}
        onClick={() => {
          editor.chain().focus().toggleBulletList().run();
          editor.view.focus();
        }}
      />
    )}
  </Menu>
);
