import React from 'react';

import { Skeleton, Tag, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';
import { ListChildComponentProps } from 'react-window';

import { MenuItemWrapper } from 'common-ui/leftMenu';
import { UserMenu} from 'core/lib';
import { Routes } from 'routes/routes';

const { Text } = Typography;


type UserMenuItemProps = {
  id?: string;
  isLoading: boolean;
};

type UserMenuItemConnectedProps = ListChildComponentProps<UserMenu[]> &
  UserMenuItemProps;

const UserMenuItem = ({ index, data, style, id }: UserMenuItemConnectedProps) => {
  const { t } = useTranslation('user');


  const item = data?.[index];
  const isActive = item?.id === parseInt(id ?? '');
  return (
    <Skeleton active loading={!item}>
      {item && (
        <MenuItemWrapper
          isActive={isActive}
          style={style}
          path={generatePath(Routes.USER_DETAILS.path, { id: item.id })}
        >
          <Text className="text-inherit" ellipsis>
            {item?.name}
          </Text>

          <Tag color="blue">{t(`status.label.${item.status}`)}</Tag>
        </MenuItemWrapper>
      )}
    </Skeleton>
  );
};

export default UserMenuItem;
