import React from 'react';

import { Tooltip } from 'antd';
import { format, formatDistance } from 'date-fns';

import { DEFAULT_DATETIME_FORMAT } from 'core/lib/constants/defaults';
import { parseJSONDate } from 'core/lib/utils';

type DateSinceProps = {
  date: string | Date;
};

const DateSince = ({ date }: DateSinceProps) => {
  return (
    <Tooltip title={format(parseJSONDate(date)!, DEFAULT_DATETIME_FORMAT)}>
      <span>{formatDistance(parseJSONDate(date)!, new Date(), { addSuffix: true })}</span>
    </Tooltip>
  );
};

export default DateSince;
