import { deserializeUserShortListResponse, deserializeUserShortResponse } from '../../users';
import {
  ConversationMessage,
  ConversationMessageResponse,
  DirectConversationDetails,
  DirectConversationDetailsResponse,
  GroupConversationDetails,
  GroupConversationDetailsResponse,
  MenuDirectConversations,
  MenuDirectConversationsResponse,
  MenuGroupConversations,
  MenuGroupConversationsResponse,
  MenuModuleConversations,
  MenuModuleConversationsResponse,
  ModuleConversationDetails,
  ModuleConversationDetailsResponse,
  ModuleConversationListing,
  ModuleConversationListingResponse,
} from '../entities';

export const deserializeModuleConversationsListing = (
  response: ModuleConversationListingResponse[]
): ModuleConversationListing[] => {
  return response.map((res) => ({
    id: res.ID,
    conversationId: res.ConversationID,
    objectType: res.ObjectType,
    objectId: res.ObjectID,
    objectTitle: res.ObjectTitle,
    participants: deserializeUserShortListResponse(res.Participants),
    createdBy: deserializeUserShortResponse(res.CreatedBy),
  }));
};

export const deserializeDirectConversationsMenu = (
  response: MenuDirectConversationsResponse[]
): MenuDirectConversations[] => {
  return response.map((res) => ({
    id: res.ID,
    conversationId: res.ConversationID,
    peer: deserializeUserShortResponse(res.Peer),
    undreadMessages: res.UnreadMessages,
  }));
};

export const deserializeGroupConversationsMenu = (
  response: MenuGroupConversationsResponse[]
): MenuGroupConversations[] => {
  return response.map((res) => ({
    id: res.ID,
    conversationId: res.ConversationID,
    title: res.Title,
    undreadMessages: res.UnreadMessages,
  }));
};

export const deserializeMenuModuleConversations = (
  response: MenuModuleConversationsResponse[]
): MenuModuleConversations[] => {
  return response.map((res) => ({
    id: res.ID,
    conversationId: res.ConversationID,
    objectType: res.ObjectType,
    objectTitle: res.ObjectTitle,
    undreadMessages: res.UnreadMessages,
  }));
};

export const deserializeModuleConversationsDetails = (
  response: ModuleConversationDetailsResponse
): ModuleConversationDetails => {
  return {
    id: response.ID,
    conversationId: response.ConversationID,
    objectType: response.ObjectType,
    objectId: response.ObjectID,
    objectTitle: response.ObjectTitle,
    totalMessages: response.TotalMessages,
    participants: deserializeUserShortListResponse(response.Participants),
    createdBy: deserializeUserShortResponse(response.CreatedBy),
  };
};

export const deserializeDirectConversationsDetails = (
  response: DirectConversationDetailsResponse
): DirectConversationDetails => {
  return {
    id: response.ID,
    conversationId: response.ConversationID,
    totalMessages: response.TotalMessages,
    participants: deserializeUserShortListResponse(response.Participants),
    createdBy: deserializeUserShortResponse(response.CreatedBy),
    peer: deserializeUserShortResponse(response.Peer),
  };
};

export const deserializeConversationMessage = (response: ConversationMessageResponse): ConversationMessage => {
  return {
    id: response.ID,
    conversationId: response.ConversationID,
    content: response.Content,
    dateCreated: response.DateCreated,
    dateModified: response.DateModified,
    user: deserializeUserShortResponse(response.User),
    type: response.Type,
    subType: response.SubType,
    status: response.Status,
  };
};

export const deserializeConversationMessagesList = (response: ConversationMessageResponse[]): ConversationMessage[] => {
  return response.map(deserializeConversationMessage);
};

export const deserializeGroupConversationsDetails = (
  response: GroupConversationDetailsResponse
): GroupConversationDetails => {
  return {
    id: response.ID,
    conversationId: response.ConversationID,
    title: response.Title,
    totalMessages: response.TotalMessages,
    participants: deserializeUserShortListResponse(response.Participants),
    createdBy: deserializeUserShortResponse(response.CreatedBy),
  };
};
