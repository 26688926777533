import React, { useContext, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { ContentContainer, MainContentContainer } from 'common-ui/containers';
import { ActivityList } from 'common-ui/lists';
import { portfolioApi } from 'core/lib';
import LoaderWrapper from 'utils/loader-wrapper';

import { PortfolioDetailsContext } from '../../context';

const PortfolioActivityTab = () => {
  const portfolio = useContext(PortfolioDetailsContext);
  const { t } = useTranslation();
  const [getHistory, { data, isFetching }] = portfolioApi.useLazyGetHistoryQuery();

  useEffect(() => {
    if (portfolio?.id) {
      getHistory(portfolio.id);
    }
  }, [portfolio?.id, getHistory]);

  return (
    <MainContentContainer id="portfolio-activity">
      <ContentContainer>
        <LoaderWrapper loading={isFetching} message={t('loaders:portfolio.loadingActivity')}>
          <ActivityList data={data ?? []} />
        </LoaderWrapper>
      </ContentContainer>
    </MainContentContainer>
  );
};

export default PortfolioActivityTab;
