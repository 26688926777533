import React from 'react';

import { Button, Tooltip, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { baseApi, UserFavorite } from 'core/lib';
import { generateRoutePathByObjectTypeAndObjectId } from 'utils';

const { Text } = Typography;

type FavoritesItemProps = {
  item: UserFavorite;
};

const FavoritesItem = ({ item }: FavoritesItemProps) => {
  const { t } = useTranslation();

  const [setFavorite] = baseApi.useSetFavoriteMutation();

  const onRemoveFavoriteClick = (favorite: UserFavorite) => {
    setFavorite({ ...favorite, isFavorite: false });
  };

  const route = generateRoutePathByObjectTypeAndObjectId(item.objectType, item.objectId);

  const itemPrefixedName = (
    <div className="flex flex-row gap-2">
      <Text className="flex flex-grow flex-shrink-0" type="secondary">
        {item.objectPrefix}
      </Text>
      <Text className="truncate" ellipsis>
        {item.objectName}
      </Text>
    </div>
  );

  const itemRender = route ? (
    <Link className="overflow-hidden" to={route}>
      {itemPrefixedName}
    </Link>
  ) : (
    itemPrefixedName
  );

  return (
    <div className="flex flex-row gap-2 justify-between items-center">
      <Tooltip title={item.objectName}>{itemRender}</Tooltip>
      <Button
        className="p-0 "
        type="link"
        onClick={(e) => {
          e?.stopPropagation();
          e?.preventDefault();
          onRemoveFavoriteClick(item);
        }}
      >
        {t('actions:global.remove')}
      </Button>
    </div>
  );
};

export default FavoritesItem;
