import React, { useContext } from 'react';

import { Form } from 'antd';
import { RcFile, UploadFile } from 'antd/lib/upload/interface';
import { useTranslation } from 'react-i18next';

import { ExternalUserTag, UserAvatar } from 'common-ui';
import { CommonObjectOption, DealBase } from 'core/lib';
import { DealBaseDetailsContext } from 'modules/dealBase/context';
import { dealBaseFormConfig } from 'modules/dealBase/form/formConfig';
import { normalizeFileUpload } from 'utils';

const {
  generalInformation: { generalInformation },
} = dealBaseFormConfig;

type GeneralInformationSectionProps = {
  editMode: boolean;
};

const DealBaseGeneralInformationSection = ({ editMode }: GeneralInformationSectionProps) => {
  const dealbase = useContext(DealBaseDetailsContext) as DealBase;
  const { t } = useTranslation('dealbase');

  return (
    <div className="flex flex-col">
      <Form.Item
        label={generalInformation.name.label}
        name={generalInformation.name.dataIndex}
        rules={generalInformation.name.rules}
      >
        <generalInformation.name.component disabled={!editMode} />
      </Form.Item>
      <Form.Item
        label={generalInformation.companyLogo.label}
        name={generalInformation.companyLogo.dataIndex}
        rules={generalInformation.companyLogo.rules}
        normalize={({ file }: { file: UploadFile<RcFile & XMLHttpRequest> }) => {
          return normalizeFileUpload(file, generalInformation.companyLogo.dataIndex);
        }}
      >
        <generalInformation.companyLogo.component disabled={!editMode} />
      </Form.Item>
      <div className="flex sm:flex-row lg:flex-col flex-col gap-x-4">
        <Form.Item
          className="md:w-3/6 lg:w-full"
          label={generalInformation.industry.label}
          name={generalInformation.industry.dataIndex}
          rules={generalInformation.industry.rules}
          valuePropName="selectedOption"
          normalize={(options: CommonObjectOption[]) => {
            return options.map((opt) => ({ id: opt.id, name: opt.label }));
          }}
        >
          <generalInformation.industry.component disabled={!editMode} />
        </Form.Item>
        <Form.Item
          className="md:w-3/6 lg:w-full"
          label={generalInformation.technology.label}
          name={generalInformation.technology.dataIndex}
          rules={generalInformation.technology.rules}
          valuePropName="selectedOption"
          normalize={(option: CommonObjectOption) => {
            return option ? { id: option.id, name: option.label } : null;
          }}
        >
          <generalInformation.technology.component disabled={!editMode} />
        </Form.Item>
      </div>
      <div className="flex sm:flex-row lg:flex-col flex-col gap-x-4">
        <Form.Item
          className="md:w-3/6 lg:w-full"
          label={generalInformation.customerFocus.label}
          name={generalInformation.customerFocus.dataIndex}
          rules={generalInformation.customerFocus.rules}
          valuePropName="selectedOption"
          normalize={(options: CommonObjectOption[]) => {
            return options.map((opt) => ({ id: opt.id, name: opt.label }));
          }}
        >
          <generalInformation.customerFocus.component disabled={!editMode} />
        </Form.Item>
        <Form.Item
          className="md:w-3/6 lg:w-full"
          label={generalInformation.foundingYear.label}
          name={generalInformation.foundingYear.dataIndex}
          rules={generalInformation.foundingYear.rules}
        >
          <generalInformation.foundingYear.component disabled={!editMode} />
        </Form.Item>
      </div>
      <div className="flex sm:flex-row lg:flex-col flex-col gap-x-4">
        <Form.Item
          className="md:w-3/6 lg:w-full"
          label={generalInformation.country.label}
          name={generalInformation.country.dataIndex}
          rules={generalInformation.country.rules}
        >
          <generalInformation.country.component disabled={!editMode} />
        </Form.Item>
        <Form.Item
          className="md:w-3/6 lg:w-full"
          label={generalInformation.city.label}
          name={generalInformation.city.dataIndex}
          rules={generalInformation.city.rules}
        >
          <generalInformation.city.component disabled={!editMode} />
        </Form.Item>
      </div>
      <div className="flex sm:flex-row flex-col lg:flex-col gap-x-4">
        <Form.Item
          className="md:w-3/6 lg:w-full"
          label={generalInformation.website.label}
          name={generalInformation.website.dataIndex}
          rules={generalInformation.website.rules}
        >
          <generalInformation.website.component disabled={!editMode} />
        </Form.Item>
        <Form.Item
          className="md:w-3/6 lg:w-full"
          label={generalInformation.heardFrom.label}
          name={generalInformation.heardFrom.dataIndex}
          rules={generalInformation.heardFrom.rules}
        >
          <generalInformation.heardFrom.component disabled={!editMode} />
        </Form.Item>
      </div>
      <div className="flex sm:flex-row lg:flex-col flex-col gap-x-4">
        <Form.Item className="md:w-3/6 lg:w-full" label={t('form.generalEvaluation.labels.createdBy')} name="createdBy">
          <div className="flex">
            {dealbase.createdBy ? <UserAvatar user={dealbase.createdBy} /> : <ExternalUserTag />}
          </div>
        </Form.Item>
      </div>
    </div>
  );
};

export default DealBaseGeneralInformationSection;
