import React from 'react';

import { List } from 'antd';
import { format } from 'date-fns';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList, ListChildComponentProps } from 'react-window';

import { UserAvatar } from 'common-ui';
import { ObjectHistory } from 'core/lib';
import { DEFAULT_DATETIME_FORMAT } from 'core/lib/constants/defaults';
import { parseJSONDate } from 'core/lib/utils';

type ActivityListProps = {
  data: ObjectHistory[];
};

const ActivityList = ({ data }: ActivityListProps) => {
  const renderItem = ({ index: itemIndex, data: activityData, style }: ListChildComponentProps<ObjectHistory[]>) => {
    const item = activityData[itemIndex];

    return (
      <List.Item style={style} key={item.id}>
        <List.Item.Meta
          avatar={<UserAvatar iconOnly user={item.user} />}
          title={item.task}
          description={format(parseJSONDate(item.dateCreated)!, DEFAULT_DATETIME_FORMAT)}
        />
      </List.Item>
    );
  };

  return (
    <div className="h-full max-w-512 mx-auto">
      <AutoSizer>
        {({ height, width }) => (
          <List>
            <FixedSizeList<ObjectHistory[]>
              itemData={data}
              width={width}
              height={height}
              itemCount={data.length}
              itemSize={80}
            >
              {renderItem}
            </FixedSizeList>
          </List>
        )}
      </AutoSizer>
    </div>
  );
};

export default ActivityList;
