import React, { ReactNode } from 'react';

import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import { useMedia } from 'react-use';

import { useAppSelector, selectLeftMenuState, setLeftMenuToggled, useAppDispatch } from 'core/lib';
import {
  isAuthenticated as isAuthenticatedSelector,
  selectPendingAuthentication,
} from 'core/lib/modules/auth/store/selectors';
import { HubProvider } from 'utils/hooks/hubHooks';

import { LeftMenu, LeftMenuNavigation } from './leftMenu';
import ModulesBar from './modulesBar';
import TopBar from './topBar';
import { Routes } from '../../routes/routes';
import LoaderWrapper from '../../utils/loader-wrapper';

type AppLayoutProps = {
  children?: ReactNode;
  location?: Location;
};

const AppLayout = ({ children, location }: AppLayoutProps) => {
  const isTablet = useMedia('(max-width: 1023px)');
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(isAuthenticatedSelector);
  const pendingTokenRequest = useAppSelector(selectPendingAuthentication);
  const leftMenuToggled = useAppSelector(selectLeftMenuState);

  const redirectOrRender = () => {
    if (isAuthenticated) {
      return children;
    }
    const from = location!.pathname?.length > 1 ? location!.pathname : null;
    return <Redirect to={{ pathname: Routes.LOGIN.path, state: { from } }} />;
  };

  const onContainerClick = () => {
    if (isTablet && leftMenuToggled) {
      dispatch(setLeftMenuToggled(false));
    }
  };

  return (
    <LoaderWrapper loading={pendingTokenRequest} message={t('loaders:global.initializingApp')}>
      <HubProvider>
        <div className="flex flex-col h-full divide-solid divide-y divide-x-0 divide-gray-200">
          <TopBar />
          <div className="flex flex-row items-center divide-solid divide-x divide-y-0 divide-gray-200">
            <div className="flex flex-auto shrink-0 justify-between items-center font-medium w-auto lg:w-1/5 lg:min-w-64 px-4 h-full">
              <LeftMenuNavigation />
            </div>
            <ModulesBar />
          </div>
          <div
            className="flex flex-row lg:divide-solid lg:divide-y-0 lg:divide-x lg:divide-gray-200"
            style={{ height: 'calc(100% - 106px)' }}
          >
            <LeftMenu />
            <div onClick={onContainerClick} className={`flex h-full w-full flex-col md:flex-auto overflow-hidden`}>
              {redirectOrRender()}
            </div>
          </div>
        </div>
      </HubProvider>
    </LoaderWrapper>
  );
};

export default AppLayout;
