import React from 'react';

const NotificationTitle = ({ title, objectName }: { title?: string; objectName?: string }) => {
  return (
    <div>
      {title && title}
      {objectName && <span className="ml-1.5 text-primary bold">{objectName}</span>}
    </div>
  );
};

export default NotificationTitle;
