import React from 'react';

import { ConversationMessage } from 'core/lib';

import { MessageRenderMapper, SystemMessage } from './index';

const SystemBaseMessage = ({
  message,
  conversationTitle,
}: {
  message: ConversationMessage;
  conversationTitle?: string;
}) => {
  const MessageRenderer = message.subType ? MessageRenderMapper[message.subType] : SystemMessage;
  return (
    <div>
      <MessageRenderer conversationTitle={conversationTitle} message={message} />
    </div>
  );
};

export default SystemBaseMessage;
