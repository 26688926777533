import React, { forwardRef } from 'react';

const ItemOverlay = forwardRef<HTMLDivElement, { children: React.ReactNode | string }>(function OverlayRender(
  { children, ...props },
  ref
) {
  return (
    <div {...props} ref={ref}>
      {children}
    </div>
  );
});

export default ItemOverlay;
