import React, { useMemo } from 'react';

import { EllipsisOutlined } from '@ant-design/icons';
import { Button, Dropdown, Popconfirm } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { useTranslation } from 'react-i18next';

import { DROPDOWN_TRIGGERS } from 'common-ui';

interface UserMessageProps {
  setEditMode: (editMode: boolean) => void;
  onDelete: () => void;
}

const UserMessageActions = ({ setEditMode, onDelete }: UserMessageProps) => {
  const { t } = useTranslation();

  const items: ItemType[] = useMemo(() => {
    return [
      { key: 'Edit', label: t('actions:global.editMessage'), onClick: () => setEditMode(true) },
      {
        key: 'Delete',
        label: (
          <Popconfirm
            title={t('conversations:confirmation.deleteMessage')}
            okButtonProps={{ danger: true }}
            okText={t('actions:global.delete')}
            onConfirm={() => onDelete()}
            className="flex"
          >
            {t('actions:global.delete')}
          </Popconfirm>
        ),
      },
    ];
  }, [setEditMode, t]); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Dropdown trigger={DROPDOWN_TRIGGERS} menu={{ items }}>
      <Button size="small" type="text" className="m-0" icon={<EllipsisOutlined />} />
    </Dropdown>
  );
};
export default UserMessageActions;
