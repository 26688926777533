import React from 'react';

import { Select, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { UserSystemRoleId } from 'core/lib/constants';
import { RolesOptions } from 'core/lib/constants/selects';
import { UserFilters } from 'core/lib/modules/users/entities/filters';

const { Title } = Typography;

type RoleFilterProps = {
  onChange: (filters: Partial<UserFilters>) => void;
  filters: UserSystemRoleId[] | undefined;
};

const RoleFilter = ({ onChange, filters }: RoleFilterProps) => {
  const { t } = useTranslation();
  return (
    <div>
      <Title level={5}>{t('user:filters.roles')}</Title>
      <Select
        mode="multiple"
        className="w-full"
        allowClear
        showSearch={false}
        options={RolesOptions}
        value={filters}
        placeholder={t('global:placeholders.selectType', { type: t('user:filters.roles') })}
        onChange={(roles) => onChange({ roles })}
      />
    </div>
  );
};

export default RoleFilter;
