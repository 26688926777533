import React, { useMemo, useRef, useState } from 'react';

import { Input, List, Switch } from 'antd';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps, useSelector } from 'react-redux';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';

import { AppState, dealBaseApi, DealBaseMenu, setLeftMenuFiltersToggled, setLeftMenuToggled } from 'core/lib';
import { selectLeftMenuFiltersToggled } from 'core/lib/modules/app/store';
import { selectFilteredMenu } from 'core/lib/modules/dealBase/store';
import { fuzzyWordsSearch } from 'core/lib/utils';
import { Routes } from 'routes/routes';
import { useMatchParamId } from 'utils/hooks';
import LoaderWrapper from 'utils/loader-wrapper';

import DealBaseMenuItem from './menuItem';

const mapState = (state: AppState) => ({
  filtersToggled: selectLeftMenuFiltersToggled(state),
});

const mapDispatch = {
  toggleLeftMenu: setLeftMenuToggled,
  toggleLeftMenuFilters: setLeftMenuFiltersToggled,
};

const connector = connect(mapState, mapDispatch);
type DealBaseMenuProps = ConnectedProps<typeof connector>;

const DealBaseLeftMenu = ({ toggleLeftMenuFilters, filtersToggled }: DealBaseMenuProps) => {
  const { t } = useTranslation('dealbase');
  const [search, setSearch] = useState('');
  const searchContainerRef = useRef<HTMLDivElement>(null);

  const id = useMatchParamId(Routes.DEAL_BASE_DETAILS.path);

  const { data, isLoading } = dealBaseApi.useGetDealBaseMenuQuery();

  const filteredMenuData = useSelector((state: AppState) => selectFilteredMenu(state, data));

  const searchResultData: DealBaseMenu[] | undefined = useMemo(() => {
    return filteredMenuData
      ?.filter((deal) => fuzzyWordsSearch(search, deal.name))
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [search, filteredMenuData]);

  const onFiltersToggleClick = () => {
    toggleLeftMenuFilters(!filtersToggled);
  };

  return (
    <LoaderWrapper loading={!data?.length && isLoading} message={t('loaders:dealBase.loadingMenu')}>
      <div ref={searchContainerRef}>
        <div className="flex flex-col p-4 gap-4">
          <div className="flex items-center justify-between gap-4">
            {t('global:filters.toggleFilters')}
            <Switch
              onClick={onFiltersToggleClick}
              size="small"
              title={t('global:filters.toggleFilters')}
              checked={filtersToggled}
            />
          </div>
          <Input
            allowClear
            placeholder={t('global:placeholders.searchByName')}
            onChange={(e) => setSearch(e.target.value)}
            value={search}
          />
        </div>
      </div>
      <AutoSizer>
        {({ width, height }) => (
          <List>
            <FixedSizeList<DealBaseMenu[]>
              itemData={searchResultData}
              overscanCount={5}
              itemSize={40}
              itemCount={searchResultData?.length ?? 0}
              width={width}
              height={height - (searchContainerRef.current?.clientHeight ?? 0)}
            >
              {(props) => <DealBaseMenuItem {...props} id={id} isLoading={isLoading} />}
            </FixedSizeList>
          </List>
        )}
      </AutoSizer>
    </LoaderWrapper>
  );
};

export default connector(DealBaseLeftMenu);
