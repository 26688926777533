import React from 'react';

import { StarFilled } from '@ant-design/icons';

import { baseApi } from 'core/lib';
import colors from 'core/lib/theme/colors';

type FavoriteToggleProps = {
  objectType: number;
  objectId: number;
};

const FavoriteToggle = ({ objectType, objectId }: FavoriteToggleProps) => {
  const { data } = baseApi.useGetFavoriteByIdQuery({ id: objectId, objectType });
  const [setFavorite] = baseApi.useSetFavoriteMutation();

  const onFavoriteClick = () => {
    setFavorite({ ...(data ?? {}), isFavorite: !data?.isFavorite, objectId, objectType });
  };

  return (
    <StarFilled
      style={{ color: data?.isFavorite ? colors.Yellow.rgba : colors.Grey400.rgba }}
      onClick={onFavoriteClick}
    />
  );
};

export default FavoriteToggle;
