import React, { useEffect } from 'react';

import { ShopOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import { AvatarSize } from 'antd/lib/avatar/SizeContext';

import { baseApi } from 'core/lib';

type CompanyLogoProps = {
  id?: number | null;
  size?: AvatarSize;
};

const contentType = 'data:image/*;base64';

const CompanyLogo = ({ id, size = 'default' }: CompanyLogoProps) => {
  const [getCompanyLogo, { data }] = baseApi.useLazyDownloadBase64Query();

  useEffect(() => {
    if (id) {
      getCompanyLogo(id, true);
    }
  }, [id, getCompanyLogo]);

  return id ? (
    <Avatar
      className="shrink-0 cursor-pointer"
      style={{ margin: 0 }}
      size={size}
      src={`${contentType},${data?.replace(/^[^,]+,/, '')}`}
    />
  ) : (
    <Avatar className="shrink-0 cursor-pointer" style={{ margin: 0 }} size={size} icon={<ShopOutlined />} />
  );
};

export default CompanyLogo;
