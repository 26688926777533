import React from 'react';

import { PageHeader, Tag, Typography } from 'antd';
import { useMedia } from 'react-use';

import FavoriteToggle from 'common-ui/favorite';
import { OBJECT_TYPES } from 'core/lib/constants';
import { PORTFOLIO_REVIEW_STATUS, PORTFOLIO_REVIEW_STATUS_COLORS } from 'core/lib/constants/portfolio';
import { PortfolioDetails } from 'core/lib/modules/portfolio';

import DetailsHeaderActions from './detailsHeaderActions';
import PortfolioStatusDropdown from './statusWorkflowDropdown';

const { Title } = Typography;

type PortfolioPageHeaderProps = {
  portfolio: PortfolioDetails;
  onBack: () => void;
};

const BasicPageHeader = ({ portfolio, onBack }: PortfolioPageHeaderProps) => {
  const isMobile = useMedia('(max-width: 768px)');
  const size = isMobile ? 'small' : 'middle';
  const textLevel = isMobile ? 5 : 4;
  const color = portfolio.reviewStatus ? PORTFOLIO_REVIEW_STATUS_COLORS[portfolio.reviewStatus] : 'blue';
  return (
    <PageHeader
      className="inherit-wrap"
      onBack={onBack}
      title={
        <div className="flex flex-row items-center gap-2">
          <Title className="ws-normal md:truncate m-0" level={textLevel}>
            {portfolio.name}
          </Title>
          <div className="flex">
            <Title className="m-0" type="secondary" level={5}>{`#${portfolio.id}`}</Title>
          </div>
          <FavoriteToggle objectType={OBJECT_TYPES.PORTFOLIO} objectId={portfolio.id} />
          {portfolio.reviewStatus && <Tag color={color}>{PORTFOLIO_REVIEW_STATUS[portfolio.reviewStatus]}</Tag>}
        </div>
      }
      extra={[
        <PortfolioStatusDropdown size={size} portfolio={portfolio} key="statusMenu" />,
        <DetailsHeaderActions size={size} portfolio={portfolio} key="actionsMenu" />,
      ]}
    />
  );
};

export default BasicPageHeader;
