import React from 'react';

import { Input, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { EmptyWrapper } from 'common-ui';
import {
  SearchResult,
  selectModuleSearchOptions,
  selectSearchModule,
  setSearchModule,
  useAppDispatch,
  useAppSelector,
} from 'core/lib';
import { getDetailsPath } from 'utils';
import LoaderWrapper from 'utils/loader-wrapper';

const { Option } = Select;

type InlineSearchProps = {
  isLoading: boolean;
  search: string;
  onSearch: (search: string) => void;
  result?: SearchResult[];
};

const InlineSearch = ({ isLoading, onSearch, search, result }: InlineSearchProps) => {
  const { t } = useTranslation('actions');
  const searchModule = useAppSelector(selectSearchModule);
  const moduleSearchOptions = useAppSelector(selectModuleSearchOptions);
  const dispatch = useAppDispatch();

  const onModuleSelect = (objectType: number) => {
    dispatch(setSearchModule(objectType));
  };

  return (
    <Input.Group className="flex justify-center" compact>
      <Select
        value={searchModule}
        options={moduleSearchOptions}
        onChange={onModuleSelect}
        placeholder={t('global:labels.module')}
        className="flex flex-auto max-w-32"
      />
      <Select
        allowClear
        showSearch
        searchValue={search}
        open={!!search}
        placeholder={t('global.search')}
        loading={isLoading}
        onSearch={onSearch}
        className="flex flex-auto max-w-64"
        filterOption={false}
        value={null}
        dropdownRender={(menu) => {
          return (
            <LoaderWrapper loading={isLoading}>
              <EmptyWrapper empty={!result?.length}>{menu}</EmptyWrapper>
            </LoaderWrapper>
          );
        }}
      >
        {result?.map((res: SearchResult) => (
          <Option className="flex" key={res.id} value={res.id} label={res.name}>
            <Link
              onClick={() => onSearch('')}
              className="flex flex-auto"
              to={{ pathname: getDetailsPath(res.objectType, res.id) }}
            >
              {`#${res.id} ${res.name}`}
            </Link>
          </Option>
        ))}
      </Select>
    </Input.Group>
  );
};

export default InlineSearch;
