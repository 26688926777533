import React from 'react';

import { Form, Select, SelectProps } from 'antd';

import { BaseSelectOption } from 'core/lib';

import { ListingEditableCellProps } from './types';

function ObjectSelector<T extends { id: number }>({
  selectedOption,
  ...props
}: SelectProps<number> & { selectedOption?: T }) {
  return (
    <Select
      showSearch
      optionFilterProp="label"
      {...props}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onChange={(_val, option: any) => {
        props.onChange?.(option, option);
      }}
      value={selectedOption?.id}
    />
  );
}

function ListingObjectSelect({ dataIndex, rules, ...props }: ListingEditableCellProps & SelectProps<number>) {
  return (
    <Form.Item
      hasFeedback
      rules={rules}
      name={dataIndex}
      className="m-0"
      valuePropName="selectedOption"
      normalize={(value: BaseSelectOption) => {
        return value ? { id: value.id, name: value.label } : null;
      }}
    >
      <ObjectSelector<BaseSelectOption> {...props} allowClear />
    </Form.Item>
  );
}

export default ListingObjectSelect;
