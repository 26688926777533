import React, { PropsWithChildren, ReactElement } from 'react';

import { Button, ButtonProps } from 'antd';

import colors from 'core/lib/theme/colors';

export const ToolbarButton = ({
  active,
  reversed,
  icon,
  ...props
}: PropsWithChildren<
  {
    active: boolean;
    reversed?: boolean;
    icon: ReactElement;
  } & ButtonProps
>) => <Button {...props} type="text" style={{ color: active ? colors.Primary.rgba : colors.Black.rgba }} icon={icon} />;
