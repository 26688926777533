import React from 'react';

import { DealBaseListing } from 'core/lib';

import {
  DealsByCountry,
  DealsByFollowUpDate,
  DealsByAssigned,
  DealsByIndustry,
  DealsByStage,
  DealsByStatus,
  DealsByTechnology,
  DealsByTier,
  DealsOpportunity,
} from './components';
import ListingFilters from '../filters/filters';

type DealBaseDashboardProps = {
  listingData?: DealBaseListing[];
};

const DealBaseDashboard = ({ listingData }: DealBaseDashboardProps) => {
  return (
    <>
      <ListingFilters />
      <div className="grid sm:grid-cols-1 lg:grid-cols-2 gap-8">
        <div className="flex-auto">
          <DealsByStatus data={listingData} />
        </div>
        <div className="flex-auto">
          <DealsByTier data={listingData} />
        </div>
      </div>
      <DealsOpportunity data={listingData} />
      <div className="grid sm:grid-cols-1 lg:grid-cols-2 gap-8">
        <div className="flex-auto">
          <DealsByAssigned data={listingData} />
        </div>
        <div className="flex-auto">
          <DealsByFollowUpDate data={listingData} />
        </div>
      </div>
      <div className="flex lg:flex-row flex-col gap-8">
        <div className="flex-auto">
          <DealsByIndustry data={listingData} />
        </div>
        <div className="flex-auto">
          <DealsByTechnology data={listingData} />
        </div>
      </div>
      <div className="flex lg:flex-row flex-col gap-8">
        <div className="flex-auto">
          <DealsByStage data={listingData} />
        </div>
        <div className="flex-auto">
          <DealsByCountry data={listingData} />
        </div>
      </div>
      {/* <DealsByProductStatus data={listingData} /> */}
    </>
  );
};

export default DealBaseDashboard;
