import _, { groupBy /* , { countBy, isNil } */ } from 'lodash';
import { createSelector } from 'reselect';

import { UserStatus } from 'core/lib/constants/statuses';
import { UserListing, UserMenu } from 'core/lib/modules/users/entities';

import { AppState } from '../../../store';
import { selectLeftMenuFiltersToggled } from '../../app/store';

const selectSelf = (state: AppState) => state.user;

export const selectUserListingGrouping = createSelector(selectSelf, (user) => user.listingGrouping);

export const selectUserListingFilters = createSelector(selectSelf, (user) => user.filters);

export const selectUserStatusFilters = createSelector(selectUserListingFilters, (filters) => filters.status);

export const selectUserOrganizationFilters = createSelector( selectUserListingFilters, (filters) => filters.organizations);

export const selectUserRoleFilters = createSelector( selectUserListingFilters, (filters) => filters.roles);

export const selectUserFilteredListingByStatusGroup = createSelector(
  selectUserListingGrouping,
  (_: AppState, listingData?: UserListing[]) => listingData,
  (listingGroup, listingData) => {
    return listingGroup ? groupBy(listingData, 'status')[listingGroup] : listingData;
  }
);

export const selectUserFilteredListing = createSelector(
  selectUserListingFilters,
  (_: AppState, listingData?: UserListing[]) => listingData,
  (filters, listingData) => {
    if (!listingData) {
      return listingData;
    }
    const filterByStatus = (data: UserListing) =>
      !filters.status?.length || !!filters.status?.includes(data.status as UserStatus);
    const filtersByRoles = (data: UserListing) =>
      !filters.roles?.length || !!( data?.systemRoles && data.systemRoles.some((role) => filters.roles?.includes(role.id)));
    const filterByOrganization = (data: UserListing) =>
      !filters.organizations?.length ||
      !!(
        data?.organizations &&
        data?.organizations.some((organization) => filters.organizations?.includes(organization.id))
      );

    return _(listingData)
      .filter(filterByStatus)
      .filter(filtersByRoles)
      .filter(filterByOrganization)
      .value() as UserListing[];
  }
);

export const selectUserFilteredMenu = createSelector(
  selectUserListingFilters,
  selectLeftMenuFiltersToggled,
  (_: AppState, listingData?: UserMenu[]) => listingData,
  (filters, leftMenuFiltersToggled, listingData) => {
    if (!listingData || !leftMenuFiltersToggled) {
      return listingData;
    }
    const filterByStatus = (data: UserMenu) =>
      !filters.status?.length || !!filters.status?.includes(data.status as UserStatus);
    const filtersByOrganizations = (data: UserMenu) =>
      !filters.organizations?.length ||
      !!(
        data?.organizationIds &&
        data?.organizationIds.some((organizatonId) => filters.organizations?.includes(organizatonId))
      );
    const filtersByRoles = (data: UserMenu) =>
      !filters.roles?.length || !!( data?.systemRoles && data.systemRoles.some((role) => filters.roles?.includes(role.id)));

    return _(listingData)
      .filter(filterByStatus)
      .filter(filtersByOrganizations)
      .filter(filtersByRoles)
      .value() as UserMenu[];
  }
);

export const selectUserFiltersCount = (isMobile: boolean) =>
  createSelector(selectUserListingFilters, (filters) => {
    return [
      ...(isMobile ? [!!filters.status?.length, !!filters.organizations?.length] : []),
      !!filters.roles?.length,
    ].filter((filter) => filter).length;
  });
