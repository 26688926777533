import React from 'react';

import { useTranslation } from 'react-i18next';
import { generatePath, Link } from 'react-router-dom';

import { conversationsApi } from 'core/lib';
import { Routes } from 'routes/routes';

import MenuItemConversationCreate from './menuItemConversationCreate';

type MenuItemConversationActionsProps = {
  objectType: number;
  objectId?: number;
  objectName?: string;
};

const MenuItemConversationActions = ({ objectType, objectId, ...rest }: MenuItemConversationActionsProps) => {
  const { t } = useTranslation();

  const { data } = conversationsApi.useGetModuleConversationExistsQuery(
    { objectType, objectId },
    { refetchOnMountOrArgChange: true }
  );

  return data ? (
    <Link to={generatePath(Routes.CONVERSATIONS_MODULE_DETAILS.path, { id: data })}>
      {t('actions:global.viewConversation')}
    </Link>
  ) : (
    <MenuItemConversationCreate objectType={objectType} objectId={objectId} {...rest} />
  );
};

export default MenuItemConversationActions;
