import React from 'react';

import { Button, Typography } from 'antd';
import { Trans } from 'react-i18next';
import { generatePath, Link as RouteLink } from 'react-router-dom';

import { Routes } from 'routes/routes';

const { Link } = Typography;

type PopoverContactButtonProps = {
  id: number;
  email: string;
};

const PopoverContactButton = ({ id, email }: PopoverContactButtonProps) => {
  return (
    <div className="flex flex-row gap-2">
      <Link className="flex flex-auto" href={`mailto:${email}`}>
        <Button size="small" className="w-full" type="primary">
          <Trans i18nKey="contactBook:actions.contact" />
        </Button>
      </Link>
      <RouteLink className="flex flex-auto" to={generatePath(Routes.CONTACT_BOOK_DETAILS.path, { id })}>
        <Button size="small" className="w-full" type="default">
          <Trans i18nKey="contactBook:actions.seeDetails" />
        </Button>
      </RouteLink>
    </div>
  );
};

export default PopoverContactButton;
