import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import { user as dkUser } from './dk';
import {
  account,
  actions,
  conversations,
  contactBook,
  dealbase,
  forms,
  global,
  loaders,
  notifications,
  organization,
  portfolio,
  user,
} from './en';

export const resources = {
  en: {
    account,
    actions,
    conversations,
    contactBook,
    loaders,
    forms,
    global,
    dealbase,
    notifications,
    organization,
    portfolio,
    user,
  },
  dk: { user: dkUser },
} as const;

i18next.use(initReactI18next).init({
  fallbackLng: 'en',
  interpolation: {
    defaultVariables: {
      count: 0,
    },
  },
  ns: [
    'account',
    'actions',
    'conversations',
    'contactBook',
    'loaders',
    'forms',
    'global',
    'dealbase',
    'notifications',
    'organization',
    'portfolio',
    'user',
  ],
  defaultNS: 'global',
  resources,
  react: {
    bindI18n: 'languageChanged',
    bindI18nStore: '',
    transEmptyNodeValue: '',
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
    useSuspense: false,
  },
});

i18next.services.formatter?.add('lowercase', (value: string) => {
  return value.toLowerCase();
});

export default i18next;
