import React, { useContext, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import Comments from 'common-ui/comments';
import { dealBaseApi, DraftBaseComment } from 'core/lib';
import { DealBaseDetailsContext } from 'modules/dealBase/context';
import LoaderWrapper from 'utils/loader-wrapper';

type DecisionsSectionProps = {
  editMode: boolean;
};

const DealBaseDecisionsSection = ({ editMode }: DecisionsSectionProps) => {
  const dealbase = useContext(DealBaseDetailsContext);
  const { t } = useTranslation();
  const [getDecisions, { data, isFetching }] = dealBaseApi.useLazyGetDecisionsQuery();
  const [postDecision, { isLoading: isSaving }] = dealBaseApi.usePostDecisionMutation();
  const [deleteDecision, { isLoading: isDeleting }] = dealBaseApi.useDeleteDecisionMutation();

  useEffect(() => {
    if (dealbase?.id) {
      getDecisions(dealbase.id);
    }
  }, [dealbase?.id, getDecisions]);

  const onSubmit = (decision: Partial<DraftBaseComment>, callback?: () => void) => {
    if (dealbase?.id) {
      decision.objectId = dealbase.id;
      postDecision(decision)
        .unwrap()
        .then(() => {
          if (callback) {
            callback();
          }
        });
    }
  };

  const onReplySubmit = (parentId: number, decision: Partial<DraftBaseComment>, callback?: () => void) => {
    if (dealbase?.id) {
      decision.objectId = dealbase.id;
      decision.replyToId = parentId;
      postDecision(decision)
        .unwrap()
        .then(() => {
          if (callback) {
            callback();
          }
        });
    }
  };

  const onDelete = (userCommentId: number, callback?: () => void) => {
    if (dealbase?.id) {
      deleteDecision({ id: userCommentId, objectId: dealbase.id })
        .unwrap()
        .then(() => {
          if (callback) {
            callback();
          }
        });
    }
  };

  return (
    <LoaderWrapper loading={isFetching} message={t('loaders:dealBase.loadingDecisions')}>
      <div className="flex flex-col">
        <Comments
          type={t('global:general.decision')}
          isSaving={isSaving || isDeleting}
          onDelete={onDelete}
          onSubmit={onSubmit}
          onReplySubmit={onReplySubmit}
          disabled={!editMode}
          comments={data ?? []}
        />
      </div>
    </LoaderWrapper>
  );
};

export default DealBaseDecisionsSection;
