import React, { useContext, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { ContentContainer, MainContentContainer } from 'common-ui/containers';
import { ActivityList } from 'common-ui/lists';
import { contactBookApi } from 'core/lib';
import { ContactBookDetailsContext } from 'modules/contactBook/context';
import LoaderWrapper from 'utils/loader-wrapper';

const ContactBookActivityTab = () => {
  const contactBook = useContext(ContactBookDetailsContext);
  const { t } = useTranslation();
  const [getHistory, { data, isFetching }] = contactBookApi.useLazyGetHistoryQuery();

  useEffect(() => {
    if (contactBook?.id) {
      getHistory(contactBook.id);
    }
  }, [contactBook?.id, getHistory]);

  return (
    <MainContentContainer id="contactBook-activity">
      <ContentContainer>
        <LoaderWrapper loading={isFetching} message={t('loaders:contactBook.loadingActivity')}>
          <ActivityList data={data ?? []} />
        </LoaderWrapper>
      </ContentContainer>
    </MainContentContainer>
  );
};

export default ContactBookActivityTab;
