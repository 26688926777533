import React from 'react';

import { PageHeader, PageHeaderProps, Typography } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { useMedia } from 'react-use';

import FavoriteToggle from 'common-ui/favorite';
import { ContactBookDetails } from 'core/lib';
import { OBJECT_TYPES } from 'core/lib/constants';

import { DealBaseDetailsHeaderActions } from '.';

const { Title } = Typography;

type ContactBookDetailsPageHeaderProps = {
  defaultSize?: SizeType;
  contactBook: ContactBookDetails;
  onBack?: () => void;
};

const ContactBookDetailsPageHeader = ({
  contactBook,
  defaultSize,
  onBack,
  ...props
}: ContactBookDetailsPageHeaderProps & PageHeaderProps) => {
  const isMobile = useMedia('(max-width: 768px)');
  const size = defaultSize ?? isMobile ? 'small' : 'middle';
  const textLevel = isMobile || defaultSize === 'small' ? 5 : 4;

  return (
    <PageHeader
      {...props}
      onBack={onBack}
      title={
        <div className="flex flex-row items-center gap-2">
          <Title className="ws-normal md:truncate m-0" level={textLevel}>
            {contactBook.name}
          </Title>
          <div className="flex">
            <Title className="m-0" type="secondary" level={5}>{`#${contactBook.id}`}</Title>
          </div>
          <FavoriteToggle objectType={OBJECT_TYPES.CONTACT_BOOK} objectId={contactBook.id} />
        </div>
      }
      extra={[<DealBaseDetailsHeaderActions size={size} contactBook={contactBook} key="actionsMenu" />]}
    />
  );
};

export default ContactBookDetailsPageHeader;
