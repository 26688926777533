import React, { useEffect } from 'react';

import { Button, ButtonProps, notification } from 'antd';
import { useTranslation } from 'react-i18next';

import { RequestError, userApi, UserDetails } from 'core/lib';
import { EXCEPTIONS } from 'core/lib/constants';
import { USER_STATUS } from 'core/lib/constants/statuses';

type UserStatusButtonActionProps = {
  user: UserDetails;
};

const UserStatusButtonAction = ({ user, ...props }: UserStatusButtonActionProps & ButtonProps) => {
  const { t } = useTranslation();
  const [activateUser, { isLoading: isActivating, error: activateUserError }] = userApi.useActivateUserMutation();
  const [deactivateUser, { isLoading: isDeactivating }] = userApi.useDeactivateUserMutation();

  const onUserActivateClick = () => {
    activateUser(user.id);
  };

  useEffect(() => {
    if (activateUserError) {
      const key = `open${Date.now()}`;

      const errorData = activateUserError as RequestError;
      if (errorData.data.ExceptionType === EXCEPTIONS.SubscriptionLimit) {
        notification.open({
          type: 'warning',
          message: t(`user:errors.maximumNumberOfUsers`),
          description: t('user:errors.activateUserMaximumNumberOfUsersDescription'),
          key,
        });
      }
    }
  }, [activateUserError, t]); // eslint-disable-line react-hooks/exhaustive-deps

  const onUserDeactivateClick = () => {
    deactivateUser(user.id);
  };

  return user.status === USER_STATUS.ACTIVE ? (
    <Button {...props} danger type="primary" onClick={onUserDeactivateClick} loading={isDeactivating}>
      {t('actions:global.deactivate')}
    </Button>
  ) : (
    <Button {...props} type="primary" onClick={onUserActivateClick} loading={isActivating}>
      {t('actions:global.activate')}
    </Button>
  );
};

export default UserStatusButtonAction;
