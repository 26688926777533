import React from 'react';

import { Select, SelectProps, Tag } from 'antd';
import { PresetColorType } from 'antd/lib/_util/colors';

type BaseMultiSelectProps = {
  asTag?: boolean;
  tagColor?: PresetColorType;
};
function BaseMultiSelect<T extends string | number>({
  asTag,
  tagColor,
  ...props
}: SelectProps<T[]> & BaseMultiSelectProps) {
  const displayTags = props.disabled && asTag && props.value?.length;
  if (displayTags) {
    return (
      <div className="flex flex-wrap gap-1">
        {props.value?.map((item) => (
          <Tag className="m-0" key={item} color={tagColor}>
            {item}
          </Tag>
        ))}
      </div>
    );
  }

  return <Select {...props} />;
}

export default BaseMultiSelect;
