import React, { useEffect, useMemo } from 'react';

import { debounce } from 'lodash';
import { useMedia } from 'react-use';

import {
  contactBookApi,
  dealBaseApi,
  portfolioApi,
  selectGlobalSearch,
  selectSearchModule,
  setGlobalSearch,
  useAppDispatch,
  useAppSelector,
} from 'core/lib';
import { OBJECT_TYPES } from 'core/lib/constants';

import { InlineSearch, SidebarSearch } from '.';

const ModuleSearch = () => {
  const globalSearch = useAppSelector(selectGlobalSearch);
  const searchModule = useAppSelector(selectSearchModule);
  const dispatch = useAppDispatch();
  const isMobile = useMedia('(max-width: 768px)');

  const [searchDealBases, { data: dealbaseSearchData, isFetching: isDealbaseSearchLoading }] =
    dealBaseApi.useLazyGetDealBaseSearchQuery();
  const [searchPortfolios, { data: portfolioSearchData, isFetching: isPortfolioSearchLoading }] =
    portfolioApi.useLazyGetPortfolioSearchQuery();
  const [searchContactBooks, { data: contactBookSearchData, isFetching: isContactBookSearchLoading }] =
    contactBookApi.useLazyGetContactBookSearchQuery();

  const debounceSearch = useMemo(() => {
    let moduleSearchMethod = null;
    switch (searchModule) {
      case OBJECT_TYPES.DEAL_BASE:
        moduleSearchMethod = searchDealBases;
        break;
      case OBJECT_TYPES.PORTFOLIO:
        moduleSearchMethod = searchPortfolios;
        break;
      case OBJECT_TYPES.CONTACT_BOOK:
        moduleSearchMethod = searchContactBooks;
        break;
      default:
        moduleSearchMethod = () => {};
    }

    return debounce(moduleSearchMethod, 500);
  }, [searchModule, searchDealBases, searchPortfolios, searchContactBooks]);

  const data = useMemo(() => {
    switch (searchModule) {
      case OBJECT_TYPES.DEAL_BASE:
        return dealbaseSearchData;
      case OBJECT_TYPES.PORTFOLIO:
        return portfolioSearchData;
      case OBJECT_TYPES.CONTACT_BOOK:
        return contactBookSearchData;
      default:
        return [];
    }
  }, [searchModule, portfolioSearchData, dealbaseSearchData, contactBookSearchData]);

  const onSearch = (searchText: string) => {
    dispatch(setGlobalSearch(searchText));
  };

  const isLoading = isDealbaseSearchLoading || isPortfolioSearchLoading || isContactBookSearchLoading;

  useEffect(() => {
    if (globalSearch) {
      debounceSearch(globalSearch, false);
    }
  }, [globalSearch, debounceSearch]);

  const SearchComponent = useMemo(() => (isMobile ? SidebarSearch : InlineSearch), [isMobile]);

  return (
    <SearchComponent
      search={globalSearch}
      isLoading={isLoading}
      onSearch={onSearch}
      result={globalSearch ? data : []}
    />
  );
};

export default ModuleSearch;
