import React from 'react';

import { Button, Result } from 'antd';
import { ResultStatusType } from 'antd/lib/result';
import { useTranslation } from 'react-i18next';

type ResultWrapperProps = {
  onConfirm: () => void;
  status?: ResultStatusType;
  children: React.ReactNode;
};

const ResultWrapper = ({ onConfirm, status, children }: ResultWrapperProps) => {
  const { t } = useTranslation();

  return status ? (
    <Result
      className="flex flex-col flex-auto justify-center items-center "
      status={status}
      title={t('global:messages.thanksForSubmission')}
      subTitle={t('global:messages.emailSubmissionMessage')}
      extra={[
        <Button onClick={onConfirm} type="primary" key="console">
          {t('global:navigation:backToSubmission')}
        </Button>,
      ]}
    />
  ) : (
    <>{children}</>
  );
};

export default ResultWrapper;
