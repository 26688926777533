import React, { useMemo, useEffect } from 'react';

import { EllipsisOutlined } from '@ant-design/icons';
import { Button, ButtonProps, Dropdown } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { useTranslation } from 'react-i18next';

import { DROPDOWN_TRIGGERS, MenuItemConversationAction } from 'common-ui';
import { OBJECT_TYPES } from 'core/lib/constants';
import appApi from 'core/lib/modules/app/services/app.api';
import { PortfolioDetails } from 'core/lib/modules/portfolio';
import { downloadAuditLog } from 'core/lib/utils/requesting';

type DetailsHeaderActionsProps = {
  portfolio: PortfolioDetails;
};

const DetailsHeaderActions = ({ portfolio, ...props }: DetailsHeaderActionsProps & ButtonProps) => {
  const { t } = useTranslation('portfolio');

  const [createAuditLog, { data: blobId, isSuccess: createAuditLogSuccess }] = appApi.useLazyCreateAuditLogQuery();
  const [downloadfile, { data: downloadData }] = appApi.useLazyDownloadBase64Query();

  const onExportAuditLogClick = () => {
    createAuditLog({ objectId: portfolio.id, objectType: OBJECT_TYPES.PORTFOLIO });
  };

  useEffect(() => {
    if (createAuditLogSuccess && blobId) {
      downloadfile(blobId);
    }
  }, [createAuditLogSuccess, blobId, downloadfile]);

  useEffect(() => {
    if (downloadData) {
      downloadAuditLog(t('global:moduleNames.portfolio'), downloadData);
    }
  }, [downloadData, t]);

  const items: ItemType[] = useMemo(() => {
    return [
      {
        key: 'conversation_action',
        label: (
          <MenuItemConversationAction
            objectType={OBJECT_TYPES.PORTFOLIO}
            objectId={portfolio.id}
            objectName={portfolio.name}
          />
        ),
      },
      { key: 'export_audit_log', label: t('actions:global.exportAuditLog'), onClick: onExportAuditLogClick },
    ];
  }, [portfolio, t]); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Dropdown trigger={DROPDOWN_TRIGGERS} menu={{ items }}>
        <Button {...props} type="default">
          <EllipsisOutlined />
        </Button>
      </Dropdown>
      {}
    </>
  );
};

export default DetailsHeaderActions;
