import React from 'react';

import { Popconfirm, PopconfirmProps } from 'antd';

const PopConfirmCancelWrapper = (props: PopconfirmProps & { children: JSX.Element }) => {
  return (
    <Popconfirm {...props}>
      {React.cloneElement(props.children, { onClick: props.disabled ? props.onConfirm : null })}
    </Popconfirm>
  );
};

export default PopConfirmCancelWrapper;
