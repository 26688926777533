import React, { useMemo } from 'react';

import { DragEndEvent } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { List } from 'antd';

import { SortableItem, DnDWrapper } from 'common-ui/sortable';
import { MenuPreference } from 'core/lib';

type OrderSelectProps = {
  menu?: (MenuPreference & { name: string })[];
  onChange?: (menu: Partial<MenuPreference>[]) => void;
  disabled: boolean;
};

function OrderSelect({ menu = [], onChange, disabled }: OrderSelectProps) {
  const draggableItemsIds = useMemo(() => {
    return menu.map((m) => m.menuId);
  }, [menu]);

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const updatedSettings = arrayMove(
        menu ?? [],
        active?.data?.current?.displayOrder,
        over?.data?.current?.displayOrder
      ).map((setting, index) => ({
        ...setting,
        displayOrder: index,
      }));

      if (onChange) {
        onChange(updatedSettings);
      }
    }
  };

  return (
    <>
      <DnDWrapper disabled={disabled} onDragEnd={handleDragEnd} draggableItemsIds={draggableItemsIds}>
        <List
          className="mr3"
          size="small"
          itemLayout="vertical"
          dataSource={menu}
          split={false}
          renderItem={(item) => (
            <SortableItem
              bordered
              disabled={disabled}
              className="px-4 py-2 mb-2"
              handle
              key={item.menuId}
              data={item}
              id={item.menuId!}
            >
              {item.name}
            </SortableItem>
          )}
        />
      </DnDWrapper>
    </>
  );
}

export default OrderSelect;
