import React, { useMemo, useState } from 'react';

import { Select, SelectProps, Tag } from 'antd';
import { PresetColorType } from 'antd/lib/_util/colors';
import { uniqBy } from 'lodash';

import { CommonObject, commonObjectsApi } from 'core/lib';

import { CommonObjectSelectProps } from './commonObjectSelect';

export interface CommonObjectMultiSelectProps extends Omit<CommonObjectSelectProps, 'selectedOption'> {
  max?: number;
  asTags?: boolean;
  color?: PresetColorType;
  selectedOption?: CommonObject[];
}

const CommonObjectMultiSelect = ({
  type,
  archived,
  objectType,
  max,
  asTags,
  color,
  selectedOption,
  ...props
}: CommonObjectMultiSelectProps & SelectProps<number[]>) => {
  const [initialSelection] = useState(
    selectedOption?.map((opt) => ({
      id: opt.id,
      value: opt.id,
      label: opt.name,
    })) ?? []
  );
  const [getCommonObjectOptions, { data, isFetching }] = commonObjectsApi.useLazyGetCommonObjectOptionsQuery();

  const onDropdownVisibleChange = (open: boolean) => {
    if (open) {
      getCommonObjectOptions({ objectType, type, archived }, true);
    }
  };

  const value = useMemo(() => selectedOption?.map((so) => so.id) ?? [], [selectedOption]);

  const selectOptions = useMemo(() => {
    const options =
      data?.map((opt) => {
        if (max && (selectedOption?.length ?? 0) >= max) {
          return { ...opt, disabled: !value?.includes(opt.id) };
        }
        return opt;
      }) ?? [];

    return uniqBy(options.concat(initialSelection), 'value');
  }, [data, max, initialSelection, selectedOption, value]);

  if (props.disabled && asTags && selectedOption?.length) {
    return (
      <div className="flex flex-wrap gap-1">
        {selectedOption?.map((item) => (
          <Tag className="m-0" key={item.id} color={color}>
            {item.name}
          </Tag>
        ))}
      </div>
    );
  }

  return (
    <Select
      showSearch
      allowClear
      optionFilterProp="label"
      mode="multiple"
      loading={isFetching}
      value={value}
      options={selectOptions}
      onDropdownVisibleChange={onDropdownVisibleChange}
      dropdownMatchSelectWidth={150}
      {...props}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onChange={(_val, option: any) => {
        props.onChange?.(option, option);
      }}
    />
  );
};

export default CommonObjectMultiSelect;
