import {
  MenuPreference,
  MenuPreferenceResponse,
  UserNotificationPreferences,
  UserNotificationPreferencesResponse,
  UserPreferences,
  UserPreferencesResponse,
} from '../../entities';

const deserializeMenuPreferenceList = (response: MenuPreferenceResponse[]): MenuPreference[] => {
  return response.map((menuItem) => {
    return {
      id: menuItem.ID,
      objectType: menuItem.ObjectType,
      menuId: menuItem.MenuID,
      displayOrder: menuItem.DisplayOrder,
    };
  });
};

export const deserializerUserPreferences = (response: UserPreferencesResponse | null): UserPreferences | null => {
  return (
    response && {
      id: response.ID,
      homepageObjectType: response.HomepageObjectType,
      homepageType: response.HomepageType,
      leftMenuOpen: response.LeftMenuOpen,
      menuDefaultType: response.MenuDefaultType,
      language: response.Language,
      menuPreferences: response.MenuPreferences && deserializeMenuPreferenceList(response.MenuPreferences),
    }
  );
};

export const deserializerUserNotificationPreferences = (
  response: UserNotificationPreferencesResponse[]
): UserNotificationPreferences[] => {
  return response.map((res) => {
    return {
      eventType: res.EventType,
      group: res.Group,
      mediums: res.Mediums.map((medium) => ({ type: medium.Type, active: medium.Active })),
    };
  });
};
