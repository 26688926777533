import React from 'react';

import { Tag } from 'antd';

type CountryTagProps = {
  country: string;
};

const CountryTag = ({ country }: CountryTagProps) => {
  return <Tag color="purple">{country}</Tag>;
};

export default CountryTag;
