import { createSelector } from 'reselect';

import {
  INDEXED_MODULES,
  MODULE_DYNAMIC,
  MODULE_OPTIONS,
  MODULE_SEARCH_OPTIONS,
  OBJECT_TYPES,
  SYSTEM_ROLES,
} from 'core/lib/constants';
import { hasRole } from 'core/lib/utils/userHelper';

import { AppState, DetailsSectionPanel } from '../../..';
import { userHasModuleAccess } from '../../../utils';
import { selectAuthenticatedUserRole, selectClientConfiguration } from '../../auth/store';

const selectSelf = (state: AppState) => state.app;

export const selectLeftMenuState = createSelector(selectSelf, (app) => app.leftMenuToggled);

export const selectLeftMenuFiltersToggled = createSelector(selectSelf, (app) => app.leftMenuFiltersToggled);

export const selectActiveModule = createSelector(selectSelf, (app) => app.activeModule);

export const selectSearchModule = createSelector(selectSelf, (app) => app.searchModule);

export const selectGlobalSearch = createSelector(selectSelf, (app) => app.globalSearch);

export const selectCustomFieldsMenuId = createSelector(selectSelf, (app) => app.customFieldsMenuId);

export const selectGlobalSearchBarToggled = createSelector(selectSelf, (app) => app.globalSearchBarToggled);

export const selectUserPreference = createSelector(selectSelf, (app) => app.userPreferences);

export const selectUserMenuPreferences = createSelector(
  selectUserPreference,
  (userPreferences) => userPreferences?.menuPreferences
);

export const selectUserPreferenceMenuDefaultType = createSelector(
  selectUserPreference,
  (userPreferences) => userPreferences?.menuDefaultType
);

export const selectModuleSearchOptions = createSelector(selectClientConfiguration, (clientConfiguration) => {
  return MODULE_SEARCH_OPTIONS.filter((module) => userHasModuleAccess(module.value, clientConfiguration));
});

export const selectModuleOptions = createSelector(selectAuthenticatedUserRole, (userRole) => {
  if (userRole && hasRole(userRole, [SYSTEM_ROLES.CLIENT_ADMIN, SYSTEM_ROLES.SUPER_ADMIN])) {
    return MODULE_OPTIONS;
  } else if (userRole) {
    return MODULE_OPTIONS.filter((module) => ![OBJECT_TYPES.USER, OBJECT_TYPES.ORGANIZATION].includes(module.value));
  }
  return [];
});

export const selectModulesWithCustomFields = createSelector(selectClientConfiguration, (clientConfiguration) => {
  return Object.keys(MODULE_DYNAMIC)
    .filter((moduleObjectType) => userHasModuleAccess(parseInt(moduleObjectType), clientConfiguration))
    .map((moduleObjectType) => INDEXED_MODULES[moduleObjectType]);
});

export const selectConversationModuleOptions = createSelector(
  selectModuleOptions,
  selectClientConfiguration,
  (moduleOptions, clientConfiguration) => {
    return moduleOptions.filter(
      (module) =>
        module.value !== OBJECT_TYPES.MY_CONVERSATIONS && userHasModuleAccess(module.value, clientConfiguration)
    );
  }
);

export const detailsSectionPanesSelector = createSelector(
  ({ data }: { data: DetailsSectionPanel[] | undefined }) => data,
  (_: DetailsSectionPanel[] | undefined, sectionKeys: string[]) => sectionKeys,
  (data: DetailsSectionPanel[] | undefined, sectionKeys: string[]) =>
    data?.filter((sectionPanel) => sectionKeys.includes(sectionPanel.name))
);
