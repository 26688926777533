import React from 'react';

import { Input, InputProps } from 'antd';
import { Trans } from 'react-i18next';

import { UserMultiSelect } from 'utils/form';
import { rules } from 'utils/form/rules';

export const organizationFormConfig = {
  name: {
    label: <Trans i18nKey="organization:form.labels.name" />,
    dataIndex: 'name',
    rules: [rules.required],
    component: (props: InputProps) => <Input maxLength={40} {...props} />,
  },

  users: {
    label: <Trans i18nKey="organization:form.labels.users" />,
    dataIndex: 'users',
    rules: [],
    component: UserMultiSelect,
  },
};
