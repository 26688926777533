import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { DownOutlined } from '@ant-design/icons';
import { Button, Checkbox, Dropdown } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { intersection } from 'lodash';
import { useTranslation } from 'react-i18next';

import { DROPDOWN_TRIGGERS, FilterActions, FilterOperatorComponent } from 'common-ui';
import { contactBookApi, useAppDispatch, useAppSelector } from 'core/lib';
import {
  selectContactBookTagsFilters,
  selectContactBookTagsOperator,
  setContactBookFilters,
} from 'core/lib/modules/contactBook/store';

const ContactBookTagsFilter = () => {
  const [visible, setVisibility] = useState(false);
  const tagsOperator = useAppSelector(selectContactBookTagsOperator);
  const [draftTagsOperator, setDraftTagsOperator] = useState(tagsOperator);

  const tagsFilters = useAppSelector(selectContactBookTagsFilters);
  const [draftFilters, setDraftFilters] = useState(tagsFilters);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { data: tagOptions } = contactBookApi.useGetContactBookTagsQuery();

  const tagChecked = useCallback((type: string) => draftFilters?.includes(type), [draftFilters]);

  useEffect(() => {
    setDraftFilters(tagsFilters);
  }, [tagsFilters]);

  const filtersUpdated = useMemo(() => {
    return (
      draftTagsOperator !== tagsOperator ||
      tagsFilters?.length !== draftFilters?.length ||
      intersection(draftFilters, tagsFilters).length !== tagsFilters?.length
    );
  }, [draftFilters, draftTagsOperator, tagsOperator, tagsFilters]);

  const onFilterUpdate = (tag: string) => {
    if (tagChecked(tag)) {
      setDraftFilters(draftFilters?.filter((draftTag) => draftTag !== tag));
    } else {
      setDraftFilters((draftFilters ?? []).concat(tag));
    }
  };

  const onApply = () => {
    dispatch(setContactBookFilters({ tags: draftFilters, tagsOperator: draftTagsOperator }));
  };

  const onClear = () => {
    setDraftFilters([]);
  };

  const items: ItemType[] = useMemo(() => {
    return [
      {
        key: 'operator',
        label: (
          <FilterOperatorComponent
            onChange={({ tagsOperator }) => setDraftTagsOperator(tagsOperator!)}
            operator={draftTagsOperator}
            filterKey="tagsOperator"
          />
        ),
      },
      { type: 'divider' },
      {
        key: 'user_group',
        type: 'group',
        className: 'max-h-64 overflow-y-auto p-0',
        children: tagOptions?.map((item) => ({
          key: item,
          label: (
            <Checkbox onChange={() => onFilterUpdate(item)} checked={tagChecked(item)} className="flex flex-auto">
              {item}
            </Checkbox>
          ),
        })),
      },
      { type: 'divider' },
      {
        type: 'group',
        key: 'status_actions',
        label: <FilterActions onClear={onClear} onApply={onApply} filtersUpdated={filtersUpdated} />,
      },
    ];
  }, [filtersUpdated, tagOptions, draftTagsOperator, tagChecked, t]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <Dropdown
        trigger={DROPDOWN_TRIGGERS}
        onOpenChange={(val) => setVisibility(val)}
        mouseLeaveDelay={0.2}
        open={visible}
        menu={{ items }}
      >
        <Button>
          {t('contactBook:filters.tagsCount', { count: tagsFilters?.length })} <DownOutlined />
        </Button>
      </Dropdown>
    </div>
  );
};

export default ContactBookTagsFilter;
