import React, { useCallback, useMemo } from 'react';

import { Datum, Pie, PieConfig } from '@ant-design/charts';
import { View } from '@antv/g2';
import { Data } from '@antv/g2plot';
import { Typography } from 'antd';
import { groupBy, sum, sumBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { TierOptions } from 'core/lib/constants/selects';
import { DealBaseListing } from 'core/lib/modules/dealBase/entities';
import { setDealBaseFilters } from 'core/lib/modules/dealBase/store';
import { Routes } from 'routes/routes';

const { Title } = Typography;

type DealsByTierProps = {
  data?: DealBaseListing[];
};

type ChartSeries = {
  tier: string;
  value: number;
  opportunity: number;
}[];

const mapDispatch = {
  applyFilters: (tier: number[] | undefined) => setDealBaseFilters({ tier }),
};

const connector = connect(undefined, mapDispatch, (_, dispatchProps, ownProps: DealsByTierProps) => ({
  ...ownProps,
  ...dispatchProps,
}));

type DealsByTierConnectedProps = ConnectedProps<typeof connector>;

const DealsByTier = ({ applyFilters, data }: DealsByTierConnectedProps) => {
  const { t } = useTranslation();
  const history = useHistory();

  const getTierOpportunity = useCallback((tierData?: DealBaseListing[]) => {
    return tierData ? sum(tierData.map((d) => (d.neTicket ? parseFloat(d.neTicket) : 0))) : 0;
  }, []);

  const series: ChartSeries = useMemo(() => {
    const groupedData = groupBy(data, 'tier');
    return Object.values(
      TierOptions.reduce((agg, { value: tier }) => {
        agg[tier] = {
          tier: tier.toString(),
          value: groupedData[tier]?.length ?? 0,
          opportunity: getTierOpportunity(groupedData[tier]),
        };
        return agg;
      }, {})
    );
  }, [data, getTierOpportunity]);

  function handleChartStatisticsClick(data: ChartSeries) {
    const tierFilters = data.map((t) => parseInt(t.tier));
    applyFilters(tierFilters);
    history.push(Routes.DEAL_BASE_LISTING.path);
  }

  const config: PieConfig = {
    height: 300,
    appendPadding: 40,
    data: series,
    angleField: 'value',
    colorField: 'tier',
    radius: 1,
    innerRadius: 0.6,
    label: false,
    legend: {
      itemWidth: 100,
      itemName: {
        formatter: (text) => `T${text} -`,
      },
      itemValue: {
        formatter: (text, item, index) => {
          return series[index]?.value;
        },
      },
      offsetX: -20,
      layout: 'vertical',
    },
    statistic: {
      title: {
        style: {
          fontSize: '1em',
        },
      },
      content: {
        style: { fontSize: '2em' },
        customHtml: (container: HTMLElement, view: View, datum?: Datum, data?: Data) => {
          container.addEventListener('click', () => handleChartStatisticsClick(data as ChartSeries));
          container.style.pointerEvents = 'auto';
          container.style.cursor = 'pointer';
          return sumBy(data as ChartSeries, (tierData) => tierData.value).toString();
        },
      },
    },
  };

  return (
    <div className="flex flex-col w-full gap-2">
      <Title level={5}>{t('dealbase:dashboards.titles.dealsByTier')}</Title>
      <div className="border border-solid b--grey-200">
        <Pie {...config} />
      </div>
    </div>
  );
};

export default connector(DealsByTier);
