import React, { useCallback } from 'react';

import { EllipsisOutlined } from '@ant-design/icons';
import { Button, Dropdown } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { DROPDOWN_TRIGGERS, MenuItemConversationAction } from 'common-ui';
import { OBJECT_TYPES, SYSTEM_ROLES } from 'core/lib/constants';
import { Routes } from 'routes/routes';
import PermissionControl, { PermissionRules } from 'utils/permissionControl';

const ListingHeaderActions = () => {
  const { t } = useTranslation('actions');

  const getItems = useCallback(
    (disabled: boolean) => {
      return [
        {
          key: 'create_action',
          disabled,
          label: <Link to={Routes.PORTFOLIO_NEW.path}>{t('global.create')}</Link>,
        },
        { key: 'conversation_action', label: <MenuItemConversationAction objectType={OBJECT_TYPES.PORTFOLIO} /> },
      ];
    },
    [t]
  );

  return (
    <PermissionControl
      rule={PermissionRules.ANY}
      disabled
      roles={[SYSTEM_ROLES.CLIENT_ADMIN, SYSTEM_ROLES.SUPER_ADMIN, SYSTEM_ROLES.SYSTEM_OWNER]}
      render={(_, disabled) => (
        <Dropdown trigger={DROPDOWN_TRIGGERS} menu={{ items: getItems(disabled) }}>
          <Button type="default">
            <EllipsisOutlined />
          </Button>
        </Dropdown>
      )}
    />
  );
};

export default ListingHeaderActions;
