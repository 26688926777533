import React from 'react';

import { useTranslation } from 'react-i18next';

import { ContentContainer, MainContentContainer } from 'common-ui/containers';
import { DetailsSectionCollapse, DetailsSectionPanel } from 'common-ui/detailsSectionCollapse';
import { OBJECT_TYPES } from 'core/lib/constants';

import {
  LoginCredentialsSection,
  OutOfOfficeSection,
  PersonalInformationSection,
  UserPrivilegesSection,
} from './sections';
import { tabSections } from '../../form/formKeys';
import { UserTabProps } from '../../types';

const defaultSections = Object.values(tabSections.generalInformation).map((sectionKey) => ({
  name: sectionKey,
  collapsed: true,
}));

const UserGeneralTab = ({ editMode, isNew, sectionValidation }: UserTabProps) => {
  const { t } = useTranslation('user');

  return (
    <MainContentContainer id="user-general">
      <ContentContainer>
        <DetailsSectionCollapse defaultSections={defaultSections} keepDefault={isNew} objectType={OBJECT_TYPES.USER}>
          <DetailsSectionPanel
            header={t('sections.loginCredentials')}
            key={tabSections.generalInformation.loginCredentials!}
            sectionKey={tabSections.generalInformation.loginCredentials!}
            invalidFields={sectionValidation?.loginCredentials}
          >
            <LoginCredentialsSection editMode={editMode} isNew={isNew} />
          </DetailsSectionPanel>
          <DetailsSectionPanel
            header={t('sections.personalInformation')}
            key={tabSections.generalInformation.personalInformation!}
            sectionKey={tabSections.generalInformation.personalInformation!}
            invalidFields={sectionValidation?.personalInformation}
          >
            <PersonalInformationSection editMode={editMode} />
          </DetailsSectionPanel>
          <DetailsSectionPanel
            header={t('sections.userPrivileges')}
            key={tabSections.generalInformation.userPrivileges!}
            sectionKey={tabSections.generalInformation.userPrivileges!}
            invalidFields={sectionValidation?.userPrivileges}
          >
            <UserPrivilegesSection editMode={editMode} />
          </DetailsSectionPanel>
          {!isNew && (
            <DetailsSectionPanel
              header={t('sections.outOfOffice')}
              key={tabSections.generalInformation.outOfOffice!}
              sectionKey={tabSections.generalInformation.outOfOffice!}
              invalidFields={sectionValidation?.outOfOffice}
            >
              <OutOfOfficeSection editMode={editMode} />
            </DetailsSectionPanel>
          )}
        </DetailsSectionCollapse>
      </ContentContainer>
    </MainContentContainer>
  );
};

export default UserGeneralTab;
