import React, { forwardRef } from 'react';

import { List } from 'antd';
import { ListItemProps } from 'antd/lib/list';

// eslint-disable-next-line react/display-name, react/prop-types
const Item = forwardRef<HTMLDivElement, ListItemProps>(({ children, style, ...props }, ref) => {
  return (
    <List.Item {...props} style={style} ref={ref}>
      {children}
    </List.Item>
  );
});

export default Item;
