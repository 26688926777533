export { default as GeneralTab } from './generalTab';
export { default as PasswordTab } from './passwordTab';
export { default as PreferencesTab } from './preferencesTab';
export { default as NotificationsTab } from './notificationsTab';
export const userProfileTabKeys = {
  general: 'general',
  password: 'password',
  preferences: 'preferences',
  notifications: 'notifications',
};
