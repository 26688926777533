import React from 'react';

import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

type FilterActionsProps = {
  onApply: () => void;
  onClear: () => void;
  filtersUpdated: boolean;
};

const FilterActions = ({ onApply, onClear, filtersUpdated }: FilterActionsProps) => {
  const { t } = useTranslation();
  return (
    <div className="flex justify-between">
      <Button type="text" onClick={onClear}>
        {t('actions:global.clear')}
      </Button>
      <Button type="primary" disabled={!filtersUpdated} onClick={onApply}>
        {t('actions:global.apply')}
      </Button>
    </div>
  );
};

export default FilterActions;
