import React from 'react';

import { Trans } from 'react-i18next';

import { ContactBookListing } from 'core/lib';

import DealBasePopover from './popovers/dealbase';

function SharedCasesRenderer({ sharedCases }: ContactBookListing) {
  const renders = sharedCases?.map((sCase, index) => [
    index > 0 && ', ',
    <DealBasePopover key={sCase.id} id={sCase.id} name={sCase.name} />,
  ]);

  return (
    <div className="flex flex-wrap gap-2">
      <Trans i18nKey="contactBook:form.professionalInformation.labels.sharedCases" />:<div>{renders}</div>
    </div>
  );
}

export default SharedCasesRenderer;
