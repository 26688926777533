import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { MODULE_SEARCH_OPTIONS, OBJECT_TYPES } from 'core/lib/constants';
import i18next from 'core/lib/utils/i18n';

import { logout } from '../../auth/store/auth.slice';
import { userPreferencesApi, UserPreferences } from '../../shared';

export interface IAppReducer {
  leftMenuToggled: boolean;
  leftMenuFiltersToggled: boolean;
  leftMenuHeader: string | null;
  activeModule: number | null;
  searchModule: number | null;
  globalSearch: string;
  globalSearchBarToggled: boolean;
  userPreferences?: UserPreferences | null;

  customFieldsMenuId: number;
}

export const appInitialState: IAppReducer = {
  leftMenuToggled: false,
  leftMenuFiltersToggled: true,
  leftMenuHeader: null,
  activeModule: null,
  searchModule: null,
  globalSearch: '',
  globalSearchBarToggled: false,
  userPreferences: null,

  customFieldsMenuId: OBJECT_TYPES.DEAL_BASE,
};

export const appSlice = createSlice({
  name: 'app',
  initialState: appInitialState,
  reducers: {
    setLeftMenuToggled: (state, { payload }: PayloadAction<boolean>) => {
      state.leftMenuToggled = payload;
    },
    setLeftMenuFiltersToggled: (state, { payload }: PayloadAction<boolean>) => {
      state.leftMenuFiltersToggled = payload;
    },
    setActiveModule: (state, { payload }: PayloadAction<number | null>) => {
      state.activeModule = payload;
      if (MODULE_SEARCH_OPTIONS.some((opt) => opt.value === payload)) {
        state.searchModule = payload;
      } else {
        state.searchModule = null;
      }
    },
    setSearchModule: (state, { payload }: PayloadAction<number>) => {
      state.searchModule = payload;
    },
    setGlobalSearch: (state, { payload }: PayloadAction<string>) => {
      state.globalSearch = payload;
    },
    setGlobalBarToggled: (state, { payload }: PayloadAction<boolean>) => {
      state.globalSearchBarToggled = payload;
    },
    setCustomFieldsMenuId: (state, { payload }: PayloadAction<number>) => {
      state.customFieldsMenuId = payload;
    },
    appHydrate: (state, { payload }: PayloadAction<Partial<IAppReducer>>) => {
      return { ...state, ...payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout, () => {
      return appInitialState;
    });
    builder.addMatcher(userPreferencesApi.endpoints.getPreferences.matchFulfilled, (state, { payload }) => {
      if (!state.userPreferences) {
        state.leftMenuToggled = payload?.leftMenuOpen ?? state.leftMenuToggled;
      }
      if (payload?.language) {
        i18next.changeLanguage(payload.language);
      }

      state.userPreferences = payload;
    });
  },
});

export const {
  setLeftMenuToggled,
  setLeftMenuFiltersToggled,
  setActiveModule,
  setSearchModule,
  setGlobalSearch,
  setGlobalBarToggled,
  setCustomFieldsMenuId,
  appHydrate,
} = appSlice.actions;

export default appSlice.reducer;
