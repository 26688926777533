import React, { useEffect, useState } from 'react';

import { UserComment } from 'core/lib';

import RenderComment from './commentItem';

type CommentListProps = {
  comments: UserComment[];
  disabled?: boolean;
  isSaving: boolean;
  nameRequired?: boolean;
  external?: boolean;
  type: string;

  onDelete?: (commentId: number, callback?: () => void) => void;
  onSubmit: (comment: Partial<UserComment>, callback?: () => void) => void;
  onReplySubmit: (parentId: number, comment: Partial<UserComment>, callback?: () => void) => void;
};

const CommentList = ({
  comments,
  type,
  isSaving,
  external,
  nameRequired,
  onDelete,
  onSubmit,
  onReplySubmit,
}: CommentListProps) => {
  const [editId, setEditId] = useState<number | null>(null);
  const [parentCommentId, setParentCommentId] = useState<number | null>(null);

  useEffect(() => {
    if (!isSaving) {
      handleCancelEditComment();
      handleCancelReplyComment();
    }
  }, [isSaving]);

  const handleCancelReplyComment = () => {
    setParentCommentId(null);
  };

  const handleEditCommentSubmit = (editComment: Partial<UserComment>, callback?: () => void) => {
    if (editComment && editComment.id) {
      onSubmit(editComment, callback);
    }
  };

  const handleCancelEditComment = () => {
    setEditId(null);
  };

  const handleSetEditComment = (item: UserComment) => {
    setEditId(item.id);
  };

  return (
    <>
      {comments.map((userComment) => (
        <RenderComment
          key={userComment.id}
          userComment={userComment}
          editMode={userComment.id === editId}
          replyMode={userComment.id === parentCommentId}
          onCancel={handleCancelEditComment}
          onReplyCancel={handleCancelReplyComment}
          onReply={setParentCommentId}
          onEdit={handleSetEditComment}
          onSubmit={handleEditCommentSubmit}
          onReplySubmit={onReplySubmit}
          onDelete={onDelete}
          type={type}
          external={external}
          nameRequired={nameRequired}
          isSaving={isSaving}
        />
      ))}
    </>
  );
};

export default CommentList;
