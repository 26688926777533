import React, { useCallback, useEffect, useState } from 'react';

import { EditFilled, ReloadOutlined } from '@ant-design/icons';
import { Badge, Button, Form, Tabs } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Prompt } from 'react-router';

import { PopConfirmCancelWrapper } from 'common-ui/wrappers';
import { SYSTEM_ROLES } from 'core/lib/constants';
import { PORTFOLIO_STATUS } from 'core/lib/constants/statuses';
import { PortfolioDetails } from 'core/lib/modules/portfolio';
import LoaderWrapper from 'utils/loader-wrapper';
import PermissionControl, { PermissionRules } from 'utils/permissionControl';

import { mandatoryTabFields } from './formConfig';
import { PortfolioSectionKeys, PortfolioTabKeys, portfolioTabs } from './formKeys';
import { PortfolioDetailsContext } from '../context';
import { ActivityTab, GeneralTab, ProductTab } from '../tabs';

const DEFAULT_ACTIVE_TAB = portfolioTabs.generalInformation;

type PortfolioFormProps = {
  onSave: (portfolio: PortfolioDetails) => Promise<void | number>;
  onCancel: () => void;
  onToggleEdit?: () => void;
  onRefetch?: () => void;
  setActiveTabKey: (tabKey: string) => void;
  activeTabKey?: string | null;
  editMode: boolean;
  portfolio: Partial<PortfolioDetails>;
  isNew?: boolean;
  isSaving: boolean;
};
const PortfolioForm = ({
  onSave,
  onCancel,
  onToggleEdit,
  onRefetch,
  setActiveTabKey,
  activeTabKey,
  editMode,
  portfolio,
  isNew = false,
  isSaving,
}: PortfolioFormProps) => {
  const [formDirty, setFormDirty] = useState(false);
  const [formObject] = useForm<PortfolioDetails>();
  const [draftPortfolio, setDraftPortfolio] = useState<Partial<PortfolioDetails>>(portfolio);
  const [tabValidations, setTabValidations] = useState<{ [key in PortfolioTabKeys]?: string[] }>({});
  const [sectionValidations, setSectionValidations] = useState<{
    [key in PortfolioTabKeys]?: { [key in PortfolioSectionKeys]?: boolean };
  }>({});

  const { t } = useTranslation();

  const init = useCallback(() => {
    formObject?.resetFields();
    setFormDirty(false);
  }, [formObject]);

  useEffect(() => {
    init();
  }, [editMode, init]);

  useEffect(() => {
    init();
  }, [draftPortfolio.id, init]);

  useEffect(() => {
    setDraftPortfolio(portfolio);
  }, [portfolio]);

  const validateTabs = () => {
    Object.entries(mandatoryTabFields).forEach(([tabKey, sectionsObject]) => {
      const tabFields = Object.values(sectionsObject).flat();
      formObject?.validateFields(tabFields).then(
        () => {
          setTabValidations((tabValidations) => ({ ...tabValidations, [tabKey]: [] }));
        },
        (err) => {
          setTabValidations((tabValidations) => ({
            ...tabValidations,
            [tabKey]: err.errorFields.map(({ name }: { name: string[] }) => name).flat(),
          }));
        }
      );
    });
  };

  useEffect(() => {
    const sectionValidations = Object.entries(mandatoryTabFields).reduce((agg, [tabKey, sectionObject]) => {
      agg[tabKey] = {};
      Object.entries(sectionObject).forEach(([sectionKey, sectionFields]) => {
        agg[tabKey][sectionKey] = !!tabValidations[tabKey]?.some((field: string) => sectionFields.includes(field));
      });
      return agg;
    }, {});

    setSectionValidations(sectionValidations);
  }, [tabValidations]);

  const setTabValidationStatus = () => {
    validateTabs();
  };

  const resetTabValidationErrors = () => {
    setTabValidations({});
    setSectionValidations({});
  };

  const onFinish = (values: PortfolioDetails) => {
    setFormDirty(false);
    onSave(values)
      .then(() => {
        resetTabValidationErrors();
      })
      .catch(() => {
        setFormDirty(true);
      });
  };
  const handleCancel = () => {
    resetTabValidationErrors();
    setDraftPortfolio(portfolio);
    onCancel();
    setFormDirty(false);
  };

  const handleValuesChange = debounce((_, allValues) => {
    setFormDirty(true);
    setDraftPortfolio({ ...draftPortfolio, ...allValues });
  }, 100);

  return (
    <LoaderWrapper overlay loading={isSaving} message={t('loaders:global.saving')}>
      <PortfolioDetailsContext.Provider value={draftPortfolio}>
        <Prompt when={formDirty} message={t('global:navigation.unsavedChanges')} />
        <Form
          onValuesChange={handleValuesChange}
          onFinishFailed={setTabValidationStatus}
          className="overflow-y-auto h-full"
          form={formObject}
          layout="vertical"
          name="portfolioForm"
          onFinish={onFinish}
          initialValues={draftPortfolio}
        >
          <Tabs
            size="small"
            activeKey={activeTabKey ?? DEFAULT_ACTIVE_TAB}
            onChange={setActiveTabKey}
            type="line"
            className="h-full overflow-y-auto tabs-centered"
            tabBarExtraContent={
              editMode ? (
                <div className="flex flex-row gap-x-3">
                  <Button htmlType="submit" type="primary" disabled={!formDirty}>
                    {t('actions:global.save')}
                  </Button>
                  <PopConfirmCancelWrapper
                    title={t('actions:confirmation.confirmCancel')}
                    onConfirm={handleCancel}
                    disabled={!formDirty}
                  >
                    <Button>{t('actions:global.cancel')}</Button>
                  </PopConfirmCancelWrapper>
                </div>
              ) : (
                <div className="flex flex-row">
                  <PermissionControl
                    rule={PermissionRules.ANY}
                    roles={[SYSTEM_ROLES.CLIENT_ADMIN, SYSTEM_ROLES.SUPER_ADMIN, SYSTEM_ROLES.SYSTEM_OWNER]}
                    disabled
                    render={(_, disabled) => {
                      const isEditDisabled = disabled || portfolio.status === PORTFOLIO_STATUS.COMPLETED;
                      return (
                        <Button type="link" disabled={isEditDisabled} onClick={onToggleEdit} icon={<EditFilled />} />
                      );
                    }}
                  />
                  <Button type="link" onClick={onRefetch} icon={<ReloadOutlined />} />
                </div>
              )
            }
            items={[
              {
                key: portfolioTabs.generalInformation,
                className: 'h-full',
                label: (
                  <Badge className="text-inherit" dot={!!tabValidations[portfolioTabs.generalInformation]?.length}>
                    {t('portfolio:tabs.generalInformation')}
                  </Badge>
                ),
                forceRender: editMode,
                children: (
                  <GeneralTab
                    sectionValidation={sectionValidations[portfolioTabs.generalInformation]}
                    isNew={isNew}
                    editMode={editMode}
                  />
                ),
              },
              {
                key: portfolioTabs.productInformation,
                className: 'h-full',
                label: t('portfolio:tabs.productInformation'),
                children: (
                  <ProductTab
                    sectionValidation={sectionValidations[portfolioTabs.productInformation]}
                    isNew={isNew}
                    editMode={editMode}
                  />
                ),
              },
              {
                key: portfolioTabs.activity,
                className: 'h-full',
                label: t('portfolio:tabs.activity'),
                disabled: isNew,
                children: <ActivityTab />,
              },
            ]}
          />
        </Form>
      </PortfolioDetailsContext.Provider>
    </LoaderWrapper>
  );
};

export default PortfolioForm;
