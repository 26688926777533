import Cookies from 'universal-cookie';

export const REFRESH_TOKEN_FREQUENCY = 300000;
// todo get client api
// const REFRESH_TOKEN = 'refreshToken';
const ACCESS_TOKEN = 'token';
const MBL_CLIENT = 'mbl_client';
// const MAX_RETRY = 2;

export default class AuthenticateSerivce {
  static cookies: Cookies = new Cookies();
  static accessToken?: string;

  static getCookiesAccessToken = () => AuthenticateSerivce.cookies.get(ACCESS_TOKEN);
  static setCookiesAccessToken = (token: string) => AuthenticateSerivce.cookies.set(ACCESS_TOKEN, token, { path: '/' });
  static removeCookiesAccessToken = () => AuthenticateSerivce.cookies.remove(ACCESS_TOKEN, { path: '/' });

  static getCookiesClient = () => AuthenticateSerivce.cookies.get(MBL_CLIENT);
  static setCookiesClient = (client: string) => AuthenticateSerivce.cookies.set(MBL_CLIENT, client, { path: '/' });
  static removeCookiesClient = () => AuthenticateSerivce.cookies.remove(MBL_CLIENT, { path: '/' });

  setAccessToken = (token: string) => {
    AuthenticateSerivce.accessToken = token;
    AuthenticateSerivce.setCookiesAccessToken(token);
  };

  retrieveAccessToken = () => {
    AuthenticateSerivce.accessToken = AuthenticateSerivce.getCookiesAccessToken();
  };

  logout = () => {
    AuthenticateSerivce.accessToken = '';
    AuthenticateSerivce.removeCookiesAccessToken();
  };
}
