import React, { useEffect, useMemo, useState } from 'react';

import { NumberOutlined } from '@ant-design/icons';
import { Button, Divider, Input, message, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { generatePath, Link } from 'react-router-dom';
import { useMedia } from 'react-use';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList, ListChildComponentProps } from 'react-window';

import { ModuleTag, UserAvatarGroup } from 'common-ui';
import {
  conversationsApi,
  ModuleConversationListing,
  RequestError,
  selectCurrentUser,
  selectWebSocketStatus,
  useAppSelector,
} from 'core/lib';
import { EXCEPTIONS, MODULE_NAMES } from 'core/lib/constants';
import colors from 'core/lib/theme/colors';
import { fuzzyWordsSearch } from 'core/lib/utils';
import { HubConnectionState } from 'core/lib/utils/signalR/utils';
import { Routes } from 'routes/routes';
import { generateRoutePathByObjectTypeAndObjectId, getRouteByObjectTypeAndRouteType } from 'utils';
import { useHub } from 'utils/hooks/hubHooks';
import LoaderWrapper from 'utils/loader-wrapper';

import { ListingContainer } from '../containers';

const { Title } = Typography;

const ConversationListing = () => {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const isMobile = useMedia('(max-width: 768px)');
  const [hub] = useHub();

  const webSocketStatus = useAppSelector(selectWebSocketStatus);
  const currentUser = useAppSelector(selectCurrentUser);

  const getTitle = (conversation: ModuleConversationListing) => {
    return conversation.objectTitle ?? MODULE_NAMES[conversation.objectType];
  };

  const { data = [], isLoading: isListingLoading } = conversationsApi.useGetModuleConversationsListingQuery();
  const [leaveConversation, { isLoading: isLeaveConversationLoading, originalArgs: leaveConversationArgs }] =
    conversationsApi.useLeaveModuleConversationMutation();

  const [
    joinConversation,
    { isLoading: isJoinConversationLoading, originalArgs: joinConversationArgs, error: joinConversationError },
  ] = conversationsApi.useJoinModuleConversationMutation();

  const searchDataResult = useMemo(
    () => data.filter((conv) => fuzzyWordsSearch(search, getTitle(conv))),
    [data, search]
  );

  const isUserParticipant = (conversation: ModuleConversationListing) => {
    return !!conversation.participants.find((user) => user.id === currentUser?.id);
  };

  useEffect(() => {
    if (joinConversationError) {
      const errorData = joinConversationError as RequestError;
      if (errorData.data.ExceptionType === EXCEPTIONS.AccessRestricted) {
        message.error(t('conversations:message.modulePermission'));
      }
    }
  }, [joinConversationError, t]);

  const onJoinConversationClick = (moduleConversationId: number) => {
    joinConversation(moduleConversationId);
  };

  const onLeaveConversationClick = (moduleConversationId: number) => {
    leaveConversation(moduleConversationId);
  };

  useEffect(() => {
    if (webSocketStatus === HubConnectionState.Connected) {
      hub.conversations.joinModuleConversationListing();
    }
    return () => {
      hub.conversations.leaveModuleConversationListing();
    };
  }, [hub.conversations, webSocketStatus]);

  const getActions = (conversation: ModuleConversationListing) => {
    const actions = [
      <Link
        key="conversation-view"
        to={generatePath(Routes.CONVERSATIONS_MODULE_DETAILS.path, { id: conversation.id })}
      >
        <Button type="default">{t('conversations:actions.view')}</Button>
      </Link>,
    ];
    if (isUserParticipant(conversation)) {
      actions.unshift(
        <Button
          key="conversation-leave"
          onClick={() => onLeaveConversationClick(conversation.id)}
          type="primary"
          loading={isLeaveConversationLoading && leaveConversationArgs === conversation.id}
          danger
        >
          {t('conversations:actions.leave')}
        </Button>
      );
    } else {
      actions.unshift(
        <Button
          key="conversation-join"
          onClick={() => onJoinConversationClick(conversation.id)}
          type="primary"
          loading={isJoinConversationLoading && joinConversationArgs === conversation.id}
        >
          {t('conversations:actions.join')}
        </Button>
      );
    }

    return actions;
  };

  const conversationListItem = ({ index, data, style }: ListChildComponentProps) => {
    const conversation = data[index];
    const title = getTitle(conversation);
    let objectUrl = getRouteByObjectTypeAndRouteType(conversation.objectType);
    if (conversation.objectId) {
      objectUrl = generateRoutePathByObjectTypeAndObjectId(conversation.objectType, conversation.objectId);
    }

    return (
      <div style={style} key={conversation.id}>
        <div className="flex flex-col md:flex-row justify-between p-4 gap-4">
          <div className="flex flex-col truncate text-ellipsis overflow-hidden gap-4">
            <div className="flex flex-row gap-2">
              <Title ellipsis level={5} className="flex font-normal items-center gap-2 m-0">
                <NumberOutlined style={{ color: colors.Primary.rgba }} />
                {objectUrl ? <Link to={objectUrl}>{title}</Link> : title}
              </Title>
              <ModuleTag objectType={conversation.objectType} />
            </div>
            <UserAvatarGroup maxCount={4} users={conversation.participants} />
          </div>
          <div className="flex flex-row gap-2">{getActions(conversation)}</div>
        </div>
        <Divider className="m-0" />
      </div>
    );
  };

  return (
    <LoaderWrapper loading={isListingLoading} message={t('loaders:myConversations.loadingListing')}>
      <ListingContainer count={searchDataResult.length}>
        <div className="h-full">
          <Input
            className="w-full"
            allowClear
            placeholder={t('conversations:placeholders.searchConversation')}
            onChange={(e) => setSearch(e.target.value)}
            value={search}
          />
          <AutoSizer className="w-full">
            {({ height, width }) => (
              <FixedSizeList
                itemData={searchDataResult}
                width={width}
                height={height - 32} // deduce the header
                itemCount={searchDataResult.length}
                itemSize={isMobile ? 155 : 110}
              >
                {conversationListItem}
              </FixedSizeList>
            )}
          </AutoSizer>
        </div>
      </ListingContainer>
    </LoaderWrapper>
  );
};

export default ConversationListing;
