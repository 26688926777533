import React from 'react';

import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { DateRecord, UserAvatar, UserLink } from 'common-ui';
import { ConversationMessage } from 'core/lib';
import { DEFAULT_DATETIME_FORMAT, DEFAULT_TIME_FORMAT_DAYPART } from 'core/lib/constants/defaults';

import { SubTypeTranslationKeys } from './mapper';

const { Text } = Typography;

const UserSystemMessage = ({
  message,
  conversationTitle,
}: {
  conversationTitle?: string;
  message: ConversationMessage;
}) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-row gap-2 mt-4 hover:bg-gray-50">
      <UserAvatar user={message.user} iconOnly />
      <div className="flex flex-col">
        <div className="flex flex-row gap-2 items-center">
          <UserLink className="font-medium" id={message.user.id} name={message.user.name} />
          <DateRecord
            className="text-xs text-gray-400"
            date={message.dateCreated}
            dateFormat={DEFAULT_TIME_FORMAT_DAYPART}
            tooltipFormat={DEFAULT_DATETIME_FORMAT}
          />
        </div>
        <Text type="secondary">
          {t(`conversations:systemMessages.${SubTypeTranslationKeys[message.subType!]}`, { name: conversationTitle })}
        </Text>
      </div>
    </div>
  );
};

export default UserSystemMessage;
