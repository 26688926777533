import React from 'react';

import { message, Modal, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';
import { useCopyToClipboard } from 'react-use';

import { ExternallAccessCredentials } from 'core/lib';
import { EXTERNAL_ACCESS } from 'core/lib/constants/dealbase';
import { Routes } from 'routes/routes';

const { Link, Paragraph, Text } = Typography;

type ExternalAccessPropTypes = {
  onClose: () => void;
  isOpen: boolean;
  externalAccess?: ExternallAccessCredentials;
  isLoading: boolean;
  title: string;
};

const ExternalAccessModal = ({ isOpen, externalAccess, onClose, title }: ExternalAccessPropTypes) => {
  const { t } = useTranslation();
  const [, copyToClipboard] = useCopyToClipboard();

  const getUrl = () => {
    return `${window.origin}${generatePath(
      externalAccess?.type === EXTERNAL_ACCESS.CASE_OWNER
        ? Routes.EXTERNAL_CASEOWNER.path
        : Routes.EXTERNAL_THIRDPARTY.path,
      { url: externalAccess?.url, dealId: externalAccess?.dealBaseId, type: externalAccess?.type }
    )}`;
  };

  const copyCredentials = () => {
    copyToClipboard(
      `${t('dealbase:externalAccess.link')}: ${getUrl()} \n ${t('dealbase:externalAccess.password')}: ${
        externalAccess?.password
      }`
    );
    message.success(t('actions:message.copied'));
  };

  return (
    <Modal title={title} onCancel={onClose} okText={t('actions:global.copy')} onOk={copyCredentials} open={isOpen}>
      <div className="flex flex-col gap-4">
        <div className="flex flex-col">
          <Text>{t('dealbase:externalAccess.link')}:</Text>
          <Link ellipsis href={getUrl()} copyable target="_blank">
            {getUrl()}
          </Link>
        </div>
        <div className="flex flex-col">
          <Text>{t('dealbase:externalAccess.password')}:</Text>
          <Paragraph copyable>{externalAccess?.password}</Paragraph>
        </div>
      </div>
    </Modal>
  );
};

export default ExternalAccessModal;
