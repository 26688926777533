import { createSlice } from '@reduxjs/toolkit';

import { DEALBASE_STATUS } from 'core/lib/constants/statuses';

import { logout } from '../../auth';
import { DealBaseFilters } from '../entities';

type DealBaseState = {
  filters: DealBaseFilters;
  listingGrouping: string;
};

const initialState: DealBaseState = {
  filters: {
    status: [
      DEALBASE_STATUS.NEW,
      DEALBASE_STATUS.PRE_SCREENING,
      DEALBASE_STATUS.SCREENING,
      DEALBASE_STATUS.INTERNAL_DD,
      DEALBASE_STATUS.EXTERNAL_DD,
      DEALBASE_STATUS.INVESTMENT,
      DEALBASE_STATUS.WAIT,
      DEALBASE_STATUS.INVESTMENT_ON_HOLD,
      DEALBASE_STATUS.WATCHLIST,
    ],
    assigned: [],
    organization: [],
    country: [],
    industry: [],
    priority: [],
    productStatus: [],
    stage: [],
    technology: [],
    tier: [],
  },
  listingGrouping: DEALBASE_STATUS.NEW.toString(),
};

export const dealBaseSlice = createSlice({
  name: 'dealBase',
  initialState,
  reducers: {
    setDealBaseFilters: (state, { payload }: { payload: Partial<DealBaseFilters> }) => {
      Object.assign(state.filters, payload);
    },
    clearDealBaseFilters: (state) => {
      Object.assign(state.filters, { ...initialState.filters, status: [] });
    },
    setDealBaseListingGrouping: (state, { payload }: { payload: string }) => {
      state.listingGrouping = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout, () => {
      return initialState;
    });
  },
});

export const { setDealBaseFilters, setDealBaseListingGrouping, clearDealBaseFilters } = dealBaseSlice.actions;

export default dealBaseSlice.reducer;
