import React, { useMemo } from 'react';

import { EllipsisOutlined } from '@ant-design/icons';
import { Button, Dropdown } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';

import { DROPDOWN_TRIGGERS, MenuItemConversationAction } from 'common-ui';
import { AppState } from 'core/lib';
import { OBJECT_TYPES } from 'core/lib/constants';
import { setCreateNewMode, createNewOrganizationModeSelector } from 'core/lib/modules/organization';

const mapState = (state: AppState) => ({
  createNewMode: createNewOrganizationModeSelector(state),
});

const mapDispatch = {
  setCreateNewMode,
};

const connector = connect(mapState, mapDispatch);
type ListingHeaderActionsProps = ConnectedProps<typeof connector>;

const ListingHeaderActions = ({ setCreateNewMode, createNewMode }: ListingHeaderActionsProps) => {
  const { t } = useTranslation('actions');

  const onCreateNewClick = () => {
    setCreateNewMode(true);
  };

  const items: ItemType[] = useMemo(() => {
    return [
      {
        key: 'create',
        disabled: createNewMode,
        label: t('actions:global.create'),
        onClick: onCreateNewClick,
      },
      {
        key: 'conversation_action',
        label: <MenuItemConversationAction objectType={OBJECT_TYPES.ORGANIZATION} />,
      },
      // {
      //   key: 'export',
      //   label: t('global.export'),
      // },
    ];
  }, [createNewMode, t]); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Dropdown trigger={DROPDOWN_TRIGGERS} menu={{ className: 'min-w-32', items }}>
      <Button type="default">
        <EllipsisOutlined />
      </Button>
    </Dropdown>
  );
};

export default connector(ListingHeaderActions);
