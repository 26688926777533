// import { isBefore, isAfter } from 'date-fns';
import _, { groupBy /* , { countBy, isNil } */ } from 'lodash';
import { createSelector } from 'reselect';

import { DealBaseStatusType } from 'core/lib/constants/statuses';
import { selectLeftMenuFiltersToggled } from 'core/lib/modules/app';
import { DealBaseListing, DealBaseMenu } from 'core/lib/modules/dealBase/entities';

import { AppState } from '../../../store';

const selectSelf = (state: AppState) => state.dealBase;

export const selectDealBaseFilters = createSelector(selectSelf, (dealBase) => dealBase.filters);

export const selectDealBaseListingGrouping = createSelector(selectSelf, (dealBase) => dealBase.listingGrouping);

export const selectDealBaseTierFilters = createSelector(selectDealBaseFilters, (filters) => filters.tier);

export const selectDealBaseAssignedUserFilters = createSelector(selectDealBaseFilters, (filters) => filters.assigned);

export const selectDealBaseStatusFilters = createSelector(selectDealBaseFilters, (filters) => filters.status);

export const selectFilteredListing = createSelector(
  selectDealBaseFilters,
  (_: AppState, listingData?: DealBaseListing[]) => listingData,
  (filters, listingData) => {
    if (!listingData) {
      return listingData;
    }
    const filterByStatus = (data: DealBaseListing) =>
      !filters.status?.length || !!filters.status?.includes(data.status as DealBaseStatusType);
    const filtersByAssignedUsers = (data: DealBaseListing) =>
      !filters.assigned?.length || !!(data.assigned?.id && filters.assigned?.includes(data.assigned?.id));
    const filterByIndustry = (data: DealBaseListing) =>
      !filters.industry?.length ||
      !!(data?.industries && data?.industries.some((industry) => filters.industry?.includes(industry.id)));
    const filterByTechnology = (data: DealBaseListing) =>
      !filters.technology?.length || !!(data?.technology && filters.technology?.includes(data.technology?.id));
    // const filterByCountry = (data: DealBaseListing) =>
    //   !filters.country?.length || !!filters.country?.includes(data.country);
    // const filterByProductStatus = (data: DealBaseListing) =>
    //   !filters.productStatus?.length || !!filters.productStatus?.includes(data.productStatus);
    // const filterByOrganization = (data: DealBaseListing) =>
    //   !filters.organization?.length ||
    //   !!(data.organization?.id && filters.organization?.includes(data.organization?.id));
    // const filterByFollowUp = (data: DealBaseListing) => {
    //   const dealFollowUpDate = parseJSONDate(data.followUpDate);
    //   if (filters.followUp?.startDate && filters.followUp?.endDate) {
    //     return !!(
    //       dealFollowUpDate &&
    //       isAfter(dealFollowUpDate, filters.followUp.startDate) &&
    //       isBefore(dealFollowUpDate, filters.followUp?.endDate)
    //     );
    //   } else if (filters.followUp?.startDate) {
    //     return !!(dealFollowUpDate && isAfter(dealFollowUpDate, filters.followUp.startDate));
    //   } else if (filters.followUp?.endDate) {
    //     return !!(dealFollowUpDate && isBefore(dealFollowUpDate, filters.followUp?.endDate));
    //   } else {
    //     return true;
    //   }
    // };
    // const filterByStage = (data: DealBaseListing) => !filters.stage?.length || !!filters.stage?.includes(data.stage);
    // const filterByPriority = (data: DealBaseListing) =>
    //   !filters.priority?.length || !!(data.priority && filters.organization?.includes(data.priority));
    const filterByTier = (data: DealBaseListing) =>
      !filters.tier?.length || !!(data.tier && filters.tier?.includes(data.tier));
    // const filterByScoring = (data: DealBaseListing) => {
    //   if (filters.resultingScore?.min && filters.resultingScore?.max) {
    //     return !!(
    //       data.resultingScore &&
    //       data.resultingScore >= filters.resultingScore.min &&
    //       data.resultingScore <= filters.resultingScore.max
    //     );
    //   } else if (filters.resultingScore?.min) {
    //     return !!(data.resultingScore && data.resultingScore >= filters.resultingScore.min);
    //   } else if (filters.resultingScore?.max) {
    //     return !!(data.resultingScore && data.resultingScore <= filters.resultingScore.max);
    //   } else {
    //     return true;
    //   }
    // };
    return (
      _(listingData)
        .filter(filterByStatus)
        .filter(filtersByAssignedUsers)
        .filter(filterByIndustry)
        .filter(filterByTechnology)
        // .filter(filterByCountry)
        // .filter(filterByProductStatus)
        // .filter(filterByOrganization)
        // .filter(filterByFollowUp)
        // .filter(filterByStage)
        // .filter(filterByPriority)
        .filter(filterByTier)
        // .filter(filterByScoring)
        .value() as DealBaseListing[]
    );
  }
);

export const selectFilteredListingByStatusGroup = createSelector(
  selectDealBaseListingGrouping,
  (_: AppState, listingData?: DealBaseListing[]) => listingData,
  (listingGroup, listingData) => {
    return listingGroup ? groupBy(listingData, 'status')[listingGroup] : listingData;
  }
);

export const selectFiltersCount = (isMobile: boolean) =>
  createSelector(selectDealBaseFilters, (filters) => {
    return [
      ...(isMobile ? [!!filters.status?.length, !!filters.assigned?.length] : []),
      !!filters.industry?.length,
      !!filters.technology?.length,
      // !!filters.country?.length,
      // !!filters.productStatus?.length,
      // !!filters.organization?.length,
      // filters.followUp?.startDate || filters.followUp?.endDate,
      // !!filters.stage?.length,
      // !!filters.priority?.length,
      !!filters.tier?.length,
      // !isNil(filters.resultingScore?.min) || !isNil(filters.resultingScore?.max),
    ].filter((filter) => filter).length;
  });

export const selectFilteredMenu = createSelector(
  selectDealBaseFilters,
  selectLeftMenuFiltersToggled,
  (_: AppState, listingData?: DealBaseMenu[]) => listingData,
  (filters, leftMenuFiltersToggled, listingData) => {
    if (!listingData || !leftMenuFiltersToggled) {
      return listingData;
    }
    const filterByStatus = (data: DealBaseMenu) =>
      !filters.status?.length || !!filters.status?.includes(data.status as DealBaseStatusType);
    const filtersByAssignedUsers = (data: DealBaseMenu) =>
      !filters.assigned?.length || !!(data.assignedId && filters.assigned?.includes(data.assignedId));
    const filterByIndustry = (data: DealBaseMenu) =>
      !filters.industry?.length ||
      !!(data?.industryIds && data?.industryIds.some((industryId) => filters.industry?.includes(industryId)));
    const filterByTechnology = (data: DealBaseMenu) =>
      !filters.technology?.length || !!(data?.technologyId && filters.technology?.includes(data.technologyId));
    const filterByTier = (data: DealBaseMenu) =>
      !filters.tier?.length || !!(data.tier && filters.tier?.includes(data.tier));

    return _(listingData)
      .filter(filterByStatus)
      .filter(filtersByAssignedUsers)
      .filter(filterByIndustry)
      .filter(filterByTechnology)
      .filter(filterByTier)
      .value() as DealBaseMenu[];
  }
);
