import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { DownOutlined } from '@ant-design/icons';
import { Button, Checkbox, Dropdown } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { intersection } from 'lodash';
import { useTranslation } from 'react-i18next';

import { DROPDOWN_TRIGGERS, FilterActions } from 'common-ui';
import { useAppDispatch, useAppSelector } from 'core/lib';
import { PortfolioStatusType, PORTFOLIO_STATUSLIST } from 'core/lib/constants/statuses';
import { selectPortfolioStatusFilters, setPortfolioFilters } from 'core/lib/modules/portfolio/store';

const StatusFilter = () => {
  const [visible, setVisibility] = useState(false);
  const statusFilters = useAppSelector(selectPortfolioStatusFilters);
  const [draftFilters, setDraftFilters] = useState(statusFilters);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const applyFilters = (statusFilters: PortfolioStatusType[] | undefined) =>
    dispatch(setPortfolioFilters({ status: statusFilters }));

  const statusChecked = useCallback((status: PortfolioStatusType) => draftFilters?.includes(status), [draftFilters]);

  useEffect(() => {
    setDraftFilters(statusFilters);
  }, [statusFilters]);

  const filtersUpdated = useMemo(() => {
    return (
      statusFilters?.length !== draftFilters?.length ||
      intersection(draftFilters, statusFilters).length !== statusFilters?.length
    );
  }, [draftFilters, statusFilters]);

  const onFilterUpdate = (status: PortfolioStatusType) => {
    if (statusChecked(status)) {
      setDraftFilters(draftFilters?.filter((draftStatus) => draftStatus !== status));
    } else {
      setDraftFilters((draftFilters ?? []).concat(status));
    }
  };

  const onApply = () => {
    applyFilters(draftFilters);
  };

  const onClear = () => {
    setDraftFilters([]);
  };

  const items: ItemType[] = useMemo(() => {
    return [
      ...PORTFOLIO_STATUSLIST.map((item) => ({
        key: item.key,
        label: (
          <Checkbox
            onChange={() => onFilterUpdate(item.status)}
            checked={statusChecked(item.status)}
            className="flex flex-auto"
          >
            {t(`portfolio:status.label.${item.key}`)}
          </Checkbox>
        ),
      })),
      { type: 'divider' },
      {
        type: 'group',
        key: 'status_actions',
        label: <FilterActions onClear={onClear} onApply={onApply} filtersUpdated={filtersUpdated} />,
      },
    ];
  }, [filtersUpdated, statusChecked, t]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <Dropdown
        trigger={DROPDOWN_TRIGGERS}
        onOpenChange={(val) => setVisibility(val)}
        mouseLeaveDelay={0.2}
        open={visible}
        menu={{ items }}
      >
        <Button>
          {t('portfolio:filters.statusCount', { count: statusFilters?.length })} <DownOutlined />
        </Button>
      </Dropdown>
    </div>
  );
};

export default StatusFilter;
