import React, { useEffect, useMemo } from 'react';

import { conversationsApi } from 'core/lib';

type AppPreferencesWrapperProps = {
  children: React.ReactNode;
};

const AppPreferencesWrapper = ({ children }: AppPreferencesWrapperProps) => {
  const { data: hasUndreadConversationMessages } = conversationsApi.useGetConversationGlobalUnreadMessagesQuery();

  const faviconUrl = useMemo(() => {
    return hasUndreadConversationMessages ? '/faviconDarkBadge.ico' : '/favicon.ico';
  }, [hasUndreadConversationMessages]);

  useEffect(() => {
    const faviconLink: HTMLLinkElement = document.querySelector("link[rel*='icon']")!; //eslint-disable-line quotes
    if (faviconLink) {
      faviconLink.href = faviconUrl;
    }
  }, [faviconUrl]);

  return <>{children}</>;
};

export default AppPreferencesWrapper;
