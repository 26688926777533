import { createSelector } from 'reselect';

import { conversationMessagesAdapter } from './conversations.slice';
import { AppState } from '../../../store';

const { selectAll } = conversationMessagesAdapter.getSelectors();

const selectSelf = (state: AppState) => state.conversations;

export const selectWebSocketStatus = createSelector(
  selectSelf,
  (conversationState) => conversationState.webSocketState
);

const selectAllConversationMessagesByConversationId = (conversationId?: number) =>
  createSelector(selectSelf, (conversationsState) => {
    if (conversationId) {
      return conversationsState.conversationMessages[conversationId];
    }
  });

export const selectConversationMessages = (conversationId?: number) =>
  createSelector(selectAllConversationMessagesByConversationId(conversationId), (conversationMessages) => {
    if (conversationMessages) {
      return selectAll(conversationMessages);
    }
    return [];
  });
