import React from 'react';

import { SearchOutlined } from '@ant-design/icons';
import { Drawer, Input, Select, List } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';

import {
  useAppSelector,
  useAppDispatch,
  SearchResult,
  selectGlobalSearchBarToggled,
  selectSearchModule,
  setSearchModule,
  setGlobalBarToggled,
  selectModuleSearchOptions,
} from 'core/lib';
import { getDetailsPath } from 'utils';
import LoaderWrapper from 'utils/loader-wrapper';

type SidebarSearchProps = {
  isLoading: boolean;
  search: string;
  onSearch: (search: string) => void;
  result?: SearchResult[];
};

const SideBarSearch = ({ isLoading, onSearch, search, result }: SidebarSearchProps) => {
  const { t } = useTranslation('actions');

  const moduleSearchOptions = useAppSelector(selectModuleSearchOptions);
  const globalSearchBarToggled = useAppSelector(selectGlobalSearchBarToggled);
  const searchModule = useAppSelector(selectSearchModule);
  const dispatch = useAppDispatch();

  const onModuleSelect = (objectType: number) => {
    dispatch(setSearchModule(objectType));
  };

  const openSearchDrawer = () => {
    dispatch(setGlobalBarToggled(true));
  };

  const closeSearchDrawer = () => {
    dispatch(setGlobalBarToggled(false));
  };

  const onSearchResultClick = () => {
    dispatch(setGlobalBarToggled(false));
    onSearch('');
  };

  return (
    <>
      <SearchOutlined onClick={openSearchDrawer} />
      <Drawer
        key="searchSideBar"
        placement="left"
        onClose={closeSearchDrawer}
        maskClosable
        closable
        open={globalSearchBarToggled}
        width="100%"
        bodyStyle={{ height: '100%', display: 'flex' }}
      >
        <div className="flex flex-auto flex-col pa4 " style={{ gap: 20 }}>
          <div className="title">{t('global.search')}</div>
          <Input.Group className="flex" compact>
            <Select
              value={searchModule}
              options={moduleSearchOptions}
              onChange={onModuleSelect}
              placeholder={t('global:labels.module')}
              className="flex flex-auto max-w-32"
            />
            <Input
              type="string"
              value={search}
              onChange={({ target }) => onSearch(target.value)}
              className="flex flex-auto w-auto"
            />
          </Input.Group>
          <div className="h-full">
            <LoaderWrapper loading={isLoading}>
              <AutoSizer>
                {({ width, height }) => (
                  <List>
                    <FixedSizeList
                      layout="vertical"
                      itemData={result}
                      width={width}
                      height={height}
                      itemCount={result?.length ?? 0}
                      itemSize={40}
                    >
                      {({ index, data, style }) => {
                        const item = data?.[index];
                        return item ? (
                          <List.Item key={item.id} style={style}>
                            <Link
                              onClick={onSearchResultClick}
                              className="flex flex-auto"
                              to={{ pathname: getDetailsPath(item.objectType, item.id) }}
                            >
                              {`#${item.id} ${item.name}`}
                            </Link>
                          </List.Item>
                        ) : null;
                      }}
                    </FixedSizeList>
                  </List>
                )}
              </AutoSizer>
            </LoaderWrapper>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default SideBarSearch;
