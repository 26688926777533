import React, { useCallback } from 'react';

import { LogoutOutlined, SettingOutlined, ShopOutlined, TeamOutlined, UserOutlined } from '@ant-design/icons';
import { Dropdown } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import { DROPDOWN_TRIGGERS, UserAvatar } from 'common-ui';
import { AppState, logout, selectCurrentUser, selectClientOrganization, resetState } from 'core/lib';
import { SYSTEM_ROLES } from 'core/lib/constants';
import { Routes } from 'routes/routes';
import PermissionControl, { PermissionRules } from 'utils/permissionControl';

const mapState = (state: AppState) => ({
  clientOrganization: selectClientOrganization(state),
  loggedInUser: selectCurrentUser(state),
});

const mapDispatch = {
  logout: logout,
};

const connector = connect(mapState, mapDispatch);
type UserProfileSettingsProps = ConnectedProps<typeof connector>;

const UserProfileSettings = ({ logout, clientOrganization, loggedInUser }: UserProfileSettingsProps) => {
  const { t } = useTranslation('user');
  const history = useHistory();

  const onLogout = () => {
    logout();
    resetState();
    history.push(Routes.LOGIN.path);
  };

  const getMenuItems = useCallback(
    (hasPermission: boolean): ItemType[] => {
      if (!loggedInUser) {
        return [];
      }
      const permissionItems = [
        {
          key: 'perm_group',
          type: 'group',
          label: clientOrganization,
          children: [
            {
              key: 'organizations',
              icon: <ShopOutlined />,
              label: <Link to={Routes.ORGANIZATION.path}>{t('actions.organizations')}</Link>,
            },
            {
              key: 'users',
              icon: <TeamOutlined />,
              label: <Link to={Routes.USER_LISTING.path}>{t('actions.users')}</Link>,
            },
            {
              key: 'custom_fields',
              icon: <SettingOutlined />,
              label: <Link to={Routes.CUSTOM_FIELDS.path}>{t('actions.customFields')}</Link>,
            },
          ],
        },
      ];

      return [
        {
          key: 'profile_group',
          className: 'cursor-default',
          type: 'group',
          label: loggedInUser.name,
          children: [
            {
              key: 'profile_settings',
              icon: <UserOutlined />,
              label: <Link to={Routes.USER_PROFILE.path}>{t('actions.profileSettings')}</Link>,
            },
          ],
        },
        ...(hasPermission ? permissionItems : []),
        { type: 'divider' },
        {
          danger: true,
          className: 'warning',
          key: 'sign_out',
          icon: <LogoutOutlined />,
          onClick: onLogout,
          label: t('actions.signOut'),
        },
      ];
    },
    [t, clientOrganization, loggedInUser] //eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    loggedInUser && (
      <PermissionControl
        roles={[SYSTEM_ROLES.CLIENT_ADMIN, SYSTEM_ROLES.SUPER_ADMIN]}
        rule={PermissionRules.ANY}
        render={(noPerm) => (
          <Dropdown trigger={DROPDOWN_TRIGGERS} className="cursor-pointer" menu={{ items: getMenuItems(!noPerm) }}>
            <div>
              <UserAvatar user={loggedInUser} iconOnly plain />
            </div>
          </Dropdown>
        )}
      />
    )
  );
};

export default connector(UserProfileSettings);
