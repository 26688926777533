import React from 'react';

import { Form, Input, InputNumber } from 'antd';

import { valueDelimiterFormatter } from 'utils/index';

export type NumberRangeInputProps = {
  dataIndex: string;
  disabled: boolean;
  addonAfter?: string | React.ReactNode;
  addonBefore?: string | React.ReactNode;
};

const NumberRangeInput = ({ addonAfter, addonBefore, dataIndex, disabled }: NumberRangeInputProps) => {
  return (
    <Input.Group compact className="flex">
      <Form.Item noStyle className="flex flex-auto" name={[dataIndex, 'from']}>
        <InputNumber
          controls={false}
          formatter={(value) => valueDelimiterFormatter(value)}
          className="flex flex-auto"
          addonBefore={addonBefore}
          placeholder="From"
          disabled={disabled}
        />
      </Form.Item>
      <Input
        readOnly
        className="flex max-w-8 h-max border-x-0 pointer-events-none justify-center"
        placeholder="~"
        disabled={disabled}
      />
      <Form.Item noStyle className="flex flex-auto" name={[dataIndex, 'to']}>
        <InputNumber
          controls={false}
          formatter={(value) => valueDelimiterFormatter(value)}
          className="flex flex-auto border-l-0"
          placeholder="To"
          addonAfter={addonAfter}
          disabled={disabled}
        />
      </Form.Item>
    </Input.Group>
  );
};

export default NumberRangeInput;
