import SystemBaseMessage from './systemBaseMessage';
import SystemMessage from './systemMessage';
import UserBaseMessage from './userBaseMessage';
import UserMessageSequence from './userMessageSequence';
import UserSystemMessage from './userSystemMessage';

const MESSAGE_TYPES = {
  USER_MESSAGE: 'user_message',
  SYSTEM_MESSAGE: 'system_message',
};

const MESSAGE_SUBTYPE = {
  CONVERSATION_CREATED: 'conversation_created',
  GROUP_RENAMED: 'group_renamed',
  USER_CONVERSATION_JOIN: 'user_conversation_join',
  USER_CONVERSATION_LEAVE: 'user_conversation_leave',
  USER_CONVERSATION_ADD: 'user_conversation_add',
  USER_CONVERSATION_REMOVE: 'user_conversation_removed',
  USER_GROUP_LEAVE: 'user_group_leave',
  USER_GROUP_ADD: 'user_group_add',
  USER_GROUP_REMOVE: 'user_group_removed',
  MESSAGE_SEQUENCE: 'message_sequence',
};

const MessageRenderMapper = {
  [MESSAGE_TYPES.USER_MESSAGE]: UserBaseMessage,
  [MESSAGE_SUBTYPE.MESSAGE_SEQUENCE]: UserMessageSequence,
  [MESSAGE_TYPES.SYSTEM_MESSAGE]: SystemBaseMessage,

  [MESSAGE_SUBTYPE.CONVERSATION_CREATED]: SystemMessage,
  [MESSAGE_SUBTYPE.USER_CONVERSATION_JOIN]: UserSystemMessage,
  [MESSAGE_SUBTYPE.USER_CONVERSATION_LEAVE]: UserSystemMessage,
  [MESSAGE_SUBTYPE.USER_CONVERSATION_ADD]: UserSystemMessage,
  [MESSAGE_SUBTYPE.USER_CONVERSATION_REMOVE]: UserSystemMessage,

  [MESSAGE_SUBTYPE.USER_GROUP_ADD]: UserSystemMessage,
  [MESSAGE_SUBTYPE.USER_GROUP_LEAVE]: UserSystemMessage,
  [MESSAGE_SUBTYPE.USER_GROUP_REMOVE]: UserSystemMessage,

  [MESSAGE_SUBTYPE.GROUP_RENAMED]: SystemMessage,
};

export const SubTypeTranslationKeys = {
  [MESSAGE_SUBTYPE.USER_CONVERSATION_JOIN]: 'conversationJoin',
  [MESSAGE_SUBTYPE.USER_CONVERSATION_LEAVE]: 'conversationLeave',
  [MESSAGE_SUBTYPE.USER_CONVERSATION_ADD]: 'addedToConversation',
  [MESSAGE_SUBTYPE.USER_CONVERSATION_REMOVE]: 'removedFromConversation',

  [MESSAGE_SUBTYPE.USER_GROUP_ADD]: 'addedToConversation',
  [MESSAGE_SUBTYPE.USER_GROUP_LEAVE]: 'conversationLeave',
  [MESSAGE_SUBTYPE.USER_GROUP_REMOVE]: 'removedFromConversation',
};

export default MessageRenderMapper;
