import React, { useMemo } from 'react';

import { Tooltip, Typography } from 'antd';
import { TextProps } from 'antd/lib/typography/Text';
import { generatePath, Link } from 'react-router-dom';

import { selectIsAuthenticatedUserAdmin, useAppSelector } from 'core/lib';
import { Routes } from 'routes/routes';

const { Text } = Typography;

type UserLinkProps = {
  id: number;
  name: string;
};

const UserLink = ({ id, name, disabled, ...props }: Omit<TextProps, 'id' | 'name'> & UserLinkProps) => {
  const isUserAdmin = useAppSelector(selectIsAuthenticatedUserAdmin);
  const linkDisabled = useMemo(() => {
    return disabled || !isUserAdmin;
  }, [disabled, isUserAdmin]);

  return (
    <Tooltip title={name} className="truncate">
      {linkDisabled ? (
        <Text style={{ margin: 0 }} {...props}>
          {name}
        </Text>
      ) : (
        <Link to={generatePath(Routes.USER_DETAILS.path, { id })}>
          <Text style={{ margin: 0 }} {...props}>
            {name}
          </Text>
        </Link>
      )}
    </Tooltip>
  );
};

export default UserLink;
