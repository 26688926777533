import { isAfter, isBefore } from 'date-fns';
import _, { groupBy } from 'lodash';
import { createSelector } from 'reselect';

import { PortfolioStatusType } from 'core/lib/constants/statuses';
import { parseJSONDate } from 'core/lib/utils';

import { AppState } from '../../../store';
import { selectLeftMenuFiltersToggled } from '../../app';
import { PortfolioListing, PortfolioMenu } from '../entities';

const selectSelf = (state: AppState) => state.portfolio;

export const selectPortfolioFilters = createSelector(selectSelf, (portfolio) => portfolio.filters);
export const selectPortfolioStatusFilters = createSelector(selectPortfolioFilters, (filters) => filters.status);
export const selectPortfolioTypeFilters = createSelector(selectPortfolioFilters, (filters) => filters.type);
export const selectPortfolioOrganizationFilters = createSelector(
  selectPortfolioFilters,
  (filters) => filters.organization
);

export const selectFilteredPortfolioListing = createSelector(
  selectPortfolioFilters,
  (_: AppState, listingData?: PortfolioListing[]) => listingData,
  (filters, listingData) => {
    if (!listingData) {
      return listingData;
    }
    const filterByStatus = (data: PortfolioListing) =>
      !filters.status?.length || !!filters.status?.includes(data.status as PortfolioStatusType);
    const filtersByType = (data: PortfolioListing) =>
      !filters.type?.length || !!(data.type?.id && filters.type?.includes(data.type?.id));
    const filterByOrganization = (data: PortfolioListing) =>
      !filters.organization?.length || !!(data.organization.id && filters.organization?.includes(data.organization.id));
    const filtersByComplianceStatus = (data: PortfolioListing) =>
      !filters.complianceStatus?.length ||
      !!(data.complianceStatus?.id && filters.complianceStatus?.includes(data.complianceStatus?.id));
    const filterByOwner = (data: PortfolioListing) =>
      !filters.owner?.length || !!(data.owner?.id && filters.owner?.includes(data.owner?.id));
    const filterByResponsible = (data: PortfolioListing) =>
      !filters.responsible?.length || !!(data.responsible?.id && filters.responsible?.includes(data.responsible?.id));
    const filterByTaskOwner = (data: PortfolioListing) =>
      !filters.taskOwner?.length || !!(data.taskOwner?.id && filters.taskOwner?.includes(data.taskOwner?.id));
    const filterByCreationDate = (data: PortfolioListing) => {
      const dateCreated = parseJSONDate(data.dateCreated);
      const startDate = filters.creationDate?.startDate ? new Date(filters.creationDate.startDate) : null;
      const endDate = filters.creationDate?.endDate ? new Date(filters.creationDate.endDate) : null;

      if (startDate && endDate) {
        return isAfter(dateCreated!, startDate) && isBefore(dateCreated!, endDate);
      }

      return true;
    };

    return _(listingData)
      .filter(filterByStatus)
      .filter(filterByOrganization)
      .filter(filtersByType)
      .filter(filtersByComplianceStatus)
      .filter(filterByOwner)
      .filter(filterByResponsible)
      .filter(filterByTaskOwner)
      .filter(filterByCreationDate)
      .value() as PortfolioListing[];
  }
);
export const selectPortfolioListingGrouping = createSelector(selectSelf, (portfolio) => portfolio.listingGrouping);

export const selectPortfolioFilteredListingByStatusGroup = createSelector(
  selectPortfolioListingGrouping,
  (_: AppState, listingData?: PortfolioListing[]) => listingData,
  (listingGroup, listingData) => {
    return listingGroup ? groupBy(listingData, 'status')[listingGroup] : listingData;
  }
);

export const selectPortfolioFilteredMenu = createSelector(
  selectPortfolioFilters,
  selectLeftMenuFiltersToggled,
  (_: AppState, listingData?: PortfolioMenu[]) => listingData,
  (filters, leftMenuFiltersToggled, listingData) => {
    if (!listingData || !leftMenuFiltersToggled) {
      return listingData;
    }
    const filterByStatus = (data: PortfolioMenu) =>
      !filters.status?.length || !!filters.status?.includes(data.status as PortfolioStatusType);
    const filterByType = (data: PortfolioMenu) =>
      !filters.type?.length || !!(data.typeId && filters.type?.includes(data.typeId));
    const filterByOrganization = (data: PortfolioMenu) =>
      !filters.organization?.length || !!(data.organizationId && filters.organization?.includes(data.organizationId));
    const filterByComplianceStatus = (data: PortfolioMenu) =>
      !filters.complianceStatus?.length ||
      !!(data.complianceStatusId && filters.complianceStatus?.includes(data.complianceStatusId));
    const filterByOwner = (data: PortfolioMenu) =>
      !filters.owner?.length || !!(data.ownerId && filters.owner?.includes(data.ownerId));
    const filterByResponsible = (data: PortfolioMenu) =>
      !filters.responsible?.length || !!(data.responsibleId && filters.responsible?.includes(data.responsibleId));
    const filterByTaskOwner = (data: PortfolioMenu) =>
      !filters.taskOwner?.length || !!(data.taskOwnerId && filters.taskOwner?.includes(data.taskOwnerId));
    const filterByCreationDate = (data: PortfolioMenu) => {
      const dateCreated = parseJSONDate(data.dateCreated);
      const startDate = filters.creationDate?.startDate ? new Date(filters.creationDate.startDate) : null;
      const endDate = filters.creationDate?.endDate ? new Date(filters.creationDate.endDate) : null;

      if (startDate && endDate) {
        return isAfter(dateCreated!, startDate) && isBefore(dateCreated!, endDate);
      }

      return true;
    };
    return _(listingData)
      .filter(filterByStatus)
      .filter(filterByOrganization)
      .filter(filterByType)
      .filter(filterByComplianceStatus)
      .filter(filterByOwner)
      .filter(filterByResponsible)
      .filter(filterByTaskOwner)
      .filter(filterByCreationDate)
      .value() as PortfolioMenu[];
  }
);

export const selectPortfolioFiltersCount = (isMobile: boolean) =>
  createSelector(selectPortfolioFilters, (filters) => {
    return [
      ...(isMobile ? [!!filters.status?.length, !!filters.organization?.length] : []),
      !!filters.type?.length,
      !!filters.owner?.length,
      !!filters.responsible?.length,
      !!filters.taskOwner?.length,
      !!filters.complianceStatus?.length,
      filters.creationDate && Object.values(filters.creationDate).some((val) => val),
    ].filter((filter) => filter).length;
  });
