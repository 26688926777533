import { createApi } from '@reduxjs/toolkit/query/react';

import { dynamicBaseQuery } from 'core/lib/utils/requesting';

import endpoints, { TAGS } from './endpoints';
import { cacher } from '../../../utils/rtkQueryCacheUtils';
import { SearchResult, ObjectHistory, DraftBaseComment, UserComment, DeleteCommentRequest } from '../../app';
import {
  ContactBookDetails,
  ContactBookDiligencePartnerInfo,
  ContactBookIndustryExpertInfo,
  ContactBookListing,
  ContactBookListingUpdate,
  ContactBookMenu,
  ContactBookVentureCapitalInfo,
} from '../entities';

const contactBookApi = createApi({
  reducerPath: 'contactBookApi',
  baseQuery: dynamicBaseQuery,
  tagTypes: [...cacher.defaultTags, ...Object.values(TAGS)],
  endpoints: (build) => ({
    getContactBookListing: build.query<ContactBookListing[], void>({
      query: () => endpoints.listing.url,
      providesTags: cacher.providesList(endpoints.listing.tag),
      transformResponse: endpoints.listing.deserializer,
    }),
    getContactBookSearch: build.query<SearchResult[], string>({
      query: (search) => ({ url: endpoints.search.url, params: { search } }),
      transformResponse: endpoints.search.deserializer,
    }),
    getContactBookMenu: build.query<ContactBookMenu[], void>({
      query: () => endpoints.menu.url,
      providesTags: cacher.providesList(endpoints.menu.tag),
      transformResponse: endpoints.menu.deserializer,
    }),
    getContactBookDetails: build.query<ContactBookDetails, string>({
      query: (id) => ({ url: endpoints.details.url, params: { id } }),
      providesTags: cacher.cacheByIdArg(endpoints.details.tag),
      transformResponse: endpoints.details.deserializer,
    }),
    getDiligencePartnerInfo: build.query<ContactBookDiligencePartnerInfo, string>({
      query: (id) => ({ url: endpoints.diligencePartnerInfo.url, params: { id } }),
      providesTags: cacher.cacheByIdArg(endpoints.diligencePartnerInfo.tag),
      transformResponse: endpoints.diligencePartnerInfo.deserializer,
    }),
    getVentureCapitalInfo: build.query<ContactBookVentureCapitalInfo, string>({
      query: (id) => ({ url: endpoints.ventureCapitalInfo.url, params: { id } }),
      providesTags: cacher.cacheByIdArg(endpoints.ventureCapitalInfo.tag),
      transformResponse: endpoints.ventureCapitalInfo.deserializer,
    }),
    getIndustryExpertInfo: build.query<ContactBookIndustryExpertInfo, string>({
      query: (id) => ({ url: endpoints.industryExpertInfo.url, params: { id } }),
      providesTags: cacher.cacheByIdArg(endpoints.industryExpertInfo.tag),
      transformResponse: endpoints.industryExpertInfo.deserializer,
    }),
    getContactBookTags: build.query<string[], void>({
      query: () => ({ url: endpoints.getTags.url }),
    }),
    getHistory: build.query<ObjectHistory[], number>({
      query: (id) => ({ url: endpoints.getHistory.url, params: { id } }),
      providesTags: cacher.cacheByIdArg(endpoints.getHistory.tag),
      transformResponse: endpoints.getHistory.deserializer,
    }),
    getComments: build.query<UserComment[], number>({
      query: (id) => ({ url: endpoints.comments.url, params: { id } }),
      providesTags: cacher.cacheByIdArg(endpoints.comments.tag),
      transformResponse: endpoints.comments.deserializer,
    }),
    getCommentsCount: build.query<number, number>({
      query: (id) => ({ url: endpoints.commentsCount.url, params: { id } }),
      providesTags: cacher.cacheByIdArg(endpoints.commentsCount.tag),
    }),
    postComment: build.mutation<void, Partial<DraftBaseComment>>({
      query: (comment) => ({
        method: 'POST',
        url: endpoints.postComment.url,
        body: comment,
      }),
      async onQueryStarted({ objectId }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            contactBookApi.util.invalidateTags([
              { type: TAGS.CONTACT_BOOK_COMMENTS, id: objectId! },
              { type: TAGS.CONTACT_BOOK_COMMENTS_COUNT, id: objectId! },
            ])
          );
        } catch {}
      },
    }),
    deleteComment: build.mutation<void, DeleteCommentRequest>({
      query: (requestParams) => ({
        method: 'DELETE',
        url: endpoints.deleteComment.url,
        body: requestParams,
      }),
      async onQueryStarted({ objectId }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            contactBookApi.util.invalidateTags([
              { type: TAGS.CONTACT_BOOK_COMMENTS, id: objectId! },
              { type: TAGS.CONTACT_BOOK_COMMENTS_COUNT, id: objectId! },
            ])
          );
        } catch {}
      },
    }),
    postContactBook: build.mutation<number, ContactBookDetails>({
      query: (contactBook) => ({ method: 'POST', url: endpoints.createContactBook.url, body: contactBook }),
      invalidatesTags: cacher.invalidatesMultipleLists(endpoints.createContactBook.invalidates),
    }),
    deleteContactBook: build.mutation<void, number>({
      query: (contactBookId) => ({
        method: 'DELETE',
        url: endpoints.deleteContactBook.url,
        params: { id: contactBookId },
      }),
      invalidatesTags: cacher.invalidatesMultipleLists(endpoints.deleteContactBook.invalidates),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            contactBookApi.util.invalidateTags([
              { type: TAGS.CONTACT_BOOK_DETAILS, id },
              { type: TAGS.CONTACT_BOOK_DILIGENCE_PARTNER_INFO, id },
              { type: TAGS.CONTACT_BOOK_INDUSTRY_EXPERT_INFO, id },
              { type: TAGS.CONTACT_BOOK_VENTURE_CAPITAL_INFO, id },
            ])
          );
        } catch {}
      },
    }),
    patchContactBook: build.mutation<void, Partial<ContactBookDetails>>({
      query: (contactBook) => ({ method: 'POST', url: endpoints.updateContactBook.url, body: contactBook }),
      invalidatesTags: cacher.invalidatesMultipleLists(endpoints.updateContactBook.invalidates),
      async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            contactBookApi.util.invalidateTags([
              { type: TAGS.CONTACT_BOOK_DETAILS, id },
              { type: TAGS.CONTACT_BOOK_DILIGENCE_PARTNER_INFO, id },
              { type: TAGS.CONTACT_BOOK_INDUSTRY_EXPERT_INFO, id },
              { type: TAGS.CONTACT_BOOK_VENTURE_CAPITAL_INFO, id },
            ])
          );
        } catch {}
      },
    }),
    updateListingItem: build.mutation<ContactBookListing, ContactBookListingUpdate>({
      query: ({ id, ...patch }) => ({
        method: 'POST',
        url: `${endpoints.updateListingItem.url}/${id}`,
        body: patch,
      }),
      invalidatesTags: cacher.invalidatesMultipleLists(endpoints.updateListingItem.invalidates),
      transformResponse: endpoints.updateListingItem.deserializer,
      async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            contactBookApi.util.updateQueryData('getContactBookListing', undefined, (draft) => {
              const index = draft.findIndex((d) => d.id === id);
              draft.splice(index, 1, { ...draft[index], ...data });
            })
          );
          dispatch(
            contactBookApi.util.invalidateTags([
              { type: TAGS.CONTACT_BOOK_DETAILS, id },
              { type: TAGS.CONTACT_BOOK_DILIGENCE_PARTNER_INFO, id },
              { type: TAGS.CONTACT_BOOK_INDUSTRY_EXPERT_INFO, id },
              { type: TAGS.CONTACT_BOOK_VENTURE_CAPITAL_INFO, id },
            ])
          );
        } catch {}
      },
    }),
  }),
});

export default contactBookApi;
