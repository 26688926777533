import React from 'react';

import { Badge, Skeleton, Tag, Typography } from 'antd';
import { generatePath } from 'react-router-dom';
import { ListChildComponentProps } from 'react-window';

import { MenuItemWrapper } from 'common-ui/leftMenu';
import { ContactBookMenu } from 'core/lib';
import { ContactBookTypesByValueKey } from 'core/lib/constants/selects/contactBook';
import { Routes } from 'routes/routes';

const { Text } = Typography;

type ContactBookMenuItemProps = {
  id?: string;
  isLoading: boolean;
};

type ContactBookMenuItemConnectedProps = ListChildComponentProps<ContactBookMenu[]> & ContactBookMenuItemProps;

const ContactBookMenuItem = ({ index, data, style, id }: ContactBookMenuItemConnectedProps) => {
  const item = data?.[index];
  const isActive = item?.id === parseInt(id ?? '');
  return (
    <Skeleton active loading={!item}>
      {item && (
        <MenuItemWrapper
          isActive={isActive}
          style={style}
          path={generatePath(Routes.CONTACT_BOOK_DETAILS.path, { id: item.id })}
        >
          <Badge style={{ lineHeight: 'auto' }} size="small" offset={[5, 0]} className="text-inherit overflow-hidden">
            <Text className="text-inherit" ellipsis>
              {item?.name}
            </Text>
          </Badge>
          <Tag color="blue">{ContactBookTypesByValueKey[item.type]}</Tag>
        </MenuItemWrapper>
      )}
    </Skeleton>
  );
};

export default ContactBookMenuItem;
