import React from 'react';

import UpdateForm from './updateForm';
import ExternalLogin from '../externalLogin';

const ExternalDealBaseUpdate = () => {
  return (
    <ExternalLogin>
      <UpdateForm />
    </ExternalLogin>
  );
};

export default ExternalDealBaseUpdate;
