import React, { useMemo } from 'react';

import { Button, Divider, Typography } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { conversationsApi, ModuleConversationDetails } from 'core/lib';
import { MODULE_NAMES } from 'core/lib/constants';
import { Routes } from 'routes/routes';
import { useLoggedInUser } from 'utils/hooks';

import ModuleConversationDetailsHeader from './moduleConversationDetailsHeader';

const { Title } = Typography;

type ModuleCommentViewWrapperProps = {
  children: React.ReactNode;
  conversationDetails: ModuleConversationDetails;
};

const ModuleCommentViewWrapper = ({ children, conversationDetails }: ModuleCommentViewWrapperProps) => {
  const currentUser = useLoggedInUser();
  const { t } = useTranslation();

  const getTitle = (conversation: ModuleConversationDetails) => {
    return conversation.objectTitle ?? MODULE_NAMES[conversation.objectType];
  };

  const [joinConversation, { isLoading: isJoinConversationLoading }] =
    conversationsApi.useJoinModuleConversationMutation();

  const onJoinConversationClick = () => {
    joinConversation(conversationDetails.id);
  };

  const isUserParticipant = useMemo(
    () => currentUser && conversationDetails.participants.map(({ id }) => id).includes(currentUser.id),
    [conversationDetails.participants, currentUser]
  );
  return isUserParticipant ? (
    <>{children}</>
  ) : (
    <div className="flex flex-col items-center gap-4">
      <Divider className="m-0" />
      <Title level={5} className="font-normal">
        <Trans
          i18nKey="conversations:placeholders.viewingConversation"
          values={{ name: `#${conversationDetails.objectId ?? ''} ${getTitle(conversationDetails)}` }}
          components={{ strong: <strong /> }}
        ></Trans>
      </Title>
      <div className="flex flex-row gap-4">
        <Button onClick={onJoinConversationClick} loading={isJoinConversationLoading} type="primary">
          {t('conversations:actions.join')}
        </Button>
        <ModuleConversationDetailsHeader
          hideAvatarGroup
          conversationDetails={conversationDetails}
          trigger={<Button type="default">{t('conversations:actions.viewDetails')}</Button>}
        />
      </div>
      <Link to={Routes.CONVERSATIONS_LISTING.path}>{t('conversations:actions.backToBrowsing')}</Link>
    </div>
  );
};

export default ModuleCommentViewWrapper;
