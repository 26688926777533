import React, { useMemo } from 'react';

import { EllipsisOutlined } from '@ant-design/icons';
import { Button, ButtonProps, Dropdown } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { useTranslation } from 'react-i18next';

import { DROPDOWN_TRIGGERS, MenuItemConversationAction, MenuItemDirectConversation } from 'common-ui';
import { UserDetails, userApi } from 'core/lib';
import { OBJECT_TYPES } from 'core/lib/constants';

import UserStatusButtonAction from './userStatusButton';

type DetailsHeaderActionsProps = {
  user: UserDetails;
};

const DetailsHeaderActions = ({ user, ...props }: DetailsHeaderActionsProps & ButtonProps) => {
  const { t } = useTranslation();

  const [unlockUser, { isLoading: isUnlocking }] = userApi.useLazyUnlockUserQuery();

  const onUserUnlock = () => {
    unlockUser(user.id);
  };

  const items = useMemo(() => {
    const menuItems: ItemType[] = [
      {
        key: 'conversation_action',
        label: <MenuItemConversationAction objectType={OBJECT_TYPES.USER} objectId={user.id} objectName={user.name} />,
      },
      { key: 'dm_action', label: <MenuItemDirectConversation userId={user.id} /> },
    ];
    if (user.locked) {
      menuItems.unshift({
        key: 'user_unlock',
        onClick: onUserUnlock,
        disabled: isUnlocking,
        label: t('user:actions.unlock'),
      });
    }
    return menuItems;
  }, [t, isUnlocking, user]); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="flex gap-2">
      <UserStatusButtonAction user={user} {...props} />
      <Dropdown trigger={DROPDOWN_TRIGGERS} menu={{ items }}>
        <Button {...props} type="default">
          <EllipsisOutlined />
        </Button>
      </Dropdown>
    </div>
  );
};

export default DetailsHeaderActions;
