import React, { useCallback, useEffect, useMemo } from 'react';

import { SendOutlined } from '@ant-design/icons';
import Link from '@tiptap/extension-link';
import Placeholder from '@tiptap/extension-placeholder';
import Underline from '@tiptap/extension-underline';
import { JSONContent, HTMLContent, EditorContent, Editor } from '@tiptap/react';
import StarterKit, { StarterKitOptions } from '@tiptap/starter-kit';
import { Button } from 'antd';
import { isUndefined, mapValues, merge, pickBy } from 'lodash';
import { isMobile } from 'react-device-detect';

import { BaseUser } from 'core/lib';
import { fuzzyWordsSearch } from 'core/lib/utils';
import { useTipTapEditor } from 'utils/hooks';

import { Toolbar } from './components';
import './editor.css';
import { ExtendedMentionExtention } from './extentions';
import suggestion from './suggestion';

export type EditorProps = {
  onSubmit?: () => void;
  onChange?: (editor: JSONContent) => void;
  setEditor?: (editor: Editor | null) => void;
  disabled?: boolean;
  disabledExtentions?: Partial<StarterKitOptions>;
  value?: JSONContent | HTMLContent;
  external?: boolean;
  readOnly?: boolean;
  mentionsData?: BaseUser[];
  submitOnEnter?: boolean;
  autoFocus?: boolean;
  toolbarHidden?: boolean;
  oneRow?: boolean;
  showSubmitButton?: boolean;
  placeholder?: string;
};

const oneRowEditorInitialClass =
  'h-auto overflow-scroll min-h-12 max-h-36 p-1 border border-solid b--grey-200 bg-white break-all';
const editorClass = 'h-auto min-h-36 p-1 border border-solid b--grey-200 bg-white ';
const viewerClass = 'h-auto p-1 border break-all ';

const defaultExtention: Partial<StarterKitOptions> = {
  blockquote: false,
  code: false,
  codeBlock: false,
  dropcursor: false,
  gapcursor: false,
  heading: false,
  strike: false,
  horizontalRule: false,
  bold: undefined,
  italic: undefined,
  bulletList: undefined,
  orderedList: undefined,
  hardBreak: undefined,
};

const WysiwygEditor = ({
  onChange,
  onSubmit,
  setEditor,
  disabled,
  disabledExtentions = {},
  value,
  external,
  readOnly,
  mentionsData,
  submitOnEnter,
  placeholder,
  autoFocus = false,
  toolbarHidden = false,
  oneRow = false,
  showSubmitButton = false,
}: EditorProps) => {
  const getEditorClass = useCallback(() => {
    if (readOnly) {
      return viewerClass;
    } else {
      if (oneRow) {
        return oneRowEditorInitialClass;
      }
      return editorClass;
    }
  }, [readOnly, oneRow]);

  const isEnterWithModifier = (event: KeyboardEvent) => {
    return event.key === 'Enter' && (event.altKey || event.shiftKey || event.ctrlKey);
  };

  const isMobileEnter = (event: KeyboardEvent) => {
    return event.key === 'Enter' && isMobile;
  };

  const getMentionConfiguration = useCallback(() => {
    return {
      HTMLAttributes: {
        class: 'mention',
      },
      external,
      suggestion:
        external || readOnly
          ? undefined
          : {
              items: ({ query }: { query: string }) =>
                mentionsData?.filter((user) => fuzzyWordsSearch(query, user.name)) ?? [],
              render: suggestion().render,
            },
    };
  }, [mentionsData, external, readOnly]);

  const editor = useTipTapEditor(
    {
      autofocus: autoFocus && 'start',
      parseOptions: {
        preserveWhitespace: 'full',
      },
      extensions: [
        StarterKit.configure({ ...defaultExtention, ...disabledExtentions }),
        Placeholder.configure({
          showOnlyCurrent: true,
          showOnlyWhenEditable: true,
          placeholder,
        }),
        Underline,
        ExtendedMentionExtention.configure(getMentionConfiguration()),
        Link.configure({
          linkOnPaste: true,
          autolink: true,
        }),
      ],
      editorProps: {
        handleKeyDown: (view, event) => {
          if (isEnterWithModifier(event) || isMobileEnter(event)) {
            return false;
          } //eslint-disable-next-line @typescript-eslint/no-explicit-any
          else if (event.key === 'Enter' && !(view.state as any).mention$?.active && submitOnEnter) {
            if (onSubmit) {
              onSubmit();
              return true;
            }
          }
          return false;
        },
        attributes: {
          class: getEditorClass(),
        },
      },
      editable: !readOnly,
      content: value ?? '<p></p>',
      onUpdate: ({ editor }) => {
        if (onChange) {
          onChange(editor.getJSON());
        }
      },
    },
    [mentionsData, placeholder, readOnly]
  );

  useEffect(() => {
    if (readOnly && value) {
      try {
        editor?.commands.setContent(value);
      } catch {}
    }
  }, [readOnly, value, editor?.commands]);

  useEffect(() => {
    if (editor) {
      editor?.setEditable(!readOnly ?? false);
      editor?.setOptions({ editorProps: { attributes: { class: getEditorClass() } } });
    }
  }, [editor, readOnly, getEditorClass]);

  useEffect(() => {
    if (setEditor) {
      setEditor(editor);
    }
  });

  const toolbarVisible = useMemo(() => !toolbarHidden && !readOnly, [toolbarHidden, readOnly]);

  const submitButtonVisible = useMemo(() => onSubmit && showSubmitButton, [onSubmit, showSubmitButton]);

  const onButtonClickSubmit = (e: React.MouseEvent) => {
    if (onSubmit) {
      onSubmit();
    }
  };

  return (
    <div className="flex flex-row items-center gap-2">
      <div className="block w-full">
        {toolbarVisible && editor && (
          <Toolbar
            enabledExtentions={mapValues(
              pickBy(merge({ ...defaultExtention, ...disabledExtentions }), isUndefined),
              () => true
            )}
            editor={editor}
          />
        )}
        <EditorContent autoFocus={autoFocus} readOnly={readOnly} editor={editor} />
      </div>
      {submitButtonVisible && (
        <Button
          id="submit-button"
          className="select-none"
          onMouseDown={(e) => e.preventDefault()}
          type="text"
          icon={<SendOutlined />}
          disabled={disabled || editor?.isEmpty}
          onClick={onButtonClickSubmit}
        />
      )}
    </div>
  );
};

export default WysiwygEditor;
