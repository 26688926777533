import { Classification, ClassificationResponse } from '../entities/classification';

export const deserializeClassification = (response: ClassificationResponse): Classification => {
  return {
    id: response.ID,
    businessCritical: response.BusinessCritical,
    dataPrivacy: response.DataPrivacy,
    eres: response.ERES,
    gxp: response.GxP,
    informationSecurity: response.InformationSecurity,
    legal: response.Legal,
    other: response.Other,
    sdg: response.SDG,
    sox: response.SOX,
  };
};
