import React from 'react';

import { AvatarProps, Select, SelectProps, TagProps } from 'antd';

import { UserAvatar, UserTag } from 'common-ui';
import { BaseSelectOption, BaseUser, userApi } from 'core/lib';
import { UserSystemRoleId } from 'core/lib/constants';

import { mergeSelectedWithOptions } from './formUtils';

export type UserSelectProps = {
  roles?: number[];
  asAvatar?: boolean;
  avatarProps?: AvatarProps;
  asTag?: boolean;
  tagProps?: TagProps;
  color?: string;
  selectedOption?: BaseUser;
  organizationIds?: number[];
  roleIds?: UserSystemRoleId[];
  filterFn?: (data: BaseSelectOption[]) => BaseSelectOption[];
};

const UserSelect = ({
  roles,
  asAvatar,
  avatarProps = {},
  asTag,
  tagProps = {},
  color,
  selectedOption,
  roleIds,
  organizationIds,
  filterFn,
  ...props
}: UserSelectProps & SelectProps<number>) => {
  const [getUserOptions, { data, isFetching }] = userApi.useLazyGetUserOptionsQuery({
    selectFromResult: ({ data, ...rest }) => {
      const res = mergeSelectedWithOptions<BaseUser>(data, selectedOption);
      if (filterFn) {
        return { data: filterFn(res), ...rest };
      }
      return { data: res, ...rest };
    },
  });

  const onDropdownVisibleChange = (open: boolean) => {
    if (open) {
      getUserOptions({ organizationIds, roleIds }, true);
    }
  };

  const value = selectedOption?.id;

  if (props.disabled && selectedOption) {
    return (
      <div className="flex flex-wrap gap-1">
        {asTag && (
          <UserTag
            id={selectedOption.id}
            name={selectedOption.name}
            key={selectedOption.id}
            tagProps={{ className: 'm-0', ...tagProps }}
          />
        )}
        {asAvatar && <UserAvatar size="default" user={selectedOption} {...avatarProps} />}
      </div>
    );
  }

  return (
    <Select
      showSearch
      allowClear
      optionFilterProp="label"
      loading={isFetching}
      value={value}
      options={data}
      onDropdownVisibleChange={onDropdownVisibleChange}
      {...props}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onChange={(_val, option: any) => {
        props.onChange?.(option, option);
      }}
    />
  );
};

export default UserSelect;
