import { RuleObject } from 'antd/lib/form';
import { StoreValue } from 'antd/lib/form/interface';
import { isNil } from 'lodash';
import isURL from 'validator/lib/isURL';

import { NumberRange, userApi } from 'core/lib';
import { MAX_INT_TYPE } from 'core/lib/constants';
import store from 'core/lib/store';

export const validateURL = (text?: string | number | readonly string[]) => {
  if (!text || typeof text !== 'string') {
    return false;
  }

  return isURL(text);
};

const passwordLengthRegex = new RegExp('^.{8,}$');

const containsUppercaseRegex = new RegExp('^(?=.*?[A-Z])');

const containsLowercaseRegex = new RegExp('^(?=.*?[a-z])');

const containsNumberRegex = new RegExp('^(?=.*?[0-9])');

const containsSpecialRegex = new RegExp('^(?=.*?[#?!@$%^&*-])');

export const PASSWORD_REGEX = {
  LENGTH: passwordLengthRegex,
  UPPERCASE: containsUppercaseRegex,
  LOWERCASE: containsLowercaseRegex,
  NUMBER: containsNumberRegex,
  SPECIAL: containsSpecialRegex,
};

const validatePasswordStrength = (password: string) => {
  return (
    PASSWORD_REGEX.LENGTH.test(password) &&
    PASSWORD_REGEX.UPPERCASE.test(password) &&
    PASSWORD_REGEX.LOWERCASE.test(password) &&
    PASSWORD_REGEX.NUMBER.test(password) &&
    PASSWORD_REGEX.SPECIAL.test(password)
  );
};

export const passwordValidator = (rule: RuleObject, value: StoreValue) => {
  if (validatePasswordStrength(value)) {
    return Promise.resolve();
  }
  return Promise.reject(rule.message as string);
};

export const uniqueUserEmailValidator = async (rule: RuleObject, value: StoreValue, userId?: number) => {
  const promise = store.dispatch(userApi.endpoints.checkUniqueEmail.initiate({ email: value, userId }));
  try {
    const { data } = await promise;
    return data ? Promise.resolve() : Promise.reject(rule.message as string);
  } catch {
    return Promise.reject(rule.message as string);
  }
};

const validateNumberRange = (value: NumberRange, maxLength: MAX_INT_TYPE) => {
  return (
    !value ||
    ((!value.from || (value.from >= -maxLength && value.from <= maxLength)) &&
      (!value.to || (value.to >= -maxLength && value.to <= maxLength)))
  );
};

export const numberRangeValidator = (maxLength: MAX_INT_TYPE) => (rule: RuleObject, value: StoreValue) => {
  if (validateNumberRange(value, maxLength)) {
    return Promise.resolve();
  }
  return Promise.reject(rule.message as string);
};

const validateRequiredNumberRange = (value?: NumberRange | null) => {
  if (!value) {
    return false;
  }
  return !isNil(value.from) || !isNil(value.to);
};

export const requiredNumberRangeValidator = (rule: RuleObject, value: StoreValue) => {
  if (validateRequiredNumberRange(value)) {
    return Promise.resolve();
  }
  return Promise.reject(rule.message as string);
};
