import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { DownOutlined } from '@ant-design/icons';
import { Button, Checkbox, Dropdown } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { intersection } from 'lodash';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';

import { DROPDOWN_TRIGGERS, FilterActions } from 'common-ui';
import { AppState } from 'core/lib';
import { DealBaseStatusType, DEALBASE_STATUSLIST } from 'core/lib/constants/statuses';
import { selectDealBaseStatusFilters, setDealBaseFilters } from 'core/lib/modules/dealBase/store';

const mapState = (state: AppState) => ({
  statusFilters: selectDealBaseStatusFilters(state),
});

const mapDispatch = {
  applyFilters: (statusFilters: DealBaseStatusType[] | undefined) => setDealBaseFilters({ status: statusFilters }),
};

const connector = connect(mapState, mapDispatch);

type StatusFilterProps = ConnectedProps<typeof connector>;

const StatusFilter = ({ applyFilters, statusFilters }: StatusFilterProps) => {
  const [visible, setVisibility] = useState(false);
  const [draftFilters, setDraftFilters] = useState(statusFilters);
  const { t } = useTranslation();

  const statusChecked = useCallback((status: DealBaseStatusType) => draftFilters?.includes(status), [draftFilters]);

  useEffect(() => {
    setDraftFilters(statusFilters);
  }, [statusFilters]);

  const filtersUpdated = useMemo(() => {
    return (
      statusFilters?.length !== draftFilters?.length ||
      intersection(draftFilters, statusFilters).length !== statusFilters?.length
    );
  }, [draftFilters, statusFilters]);

  const onFilterUpdate = (status: DealBaseStatusType) => {
    if (statusChecked(status)) {
      setDraftFilters(draftFilters?.filter((draftStatus) => draftStatus !== status));
    } else {
      setDraftFilters((draftFilters ?? []).concat(status));
    }
  };

  const onApply = () => {
    applyFilters(draftFilters);
  };

  const onClear = () => {
    setDraftFilters([]);
  };

  const items: ItemType[] = useMemo(() => {
    return [
      ...DEALBASE_STATUSLIST.map((item) => ({
        key: item.key,
        label: (
          <Checkbox
            onChange={() => onFilterUpdate(item.status)}
            checked={statusChecked(item.status)}
            className="flex flex-auto"
          >
            {t(`dealbase:status.label.${item.key}`)}
          </Checkbox>
        ),
      })),
      { type: 'divider' },
      {
        type: 'group',
        key: 'status_actions',
        label: <FilterActions onClear={onClear} onApply={onApply} filtersUpdated={filtersUpdated} />,
      },
    ];
  }, [filtersUpdated, statusChecked, t]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <Dropdown
        trigger={DROPDOWN_TRIGGERS}
        onOpenChange={(val) => setVisibility(val)}
        mouseLeaveDelay={0.2}
        open={visible}
        menu={{ items }}
      >
        <Button>
          {t('dealbase:filters.statusCount', { count: statusFilters?.length })} <DownOutlined />
        </Button>
      </Dropdown>
    </div>
  );
};

export default connector(StatusFilter);
