import React from 'react';

import { generatePath, Redirect, Route, Switch } from 'react-router-dom';

import { Routes } from 'routes/routes';

import ExternalComments from './comments';
import ExternalDealBaseForm from './create';
import ExternalUpdate from './update';

const ExternalDealBase = () => {
  return (
    <Switch>
      <Route
        path={[Routes.EXTERNAL_FORM.path, Routes.EXTERNAL_FORM_OLD.path]}
        exact
        render={() => <Redirect to={generatePath(Routes.EXTERNAL_CREATE.path)} />}
      />
      <Route path={Routes.EXTERNAL_CREATE.path} component={ExternalDealBaseForm} />
      <Route path={Routes.EXTERNAL_CASEOWNER.path} component={ExternalUpdate} />
      <Route path={Routes.EXTERNAL_THIRDPARTY.path} component={ExternalComments} />
    </Switch>
  );
};

export default ExternalDealBase;
