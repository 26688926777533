import React from 'react';

import { Form } from 'antd';

import { CommonObjectOption } from 'core/lib';

import { portfolioFormConfig } from '../../../form/formConfig';

const {
  generalInformation: { supplierInformation },
} = portfolioFormConfig;

type SupplierInformationSectionProps = {
  editMode: boolean;
};

const PortfolioSupplierInformationSection = ({ editMode }: SupplierInformationSectionProps) => {
  return (
    <div className="flex flex-col">
      <div className="flex sm:flex-row flex-col gap-x-4">
        <Form.Item
          className="md:w-3/6"
          label={supplierInformation.supplierCompany.label}
          name={supplierInformation.supplierCompany.dataIndex}
          rules={supplierInformation.supplierCompany.rules}
        >
          <supplierInformation.supplierCompany.component disabled={!editMode} />
        </Form.Item>
        <Form.Item
          className="md:w-3/6"
          label={supplierInformation.systemName.label}
          name={supplierInformation.systemName.dataIndex}
          rules={supplierInformation.systemName.rules}
        >
          <supplierInformation.systemName.component disabled={!editMode} />
        </Form.Item>
      </div>
      <div className="flex sm:flex-row flex-col gap-x-4">
        <Form.Item
          className="md:w-3/6"
          label={supplierInformation.systemVersion.label}
          name={supplierInformation.systemVersion.dataIndex}
          rules={supplierInformation.systemVersion.rules}
        >
          <supplierInformation.systemVersion.component disabled={!editMode} />
        </Form.Item>
        <Form.Item
          className="md:w-3/6"
          label={supplierInformation.technology.label}
          name={supplierInformation.technology.dataIndex}
          rules={supplierInformation.technology.rules}
          valuePropName="selectedOption"
          normalize={(option: CommonObjectOption) => {
            return option ? { id: option.id, name: option.label } : null;
          }}
        >
          <supplierInformation.technology.component disabled={!editMode} />
        </Form.Item>
      </div>
      <div className="flex sm:flex-row flex-col gap-x-4">
        <Form.Item
          className="md:w-3/6"
          label={supplierInformation.supplierType.label}
          name={supplierInformation.supplierType.dataIndex}
          rules={supplierInformation.supplierType.rules}
          valuePropName="selectedOption"
          normalize={(option: CommonObjectOption) => {
            return option ? { id: option.id, name: option.label } : null;
          }}
        >
          <supplierInformation.supplierType.component disabled={!editMode} />
        </Form.Item>
        <Form.Item
          className="md:w-3/6"
          label={supplierInformation.language.label}
          name={supplierInformation.language.dataIndex}
          rules={supplierInformation.language.rules}
          valuePropName="selectedOption"
          normalize={(options: CommonObjectOption[]) => {
            return options ? options.map((option) => ({ id: option.id, name: option.label })) : null;
          }}
        >
          <supplierInformation.language.component disabled={!editMode} />
        </Form.Item>
      </div>
    </div>
  );
};

export default PortfolioSupplierInformationSection;
