import React from 'react';

import { Select, SelectProps, TagProps } from 'antd';

import { OrganizationTag } from 'common-ui';
import { OrganizationShort, organizationApi } from 'core/lib';

import { mergeMultiSelectedWithOptions } from './formUtils';

export interface OrganizationMultiSelectProps {
  max?: number;
  asTags?: boolean;
  tagProps?: TagProps;
  color?: string;
  selectedOption?: OrganizationShort[];
}

const OrganizationMultiSelect = ({
  color,
  max,
  asTags,
  tagProps = {},
  selectedOption,
  ...props
}: OrganizationMultiSelectProps & SelectProps<number[]>) => {
  const [getOrganizationOptions, { data, isFetching }] = organizationApi.useLazyGetUserOrganizationsQuery({
    selectFromResult: ({ data, ...rest }) => {
      return { data: mergeMultiSelectedWithOptions<OrganizationShort>(data, selectedOption), ...rest };
    },
  });

  const onDropdownVisibleChange = (open: boolean) => {
    if (open) {
      getOrganizationOptions();
    }
  };

  if (props.disabled && asTags && selectedOption?.length) {
    return (
      <div className="flex flex-wrap gap-1">
        {selectedOption?.map((item) => (
          <OrganizationTag name={item.name} tagProps={{ className: 'm-0', ...tagProps }} key={item.id} />
        ))}
      </div>
    );
  }

  const value = selectedOption?.map((so) => so.id) ?? [];

  return (
    <Select
      showSearch
      allowClear
      optionFilterProp="label"
      mode="multiple"
      loading={isFetching}
      value={value}
      options={data}
      dropdownMatchSelectWidth={150}
      onDropdownVisibleChange={onDropdownVisibleChange}
      {...props}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onChange={(_val, option: any) => {
        props.onChange?.(option, option);
      }}
    />
  );
};

export default OrganizationMultiSelect;
