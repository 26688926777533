import React from 'react';

import { BaseUser, ConversationMessage } from 'core/lib';

import { MessageRenderMapper, UserMessage } from './index';

type UserBaseMessageProps = {
  message: ConversationMessage;
  conversationParticipants?: BaseUser[];
};

const UserBaseMessage = ({ message, conversationParticipants }: UserBaseMessageProps) => {
  const MessageRenderer = message.subType ? MessageRenderMapper[message.subType] : UserMessage;
  return (
    <div>
      <MessageRenderer conversationParticipants={conversationParticipants} message={message} />
    </div>
  );
};

export default UserBaseMessage;
